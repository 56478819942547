import { call, put, takeEvery } from "redux-saga/effects";
import {  SEARCH_BY_CITY, SEARCH_BY_EDUCATION, SEARCH_BY_FILTER, SEARCH_BY_OCCUPATION } from "./ActionTypes";
import { searchByCityRequestApiError, searchByCityRequestSuccess, searchByEducationRequestApiError, searchByEducationRequestSuccess, searchByFilterRequestApiError, searchByFilterRequestSuccess, searchByOccupationRequestApiError, searchByOccupationRequestSuccess} from "./Action";
import { Search_By_City, Search_By_Education, Search_By_Filter, Search_By_Occupation } from "../../Helpers/api_helpers";
import Constants from '../../Constants/constants';


function* searchByCity({ payload: {values} }) {
  //console.log(values,"datadata");
  try {
    const response = yield call(Search_By_City,{
      email : localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail),
      userId : localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
  });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(searchByCityRequestSuccess(response));
    } else {
      yield put(searchByCityRequestSuccess(response));
    }
  } catch (error) {
    yield put(searchByCityRequestApiError(error));
  }
}

function* searchByEducation({ payload: {values} }) {
  //console.log(values,"datadata");
  try {
    const response = yield call(Search_By_Education,{
      email : localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail),
      userId : localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
  });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(searchByEducationRequestSuccess(response));
    } else {
      yield put(searchByEducationRequestSuccess(response));
    }
  } catch (error) {
    yield put(searchByEducationRequestApiError(error));
  }
}

function* searchByOccupation({ payload: {values} }) {
  //console.log(values,"datadata");
  try {
    const response = yield call(Search_By_Occupation,{
      email : localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail),
      userId : localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
  });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(searchByOccupationRequestSuccess(response));
    } else {
      yield put(searchByOccupationRequestSuccess(response));
    }
  } catch (error) {
    yield put(searchByOccupationRequestApiError(error));
  }
}

function* searchByFilter({ payload: {values} }) {
  //console.log(values,"datadata");
  try {
    const response = yield call(Search_By_Filter,{
      email : localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail),
      userId : localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
  });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(searchByFilterRequestSuccess(response));
    } else {
      yield put(searchByFilterRequestSuccess(response));
    }
  } catch (error) {
    yield put(searchByFilterRequestApiError(error));
  }
}


function* exploreProfile() {
  yield takeEvery(SEARCH_BY_CITY, searchByCity);
  yield takeEvery(SEARCH_BY_EDUCATION, searchByEducation);
  yield takeEvery(SEARCH_BY_OCCUPATION, searchByOccupation);
  yield takeEvery(SEARCH_BY_FILTER, searchByFilter);
}

export default exploreProfile;
