import React from 'react'
import Loader from '../Loader'

function SearchResultMessage({ message, onClick, loading }) {
    
    return (
        <div className='flex flex-col items-center justify-center'>
            <p className="py-5 px-4">{message}</p>
            <button
                className={`text-[12px] text-[#FFFFFF] w-24 font-segeo font-semibold ${loading ? "bg-white" : "bg-[#D10A11]"} p-1  px-3 rounded-[5px]`}
                onClick={onClick}>
                {loading ? <Loader className="!inline bg-white text-white" /> : "Yes"}
            </button>
        </div>
    )
}

export default SearchResultMessage