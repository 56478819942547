import React, { useEffect, useState, useCallback, useRef } from 'react'

function useNewLineDetect() {
    const [isNewLine, setIsNewLine] = useState(false)
    const textRef = useRef(null)

    function onLineWrapDoSomething() {
        if (!textRef.current) return;
        const { lineHeight } = getComputedStyle(textRef.current);
        const lineHeightParsed = parseInt(lineHeight.split('px')[0]);
        const amountOfLinesTilAdjust = 2;
        console.log("onLineWrapDoSomething",textRef.current?.target,textRef.current?.clientHeight);
        if (textRef.current?.clientHeight >= (lineHeightParsed * amountOfLinesTilAdjust)) {
            setIsNewLine(true)
        } else {
            setIsNewLine(false)
        }
    }

    useEffect(() => {
        const observer = new MutationObserver(mutations =>
            mutations.forEach(mutationRecord => onLineWrapDoSomething())
        );
        if (textRef.current) {
            observer.observe(textRef.current, { attributes: true, attributeFilter: ['style'] });
        }

        window.addEventListener('resize', onLineWrapDoSomething)

        return () => {
            observer.disconnect();
            window.removeEventListener('resize', onLineWrapDoSomething)
        }
    }, [textRef.current])

    const refCallback = useCallback((node) => {
        if (node !== null) {
            textRef.current = node;
            console.log("refCallback",node,node.clientHeight);
            onLineWrapDoSomething()
        }
    }, [])

    return {isNewLine,refCallback}
}

export default useNewLineDetect