import React, { useCallback, useEffect, useState, useRef } from "react";
import "./MultiRangeSlider.css";
import { Slider } from 'antd';
import Alert from "../../Components/Kalyanlite/assets/Images/danger.svg";
import { useTranslation } from "react-i18next";



const MultiRangeSliderHeight = ({ min, max, onChange, value, data }) => {
  const [val, setVal] = useState([toInches(min),toInches(max)]);
  const [minHeight, setMinHeight] = useState()
  const [maxHeight, setMaxHeight] = useState();
  const [showError, setShowError] = useState(false)
  const {t:trans} = useTranslation()
  useEffect(() => {
    if(data?.minheight != undefined && data?.maxheight != undefined){
      setVal([defaultToInces(data?.minheight), defaultToInces(data?.maxheight)])
    }
  },[data])
  useEffect(() => {
  if(val[0]?.toString().includes(".")){
  // let minHeight = val[0]?.toString().split('.')
    setMinHeight(toInches(val[0]))
  }
  else{
    setMinHeight(toInches(val[0]))
  }
  },[min, val])
  useEffect(() => {
    if(val[1]?.toString().includes(".")){
    // let maxHeight = val[1]?.toString().split('.')
      setMaxHeight(toInches(val[1]))
    }
    else{
      setMaxHeight(toInches(val[1]))
    }
    },[min, val])
    function toInches(n) {
      let formatedValue = n;
      if(typeof n  == "number"){
         formatedValue= Math.floor(n / 12) + "'" + (n % 12).toFixed(0) + '"';
      }
      if(formatedValue == `5'0"` || formatedValue == `6'0"` ||formatedValue == `7'0"` ){
        if(formatedValue == `5'0"`){
          return '5';
        }else if(formatedValue == `6'0"`){
          return '6';
        }else if(formatedValue == `7'0"`){
          return '7';
        }
      }
      else{
        return formatedValue;
      }
    }
    function defaultToInces(str) {       
      if(str && str !=null){
      if(str =='5' || str =='6' || str =='7'){          
        return parseInt(str) * 12 ;
      } 
      else{
        let Height = ""
        if(typeof str == "string"){
          Height = str?.split('.')
        }else{
          Height = str[0]?.split('.')
        }        
        const n = parseInt(Height[0]) * 12 + parseInt(Height[1]);
        return n;   
      } 
    }   
    }

    function TipFormater(n) {
      const formatedValue= Math.floor(n / 12) + '.'+ (n % 12).toFixed(0);
      if(formatedValue == 5.0 || formatedValue == 6.0 ||formatedValue == 7.0 ){
        if(formatedValue == 5.0){
          return '5';
        }else if(formatedValue == 6.0){
          return '6';
        }else if(formatedValue == 7.0){
          return '7';
        }
      }
      else{
        return formatedValue;
      }
    }

    const formatter = (n) => {
      const formatedValue = Math.floor(n / 12) + "." + (n % 12).toFixed(0);
      if (formatedValue == 5.0 || formatedValue == 6.0 || formatedValue == 7.0) {
        if (formatedValue == 5.0) {
          return "5'0"+'"';
        } else if (formatedValue == 6.0) {
          return "6'0"+'"';
        } else if (formatedValue == 7.0) {
          return "7'0"+'"';
        }
      } else {
        return formatedValue.toString().replace(".","'")+'"';
      }
    };

    function convertHeight(heightStr) {
      if(heightStr == undefined){
        return;
      }
      if(heightStr.length == 0){
        return;
      }
      if(heightStr == 5 || heightStr == 6 || heightStr == 7){
        heightStr = heightStr+".0"
      }
      // Split the height string into feet and inches
      const [feet, inches] = heightStr.split('.');
      // Convert to inches and return the total height
      return parseInt(feet) * 12 + parseInt(inches);
    }
    const errorMsgCheck =(minHeight,maxHeight)=>{
      if(minHeight &&maxHeight){
        setShowError(Math.abs(convertHeight(maxHeight) - convertHeight(minHeight))  < 12)
      }
    }

  return (
    <>
    <div className="mt-[2rem]" >
      <p className="text-[#9F9F9F] text-[13px] font-bold  ">
        {value}
      </p>
    </div>
    <div className="flex justify-between ">
          <p className="text-[#575556] flex text-[13px] font-bold mr-[1rem]">
          {trans('filters.min')} {minHeight} 
          </p>
          <p className="text-[#575556] flex text-[13px] font-bold ">
          {trans('filters.max')} {maxHeight}
          </p>
    </div>
    {(data.minheight) &&
    <Slider
    range={{
      draggableTrack: true,
    }}
    tipFormatter={value => TipFormater(value)}
    // step={0.1}    
    min={min}
    max={max}
    defaultValue={[defaultToInces(data?.minheight), defaultToInces(data?.maxheight)]}
    value={[val[0], val[1]]}
    onChange={(event) => {
      onChange(TipFormater(event[0]),TipFormater(event[1]))
      setVal(event)
      // errorMsgCheck(data?.maxheight,data?.minheight)
    }}
    tooltip={{formatter}}
  />
}
{Math.abs(convertHeight(data?.maxheight) - convertHeight(data?.minheight))  < 12 && (
        <div className='mt-4 flex items-center'>
          <img src={Alert} alt="alert" className='w-4 h-4 mr-2'/>
          <p className='text-[#D10A11] text-[11px] lg:text-[15px]'>
          {trans('filters.broaden_this_parameter_a_littl')}
          </p>
        </div>
      )}
  </>
  );
};


export default MultiRangeSliderHeight;
