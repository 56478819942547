import React, { useRef, useEffect } from 'react'
import confetti from 'canvas-confetti';


function PaperBlast() {
    const canvasRef = useRef(null);

    useEffect(() => {
        const myConfetti = confetti.create(canvasRef.current, {
            resize: true, // will fit all screen sizes
            useWorker: true // use worker for performance
        });

        myConfetti({
            particleCount: 300,
            spread: 80,
            origin: { y: 0.6 }
        });

    }, []);
    return (
        <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }} />
    )
}

export default PaperBlast