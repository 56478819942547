import React, { memo, useEffect, useRef, lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom';
import $ from 'jquery'
// import {Helmet} from "react-helmet";


//Components
import Header from '../Header/Header'
import '../../App.css'
import Constants from '../../Constants/constants';
const Footer = lazy(() => import('../Footer/Footer'));

export default function RegisterLayout(props) {
  const ContentSection = () => {
      return <Outlet />;
  }

  useEffect(() => {
    if(props?.menu != "basic_data" ){
      localStorage.removeItem(Constants.loginLocalStorageKeys.loginCurrentPage)
    }
  }, [props?.menu])
  
useEffect(()=>{
  localStorage.removeItem("isShowPaymentPopup")
},[])

  return (
    <div className="App font-segeo " id={props?.className=="forgotpass"?"fixedFooter":""}>
                  {/* <InjectScript script={scriptToInject} /> */}
              {/* <Helmet>
                {scriptToInject}
            </Helmet> */}
      <Header page={props.page}  menu={props.menu} type={props.type} isFrom={props?.isFrom}/>
      <main>
        {ContentSection()}
      </main>
      {props?.menu == "basic_data" ? <></> : <Suspense fallback={null}><Footer /></Suspense>}
    </div>
  )
}
