import React, { useContext ,useEffect, useState} from "react";
import { UserDetailsRequestSection, UserDetailsSection } from "./ProfileSection";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
import RouteSegments, { RouteHelper } from "../../Routes/RouteSegment";
import ViewMessageNav from "../ViewMessageComponents/ViewMessageNav";
import { NavListContext } from "../../Contexts/navContext";
import { UploadPhotoPopup } from "../CommonPopup";
import { CommonSuccessPopup } from "../CommonPopup";
import ConsultIcon from "../../Images/Group 225.svg"
import MessageIcon from "../../Images/Group 428.svg"
import SearchIconRed from "../../Images/Group 256.svg"
import Constants, { EditProfileMenu } from "../../Constants/constants";
import tagclose from '../../Images/tagclose.svg'
import SidePannelProfileSkeleton from "../skeletonLoader/SidePannelProfileSkeleton";
import { CardSkeletonWithSlider } from "../skeletonLoader/CardSkeletonWithSlider";
import { useTranslation } from "react-i18next";

const DashboardNav = (props) => {

  const { navList, setNavList } = useContext(NavListContext);
  const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);

  const { t: trans } = useTranslation();

  useEffect(() => {
    console.log(props?.profileDataCount,"avatar");
  }, [props.menu,props.page, props.state,props.tab,props?.data?.isAvatarPic,props?.profileDataCount]);

  const navigate = useNavigate()

  return (
    <>
     {showUploadImagePopup ? (
        <UploadPhotoPopup
          close={() => setShowUploadImagePopup(false)}
          title={trans('my_profile.upload_photo')}
          callBack={() => {

            setsuccessMessage("Image uploaded succesfully")
            setshowSuccessPopup(true)

          }}
        />
      ) : (
        <></>
      )}
       {showSuccessPopup && (

        <CommonSuccessPopup
          close={() => setshowSuccessPopup(false)}
          title={trans('photos.success')}
          content={successMessage}
        />
      )}
      {props?.data?.length == 0 ?
          <div className="block md:hidden lg:hidden section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1] my-6 bg-white" >
          <SidePannelProfileSkeleton  cards={1} menu="profile"/>
          {/* <div className="mt-4 px-3">
            <CardSkeletonWithSlider cards={3} menu={props?.menu}/>
          </div> */}
    </div>
        :
         
    <nav className="">
      <ViewMessageNav className="hidden" page="matches" menu="" />
      <div className=" md:hidden block bg-white">
        <div className="grid grid-cols-4 rounded-b-[50px] md:rounded-b-[30px] p-2  bg-[#D10A11]">
          <div className=" justify-center items-center flex col-span-2">
            <span className="relative inline-block">
              <img
                alt=""
                onClick={() => { 
                // setShowImagePopup(true);
                if (props?.data?.avatar === "Y" ) {
                  navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
                } else {
                  // setShowImagePopup(true);
                  navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath), { state: { tab: "1" } });
                }
              }}
                src={props?.data?.profile_imageurl} //"/Assets/Images/Pic11.png"
                className="rounded-[10px] w-14 h-14 md:w-12 md:h-12"
              />
              <span 
              onClick={() => {
                setShowUploadImagePopup(true)
                // navigate(RouteSegments.EDITPROFILE, { state: { tab: "3" } });
              }} 
              className="absolute top-1 right-1 inline-flex items-center justify-center p-1 px-[6px] text-[10px] font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-[#FDC300] rounded-full ">
                + 
              </span>
            </span>

            <div className="px-1 pl-2">
              <p className="text-[#FEBABA] font-segeo font-semibold text-[13px]">
                Hi {props?.data?.username},
              </p>
              <p className="text-[#FFFFFF] font-bold font-segeo text-[14px] px-0 md:px-7">
                {trans('my_profile_icon.welcome_back')}
              </p>
            </div>
          </div>
          <div className="col-span-2 pl-[1rem] mb-1 md:mb-0">
            <div className="flex">
              {props?.data?.isPaid == "Y" ? (
                <></>
              ) : (
                <>
                  <p className="text-[#575556] text-[7pt]  text-[#E9E9E9]">
                    To Contact <br />
                    <span className="text-[#575556] text-[7pt]  text-[#E9E9E9]">
                      {trans('matches.matches')}:
                    </span>
                  </p>
                </>
              )}
              <div className="pl-[0.9rem]">
                <button 
                onClick={() => {
                  if (props?.data?.isPaid === "Y") {
                    navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[6].urlPath), { state: { tab: "7" } });
                  } else {

                    navigate(RouteSegments.MEMBERSHIP_REGISTER())
                  }
                }}
                className="bg-[#FDC300] text-[#FFFFFF] text-[7pt] rounded-[5px]  p-[0.3rem] px-5">
                  {props?.data?.isPaid === "Y" ? "My Plan" : trans('matches.pay_now')}
                </button>
              </div>
            </div>
            <div className="flex  py-1">
              <img className="w-3 h-3 mt-1" src={ConsultIcon} alt="" />

              <p className=" mt-[3px] text-[7pt] px-2 font-bold font-segeo text-[#FFFFFF]">
                {/* {props?.data?.usercountrycode} {props?.data?.userphoneno} */}
                <a href={`tel:+${Constants.helpLinePhNo}`}>{Constants.helpLinePhNo}</a>
              </p>
            </div>
            <div className="flex  ">
              <img className="h-3 w-3 mt-1" src={MessageIcon} alt=""/>
              <p className=" mt-[3px] text-[7pt] px-2 font-bold font-segeo text-[#FFFFFF]">
                {/* {props?.data?.usermailid} */}
                <a href={`mailto:${Constants.helpLineMailId}`}>{Constants.helpLineMailId}</a>
              </p>
            </div>
          </div>
        </div>
        {/* <UserDetailsRequestSection data={props.data}/> */}
        <div className="px-8 py-3">
          <ul className="divide-y">
            <UserDetailsRequestSection profileDataCount={props?.profileDataCount} data={props?.data}/>
            {/* <li className="flex justify-between ">
                      <div className=" flex ">
                        <div className="py-3">
                          <img
                            className="w-5 h-5"
                            src="/Assets/Images/Group 17.svg"
                          />
                        </div>
                        <div className="px-3">
                          <span className="text-[13px] font-bold text-[#7A7A7A]">
                            Photo Request
                          </span>
                          <span className="inline-flex justify-center items-center font-bold ml-2 p-1 h-5 w-5 text-[10px] text-[#000000] bg-[#FDC300] rounded-full">
                            7
                          </span>
                          <p className="text-[11px] font-segeo text-[#9F9F9F]">
                            We Request you to Add your Photo
                          </p>
                        </div>
                      </div>
                      <div className="text-center mt-3 ">
                        <button className=" text-[#FFFFFF] px-5 text-[11px] bg-[#D10A11] p-1 rounded-[5px]">
                          Add Now
                        </button>
                      </div>
                    </li>
                    <li className="flex justify-between ">
                      <div className=" flex py-1">
                        <div className="py-3">
                          <img
                            className="w-5 h-5"
                            src="/Assets/Images/Group 174.svg"
                          />
                        </div>
                        <div className="px-3">
                          <span className="text-[13px] font-bold text-[#7A7A7A]">
                            Photo Request
                          </span>
                          <p className="text-[11px] font-segeo  text-[#9F9F9F]">
                            We Request you to Add your Photo
                          </p>
                        </div>
                      </div>
                      <div className="text-center mt-3 ">
                        <button className=" text-[#FFFFFF] px-8 text-[11px] bg-[#D10A11] p-1 rounded-[5px]">
                          Edit
                        </button>
                      </div>
                    </li>
                    <li className="flex justify-between ">
                      <div className=" flex py-1">
                        <div className="py-3">
                          <img
                            className="w-5 h-5"
                            src="/Assets/Images/Group 175.svg"
                          />
                        </div>
                        <div className="px-3">
                          <span
                            className="text-[13px] font-bold
                               text-[#7A7A7A]"
                          >
                            Photo Request
                          </span>
                          <span className="inline-flex justify-center items-center font-bold ml-2 p-1 h-5 w-5 text-[10px] text-[#000000] bg-[#FDC300] rounded-full">
                            4
                          </span>
                          <p className="text-[11px] font-segeo text-[#9F9F9F]">
                            We Request you to Add your Photo
                          </p>
                        </div>
                      </div>
                      <div className="text-center mt-3 ">
                        <button className=" text-[#FFFFFF] px-4  text-[11px] bg-[#D10A11] p-1  rounded-[5px]">
                          Read Now
                        </button>
                      </div>
                    </li>
                    <li className="flex justify-between ">
                      <div className=" flex py-1">
                        <div className="py-3">
                          <img
                            className="h-5 w-5"
                            src="/Assets/Images/Group 176.svg"
                          />
                        </div>
                        <div className="px-3">
                          <span
                            className="text-[13px] font-bold
                               text-[#7A7A7A]"
                          >
                            Photo Request
                          </span>
                          <span className="inline-flex justify-center items-center font-bold ml-2 p-1 h-5 w-5 text-[10px] text-[#000000] bg-[#FDC300] rounded-full">
                            4
                          </span>
                          <p className="text-[11px] font-segeo  text-[#9F9F9F]">
                            We Request you to Add your Photo
                          </p>
                        </div>
                      </div>
                      <div className="text-center mt-3 ">
                        <button className=" text-[#FFFFFF] px-5 text-[11px] bg-[#D10A11] p-1   rounded-[5px]">
                          Edit Now
                        </button>
                      </div>
                    </li>
                    <li className="flex justify-between">
                      <div className=" flex py-1">
                        <div className="py-3">
                          <img
                            className="w-5 h-5"
                            src="/Assets/Images/profile1.svg"
                          />
                        </div>
                        <div className="px-3">
                          <span className="text-[13px] font-bold text-[#7A7A7A]">
                            Photo Request
                          </span>
                          <span className="inline-flex justify-center items-center font-bold ml-2 p-1 h-6 w-6 text-[7px] text-[#000000] bg-[#FDC300] rounded-full">
                            100%
                          </span>
                          <p className="text-[11px] font-segeo  text-[#9F9F9F]">
                            We Request you to Add your Photo
                          </p>
                        </div>
                      </div>
                      <div className="text-center mt-3 ">
                        <button className=" text-[#FFFFFF] px-8 text-[11px] bg-[#D10A11]  p-1 rounded-[5px]">
                          Edit
                        </button>
                      </div>
                    </li> */}
          </ul>
        </div>
      </div>
      <div className="  md:hidden flex  bg-[#FEBABA] p-2 md:px-2 px-5  justify-between  items-center">
        <div className="  md:hidden  flex items-center space-x-2 md:space-x-3  pl-2 md:pl-0">
          <p className="text-[#000000] pl-2 md:pl-3 text-[9pt] font-segeo font-bold">
            You are here
          </p>
          <div className="pr-1">
            <span
              className=""
            >
              {navList.map((sample, i) =>
                <div
                  className="relative border border-[#D10A11] text-[#D10A11] text-[8pt] rounded-[10px] px-3 p-1">
                  <span
                    className="flex justify-center"
                  >
                    {sample}
                  </span>

                  <img alt="" src={tagclose}
                    className="absolute inline-flex cursor-pointer
                           top-[-2px] right-[-5px]
                           justify-center items-center  w-[0.7rem] h-[0.7rem] 
                           " />


                </div>
              )}
            </span>
          </div>
        </div>
         <Link to={RouteSegments.SEARCH}>
        <div className="md:hidden relative ">
          <div className="flex absolute  inset-y-0 left-0 items-center pl-3  pointer-events-none">
            <img className="w-4 h-4 " src={SearchIconRed} alt=""/>
            <span className="sr-only">Search icon</span>
          </div>
          <input
            type="text"
            id="search-navbar"
            className="!placeholder-[#D10A11] border-none font-segeo font-semibold  block p-1 pl-10 w-36 rounded-md text-[11px] "
            placeholder="Search"
            readOnly="readonly"
          />
        </div>
        </Link>
      </div>
    </nav>
  }
    </>
  );
};

export default DashboardNav;
