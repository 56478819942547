import React, { useState, useEffect, useContext } from "react";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { POST } from "../../Services/api_services";
import { loginFrom , EventName } from "../../Constants/constants";
import axios from "axios";
import { LoginContext } from "../../Contexts/LoginContext";
import Loader from "../Loader";
import { CommonSuccessPopup, NotificationPopup } from "../CommonPopup";
import { validateImage } from "../../Utils";
import config from "../../config";
import { useAnalytics } from "../../Hooks/usePageChange";
import PopoverImage from '../../Images/Group 943.png'
import TrustmarkImage from '../../Images/Group 947.svg'
import TrustMarkViewImage from '../../Images/Group 949.svg'
import { useTranslation } from "react-i18next";
import { getDeviceInfo, getGroupAuthId } from "../Kalyanlite/utils";
import OverflowToolTip from "../utils/OverflowTooltip";

const ProfileTrustMarkComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);

  const [isIdsProofUploading, setIsIdsProofUploading] = useState(false)
  const [isEducationProofUploading, setIsEducationProofUploading] = useState(false)
  const [isIncomeProofUploading, setIsIncomeProofUploading] = useState(false)
  const [notification, setNotification] = useState({ show: false, title: "", message: "" })
  const [successPopup, setSuccessPopup] = useState({ show: false, content: "" })

  const { t:trans } = useTranslation();

  useEffect(() => { }, [props.editProfileData]);
  const { onClickTrack } = useAnalytics()

  const callBackDelete = async (image, type) => {
    //console.log("callBackDelete", image);
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      type: type,
      imagePath: `${image}`,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.trustDoumentDeleteUrl()}`,
      request
    );
    //console.log("statusCode", statusCode);
    if (statusCode === 200) {
      setSuccessPopup({ show: true, content: trans('trust_mark.document_deleted_succesfully') })
      // alert("Trust Document Deleted");
      props.callBackReload();
    } else if (statusCode === 401) {
      logout();
    }
  };

  const showNotification = ({ description = "", title = `${trans('photos.status')}!` }) => {
    setNotification({ show: true, title: title, message: description })
  }


  const onHoroScopeFileChange = (file, type) => {
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({ title: "Invalid image", description: trans('upload_photos.height_and_width_must_be_more') })
      },
      onExtensionError: (fileType) => {
        showNotification({ title: "Invalid image", description: "Invalid extension!" })
      },
      onFileSizeError: (img) => {
        showNotification({ title: "Invalid image", description: trans('upload_photos.image_size_should_not_be_great') })
      },
      onSuccess: (img, readerStr) => {
        handleUpload(file, type)
      }
    })
  }

  const handleUpload = async (file, type) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("trustDocumentUploadFrom", getDeviceInfo());
      fileData.append("documents", file);
      fileData.append("type", type);
      fileData.append("trustrequesttypeid", 88);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("recieverprofileid", getGroupAuthId());


      // let config1 = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };

      if (type === "idproof") {
        setIsIdsProofUploading(true)
      } else if (type === "educationproof") {
        setIsEducationProofUploading(true)
      } else {
        //incomeproof
        setIsIncomeProofUploading(true)
      }



      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.trustDocumentUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      //console.log(data.data.status, data.status);

      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          // alert("Image upload succesfully");
          setSuccessPopup({ show: true, content: trans('trust_mark.document_upload_succesfully') })
          props.callBackReload();

        }
      }
    } else {
      alert("Please upload file less than 10 mb");
    }
    setIsIdsProofUploading(false)
    setIsEducationProofUploading(false)
    setIsIncomeProofUploading(false)
  };

  const TrustMarkImageUpload = (props) => {

    const isV3User = localStorage.getItem("isV3User")

    if (props.isUploading) {
      return (
        <div className="row">
          <span clasaName="mt-1">{props.cardTitle}</span>
          <span className="bg-[#FFFFFF] md:h-[6rem] lg:h-[10rem] xl:h-[12rem] md:w-[6rem] lg:w-[10rem] xl:w-[12rem] flex flex-col md:rounded-[11px] lg:rounded-[17px] ">
            <div className=" flex justify-center items-center h-full">
              <Loader loaderClassName="w-[5rem] h-[5rem]" className="h-full" />
            </div>
            <p className="text-[#575556] md:text-[8px] lg:text-[15px] xl:text-[16px] flex justify-center font-bold mb-4">
               {trans('upload_photos.uploading')}
            </p>
          </span>
        </div>
      )
    }
    return (
      <label className="row">
        <span className="mt-1"> <div className=" flex space-x-2 mb-2">
          <img
            className="w-5 h-5"
            src={props.icon} //"/Assets/Images/Group 947.svg"
            alt=""
          /> 
          <OverflowToolTip as="p" className={`md:text-[15px] lg:text-[13px]  xl:text-[16px] ${props.cardTitle=="Income Proof"?"w-[6rem]":"w-[6rem] lg:w-full"} font-semibold truncate `}  >
            {props.cardTitle}
          </OverflowToolTip>
        </div>
        </span>
        <span 
          className="bg-[#FFFFFF] md:h-[6rem] lg:h-[10rem] xl:h-[12rem] md:w-[8rem] lg:w-[10rem] xl:w-[12rem] grid md:rounded-[11px] lg:rounded-[17px] "
          onClick={() => {
            if (props.icon === TrustmarkImage) {
              onClickTrack(EventName.profileAddIdProofButton);
            } else if (props.icon === "/Assets/Images/Group 948.svg") {
              onClickTrack(EventName.profileAddEducationProofButton);
            } else if (props.icon === TrustMarkViewImage) {
              onClickTrack(EventName.profileAddIncomeProofButton);
            }
          }}
        >
          <div className=" flex justify-center items-center ">
            <img
              className=" md:w-[3rem] lg:w-[5rem]"
              src={props.icon} //"/Assets/Images/Group 947.svg"
              alt=""
            />
          </div>
          <p className={`${isV3User ?"text-[#575556] md:text-[8px] lg:text-[15px] xl:text-[15px] flex justify-center font-bold items-center text-center":"text-[#575556] md:text-[8px] lg:text-[15px] xl:text-[16px] flex justify-center font-bold"} ${props?.type == "educationproof" ? "pt-4" : ""}`}>
            {props.title}
          </p>
        </span>
        <input
          style={{ display: "none" }}
          type="file"
          onChange={(event) => {
            // //console.log("file", URL.createObjectURL(event.target.files[0]));
            if (event.target.files[0]) {
              onHoroScopeFileChange(event.target.files[0], props.type);
            }
          }}
          accept="image/jpeg, image/png"
        />
        {/* <img
          src={PopoverImage}
          className=" md:w-[0.6rem] lg:w-[1rem] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
        /> */}
      </label>
    );
  };

  const TrustMarkImageView = (props) => {
    const [showDropdown, setDropdown] = useState(false);
    //console.log("props222", props);
    return (
      <div className="row">

        <div className=" mb-2 flex space-x-2">
          <img
            className=" w-5 h-5 lg:w-5 lg:h-5 md:w-4 md:h-4"
            src={props.icon}
            alt=""
          /> <span className="xl:text-[16px] lg:text-[13px] whitespace-nowrap md:text-[13px] font-semibold">{props.type !== undefined ? props.type : ''}</span>
        </div>

        <div className="">
          <span className="bg-[#FFFFFF] flex items-center justify-center md:rounded-[11px]  lg:rounded-[17px]">
            <img
              className="object-cover md:h-[6rem] lg:h-[10rem] xl:h-[12rem] md:w-[8rem] lg:w-[10rem] xl:w-[12rem] grid md:rounded-[11px]  lg:rounded-[17px]"
              src={props.image}
              alt=""
            />
          </span>
          <img
            onClick={() => setDropdown(!showDropdown)}
            src={PopoverImage}
            className={` float-right relative md:top-[-5rem]  md:w-[1rem] md:h-[1rem] ${isV3User ?"xl:top-[-11rem] lg:top-[-9rem]":"lg:top-[-11rem]"} lg:top-[-11rem] right-[1rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  `}
            alt=""
          />
          <DropDown isShow={showDropdown} callBackDelete={props.callBackDelete} />
        </div>
      </div>
    );
  };
  const TrustMarkImageUploadMobile = (props) => {

    if (props.isUploading) {
      return (
        <div className="row justify-center">
          <span clasaName="mt-1">{props.cardTitle}</span>
          <span className="bg-[#FFFFFF] h-[13rem] w-[17rem] flex flex-col rounded-[11px]">
            <div className=" flex justify-center items-center h-full">
              <Loader loaderClassName="w-[5rem] h-[5rem]" className="h-full" />
            </div>
            <p className="text-[#575556] md:text-[8px] lg:text-[15px] xl:text-[16px] flex justify-center font-bold mb-4 text-center">
               {trans('upload_photos.uploading')}
            </p>
          </span>
        </div>
      )
    }
    return (
      <label className=" relative m-10">
        <div className="row justify-center">
          <div className="mb-2 space-x-2 flex">
            <img
              className="w-5 h-5"
              src={props.icon} //"/Assets/Images/Group 947.svg"
              alt=""
            /><span className="font-semibold">{props.cardTitle}</span>
          </div>
          <div className="flex justify-center bg-[#FFFFFF] h-[13rem] rounded-[11px] md:h-[6rem] lg:h-[10rem] xl:h-[12rem] md:w-[6rem] lg:w-[10rem] xl:w-[12rem] grid md:rounded-[11px] lg:rounded-[17px] ">
            <div className="grid space-y-2  justify-center items-center  ">
              <img
                className="max-w-full max-h-full md:w-[3rem] lg:w-[5rem]"
                src={props.icon} //"/Assets/Images/Group 947.svg"
                alt=""
              />
            </div>
            <p className="text-[#575556] md:text-[8px] text-center lg:text-[15px] xl:text-[16px] flex justify-center font-bold ">
              {props.title}
            </p>
          </div>
          <input
            style={{ display: "none" }}
            type="file"
            onChange={(event) => {
              // //console.log("file", URL.createObjectURL(event.target.files[0]));
              if (event.target.files[0]) {
                onHoroScopeFileChange(event.target.files[0], props.type);
              }
            }}
            accept="image/jpeg, image/png"
          />
          {/* <img
          src={PopoverImage}
          className=" md:w-[0.6rem] lg:w-[1rem] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
        /> */}
        </div>
      </label>
    );
  };

  const TrustMarkImageViewMobile = (props) => {
    const [showDropdown, setDropdown] = useState(false);
    return (
      <div className="row ">

        <div className="pt-1 mb-2 flex space-x-2">
          <img
            className="w-5 h-5"
            src={props.icon}
            alt=""
          /> <span className="md:text-[15px] lg:text-[16px] font-semibold">{props.type !== undefined ? props.type : ''}</span>
        </div>

        <div className=" ">
          <div className="bg-[#FFFFFF] h-[13rem]  rounded-[11px]  md:rounded-[11px]  lg:rounded-[17px]">
            <span className="relative  h-[13rem]">

              <img
                className=" object-cover h-[13rem]  w-[19rem] md:h-[6rem] lg:h-[10rem] xl:h-[12rem] md:w-[6rem] lg:w-[10rem] xl:w-[12rem] rounded-[11px] grid md:rounded-[11px]  lg:rounded-[17px]"
                src={props.image}
                alt=""
              />

              <img
                onClick={() => setDropdown(!showDropdown)}
                src={PopoverImage}
                className="w-[0.7rem] z-10 md:w-[0.6rem] lg:w-[1rem] top-[13px] right-[15px] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[1.3rem] lg:right-[1rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
                alt=""
              />
              <DropDown isShow={showDropdown} callBackDelete={props.callBackDelete} />
            </span>
          </div>
        </div>
      </div>
    );
  };
  const DropDown = (props) => {
    return (
      <div
        id="dropdown"
        className={`${props.isShow ? "" : "hidden"
          } z-10 relative float-right mr-2 md:mr-0 top-[-11.7rem] md:top-[-4.3rem] lg:top-[-8.4rem]  xl:top-[-10rem] bg-white divide-y divide-gray-100 rounded-lg shadow  dark:bg-gray-700`}
      >
        <ul
          className="py-1 text-[12px] md:text-[10px] lg:text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDefaultButton"
        >
          <li>
            <button
              onClick={() => {
                props.callBackDelete();
              }}

              className="block cursor-pointer px-2 py-1 md:py-0 lg:py-1 hover:bg-gray-100 "
            >
              {trans('my_profile.remove_proof')}
            </button>
          </li>
        </ul>
      </div>
    );
  };

  const closeSuccessPopup = () => {
    setSuccessPopup({ show: false, content: "" })
  }

  const closeNotification = () => {
    setNotification({ show: false, message: "", title: "" })
  }

  const isV3User = localStorage.getItem("isV3User")

  return (
    <div>
      {
        notification.show
          ? <NotificationPopup close={closeNotification} title={notification.title} content={notification.message} />
          : null
      }
      {successPopup.show && (
        <CommonSuccessPopup
          close={closeSuccessPopup}
          title={trans('photos.success')}
          content={successPopup.content}
        />
      )}
      <div className="hidden md:block">
        <div className="flex justify-between">
          <p className={`${isV3User ?"text-[#000000] text-[16px] mt-[0.4rem] font-semibold  ":" text-[#000000] text-[16px] font-semibold "}`}>
          {trans('my_profile.trust_mark_file_upload')}
          </p>
        </div>
        <div className="mt-[1rem]  bg-[#F1F1F1] rounded-[14px] md:w-[30rem] lg:w-full ">
          <div className={`${isV3User ? "p-[1rem] pt-[1.5rem] pb-[3rem] md:space-x-6 flex md:justify-between md:h-[12rem] xl:h-[17rem] lg:h-[15rem]":"p-[1rem] pt-[1.5rem] pb-[3rem] md:space-x-6 flex md:justify-between md:h-[12rem] lg:h-[20rem]"}`}>
            {props?.editProfileData?.idproof ? (
              <TrustMarkImageView
                image={props?.editProfileData?.idproof}
                icon={TrustmarkImage}
                type={trans('my_profile.id_proof')}
                callBackDelete={() => {
                  callBackDelete(props?.editProfileData?.idproof, "idproof");
                }}
              />
            ) : (
              <TrustMarkImageUpload
                icon={TrustmarkImage}
                title={trans('my_profile.id_proof')}
                type={"idproof"}
                cardTitle={trans('my_profile.id_proof')}
                isUploading={isIdsProofUploading}
              />
            )}
            {props?.editProfileData?.eduproof ? (
              <TrustMarkImageView
                image={props?.editProfileData?.eduproof}
                icon={"/Assets/Images/Group 948.svg"}
                type={trans('my_profile.education_proof')}
                callBackDelete={() => {
                  callBackDelete(
                    props?.editProfileData?.eduproof,
                    "educationproof"
                  );
                }}
              />
            ) : (
              <TrustMarkImageUpload
                icon={"/Assets/Images/Group 948.svg"}
                title={trans('my_profile.add_education_proof')}
                type={"educationproof"}
                cardTitle={trans('my_profile.education_proof')}
                isUploading={isEducationProofUploading}
              />
            )}
            {props?.editProfileData?.incomeproof ? (
              <TrustMarkImageView
                image={props?.editProfileData?.incomeproof}
                icon={TrustMarkViewImage}
                type={trans('my_profile.income_proof')}
                callBackDelete={() => {
                  callBackDelete(
                    props?.editProfileData?.incomeproof,
                    "incomeproof"
                  );
                }}
              />
            ) : (
              <TrustMarkImageUpload
                icon={TrustMarkViewImage}
                title={trans('my_profile.add_income_proof')}
                type={"incomeproof"}
                cardTitle={trans('my_profile.income_proof')}
                isUploading={isIncomeProofUploading}
              />
            )}
            {/* <span className=" relative inline-block">
              <span className="bg-[#FFFFFF] md:h-[6rem] lg:h-[10rem] xl:h-[12rem] md:w-[6rem] lg:w-[10rem] xl:w-[12rem] grid md:rounded-[11px] lg:rounded-[17px] ">
                <div className=" flex justify-center items-center ">
                  <img
                    className=" md:w-[3rem] lg:w-[5rem]"
                    src="/Assets/Images/Group 947.svg"
                  />
                </div>
                <p className=" text-[#575556] md:text-[8px] lg:text-[15px] xl:text-[16px] flex justify-center font-bold ">
                  Add ID Proof
                </p>
              </span>
              <img
                src={PopoverImage}
                className=" md:w-[0.6rem] lg:w-[1rem] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span> */}
            {/* 
            <span className=" relative inline-block">
              <span className="bg-[#FFFFFF] md:h-[6rem] lg:h-[10rem] xl:h-[12rem] md:w-[6rem] lg:w-[10rem] xl:w-[12rem] grid md:rounded-[11px] lg:rounded-[17px]">
                <div className=" flex justify-center items-center ">
                  <img
                    className=" md:w-[3rem] lg:w-[5rem]"
                    src="/Assets/Images/Group 948.svg"
                  />
                </div>
                <p className=" text-[#575556] md:text-[8px] lg:text-[15px] xl:text-[16px] flex justify-center font-bold ">
                  Add Education Proof
                </p>
              </span>
              <img
                src={PopoverImage}
                className=" md:w-[0.6rem] lg:w-[1rem] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span> */}

            {/* <span className=" relative inline-block">
              <span className="bg-[#FFFFFF] md:h-[6rem] lg:h-[10rem] xl:h-[12rem] md:w-[6rem] lg:w-[10rem] xl:w-[12rem] grid md:rounded-[11px] lg:rounded-[17px]">
                <div className="flex justify-center items-center">
                  <img
                    className=" md:w-[3rem] lg:w-[5rem]"
                    src=TrustMarkViewImage
                  />
                </div>
                <p className=" text-[#575556] md:text-[8px] lg:text-[15px] xl:text-[16px] flex justify-center font-bold ">
                  Add Income Proof
                </p>
              </span>
              <img
                src={PopoverImage}
                className=" md:w-[0.6rem] lg:w-[1rem] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span> */}
          </div>
        </div>
        {isV3User ?
                <div className="mt-[1rem]">
                <p className="text-[#575556] md:text-[8px] lg:text-[16px] flex justify-start font-bold ">
                  {trans('my_profile.note')}
                </p>
                <p className="text-[#575556] md:text-[8px] lg:text-[14px] flex justify-start  font-semibold opacity-[0.8] ">
                {trans('my_profile.documents_will_be_visible_only')}
                </p>
                <div className=" md:mt-[1rem] lg:mt-[2rem] text-[#575556] md:text-[8px] lg:text-[14px]  justify-start font-semibold opacity-[0.8] ">
                  <p>1. {trans('my_profile.any_governmentissued_id_cards')}</p>
                  <p>
                    2. {trans('my_profile.copy_of_your_latest_education')}
                  </p>
                  <p>
                    3. {trans('my_profile.copy_of_your_latest_employment')}
                  </p>
                  <p>4. {trans('my_profile.png_jpg_gif_jpeg_less_than_10m')}</p>
                </div>
              </div>
              :
        <div className="mt-[1rem]">
          <p className="text-[#575556] md:text-[8px] lg:text-[16px] flex justify-start font-bold ">
            {trans('my_profile.note')}
          </p>
          <p className="text-[#575556] md:text-[8px] lg:text-[16px] flex justify-start  font-semibold opacity-[0.8] ">
          {trans('my_profile.documents_will_be_visible_only')}
          </p>
          <div className=" md:mt-[1rem] lg:mt-[2rem] text-[#575556] md:text-[8px] lg:text-[16px]  justify-start font-semibold opacity-[0.8] ">
            <p>1. {trans('my_profile.any_governmentissued_id_cards')}</p>
            <p>
              2. {trans('my_profile.copy_of_your_latest_education')}
            </p>
            <p>
              3. {trans('my_profile.copy_of_your_latest_employment')}
            </p>
            <p>4. {trans('my_profile.png_jpg_gif_jpeg_less_than_10m')}</p>
          </div>
        </div>
        }
      </div>
      <div className="block md:hidden">
        <div className="flex justify-between">
          <p className=" text-[#000000] text-[16px] mt-[1rem] font-semibold mx-[1rem] ">
          {trans('my_profile.trust_mark_file_upload')}
          </p>
        </div>
        <div className="mt-[1rem] mx-[1rem] bg-[#F1F1F1] rounded-[14px] ">
          <div className="p-[1rem] pt-[1.5rem] pb-[3rem] space-y-5 justify-between  px-7">
            {props?.editProfileData?.idproof ? (
              <TrustMarkImageViewMobile
                image={props?.editProfileData?.idproof}
                icon={TrustmarkImage}
                type={trans('my_profile.id_proof')}
                callBackDelete={() => {
                  callBackDelete(props?.editProfileData?.idproof, "idproof");
                }}
              />
            ) : (
              <TrustMarkImageUploadMobile
                icon={TrustmarkImage}
                title={trans('my_profile.add_id_proof')}
                type={"idproof"}
                cardTitle={trans('my_profile.id_proof')}
                isUploading={isIdsProofUploading}
              />
            )}
            {props?.editProfileData?.eduproof ? (
              <TrustMarkImageViewMobile
                image={props?.editProfileData?.eduproof}
                type={trans('my_profile.education_proof')}
                icon={"/Assets/Images/Group 948.svg"}
                callBackDelete={() => {
                  callBackDelete(
                    props?.editProfileData?.eduproof,
                    "educationproof"
                  );
                }}
              />
            ) : (
              <TrustMarkImageUploadMobile
                icon={"/Assets/Images/Group 948.svg"}
                title={trans('my_profile.add_education_proof')}
                type={"educationproof"}
                cardTitle={trans('my_profile.education_proof')}
                isUploading={isEducationProofUploading}
              />
            )}
            {props?.editProfileData?.incomeproof ? (
              <TrustMarkImageViewMobile
                image={props?.editProfileData?.incomeproof}
                type={trans('my_profile.income_proof')}
                icon={TrustMarkViewImage}
                callBackDelete={() => {
                  callBackDelete(
                    props?.editProfileData?.incomeproof,
                    "incomeproof"
                  );
                }}
              />
            ) : (
              <TrustMarkImageUploadMobile
                icon={TrustMarkViewImage}
                title={trans('my_profile.add_income_proof')}
                type={"incomeproof"}
                cardTitle={trans('my_profile.income_proof')}
                isUploading={isIncomeProofUploading}
              />
            )}

            {/* <span className=" relative ">
              <span className="bg-[#FFFFFF] h-[8rem] rounded-[11px] md:h-[6rem] lg:h-[10rem] xl:h-[12rem] md:w-[6rem] lg:w-[10rem] xl:w-[12rem] grid md:rounded-[11px] lg:rounded-[17px] ">
                <div className=" grid space-y-2  justify-center items-center ">
                  <img
                    className=" w-[3rem] md:w-[3rem] lg:w-[5rem]"
                    src="/Assets/Images/Group 947.svg"
                  />
                </div>
                <p className=" text-[#575556] md:text-[8px] lg:text-[15px] xl:text-[16px] flex justify-center font-bold ">
                  Add ID Proof
                </p>
              </span>
              <img
                src={PopoverImage}
                className="w-[0.7rem] md:w-[0.6rem] lg:w-[1rem] top-[25px] right-[25px] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span>

            <span className=" relative  ">
              <span className="bg-[#FFFFFF] mt-[1rem] h-[8rem] rounded-[11px] lg:h-[10rem] xl:h-[12rem] md:w-[6rem] lg:w-[10rem] xl:w-[12rem] grid md:rounded-[11px] lg:rounded-[17px]">
                <div className=" grid justify-center items-center ">
                  <img
                    className=" w-[3rem] lg:w-[5rem]  "
                    src="/Assets/Images/Group 948.svg"
                  />
                </div>
                <p className=" text-[#575556] md:text-[8px] lg:text-[15px] xl:text-[16px] flex justify-center font-bold ">
                  Add Education Proof
                </p>
              </span>
              <img
                src={PopoverImage}
                className=" w-[0.7rem] md:w-[0.6rem] lg:w-[1rem] top-[35px] right-[25px] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span>

            <span className=" relative mt-2 ">
              <span className="bg-[#FFFFFF] h-[8rem] mt-[1rem] rounded-[11px] lg:h-[10rem] xl:h-[12rem] md:w-[6rem] lg:w-[10rem] xl:w-[12rem] grid md:rounded-[11px] lg:rounded-[17px]">
                <div className=" grid justify-center items-center">
                  <img
                    className=" w-[3rem] lg:w-[5rem] "
                    src="/Assets/Images/Group 949.svg"
                  />
                </div>
                <p className=" text-[#575556] md:text-[8px] lg:text-[15px] xl:text-[16px] flex justify-center font-bold ">
                  Add Income Proof
                </p>
              </span>
              <img
                src={PopoverImage}
                className=" w-[0.7rem]  md:w-[0.6rem] lg:w-[1rem] top-[35px] right-[25px] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span> */}
          </div>
        </div>
        <div className="mt-[1rem] mb-[8rem] mx-[1rem]">
          <p className="text-[#575556] text-[11px] lg:text-[16px] flex justify-start font-bold ">
          {trans('my_profile.note')}
          </p>
          <p className="text-[#575556] text-[11px] lg:text-[16px] flex justify-start  font-semibold opacity-[0.8] ">
          {trans('my_profile.documents_will_be_visible_only')}
          </p>
          <div className=" mt-[0.5rem] lg:mt-[2rem] text-[#575556] text-[10px] lg:text-[16px]  justify-start font-semibold opacity-[0.8] ">
            <p>1. {trans('my_profile.any_governmentissued_id_cards')}</p>
            <p>
              2. {trans('my_profile.copy_of_your_latest_education')}
            </p>
            <p>
              3. {trans('my_profile.copy_of_your_latest_employment')}
            </p>
            <p>4. {trans('my_profile.png_jpg_gif_jpeg_less_than_10m')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileTrustMarkComponent;
