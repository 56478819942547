import React, { useEffect, useState, useContext } from "react";

import { HelpProfileAccordianComponent } from "../../Components/ViewMessageComponents/AccordianComponents";
import "../../index.css";

import RouteSegments from "../../Routes/RouteSegment";
import { Link } from 'react-router-dom';
// images
import DeletephotoStep1img from '../../Images/DeletephotoStep1img.png'
import DeletephotoStep2img from '../../Images/DeletephotoStep2img.png'
import DeletephotoStep3img from '../../Images/DeletephotoStep3img.png'
import DeletephotoStep4img from '../../Images/DeletephotoStep4img.png'
import DeletephotoStep6img from '../../Images/DeletephotoStep6img.png'
import DeletephotoStep7img from '../../Images/DeletephotoStep7img.png'
import { useTranslation } from "react-i18next";

const HelpPage = (props) => {

    const { t: trans } = useTranslation();
    const amount1 = 4890;
  const number1 = 25;
  const amount2 = 5490;
  const number2 = 100;
  const amount3 = 8490;
  const number3 = 150;
    return (
        <>


            <div className="p-3">
                <div>
                    <p className="text-[#575556] font-bold text-[17px] pl-2"> {trans('help.faq')}</p>

                </div>

                <div className=" mx-auto mt-3">
                    <div
                    //  activeclassName= ' focus:outline-none'
                    //  bordeclassName=' border-radiums:10px; border-width:1px; border-color:#707070 '

                    // className=""
                    >
                        <div className="rounded-[7px] ">
                            <HelpProfileAccordianComponent


                                title={trans('help.how_to_login')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2 font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={true}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            {trans('help.visit_wwwkalyanmatrimonycom')}
                                        </li>
                                        <li>
                                            {trans('help.enter_the_registered_email_id')}
                                        </li>
                                        <li>
                                            {trans('help.click_on_the_sign_in_option_on')}
                                        </li>
                                    </ul>



                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={trans('help.forgot_password')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            {trans('help.please_select_the_forgot_passw')}
                                        </li>
                                        <li>
                                            {trans('help.a_link_to_reset_the_password_w')}
                                        </li>
                                        <li>
                                            {trans('help.click_on_the_link_and_enter_th')}
                                        </li>
                                        <li>
                                            {trans('help.now_you_can_use_the_new_passwo')}
                                        </li>
                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={trans('help.how_to_edit_profile')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            {trans('help.login_to_your_profile_with_you')}
                                        </li>
                                        <li>
                                            {trans('help.enter_the_registered_email_id')} 
                                        </li>
                                        <li>
                                            {trans('help.enter_the_registered_email_id')} 
                                        </li>
                                        <li>
                                            {trans('help.click_on_the_pen_symbol_that_a')}
                                        </li>
                                        <li>
                                            {trans('help.make_the_necessary_changes_and')}
                                        </li>
                                        <li>
                                            {trans('help.modified_content_will_be_displ')}
                                        </li>
                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={trans('help.how_to_search_profile')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            {trans('help.login_to_your_profile_with_you')}
                                        </li>
                                        <li>
                                            {trans('help.after_loggingin_please_select')}
                                        </li>
                                        <li>
                                            {trans('help.your_partner_expectations_crit')}
                                        </li>
                                        <li>
                                            {trans('help.make_the_necessary_changes_if')}
                                        </li>
                                        <li>
                                            {trans('help.profiles_that_match_the_search')}
                                        </li>
                                        <li>
                                            {trans('help.if_you_wish_to_filter_the_prof')}
                                        </li>
                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={trans('help.how_to_send_messages')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <p className="text-[15px] font-bold px-3 py-3">{trans('help.free_member')}</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            {trans('help.login_to_your_profile_with_you')}
                                        </li>
                                        <li>
                                            {trans('help.once_you_login_to_your_profile')}
                                        </li>
                                        <li>
                                            {trans('help.click_the_send_message_option')}
                                        </li>
                                        <li>
                                            {trans('help.you_will_be_notified_once_the')}
                                        </li>

                                    </ul>
                                    <p className="text-[15px] font-bold px-3">{trans('help.paid_member')}</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            {trans('help.login_to_your_profile_with_you')}
                                        </li>
                                        <li>
                                            {trans('help.after_you_login_to_your_profil')}
                                        </li>
                                        <li>
                                            {trans('help.click_the_send_message_option')}
                                        </li>
                                        <li>
                                            {trans('help.for_a_quick_response_we_sugges')}
                                        </li>
                                        <li>
                                            {trans('help.you_will_be_notified_once_the')}
                                        </li>

                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title= {trans('help.how_to_reply_to_messages')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            {trans('help.login_to_your_profile_with_you')}
                                        </li>
                                        <li>
                                        {trans('help.once_you_login_to_your_profile')}

                                            {/* Once you login to your profile, please select the "Inbox" option from the left navigation menu */}
                                        </li>
                                        <li>
                                        {trans('help.unread_messages_will_be_displa')}
                                             </li>
                                        <li>
                                            {trans('help.select_the_message_and_click_t')}
                                        </li>

                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={trans('help.how_to_add_photo')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            {trans('help.login_to_your_profile_with_you')}
                                        </li>

                                        <li>
                                            {trans('help.once_you_login_to_your_profile')}
                                        </li>
                                        <li>
                                            {trans('help.select_my_profile_in_the_edit')}
                                            {/* Select "My Profile" in the Edit Profile section and choose the option "Photos" */}
                                        </li>
                                        <li>
                                            {trans('help.select_the_photo_you_wish_to_d')}
                                        </li>
                                        <li>
                                            {trans('help.please_add_images_that_are_les')}
                                        </li>

                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={trans('help.how_to_add_horoscope')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                        {trans('help.login_to_your_profile_with_you')}
                                        </li>
                                        <li>
                                        {trans('help.once_you_login_to_your_profile')}
                                        </li>
                                        <li>
                                            {trans('help.enter_the_date_of_birth_place')}
                                        </li>
                                        <li>
                                        {trans('help.if_you_do_not_wish_to_generate')}
                                        </li>
                                        <li>
                                            {trans('help.please_add_images_that_are_les')}
                                        </li>

                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={trans('help.how_to_add_trust_document')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            {trans('help.login_to_your_profile_with_you')}
                                        </li>

                                        <li>
                                            {trans('help.once_you_login_to_your_profile')}
                                        </li>
                                        <li>
                                            {trans('help.select_my_profile_in_the_edit')}
                                        </li>
                                        <li>
                                        {trans('help.you_can_add_id_proof_education')}
                                        </li>
                                        <li>
                                        {trans('help.documents_will_be_visible_only')}
                                        </li>
                                        <li>
                                        {trans('help.please_add_an_image_that_is_le')}
                                        </li>
                                        <li>
                                        {trans('help.following_are_the_list_of_acce')}
                                            <ol className="mx-9 mt-2 space-y-1 list-decimal list-outside">
                                                <li>{trans('help.any_governmentissued_id_cards')}</li>
                                                <li>{trans('help.copy_of_your_latest_education')}</li>
                                                <li>{trans('help.copy_of_your_latest_employment')}</li>
                                            </ol>
                                        </li>

                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={trans('help.what_are_the_packages_availabl')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <p className="text-[#7A7A7A] text-[13px] px-3 py-3">
                                     {trans('help.we_have_two_major_types_of_pac',{amount:4890})}
                                    </p>
                                    <p className="text-[15px] font-bold px-3">{trans('help.unlimited_validity_packages')}</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                        {trans('help.rsamount__view_number_phone_nu', {amount:4890, number:25})}


                                        </li>
                                        <li>
                                        {trans('help.rsamount__view_number_phone_nu', {amount:5490, number:100})}
                                        </li>
                                        <li>
                                        {trans('help.rsamount__view_number_phone_nu', {amount:8490, number:150})}
                                        </li>

                                    </ul>
                                    <p className="text-[15px] font-bold px-3">{trans('help.unlimited_phone_numbers')}</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                        {trans('help.rsamount__day_number_validity', {amount:4890   ,day_number:50})}


                                        </li>
                                        <li>
                                        {trans('help.rsamount__day_number_validity', {amount:5490   ,day_number:100})}
                                        </li>
                                        <li>
                                        {trans('help.rsamount__day_number_validity', {amount:8490   ,day_number:200})}
                                        </li>

                                    </ul>
                                    <p className="text-[15px] font-bold px-3">{trans('help.add_on_packages')}</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            {trans('help.astro_match_reports_number_mat',{number:25, amount:500})}
                                            {/* Astro Match Reports (25 matched profile) - Rs.500 */}

                                        </li>
                                    </ul>

                                    <p className="text-[15px] font-bold px-3">{trans('help.truly_unlimited')}</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            {trans('help.rsamount_unlimiteddays_phone_n',{amount:12790})}
                                            {/* Rs.12790 Unlimited*(Days, Phone Numbers, Chat with online matches, Send Message to Matches, View Horoscopes) */}
                                        </li>
                                    </ul>

                                    <div className=" px-3 mb-4 flex text-[13px] text-[#7A7A7A] dark:text-gray-400 ">

                                        <p> <Link to={RouteSegments.MEMBERSHIP_REGISTER()} className="text-[#297CFE] hover:underline cursor-pointer">
                                        {trans('help.click_here_to_make_a_payment_a')}
                                        </Link>
                                        {/* <span className="pl-1 "> to make a payment and upgrade your profile to paid services.</span> */}
                                        </p>

                                    </div>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>

                <div className=" mx-auto mt-3">
                    <div>
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={trans('help.how_to_buy_muhurat_wedding_jew')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}
                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400" type="a">
                                        <li>
                                            {trans('help.most_preferred_method_get_stor')}
                                        </li>

                                        <li>
                                            
                                           
                                             <a href="https://stores.kalyanjewellers.net/" className="underline" target="_blank">
                                             {trans('help.visit_kalyan_jewellers_store_n')} </a>
                                        </li>
                                        <li>
                                            <a href="https://www.kalyanjewellers.net/" className="underline" target="_blank">{trans('help.visit_online_kalyan_jewellers')}</a> 
                                        </li>
                                    </ul>
                                </div>
                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>

                <div className=" mx-auto mt-3">
                    <div>
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={trans('help.delete_photo')}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}
                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-4 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400" type="a">
                                    <div className=" font-medium text-[#575556] text-[12px] md:text-[14px] lg:text-[16px] ">
               
                <p className=" font-bold text-[15px] md:text-[16px] lg:text-[16px] ">
                   {trans('help.how_to_delete_photos')}
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[1rem]">
                    1. {trans('help.login_to_your_profile_with_you')}
                    <img src = {DeletephotoStep1img} className='mt-[1rem]' alt=""/>
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]">
                    2. {trans('help.once_you_login_to_your_profile')}
                    <img src = {DeletephotoStep2img} className='mt-[1rem]'alt=""/>
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]" >
                    3. {trans('help.once_the_user_clicks_on_the_my')}
                    <img src = {DeletephotoStep3img} className='mt-[1rem]'alt=""/>
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]">
                    4. {trans('help.in_the_my_profile_section_choo')}
                    <img src = {DeletephotoStep4img} className='mt-[1rem]'alt=""/>
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]">
                    5. {trans('help.all_the_added_photos_by_the_us')}
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]" >
                    6. {trans('help.if_the_user_clicks_on_three_do')}
                 
                    <p className="text-[#7A7A7A] text-[13px] mt-[1rem] ml-[2rem]" >
                        i){trans('help.set_as_profile')}
                    </p> 
                    <p className="text-[#7A7A7A] text-[13px] mt-[1rem] ml-[2rem]" >
                        ii){trans('help.delete_option_present_inside')}
                    </p>
                    <img src = {DeletephotoStep6img} className='mt-[1rem]' alt="" />
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]">
                    7. {trans('help.if_the_user_clicks_on_delete_t')}
                    <img src = {DeletephotoStep7img} className='mt-[1rem]' alt="" />
                </p>
                
                
            </div>
                                    </ul>
                                </div>
                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default HelpPage;