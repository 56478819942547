import React, { useEffect, useState } from "react";
import { SettingsRadioButton } from "../Edit Profile/SettingsComponents/SettingsUtills";
import { CommonCheckButton, CommonRadioButton } from "./DashboardUtills";
import { useFormik } from "formik";
import { NavLink } from "react-router-dom";
import RouteSegments from "../../Routes/RouteSegment";
import { MessageAccordianComponent } from "../ViewMessageComponents/AccordianComponents";
import CloseIcon from "../../Images/Group 761@2x.png";
import LockIcon from "../../Images/lockicon.svg";
import ProfilelastonlineImage from "../../Images/Group 619.svg";
import MessageImage from "../../Images/Group 497.svg";

import * as Yup from "yup";
import Constants from "../../Constants/constants";
import { dateFormat } from "../../Utils";
import { useTranslation } from "react-i18next";
const SendMessagePopup = (props) => {
  console.log("profileData", props.profileData);
  const [radioaMessage, setradioMessage] = useState([]);
  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth <= 767);

  const { t: trans } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallDevice(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const messageContent1 = props?.dashboard1Data?.msgcontent1;

    const parts = messageContent1?.split("If");
    const result = parts?.join("\n If");

    //console.log(result,"result1");

    let radio = [
      {
        id: "1",
        message: result,
        isSelected: props?.dashboard1Data?.isPaid == "N" ?? true,
      },
      {
        id: "2",
        message: props?.dashboard1Data?.msgcontent2,
        isSelected: false,
      },
      {
        id: "3",
        message: props?.dashboard1Data?.msgcontent3,
        isSelected: false,
      },
      {
        id: "4",
        message: props?.dashboard1Data?.msgcontent4,
        isSelected: false,
      },
    ];
    setradioMessage(radio);
  }, [props?.dashboard1Data]);
  useEffect(() => {}, [radioaMessage]);
  const formik = useFormik({
    initialValues: {
      message:
        props?.dashboard1Data?.isPaid == "N"
          ? props?.dashboard1Data?.msgcontent1
          : "",
      sendSms: "N",
      requestphotocheck:props?.profileData?.isAvatarPic === true && props?.profileData?.profilerequestcheck === false ? "Y":null,
      requestparentcheck:((props.profileData?.profileparentsnumber == null || props.profileData?.profileparentsnumber == "") && props?.profileData?.parentnumberrequestcheck === false)? "Y":null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      message: Yup.string().required("Message should not be blank"),
    }),

    onSubmit: (values) => {
      console.log("message", values);
      // return false;

      if (
        props?.dashboard1Data?.isPaid === "N" &&
        props?.profileData?.profilealreadyContected === "Y"
      ) {
        props.onButtonCallback(props?.profileData?.profileid, values);
        props.close();
        // return false;
      } else if (props?.dashboard1Data?.status === "pending") {
        //console.log(props?.dashboard1Data?.status, "yes")
        return false;
      } else {
        props.onButtonCallback(props?.profileData?.profileid, values);
        props.close();
      }
    },
  });
  return (
    <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      {
      // props?.profileData?.profilealreadyContected === "Y" &&
      // props?.dashboard1Data?.isPaid === "N" ? (
      //   <div className="relative w-auto my-6 mx-6">
      //     <div className="">
      //       <button
      //         className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
      //         onClick={() => {
      //           props.close();
      //         }}
      //       >
      //         <img className="h-5 w-5 " src={CloseIcon} />
      //       </button>
      //     </div>

      //     <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
      //       <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
      //         <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
      //           {props.title}
      //         </h3>
      //       </div>
      //       <div className="relative p-6 flex-auto w-[80vw] md:w-[70vw] lg:w-[50vw]">
      //         <div className="flex flex-row  justify-start items-center border-b pb-2">
      //           <div className="relative justify-start items-center">
      //             <img
      //               src={props?.profileData?.profileurl} //"/Assets/Images/profile1.png"
      //               alt=""
      //               className={`${
      //                 props?.profileData?.profileblurPhoto === "Y"
      //                   ? "blur-[2px]"
      //                   : ""
      //               } w-16 h-16 md:w-20 md:h-20 rounded-[50%]`}
      //             />
      //             {props?.profileData?.profilefromPasswordProtected === "Y" && (
      //               <div className="absolute top-[-0.4rem]  flex justify-center items-center h-[100%] w-[100%]  cursor-pointer">
      //                 <img
      //                   className="h-[2rem] w-[2rem] "
      //                   src={LockIcon}
      //                   alt="Person"
      //                 />
      //               </div>
      //             )}
      //           </div>
      //           <div className="flex flex-col ml-2">
      //             <div className="flex flex-row item-center justify-start">
      //               <span className="text-[#575556]  md:text-[16px] lg:text-[16px] text-[10pt]  font-bold">
      //                 {props?.profileData?.profilename} |{" "}
      //                 <span className="text-[#575556]  md:text-[16px] lg:text-[16px] text-[10pt] font-normal">
      //                   {props?.profileData?.smProfileId} |{" "}
      //                 </span>
      //               </span>
      //               {props?.profileData?.profilelastOnline === "Online Now" ? (
      //                 <span className="flex ml-1 justify-center items-center">
      //                   <img
      //                     className=" w-4 h-4 md:w-2 md:h-2 lg:w-3 lg:h-3"
      //                     src={ProfilelastonlineImage}
      //                     alt=""
      //                   />
      //                   <span className="text-[#039511] px-2 md:px-1 lg:px-2 text-center md:text-[14px] lg:text-[14px] text-[10px]">
      //                     Online Chat Now
      //                   </span>
      //                 </span>
      //               ) : (
      //                 <span className="flex justify-center text-[#039511] font-segeo font-normal md:text-[14px] lg:text-[14px] text-[10px] px-1">
      //                   Last Online: {props?.profileData?.profilelastOnline}
      //                 </span>
      //               )}
      //             </div>
      //             <span className="text-[#575556]  md:text-[16px] lg:text-[14px] text-[9pt] font-semibold break-words my-2">
      //               {props?.profileData?.profileage} |{" "}
      //               {props?.profileData?.profileheight} |{" "}
      //               {props?.profileData?.profilequalification} |{" "}
      //               {props?.profileData?.profilereligion} |{" "}
      //               {props?.profileData?.profilecountry}
      //             </span>
      //           </div>
      //         </div>
      //         {props?.profileData?.profileStatus == "open" ? (
      //           <div className="mt-4 pl-[12%]">
      //             <span className=" text-[#575556]  md:text-[12px] lg:text-[14px] text-[8px]  font-bold">
      //               {/* {props?.profileData?.profilealreadyContectedMsg} */}
      //               <p>
      //                 You have already contacted this customer and awaiting
      //                 response,{" "}
      //                 <NavLink
        //                   to={RouteSegments.MEMBERSHIP_REGISTER()}
      //                   className="underline cursor-pointer text-[#0000ff]"
      //                 >
      //                   please Upgrade your Profile
      //                 </NavLink>{" "}
      //                 to send a personalized message.
      //               </p>
      //             </span>
      //           </div>
      //         ) : (
      //           <div className="border-0 rounded-lg text-center relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
      //             {props.profileData.profileStatus == "hidden"
      //               ? "profile has been hidden"
      //               : props.profileData.profileStatus == "inactive"
      //               ? "profile has been inactive"
      //               : props.profileData.profileStatus == "delete"
      //               ? "profile has been deleted"
      //               : props.profileData.profileStatus == "suspension"
      //               ? "profile has been suspended"
      //               : ""}
      //           </div>
      //         )}
      //       </div>
      //     </div>
      //   </div>
      // ) : 
      props?.dashboard1Data?.status === "pending" ? (
        <div className="relative w-auto my-6 mx-6">
          <div className="">
            <button
              className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
              onClick={() => {
                props.close();
              }}
            >
              <img className="h-5 w-5 " src={CloseIcon} alt=""/>
            </button>
          </div>

          <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
              <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
                {props.title}
              </h3>
            </div>
            <div className="relative p-6 flex-auto w-[80vw] md:w-[70vw] lg:w-[50vw]">
              <div className="flex flex-row  justify-start items-center border-b pb-2">
                <div className="relative justify-start items-center">
                  <img
                    src={props?.profileData?.profileurl} //"/Assets/Images/profile1.png"
                    alt=""
                    className={`${
                      props?.profileData?.profileblurPhoto === "Y"
                        ? "blur-[2px]"
                        : ""
                    } w-16 h-16 md:w-20 md:h-20 rounded-[50%]`}
                  />
                  {props?.profileData?.profilefromPasswordProtected === "Y" && (
                    <div className="absolute top-[-0.4rem] flex justify-center items-center h-[100%] w-[100%]  cursor-pointer">
                      <img
                        className="h-[2rem] w-[2rem] "
                        src={LockIcon}
                        alt="Person"
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-col ml-2">
                  <div className="flex flex-row item-center justify-start">
                    <span className="text-[#575556]  md:text-[16px] lg:text-[16px] text-[10pt]  font-bold">
                      {props?.profileData?.profilename} |{" "}
                      <span className="text-[#575556]  md:text-[16px] lg:text-[16px] text-[10pt] font-normal">
                        {props?.profileData?.smProfileId} |{" "}
                      </span>
                    </span>
                    {props?.profileData?.profilelastOnline === "Online Now" ? (
                      <span className="flex ml-1 justify-center items-center">
                        <img
                          className=" w-4 h-4 md:w-2 md:h-2 lg:w-3 lg:h-3"
                          src={ProfilelastonlineImage}
                          alt=""
                        />
                        <span className="text-[#039511] px-2 md:px-1 lg:px-2 text-center md:text-[14px] lg:text-[14px] text-[10px]">
                          Online Chat Now
                        </span>
                      </span>
                    ) : (
                      <span className="flex justify-center text-[#039511] font-segeo font-normal md:text-[14px] lg:text-[14px] text-[10px] px-1">
                        Last Online: {dateFormat(props?.profileData?.profilelastOnline)}
                      </span>
                    )}
                  </div>
                  <span className="text-[#575556]  md:text-[16px] lg:text-[14px] text-[9pt] font-semibold break-words my-2">
                    {props?.profileData?.profileage} |{" "}
                    {props?.profileData?.profileheight} |{" "}
                    {props?.profileData?.profilequalification} |{" "}
                    {props?.profileData?.profilereligion} |{" "}
                    {props?.profileData?.profilecountry}
                  </span>
                </div>
              </div>
              <div className="mt-4 pl-[12%]">
                <span className=" text-[#575556]  md:text-[12px] lg:text-[14px] text-[8px]  font-bold">
                  You can send message only after your profile is validated
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative w-auto my-6 mx-6">
          <div className="">
            <button
              className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
              onClick={() => {
                props.close();
              }}
            >
              <img className="h-5 w-5 " src={CloseIcon} alt=""/>
            </button>
          </div>

          <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
              <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
                {props.title}
              </h3>
            </div>
            <div className="relative p-6 flex-auto w-[80vw] md:w-[70vw] lg:w-[50vw]">
              <div className="flex flex-row item-center  md:hidden">
                {props?.profileData?.profilelastOnline === "Online Now" ? (
                  <span className="flex pl-[4.6rem] md:pl-1  justify-center items-center">
                    <img
                      className=" w-2 h-2 md:w-2 md:h-2 lg:w-3 lg:h-3"
                      src={ProfilelastonlineImage}
                      alt=""
                    />
                    <span className="text-[#039511]  px-2 md:px-1 lg:px-2 text-center md:text-[14px] lg:text-[14px] text-[10px]">
                      Online Chat Now
                    </span>
                  </span>
                ) : (
                  <span className="flex justify-center text-[#039511] font-segeo pl-[4.6rem] md:pl-1 font-normal md:text-[14px] lg:text-[14px] text-[10px] md:px-1 absolute lg:static md:static top-[.5rem] pt-0.5">
                    Last Online: {dateFormat(props?.profileData?.profilelastOnline)}
                  </span>
                )}
              </div>
              <div className="flex flex-row  justify-start items-center border-b pb-2">
                <div className="relative justify-start items-center">
                  <img
                    src={props?.profileData?.profileurl} //"/Assets/Images/profile1.png"
                    alt=""
                    className={`${
                      props?.profileData?.profileblurPhoto === "Y"
                        ? "blur-[2px]"
                        : ""
                    } w-16 h-16 md:w-20 md:h-20 rounded-[50%]`}
                  />
                  {props?.profileData?.profilefromPasswordProtected === "Y" && (
                    <div className="absolute top-[-0.4rem]  flex justify-center items-center h-[100%] w-[100%]  cursor-pointer">
                      <img
                        className="h-[2rem] w-[2rem] "
                        src={LockIcon}
                        alt="Person"
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-col ml-2">
                  <div className="flex flex-row item-center justify-start">
                    <span className="text-[#575556]  md:text-[16px] lg:text-[16px] text-[10pt]  font-bold">
                      {props?.profileData?.profilename} |{" "}
                      <span className="text-[#575556]  md:text-[16px] lg:text-[16px] text-[10pt] font-normal">
                        {props?.profileData?.smProfileId} |{" "}
                      </span>
                    </span>
                    {props?.profileData?.profilelastOnline === "Online Now" ? (
                      <span className="flex ml-1 justify-center items-center hidden md:flex">
                        <img
                          className=" w-4 h-4 md:w-2 md:h-2 lg:w-3 lg:h-3"
                          src={ProfilelastonlineImage}
                          alt=""
                        />
                        <span className="text-[#039511] px-2 md:px-1 lg:px-2 text-center md:text-[14px] lg:text-[14px] text-[10px] hidden md:flex">
                          Online Chat Now
                        </span>
                      </span>
                    ) : (
                      <span className="flex justify-center text-[#039511] font-segeo font-normal md:text-[14px] lg:text-[14px] pl-[4.6rem] md:pl-1  text-[10px] md:px-1 absolute lg:static md:static top-[.5rem] pt-0.5 hidden md:flex">
                        Last Online: {dateFormat(props?.profileData?.profilelastOnline)}
                      </span>
                    )}
                  </div>
                  <span className="text-[#575556]  md:text-[16px] lg:text-[14px] text-[9pt] font-semibold break-words my-2">
                    {props?.profileData?.profileage} |{" "}
                    {props?.profileData?.profileheight} |{" "}
                    {props?.profileData?.profilequalification} |{" "}
                    {props?.profileData?.profilereligion} |{" "}
                    {props?.profileData?.profilecountry}
                  </span>
                </div>
              </div>
              {localStorage.getItem(
                Constants.loginLocalStorageKeys.loginUserStatus
              ) == "hidden" ? (
                "Your profile is hidden"
              ) : props?.profileData?.profileStatus == "open" ||
                props?.profileData?.profileStatus == "pending" ? (
                <form onSubmit={formik.handleSubmit}>
                  {isSmallDevice ? (
                    <div className="">
                      {radioaMessage?.map(
                        (e, i) =>
                          e.message && (
                            <MessageAccordianComponent
                              id={e.id}
                              name={e.id}
                              selected={e.isSelected}
                              radiotext={e.message}
                              onChange={(item) => {
                                //console.log("radio", item.target.value);
                                let newVal = radioaMessage.map((ele, index) => {
                                  if (item.target.value === ele.id) {
                                    formik.setFieldValue(
                                      "message",
                                      ele.message
                                    );
                                    return { ...ele, isSelected: true };
                                  } else {
                                    return { ...ele, isSelected: false };
                                  }
                                });
                                setradioMessage(newVal);
                              }}
                              title={e.message}
                              titleClassName={
                                "flex items-center font-segeo justify-between  w-full py-2 pl-3 pr-2 font-semibold text-[#575556] bg-[#eaeaea] cursor-pointer block md:hidden lg:hidden"
                              }
                              defaultView={false}
                              titleIcon1={
                                <img
                                  src={MessageImage}
                                  className="w-3 h-3 fill-current rotate-180"
                                  alt=""
                                />
                              }
                              titleIcon2={
                                <img
                                  src={MessageImage}
                                  className="w-3 h-3 fill-current"
                                  alt=""
                                />
                              }
                            >
                              {e.message}
                            </MessageAccordianComponent>
                          )
                      )}
                    </div>
                  ) : (
                    <div className="">
                      {radioaMessage?.map(
                        (e, i) =>
                          e.message && (
                            <CommonRadioButton
                              id={e.id}
                              name={e.id}
                              selected={e.isSelected}
                              radiotext={e.message}
                              onChange={(item) => {
                                //console.log("radio", item.target.value);
                                let newVal = radioaMessage.map((ele, index) => {
                                  if (item.target.value === ele.id) {
                                    console.log(ele.id, "isSelectedisSelected");
                                    formik.setFieldValue(
                                      "message",
                                      ele.message
                                    );
                                    return { ...ele, isSelected: true };
                                  } else {
                                    return { ...ele, isSelected: false };
                                  }
                                });
                                setradioMessage(newVal);
                              }}
                            />
                          )
                      )}
                    </div>
                  )}
                  {props?.dashboard1Data?.isPaid === "Y" ? (
                    <div className="flex flex-col items-center justify-center">
                      <span className="text-[26px] font-bold">or</span>
                      <textarea
                        id="message"
                        rows="4"
                        className="block p-2.5 w-full text-sm text-gray-900  rounded-[3px] border border-[#8F8F8F] border-opacity-50"
                        placeholder=""
                        name="message"
                        value={formik.values.message}
                        onChange={(e) => {
                          let newVal = radioaMessage.map((ele, index) => {
                            if (ele.isSelected) {
                              return { ...ele, isSelected: false };
                            } else {
                              return { ...ele };
                            }
                          });
                          setradioMessage(newVal);
                          formik.handleChange(e);
                        }}
                      ></textarea>
                      {formik.errors.message && (
                        <p className="text-[red] text-[12px]">
                          {formik.errors.message}
                        </p>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {props?.dashboard1Data?.isPaid === "N"  ?
                   <div>
                      <span className="text-[26px] font-bold  mt-4 flex justify-center items-center">or</span>
                      <div className=" p-[2.5rem] w-full text-sm text-gray-900  flex justify-center items-center rounded-[3px] border border-[#8F8F8F] border-opacity-50">
                      <NavLink
                                  to={RouteSegments.MEMBERSHIP_REGISTER()}
                   className=" cursor-pointer text-[#D10A11] text-[15px] underline"
                 >   Buy a pack to Send Your Own Message</NavLink>
                      </div>
                   
                 </div>
                 :""
                  }
                 
                  <div className="flex flex-row justify-center m-2 space-x-8">
                    <CommonCheckButton
                      id={"email"}
                      name={"email"}
                      selected={true}
                      checktext={"Send Email"}
                      onChange={(item) => {
                        //console.log("check", item.target.value);
                      }}
                    />
                    <CommonCheckButton
                      id={"sendSms"}
                      name={"sendSms"}
                      selected={formik.values.sendSms === "Y" ? true : false}
                      checktext={"Send SMS"}
                      value={formik.values.sendSms}
                      onChange={(item) => {
                        //console.log("check", item.target.value);
                        formik.setFieldValue(
                          "sendSms",
                          `${formik.values.sendSms === "Y" ? "N" : "Y"}`
                        );
                      }}
                    />

                    {/* <CommonCheckButton
                      id={"requestphotocheck"}
                      name={"requestphotocheck"}
                      selected={formik.values.requestphotocheck === "Y" ? true : false}
                      checktext={trans('chat.request_photo')}
                      value={formik.values.requestphotocheck}
                      onChange={(item) => {
                        //console.log("check", item.target.value);
                        formik.setFieldValue(
                          "requestphotocheck",
                          `${formik.values.requestphotocheck === "Y" ? "N" : "Y"}`
                        );
                      }}
                    /> */}
 

                    {((props.profileData?.profileparentsnumber == null || props.profileData?.profileparentsnumber == "") && props?.profileData?.parentnumberrequestcheck === false) ? (
                      <CommonCheckButton
                        id={"requestparentcheck"}
                        name={"requestparentcheck"}
                        selected={
                          formik.values.requestparentcheck === "Y"
                            ? true
                            : false
                        }
                        checktext={trans('view_profile.request_parents_number')}
                        value={formik.values.requestparentcheck}
                        onChange={(item) => {
                          //console.log("check", item.target.value);
                          formik.setFieldValue(
                            "requestparentcheck",
                            `${
                              formik.values.requestparentcheck === "Y"
                                ? "N"
                                : "Y"
                            }`
                          );
                        }}
                      />
                    ) : (
                      <>
                        {/* <div className="flex mt-2 items-center">
                          
                          <label
                            htmlFor={props.id}
                            className=" font-semibold text-[11px] md:text-[10px] lg:text-[13px] text-[#575556] pl-1 cursor-pointer"
                          >
                            Parent's Number Requested
                          </label>
                        </div> */}
                      </>
                    )}
                    {
                     props?.profileData?.isAvatarPic === true && props?.profileData?.profilerequestcheck === false
                     ? <CommonCheckButton
                      id={"requestphotocheck"}
                      name={"requestphotocheck"}
                      selected={ formik.values.requestphotocheck === "Y"
                      ? true
                      : false}
                      checktext={trans('chat.request_photo')}
                      value={formik.values.requestphotocheck}
                      onChange={(item) => {
                        //console.log("check", item.target.value);
                        formik.setFieldValue(
                          "requestphotocheck",
                          `${
                            formik.values.requestphotocheck === "Y"
                              ? "N"
                              : "Y"
                          }`
                        );
                      }}
                    />
                    :null
                    }
                  </div>
                  <div className="flex justify-center mt-2">
                    <button
                      // onClick={() => {
                      //   //   props.onButtonCallback();
                      //   props.close();
                      // }}
                      type="submit"
                      className=" flex items-center justify-center lg:w-[2.5rem] lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                    >
                      Send
                    </button>
                  </div>
                </form>
              ) : (
                <div className="text-center">
                  {props?.profileData?.profileStatus == "hidden"
                    ? "profile has been hidden"
                    : props?.profileData?.profileStatus == "inactive"
                    ? "profile has been inactive"
                    : props?.profileData?.profileStatus == "delete"
                    ? "profile has been deleted"
                    : props?.profileData?.profileStatus == "suspension"
                    ? "profile has been suspended"
                    : ""}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SendMessagePopup;
