import { combineReducers } from "redux";
import Home from './Home/Reducer'
import Login from './Login/Reducer';
import Register from './Register/Reducer';
import Payment from './Payment/Reducer';
import Contact from './Contact/Reducer';
import Conversation from "./Conversation/Reducer";
import FilterSection from "./FilterSection/Reducer";
import BookAppointment from "./BookAppointment/Reducer";
import SearchById from "./SearchById/Reducer";
import ExploreProfile from "./ExploreProfile/Reducer";
import BannerRedirection from "./BannerRedirection/Reducer";
import SearchPageCheck from "./SearchPageCheck/Reducer";
import ContentCard from "./ContentCard/Reducer";
import StoreRegistration from './StoreRegistration/Reducer';
import DashBoardAPI from "./DashboardAPI/Reducer";
import KalyanLite from "./KalyanLite/Reducer";
import Chats from "./Chats/Reducer"
import KalyanRewards from "./KalyanRewards/Reducer"


const appReducer = combineReducers({
    Home,
    Login,
    Register,
    Payment,
    Contact,
    Conversation,
    FilterSection,
    BookAppointment,
    SearchById,
    ExploreProfile,
    BannerRedirection,
    SearchPageCheck,
    ContentCard,
    StoreRegistration,
    DashBoardAPI,
    KalyanLite,
    Chats,
    KalyanRewards,
});

const rootReducer = (state, action) => {
    if (action.type === "CLEAR_STORE") {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export default rootReducer;
