import { BOOK_APPOINTMENT, BOOK_APPOINTMENT_API_ERROR, BOOK_APPOINTMENT_SUCCESS,
  GET_STATE_LIST, GET_STATE_LIST_SUCCESS,GET_STATE_LIST_API_ERROR,
  GET_STORE_LIST, GET_STORE_LIST_SUCCESS, GET_STORE_LIST_API_ERROR
      } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const BookAppointment = (state = initialState, action) => {
    //console.log(action.type,"12345");
    switch (action.type) {
      case GET_STATE_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
        case GET_STATE_LIST_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          stateListData:action.payload.data,
          loading: false,
        };
        break;
      case GET_STATE_LIST_API_ERROR:
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;
        case GET_STORE_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
        case GET_STORE_LIST_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          storeListData:action.payload.data,
          loading: false,
        };
        break;
      case GET_STORE_LIST_API_ERROR:
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;
      case BOOK_APPOINTMENT:
        state = {
          ...state,
          loading: true,
        };
        break;
        case BOOK_APPOINTMENT_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          BAResponseData:action.payload.data,
          loading: false,
        };
        break;
      case BOOK_APPOINTMENT_API_ERROR:
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default BookAppointment;
  