import { STORE_REGISTER_FORM_DATA, STORE_REGISTER_FORM_DATA_FAILURE, STORE_REGISTER_FORM_DATA_SUCCESS, STORE_REGISTER_FORM_SUBMIT, STORE_REGISTER_FORM_SUBMIT_API_ERROR, STORE_REGISTER_FORM_SUBMIT_SUCCESS, STORE_REGISTER_FORM_SUBMIT_SUCCESS_POPUP } from "./ActionTypes";

  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const StoreRegistration = (state = initialState, action) => {
    //console.log(action.type,"12345");
    switch (action.type) {
        case STORE_REGISTER_FORM_DATA:
        state = {
          ...state,
          data:action.payload.data,
          loading: true,
        };
        break;
        case STORE_REGISTER_FORM_DATA_SUCCESS:
        state = {
          ...state,
          FormInputData:action.payload.data,
          loading: false,
        };
        break;
        case STORE_REGISTER_FORM_DATA_FAILURE:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          FormInputDataError:action.payload.data,
          loading: false,
        };
        break;
        case STORE_REGISTER_FORM_SUBMIT:
          state = {
            ...state,
            loading: true,
          };
          break;
        case STORE_REGISTER_FORM_SUBMIT_SUCCESS:
          console.log(action.payload.data,"statse");
          state = {
            ...state,
            FormInputSubmit:action.payload.data,
            loading: false,
          };
          break;
          case STORE_REGISTER_FORM_SUBMIT_SUCCESS_POPUP:
            console.log(action.payload.data,"statse");
            state = {
              ...state,
              FormInputSubmitPopup:action.payload.data,
              loading: false,
            };
            break;
        case STORE_REGISTER_FORM_SUBMIT_API_ERROR:
          //console.log( action.payload.data,"123456");
          state = {
            ...state,
            FormInputSubmitError: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
          break;
        default:
          state = { ...state };
          break;
    }
    return state;
  };
  
  export default StoreRegistration;
  