import { CONTENT_CARD_CLICK, CONTENT_CARD_CLICK_API_ERROR, CONTENT_CARD_CLICK_SUCCESS } from "./ActionTypes";

//Content Card onclick

  export const contentCardOnclick = (name,page) => {
    // console.log(name,page,"12345ka");
    return {
      type: CONTENT_CARD_CLICK,
      payload: {name,page}
    };
  };

  export const contentCardOnclickSuccess = data => {
    console.log(data,"12344");
    return {
      type: CONTENT_CARD_CLICK_SUCCESS,
      payload: data,
    };
  };
  
  export const contentCardOnclickApiError = error => {
    //////console.log(error,"12345");
    return {
      type: CONTENT_CARD_CLICK_API_ERROR,
      payload: error,
    };
  };

  