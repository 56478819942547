import { ADD_POINT, ADD_POINT_FAIL, ADD_POINT_SUCCESS, GET_ACTIVITY_LIST, GET_ACTIVITY_LIST_FAIL, GET_ACTIVITY_LIST_SUCCESS, GET_ACTIVITY_TOTAL_POINTS, GET_ACTIVITY_TOTAL_POINTS_FAIL, GET_ACTIVITY_TOTAL_POINTS_SUCCESS, HIDE_CELEBRATION, POINT_AWARDED, REWARD_POPUP_SHOWN } from "./ActionTypes";


export const getActivityList = (payload) => {
    return {
        type: GET_ACTIVITY_LIST,
        payload,
    };
};

export const getActivityListSuccess = (payload) => {
    return {
        type: GET_ACTIVITY_LIST_SUCCESS,
        payload,
    };
};

export const getActivityListFail = (payload) => {
    return {
        type: GET_ACTIVITY_LIST_FAIL,
        payload,
    };
};

export const getActivityTotalPoints = (payload) => {
    return {
        type: GET_ACTIVITY_TOTAL_POINTS,
        payload,
    };
};

export const getActivityTotalPointsSuccess = (payload) => {
    return {
        type: GET_ACTIVITY_TOTAL_POINTS_SUCCESS,
        payload,
    };
};

export const getActivityTotalPointsFail = (payload) => {
    return {
        type: GET_ACTIVITY_TOTAL_POINTS_FAIL,
        payload,
    };
};

export const addPoint = ({ activityId, points }) => {
    return {
        type: ADD_POINT,
        payload: { activityId, points },
    };
};

export const addPointSuccess = (payload) => {
    return {
        type: ADD_POINT_SUCCESS,
        payload,
    };
};

export const addPointFail = (payload) => {
    return {
        type: ADD_POINT_FAIL,
        payload,
    };
};

export const hideCelebration = (payload) => {
    return {
        type: HIDE_CELEBRATION,
        payload,
    };
};

export const pointAwarded = (payload) => {
    return {
        type: POINT_AWARDED,
        payload,
    };
};

export const rewardPopupShown = (payload) => {
    return {
        type: REWARD_POPUP_SHOWN,
        payload,
    };
};


