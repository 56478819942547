import React, { useEffect, useState } from 'react'
import { EditButton, HobbyListTag, SaveCancelButton } from '../EditProfileUtills';
import Loader from '../../Loader';
import ActionRestrict from '../../Kalyanlite/Common/ActionRestrict';
import { useTranslation } from 'react-i18next';

export default function HobbyComponent(props) {

    const [isShowEdit, setEditView] = useState(false);
    const [editData, setEditData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { t:trans } = useTranslation();

    const handleSelect = (selectedId) => {
      // Create a copy of editData
      const updatedEditData = [...editData.map(e => ({ ...e }))]; // Ensure a deep copy of each object
    
      updatedEditData.forEach((e) => {
        if (e.id === selectedId) {
          if (e.selected === "N") {
            props?.formik?.values?.[props?.keyValue].push(e.id);
            e.selected = "Y";
          } else {
            props?.formik?.values?.[props?.keyValue].splice(
              props?.formik?.values?.[props?.keyValue].indexOf(selectedId),
              1
            );
            e.selected = "N";
          }
        }
      });
    
      // Update the state with the new copy
      setEditData(updatedEditData);
    };    

    useEffect(() => {
      if(props?.formik.isSubmitting == false){
        setEditView(false);
      }
    }, [props?.formik.isSubmitting])

    useEffect(() => {
      if(props?.data){
        setIsLoading(false)
        setEditData(props?.data);
      }
    
    }, [props?.data])
    

    
    const hobbiesList = props?.data?.filter((e)=> e.selected == "Y").map((e)=> e.id) ?? [];

  return (
    <div>
      {isShowEdit ?
          <></>
          : <>
          <ActionRestrict>
          <EditButton
              editCallBack={() => {
                setEditView(true);
              }}
            />
          </ActionRestrict>
          {isLoading ? <Loader />  : hobbiesList.length > 0 ? 
            <div className="mt-2 px-[1rem] h-[8rem] rounded-[4px] overflow-auto">
              <div className="flex flex-wrap m-2 gap-2">
                {
                props?.data?.map((element, index) => (
                  <>
                    {element.selected === "Y" &&
                        <HobbyListTag key={index} text={element.name} isFrom={"show"}/>
                    }
                  </>
                ))}
              </div>
            </div>
            :
            <p class="w-full mt-2 px-[1rem] md:text-[12px] lg:text-[14px] text-[#7A7A7A] font-semibold break-words text-center">{trans('my_profile.not_specified')}</p>
            }
        </>
        }
        {isShowEdit && 
        <form onSubmit={props?.formik?.handleSubmit}>
            <div className="p-5 md:p-0">
                <div className="mt-2 px-[1rem] h-[8rem] rounded-[4px] overflow-auto">
                  <div className="flex flex-wrap m-2 gap-2">
                    {editData?.map((element, index) => (
                      <>
                        <HobbyListTag 
                          key={index} 
                          isFrom={"edit"} 
                          text={element.name} 
                          selected={element.selected}
                          onClick={()=> handleSelect(element.id)}
                        />
                      </>
                    ))}
                  </div>
              </div>
              <SaveCancelButton
              submitStatus={props?.formik.isSubmitting}
                saveCallBack={(e) => {
                  e.preventDefault();
                  setIsLoading(true)
                  props?.formik?.handleSubmit(e);
                }}
                cancelCallback={() => {
                  props?.formik?.resetForm();
                  setEditData(props?.data);
                  setEditView(false);
                }}
                isFrom={"Hobby"}
              />
            </div>
            </form>
        }
    </div>
  )
}
