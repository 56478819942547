import React, { useEffect, useState, useContext, useMemo } from "react";
import Constants from '../../Constants/constants';
import { NavBarContext } from "../../Contexts/NavBarContext";
import { useTranslation } from "react-i18next";


const ViewMessageCards = (props) => {
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);
  const { t: trans } = useTranslation();
  //console.log(props?.cardData?.status,"jikjlo");
  //console.log(menuSelect,"menuSelectss");
  console.log(props?.showList,"props?.showList");
  console.log(props.profileContent,"props.profileContent");
  return (
    <div>
{props.isFrom !== "sidepanel" ?
    <div className={`grid grid-cols-10 md:grid  sm:grid-cols-10 bg-[#F2F2F2] h-auto pb-2  px-2 my-3 lg:p-2 ${props.lengthOfRow.length === props.key1 + 1 ? "mb-[2rem]" : ""}`}>
      <div className="col-span-2 flex justify-center items-center  lg:mt-2 lg:ml-2">
        <div className=' md:w-19 md:h-19 relative lg:w-[7.25rem] lg:h-[7.25rem] w-13 h-13'>

            <img
               className={`${
            props?.cardData?.blurphoto === "Y" ? "blur-[2px]  " :  " "
          } w-full h-full rounded-full `}
              src={props.profileimageurl}//"Assets/Images/profilematch1.png"
              alt=""
            />
              {props?.cardData?.fromPasswordProtected === "Y" && (
                  <div
                  className="absolute top-[-0.3rem] right-[0rem]   lg:top-[-0.4rem]  md:right-[0rem] lg:right-[0rem] flex justify-center items-center h-[100%] w-[100%]  cursor-pointer"
                  > 
                <img
                  className=" h-[1.5rem] w-[1.5rem] lg:h-[2rem] lg:w-[2rem] "
                  src="/Assets/Images/lockicon.svg"
                  alt="Person"
                />
                  </div>
                 )}
          </div>
        </div>
        <div className=" col-span-5 flex  flex-col mt-[0.4rem] px-[0.4rem] md:px-0 md:pl-[1rem] lg:px-5 sm:col-span-5">
          <div className="flex justify-start">
            <div className=" py-0 md:py-1">
              <div className=" grid grid-cols-2 gap-4">
                <div>
                  <p className="text-[#575556] font-segeo text-[12px] md:text-[12px] lg:text-[17px] font-bold">
                    {props.cardData.smProfileId}
                  </p>
                </div>
                <div className="self-center">
                <div className="hidden md:flex md:mt-[2px] md:ml-[2rem] lg:ml-0 lg:flex space-x-2 text-[#039511]">
                  {props.cardData.lastOnline === "Online Now" ? 
                    <img
                      className=" md:w-3 md:h-3 lg:w-4 lg:h-4 w-4 h-4"
                      src="/Assets/Images/Group 619.svg"
                      alt=""
                    /> : <></>
                  }
                  <span className=" text-[#039511] text-[10px] md:text-[10px] md:whitespace-nowrap lg:text-[11px]">
                    {props.cardData.lastOnline === "Online Now" ? "Online Chat Now" : `Last Online: ${props.cardData.lastOnline}`}
                  </span>
                </div>
                </div>
            </div>
            <p className=" pt-1 md:pt-1 lg:pt-2 text-[12px] md:text-[13px] leading-[13px] md:leading-[16px] lg:text-[17px] text-[#7A7A7A] font-semibold font-segeo profile">
              {props.profileName}
            </p>
            <p className="pt-1 md:pt-1 lg:pt-2 text-[12px] md:text-[12px] lg:text-[15px] text-[#7A7A7A] font-semibold font-segeo">
              {props.profileAge} yrs, {props.profileHeight}
            </p>
            {/* {props?.showList && */}
            <>
            <p className={`${props.selectedFilter?"h-[2rem] md:h-[1.3rem] lg:h-[3rem] profile":""} inbox-text lg:block lg:pl-0 pt-1 lg:pl-0 lg:py-0 lg:pt-2 text-[9px] w-[73%] md:w-[79%] lg:w-full md:text-[10px] lg:text-[13px] text-[#7A7A7A] font-normal break-words font-segeo `}>
            {props.viewBtnName == "View Message" ||props.viewBtnName == "Reply" ||props.viewBtnName == "Read & Reply" ?
            props?.cardData?.status == "open" || props?.cardData?.status == "pending" ?
            props.profileContent
            :
            props.cardData.status == "inactive"?
              "Profile has been inactive"
              :props.cardData.status == "hidden"?
              "Profile has been hidden"
              :props.cardData.status == "delete"?
              "Profile has been deleted"
              :props.cardData.status == "suspension"?
              "Profile has been suspened"
              :""
            :
            props?.cardData?.profileStatus == "open" || props?.cardData?.profileStatus == "pending" ?
            props.cardData?.requestContent
            :
            props.cardData.profileStatus == "inactive"?
              "Profile has been inactive"
              :props.cardData.profileStatus == "hidden"?
              "Profile has been hidden"
              :props.cardData.profileStatus == "delete"?
              "Profile has been deleted"
              :props.cardData.profileStatus == "suspension"?
              "Profile has been suspened"
              :""
            }
           
            </p>
            </>
             {/* }  */}
            
          </div>
        </div>
      </div>
      <div className=" col-span-3 flex py-0 md:py-4 mb-2 pt-0 md:pt-[0.5rem] lg:mt-0 flex-col items-center ">
        <div className="md:hidden col-span-2 flex justify-end items-center pr-4 lg:pr-6 md:pr-6 whitespace-nowrap mt-[.7rem] ">
         {props.cardData.lastOnline==="Online Now"? <img className=" w-2 h-2 md:h-3 md:w-3" src="/Assets/Images/Ellipse 36.svg" alt=""/>:<></>}
          <span
            className="px-1 md:px-2 lg:px-2 text-[8px] md:text-[10px]
            text-[#039511]"
          >
            {props.cardData.lastOnline==="Online Now"? "Online Chat Now":`Last Online: ${props.cardData.lastOnline}`}
          </span>
        </div>
        <div className="flex flex-col justify-center mt-[1.3rem] md:mt-0 md:pt-[2.2rem] lg:pt-[3.3rem] md:ml-[0.6rem] lg:ml-0">
            <button 
            onClick={()=>
             props.viewBtnName == "View Message" ||props.viewBtnName == "Reply" ||props.viewBtnName == "Read & Reply" ?
              (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                  props.viewProfileStatusPopup("Your Profile is hidden")
                  
                  :
              props?.cardData?.status == "open" || props?.cardData?.status == "pending" ?
            props.onClickViewProfile()
            :
            props.cardData.status == "inactive"?
                  props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.status == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.status == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.status == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""
            :
            (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                  props.viewProfileStatusPopup("Your Profile is hidden")
                  :
            props?.cardData?.profileStatus == "open" || props?.cardData?.profileStatus == "pending" ?
            props.onClickViewProfile()
            :
            props.cardData.profileStatus == "inactive"?
            props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.profileStatus == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.profileStatus == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.profileStatus == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""
                 
                  

                  
           }
            className="flex justify-center items-center text-[9px] w-18 md:w-26 lg:w-32 md:text-[9px] lg:text-[12px] p-[0.4rem] md:p-2  rounded-[5px] font-bold font-segeo text-[#7A7A7A] border border-[#9F9F9F]">
              {trans('chat.view_profile')}
            </button>
            {/* </div>
          <div className=" py-1 md:py-2 lg:py-2 flex flex-col justify-center items-center"> */}
            <button 
            onClick={() =>props.viewBtnName == "View Message" ||props.viewBtnName == "Reply" ||props.viewBtnName == "Read & Reply" ||props.viewBtnName == "View Message" ?
            (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                  props.viewProfileStatusPopup("Your Profile is hidden")
                  
                  :
            props?.cardData?.status == "open" || props?.cardData?.status == "pending" ?
              props.onClickViewBtn(props.viewBtnName,"sidepanel")
              :
              props.cardData.status == "inactive"?
                props.viewProfileStatusPopup("Profile has been inactive")
                :props.cardData.status == "hidden" ?
                props.viewProfileStatusPopup("Profile has been hidden")
                :props.cardData.status == "delete" ?
                props.viewProfileStatusPopup("Profile has been deleted")
                :props.cardData.status == "suspension" ?
                props.viewProfileStatusPopup("Profile has been suspended")
                :""
          :
          (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
          props.viewProfileStatusPopup("Your Profile is hidden")
          :
          props?.cardData?.profileStatus == "open" || props?.cardData?.profileStatus == "pending" ?
          props.onClickViewBtn(props.viewBtnName,"sidepanel")
          :
          props.cardData.profileStatus == "inactive"?
          props.viewProfileStatusPopup("Profile has been inactive")
                :props.cardData.profileStatus == "hidden" ?
                props.viewProfileStatusPopup("Profile has been hidden")
                :props.cardData.profileStatus == "delete" ?
                props.viewProfileStatusPopup("Profile has been deleted")
                :props.cardData.profileStatus == "suspension" ?
                props.viewProfileStatusPopup("Profile has been suspended")
                
                :""
              }
              
             className=" flex justify-center items-center text-[#FFFFFF] w-18 md:w-26 lg:w-32 text-[9px] md:text-[9px] lg:text-[12px] p-2   mt-2  rounded-[5px] whitespace-nowrap bg-[#D10A11] font-bold font-segeo  ">
              {props.viewBtnName}
              {/* {//console.log(props.viewBtnName, "props.viewBtnName")} */}
            </button>
            {/* </div> */}
          </div>
        </div>
        
      </div>
:
      <div className={`grid grid-cols-2 md:grid  sm:grid-cols-10 bg-[#F2F2F2] h-auto pb-2  px-2 my-3 lg:p-2 ${props.lengthOfRow.length === props.key1 + 1 ? "mb-[2rem]" : ""}`}>
      <div className="col-span-4 md:col-span-3 lg:col-span-4 flex justify-center items-center  lg:mt-2 lg:ml-2 md:mt-4">
        <div className=' md:w-19 md:h-19 relative lg:w-[7.25rem] lg:h-[7.25rem] w-13 h-13'>

            <img
               className={`${
            props?.cardData?.blurphoto === "Y" ? "blur-[2px]  " :  " "
          } w-full h-full rounded-full md:w-[4rem] md:h-[4rem] lg:w-full lg:h-full`}
              src={props.profileimageurl}
              alt=""
            />
              {props?.cardData?.fromPasswordProtected === "Y" && (
                  <div
                  className="absolute top-[-0.3rem] right-[0rem]   lg:top-[-0.4rem]  md:right-[0rem] lg:right-[0rem] flex justify-center items-center h-[100%] w-[100%]  cursor-pointer"
                  > 
                <img
                  className=" h-[1.5rem] w-[1.5rem] lg:h-[2rem] lg:w-[2rem] "
                  src="/Assets/Images/lockicon.svg"
                  alt="Person"
                />
                  </div>
                 )}
          </div>
        </div>
        <div className=" col-span-4 flex  flex-col mt-[0.4rem] px-[0.4rem] md:px-0 sm:col-span-5 md:mt-4">
          <div className="flex justify-start">
            <div className=" py-0 md:py-1">
              <div className=" flex gap-4 items-center">
                  <p className="text-[#575556] font-segeo text-[12px] md:text-[12px] lg:text-[16px] font-bold">
                    {props.cardData.smProfileId}
                  </p>
                <div className="self-center">
                <div className="hidden md:flex lg:ml-0 lg:flex space-x-2 text-[#039511]">
                  {props.cardData.lastOnline === "Online Now" ? 
                    <img
                      className=" md:w-3 md:h-3 lg:w-4 lg:h-4 w-4 h-4"
                      src="/Assets/Images/Group 619.svg"
                      alt=""
                    /> : <></>
                  }
                  <span className=" text-[#039511] text-[10px] md:text-[10px] md:whitespace-nowrap lg:text-[10px]">
                    {props.cardData.lastOnline === "Online Now" ? "Online Chat Now" : `Last Online: ${props.cardData.lastOnline}`}
                  </span>
                </div>
                </div>
            </div>
            <p className=" pt-1 md:pt-1 lg:pt-2 text-[12px] md:text-[13px] leading-[13px] md:leading-[16px] lg:text-[16px] text-[#7A7A7A] font-semibold font-segeo profile">
              {props.profileName}
            </p>
            <p className="pt-1 md:pt-1 lg:pt-2 text-[12px] md:text-[12px] lg:text-[14px] text-[#7A7A7A] font-semibold font-segeo">
              {props.profileAge} yrs, {props.profileHeight}
            </p>
            {/* {props?.showList && */}
            <>
            <p className={`${props.selectedFilter?"h-[2rem] md:h-[2rem] lg:h-[3rem] profile":""} inbox-text lg:block lg:pl-0 pt-1 lg:pl-0 lg:py-0 lg:pt-2 text-[9px] w-[73%] md:w-[79%] lg:w-full md:text-[10px] lg:text-[12px] text-[#7A7A7A] font-normal break-words font-segeo `}>
            {props.viewBtnName == "View Message" ||props.viewBtnName == "Reply" ||props.viewBtnName == "Read & Reply" ?
            props?.cardData?.status == "open" || props?.cardData?.status == "pending" ?
            props.profileContent
            :
            props.cardData.status == "inactive"?
              "Profile has been inactive"
              :props.cardData.status == "hidden"?
              "Profile has been hidden"
              :props.cardData.status == "delete"?
              "Profile has been deleted"
              :props.cardData.status == "suspension"?
              "Profile has been suspened"
              :""
            :
            props?.cardData?.profileStatus == "open" || props?.cardData?.profileStatus == "pending" ?
            props.cardData?.requestContent
            :
            props.cardData.profileStatus == "inactive"?
              "Profile has been inactive"
              :props.cardData.profileStatus == "hidden"?
              "Profile has been hidden"
              :props.cardData.profileStatus == "delete"?
              "Profile has been deleted"
              :props.cardData.profileStatus == "suspension"?
              "Profile has been suspened"
              :""
            }
           
            </p>
            </>
            {/* } */}
            
          </div>
        </div>
      </div>
        <div className=" col-span-10 flex justify-center mt-2 md:mt-0">
        <div className="md:hidden col-span-2 flex justify-end items-center pr-4 lg:pr-6 md:pr-6 whitespace-nowrap mt-4 ">
         {props.cardData.lastOnline==="Online Now"? <img className=" w-2 h-2 md:h-3 md:w-3" src="/Assets/Images/Ellipse 36.svg" alt=""/>:<></>}
          <span
            className="px-1 md:px-2 lg:px-2 text-[8px] md:text-[10px]
            text-[#039511]"
          >
            {props.cardData.lastOnline==="Online Now"? "Online Chat Now":`Last Online: ${props.cardData.lastOnline}`}
          </span>
        </div>
        <div className="flex flex-row justify-around space-x-9">
            <button 
            onClick={()=>
              props.viewBtnName == "View Message" ||props.viewBtnName == "Reply" ||props.viewBtnName == "Read & Reply" ?
              (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                  props.viewProfileStatusPopup("Your Profile is hidden")
                  :
              props?.cardData?.status == "open" || props?.cardData?.status == "pending" ?
            props.onClickViewProfile()
            :
            props.cardData.status == "inactive"?
                  props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.status == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.status == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.status == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""
            :
            (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                  props.viewProfileStatusPopup("Your Profile is hidden")
                  :
            props?.cardData?.profileStatus == "open" || props?.cardData?.profileStatus == "pending" ?
            props.onClickViewProfile()
            :
            props.cardData.profileStatus == "inactive"?
            props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.profileStatus == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.profileStatus == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.profileStatus == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""
          } 
            className="flex justify-center items-center text-[9px] w-18 md:w-26 lg:w-32 md:text-[9px] lg:text-[12px] p-2  rounded-[5px] font-bold font-segeo text-[#7A7A7A] border border-[#9F9F9F]">
              {trans('chat.view_profile')}
            </button>
            <button 
            onClick={() =>props.viewBtnName == "View Message" ||props.viewBtnName == "Reply" ||props.viewBtnName == "Read & Reply" ||props.viewBtnName == "View Message" ?
            (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                  props.viewProfileStatusPopup("Your Profile is hidden")
                  :
            props?.cardData?.status == "open" || props?.cardData?.status == "pending" ?
              props.onClickViewBtn(props.viewBtnName,"sidepanel")
              :
              props.cardData.status == "inactive"?
                props.viewProfileStatusPopup("Profile has been inactive")
                :props.cardData.status == "hidden" ?
                props.viewProfileStatusPopup("Profile has been hidden")
                :props.cardData.status == "delete" ?
                props.viewProfileStatusPopup("Profile has been deleted")
                :props.cardData.status == "suspension" ?
                props.viewProfileStatusPopup("Profile has been suspended")
                :""
          :
          (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
          props.viewProfileStatusPopup("Your Profile is hidden")
          :
          props?.cardData?.profileStatus == "open" || props?.cardData?.profileStatus == "pending" ?
          props.onClickViewBtn(props.viewBtnName,"sidepanel")
          :
          props.cardData.profileStatus == "inactive"?
          props.viewProfileStatusPopup("Profile has been inactive")
                :props.cardData.profileStatus == "hidden" ?
                props.viewProfileStatusPopup("Profile has been hidden")
                :props.cardData.profileStatus == "delete" ?
                props.viewProfileStatusPopup("Profile has been deleted")
                :props.cardData.profileStatus == "suspension" ?
                props.viewProfileStatusPopup("Profile has been suspended")
                :""}
             className={`flex justify-center items-center text-[#FFFFFF] w-18 md:w-26 lg:w-32 text-[9px] md:text-[9px] lg:text-[12px] p-2 rounded-[5px] whitespace-nowrap bg-[#D10A11] font-bold font-segeo ${props.viewBtnName == 'Reply' ? 'px-5':""}`}>
              {props.viewBtnName}
              {/* {//console.log(props.viewBtnName, "vp")} */}
            </button>
          </div>
        </div>
      </div>
      }
    </div>
  )
}

export default ViewMessageCards
