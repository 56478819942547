import { 
  HELP_DATA_DETAIL_BEFORE_LOGIN, 
  HELP_DATA_DETAIL_BEFORE_LOGIN_API_ERROR, 
  HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT, 
  HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT_API_ERROR, 
  HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT_SUCCESS, 
  HELP_DATA_DETAIL_BEFORE_LOGIN_SUCCESS
 } from "./ActionTypes";


  //onload help data form detail
  export const helpDataDetailBeforeLogin = (data) => {
    //console.log(data,"12345");
    return {
      type: HELP_DATA_DETAIL_BEFORE_LOGIN,
      payload: {data}
    };
  };

  export const helpDataDetailBeforeLoginSuccess = data => {
    //console.log(data,"12344");
    return {
      type: HELP_DATA_DETAIL_BEFORE_LOGIN_SUCCESS,
      payload: data,
    };
  };
  
  export const helpDataDetailBeforeLoginApiError = error => {
    //////console.log(error,"12345");
    return {
      type: HELP_DATA_DETAIL_BEFORE_LOGIN_API_ERROR,
      payload: error,
    };
  };

  //FAQ form submit

  export const helpDataDetailBeforeLoginSubmit = (data) => {
    //console.log(data,"12345");
    return {
      type: HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT,
      payload: {data}
    };
  };

  export const helpDataDetailBeforeLoginSubmitSuccess = data => {
    //console.log(data,"12344");
    return {
      type: HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT_SUCCESS,
      payload: data,
    };
  };
  
  export const helpDataDetailBeforeLoginSubmitApiError = error => {
    //////console.log(error,"12345");
    return {
      type: HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT_API_ERROR,
      payload: error,
    };
  };

  