import React, { useEffect, useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { POST, GET } from "../../Services/api_services";
import { LoginContext } from "../../Contexts/LoginContext";
import Constants, { EventKeys, EventName, loginFrom } from "../../Constants/constants";
import '../../index.css'
import Loader from '../../Components/Loader'
import config from "../../config";
import CloseIcon from "../../Images/Group 761@2x.png"
import { validateNumber } from "../../Utils";
import { useDispatch } from "react-redux";
import { dashboardProfileData } from '../../Store/DashboardAPI/Action';
import { useAnalytics } from "../../Hooks/usePageChange";
import { useTranslation } from "react-i18next";


const ParentsPhonePopup = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [isdCodeList, setIsdCodeList] = useState([]);
  const[onSubmitPopup,setOnSubmitPopup]=useState(false)
  const [isShowMessage,setIsShowMessage]=useState("")
  const [showpopup ,setShowpopup]=useState(false)
  const dispatch = useDispatch();
  const { onClickTrack } = useAnalytics()
  const { t: trans } = useTranslation();

  const formik = useFormik({
    initialValues: {
      number:props?.parentsMobileNumber?? "",
      isdCode: props?.parentsIsdCode??
        "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // number: Yup.string().min(10, "Length should be 10")
      // .test("india", "Mobile number should have 10 digits", function (value) {
      //   const { isdCode } = this.parent;
      //   if (isdCode === '+91') {
      //     return !value || value.length === 10 ? true : this.createError({ message: "Mobile number should have 10 digits", path: "number" });
      //   }
      //   return true;
      // }).test("other", "Mobile number should have 20 digits", function (value) {
      //   const { isdCode } = this.parent;
      //   if (isdCode !== '+91') {
      //     return !value || value.length < 21 ? true : this.createError({ message: "Please enter valid mobile number", path: "number" });
      //   }
      //   return true;
      // }).required("Please enter phone number"),
      // isdCode:Yup.string().required("Please Select Isd code") ,
      number: Yup.string()
      .test("india", "Please enter a valid Mobile Number", function (value) {
        const { isdCode } = this.parent;
        if (isdCode === '+91') {
          return !value || value.length === 10 ? true : this.createError({ message: trans('family.please_enter_a_valid_mobile_nu'), path: "number" });
        }
        return true;
      }).test("other", "Please enter a valid Mobile Number", function (value) {
        const { isdCode } = this.parent;
        if (isdCode !== '+91') {
          return !value || (value.length >= 7 && value.length <= 15) ? true : this.createError({ message: trans('family.please_enter_a_valid_mobile_nu'), path: "number" });
        }
        return true;
      }).required(trans('family.please_enter_mobile_number')),
      isdCode:Yup.string().required("Please Select Isd code"),
    }),

    onSubmit: (values) => {      
        setOnSubmitPopup(true)
        apicallSubmitParentsNum(values)
        if(props?.isFrom =="family"){
          onClickTrack(EventName.Family_Parent_Number_Popup_Submit_Button,{[EventKeys.Family_Parent_Number_Popup_Submit_Button_Request]:values})
        }
        // setTimeout(function(){
        //   setOnSubmitPopup(false)

        // },[200])

        // setTimeout(function(){
        //   setIsShowMessage("Parent's number saved")
        // },[500])
    //   props.callBackApply(values);
    },
  });

  useEffect(() => {
    //console.log("Popup called");
    apicallIsdCode();
    console.log(props,"contentResponseData");
    
  }, []);
  useEffect(() => {
    //console.log("Popup called");
  }, [isdCodeList]);

  {
    /* api/submitparentsnumber
    api/getisdcode */
  }
  const apicallIsdCode = async () => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.gteIsdCodeUrl()}`
    );

    if (statusCode === 200) {
      setIsdCodeList(data?.data?.isdcode);
    } else if (statusCode === 401) {
      logout();
    }
  };

  const [status, setStatus] = useState(null);

  function changeStatus(e) {
    setStatus(e.target.value);
  }
//   "{
//     ""userId"": ""1631292"",
//     ""email"": ""nayekkarthikeyan@gmail.com"",
//     ""parentsMobileNumber"": ""7686858823"",
//     ""loginFrom"": ""android"",
//     ""parentsNumberFrom"": """",
//     ""countryId"": ""+91""
// }"

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    }
  }, [])


const apicallSubmitParentsNum = async (value) => {
  const loginUrl = sessionStorage.getItem(Constants.loginLocalStorageKeys.loginUrl)
  let notificationId = null;

  if (loginUrl?.includes("parentsnumber")) {
    notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)
    sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
  }
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      parentsIsdCode: value.isdCode,
      parentsMobileNumber: value.number,
      parentsNumberFrom:"",
      isContent: props?.name,
      contentId: props?.contentId,
      notificationId: notificationId ?? undefined
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.submitparentsnumberUrl()}`,
      request
    );

    if (statusCode === 200) {
      if(data?.data?.status=="SUCCESS"){
        if(props.onSubmitSuccess){
          props.onSubmitSuccess(value.number,value.isdCode)
        }
        // window.location.reload(false)
        // alert("Parent's number saved")
        setIsShowMessage("Parent's number saved")
        setShowpopup(true)
        setOnSubmitPopup(false)

        if(props.refresh){
          props.refresh()
        }
        dispatch(dashboardProfileData())
        
        
      } else if(data?.data?.status=="FAILURE"){
        if(data?.data?.parentsNumberExist === "Yes"){
          setOnSubmitPopup(false)
          formik.setFieldError("number","Mobile Number Already Exist")
        }
      }
      // props.close();
    } else if (statusCode === 401) {
      logout();
    }
  };
  return (
    <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6 max-w-3xl ">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-12px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-7 w-7 lg:h-[2rem] lg:w-[2rem]" src={CloseIcon} alt=""/>
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className="flex items-center justify-center relative p-6 flex-auto w-[90vw] md:w-[40vw]">
           {onSubmitPopup ?
         <Loader/>
         :showpopup ?
           <div className="flex items-center justify-center relative p-6 flex-auto w-[90vw] md:w-[40vw]">{isShowMessage}</div>
           
           :
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col items-center justify-center"
            >
              <div className="flex space-x-2 my-2 ">
                <label className="select font-bold text-[#575556] w-[5rem] md:w-[8rem]">
                  <select
                    name="isdCode"
                    id="isdCode"
                    value={formik.values.isdCode}
                    // onChange={changeStatus}
                    onChange={formik.handleChange}
                    className=" md:p-[0.4rem] lg:p-2.5 my-1  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px] leading-[39px]  "
                  >
                    <option >select</option>
                    {isdCodeList?.map((e, i) => (
                      <option key={i} value={e.isdcode}>
                        {e.isdcode} {e.country}
                      </option>
                    ))}
                  </select>
                  {formik.errors.isdCode && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.isdCode}
                      </p>
                    )}
                </label>

                <label className="">
                  <input
                    name={"number"}
                    id={"number"}
                    type={"tel"}
                    placeholder={trans('my_profile.mobile_number')}
                    value={formik.values.number}
                    
                    onChange={(e)=>{
                      if(validateNumber(e.target.value)){
                        formik.handleChange(e)
                      }
                    }}
                    className="no-spinner w-[49vw] md:w-[20vw] p-2.5 my-1  text-sm text-gray-900  rounded-[3px] border border-[#8F8F8F]"
                  />
                  <div className="h-2 mb-2">
                    {formik.errors.number && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.number}
                      </p>
                    )}
                    
                  </div>
                </label>
              </div>

            {onSubmitPopup ?
            <button
            type="submit"
           
            className=" flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold py-[1rem] md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
          >
            Submitig...
          </button>
          :
          <button
                type="submit"
               
                
                className=" flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold py-[1rem] md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
              >
                Submit
              </button>


            }  
            </form>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentsPhonePopup;
