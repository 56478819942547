import { 
  BLOCK_PROFILE,
  BLOCK_PROFILE_API_ERROR,
  BLOCK_PROFILE_SUCCESS,
  CLEAR_ASTRO_BALANCE,
  GET_ASTRO_BALANCE,
  GET_ASTRO_BALANCE_API_ERROR,
  GET_ASTRO_BALANCE_SUCCESS,
  GET_HOROSCOPE_MATCH,
  GET_HOROSCOPE_MATCH_API_ERROR,
  GET_HOROSCOPE_MATCH_SUCCESS,
  MESSAGE_THREAD, MESSAGE_THREAD_API_ERROR, MESSAGE_THREAD_SUCCESS, 
  SENT_MESSAGE, SENT_MESSAGE_API_ERROR, SENT_MESSAGE_SUCCESS } from "./ActionTypes";


  //get message thread
export const getMessage = (data, id, email, type, notificationId) => {
    //console.log(data, id, email);
    return {
      type: MESSAGE_THREAD,
      payload: { data, id, email, type, notificationId }
    };
  };

  export const getMessageSuccess = data => {
    //console.log(data,"12344");
    return {
      type: MESSAGE_THREAD_SUCCESS,
      payload: data,
    };
  };
  
  export const getMessageApiError = error => {
    return {
      type: MESSAGE_THREAD_API_ERROR,
      payload: error,
    };
  };

  //sent message thread
  export const sentMessage = (id, email, msg, toProfileId,notificationId) => {
    //console.log(id, email, msg, toProfileId);
    return {
      type: SENT_MESSAGE,
      payload: {id, email,  msg, toProfileId,notificationId}
    };
  };

  export const sentMessageSuccess = data => {
    //console.log(data,"12344");
    return {
      type: SENT_MESSAGE_SUCCESS,
      payload: data,
    };
  };
  
  export const sentMessageApiError = error => {
    return {
      type: SENT_MESSAGE_API_ERROR,
      payload: error,
    };
  };

  //block profile
  export const blockProfile = (status, toProfileId) => {
    //console.log(toProfileId);
    return {
      type: BLOCK_PROFILE,
      payload: {status, toProfileId}
    };
  };

  export const blockProfileSuccess = data => {
    //console.log(data,"12344");
    return {
      type: BLOCK_PROFILE_SUCCESS,
      payload: data,
    };
  };
  
  export const blockProfileApiError = error => {
    return {
      type: BLOCK_PROFILE_API_ERROR,
      payload: error,
    };
  };

  //astro balance
  export const getAstroBalance = () => {
    return {
      type: GET_ASTRO_BALANCE,
      payload: {}
    };
  };

export const clearAstroBalance = () => {
  return {
    type: CLEAR_ASTRO_BALANCE,
    payload: {}
  };
};

  export const getAstroBalanceSuccess = data => {
    //console.log(data,"12344");
    return {
      type: GET_ASTRO_BALANCE_SUCCESS,
      payload: data,
    };
  };
  
  export const getAstroBalanceApiError = error => {
    return {
      type: GET_ASTRO_BALANCE_API_ERROR,
      payload: error,
    };
  };

   //horoscope match
   export const getHoroscopeMatch = (toProfileId) => {
    //console.log(toProfileId,"12344");
    return {
      type: GET_HOROSCOPE_MATCH,
      payload: {toProfileId}
    };
  };

  export const getHoroscopeMatchSuccess = data => {
    //console.log(data,"12344");
    return {
      type: GET_HOROSCOPE_MATCH_SUCCESS,
      payload: data,
    };
  };
  
  export const getHoroscopeMatchApiError = error => {
    return {
      type: GET_HOROSCOPE_MATCH_API_ERROR,
      payload: error,
    };
  };

  