import {
    REGISTER_EMAIL_CHECK,
    REGISTER_EMAIL_CHECK_SUCCESS,
    REGISTER_PHONE_CHECK,
    REGISTER_PHONE_CHECK_SUCCESS,
    REGISTER_USER,
    REGISTER_SUCCESS,
    API_ERROR,
    REGISTER_EMAIL_CHECK_FAILURE,
    RESET_API_ERROR,
  } from "./ActionTypes";


  //email check

  export const registerEmailCheck = (values) => {
    //console.log(values,"1234567890");
    return {
      type: REGISTER_EMAIL_CHECK,
      payload: { values },
    };
  };

  export const registerEmailCheckSuccess = emailId => {
    //console.log(emailId,"12344");
    return {
      type: REGISTER_EMAIL_CHECK_SUCCESS,
      payload: emailId,
    };
  };

  export const registerEmailCheckFailure = emailId => {
    //console.log(emailId,"12344");
    return {
      type: REGISTER_EMAIL_CHECK_FAILURE,
      payload: emailId,
    };
  };

   //phone check

   export const registerPhoneCheck = (phoneNumber) => {
    //console.log(phoneNumber,"phoneNumber");
    return {
      type: REGISTER_PHONE_CHECK,
      payload: { phoneNumber },
    };
  };

  export const registerPhoneCheckSuccess = (phoneNumber) => {
    return {
      type: REGISTER_PHONE_CHECK_SUCCESS,
      payload: { phoneNumber },
    };
  };


  //register form
  
  export const registerUser = (user, history) => {
    //console.log(history,"12344");
    return {
      type: REGISTER_USER,
      payload: { user, history },
    };
  };
  
  export const registerSuccess = user => {
    //console.log(user,"12344");
    return {
      type: REGISTER_SUCCESS,
      payload: user,
    };
  };
  
  export const apiError = error => {
    //console.log(error.data,"12345");
    return {
      type: API_ERROR,
      payload: error,
    };
  };

  export const restApiError = () => {
    //console.log(error.data,"12345");
    return {
      type: RESET_API_ERROR,
    };
  };