import { call, put, takeEvery } from "redux-saga/effects";
import Constants from "../../Constants/constants";


// Login Redux States
import { STORE_REGISTER_FORM_DATA, STORE_REGISTER_FORM_SUBMIT} from "./ActionTypes";
import { getStoreRegisterDataFailure, getStoreRegisterDataSuccess, storeRegisterFormError, storeRegisterFormSuccess, storeRegisterFormSuccessPopup } from "./Action";
import { Store_Register_Detail, Store_Register_Form_Submit } from "../../Helpers/api_helpers";
import RouteSegments from "../../Routes/RouteSegment";



function* getStoreRegistrationData() {
  //console.log(user,"123");
  try { // 
    const response = yield call(Store_Register_Detail);
    console.log(response,"register response");
    if (response.data.status === "Success") {
      yield put(getStoreRegisterDataSuccess(response));
      ////console.log("123");
    } else {
      ////console.log("123");
      yield put(getStoreRegisterDataFailure(response));
    }
  } catch (error) {
    ////console.log("123");
    yield put(getStoreRegisterDataFailure(error));
  }
}


function* storeRegistrationFormSubmit({ payload: { user, history } }) {
  try { 
    const response = yield call(Store_Register_Form_Submit, {
      "name": user?.name,
      "profileForId": user?.profileId,
      "email": user?.email,
      "mobilenumber": user?.phone,
      "storeId": user?.store,
      "storeExecutiveName": user?.executiveName,
      "storeExecutiveId": user?.executiveID,
      "kioskName": user?.kioskName,
      "kioskId": user?.kioskID,
      "registerFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      "action": user?.password?"savestoreuser":"savestore",
      "pwdStore": user?.password?user?.password:undefined
    });
    console.log(response.data,"register response");
    if (response.data.status === "Success") {
      if(response.data.data.message == "User Registration Successfully"){
        localStorage.setItem(Constants.loginLocalStorageKeys.loginName,response.data.data.email) //username
        localStorage.setItem(Constants.loginLocalStorageKeys.loginPassword,user?.password ?? "")
        localStorage.setItem(Constants.loginLocalStorageKeys.loginId,response.data.data.userid) //userid
        localStorage.setItem(Constants.loginLocalStorageKeys.isLoggedIn,true) //userid
        if(user?.password){
          window.location.href = response.data.data.nextpage
        }
        else{
          yield put(storeRegisterFormSuccessPopup(response));
        }
      }
      else{
        yield put(storeRegisterFormSuccess(response));
      }
    } else {
      yield put(storeRegisterFormError(response));
    }
  } catch (error) {
    yield put(storeRegisterFormError(error));
  }
}


function* storeRegistrationSaga() {
  yield takeEvery(STORE_REGISTER_FORM_DATA, getStoreRegistrationData);
  yield takeEvery(STORE_REGISTER_FORM_SUBMIT, storeRegistrationFormSubmit);
}

export default storeRegistrationSaga;
