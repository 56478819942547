import { CONTENT_CARD_CLICK, CONTENT_CARD_CLICK_API_ERROR, CONTENT_CARD_CLICK_SUCCESS } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const ContentCard = (state = initialState, action) => {
    ////console.log(action.type,"12345");
    switch (action.type) {

        //Content Card onclick
        case CONTENT_CARD_CLICK:
          state = {
            ...state,
            loading: true,
          };
          break;
          case CONTENT_CARD_CLICK_SUCCESS:
          console.log(action.payload.data,"statse");
          state = {
            ...state,
            contentCardData:action.payload.data,
            loading: false,
          };
          break;
        case CONTENT_CARD_CLICK_API_ERROR:
          ////console.log( action.payload.data,"123456");
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
          break;
       
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default ContentCard;
  