//city api call
export const SEARCH_BY_CITY = "SEARCH_BY_CITY"
export const SEARCH_BY_CITY_SUCCESS = "SEARCH_BY_CITY_SUCCESS"
export const SEARCH_BY_CITY_API_ERROR = "SEARCH_BY_CITY_API_ERROR"

//education api call
export const SEARCH_BY_EDUCATION = "SEARCH_BY_EDUCATION"
export const SEARCH_BY_EDUCATION_SUCCESS = "SEARCH_BY_EDUCATION_SUCCESS"
export const SEARCH_BY_EDUCATION_API_ERROR = "SEARCH_BY_EDUCATION_API_ERROR"

//occupation api call
export const SEARCH_BY_OCCUPATION = "SEARCH_BY_OCCUPATION"
export const SEARCH_BY_OCCUPATION_SUCCESS = "SEARCH_BY_OCCUPATION_SUCCESS"
export const SEARCH_BY_OCCUPATION_API_ERROR = "SEARCH_BY_OCCUPATION_API_ERROR"

//searchbyfilter api call
export const SEARCH_BY_FILTER = "SEARCH_BY_FILTER"
export const SEARCH_BY_FILTER_SUCCESS = "SEARCH_BY_FILTER_SUCCESS"
export const SEARCH_BY_FILTER_API_ERROR = "SEARCH_BY_FILTER_API_ERROR"
