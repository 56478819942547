import axios from "axios";
import { IP_Address } from "../Constants/IpAddress";

const axiosInstance = axios.create({ withCredentials: true });

export const GET = async (url, params) => {
  let getResponse = await axiosInstance.get(url, { params });
  if (getResponse.status === 200) {
    return { data: getResponse.data, statusCode: getResponse.status };
  } else {
    return {
      message: getResponse.message,
      statusCode: getResponse.statusCode,
    };
  }
};

export const POST = async (url, body, options) => {
  let postresponse = await axiosInstance.post(url, body, options).catch((e) => {
    
    return { data: null, message: e.message, statusCode: e.response.data.statusCode };
  });
  //console.log('status code',postresponse)
  if (postresponse.status === 200) {
    return { data: postresponse.data, statusCode: postresponse.data.statusCode };
  } else {
    return {
      data: null,
      message: postresponse.message,
      statusCode: postresponse.data.statusCode,
    };
  }
};
