import React from "react";
import { useTranslation } from 'react-i18next';

export default function FairPolicyDeatil() {
  const { t: trans } = useTranslation();
  return (
    <div className="text-left text-[#676767]">
      <p className=" ">{trans('fair_usage_policy_popup.we_wish_you_happy_search_and_q')}</p>
      <br />
      <p className=" ">
        {trans('fair_usage_policy_popup.the_below_restrictions_in_the')}
      </p>
      <p className=" underline pt-[1rem] ">{trans('fair_usage_policy_popup.unlimited_validity_packages')}</p>
      <p className="pl-[1rem] flex pt-[1rem] ">
        <p> 1. </p>
        <p> {trans('fair_usage_policy_popup.you_will_be_unable_to_use_the')} </p>
      </p>
      <div className="pl-[2rem]">
        <p>1.{trans('fair_usage_policy_popup.date_of_marriage')}</p>
        <p>2.{trans('fair_usage_policy_popup.two_years_from_purchase_date')}</p>
        <p>3.{trans('fair_usage_policy_popup.number_days_after_you_have_sto', { number: 90 })}</p>
        <p>4.{trans('fair_usage_policy_popup.date_of_profile_deletion_or_su')}</p>
      </div>
      <p className="pl-[1rem] flex ">
        <p>  2. </p>
        <p> {trans('fair_usage_policy_popup.you_can_send_a_maximum_of_numb', { number: 100 })}
        </p>
      </p>
      <p className="pl-[1rem]">3.{trans('fair_usage_policy_popup.case_for_extension_of_validity')}</p>
      <div className="pl-[2rem]">
        <p>
          1.{trans('fair_usage_policy_popup.you_have_to_buy_a_new_package')}
        </p>
        <p>
          2.{trans('fair_usage_policy_popup.if_the_phone_count_is_not_exha')}
        </p>
      </div>
      {/* ///ulim */}
      <p className=" underline pt-[1rem] ">
        {trans('fair_usage_policy_popup.unlimited_phone_numbers_packag')}
      </p>
      <p className="pl-[1rem] flex pt-[1rem] ">
        <p> 1. </p>
        <p>{trans('fair_usage_policy_popup.you_will_be_unable_to_use_the')}
        </p>
      </p>
      <div className="pl-[2rem]">
        <p>1.{trans('fair_usage_policy_popup.if_you_use_more_than_number_ph', { number: 10 })}</p>
        <p>2.{trans('fair_usage_policy_popup.if_you_use_more_than_number_ph', { number: 1000 })}</p>

      </div>
      <p className="pl-[1rem] flex ">
        <p> 2. </p>
        <p>{trans('fair_usage_policy_popup.you_can_send_a_maximum_of_numb', { number: 100 })} </p>

      </p>

      <p className="pl-[1rem]">3.{trans('fair_usage_policy_popup.case_for_extension')}</p>
      <div className="pl-[2rem]">
        <p>
          1.{trans('fair_usage_policy_popup.you_have_to_buy_a_new_package')}
        </p>
        <p>
          2. {trans('fair_usage_policy_popup.if_the_validity_is_not_over_th', { number: 1000 })}
        </p>
      </div>
      {/* ///ulimited*/}
      <p className=" underline pt-[1rem] ">
        {trans('fair_usage_policy_popup.truly_unlimited_package')}
      </p>
      <p className="pl-[1rem] flex pt-[1rem] ">
        <p> 1. </p>
        <p> {trans('fair_usage_policy_popup.you_will_be_unable_to_use_the')}  </p>
      </p>
      <div className="pl-[2rem]">
        <p>1.{trans('fair_usage_policy_popup.date_of_marriage')}</p>
        <p>2.{trans('fair_usage_policy_popup.two_years_from_purchase_date')}</p>
        <p>3.{trans('fair_usage_policy_popup.after_day_number_days_after_yo', { day_number: 90 })}</p>
        <p>4.{trans('fair_usage_policy_popup.date_of_profile_deletion_or_su')}</p>
      </div>
      <p className="pl-[1rem] flex ">
        <p>2.</p>
        <p> {trans('fair_usage_policy_popup.you_will_be_unable_to_use_the')}
        </p>
      </p>
      <div className="pl-[2rem]">
        <p>1.{trans('fair_usage_policy_popup.if_you_use_more_than_number_ph', { number: 10 })}</p>
        <p>2.{trans('fair_usage_policy_popup.if_you_use_more_than_number_ph', { number: 1000 })}</p>

      </div>
      <p className="pl-[1rem] flex ">
        <p>3.</p>
        <p>{trans('fair_usage_policy_popup.you_can_send_a_maximum_of_numb', { number: 100 })}</p>
      </p>
      <p className="pl-[1rem]">4.{trans('fair_usage_policy_popup.case_for_extension')}</p>
      <div className="pl-[2rem]">
        <p>
          1.{trans('fair_usage_policy_popup.in_the_event_the_member_is_unm', { number: 1000 })}
        </p>

      </div>
      <p className=" underline pt-[1rem] ">
        {trans('fair_usage_policy_popup.matches_via_whatsapp')}
      </p>
      <p className="pl-[1rem] flex pt-[1rem] ">
        <p> 1. </p>
        <p> {trans('fair_usage_policy_popup.matches_via_whatsapp_is_an_add')}
        </p>
      </p>
      <p className="pl-[1rem] flex">
        <p> 2. </p>
        <p> {trans('fair_usage_policy_popup.the_validity_of_matches_via_wh')}
        </p>
      </p>
      <p className="pl-[1rem] flex">
        <p> 3. </p>
        <p> {trans('fair_usage_policy_popup.all_fair_usage_restrictions_of')}
        </p>
      </p>
      <p className="pl-[1rem] flex">
        <p> 4. </p>
        <p> {trans('fair_usage_policy_popup.if_the_main_pack_is_suspended')}
        </p>
      </p>
    </div>
  );
}
