import { call, put, takeEvery } from "redux-saga/effects";

// Redux States
import { BANNER_REDIRECTION_AKHAYATRITIYA, BANNER_REDIRECTION_DEVELOPER } from "./ActionTypes";
import { bannerRedirectionAkhayatritiyaApiError, bannerRedirectionAkhayatritiyaSuccess, bannerRedirectionDeveloperApiError, bannerRedirectionDeveloperSuccess } from "./Action";
import { BannerRedirectionData } from "../../Helpers/api_helpers";


function* bannerRedirectionDeveloperCall({ payload: {pathname, param} }) {
  console.log(pathname, param,"pathname, param");
  try {
    const response = yield call(BannerRedirectionData, pathname, param);
    if (response.data.status === "Success") {
      yield put(bannerRedirectionDeveloperSuccess(response));
    } else {
      yield put(bannerRedirectionDeveloperApiError(response));
    }
  } catch (error) {
    yield put(bannerRedirectionDeveloperApiError(error));
  }
}

function* bannerRedirectionAkhayatritiyaCall({ payload: {pathname, param} }) {
  console.log(pathname, param,"pathname, param");
  try {
    const response = yield call(BannerRedirectionData, pathname, param);
    if (response.data.status === "Success") {
      yield put(bannerRedirectionAkhayatritiyaSuccess(response));
    } else {
      yield put(bannerRedirectionAkhayatritiyaApiError(response));
    }
  } catch (error) {
    yield put(bannerRedirectionAkhayatritiyaApiError(error));
  }
}


function* bannerRedirectionSaga() {
  yield takeEvery(BANNER_REDIRECTION_DEVELOPER, bannerRedirectionDeveloperCall);
  yield takeEvery(BANNER_REDIRECTION_AKHAYATRITIYA, bannerRedirectionAkhayatritiyaCall);
}

export default bannerRedirectionSaga;
