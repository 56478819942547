import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
//Images
import headerimg from '../../Components/Kalyanlite/assets/Images/topheaderimage.png'
import kalyanprofile from '../../Components/Kalyanlite/assets/Images/kalayanprofile.png'
import profilephoto from '../../Components/Kalyanlite/assets/Images/profilephoto.svg'
import leftwelcome from '../../Components/Kalyanlite/assets/Images/leftwelcome.svg'
import rightwelcome from '../../Components/Kalyanlite/assets/Images/rightwelcome.svg'
import contentimage from '../../Components/Kalyanlite/assets/Images/contentimage.png'
import leftdesign from '../../Components/Kalyanlite/assets/Images/design.svg'
import leftsdesign from '../../Components/Kalyanlite/assets/Images/leftdesign.svg'
import bottomimg from '../../Components/Kalyanlite/assets/Images/bottomimage.png'
import phoneno from '../../Components/Kalyanlite/assets/Images/Group 2608.svg'
import parentno from '../../Components/Kalyanlite/assets/Images/Group 2609.svg'
import flowerimg from '../../Components/Kalyanlite/assets/Images/flowerimage.svg'
import profileround from '../../Components/Kalyanlite/assets/Images/profileround.svg'
import staricon from '../../Components/Kalyanlite/assets/Images/staricon.svg'
import profilepic from '../../Components/Kalyanlite/assets/Images/whatsAppimage.png'
import KalyanLogo from '../../Images/KM logo@2x.png'

import PhonePopupLite from '../DashboardComponents/PhonePopupLite'
import { POST } from "../../Services/api_services";
import * as KalyanLiteSelector from "../../Store/KalyanLite/selectors";
import {
    getProfileMatch,
    matchRequest,
    viewPhone,

} from "../../Store/KalyanLite/Action";
import EndPoints from "../../Constants/EndPoints";
import config from "../../config";
import { conversationStatus, offlineprofile, sendMessage } from '../../Store/Chats/Action'
import { BulkRequestPopup, CommonPayNowPopup, CommonSuccessPopup } from "../CommonPopup";
import { useAnalytics } from "../../Hooks/usePageChange";
import { LoginContext } from "../../Contexts/LoginContext";
import PhonePopup from "../DashboardComponents/PhonePopup";
import { Conversation_Data, sagaPost } from "../../Helpers/api_helpers";
import Constants from "../../Constants/constants";
import { dashboardProfileData } from "../../Store/DashboardAPI/Action";
import Loader from "../Loader";
import SuccessPopup from "./Popup/SuccessPopup";
// import { loginFrom } from "../Constants/constants";
import KMLogo from '../Kalyanlite/assets/Images/KM logo.png';
import LoaderGif from "../../Gif/loader.gif" 
import { getDeviceInfo } from "./utils";
import { useTranslation } from "react-i18next";

const DetailsItem = (props) => {
    return (

        props?.value !=  "_" ? 

        <div className="space-y-3 leading-[19px] md:leading-[23px] lg:leading-[17px] xl:leading-[26px]">
            <p className="text-white font-regular text-[15px] md:text-[26px] lg:text-[28px]  font-segoe-ui break-words" style={{ whiteSpace: 'nowrap' }}>
                {props.title}
            </p>
            <span
                className="text-white text-[15px] md:text-[26px] lg:text-[28px] break-words leading-[32px] font-bold font-segoe-ui"
                style={{ display: 'inline-block' }}
            >
                {props.value}
            </span>
        </div>
        :
        <></>

    );
};

const BasicDetailsItem = (props) => {
    return (
        <div className=" grid grid-cols-12 md:grid-cols-9 lg:grid-cols-9 mt-4 gap-3 md:mt-[1rem] items-baseline  ">
            <div className="col-span-6 md:col-span-4  mt-4">
                <p className="text-[15px] md:text-[30px] text-white     ">
                    {props.title}
                </p>
            </div>
            <div className="col-span-6 md:col-span-5 md:pr-[1rem] lg:pr-[4rem] md:pl-[1rem] ">
                <p className=" text-[15px] md:text-[30px] text-white   font-bold">
                    {props.value}
                </p>
            </div>
        </div>

    );
};

const KalyanLogoItem = (props) => {
    return (
        <div className={`flex justify-center items-center border-[.5rem] border-[#FFE0AD] ${props.From == "top" ? "border-b-0" : "border-t-0"}`}>
            <img className='w-[40vw]' src={KalyanLogo} alt="" loading="eager"/>
        </div>

    );
};


export const OfflinePage = () => {

    // const profileData = useSelector(KalyanLiteSelector.getMatchProfileById);
    const dispatch = useDispatch();
    const location = useLocation();

    const { t: trans } = useTranslation();


    const { viewprofileid, isProfileLoading, phoneProfileData } = useSelector((state) => {
        return {
            viewprofileid: state.KalyanLite?.currentMatch?.data?.profileid,
            isProfileLoading: state?.KalyanLite?.matchProfileLoading,
            phoneProfileData: state?.KalyanLite?.profileData
        };

    });

    let { responseData } = useSelector(state => ({
        responseData: state.Chats?.profiledata
    }));
    
    const userData = useSelector(
        (state) => state?.DashBoardAPI?.ProfileData?.data,
    );

    const { ProfileData, matchesListData } = useSelector(state => ({
        ProfileData: state.DashBoardAPI?.ProfileData?.data,
        matchesListData: state.DashBoardAPI?.matchesListData?.data
      }));

    const profileData = useSelector((state) => state.KalyanLite?.matchProfiles[location?.state?.profile_id ?? localStorage.getItem('profileId')]);

    const { conversationStatusData } = useSelector((state) => {
        return {
          conversationStatusData: state?.Chats?.conversationStatusData,
        };
      });

    console.log(phoneProfileData, "phoneProfileData",profileData);


    const isV3User = localStorage.getItem("isV3User");

    const [showPhonePopup, setShowPhonePopup] = useState(false);
    const [showUnBlockPopup, setShowUnBlockPopup] = useState(false)
    const [PhotoPopup, setPhotoPopup] = useState(false);

    const [phonePopupType, setPhonePopupType] = useState("")

    const [paynowPopupData, setPaynowPopupData] = useState({ show: false, title: "", content: "" })
    const [nonValidationPopupData, setNonValidationPopupData] = useState({ show: false, title: "", content: "" })
    const [showBulkRequestPopupData, setShowBulkRequestPopupData] = useState({ show: false, title: "", content: "", message: "" })
    const [SuccessPopupState, setSuccessPopupState] = useState({
        show: false,
        message: "",
      });

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [userProfileId, setUserProfileId] = useState(location?.state?.profile_id ?? localStorage.getItem('profileId'))
    const [phoneNumData, setPhoneNumData] = useState(profileData?.viewPhone);

    const { loginDetail, logout } = useContext(LoginContext);

    const [showLoader, setShowLoader] = useState(true);


    const { onClickTrack } = useAnalytics()


    //for parents number
    const [isParentsNumberAvailble,setIsParentsNumberAvailble] =useState("")
    const [isParentsNumberRequested,setIsParentsNumberRequested] =useState("")

        const loggedInProfileStatus = userData?.status
        
        const profileblockedByMe = profileData?.profiledetails?.profileblockedByMe === "Y";
        
        const loggedInUserPaid = userData?.isPaid === "Y"
        
    console.log(isParentsNumberAvailble, isParentsNumberRequested, "isParentsNumberAvailble");
    console.log(phoneNumData, profileblockedByMe, loggedInUserPaid, "viewPhoneData12",viewprofileid);

    const createConversation = async () => {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Constants.loginLocalStorageKeys.chatLogin,
            )}`,
          },
          data: {
            receiver_id: parseInt(profileData?.profiledetails?.profileid),
            page_size: 10,
            page: 1,
            receiver_status:loggedInProfileStatus,
            user_id: localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
          },
        };
      
        const response = await Conversation_Data(axiosConfig);
      
        if (response.status === 200) {
          const conversation_id = response?.data?.conversation_id;
      
          // Dispatch action to update chat list
          // dispatch(getConversationList());
      
          return { status: 200, conversation_id };
        } else {
          return response;
        }
      };

    const sendRequest = async (messageType) => {

        dispatch(
            matchRequest({ profileId: userProfileId, requestType: messageType }),
        );

        const bulktrigger = messageType === "Photo" || messageType === "Parents Number"

        let request = {
            email: loginDetail()[0],
            userId: loginDetail()[1],
            toProfileId: `${userProfileId}`,
            sendsms: "Y",
            typeList: [messageType],
            triggerType: "singletrigger",
            bulktrigger: bulktrigger,
        };

        let { statusCode, data } = await POST(
            `${config.api.API_URL}${EndPoints.requestPhotoHoroTrustParent()}`,
            request,
        );
        console.log("sendRequest", data);

        if (statusCode === 200) {
            let message = "";
            let content = "";
            let message_type_id;

            if (messageType === "Parents Number") {
                message = trans('send_bulk_request.parents_number_request_sent');
                content = "Request Parent's Phone Number";
                message_type_id = 7;

            }

            dispatch(offlineprofile({profile_id: userProfileId}));

            if (conversationStatusData.is_conversation_exists) {
                dispatch(
                  sendMessage({
                    receiver_id: userProfileId,
                    conversation_id: conversationStatusData?.conversation_id,
                    file: content,
                    message_type_id: message_type_id,
                  }),
                );
              } else {
                const response = await createConversation()
        
                if (response.status === 200) {
                  const conversation_id = response?.conversation_id
                  dispatch(
                    sendMessage({
                      receiver_id: userProfileId,
                      conversation_id: conversation_id,
                      file: content,
                      message_type_id: message_type_id,
                    }),
                  );
                }
              }
              if (data?.data?.recomm_count > 0 && request.bulktrigger === true) {
                setShowBulkRequestPopupData({
                  show: true,
                  title: trans('photos.success'),
                  content: message,
                //   message: data?.data?.bulkrequestcontent,
                })
              }
            //   setPhotoPopup(true);

        } else if (statusCode === 401) {
            logout();
        }
    };
    const onPhoneViewClick = (type) => {
        if (!loggedInUserPaid && profileblockedByMe) {
            setPaynowPopupData({ show: true, title: "Buy a Pack to Contact Profile", content: "Upgrade To View Phone Number" })
            return;
        }

        if (profileblockedByMe) {
            setShowUnBlockPopup(true);
            return;
        }

        setPhonePopupType(type);

        if (phoneNumData) {
            setShowPhonePopup(true);
        } else {
            viewPhoneNumber()
        }
    };

    const onParentsNumberClick = () => {


        if (profileblockedByMe) {
            setShowUnBlockPopup(true);
            return;
        }
        if (isParentsNumberAvailble) {
            onPhoneViewClick("parents")
            //   onClickTrack(EventName.View_Profile_Contact_Options_Parents_Number_Button,{[EventKeys.viewprofileid]:viewprofileid});
            return;
        }

        if (!isParentsNumberRequested) {
            if (loggedInProfileStatus === "pending") {
                setNonValidationPopupData({
                    show: true,
                    title:trans('non_validation_popup.non_validated_user'),
                    content:trans('non_validation_popup__request.your_profile_is_under_validati')
                });
            }
            // setParentsSuccessPopup(true)
            sendRequest("Parents Number");
            //   onClickTrack(EventName.View_Profile_Contact_Options_Request_Parents_Number_Button,{[EventKeys.viewprofileid]:viewprofileid,[EventKeys.messageType]:'Parents Number'});
        }


    };

    const viewPhoneNumber = async () => {
        const data = {
            url: EndPoints.viewPhoneUrl(),
            request: {
                "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
                "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
                "phoneViewFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
                "toProfileId": userProfileId
            }
        }
        const response = await sagaPost(data)
        if (response.status === 200) {
            setPhoneNumData(response.data.data)
            setShowPhonePopup(true);
        }
    }

    function formatLastSeen(lastSeenTimestamp) {
        const currentDate = new Date();
        const lastSeenDate = new Date(lastSeenTimestamp);

        console.log(lastSeenDate, "lastSeenDate");

        // Check if the user is online now
        if (lastSeenTimestamp === "Online Now") {
            return trans('view_profile.online');
        }

        // Check if the user was seen today
        if (
            currentDate.getFullYear() === lastSeenDate.getFullYear() &&
            currentDate.getMonth() === lastSeenDate.getMonth() &&
            currentDate.getDate() === lastSeenDate.getDate()
        ) {
            // Format time (e.g., Last seen today at 4:00 PM)
            return `Last seen today at ${lastSeenDate.toLocaleTimeString([], { hour: "numeric", hourCycle: "h12", minute: "2-digit" })}`;
        }

        // Check if the user was seen yesterday
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        if (
            yesterday.getFullYear() === lastSeenDate.getFullYear() &&
            yesterday.getMonth() === lastSeenDate.getMonth() &&
            yesterday.getDate() === lastSeenDate.getDate()
        ) {
            // Format time (e.g., Last seen yesterday at 6:00 PM)
            return `Last seen yesterday at ${lastSeenDate.toLocaleTimeString([], { hour: "numeric", hourCycle: "h12", minute: "2-digit" })}`;
        }

        // Format date as DD/MM/YY (e.g., Last seen at 2/7/23)
        const day = lastSeenDate.getDate().toString().padStart(2, "0");
        const month = (lastSeenDate.getMonth() + 1).toString().padStart(2, "0");
        const year = lastSeenDate.getFullYear().toString().slice(2);
        const lastseenText = trans('view_profile.last_seen_at');


        return `${lastseenText} ${day}/${month}/${year}`;
    }



    const closeBulkRequestPopup = () => {
        setShowBulkRequestPopupData({ show: false, content: "", message: "", title: "" })
      }
    const showSuccessPopup = (message) => {
        setSuccessPopupState({ show: true, message });
    };

    const closeSuccessPopup = () => {
        setSuccessPopupState({ show: false, message: "" });
    };

      const onBulkRequestSend = (status) => {
        if (status) {
          apiCallBulkRequest()
        } else {
          closeBulkRequestPopup()
        }
      }

      const apiCallBulkRequest = async () => {
    
        let request = {
          email: loginDetail()[0],
          userId: loginDetail()[1],
          "typeList": [
            "Photo",
            "Parents Number"
          ],
          "triggerType": "bulk"
        }
    
        let { statusCode, data } = await POST(
          `${config.api.API_URL}${EndPoints.bulkrequest()}`,
          request
        );
    
        if (statusCode === 200) {
          closeBulkRequestPopup()
          showSuccessPopup("Request Sent Successfully");
        }
    
      }

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem('profileId',userProfileId)
        dispatch(offlineprofile({profile_id: userProfileId}));
        dispatch(getProfileMatch({
            email: loginDetail()[0],
            userId: loginDetail()[1],
            viewprofileid: userProfileId,
            callFrom:"OfflinePage",
            status: "new",
            loginFrom:getDeviceInfo(),
            pageno: 1,
        }));
        dispatch(conversationStatus(userProfileId))
        if(location?.state?.isPhonePopup == "Y"){
            viewPhoneNumber()   
        }
    }, []);

    useEffect(() => {

      if(responseData){
          setIsParentsNumberAvailble(responseData?.parent_mobile_number?.profile_parents_number != "")
          setIsParentsNumberRequested(responseData?.parent_mobile_number?.parent_number_request_check === true)
          setShowLoader(false);  
      }else{
          setShowLoader(true);    
      }
      
    }, [responseData,isParentsNumberAvailble, isParentsNumberRequested,profileData])
    



    return (
        <>
        {showLoader &&  <div className={`flex items-center justify-center bg-white fixed z-50 w-full h-full left-0 right-0`}>
                <div className='flex flex-col justify-center items-center'>
                    <img alt="kalyan" width="200" src={KMLogo} />
                    <img alt="" width="50" src={LoaderGif} />
                </div>
            </div>} 
            {showPhonePopup && (
                isV3User ?
                    <PhonePopupLite
                        phoneNumData={phoneNumData}
                        close={() => setShowPhonePopup(false)}
                        title={trans('view_phone_number_popup.view_phone_number')}
                        profileData={profileData}
                        isParents={phonePopupType === "parents"}
                        formatLastSeen={formatLastSeen}

                    />
                    : <PhonePopup
                        title={trans('view_phone_number_popup.view_phone_number')}
                        phoneNumData={phoneNumData}
                        profileData={profileData}
                        formatLastSeen={formatLastSeen}
                        close={() => setShowPhonePopup(false)}
                    />
            )}
            {
                paynowPopupData.show && <CommonPayNowPopup
                    close={() => setPaynowPopupData({ show: false })}
                    title={paynowPopupData.title}
                    content={paynowPopupData.content}
                />
            }
            {PhotoPopup && 
                <SuccessPopup 
                    closePopup={() => {
                    setPhotoPopup(false)
                    // setPhotoRequestVisibility(true)
                    }} 
                />}
            {
                showBulkRequestPopupData.show && (
                <BulkRequestPopup
                    close={closeBulkRequestPopup}
                    onSend={onBulkRequestSend}
                    content={showBulkRequestPopupData.content}
                    message={showBulkRequestPopupData?.message}
                    imageSrc={showBulkRequestPopupData.imageSrc}
                    title={showBulkRequestPopupData.title} />
                )
            }

        {SuccessPopupState.show && (
                <CommonSuccessPopup
                close={closeSuccessPopup}
                title={trans('photos.success')}
                content={SuccessPopupState.message}
                />
      )}
            
            <div className='h-screen fontRubik'>
                <KalyanLogoItem From={"top"} />
                <div className='relative'>
                    <img className='w-full ' src={kalyanprofile} alt="" loading="eager"/>

                    <div className='lg:hidden absolute top-[4rem] md:top-[6rem] lg:top-[10rem] w-full flex justify-center'>
                        <img className='w-[59%] h-[59%] md:w-[52%] lg:w-[40%] md:h-[40%] 2xl:w-[34%]' src={profileround} alt="" loading="eager"/>
                        <div className='absolute flex justify-center top-[1.8rem] md:top-[2.4rem] lg:top-[3rem] xl:top-[3.5rem] 2xl:top-[4.3rem] w-full h-[100vw]'>
                            <img className={`${responseData?.picturePath.includes("avatar") ? "w-[46vw] h-[46vw] md:w-[20rem] md:h-[20rem]" : "w-[47%] md:w-[42%]  lg:w-[47%] h-[47%]"} object-cover rounded-full`} src={responseData?.picturePath} alt="" loading="eager"/>
                        </div>
                    </div>

                    <div className='hidden lg:flex absolute top-[4rem] md:top-[6rem] lg:top-[10rem] 2xl:top-[14rem] w-full  justify-center'>
                        {/* Profile round image */}
                        <img 
                        style={{ width: windowWidth * 0.80, height: windowHeight * 0.80 }}
                        className='w-[59%] h-[59%] md:w-[52%] lg:w-[40%] md:h-[40%] 2xl:w-[34%]' src={profileround} alt="" loading="eager"

                        />
                        <div className='absolute flex justify-center md:top-[2.4rem] top-[1.5rem] 2xl:top-[4.3rem] xl:top-[3.5rem] lg:top-[3rem]'>
                            {/* Profile pic image */}
                            <img
                                style={{ width: windowWidth * 0.33, height: windowHeight * 0.63 }}
                                className={`w-[11rem] h-[11.5rem] profilepic  2xl:h-[29rem] 3xl:h-[33rem] object-cover rounded-full`}
                                src={responseData?.picturePath}
                                alt=""
                                loading="eager"
                            />
                        </div>
                    </div>
                    {/* Left welcome image */}
                    <div className="lg:hidden">
                    <div
                        className='absolute left-0 w-[30%] h-[30%]' >
                        <img className="relative top-[-23px] md:left-[48px] left-[14px] md:top-[-9rem] lg:left-[31%] lg:top-[-21%]" src={leftwelcome} alt=""  loading="eager"/>
                    </div>

                    {/* Right welcome image */}
                    <div
                        className='absolute right-0 w-[30%] h-[30%]'

                    >
                        <img className="relative top-[-23px] md:right-[42px] right-[14px] md:top-[-9rem] lg:top-[-21%] lg:right-[31%]" src={rightwelcome} alt=""  loading="eager"/>
                    </div>
                    </div>
                    <div className='hidden lg:flex'>
                        {/* Left welcome image */}
                        <div
                            className='absolute '
                            style={{
                                top: windowWidth == 1296 ? '29rem' : windowWidth == 1944 ? '46rem'
                                    : windowWidth == 1440 ? '32rem' : windowWidth == 1728 ? '40rem' :
                                        windowWidth == 1620 ? '38rem' : windowWidth == 1037 ? '27rem' : windowWidth <= 1098 ? '29rem' : 
                                        windowWidth == 1876 ? '49rem':'36rem',

                                left: windowWidth == 1296 ? '7rem' : windowWidth == 1944 ? '15rem' :
                                    windowWidth == 1440 ? '10rem' : windowWidth == 1728 ? '12rem' :
                                        windowWidth == 1620 ? '9rem' : windowWidth == 1037 ? '8rem' : windowWidth == 1178 ? '9rem' : '10rem',

                                right: windowWidth == 1296 ? '15rem' : windowWidth == 1944 ? '15rem' :
                                    windowWidth == 1440 ? '' : windowWidth == 1728 ? '27rem' : windowWidth == 1620 ? '7rem'
                                        : windowWidth == 1037 ? '7rem' : windowWidth == 1178 ? '24rem' : '7rem',

                                width: windowWidth == 1296 ? '30%' : windowWidth == 1944 ? '28%' :
                                    windowWidth == 1440 ? '28%' : windowWidth == 1728 ? '28%' : windowWidth == 1620 ? '30%'
                                        : windowWidth == 1037 ? '28%' : windowWidth == 1178 ? '28%' : '28%',
                            }}
                        >
                            <img className="w-full h-full" src={leftwelcome} alt="" loading="eager"/>
                        </div>

                        {/* Right welcome image */}
                        <div
                            className='absolute '
                            style={{
                                top: windowWidth == 1296 ? '29rem' : windowWidth == 1440 ? '32rem' :
                                    windowWidth == 1944 ? '46rem' : windowWidth == 1728 ? '40rem' :
                                        windowWidth == 1620 ? '38rem' : windowWidth == 1037 ? '27rem' :
                                            windowWidth == 1178 ? '29rem' :windowWidth == 1876 ? '49rem': '36rem',

                                left: windowWidth == 1296 ? '' : windowWidth == 1440 ? '' :
                                    windowWidth == 1944 ? '' : windowWidth == 1728 ? '' : windowWidth == 1620 ? ''
                                        : windowWidth == 1037 ? '' : windowWidth == 1178 ? '' : '',

                                right: windowWidth == 1296 ? '7rem' : windowWidth == 1944 ? '15rem' : windowWidth == 1728 ? '12rem' : windowWidth == 1440 ? '10rem'
                                    : windowWidth == 1620 ? '9rem' : windowWidth == 1037 ? '8rem' : windowWidth == 1178 ? '9rem' : '11rem',

                                width: windowWidth == 1296 ? '30%' : windowWidth == 1944 ? '28%'
                                    : windowWidth == 1440 ? '28%' : windowWidth == 1728 ? '28%'
                                        : windowWidth == 1620 ? '30%' : windowWidth == 1037 ? '28%' : windowWidth == 1178 ? '28%' : '28%',
                            }}
                        >
                            <img className="w-full h-full" src={rightwelcome} alt="" loading="eager"/>
                        </div>
                    </div>

                    <div className='lg:hidden absolute top-[9rem] md:top-[17rem] xl:top-[24rem] lg:top-[22rem] lg:right-[9rem] 2xl:top-[32rem] 2xl:right-[16rem] md:right-[4rem] right-[2rem] xl:right-[13rem]'>
                        <img className='w-[2.3rem] h-[2.3rem] md:w-[5rem] md:h-[5rem] lg:w-[7rem] lg:h-[7rem]' src={staricon} alt=""  loading="eager"/>
                    </div>
                    <div className='lg:hidden absolute top-[9rem] md:top-[17rem] md:left-[4rem] 2xl:top-[32rem] 2xl:left-[18rem] xl:top-[24rem] lg:top-[22rem] lg:left-[9rem] left-[2rem] xl:left-[13rem]'>
                        <img className='w-[2.3rem] h-[2.3rem] md:w-[5rem] md:h-[5rem] lg:w-[7rem] lg:h-[7rem]' src={staricon} alt=""  loading="eager"/>
                    </div>
                    <div
                        className='hidden lg:block absolute'
                        style={{
                            top: windowWidth == 1296 ? '25rem' : windowWidth == 1944 ? '38rem'
                                : windowWidth == 1440 ? '27rem' : windowWidth == 1728 ? '34rem' :
                                    windowWidth == 1620 ? '33rem' : windowWidth == 1037 ? '22rem' 
                                    : windowWidth == 1178 ? '24rem' :windowWidth == 1800 ? '30rem':windowWidth == 1876 ? '38rem': '32rem',

                            left: windowWidth == 1296 ? '15rem' : windowWidth == 1944 ? '21rem' :
                                windowWidth == 1440 ? '16rem' : windowWidth == 1728 ? '19rem' :
                                    windowWidth == 1620 ? '17rem' : windowWidth == 1037 ? '11rem' : windowWidth == 1178 ? '13rem': 
                                    windowWidth == 1800 ? '23rem': windowWidth == 1876 ? '19rem': '21rem',

                            right: windowWidth == 1296 ? '15rem' : windowWidth == 1944 ? '' :
                                windowWidth == 1440 ? '' : windowWidth == 1728 ? '27rem' : windowWidth == 1620 ? '7rem'
                                    : windowWidth == 1037 ? '7rem' : windowWidth == 1178 ? '24rem' :
                                    windowWidth == 1800 ? '7rem': windowWidth == 1876 ? '7rem': '7rem',

                            width: windowWidth == 1296 ? '7%' : windowWidth == 1944 ? '7%' :
                                windowWidth == 1440 ? '7%' : windowWidth == 1728 ? '7%' : windowWidth == 1620 ? '7%'
                                    : windowWidth == 1037 ? '7%' : windowWidth == 1178 ? '7%' :windowWidth == 1800 ? '7%':windowWidth == 1876 ? '7%': '7%',
                        }}
                    >
                        <img className="w-full h-full" src={staricon} alt=""  loading="eager"/>
                    </div>

                    {/* Right star image */}
                    <div
                        className='hidden lg:block absolute'
                        style={{
                            top: windowWidth == 1296 ? '25rem' : windowWidth == 1440 ? '27rem' :
                                windowWidth == 1944 ? '38rem' : windowWidth == 1728 ? '34rem' : windowWidth == 1620 ? '33rem' : windowWidth == 1037 ? '22rem' :
                                    windowWidth == 1178 ? '24rem' :windowWidth == 1800 ? '32rem': windowWidth == 1876 ? '38rem':'32rem',

                            left: windowWidth == 1296 ? '' : windowWidth == 1440 ? '' :
                                windowWidth == 1944 ? '' : windowWidth == 1728 ? '' : windowWidth == 1620 ? ''
                                    : windowWidth == 1037 ? '' : windowWidth == 1178 ? '' :windowWidth == 1800 ? '': '',

                            right: windowWidth == 1296 ? '15rem' : windowWidth == 1728 ? '19rem' : windowWidth == 1440 ? '17rem'
                                : windowWidth == 1944 ? '23rem' : windowWidth == 1620 ? '18rem' : windowWidth == 1037 ? '11rem'
                                 : windowWidth == 1178 ? '13rem':windowWidth == 1800 ? '23rem':windowWidth == 1876 ? '19rem': '22rem',

                            width: windowWidth == 1296 ? '7%' : windowWidth == 1944 ? '7%'
                                : windowWidth == 1440 ? '7%' : windowWidth == 1728 ? '7%'
                                    : windowWidth == 1620 ? '7%' : windowWidth == 1037 ? '7%' : windowWidth == 1178 ? '7%' 
                                    :windowWidth == 1800 ? '7%': '7%',
                        }}
                    >
                        <img className="w-full h-full" src={staricon} alt=""  loading="eager"/>
                    </div>
                    <div className=' hidden md:block absolute leftdesign  top-[11rem]  lg:top-[22rem] xl:top-[30rem] 2xl:top-[46rem] 3xl:top-43rem]  lg:right-[2rem] right-[0.4rem] md:top-[19rem] md:right-[0.6rem] xl:right-[2.5rem]'>
                        <img className='leftdesign md:!h-full line' src={leftsdesign} alt="" loading="eager"/>
                    </div>
                    <div className='hidden md:flex leftdesign  absolute lg:top-[24rem] lg:left-[1rem] md:top-[20rem] top-[11rem]  3xl:top-[48rem] 2xl:top-[46rem] xl:top-[30rem] left-[0.4rem] md:left-[0.6rem] xl:left-[2rem] 2xl:left-[2.1rem]'>
                        <img className='leftdesign md:!h-full line' src={leftsdesign} alt="" loading="eager"/>
                    </div>

                    <div className='md:hidden  leftdesign  lg:w-full absolute  pl-2 left-0 top-[4%] lg:top-[9%]' >
                        <img className='  h-[100%]' src={leftdesign} alt="" loading="eager"/>
                    </div>
                    <div className='md:hidden  leftdesign  lg:w-full absolute right-0 pr-2  lg:pr-[2rem] top-[4%] lg:top-[9%]'>
                        <img className='   h-[100%]' src={leftdesign} alt="" loading="eager"/>
                    </div>


                    <div
                        style={{
                            top: windowWidth == 1296 ? '45rem' : windowWidth == 1440 ? '48rem' :
                                windowWidth == 1944 ? '68rem' : windowWidth == 1728 ? '60rem' :
                                    windowWidth == 1620 ? '58rem' : windowWidth == 1037 ? '39rem' :
                                        windowWidth == 1178 ? '42rem' : windowWidth >= 768 ? '32rem' : windowWidth >= 375 ? '19rem' : '32rem',
                            backgroundImage: `url(${contentimage})`,
                            backgroundRepeat: 'round',
                        }}
                        className='pb-[14rem] md:pb-[30rem] pt-[4rem] md:pt-0'>
                            
                        {/* //changes */}
                        <div className='relative lg:top-[-6rem] md:top-0 justify-center text-center flex flex-col mx-[2.5rem] md:mx-auto'>
                            <h1 className='text-[#FFB324] text-[35px] md:text-[77px] lg:text-[100px] font-bold'>{responseData?.profile_user_name}</h1>
                            <p className='text-[#F5B86B] md:text-[35px] lg:text-[40px] text-[20px] font-bold'>{responseData?.mat_profile_id}</p>
                            <p className='text-[#F5B86B] md:text-[35px] lg:text-[40px] text-[20px] font-bold'>{responseData?.years} </p>
                            <p className='text-[#F5B86B] md:text-[35px] lg:text-[40px] text-[20px] font-bold'>{responseData?.height} , {responseData?.caste}</p>
                            <p className='text-[#F5B86B] md:text-[35px] lg:text-[40px] text-[20px] font-bold'>{responseData?.degree}</p>
                            <p className='text-[#F5B86B] md:text-[35px] lg:text-[40px] text-[20px] font-bold'>{responseData?.occupation}</p>
                        </div>

                        <div className='hidden md:flex gap-12 lg:gap-1 xl:gap-12  py-5 space-x-5 justify-center'>
                            <button onClick={onPhoneViewClick} className='text-[#4B0001] flex whitespace-nowrap bg-[#FFB324] text-[25px] md:pr-[1rem] md:pl-[1rem] lg:pr-[5rem] lg:pl-[3rem] py-3 gap-4 rounded-[10px] items-center'>
                                <img className='w-[2rem] h-[2rem]' src={phoneno} alt="" loading="eager"/>Phone Number</button>
                            <button onClick={onParentsNumberClick} className='text-[#4B0001] flex bg-[#FFB324] whitespace-nowrap text-[25px] md:pr-[1rem] md:pl-[1rem] lg:pr-[5rem] lg:pl-[3rem] py-3 gap-4 rounded-[10px] items-center'>
                                <img className='w-[2rem] h-[2rem]' src={parentno} alt="" loading="eager"/> 
                                {isParentsNumberAvailble
                                    ? "Parent's Number"
                                    : isParentsNumberRequested
                                        ? <p>Parent's No Requested</p>
                                        : "Request Parent's No."
                                } 
                            </button>
                        </div>
                        <div className='md:hidden flex  flex-col md:gap-5 md:flex-row gap-4 mx-[3.5rem] my-2 justify-center'>
                            <button onClick={onPhoneViewClick} className='text-[#4B0001] whitespace-nowrap flex bg-[#FFB324] text-[17px] md:text-[21px] pr-[3rem] pl-[1rem] py-2 md:py-3 gap-2 md:gap-4  rounded-[10px] '>
                                <img className='w-[1.5rem] h-[1.5rem] md:w-[2rem] md:h-[2rem]' src={phoneno} alt="" loading="eager"/>Phone Number</button>


                            <button onClick={onParentsNumberClick} className='text-[#4B0001] whitespace-nowrap flex bg-[#FFB324]  text-[17px] md:text-[21px] pr-[3rem] pl-[1rem] py-2 md:py-3 gap-2 md:gap-4  rounded-[10px] items-center '>
                                <img className='w-[1.5rem] h-[1.5rem] md:w-[2rem] md:h-[2rem]' src={parentno} alt="" loading="eager"/> {isParentsNumberAvailble
                                    ? "Parent's Number"
                                    : isParentsNumberRequested
                                        ? <p>Parent's No Requested</p>
                                        : "Request Parent's No."} </button>
                        </div>
                        <div className='flex flex-col mx-[3.5rem] md:mx-[7rem] lg:mx-[10rem] xl:mx-[16rem] '>
                            <p className='text-[#FDC071] md:text-[45px] text-[30px] font-bold pt-4'>About {responseData?.gender === "M" ? "Him" : "Her"}</p>
                            <p className='text-[#FFFFFF] text-[20px] md:text-[27px] '>
                                {responseData?.more_info ? responseData?.more_info : "_"}
                            </p>
                        </div>
                        <div className='text-center flex py-3 justify-center'>
                            <img className='md:w-[50%] lg:w-[40%] w-[70%]' src={flowerimg} alt="" loading="eager"/>
                        </div>
                        <div className='mx-[3.5rem] md:mx-[7rem] lg:mx-[10rem] xl:mx-[16rem]'>
                            
                            {(responseData?.basic_details?.mother_tongue || responseData?.basic_details?.weight || responseData?.basic_details?.marrital_status || responseData?.basic_details?.citizenship
                            || responseData?.basic_details?.lives_in || responseData?.basic_details?.physical_status) && <>
                                <p className='text-[#FDC071] md:text-[45px] text-[28px] font-bold py-4 break-words'>{trans('view_profile.basic_details')}</p>
                            <div className='grid md:grid-cols-2 grid-cols-2 gap-12'>
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.mother_tongue')}
                                    value={responseData?.basic_details?.mother_tongue ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.weight')}
                                    value={responseData?.basic_details?.weight ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.marital_status')}
                                    value={responseData?.basic_details?.marrital_status ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={"Citizenship"}
                                    value={responseData?.basic_details?.citizenship ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.lives_in')}
                                    value={responseData?.basic_details?.lives_in ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.physical_status')}
                                    value={responseData?.basic_details?.physical_status ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />


                            </div>
                            </>}
                            { (responseData?.religious_background_details?.religion || responseData?.religious_background_details?.caste || responseData?.religious_background_details?.denomination_background || responseData?.religious_background_details?.sub_caste || responseData?.religious_background_details?.if_gothra) && <>
                            <p className='text-[#FDC071] md:text-[45px] text-[28px] font-bold py-4 md:py-[2rem] lg:py-4 leading-[43px] pt-[1rem] lg:pt-[2rem] lg:pb-[2.5rem] break-words'>Religious Background</p>
                            <div className='grid md:grid-cols-2 grid-cols-2 gap-12'>
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.religion')}
                                    value={responseData?.religious_background_details?.religion ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.caste')}
                                    value={responseData?.religious_background_details?.caste ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                {responseData?.religious_background_details?.denomination_background ?
                                    <DetailsItem
                                        className={"flex md:p-2 py-1"}
                                        title={trans('view_profile.denomination')}
                                        value={responseData?.religious_background_details?.denomination_background ?? "_"
                                        }
                                        style={{ marginRight: "20px" }}
                                    /> : ""}
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('set_your_partner_expectations.subcaste')}
                                    value={responseData?.religious_background_details?.sub_caste ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />


                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={"Gothra"}
                                    value={responseData?.religious_background_details?.if_gothra ?
                                        responseData?.religious_background_details?.gothra : "_"}
                                    style={{ marginRight: "20px" }}
                                />

                            </div>
                            </>}
                            {(responseData?.horoscope_details?.star || responseData?.horoscope_details?.rashi || responseData?.horoscope_details?.dosham) &&
                                <>
                                    <p className='text-[#FDC071] md:text-[45px] text-[28px] font-bold py-4 md:py-[2rem] leading-[43px] pt-[2rem] lg:pt-[2rem] lg:pb-[2.5rem] break-words'>Horoscope</p>
                                    <div className='grid md:grid-cols-2 grid-cols-2 gap-12'>
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            title={trans('view_profile.star')}
                                            value={responseData?.horoscope_details?.star ?? "_"}
                                            style={{ marginRight: "20px" }}
                                        />
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            title={"Rashi"}
                                            value={responseData?.horoscope_details?.rashi ?? "_"}
                                            style={{ marginRight: "20px" }}
                                        />
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            title={trans('view_profile.dosham')}
                                            value={responseData?.horoscope_details?.dosham ?? "_"}
                                            style={{ marginRight: "20px" }}
                                        />
                                    </div>
                                </>
                            }
                            {(responseData?.professional_details?.degree || responseData?.professional_details?.employment_type || responseData?.professional_details?.designation) &&
                                <>
                                    <p className='text-[#FDC071] md:text-[45px] text-[28px] font-bold py-4 md:py-[2rem] leading-[43px] pt-[2rem] lg:pt-[2rem] lg:pb-[2.5rem] break-words'>Professional Details</p>
                                    <div className='grid md:grid-cols-2 grid-cols-2 gap-12'>
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            title={trans('view_profile.degree')}
                                            value={responseData?.professional_details?.degree ?? "_"}
                                            style={{ marginRight: "20px" }}
                                        />
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            title={trans('view_profile.employment_type')}
                                            value={responseData?.professional_details?.employment_type ?? "_"}
                                            style={{ marginRight: "20px" }}
                                        />
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            title={trans('view_profile.designation')}
                                            value={responseData?.professional_details?.designation ?? "_"}
                                            style={{ marginRight: "20px" }}
                                        />

                                    </div>
                                </>
                            }
                            {(responseData?.foodhabits_details?.food_habits || responseData?.foodhabits_details?.smoking || responseData?.foodhabits_details?.drinnking) && <>
                            <p className='text-[#FDC071] md:text-[45px] text-[28px] font-bold py-4 md:py-[2rem] leading-[43px] pt-[2rem] lg:pt-[2rem] lg:pb-[2.5rem] break-words'>{trans('view_profile.food_and_habits')}</p>
                            <div className='grid md:grid-cols-2 grid-cols-2 gap-12'>
                                {responseData?.foodhabits_details?.food_habits &&
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.food_habits')}
                                    value={responseData?.foodhabits_details?.food_habits ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                }
                                {responseData?.foodhabits_details?.smoking &&
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.smoking')}
                                    value={responseData?.foodhabits_details?.smoking ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                }
                                {responseData?.foodhabits_details?.drinking &&
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.drinking')}
                                    value={responseData?.foodhabits_details?.drinking ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                }

                            </div>
                            </>}
                            {(responseData?.family_background_details?.family_status || responseData?.family_background_details?.family_type || responseData?.family_background_details?.family_value) &&
                                <>
                            <p className='text-[#FDC071] md:text-[45px] text-[28px] font-bold py-4 md:py-[2rem] leading-[43px] pt-[2rem] lg:pt-[2rem] lg:pb-[2.5rem] break-words'>Family Background</p>
                            <div className='grid md:grid-cols-2 grid-cols-2 gap-12'>
                                {responseData?.family_background_details?.family_status &&
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.family_status')}
                                    value={responseData?.family_background_details?.family_status ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                }
                                {responseData?.family_background_details?.family_type &&
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    title={trans('view_profile.family_type')}
                                    value={responseData?.family_background_details?.family_type ?? "_"}
                                    style={{ marginRight: "20px" }}
                                />
                                }
                                {responseData?.family_background_details?.family_value &&
                                    <DetailsItem
                                        className={"flex md:p-2 py-1"}
                                        title={trans('view_profile.family_values')}
                                        value={responseData?.family_background_details?.family_value ?? "_"}
                                        style={{ marginRight: "20px" }}
                                    />
                                }
                            </div>
                            </>
                            }
                        </div>

                        <div className='text-center py-5 flex justify-center'>
                            <img className='md:w-[50%] lg:w-[40%] w-[70%]' src={flowerimg} alt="" loading="eager"/>
                        </div>

                        <div className='mx-[3.5rem] md:mx-[7rem] lg:mx-[10rem] xl:mx-[16rem]'>
                            {(responseData?.basic_expectations?.age || responseData?.basic_expectations?.height || responseData?.basic_expectations?.marrital_status || responseData?.basic_expectations?.mother_tongue || responseData?.basic_expectations?.physical_status 
                            || responseData?.basic_expectations?.eating_habits || responseData?.basic_expectations?.drinking_habits || responseData?.basic_expectations?.smoking_habits) && 
                                <>
                                    <p className='text-[#FDC071] text-[29px] md:text-[45px] font-bold  leading-[30px] pt-[2rem] md:pt-[3rem] md:pb-[1.3rem]'>Basic Expectations</p>
                                    <div className='grid '>
                                        {responseData?.basic_expectations?.age &&
                                            <BasicDetailsItem
                                                className={"flex md:p-2 py-1"}
                                                title={"Age"}
                                                value={responseData?.basic_expectations?.age ?? "_"}
                                                style={{}}
                                            />
                                        }
                                        {responseData?.basic_expectations?.height &&
                                            <BasicDetailsItem
                                                className={""}
                                                title={trans('view_profile.height')}
                                                value={responseData?.basic_expectations?.height ?? "_"}
                                                style={{}}
                                            />
                                        }
                                        {responseData?.basic_expectations?.marrital_status && 
                                            <BasicDetailsItem
                                                className={""}
                                                title={trans('view_profile.marital_status')}
                                                value={responseData?.basic_expectations?.marrital_status ?? "_"}
                                                style={{}}
                                            />
                                        }
                                        {responseData?.basic_expectations?.mother_tongue && 
                                            <BasicDetailsItem
                                                className={""}
                                                title={trans('view_profile.mother_tongue')}
                                                value={responseData?.basic_expectations?.mother_tongue ?? "_"}
                                                style={{}}
                                            />
                                        }
                                        {responseData?.basic_expectations?.physical_status &&
                                            <BasicDetailsItem
                                                className={""}
                                                title={trans('view_profile.physical_status')}
                                                value={responseData?.basic_expectations?.physical_status ?? "_"}
                                                style={{}}
                                            />
                                        }
                                        {responseData?.basic_expectations?.eating_habits &&
                                            <BasicDetailsItem
                                                className={""}
                                                title={trans('view_profile.eating_habits')}
                                                value={responseData?.basic_expectations?.eating_habits ?? "_"}
                                                style={{}}
                                            />
                                        }
                                        {responseData?.basic_expectations?.drinking_habits &&
                                            <BasicDetailsItem
                                                className={""}
                                                title={trans('view_profile.drinking_habits')}
                                                value={responseData?.basic_expectations?.drinking_habits ?? "_"}
                                                style={{}}
                                            />
                                        }
                                        {responseData?.basic_expectations?.smoking_habits &&   
                                            <BasicDetailsItem
                                                className={""}
                                                title={trans('view_profile.smoking_habits')}
                                                value={responseData?.basic_expectations?.smoking_habits ?? "_"}
                                                style={{}}
                                            />
                                        }
                                    </div>
                                </>
                            }
                            {(responseData?.religious_expectations?.religion || responseData?.religious_expectations?.caste || responseData?.religious_expectations?.denomination || responseData?.religious_expectations?.sub_caste) &&
                                <>
                                    <p className='text-[#FDC071] text-[29px] md:text-[45px] font-bold  leading-[30px] pt-[2rem] md:pt-[3rem] md:pb-[1.3rem]'>Religious Expectations</p>
                                    <div className='grid '>
                                            {responseData?.religious_expectations?.religion &&
                                                <BasicDetailsItem
                                                    className={"flex md:p-2 py-1"}
                                                    title={trans('view_profile.religion')}
                                                    value={responseData?.religious_expectations?.religion ?? "_"}
                                                    style={{}}
                                                />
                                            }
                                            {responseData?.religious_expectations?.caste &&
                                                <BasicDetailsItem
                                                    className={""}
                                                    title={trans('view_profile.caste')}
                                                    value={responseData?.religious_expectations?.caste ?? "_"}
                                                    style={{}}
                                                />
                                            }
                                            {responseData?.religious_expectations?.show_denomination ? <BasicDetailsItem
                                                className={""}
                                                title={trans('view_profile.denomination')}
                                                value={responseData?.religious_expectations?.denomination ?? "_"}
                                                style={{}}
                                            /> : ""}
                                            {responseData?.religious_expectations?.sub_caste &&
                                                <BasicDetailsItem
                                                    className={""}
                                                    title={trans('set_your_partner_expectations.subcaste')}
                                                    value={responseData?.religious_expectations?.sub_caste ?? "_"}
                                                    style={{}}
                                                />
                                            }
                                    </div>
                                </>
                            }
                            {(responseData?.locations?.residing_country || responseData?.locations.residing_state || responseData?.locations.residing_city) &&
                                <>
                                    <p className='text-[#FDC071] text-[29px] md:text-[45px] font-bold  leading-[30px] pt-[2rem] md:pt-[3rem] md:pb-[1.3rem]'>Location</p>
                                    <div className='grid '>
                                        {responseData?.locations?.residing_country &&
                                            <BasicDetailsItem
                                                className={"flex md:p-2 py-1"}
                                                title={trans('view_profile.country')}
                                                value={responseData?.locations?.residing_country ?? "_"}
                                                style={{}}
                                            />
                                        }
                                        {responseData?.locations?.residing_state && 
                                            <BasicDetailsItem
                                                className={""}
                                                title={trans('view_profile.residing_city')}
                                                value={responseData?.locations?.residing_state ?? "_"} style={{}}
                                            />
                                        }
                                        {responseData?.locations?.residing_city &&
                                            <BasicDetailsItem
                                                className={""}
                                                title={trans('view_profile.residing_state')}
                                                value={responseData?.locations?.residing_city ?? "_"} style={{}}
                                            />
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                </div>
                <div className=' relative xl:bottom-[39rem] 2xl:bottom-[52rem] 3xl:bottom-[56rem] lg:bottom-[33rem] md:bottom-[22rem] bottom-[10rem] lg:w-full lg:h-full'>
                    <img className='lg:w-full lg:h-full' src={bottomimg} alt="" loading="eager"/>
                    <KalyanLogoItem  From={"bottom"}/>
                </div>

            </div>
        </>
    )
}



export default OfflinePage