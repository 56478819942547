import React, { useEffect, useState, useContext } from "react";
import { loginFrom } from "../../Constants/constants";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { GET, POST } from "../../Services/api_services";
import { LoginContext } from "../../Contexts/LoginContext";
import HoroscopeComponent from "../../Components/Edit Profile/MyProfileComponents/HoroscopeComponent";
import Loader from "../Loader";
import { registerStarList } from "../../Store/Home/Action";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import { useAnalytics } from "../../Hooks/usePageChange";
import AstrologicalComponent from "./AstrologicalComponent";
import AstrologicalDetails from "../Kalyanlite/Popup/AstrologicalDetails";

const AstroComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [editMyProfileData, setEditMyProfielData] = useState();
  const [motherToungeId, setMotherToungeId] = useState(null);
  const [edithoroinfofreshData, setedithoroinfofresh] = useState();
  const [dashboard1Data, setDashboard1Data] = useState([]);
  const [profileCount, setProfileCount] = useState([]);
  const [isHoroscopeLoading, setIsHoroscopeLoading] = useState(true);
  const [isPrefLoading, setIsPrefLoading] = useState(false);
  const isV3User = localStorage.getItem("isV3User");

  console.log(edithoroinfofreshData, "edithoroinfofreshData");

  useEffect(() => {
    // setDashboard1Data(props.dashboard1Data);
    setEditMyProfielData(props.data);
  }, [props.dashboard1Data, props.data]);

  useEffect(() => {
    setProfileCount(props.proileCount);
  }, [props.proileCount]);

  useEffect(() => {
    const id = editMyProfileData?.motherTongue?.[0]?.id;
    setMotherToungeId(id);
    console.log("editMyProfileData", id);
  }, [editMyProfileData]);

  const dispatch = useDispatch();
  const apiCallStar = async (motherTounge) => {
    dispatch(registerStarList(motherTounge));
  };

  useEffect(() => {
    apiCallStar();
  }, []);

  const { starList } = useSelector(state => ({
    starList: state.Home?.starList?.data?.data,
  }));

  function horoscopeCallBack() {
    setIsHoroscopeLoading(true);
    if (motherToungeId) {
      apiCallDropDown(motherToungeId);
    }
  }

  useEffect(() => {
    horoscopeCallBack();
  }, [props.accordianSelect]);

  const apiCallDropDown = async (id) => {
    const url = `${config.api.API_URL}${EndPoints.edithoroinfofreshUrl({ motherToungeId: id })}`;
    let { statusCode, data } = await GET(url);
    if (statusCode === 200) {
      setDropDownData(data.data);
    } else if (statusCode === 401) {
      logout();
    }
    setIsHoroscopeLoading(false);
  };

  useEffect(() => {
    if (motherToungeId) {
      apiCallDropDown(motherToungeId);
    }
  }, [motherToungeId]);

  const setDropDownData = (data) => {
    setedithoroinfofresh(data);
  };

  function preferenceRefresh() {
    setIsPrefLoading(true);
  }

  return (
    <div className={` ${props?.type == "astroshow" ? "" : "p-3"}`}>
      <div className={`${isV3User ? "border-[#EAE6DF] bg-white" : "rounded-[9px] md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
        {/* {props.isEditProfileLoading || props.isPartnerPrefLoading
        ? <Loader />
          : */}
        {props?.type == "astroshow" ?
          <AstrologicalDetails
            starList={starList}
            refresh={horoscopeCallBack}
            data={editMyProfileData}
            callBackReload={props.callBackReload}
            dropDown={edithoroinfofreshData}
            setCompleteNow={props?.setCompleteNow}
            setOpenModel={props?.setOpenModel}
            close={props?.close}
            type="astroDetails"
            notificationId={props?.notificationId}
            notificationListId={props?.notificationListId}
          />
          : <AstrologicalComponent
            starList={starList}
            refresh={horoscopeCallBack}
            data={editMyProfileData}
            callBackReload={props.callBackReload}
            dropDown={edithoroinfofreshData}
            setCompleteNow={props?.setCompleteNow}
            setOpenModel={props?.setOpenModel}
            postData={props?.postData}
            isFrom={props?.isFrom}
            close={props?.close}
            isFromSms={props?.isFromSms}
            editviatrialpack={props.editviatrialpack}
            notificationId={props.notificationId}
            OnClose={props.OnClose} 
          />}
      </div>
    </div>
  );
};

export default AstroComponent;
