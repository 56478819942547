//get store register detail
export const STORE_REGISTER_FORM_DATA = "STORE_REGISTER_FORM_DATA"
export const STORE_REGISTER_FORM_DATA_SUCCESS = "STORE_REGISTER_FORM_DATA_SUCCESS"
export const STORE_REGISTER_FORM_DATA_FAILURE = "STORE_REGISTER_FORM_DATA_FAILURE"



//submit store register form
export const STORE_REGISTER_FORM_SUBMIT = "STORE_REGISTER_FORM_SUBMIT"
export const STORE_REGISTER_FORM_SUBMIT_SUCCESS = "STORE_REGISTER_FORM_SUBMIT_SUCCESS"
export const STORE_REGISTER_FORM_SUBMIT_SUCCESS_POPUP = "STORE_REGISTER_FORM_SUBMIT_SUCCESS_POPUP"
export const STORE_REGISTER_FORM_SUBMIT_API_ERROR = "STORE_REGISTER_FORM_SUBMIT_API_ERROR"
