import { BANNER_REDIRECTION, BANNER_REDIRECTION_AKHAYATRITIYA, BANNER_REDIRECTION_AKHAYATRITIYA_API_ERROR, BANNER_REDIRECTION_AKHAYATRITIYA_SUCCESS, BANNER_REDIRECTION_API_ERROR, BANNER_REDIRECTION_DEVELOPER, BANNER_REDIRECTION_DEVELOPER_API_ERROR, BANNER_REDIRECTION_DEVELOPER_SUCCESS, BANNER_REDIRECTION_SUCCESS } from "./ActionTypes";



  //developer redirection
  export const bannerRedirectionDeveloper = (pathname, param) => {
    console.log(pathname, param,"12345");
    return {
      type: BANNER_REDIRECTION_DEVELOPER,
      payload: {pathname, param}
    };
  };

  export const bannerRedirectionDeveloperSuccess = data => {
    //console.log(data,"12344");
    return {
      type: BANNER_REDIRECTION_DEVELOPER_SUCCESS,
      payload: data,
    };
  };
  
  export const bannerRedirectionDeveloperApiError = error => {
    return {
      type: BANNER_REDIRECTION_DEVELOPER_API_ERROR,
      payload: error,
    };
  };

  //akhayatritiya redirection
  export const bannerRedirectionAkhayatritiya = (pathname, param) => {
    console.log(pathname, param,"12345");
    return {
      type: BANNER_REDIRECTION_AKHAYATRITIYA,
      payload: {pathname, param}
    };
  };

  export const bannerRedirectionAkhayatritiyaSuccess = data => {
    //console.log(data,"12344");
    return {
      type: BANNER_REDIRECTION_AKHAYATRITIYA_SUCCESS,
      payload: data,
    };
  };
  
  export const bannerRedirectionAkhayatritiyaApiError = error => {
    return {
      type: BANNER_REDIRECTION_AKHAYATRITIYA_API_ERROR,
      payload: error,
    };
  };

  