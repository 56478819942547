
import { STORE_REGISTER_FORM_DATA, STORE_REGISTER_FORM_DATA_FAILURE, STORE_REGISTER_FORM_DATA_SUCCESS, STORE_REGISTER_FORM_SUBMIT, STORE_REGISTER_FORM_SUBMIT_API_ERROR, STORE_REGISTER_FORM_SUBMIT_SUCCESS, STORE_REGISTER_FORM_SUBMIT_SUCCESS_POPUP } from './ActionTypes';


  //get store detail

  export const getStoreRegisterData = (values) => {
    //console.log(values,"1234567890");
    return {
      type: STORE_REGISTER_FORM_DATA,
      payload: { values },
    };
  };

  export const getStoreRegisterDataSuccess = emailId => {
    //console.log(emailId,"12344");
    return {
      type: STORE_REGISTER_FORM_DATA_SUCCESS,
      payload: emailId,
    };
  };

  export const getStoreRegisterDataFailure = emailId => {
    //console.log(emailId,"12344");
    return {
      type: STORE_REGISTER_FORM_DATA_FAILURE,
      payload: emailId,
    };
  };


  //register form
  
  export const storeRegisterForm = (user, history) => {
    //console.log(history,"12344");
    return {
      type: STORE_REGISTER_FORM_SUBMIT,
      payload: { user, history },
    };
  };
  
  export const storeRegisterFormSuccess = user => {
    //console.log(user,"12344");
    return {
      type: STORE_REGISTER_FORM_SUBMIT_SUCCESS,
      payload: user,
    };
  };

  export const storeRegisterFormSuccessPopup = user => {
    //console.log(user,"12344");
    return {
      type: STORE_REGISTER_FORM_SUBMIT_SUCCESS_POPUP,
      payload: user,
    };
  };
  
  export const storeRegisterFormError = error => {
    //console.log(error.data,"12345");
    return {
      type: STORE_REGISTER_FORM_SUBMIT_API_ERROR,
      payload: error,
    };
  };