import {
  CALLBACK_DETAIL,
   CALLBACK_DETAIL_API_ERROR,
   CALLBACK_DETAIL_SUCCESS,
   CLEAR_DYNAMIC_PIXEL,
   COUPEN_CODE,
   COUPEN_CODE_API_ERROR,
   COUPEN_CODE_SUCCESS,
   DYNAMIC_PIXEL,
   DYNAMIC_PIXEL_API_ERROR,
   DYNAMIC_PIXEL_SUCCESS,
   MEMBERSHIP_DETAIL, MEMBERSHIP_DETAIL_API_ERROR, MEMBERSHIP_DETAIL_SUCCESS, PAY_NOW, PAY_NOW_API_ERROR, PAY_NOW_SUCCESS,
   OFFLINE_PROFILE_FAIL,OFFLINE_PROFILE,OFFLINE_PROFILE_SUCCESS
  } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
    offlineDetail: null, // Add offlineDetail property

  };
  
  const payment = (state = initialState, action) => {
    ////console.log(action.type,"12345");
    switch (action.type) {
      case MEMBERSHIP_DETAIL:
        state = {
          ...state,
          loading: true,
        };
        break;
        case MEMBERSHIP_DETAIL_SUCCESS:
        console.log(action.payload.data,"statse");
        state = {
          ...state,
          data:action.payload.data,
          loading: false,
        };
        break;
      case MEMBERSHIP_DETAIL_API_ERROR:
        ////console.log( action.payload.data,"123456");
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

        //offline

        // case OFFLINE_PROFILE:
        //   state = {
        //     ...state,
        //     loading: true,
        //   };
        //   break;
        //   case OFFLINE_PROFILE_SUCCESS:
        //   console.log(action.payload.data,"statse1");
        //   state = {
        //     ...state,
        //     profiledata:action.payload.data,
        //     loading: false,
        //   };
        //   break;
        // case OFFLINE_PROFILE_FAIL:
        //   ////console.log( action.payload.data,"123456");
        //   state = {
        //     ...state,
        //     error: action.payload.data,
        //     loading: false,
        //     isUserLogout: false,
        //   };
        //   break;

        //request callback
        case CALLBACK_DETAIL:
          state = {
            ...state,
            loading: true,
          };
          break;
          case CALLBACK_DETAIL_SUCCESS:
          //console.log(action.payload.data,"statse");
          state = {
            ...state,
            callbackdata:action.payload.data,
            loading: false,
          };
          break;
        case CALLBACK_DETAIL_API_ERROR:
          ////console.log( action.payload.data,"123456");
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
          break;

        //coupen code
        case COUPEN_CODE:
          state = {
            ...state,
            loading: true,
          };
          break;
          case COUPEN_CODE_SUCCESS:
          //console.log(action.payload.data,"statse");
          state = {
            ...state,
            applyCoupenData:action.payload.data,
            loading: false,
          };
          break;
        case COUPEN_CODE_API_ERROR:
          ////console.log( action.payload.data,"123456");
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
          break;

          //cpay now
        case PAY_NOW:
          state = {
            ...state,
            loading: true,
          };
          break;
          case PAY_NOW_SUCCESS:
          //console.log(action.payload.data,"statse");
          state = {
            ...state,
            payNowData:action.payload.data,
            loading: false,
          };
          break;
        case PAY_NOW_API_ERROR:
          ////console.log( action.payload.data,"123456");
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
          break;

           //cpay now
        case DYNAMIC_PIXEL:
          state = {
            ...state,
            loading: true,
          };
          break;
          case DYNAMIC_PIXEL_SUCCESS:
          //console.log(action.payload.data,"statse");
          state = {
            ...state,
            dynmaicPixelData:action.payload.data,
            loading: false,
          };
          break;
        case DYNAMIC_PIXEL_API_ERROR:
          ////console.log( action.payload.data,"123456");
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
          break;
      case CLEAR_DYNAMIC_PIXEL:
        state = { ...state, dynmaicPixelData:null };
        break;

      default:
        state = { ...state };
        break;
    }

    return state;
  };
  
  export default payment;
  