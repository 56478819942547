import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';

//validation
import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux";

//components
import {changeMobileNo} from "../../Store/Login/Action";
import RouteSegments from '../../Routes/RouteSegment';

//context
import { LoginContext } from '../../Contexts/LoginContext';
import { useContext } from 'react';
import { blockInvalidCharForNumber } from '../../Constants/constants';
import { useTranslation } from 'react-i18next';







const ChangeMobileNumberForm = (props) => {
    const {mobileNumberForOTP, setMobileNumberForOTP} = useContext(LoginContext)
    //console.log(props,"234567890g");
    const dispatch = useDispatch();
    const { t: trans } = useTranslation();



    const [showHidePassword, changeShowHidePassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")

    

//Error msg for  form
const formik = useFormik({
    initialValues:{
      ISDCode: '+91',
      MobileNumber: ''
    },
    validationSchema:
        Yup.object().shape({
            ISDCode: Yup.string()
              .required('Please select ISDcode'),
        //     MobileNumber: Yup.string()
        //     .required('Please enter mobile number')
        //     .min(10, "Length should be 10")
        //   })
        MobileNumber: Yup.string()
        .test("india", "Please enter a valid Mobile Number", function(value) {
          const { ISDCode } = this.parent;
          if (ISDCode === "+91") {
            return !value || value.length === 10 ? true : this.createError({ message: trans('family.please_enter_a_valid_mobile_nu'), path: "ISDCode" });
          }
          return true;
        })
        .test("other", "Please enter a valid Mobile Number", function(value) {
          const { ISDCode } = this.parent;
          if (ISDCode !== "+91") {
            return  !value || (value.length >= 7 && value.length <= 15) ? true : this.createError({ message: trans('family.please_enter_a_valid_mobile_nu'), path: "ISDCode" });
          }
          return true;
        })
        .required(trans('family.please_enter_mobile_number')),
}),
    onSubmit: (values) => {
        FormSubmission(values);
      },
    })

    // form submit
    const FormSubmission = (values) => {
        //console.log(values);
            dispatch(changeMobileNo(values))
    }
    
    const { Changeddata } = useSelector(state => ({
        Changeddata: state.Login?.Changeddata?.data
      }));

      //console.log(Changeddata,"Changeddata");

    useEffect(() => {
        if(Changeddata?.status == "SUCCESS"){
            // window.location.href = RouteSegments.PROFILE_OTP
            setMobileNumberForOTP(Changeddata?.mobileNo)
            setErrorMessage("")

        }
        else {
            setErrorMessage(Changeddata?.statusMessage)
            
        }
        
    },[Changeddata])

    useEffect(() => {
        setErrorMessage("")
    },[formik.values])

    return (
        <>
       
            <form className='' onSubmit={formik.handleSubmit}>
                {/* <div className="text-[red] text-[12px]">{error}</div   > */}
                <div className="flex space-x-4  my-[1.1rem] lg:pl-[5rem] ">               
                    <div className="flex  space-x-4 md:ml-[1rem] lg:ml-0">
                        <div className="space-y-1 mt-[0rem]">
                            <div  >
                                <label className="relative block" >
                                <select
                                id="ISDCode"
                                name="ISDCode"
                                type="text"
                                autoComplete="off"
                                placeholder="Select an Option"
                                value={formik.values.ISDCode}
                                onChange={formik.handleChange}
                                className="md:w-[5rem] w-[3.3rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] md:text-[12px] text-[10px] lg:text-[16px] lgfocus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  block " >
                                {props?.requestData?.isdcodes?.map((isdcode, i) => (
                                    <option value={isdcode.isdcode}>{isdcode?.isdcode+", "+isdcode?.country}</option>
                                ))}
                                </select>
                                </label>
                                </div>
                        </div>

                            <div  >
                                <label className="relative flex justify-end	">
                                    <input id="MobileNumber" name="MobileNumber" autoComplete="off" 
                                    placeholder={trans('my_profile.mobile_number')}
                                    type="text"
                                    onKeyDown={(event) => blockInvalidCharForNumber(event, formik.values.ISDCode == "+91" ? 10 : 15)}

                                    values={formik.values.MobileNumber}
                                    onChange={formik.handleChange}
                                    className="w-[5rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] md:text-[12px] text-[10px] lg:text-[16px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block " />
                                </label>
                                {formik.errors.MobileNumber && formik.touched.MobileNumber && (
                                <div className="md:pl-[1rem]  text-[red] md:text-[12px] text-[10px] lg:text-[14px] h-[1rem] items-center justify-center">{formik.errors.MobileNumber}</div   >
                                )}

                                {formik.errors.ISDCode && formik.touched.ISDCode && (
                                <div className="md:pl-[1rem] lg:pl-[1rem]  text-[red] md:text-[12px] text-[10px] lg:text-[14px] h-[1rem] items-center justify-center">{formik.errors.ISDCode}</div   >
                                )}
                                </div>

                        <div className='space-y-1'>  
                            <div>
                                <label className="" >
                                    <button type="submit"
                                        className="w-[7rem] flex justify-center text-left py-1 md:py-2 border border-transparent rounded-[7px] shadow-sm md:text-[14px] text-[10px] lg:text-[16px] text-[#FFFFFF] font-semibold bg-[#D10A11] hover:bg-[#D10A11]  focus:outline-none">
                                        SUBMIT
                                    </button>
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
                
              </form>
        <p className='text-[red] text-[12px] flex justify-center'>{errorMessage!=""&&errorMessage}</p>
        </>

    )
}
export default ChangeMobileNumberForm;

