import { call, put, takeEvery } from "redux-saga/effects";
import { FILTER_REQUEST} from "./ActionTypes";
import { sendFilterRequestApiError, sendFilterRequestSuccess } from "./Action";
import { Send_Filter_Request } from "../../Helpers/api_helpers";


function* filterRequest({ payload: {values} }) {
  //console.log(values,"datadata");
  try {
    const response = yield call(Send_Filter_Request,{
      "pageno": values.pageno,
      "email": values.email ?? null,
      "userId": values.userId,
      "ageTo": values?.ageTo,
      "ageFrom": values.ageFrom,
      "heightFrom": values.heightFrom,
      "heightTo": values.heightTo,
      "marital_status": values.maritalStatus,
      "physicalStatus": values.physicalStatus,
      "mother_tongue":values.motherTongue,
      "educationId": values.education,
      "subcasteId": values.subCaste,
      "casteIdList": values.caste,
      "countryIdList": values.country,
      "religionIdList": values.religion,
      "domainIdList":values.domain,
      "employedInIdList": values.employedIn,
      "occupationIdList": values.occupation,
      "cityIdList": values.city,
      "stateIdList": values.state,
      "starIdList": values.star,
      "raasiIdList": values.raasi,
      "doshamIdList": values.dosham,
      "drinkingIdList": values.drinking,
      "smokingIdList": values.smoking,
      "foodIdList": values.food,
      "shortlisted": values.shortlisted,
      "ignored": values.ignored,
      "contacted": values.contacted,
      "viewed": values.viewed,
      "withHoroscope":values.withHoroscope,
      "withPhoto":values.withPhoto,
      "days":values.days,
      "weeks":values.weeks,
      "months":values.months,
      "minincome":values.minincome,
      "maxincome":values.maxincome
    
  });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(sendFilterRequestSuccess(response));
    } else {
      yield put(sendFilterRequestApiError(response));
    }
  } catch (error) {
    yield put(sendFilterRequestApiError(error));
  }
}




function* filterSectionSaga() {
  yield takeEvery(FILTER_REQUEST, filterRequest);
}

export default filterSectionSaga;
