import React, { useEffect } from 'react'


const RefineFilter = (props) => {

    useEffect(() => {
        //console.log(props.data, "ty")
    }, [props.data]); 

    return (
        <div> 
            {props.menu === "basic" || props?.menu == "activity" ?
                <div className='text-[#9F9F9F] font-segeo mb-2 mt-3 font-bold flex px-4 justify-end text-[12px] '>

                    <button className='bg-[#D10A11] text-[#FFFFFF] p-[0.25rem]  px-6 rounded-[5px]' onClick={(event) => props?.RefineSearch(event,props.menu)}>
                        Apply
                    </button>
                </div>
                :
                <div className='text-[#9F9F9F] font-segeo mb-2 mt-3 font-bold items-center text-[12px] flex px-4 justify-between'>
                    
                    <button className='text-[13px]' onClick={(event) => props?.selectAllFilter(event,props.menu, "all")}>
                        All
                    </button>
                    <button className='text-[13px]' onClick={(event) => props?.clearAllFilter(event,props.menu, "clear")}>
                        Clear
                    </button>

                    <button className='bg-[#D10A11] text-[#FFFFFF] p-[0.25rem]  px-6 rounded-[5px]' onClick={(event) => props?.RefineSearch(event,props.menu)}>
                        Apply
                    </button>
                </div>



            }

        </div>
    )
}

export default RefineFilter
