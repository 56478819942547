import CloseIcon from "../../../Images/Group 761@2x.png";
import Tick from "../../../Components/Kalyanlite/assets/Images/Group 2581.svg";
import * as Images from "../../../Components/Kalyanlite/assets/images";
import { useTranslation } from "react-i18next";


const LanguageSwitcher = ({ onClose, languages, changeLanguage, selectedLanguage }) => {
  const {t: trans} = useTranslation()
  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-full">
        <button
          className={`md:block bg-transparent z-40 border-0 text-black  absolute top-1.5 right-1.5`}
          onClick={onClose}
        >
          <img className="h-6 w-6 " src={CloseIcon} alt="" />
        </button>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-2 flex-auto">
            <ul
              className='py-4 space-y-2 text-sm text-gray-700 dark:text-gray-200 divide-y divide-gray-400'>
              <li>
                <div className="flex items-center px-4 py-2 ">
                  <img src={Images.Language} className="mr-3 h-5 w-5 shrink-0" />
                  <span className="text-[#7A7A7A] text-[13px] font-Poppins-Regular  mr-2">{trans('my_profile_icon.choose_language')}</span>
                </div>
              </li>
              {languages.map(lang => (<li key={lang.code}>
                <div onClick={() => {
                  changeLanguage(lang.code)
                  onClose()
                }} className="flex items-center justify-between px-4 py-1.5 cursor-pointer">
                  <p className="text-[#7A7A7A] text-[13px] font-Poppins-Regular flex gap-4 shrink-0 mr-2">{lang.name}</p>
                  {selectedLanguage == lang.code && <img
                    src={Tick}
                    alt='tick'
                    className='shrink-0 w-3 h-3 md:w-4 md:h-4 lg:w-3 lg:h-3'
                  />}
                </div>
              </li>))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
