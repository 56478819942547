import { SEARCH_BY_CITY, SEARCH_BY_CITY_API_ERROR, SEARCH_BY_CITY_SUCCESS, SEARCH_BY_EDUCATION, SEARCH_BY_EDUCATION_API_ERROR, SEARCH_BY_EDUCATION_SUCCESS, SEARCH_BY_FILTER, SEARCH_BY_FILTER_API_ERROR, SEARCH_BY_FILTER_SUCCESS, SEARCH_BY_OCCUPATION, SEARCH_BY_OCCUPATION_API_ERROR, SEARCH_BY_OCCUPATION_SUCCESS } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const ExploreProfile = (state = initialState, action) => {
    //console.log(action.type,"12345");
    switch (action.type) {
      case SEARCH_BY_CITY:
        state = {
          ...state,
          loading: true,
        };
        break;
        case SEARCH_BY_CITY_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          searchCityResultData:action.payload.data,
          loading: false,
        };
        break;
      case SEARCH_BY_CITY_API_ERROR:
        state = {
          ...state,
          searchCityResultData: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

        case SEARCH_BY_EDUCATION:
        state = {
          ...state,
          loading: true,
        };
        break;
        case SEARCH_BY_EDUCATION_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          searchEducationResultData:action.payload.data,
          loading: false,
        };
        break;
      case SEARCH_BY_EDUCATION_API_ERROR:
        state = {
          ...state,
          searchEducationResultData: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

        case SEARCH_BY_OCCUPATION:
        state = {
          ...state,
          loading: true,
        };
        break;
        case SEARCH_BY_OCCUPATION_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          searchOccupationResultData:action.payload.data,
          loading: false,
        };
        break;
      case SEARCH_BY_OCCUPATION_API_ERROR:
        state = {
          ...state,
          searchOccupationResultData: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

        case SEARCH_BY_FILTER:
        state = {
          ...state,
          loading: true,
        };
        break;
        case SEARCH_BY_FILTER_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          searchFilterResultData:action.payload.data,
          loading: false,
        };
        break;
      case SEARCH_BY_FILTER_API_ERROR:
        state = {
          ...state,
          searchFilterResultData: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default ExploreProfile;
  