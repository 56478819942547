import { call, put, takeEvery } from "redux-saga/effects";
import { Activity_Popup_Count, Activity_Store_List, Dashboard_Profile_Data, Get_Total_Points, Store_Activity_Store_List } from "../../Helpers/api_helpers";
import Constants from "../../Constants/constants";
import { addPointFail, addPointSuccess, getActivityListFail, getActivityListSuccess, getActivityTotalPointsFail, getActivityTotalPointsSuccess } from "./Action";
import { ADD_POINT, GET_ACTIVITY_LIST, GET_ACTIVITY_TOTAL_POINTS, REWARD_POPUP_SHOWN } from "./ActionTypes";

function* fetchActivityList(payload) {
    try {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem(
                Constants.loginLocalStorageKeys.chatLogin
            )}`,
        }
        const response = yield call(Activity_Store_List, { headers });
        console.log("fetchActivityList", response);
        if (response.status === 200) {
            yield put(getActivityListSuccess(response?.data));
        } else {
            yield put(getActivityListFail(response));
        }
    } catch (error) {
        yield put(getActivityListFail(error));
    }
}

function* fetchTotalPoints(payload) {
    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                profile_id: localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
            }
        };

        const response = yield call(Get_Total_Points, axiosConfig);
        if (response.status === 200) {
            yield put(getActivityTotalPointsSuccess(response?.data));
        } else {
            yield put(getActivityTotalPointsFail(response));
        }
    } catch (error) {
        yield put(getActivityTotalPointsFail(error));
    }
}

function* addPoint({ payload }) {
    try {

        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                profile_id: localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
                activity_id: payload.activityId,
                user_id: localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
                status: "pending",
            }
        };

        const response = yield call(Store_Activity_Store_List, axiosConfig);
        if (response.status === 200) {
            yield put(addPointSuccess(response?.data));
        } else {
            yield put(addPointFail(response));
        }
    } catch (error) {
        yield put(addPointFail(error));
    }
}

function* rewardPopupShownApi({ payload }) {
    try {
        const request = {
            "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
            "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
        }
        const response = yield call(Activity_Popup_Count, request);
        if(response?.status == 200){
            const data = {
                email : localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
                userId: localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
                "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice)
            }
            yield call(Dashboard_Profile_Data, data);
        }
        else{}

    } catch (error) {

    }
}

function* rewardSaga() {
    yield takeEvery(GET_ACTIVITY_LIST, fetchActivityList);
    yield takeEvery(GET_ACTIVITY_TOTAL_POINTS, fetchTotalPoints);
    yield takeEvery(REWARD_POPUP_SHOWN, rewardPopupShownApi)
    // yield takeEvery(ADD_POINT, addPoint);
}

export default rewardSaga