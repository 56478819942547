import React, { useState, useEffect, useContext } from "react";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { GET, POST } from "../../Services/api_services";
import Constants, { loginFrom, EventName, EventKeys } from "../../Constants/constants";
import axios from "axios";
import { LoginContext } from "../../Contexts/LoginContext";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  BasicEditTextView,
  HoroscopeEditTextView,
  HoroscopeSearchView,
  HoroscopeSelectView,
} from "./EditProfileUtills";
import HoroscopeComponent from "./MyProfileComponents/HoroscopeComponent";
import { MultiSelectView } from "./PartnerPreference/PartnerPreferenceUtils";
import { useSearchParams } from "react-router-dom";
import { validateImage } from "../../Utils";
import { NotificationPopup } from "../CommonPopup";
import config from "../../config";
import Loader from "../Loader";
import UploadingImage from '../../Images/Group 964.svg'
import HoroscopeGenarateImage from '../../Images/Group 963@2x.png'
import { useAnalytics } from "../../Hooks/usePageChange";
import { useTranslation } from "react-i18next";
import { getDeviceInfo, getGroupAuthId } from "../Kalyanlite/utils";

const ProfileHoroscopeComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const hoursArray = [...new Array(12)];
  const minutesSecondsArray = [...new Array(59)];
  const [placeDataList, setplaceDataList] = useState([]);
  const [isUploading, setIsUploading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [notification, setNotification] = useState({show:false,title:"",message:""})

  const [searchParams, setSearchParams] = useSearchParams()
  const isV3User = localStorage.getItem("isV3User")
  const { onClickTrack } = useAnalytics()

  const { t:trans } = useTranslation();

  useEffect(() => {
    //console.log("horoscope", props.data);
    //console.log(
    //   "chartstyle",
    //   props?.data?.chartStyle.filter((e) => e.selected === "Y").name
    // );
  }, [props.data]);
  const formik = useFormik({
    initialValues: {
      dob: props?.data?.dob ? props?.data?.dob : "",
      hours: props?.data?.hours
        ? `${(props?.data?.hours).toString().padStart(2, "0")}`
        : "",
      minutes: props?.data?.minutes
        ? `${(props?.data?.minutes).toString().padStart(2, "0")}`
        : "",
      seconds: props?.data?.seconds
        ? `${(props?.data?.seconds).toString().padStart(2, "0")}`
        : "",
      ampm: props?.data?.ampm ? props?.data?.ampm : "AM",
      placeOfBirth: props?.data?.regionSelected
        ? props?.data?.regionSelected
        : "",
      placeOfBirthId: props?.data?.cityId ? `${props?.data?.cityId}` : "",
      chartStyle: props?.data?.chartStyle
        ? props?.data?.chartStyle?.filter((e) => e.selected === "Y")[0]?.id
        : "",
      language: props?.data?.language
        ? props?.data?.language.filter((e) => e.selected === "Y")[0]?.id
        : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      dob: Yup.string()
      .required("Please Enter Date of Birt"),
      hours: Yup.string().required(trans('generate_horoscope.please_select_the_hours'))
      .test('is-number', trans('please_select_time_of_birth.please_select_time_of_birth'), value => /^\d+$/.test(value)),
      minutes: Yup.string().required(trans('generate_horoscope.please_select_the_minutes'))
      .test('is-number', trans('please_select_time_of_birth.please_select_time_of_birth'), value => !isNaN(value)),
      seconds: Yup.string().required(trans('generate_horoscope.please_select_the_seconds'))
      .test('is-number', trans('please_select_time_of_birth.please_select_time_of_birth'), value => /^\d+$/.test(value)),
      ampm: Yup.string().required("Please Enter Time of Birth"),
      placeOfBirth: Yup.string().required(trans('generate_horoscope.please_select_place_of_birth')),
      chartStyle: Yup.string().required(trans('generate_horoscope.please_select_chart_style')),
      language: Yup.string().required(trans('generate_horoscope.please_select_language')),
    }),

    onSubmit: (values) => {
      apiCallGenerateHoroscope(values);

    },
  });
  // const SubmitCall = (value) => {
  //   //console.log(value);
  // };

  const [generatedUrl, setgeneratedurl] = useState();
  useEffect(() => {}, [generatedUrl, placeDataList]);

  const navigateToProfile = ()=>{
    searchParams.set('tab',"1");
    setSearchParams(searchParams)
  }

  const showNotification = ({ description = "", title = `${trans('photos.status')}!` }) => {
    setNotification({ show: true, title: title, message: description })
  }

  const onHoroScopeFileChange = (file,type)=>{
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({ title: "Invalid image", description: trans('upload_photos.height_and_width_must_be_more') })
      },
      onExtensionError: (fileType) => {
        showNotification({ title: "Invalid image", description: "Invalid extension!" })
      },
      onFileSizeError:(img)=>{
        showNotification({ title: "Invalid image", description: trans('upload_photos.image_size_should_not_be_great') })
      },
      onSuccess: (img, readerStr) => {
        handleUpload(file,type)
      }
    })
  }

  const handleUpload = async (file, type) => {
    setIsUploading(true)
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("trustDocumentUploadFrom", loginFrom);
      fileData.append("documents", file);
      fileData.append("type", type);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("horoscoperequesttypeid", 86);
      fileData.append("recieverprofileid", getGroupAuthId());

      // let config = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };

      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.trustDocumentUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      //console.log("callBackPopup",data);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          // alert("Image upload succesfully");
          // props.callBackReload();
          props.callBackPopup(data?.data?.data?.fileUploadpath,true);
          // navigateToProfile()
        }
      }
    } else {
      alert("Please upload file less than 10 mb");
    }

    setIsUploading(false)
  };

  const callBackPinApi = async (e) => {
    //console.log("callBackPinApi", e);

    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getPlaceUrl(e)}`
    );
    //console.log("pindata", data);
    if (statusCode === 200) {
      setplaceDataList(data.region);
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apiCallGenerateHoroscope = async (value) => {
    setIsGenerating(true)
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      hrs: value.hours,
      mins: value.minutes,
      ampm: value.ampm,
      chartStyle: value.chartStyle,
      cityId: `${value.placeOfBirthId}`,
      horoscopeUploadFrom: loginFrom,
      language: value.language,
      secs: value.seconds,
      horoscoperequesttypeid:86,
      requestfulfilledfrom:getDeviceInfo(),
      requestfulfilledvia:props?.isFrom == "AfterRegister" ? "AfterRegistration" : "myprofile",
      recieverprofileid:getGroupAuthId()

    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.generateHoroscopeUrl()}`,
      request
    );
    setIsGenerating(false)
    if (statusCode === 200) {
      //console.log("data.data.", data.data);
      if (data.data.status == "SUCCESS") {
        setgeneratedurl(data.data.url);
        props.callBackPopup(data.data.url,false);
        onClickTrack(EventName.profileGenerateHoroscope , {[EventKeys.profileGenerateHoroscope]:value})
        // navigateToProfile()
        // apiCallHorscopeRedirection(generatedUrl)
        // window.open(data.data.url, "_blank");
      }
    } else if (statusCode === 401) {
      logout();
    }
    //   "{
    //     ""status"": ""Success"",
    //     ""message"": ""Success"",
    //     ""statusCode"": 200,
    //     ""data"": {
    //         ""url"": ""http://www.astrovisiononline.com/avservices/singlepagehoro/inserttolsdb_v3.php?data=%3Cdata%3E%3CBIRTHDATA%3E%3CCORR%3E1%3C%2FCORR%3E%3CCUSTID%3EKMGethoroGenerateDetails+%28194426%2C+1987-11-12%2C+F%2C+nathiya%2C+Sivakasi%2C+77%2C+49%2C+9%2C+27%2C+5.2999999999999998%2C+139771%2C+N%2C+E%2C+3%2C+1%29%3C%2FCUSTID%3E%3CDAY%3E12%3C%2FDAY%3E%3CLAT%3E09.27%3C%2FLAT%3E%3CLATDIR%3EE%3C%2FLATDIR%3E%3CLONG%3E077.49%3C%2FLONG%3E%3CLONGDIR%3EN%3C%2FLONGDIR%3E%3CMONTH%3E11%3C%2FMONTH%3E%3CNAME%3Enathiya%3C%2FNAME%3E%3CPLACE%3ESivakasi%3C%2FPLACE%3E%3CSEX%3EFEMALE%3C%2FSEX%3E%3CTIME24HR%3E16.40.45%3C%2FTIME24HR%3E%3CTZONE%3E05.30%3C%2FTZONE%3E%3CTZONEDIR%3EE%3C%2FTZONEDIR%3E%3CYEAR%3E1987%3C%2FYEAR%3E%3C%2FBIRTHDATA%3E%3COPTIONS%3E%3CCHARTSTYLE%3E0%3C%2FCHARTSTYLE%3E%3CHSETTINGS%3E%3CADVANCEDOPTION1%3E0%3C%2FADVANCEDOPTION1%3E%3CADVANCEDOPTION2%3E0%3C%2FADVANCEDOPTION2%3E%3CADVANCEDOPTION3%3E0%3C%2FADVANCEDOPTION3%3E%3CADVANCEDOPTION4%3E0%3C%2FADVANCEDOPTION4%3E%3CAYANAMSA%3E1%3C%2FAYANAMSA%3E%3CBHAVABALAMETHOD%3E1%3C%2FBHAVABALAMETHOD%3E%3CDASASYSTEM%3E1%3C%2FDASASYSTEM%3E%3CFAVMARPERIOD%3E50%3C%2FFAVMARPERIOD%3E%3CGULIKATYPE%3E1%3C%2FGULIKATYPE%3E%3CPARYANTHAREND%3E25%3C%2FPARYANTHAREND%3E%3CPARYANTHARSTART%3E0%3C%2FPARYANTHARSTART%3E%3C%2FHSETTINGS%3E%3CLANGUAGE%3EMAL%3C%2FLANGUAGE%3E%3CREPDMN%3EKALYANMATRITEST%3C%2FREPDMN%3E%3CREPTYPE%3ELS-SP%3C%2FREPTYPE%3E%3C%2FOPTIONS%3E%3C%2Fdata%3E"",
    //         ""status"": ""SUCCESS""
    //     }
    // }"
    // https://www.astrovisiononline.com/avservices/singlepagehoro/inserttolsdb_v3.php?data=%3Cdata%3E%3CBIRTHDATA%3E%3CCORR%3E1%3C%2FCORR%3E%3CCUSTID%3EKMGethoroGenerateDetails+%28194426%2C+1987-11-12%2C+F%2C+nathiya%2C+Sivakasi%2C+77%2C+49%2C+9%2C+27%2C+5.2999999999999998%2C+139771%2C+N%2C+E%2C+3%2C+1%29%3C%2FCUSTID%3E%3CDAY%3E12%3C%2FDAY%3E%3CLAT%3E09.27%3C%2FLAT%3E%3CLATDIR%3EE%3C%2FLATDIR%3E%3CLONG%3E077.49%3C%2FLONG%3E%3CLONGDIR%3EN%3C%2FLONGDIR%3E%3CMONTH%3E11%3C%2FMONTH%3E%3CNAME%3Enathiya%3C%2FNAME%3E%3CPLACE%3ESivakasi%3C%2FPLACE%3E%3CSEX%3EFEMALE%3C%2FSEX%3E%3CTIME24HR%3E16.40.45%3C%2FTIME24HR%3E%3CTZONE%3E05.30%3C%2FTZONE%3E%3CTZONEDIR%3EE%3C%2FTZONEDIR%3E%3CYEAR%3E1987%3C%2FYEAR%3E%3C%2FBIRTHDATA%3E%3COPTIONS%3E%3CCHARTSTYLE%3E0%3C%2FCHARTSTYLE%3E%3CHSETTINGS%3E%3CADVANCEDOPTION1%3E0%3C%2FADVANCEDOPTION1%3E%3CADVANCEDOPTION2%3E0%3C%2FADVANCEDOPTION2%3E%3CADVANCEDOPTION3%3E0%3C%2FADVANCEDOPTION3%3E%3CADVANCEDOPTION4%3E0%3C%2FADVANCEDOPTION4%3E%3CAYANAMSA%3E1%3C%2FAYANAMSA%3E%3CBHAVABALAMETHOD%3E1%3C%2FBHAVABALAMETHOD%3E%3CDASASYSTEM%3E1%3C%2FDASASYSTEM%3E%3CFAVMARPERIOD%3E50%3C%2FFAVMARPERIOD%3E%3CGULIKATYPE%3E1%3C%2FGULIKATYPE%3E%3CPARYANTHAREND%3E25%3C%2FPARYANTHAREND%3E%3CPARYANTHARSTART%3E0%3C%2FPARYANTHARSTART%3E%3C%2FHSETTINGS%3E%3CLANGUAGE%3EMAL%3C%2FLANGUAGE%3E%3CREPDMN%3EKALYANMATRITEST%3C%2FREPDMN%3E%3CREPTYPE%3ELS-SP%3C%2FREPTYPE%3E%3C%2FOPTIONS%3E%3C%2Fdata%3E
  };
  const apiCallHorscopeRedirection = async (url) => {
    let { statusCode, data } = await POST(
      url
      // `${config.api.API_URL}${EndPoints.generateHoroscopeUrl()}`,
      // request
    );
    //console.log("data.", data);
    if (statusCode === 200) {
      if (data.data.status == "SUCCESS") {
        // window.open(data.data.url, "_blank");
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const closeNotification = ()=>{
    setNotification({show:false,message:"",title:""})
  }

  return (
    <div x-show="tab === 5">
      {
      notification.show
        ? <NotificationPopup close={closeNotification} title={notification.title} content={notification.message} />
        : null
      }
      {isUploading? <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" /> :""}
      <form onSubmit={formik.handleSubmit}>
        <div className="md:block">
          {props?.menu=="addhoro"?"":<div className="flex justify-between md:pl-[1rem] ">
            <p className=" text-[#000000] text-[16px] pt-[2rem] pl-[1rem] md:pl-0 md:pt-0  font-semibold ">
            {trans('my_profile.add__manage_horoscope')}
            </p>
          </div>}

          <div className={`mt-[1rem]  text-[13px]  md:p-[1rem] lg:p-0 ${isV3User ? "bg-[#DADADAE0]" : (isV3User && props?.menu==="addhoro")?"bg-[#ffffff]": "bg-[#F1F1F1] m-[1rem] md:m-0" } rounded-[14px]`}>
            {localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite)
              ?
               <div className={`h-[12rem] flex flex-col items-center justify-center`}>
                <label className=" flex justify-end  ">
                  <div
                    className="flex items-center space-x-2 justify-end  font-bold text-white bg-[#D10A11] px-[1rem] rounded-[4px] py-2 md:text-[11px] lg:text-[13px]  "
                  >
                    <img
                      alt=""
                      className=" md:w-[1rem] lg:w-[1.3rem]"
                      src={UploadingImage}
                    />
                    <p onClick={() => { onClickTrack(EventName.profileUploadHoroscope) }}> {isUploading ?  trans('upload_photos.uploading') : trans('generate_horoscope.upload_horoscope')} </p>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      disabled={isUploading}
                      onChange={(event) => {
                        // //console.log(
                        //   "file",
                        //   URL.createObjectURL(event.target.files[0])
                        // );
                        if (event.target.files[0]) {
                          onHoroScopeFileChange(event.target.files[0], "horoscope");
                        }

                      }}
                      accept="image/jpeg, image/png"
                    />
                  </div>
                </label>
                <p className="text-[#575556] py-[0.5rem] md:py-0 md:pt-[0.5rem] lg:pt-[1rem] md:text-[10px] lg:text-[12px] font-bold ">
                  {trans('generate_horoscope.png_jpg_gif_less_than_10mb')}
                </p>
              </div>
              : 
              <div className={` ${props?.menu=="addhoro"?"":"lg:grid lg:grid-cols-12"}`}>
              <div className={` ${props?.menu=="addhoro"?"grid md:grid-cols-2 space-y-3 md:space-y-0":"lg:col-span-6"}`}>
              
               <HoroscopeEditTextView
                  title={trans('generate_horoscope.date_of_birth')}
                  inputType={"text"}
                  inputID={"dob"}
                  inputName={"dob"}
                  value={formik.values.dob}
                  // onChange={formik.handleChange}
                  error={formik.errors.dob}
                  isPaid={props?.dashboard1Data?.isPaid==='Y'}
                />
               

          
                <div className={` mt-4 ${props?.menu=="addhoro"?"":"flex flex-col md:grid md:grid-cols-9"} md:mt-[2rem] md:mb-[2rem] lg:mb-[3rem] `}>
                  <div className="md:col-span-3 flex items-center  pl-[1rem] ">
                    <p className=" text-[13px]  md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold   ">
                    {trans('generate_horoscope.time_of_birth')}<span className="text-[#D10A11]">*</span>{" "}
                    </p>
                  </div>
                  <div className="pl-[1rem] md:col-span-6 md:pl-[1rem] flex-col">
                    <div className="flex justify-between space-x-1 pr-[1rem]">
                    <p className="  ">
                      <div className=" horoscope select font-bold text-[#575556]  ">
                        <select
                          name="hours"
                          id="hours"
                          value={formik.values.hours}
                          onChange={formik.handleChange}
                          className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[13px]  md:text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                        >
                          <option value="hh">{trans('generate_horoscope.hh')}</option>
                          {hoursArray.map((e, i) => (
                            <option
                              key={i}
                              value={(i + 1).toString().padStart(2, "0")}
                            >
                              {(i + 1).toString().padStart(2, "0")}
                            </option>
                          ))}
                        </select>
                      </div>
                    </p>
                    <p className="  ">
                      <div className=" horoscope select font-bold text-[#575556]  ">
                        <select
                          name="minutes"
                          id="minutes"
                          value={formik.values.minutes}
                          onChange={formik.handleChange}
                          className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[13px]  md:text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                        ><option value="mm">{trans('generate_horoscope.mm')}</option>
                          {minutesSecondsArray.map((e, i) => (
                            <option
                              key={i}
                              value={(i).toString().padStart(2, "0")}
                            >
                              {(i).toString().padStart(2, "0")}
                            </option>
                          ))}
                        </select>
                      </div>
                    </p>
                    <p className="  ">
                      <div className=" horoscope select font-bold text-[#575556]  ">
                        <select
                          name="seconds"
                          id="seconds"
                          value={formik.values.seconds}
                          onChange={formik.handleChange}
                          className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[13px]  md:text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                        >
                          <option value="ss">{trans('generate_horoscope.ss')}</option>
                          {minutesSecondsArray.map((e, i) => (
                            <option
                              key={i}
                              value={(i).toString().padStart(2, "0")}
                            >
                              {(i).toString().padStart(2, "0")}
                            </option>
                          ))}
                        </select>
                      </div>
                    </p>
                    <p className="  ">
                      <div className=" horoscope select font-bold text-[#575556]  ">
                        <select
                          name="ampm"
                          id="ampm"
                          value={formik.values.ampm}
                          onChange={formik.handleChange}
                          className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[13px]  md:text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                           <option value="AM/PM">AM/PM</option>
                        </select>
                      </div>
                    </p>
                   </div>
                   <div>
                   {(formik.errors.hours ?
                    <p className="text-[red] text-[12px]  ">
                    {formik.errors.hours}
                   
                  </p>:
                   formik.errors.minutes ?
                   <p className="text-[red] text-[12px] ">
                        {formik.errors.minutes}
                       
                      </p>
                      : formik.errors.seconds
                      ?
                      <p className="text-[red] text-[12px] ">
                        {formik.errors.seconds}
                       
                      </p>
                      :<p className="text-[red] text-[12px] ">
                      {formik.errors.hours}
                     
                    </p>


                    )}
                   </div>
                    
                  </div>
                   {/* <div className="pl-[1rem] md:pl-[10rem] md:col-span-12  lg:whitespace-nowrap lg:w-full md:w-[28rem] flex ">
                 
                    {(formik.errors.hours ?
                    <p className="text-[red] text-[12px]  ">
                    {formik.errors.hours}
                   
                  </p>:
                   formik.errors.minutes ?
                   <p className="text-[red] text-[12px] ">
                        {formik.errors.minutes}
                       
                      </p>
                      : formik.errors.seconds
                      ?
                      <p className="text-[red] text-[12px] ">
                        {formik.errors.seconds}
                       
                      </p>
                      :<p className="text-[red] text-[12px] ">
                      {formik.errors.hours}
                     
                    </p>


                    )}
                    </div> */}
                </div>
               
                <HoroscopeSearchView
                title={trans('generate_horoscope.place_of_birth')}
                inputID={"placeOfBirth"}
                inputName={"placeOfBirth"}
                value={formik.values.placeOfBirth}
                onChange={(e) => {
                  formik.setFieldValue("placeOfBirthId", e);
                  const selected = placeDataList?.filter(place=>place.cityId === e)?.at(0)
                  formik.setFieldValue("placeOfBirth",`${selected?.placeName}-${selected?.regionName}-${selected?.countryName}`)
                  //console.log('placeOfBirthId');
                  // formik.handleChange(e);
                }}
                error={formik.errors.placeOfBirth}
                callBackApi={callBackPinApi}
                options={placeDataList}
                placeholder="Enter first three letters of your city/town"
              />
                
          
                <HoroscopeSelectView
                  title={trans('generate_horoscope.chart_style')}
                  inputID={"chartStyle"}
                  inputName={"chartStyle"}
                  value={formik.values.chartStyle}
                  onChange={formik.handleChange}
                  error={formik.errors.chartStyle}
                  options={props?.data?.chartStyle}
                />
            
           
                <HoroscopeSelectView
                title={trans('generate_horoscope.language')}
                inputID={"language"}
                inputName={"language"}
                value={formik.values.language}
                onChange={formik.handleChange}
                error={formik.errors.language}
                options={props?.data?.language}
              />
              
          
                <div className=" flex justify-end mt-[3rem] mb-[2rem] mr-[1rem]">
                  <button
                    type="submit"
                    disabled={isGenerating}
                    className="
                   flex items-center space-x-2 justify-end  font-bold text-white bg-[#D10A11] px-[0.8rem]  lg:px-[1rem] rounded-[4px] py-[0.4rem] lg:py-2  md:text-[11px] lg:text-[13px]  
                  "
                  >
                    <img alt=""
                      className=" w-[1.3rem] md:w-[1rem] lg:w-[1.3rem]"
                      src={HoroscopeGenarateImage}
                    />
                    <p> {isGenerating?"Generating Horoscope":trans('generate_horoscope.generate_horoscope')} </p>
                  </button>
                </div>
                

              </div>      
              {/* </form> */}
              

              <div className=" lg:col-span-6 border-[1px] border-[#8F8F8F] lg:border-none md:py-[1rem] lg:py-0 md:mx-[1rem] lg:mx-0 flex justify-center items-center ">
                <div className={` ${props?.menu=="addhoro"?"":"lg:flex lg:space-x-5"}  `}>
                  <div className={`hidden ${props?.menu=="addhoro"?"flex justify-center items-center":""} md:block`}>
                    <div className=" border-[4px] border-[#D10A11] rounded-full md:hidden lg:block ">
                      <p className="text-[#D10A11] text-[22px] p-[0.4rem] font-bold ">
                        {trans('generate_horoscope.or')}
                      </p>
                    </div>
                  </div>
                  <div className="lg:hidden flex justify-center items-center m-[1rem] ">
                    <div className=" border-[4px] border-[#D10A11] text-[#D10A11] text-center text-[22px]  rounded-[50%] md:w-[21dvw] lg:w-[35dvw] w-[35dvw] h-[60px] flex justify-center items-center lg:block">
                      {trans('generate_horoscope.or')}
                    </div>
                  </div>
                  <div className="">
                    <label className=" flex justify-end  lg:mx-3 xl:mx-1">
                      <div
                        className="
                   flex items-center space-x-2 justify-end  font-bold text-white bg-[#D10A11] px-[1rem] rounded-[4px] py-2 md:text-[11px] lg:text-[13px]  
                  "
                  >
                        <img
                        alt=""
                          className=" md:w-[1rem] lg:w-[1.3rem]"
                          src={UploadingImage}
                        />
                        <p className="xl:whitespace-nowrap" onClick={() => {onClickTrack(EventName.profileUploadHoroscope)}}> {isUploading? trans('upload_photos.uploading'):trans('generate_horoscope.upload_horoscope')} </p>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          disabled={isUploading}
                          onChange={(event) => {
                            // //console.log(
                            //   "file",
                            //   URL.createObjectURL(event.target.files[0])
                            // );
                            if(event.target.files[0]){
                              onHoroScopeFileChange(event.target.files[0], "horoscope");
                            }
                            
                          }}
                          accept="image/jpeg, image/png"
                        />
                      </div>
                    </label>
                    <p className="text-[#575556] py-[0.5rem] md:py-0 md:pt-[0.5rem] lg:pt-[1rem] md:text-[10px] lg:text-[12px] font-bold ">
                      {trans('generate_horoscope.png_jpg_gif_less_than_10mb')}
                    </p>
                  </div>
                </div>
              </div>
                

              </div>}
          </div>
        </div>
        </form>
        {/* <div className="block md:hidden">
          <div className="flex justify-between">
            <p className=" text-[#000000] mt-[1rem] pl-[1rem] text-[16px] font-semibold ">
              Add / Manage Horoscope
            </p>
          </div>
          <div className="mt-[1rem] md:p-[1rem] lg:p-0 p-[1rem]  bg-[#F1F1F1] rounded-[14px] ">
            <div className=" lg:grid lg:grid-cols-12">
              <div className="lg:col-span-6">
                <div className=" grid grid-cols-9 mt-[2rem] ">
                  <div className="col-span-3 flex items-center  md:pl-[1rem] ">
                    <p className=" text-[14px] flex text-[#575556] font-semibold   ">
                      Date of Birth
                    </p>
                  </div>
                  <div className="  col-span-6 md:pl-[1rem] w-full ">
                    <p className=" w-full  ">
                      <input
                        type="email"
                        id="email"
                        className="  border border-[#8F8F8F]   text-[12px]  rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold   "
                        placeholder="17-06-2023"
                        required
                      />
                    </p>
                  </div>
                </div>
                <div className=" grid grid-cols-9 mt-[2rem] md:mb-[2rem] lg:mb-[3rem] ">
                  <div className="col-span-3 flex items-center   ">
                    <p className=" text-[14px] flex text-[#575556] font-semibold   ">
                      {" "}
                      Time of Birth <span className="text-[#D10A11]">
                        *
                      </span>{" "}
                    </p>
                  </div>
                  <div className="  col-span-6 md:pl-[1rem] flex justify-around space-x-1 ">
                    <p className="  ">
                      <label className=" select font-bold text-[#575556]  ">
                        <select
                          name=""
                          id=""
                          className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                        >
                          <option value="">08</option>
                          <option value="">08</option>
                          <option value="">08</option>
                          <option value="">08</option>
                        </select>
                      </label>
                    </p>
                    <p className="  ">
                      <label className=" select font-bold text-[#575556]  ">
                        <select
                          name=""
                          id=""
                          className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                        >
                          <option value="">30</option>
                          <option value="">08</option>
                          <option value="">08</option>
                          <option value="">08</option>
                        </select>
                      </label>
                    </p>
                    <p className="  ">
                      <label className=" select font-bold text-[#575556]  ">
                        <select
                          name=""
                          id=""
                          className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                        >
                          <option value="">02</option>
                          <option value="">08</option>
                          <option value="">08</option>
                          <option value="">08</option>
                        </select>
                      </label>
                    </p>
                    <p className="  ">
                      <label className=" select font-bold text-[#575556]  ">
                        <select
                          name=""
                          id=""
                          className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                        >
                          <option value="">PM</option>
                          <option value="">08</option>
                          <option value="">08</option>
                          <option value="">08</option>
                        </select>
                      </label>
                    </p>
                  </div>
                </div>
                <div className=" grid grid-cols-9 mt-[2rem] md:mb-[2rem] lg:mb-[3rem] ">
                  <div className="col-span-3 flex items-center    ">
                    <p className=" text-[14px] flex text-[#575556] font-semibold   ">
                      {" "}
                      Place of Birth <span className="text-[#D10A11]">
                        *
                      </span>{" "}
                    </p>
                  </div>
                  <div className="  col-span-6 md:pl-[1rem] ">
                    <p className="  ">
                      <label className=" select font-bold text-[#575556] w-full ">
                        <select
                          name=""
                          id=""
                          className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                        >
                          <option value="">Virudunagar-Tamil Nadu-India</option>
                          <option value="">08</option>
                          <option value="">08</option>
                          <option value="">08</option>
                        </select>
                      </label>
                    </p>
                  </div>
                </div>
                <div className=" grid grid-cols-9 mt-[2rem] md:mb-[2rem] lg:mb-[3rem] ">
                  <div className="col-span-3 flex items-center   ">
                    <p className=" md:text-[12px] text-[14px] flex text-[#575556] font-semibold   ">
                      Chart Style
                      <span className="text-[#D10A11]">*</span>{" "}
                    </p>
                  </div>
                  <div className="  col-span-6 md:pl-[1rem]  ">
                    <p className="  ">
                      <label className=" select font-bold text-[#575556] w-full ">
                        <select
                          name=""
                          id=""
                          className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                        >
                          <option value="">South India</option>
                          <option value="">08</option>
                          <option value="">08</option>
                          <option value="">08</option>
                        </select>
                      </label>
                    </p>
                  </div>
                </div>
                <div className=" grid grid-cols-9 mt-[2rem] ">
                  <div className="col-span-3 flex items-center  md:pl-[1rem]  ">
                    <p className=" md:text-[12px] text-[14px] flex text-[#575556] font-semibold   ">
                      Language
                      <span className="text-[#D10A11]">*</span>
                    </p>
                  </div>
                  <div className="  col-span-6 md:pl-[1rem] w-full ">
                    <p className=" w-full  ">
                      <input
                        type="email"
                        id="email"
                        className="  border border-[#8F8F8F]   text-[12px]  rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold   "
                        placeholder="17-06-2023"
                        required
                      />
                    </p>
                  </div>
                </div>
                <div className=" flex justify-end mt-[2rem] mb-[2rem] ">
                  <button
                    className="
                   flex items-center space-x-2 justify-end  font-bold text-white bg-[#D10A11] px-[0.8rem] rounded-[4px] py-[0.4rem] md:text-[11px] text-[13px]  
                  "
                  >
                    <img
                      className=" w-[1.3rem] md:w-[1rem] lg:w-[1.3rem]"
                      src="/Assets/Images/Group 963@2x.png"
                    />
                    <p> Generate Horoscope </p>
                  </button>
                </div>
              </div>
              <div className="flex justify-center items-center ">
                <div className=" border-[4px] border-[#D10A11] text-[#D10A11] text-[22px]  rounded-[50%] w-[60px] h-[60px] flex justify-center items-center md:hidden lg:block ">
                  OR
                </div>
              </div>
              <div className=" mt-[1rem] mb-[6rem] lg:col-span-6 border-[1px] border-[#8F8F8F] lg:border-none py-[1rem]  flex justify-center items-center ">
                <div className=" lg:flex  lg:space-x-5 ">
                  <div></div>
                  <div className="">
                    <div className=" flex justify-end  ">
                      <button
                        className="
                   flex items-center space-x-2 justify-end  font-bold text-white bg-[#D10A11] px-[0.8em] rounded-[4px] py-[0.4rem] md:text-[11px] text-[13px]  
                  "
                      >
                        <img
                          className=" w-[1.3rem] md:w-[1rem] lg:w-[1.3rem]"
                          src="/Assets/Images/Group 964.svg"
                        />
                        <p> Upload Horoscope </p>
                      </button>
                    </div>
                    <p className="text-[#575556] text-[11px] md:text-[10px] lg:text-[12px] font-bold ">
                      {trans('generate_horoscope.png_jpg_gif_less_than_10mb')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* </form> */}
    </div>
  );
};

export default ProfileHoroscopeComponent;
