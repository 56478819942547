//matches list api call
export const DASHBOARD_MATCHES_LIST = "DASHBOARD_MATCHES_LIST"
export const DASHBOARD_MATCHES_LIST_SUCCESS = "DASHBOARD_MATCHES_LIST_SUCCESS"
export const DASHBOARD_MATCHES_LIST_API_ERROR = "DASHBOARD_MATCHES_LIST_API_ERROR"

//left panel api call
export const DASHBOARD_LEFT_PANEL_DATA = "DASHBOARD_LEFT_PANEL_DATA"
export const DASHBOARD_LEFT_PANEL_DATA_SUCCESS = "DASHBOARD_LEFT_PANEL_DATA_SUCCESS"
export const DASHBOARD_LEFT_PANEL_DATA_API_ERROR = "DASHBOARD_LEFT_PANEL_DATA_API_ERROR"


//profile data api call
export const DASHBOARD_PROFILE_DATA = "DASHBOARD_PROFILE__DATA"
export const DASHBOARD_PROFILE_DATA_SUCCESS = "DASHBOARD_PROFILE__DATA_SUCCESS"
export const DASHBOARD_PROFILE_DATA_API_ERROR = "DASHBOARD_PROFILE__DATA_API_ERROR"

