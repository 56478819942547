import { call, put, takeEvery } from "redux-saga/effects";

// Redux States
import { CONTENT_CARD_CLICK } from "./ActionTypes";
import { contentCardOnclickApiError, contentCardOnclickSuccess } from "./Action";
import { Content_Card_Onclick } from "../../Helpers/api_helpers";
import Constants from "../../Constants/constants";

function* contentCardOnclickCall({ payload: {name,page} }) {
  console.log(name,page,"datadata");
  try {
    const response = yield call(Content_Card_Onclick,{
      "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      "page":page,
      "subject":name,
      "isClick":"True"
    })
    console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(contentCardOnclickSuccess(response));
    } else {
      yield put(contentCardOnclickApiError(response));
    }
  } catch (error) {
    yield put(contentCardOnclickApiError(error));
  }
}


function* contentCardSaga() {
  yield takeEvery(CONTENT_CARD_CLICK, contentCardOnclickCall);
}

export default contentCardSaga;
