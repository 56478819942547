//language list

export const LANGUAGE_LIST =[
  {
    "name": "Tamil Brides and Grooms",
    "link": "tamil-marriages-matrimonial-matrimony"
  },
  {
    "name": "Malayalee Brides and Grooms",
    "link": "malayalee-marriages-matrimonial-matrimony"
  },
  {
    "name": "Telugu Brides and Grooms",
    "link": "telugu-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kannada Brides and Grooms",
    "link": "kannada-marriages-matrimonial-matrimony"
  }
]


//caste list

export const CASTE_LIST =  [
  {
    "name": "Vanniar Brides and Grooms",
    "link": "vanniar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Brahmin Brides and Grooms",
    "link": "brahmin-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Iyer Brides and Grooms",
    "link": "iyer-marriages-matrimonial-matrimony"
  },
  {
    "name": "Iyengar Brides and Grooms",
    "link": "iyengar-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Gounder Brides and Grooms",
    "link": "gounder-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kongu Vellala Gounder Brides and Grooms",
    "link": "kongu-vellala-gounder-marriages-matrimonial-gounder-matrimony"
  },
  {
    "name": "Chettiar Brides and Grooms",
    "link": "chettiar-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Tamil Chettiar Brides and Grooms",
    "link": "tamil-chettiar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Mudaliar Brides and Grooms",
    "link": "mudaliar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Thevar Brides and Grooms",
    "link": "thevar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Pillai Brides and Grooms",
    "link": "pillai-marriages-matrimonial-matrimony"
  },
  {
    "name": "Telugu Chettiar Brides and Grooms",
    "link": "telugu-chettiar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Hindu Nadar Brides and Grooms",
    "link": "hindu-nadar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Vellalar Brides and Grooms",
    "link": "vellalar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Nadar Brides and Grooms",
    "link": "nadar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Nair Brides and Grooms",
    "link": "nair-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kongu Vellalar Brides and Grooms",
    "link": "kongu-vellalar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Thuluva Vellalar Brides and Grooms",
    "link": "thuluva-vellalar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Naidu Brides and Grooms",
    "link": "naidu-marriages-matrimonial-matrimony"
  },
  {
    "name": "Veluthedathu Nair Brides and Grooms",
    "link": "veluthedathu-nair-marriages-matrimonial-matrimony"
  },
  {
    "name": "Mukkulathor Brides and Grooms",
    "link": "mukkulathor-marriages-matrimonial-matrimony"
  },
  {
    "name": "Ezhava Brides and Grooms",
    "link": "ezhava-marriages-matrimonial-matrimony"
  },
  {
    "name": "Reddy Brides and Grooms",
    "link": "reddy-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kamma Brides and Grooms",
    "link": "kamma-marriages-matrimonial-matrimony"
  },
  {
    "name": "Vishwakarma Brides and Grooms",
    "link": "vishwakarma-marriages-matrimonial-matrimony"
  },
  {
    "name": "Tamil Brahmin Brides and Grooms",
    "link": "tamil-brahmin-marriages-matrimonial-matrimony"
  },
  {
    "name": "Namboodri Brides and Grooms",
    "link": "namboodri-marriages-matrimonial-matrimony"
  },
  {
    "name": "Lingayath Brides and Grooms",
    "link": "lingayath-marriages-matrimonial-matrimony"
  },
  {
    "name": "Thiyaa Brides and Grooms",
    "link": "thiyaa-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kerala Vishwakarma Brides and Grooms",
    "link": "kerala-vishwakarma-marriages-matrimonial-matrimony"
  },
  {
    "name": "Telugu Yadav Brides and Grooms",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Tamil Vishwakarma Brides and Grooms",
    "link": "tamil-vishwakarma-marriages-matrimonial-matrimony"
  },
  {
    "name": "Tamil Yadava Brides and Grooms",
    "link": "tamil-yadava-marriages-matrimonial-matrimony"
  },
  {
    "name": "Telugu Vishwakarma Brides and Grooms",
    "link": "telugu-vishwakarma-marriages-matrimonial-matrimony"
  },
  {
    "name": "Yadav Brides and Grooms",
    "link": "yadav-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kapu Brides and Grooms",
    "link": "kapu-marriages-matrimonial-matrimony"
  },
  {
    "name": "Arya Vysya Brides and Grooms",
    "link": "arya-vysya-marriages-matrimonial-matrimony"
  },
  {
    "name": "Gurukkal Brides and Grooms",
    "link": "gurukkal-marriages-matrimonial-matrimony"
  },
  {
    "name": "Senguntha Mudaliar Brides and Grooms",
    "link": "senguntha-mudaliar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Adidravidar Brides and Grooms",
    "link": "adidravidar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Vokkaliga Brides and Grooms",
    "link": "vokkaliga-marriages-matrimonial-matrimony"
  },
  {
    "name": "Shetty Brides and Grooms",
    "link": "shetty-marriages-matrimonial-matrimony"
  },
  {
    "name": "Ambalavasi Brides and Grooms",
    "link": "ambalavasi-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kammavar Naidu Brides and Grooms",
    "link": "kammavar-naidu-marriages-matrimonial-matrimony"
  },
  {
    "name": "Arunthathiyar Brides and Grooms",
    "link": "arunthathiyar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Dheevara Brides and Grooms",
    "link": "dheevara-marriages-matrimonial-matrimony"
  },
  {
    "name": "Agamudayar Brides and Grooms",
    "link": "agamudayar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Maruthuvar Brides and Grooms",
    "link": "maruthuvar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Dhanvantari Brides and Grooms",
    "link": "dhanvantari-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kshatriya Brides and Grooms",
    "link": "kshatriya-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kudumbi Brides and Grooms",
    "link": "kudumbi-marriages-matrimonial-matrimony"
  },
  {
    "name": "Ezhuthachan Brides and Grooms",
    "link": "ezhuthachan-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kallar Brides and Grooms",
    "link": "kallar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Muthuraja Brides and Grooms",
    "link": "muthuraja-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kuruba Brides and Grooms",
    "link": "kuruba-marriages-matrimonial-matrimony"
  },
  {
    "name": "Devadiga Brides and Grooms",
    "link": "devadiga-marriages-matrimonial-matrimony"
  },
  {
    "name": "Karuneegar Brides and Grooms",
    "link": "karuneegar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Maravar Brides and Grooms",
    "link": "maravar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kulalar Brides and Grooms",
    "link": "kulalar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Billava Brides and Grooms",
    "link": "billava-marriages-matrimonial-matrimony"
  },
  {
    "name": "Vannar Brides and Grooms",
    "link": "vannar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Velama Brides and Grooms",
    "link": "velama-marriages-matrimonial-matrimony"
  },
  {
    "name": "Madhwa Brides and Grooms",
    "link": "madhwa-marriages-matrimonial-matrimony"
  },
  {
    "name": "Valluvan Brides and Grooms",
    "link": "valluvan-marriages-matrimonial-matrimony"
  },
  {
    "name": "Banjara Brides and Grooms",
    "link": "banjara-marriages-matrimonial-matrimony"
  },
  {
    "name": "Munnuru Kapu Brides and Grooms",
    "link": "munnuru-kapu-marriages-matrimonial-matrimony"
  },
  {
    "name": "Caste no Bar Brides and Grooms",
    "link": "caste-no-bar-marriages-matrimonial-matrimony"
  },
  {
    "name": "Vaishnava Brides and Grooms",
    "link": "vaishnava-marriages-matrimonial-matrimony"
  },
  {
    "name": "Inter caste Brides and Grooms",
    "link": "inter-caste-marriages-matrimonial-matrimony"
  },
  {
    "name": "Smartha Brides and Grooms",
    "link": "smartha-marriages-matrimonial-matrimony"
  },
  {
    "name": "Senai Thalaivar Brides and Grooms",
    "link": "senai-thalaivar-marriages-matrimonial-matrimony"
  }
]


//caste list

export const CITY_LIST =[
  {
    "name": "Chennai Brides and Grooms",
    "link": "chennai-marriages-matrimonial-matrimony"
  },
  {
    "name": "Bangalore Brides and Grooms",
    "link": "bangalore-marriages-matrimonial-matrimony"
  },
  {
    "name": "Trivandrum Brides and Grooms",
    "link": "trivandrum-marriages-matrimonial-matrimony"
  },
  {
    "name": "Hyderabad Brides and Grooms",
    "link": "hyderabad-marriages-matrimonial-matrimony"
  },
  {
    "name": "Secunderabad Brides and Grooms",
    "link": "secunderabad-marriages-matrimonial-matrimony"
  },
  {
    "name": "Vishakhapatnam Brides and Grooms",
    "link": "vishakhapatnam-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kolkata Brides and Grooms",
    "link": "kolkata-marriages-matrimonial-matrimony"
  },
  {
    "name": "Mumbai Brides and Grooms",
    "link": "mumbai-marriages-matrimonial-matrimony"
  },
  {
    "name": "Pune Brides and Grooms",
    "link": "pune-marriages-matrimonial-matrimony"
  },
  {
    "name": "Ahmedabad Brides and Grooms",
    "link": "ahmedabad-marriages-matrimonial-matrimony"
  },
  {
    "name": "Jaipur Brides and Grooms",
    "link": "jaipur-marriages-matrimonial-matrimony"
  },
  {
    "name": "Surat Brides and Grooms",
    "link": "surat-marriages-matrimonial-matrimony"
  },
  {
    "name": "Delhi Brides and Grooms",
    "link": "delhi-marriages-matrimonial-matrimony"
  },
  {
    "name": "Noida Brides and Grooms",
    "link": "noida-marriages-matrimonial-matrimony"
  },
  {
    "name": "Lucknow Brides and Grooms",
    "link": "lucknow-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kanpur Brides and Grooms",
    "link": "kanpur-marriages-matrimonial-matrimony"
  },
  {
    "name": "Allahabad Brides and Grooms",
    "link": "allahabad-marriages-matrimonial-matrimony"
  },
  {
    "name": "Ranchi Brides and Grooms",
    "link": "ranchi-marriages-matrimonial-matrimony"
  },
  {
    "name": "Andhra Brides and Grooms",
    "link": "andhra-marriages-matrimonial-matrimony"
  },
  {
    "name": "Coimbatore Brides and Grooms",
    "link": "coimbatore-marriages-matrimonial-matrimony"
  },
  {
    "name": "Madurai Brides and Grooms",
    "link": "madurai-marriages-matrimonial-matrimony"
  },
  {
    "name": "Trichy Brides and Grooms",
    "link": "trichy-marriages-matrimonial-matrimony"
  },
  {
    "name": "Salem Brides and Grooms",
    "link": "salem-marriages-matrimonial-matrimony"
  },
  {
    "name": "Tuticorin Brides and Grooms",
    "link": "tuticorin-marriages-matrimonial-matrimony"
  },
  {
    "name": "Nagercoil Brides and Grooms",
    "link": "nagercoil-marriages-matrimonial-matrimony"
  },
  {
    "name": "Tirunelveli Brides and Grooms",
    "link": "tirunelveli-marriages-matrimonial-matrimony"
  },
  {
    "name": "Pondicherry Brides and Grooms",
    "link": "pondicherry-marriages-matrimonial-matrimony"
  },
  {
    "name": "Ernakulam Kochi Brides and Grooms",
    "link": "ernakulam-kochi-marriages-matrimonial-matrimony"
  },
  {
    "name": "Thrissur Brides and Grooms",
    "link": "thrissur-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kannur Brides and Grooms",
    "link": "kannur-marriages-matrimonial-matrimony"
  },
  {
    "name": "Mangalore Brides and Grooms",
    "link": "mangalore-marriages-matrimonial-matrimony"
  },
  {
    "name": "Mysore Brides and Grooms",
    "link": "mysore-marriages-matrimonial-matrimony"
  },
  {
    "name": "Belgaum Brides and Grooms",
    "link": "belgaum-marriages-matrimonial-matrimony"
  },
  {
    "name": "Warangal Brides and Grooms",
    "link": "warangal-marriages-matrimonial-matrimony"
  },
  {
    "name": "Khammam Brides and Grooms",
    "link": "khammam-marriages-matrimonial-matrimony"
  },
  {
    "name": "Nalgonda Brides and Grooms",
    "link": "nalgonda-marriages-matrimonial-matrimony"
  },
  {
    "name": "Chittoor Brides and Grooms",
    "link": "chittoor-marriages-matrimonial-matrimony"
  },
  {
    "name": "Guntur Brides and Grooms",
    "link": "guntur-marriages-matrimonial-matrimony"
  },
  {
    "name": "Nellore Brides and Grooms",
    "link": "nellore-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kurnool Brides and Grooms",
    "link": "kurnool-marriages-matrimonial-matrimony"
  }
]


//caste and city 

export const CASTE_CITY_LIST = [
  {
    "name": "Vanniya Kula Kshatriyar Brides and Grooms in Chennai",
    "link": "vanniya-kula-kshatriyar-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Brahmin Brides and Grooms in Chennai",
    "link": "brahmin-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Iyer Brides and Grooms in Chennai",
    "link": "iyer-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Iyengar Brides and Grooms in Chennai",
    "link": "iyengar-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Tamil Brahmin Brides and Grooms in Chennai",
    "link": "tamil-brahmin-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Chettiar Brides and Grooms in Chennai",
    "link": "chettiar-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Mudaliyar Brides and Grooms in Chennai",
    "link": "mudaliyar-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Gounder Brides and Grooms in Chennai",
    "link": "gounder-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Nadar Brides and Grooms in Chennai",
    "link": "nadar-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Devar Brides and Grooms in Chennai",
    "link": "devar-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Vellalar Brides and Grooms in Chennai",
    "link": "vellalar-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Pillai Brides and Grooms in Chennai",
    "link": "pillai-marriages-matrimonial-matrimony-chennai"
  },
  {
    "name": "Vanniya Kula Kshatriyar Brides and Grooms in Madurai",
    "link": "vanniya-kula-kshatriyar-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Brahmin Brides and Grooms in Madurai",
    "link": "brahmin-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Iyer Brides and Grooms in Madurai",
    "link": "iyer-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Iyengar Brides and Grooms in Madurai",
    "link": "iyengar-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Tamil Brahmin Brides and Grooms in Madurai",
    "link": "tamil-brahmin-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Chettiar Brides and Grooms in Madurai",
    "link": "chettiar-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Mudaliyar Brides and Grooms in Madurai",
    "link": "mudaliyar-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Gounder Brides and Grooms in Madurai",
    "link": "gounder-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Nadar Brides and Grooms in Madurai",
    "link": "nadar-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Devar Brides and Grooms in Madurai",
    "link": "devar-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Vellalar Brides and Grooms in Madurai",
    "link": "vellalar-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Pillai Brides and Grooms in Madurai",
    "link": "pillai-marriages-matrimonial-matrimony-madurai"
  },
  {
    "name": "Vanniya Kula Kshatriyar Brides and Grooms in Coimbatore",
    "link": "vanniya-kula-kshatriyar-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Brahmin Brides and Grooms in Coimbatore",
    "link": "brahmin-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Iyer Brides and Grooms in Coimbatore",
    "link": "iyer-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Iyengar Brides and Grooms in Coimbatore",
    "link": "iyengar-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Tamil Brahmin Brides and Grooms in Coimbatore",
    "link": "tamil-brahmin-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Chettiar Brides and Grooms in Coimbatore",
    "link": "chettiar-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Mudaliyar Brides and Grooms in Coimbatore",
    "link": "mudaliyar-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Gounder Brides and Grooms in Coimbatore",
    "link": "gounder-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Nadar Brides and Grooms in Coimbatore",
    "link": "nadar-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Devar Brides and Grooms in Coimbatore",
    "link": "devar-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Vellalar Brides and Grooms in Coimbatore",
    "link": "vellalar-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Pillai Brides and Grooms in Coimbatore",
    "link": "pillai-marriages-matrimonial-matrimony-coimbatore"
  },
  {
    "name": "Vanniya Kula Kshatriyar Brides and Grooms in Trichy",
    "link": "vanniya-kula-kshatriyar-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Brahmin Brides and Grooms in Trichy",
    "link": "brahmin-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Iyer Brides and Grooms in Trichy",
    "link": "iyer-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Iyengar Brides and Grooms in Trichy",
    "link": "iyengar-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Tamil Brahmin Brides and Grooms in Trichy",
    "link": "tamil-brahmin-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Chettiar Brides and Grooms in Trichy",
    "link": "chettiar-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Mudaliyar Brides and Grooms in Trichy",
    "link": "mudaliyar-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Gounder Brides and Grooms in Trichy",
    "link": "gounder-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Nadar Brides and Grooms in Trichy",
    "link": "nadar-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Devar Brides and Grooms in Trichy",
    "link": "devar-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Vellalar Brides and Grooms in Trichy",
    "link": "vellalar-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Pillai Brides and Grooms in Trichy",
    "link": "pillai-marriages-matrimonial-matrimony-trichy"
  },
  {
    "name": "Vanniya Kula Kshatriyar Brides and Grooms in Pondicherry",
    "link": "vanniya-kula-kshatriyar-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Brahmin Brides and Grooms in Pondicherry",
    "link": "brahmin-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Iyer Brides and Grooms in Pondicherry",
    "link": "iyer-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Iyengar Brides and Grooms in Pondicherry",
    "link": "iyengar-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Tamil Brahmin Brides and Grooms in Pondicherry",
    "link": "tamil-brahmin-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Chettiar Brides and Grooms in Pondicherry",
    "link": "chettiar-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Mudaliyar Brides and Grooms in Pondicherry",
    "link": "mudaliyar-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Gounder Brides and Grooms in Pondicherry",
    "link": "gounder-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Nadar Brides and Grooms in Pondicherry",
    "link": "nadar-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Devar Brides and Grooms in Pondicherry",
    "link": "devar-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Vellalar Brides and Grooms in Pondicherry",
    "link": "vellalar-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Pillai Brides and Grooms in Pondicherry",
    "link": "pillai-marriages-matrimonial-matrimony-pondicherry"
  },
  {
    "name": "Vanniya Kula Kshatriyar Brides and Grooms in Salem",
    "link": "vanniya-kula-kshatriyar-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Brahmin Brides and Grooms in Salem",
    "link": "brahmin-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Iyer Brides and Grooms in Salem",
    "link": "iyer-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Iyengar Brides and Grooms in Salem",
    "link": "iyengar-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Tamil Brahmin Brides and Grooms in Salem",
    "link": "tamil-brahmin-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Chettiar Brides and Grooms in Salem",
    "link": "chettiar-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Mudaliyar Brides and Grooms in Salem",
    "link": "mudaliyar-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Gounder Brides and Grooms in Salem",
    "link": "gounder-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Nadar Brides and Grooms in Salem",
    "link": "nadar-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Devar Brides and Grooms in Salem",
    "link": "devar-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Vellalar Brides and Grooms in Salem",
    "link": "vellalar-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Pillai Brides and Grooms in Salem",
    "link": "pillai-marriages-matrimonial-matrimony-salem"
  },
  {
    "name": "Vanniya Kula Kshatriyar Brides and Grooms in Tiruppur",
    "link": "vanniya-kula-kshatriyar-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Brahmin Brides and Grooms in Tiruppur",
    "link": "brahmin-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Iyer Brides and Grooms in Tiruppur",
    "link": "iyer-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Iyengar Brides and Grooms in Tiruppur",
    "link": "iyengar-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Tamil Brahmin Brides and Grooms in Tiruppur",
    "link": "tamil-brahmin-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Chettiar Brides and Grooms in Tiruppur",
    "link": "chettiar-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Mudaliyar Brides and Grooms in Tiruppur",
    "link": "mudaliyar-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Gounder Brides and Grooms in Tiruppur",
    "link": "gounder-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Nadar Brides and Grooms in Tiruppur",
    "link": "nadar-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Devar Brides and Grooms in Tiruppur",
    "link": "devar-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Vellalar Brides and Grooms in Tiruppur",
    "link": "vellalar-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Pillai Brides and Grooms in Tiruppur",
    "link": "pillai-marriages-matrimonial-matrimony-tiruppur"
  },
  {
    "name": "Vanniya Kula Kshatriyar Brides and Grooms in Tirunelveli",
    "link": "vanniya-kula-kshatriyar-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Brahmin Brides and Grooms in Tirunelveli",
    "link": "brahmin-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Iyer Brides and Grooms in Tirunelveli",
    "link": "iyer-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Iyengar Brides and Grooms in Tirunelveli",
    "link": "iyengar-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Tamil Brahmin Brides and Grooms in Tirunelveli",
    "link": "tamil-brahmin-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Chettiar Brides and Grooms in Tirunelveli",
    "link": "chettiar-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Mudaliyar Brides and Grooms in Tirunelveli",
    "link": "mudaliyar-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Gounder Brides and Grooms in Tirunelveli",
    "link": "gounder-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Nadar Brides and Grooms in Tirunelveli",
    "link": "nadar-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Devar Brides and Grooms in Tirunelveli",
    "link": "devar-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Vellalar Brides and Grooms in Tirunelveli",
    "link": "vellalar-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Pillai Brides and Grooms in Tirunelveli",
    "link": "pillai-marriages-matrimonial-matrimony-tirunelveli"
  },
  {
    "name": "Vanniya Kula Kshatriyar Brides and Grooms in Tuticorin",
    "link": "vanniya-kula-kshatriyar-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Brahmin Brides and Grooms in Tuticorin",
    "link": "brahmin-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Iyer Brides and Grooms in Tuticorin",
    "link": "iyer-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Iyengar Brides and Grooms in Tuticorin",
    "link": "iyengar-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Tamil Brahmin Brides and Grooms in Tuticorin",
    "link": "tamil-brahmin-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Chettiar Brides and Grooms in Tuticorin",
    "link": "chettiar-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Mudaliyar Brides and Grooms in Tuticorin",
    "link": "mudaliyar-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Gounder Brides and Grooms in Tuticorin",
    "link": "gounder-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Nadar Brides and Grooms in Tuticorin",
    "link": "nadar-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Devar Brides and Grooms in Tuticorin",
    "link": "devar-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Vellalar Brides and Grooms in Tuticorin",
    "link": "vellalar-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Pillai Brides and Grooms in Tuticorin",
    "link": "pillai-marriages-matrimonial-matrimony-tuticorin"
  },
  {
    "name": "Vanniya Kula Kshatriyar Brides and Grooms in Nagercoil",
    "link": "vanniya-kula-kshatriyar-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Brahmin Brides and Grooms in Nagercoil",
    "link": "brahmin-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Iyer Brides and Grooms in Nagercoil",
    "link": "iyer-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Iyengar Brides and Grooms in Nagercoil",
    "link": "iyengar-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Tamil Brahmin Brides and Grooms in Nagercoil",
    "link": "tamil-brahmin-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Chettiar Brides and Grooms in Nagercoil",
    "link": "chettiar-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Mudaliyar Brides and Grooms in Nagercoil",
    "link": "mudaliyar-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Gounder Brides and Grooms in Nagercoil",
    "link": "gounder-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Nadar Brides and Grooms in Nagercoil",
    "link": "nadar-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Devar Brides and Grooms in Nagercoil",
    "link": "devar-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Vellalar Brides and Grooms in Nagercoil",
    "link": "vellalar-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Pillai Brides and Grooms in Nagercoil",
    "link": "pillai-marriages-matrimonial-matrimony-nagercoil"
  },
  {
    "name": "Brahmin Brides and Grooms in Trivandrum",
    "link": "brahmin-marriages-matrimonial-matrimony-trivandrum"
  },
  {
    "name": "Kerala Brahmin Brides and Grooms in Trivandrum",
    "link": "kerala-brahmin-marriages-matrimonial-matrimony-trivandrum"
  },
  {
    "name": "Iyer Brides and Grooms in Trivandrum",
    "link": "iyer-marriages-matrimonial-matrimony-trivandrum"
  },
  {
    "name": "Iyengar Brides and Grooms in Trivandrum",
    "link": "iyengar-marriages-matrimonial-matrimony-trivandrum"
  },
  {
    "name": "Nair Brides and Grooms in Trivandrum",
    "link": "nair-marriages-matrimonial-matrimony-trivandrum"
  },
  {
    "name": "Ezhava Brides and Grooms in Trivandrum",
    "link": "ezhava-marriages-matrimonial-matrimony-trivandrum"
  },
  {
    "name": "Nambiar Brides and Grooms in Trivandrum",
    "link": "nambiar-marriages-matrimonial-matrimony-trivandrum"
  },
  {
    "name": "Thiyya Brides and Grooms in Trivandrum",
    "link": "thiyya-marriages-matrimonial-matrimony-trivandrum"
  },
  {
    "name": "Iyer Brides and Grooms in Ernakulam Kochi",
    "link": "iyer-marriages-matrimonial-matrimony-ernakulam-kochi"
  },
  {
    "name": "Brahmin Brides and Grooms in Ernakulam Kochi",
    "link": "brahmin-marriages-matrimonial-matrimony-ernakulam-kochi"
  },
  {
    "name": "Kerala Brahmin Brides and Grooms in Ernakulam Kochi",
    "link": "kerala-brahmin-marriages-matrimonial-matrimony-ernakulam-kochi"
  },
  {
    "name": "Ezhava Brides and Grooms in Ernakulam Kochi",
    "link": "ezhava-marriages-matrimonial-matrimony-ernakulam-kochi"
  },
  {
    "name": "Iyengar Brides and Grooms in Ernakulam Kochi",
    "link": "iyengar-marriages-matrimonial-matrimony-ernakulam-kochi"
  },
  {
    "name": "Nair Brides and Grooms in Ernakulam Kochi",
    "link": "nair-marriages-matrimonial-matrimony-ernakulam-kochi"
  },
  {
    "name": "Iyer Brides and Grooms in Palakkad",
    "link": "iyer-marriages-matrimonial-matrimony-palakkad"
  },
  {
    "name": "Nambiar Brides and Grooms in Ernakulam Kochi",
    "link": "nambiar-marriages-matrimonial-matrimony-ernakulam-kochi"
  },
  {
    "name": "Thiyya Brides and Grooms in Ernakulam Kochi",
    "link": "thiyya-marriages-matrimonial-matrimony-ernakulam-kochi"
  },
  {
    "name": "Ezhava Brides and Grooms in Palakkad",
    "link": "ezhava-marriages-matrimonial-matrimony-palakkad"
  },
  {
    "name": "Brahmin Brides and Grooms in Palakkad",
    "link": "brahmin-marriages-matrimonial-matrimony-palakkad"
  },
  {
    "name": "Kerala Brahmin Brides and Grooms in Palakkad",
    "link": "kerala-brahmin-marriages-matrimonial-matrimony-palakkad"
  },
  {
    "name": "Iyer Brides and Grooms in Kozhikode",
    "link": "iyer-marriages-matrimonial-matrimony-kozhikode"
  },
  {
    "name": "Iyengar Brides and Grooms in Palakkad",
    "link": "iyengar-marriages-matrimonial-matrimony-palakkad"
  },
  {
    "name": "Nair Brides and Grooms in Palakkad",
    "link": "nair-marriages-matrimonial-matrimony-palakkad"
  },
  {
    "name": "Ezhava Brides and Grooms in Kozhikode",
    "link": "ezhava-marriages-matrimonial-matrimony-kozhikode"
  },
  {
    "name": "Nambiar Brides and Grooms in Palakkad",
    "link": "nambiar-marriages-matrimonial-matrimony-palakkad"
  },
  {
    "name": "Thiyya Brides and Grooms in Palakkad",
    "link": "thiyya-marriages-matrimonial-matrimony-palakkad"
  },
  {
    "name": "Iyer Brides and Grooms in Thrissur",
    "link": "iyer-marriages-matrimonial-matrimony-thrissur"
  },
  {
    "name": "Brahmin Brides and Grooms in Kozhikode",
    "link": "brahmin-marriages-matrimonial-matrimony-kozhikode"
  },
  {
    "name": "Kerala Brahmin Brides and Grooms in Kozhikode",
    "link": "kerala-brahmin-marriages-matrimonial-matrimony-kozhikode"
  },
  {
    "name": "Ezhava Brides and Grooms in Thrissur",
    "link": "ezhava-marriages-matrimonial-matrimony-thrissur"
  },
  {
    "name": "Iyengar Brides and Grooms in Kozhikode",
    "link": "iyengar-marriages-matrimonial-matrimony-kozhikode"
  },
  {
    "name": "Nair Brides and Grooms in Kozhikode",
    "link": "nair-marriages-matrimonial-matrimony-kozhikode"
  },
  {
    "name": "Iyer Brides and Grooms in Kollam",
    "link": "iyer-marriages-matrimonial-matrimony-kollam"
  },
  {
    "name": "Nambiar Brides and Grooms in Kozhikode",
    "link": "nambiar-marriages-matrimonial-matrimony-kozhikode"
  },
  {
    "name": "Thiyya Brides and Grooms in Kozhikode",
    "link": "thiyya-marriages-matrimonial-matrimony-kozhikode"
  },
  {
    "name": "Ezhava Brides and Grooms in Kollam",
    "link": "ezhava-marriages-matrimonial-matrimony-kollam"
  },
  {
    "name": "Brahmin Brides and Grooms in Thrissur",
    "link": "brahmin-marriages-matrimonial-matrimony-thrissur"
  },
  {
    "name": "Kerala Brahmin Brides and Grooms in Thrissur",
    "link": "kerala-brahmin-marriages-matrimonial-matrimony-thrissur"
  },
  {
    "name": "Iyer Brides and Grooms in Kottayam",
    "link": "iyer-marriages-matrimonial-matrimony-kottayam"
  },
  {
    "name": "Iyengar Brides and Grooms in Thrissur",
    "link": "iyengar-marriages-matrimonial-matrimony-thrissur"
  },
  {
    "name": "Nair Brides and Grooms in Thrissur",
    "link": "nair-marriages-matrimonial-matrimony-thrissur"
  },
  {
    "name": "Ezhava Brides and Grooms in Kottayam",
    "link": "ezhava-marriages-matrimonial-matrimony-kottayam"
  },
  {
    "name": "Nambiar Brides and Grooms in Thrissur",
    "link": "nambiar-marriages-matrimonial-matrimony-thrissur"
  },
  {
    "name": "Thiyya Brides and Grooms in Thrissur",
    "link": "thiyya-marriages-matrimonial-matrimony-thrissur"
  },
  {
    "name": "Iyer Brides and Grooms in Alappuzha",
    "link": "iyer-marriages-matrimonial-matrimony-alappuzha"
  },
  {
    "name": "Brahmin Brides and Grooms in Kollam",
    "link": "brahmin-marriages-matrimonial-matrimony-kollam"
  },
  {
    "name": "Kerala Brahmin Brides and Grooms in Kollam",
    "link": "kerala-brahmin-marriages-matrimonial-matrimony-kollam"
  },
  {
    "name": "Ezhava Brides and Grooms in Alappuzha",
    "link": "ezhava-marriages-matrimonial-matrimony-alappuzha"
  },
  {
    "name": "Iyengar Brides and Grooms in Kollam",
    "link": "iyengar-marriages-matrimonial-matrimony-kollam"
  },
  {
    "name": "Nair Brides and Grooms in Kollam",
    "link": "nair-marriages-matrimonial-matrimony-kollam"
  },
  {
    "name": "Iyer Brides and Grooms in Malappuram",
    "link": "iyer-marriages-matrimonial-matrimony-malappuram"
  },
  {
    "name": "Nambiar Brides and Grooms in Kollam",
    "link": "nambiar-marriages-matrimonial-matrimony-kollam"
  },
  {
    "name": "Thiyya Brides and Grooms in Kollam",
    "link": "thiyya-marriages-matrimonial-matrimony-kollam"
  },
  {
    "name": "Ezhava Brides and Grooms in Malappuram",
    "link": "ezhava-marriages-matrimonial-matrimony-malappuram"
  },
  {
    "name": "Brahmin Brides and Grooms in Kottayam",
    "link": "brahmin-marriages-matrimonial-matrimony-kottayam"
  },
  {
    "name": "Kerala Brahmin Brides and Grooms in Kottayam",
    "link": "kerala-brahmin-marriages-matrimonial-matrimony-kottayam"
  },
  {
    "name": "Iyer Brides and Grooms in Kannur",
    "link": "iyer-marriages-matrimonial-matrimony-kannur"
  },
  {
    "name": "Iyengar Brides and Grooms in Kottayam",
    "link": "iyengar-marriages-matrimonial-matrimony-kottayam"
  },
  {
    "name": "Nair Brides and Grooms in Kottayam",
    "link": "nair-marriages-matrimonial-matrimony-kottayam"
  },
  {
    "name": "Ezhava Brides and Grooms in Kannur",
    "link": "ezhava-marriages-matrimonial-matrimony-kannur"
  },
  {
    "name": "Nambiar Brides and Grooms in Kottayam",
    "link": "nambiar-marriages-matrimonial-matrimony-kottayam"
  },
  {
    "name": "Thiyya Brides and Grooms in Kottayam",
    "link": "thiyya-marriages-matrimonial-matrimony-kottayam"
  },
  {
    "name": "Iyer Brides and Grooms in Bangalore",
    "link": "iyer-marriages-matrimonial-matrimony-bangalore"
  },
  {
    "name": "Brahmin Brides and Grooms in Alappuzha",
    "link": "brahmin-marriages-matrimonial-matrimony-alappuzha"
  },
  {
    "name": "Kerala Brahmin Brides and Grooms in Alappuzha",
    "link": "kerala-brahmin-marriages-matrimonial-matrimony-alappuzha"
  },
  {
    "name": "Gowda Brides and Grooms in Bangalore",
    "link": "gowda-marriages-matrimonial-matrimony-bangalore"
  },
  {
    "name": "Iyengar Brides and Grooms in Alappuzha",
    "link": "iyengar-marriages-matrimonial-matrimony-alappuzha"
  },
  {
    "name": "Nair Brides and Grooms in Alappuzha",
    "link": "nair-marriages-matrimonial-matrimony-alappuzha"
  },
  {
    "name": "Vokkaliga Brides and Grooms in Bangalore",
    "link": "vokkaliga-marriages-matrimonial-matrimony-bangalore"
  },
  {
    "name": "Nambiar Brides and Grooms in Alappuzha",
    "link": "nambiar-marriages-matrimonial-matrimony-alappuzha"
  },
  {
    "name": "Thiyya Brides and Grooms in Alappuzha",
    "link": "thiyya-marriages-matrimonial-matrimony-alappuzha"
  },
  {
    "name": "Iyer Brides and Grooms in Mangalore",
    "link": "iyer-marriages-matrimonial-matrimony-mangalore"
  },
  {
    "name": "Brahmin Brides and Grooms in Malappuram",
    "link": "brahmin-marriages-matrimonial-matrimony-malappuram"
  },
  {
    "name": "Kerala Brahmin Brides and Grooms in Malappuram",
    "link": "kerala-brahmin-marriages-matrimonial-matrimony-malappuram"
  },
  {
    "name": "Gowda Brides and Grooms in Mangalore",
    "link": "gowda-marriages-matrimonial-matrimony-mangalore"
  },
  {
    "name": "Iyengar Brides and Grooms in Malappuram",
    "link": "iyengar-marriages-matrimonial-matrimony-malappuram"
  },
  {
    "name": "Nair Brides and Grooms in Malappuram",
    "link": "nair-marriages-matrimonial-matrimony-malappuram"
  },
  {
    "name": "Vokkaliga Brides and Grooms in Mangalore",
    "link": "vokkaliga-marriages-matrimonial-matrimony-mangalore"
  },
  {
    "name": "Nambiar Brides and Grooms in Malappuram",
    "link": "nambiar-marriages-matrimonial-matrimony-malappuram"
  },
  {
    "name": "Thiyya Brides and Grooms in Malappuram",
    "link": "thiyya-marriages-matrimonial-matrimony-malappuram"
  },
  {
    "name": "Iyer Brides and Grooms in Mysore",
    "link": "iyer-marriages-matrimonial-matrimony-mysore"
  },
  {
    "name": "Brahmin Brides and Grooms in Kannur",
    "link": "brahmin-marriages-matrimonial-matrimony-kannur"
  },
  {
    "name": "Kerala Brahmin Brides and Grooms in Kannur",
    "link": "kerala-brahmin-marriages-matrimonial-matrimony-kannur"
  },
  {
    "name": "Gowda Brides and Grooms in Mysore",
    "link": "gowda-marriages-matrimonial-matrimony-mysore"
  },
  {
    "name": "Iyengar Brides and Grooms in Kannur",
    "link": "iyengar-marriages-matrimonial-matrimony-kannur"
  },
  {
    "name": "Nair Brides and Grooms in Kannur",
    "link": "nair-marriages-matrimonial-matrimony-kannur"
  },
  {
    "name": "Vokkaliga Brides and Grooms in Mysore",
    "link": "vokkaliga-marriages-matrimonial-matrimony-mysore"
  },
  {
    "name": "Nambiar Brides and Grooms in Kannur",
    "link": "nambiar-marriages-matrimonial-matrimony-kannur"
  },
  {
    "name": "Thiyya Brides and Grooms in Kannur",
    "link": "thiyya-marriages-matrimonial-matrimony-kannur"
  },
  {
    "name": "Iyer Brides and Grooms in Belgaum",
    "link": "iyer-marriages-matrimonial-matrimony-belgaum"
  },
  {
    "name": "Brahmin Brides and Grooms in Bangalore",
    "link": "brahmin-marriages-matrimonial-matrimony-bangalore"
  },
  {
    "name": "Kannada Brahmin Brides and Grooms in Bangalore",
    "link": "kannada-brahmin-marriages-matrimonial-matrimony-bangalore"
  },
  {
    "name": "Gowda Brides and Grooms in Belgaum",
    "link": "gowda-marriages-matrimonial-matrimony-belgaum"
  },
  {
    "name": "Iyengar Brides and Grooms in Bangalore",
    "link": "iyengar-marriages-matrimonial-matrimony-bangalore"
  },
  {
    "name": "Lingayath Brides and Grooms in Bangalore",
    "link": "lingayath-marriages-matrimonial-matrimony-bangalore"
  },
  {
    "name": "Vokkaliga Brides and Grooms in Belgaum",
    "link": "vokkaliga-marriages-matrimonial-matrimony-belgaum"
  },
  {
    "name": "Devanga Brides and Grooms in Bangalore",
    "link": "devanga-marriages-matrimonial-matrimony-bangalore"
  },
  {
    "name": "Vishwakarma Brides and Grooms in Bangalore",
    "link": "vishwakarma-marriages-matrimonial-matrimony-bangalore"
  },
  {
    "name": "Iyer Brides and Grooms in Hubli",
    "link": "iyer-marriages-matrimonial-matrimony-hubli"
  },
  {
    "name": "Adi Kannada Brides and Grooms in Bangalore",
    "link": "adi-kannada-marriages-matrimonial-matrimony-bangalore"
  },
  {
    "name": "Kannada Brahmin Brides and Grooms in Mangalore",
    "link": "kannada-brahmin-marriages-matrimonial-matrimony-mangalore"
  },
  {
    "name": "Gowda Brides and Grooms in Hubli",
    "link": "gowda-marriages-matrimonial-matrimony-hubli"
  },
  {
    "name": "Brahmin Brides and Grooms in Mangalore",
    "link": "brahmin-marriages-matrimonial-matrimony-mangalore"
  },
  {
    "name": "Lingayath Brides and Grooms in Mangalore",
    "link": "lingayath-marriages-matrimonial-matrimony-mangalore"
  },
  {
    "name": "Vokkaliga Brides and Grooms in Hubli",
    "link": "vokkaliga-marriages-matrimonial-matrimony-hubli"
  },
  {
    "name": "Iyengar Brides and Grooms in Mangalore",
    "link": "iyengar-marriages-matrimonial-matrimony-mangalore"
  },
  {
    "name": "Vishwakarma Brides and Grooms in Mangalore",
    "link": "vishwakarma-marriages-matrimonial-matrimony-mangalore"
  },
  {
    "name": "Iyer Brides and Grooms in Bagalkot",
    "link": "iyer-marriages-matrimonial-matrimony-bagalkot"
  },
  {
    "name": "Devanga Brides and Grooms in Mangalore",
    "link": "devanga-marriages-matrimonial-matrimony-mangalore"
  },
  {
    "name": "Kannada Brahmin Brides and Grooms in Mysore",
    "link": "kannada-brahmin-marriages-matrimonial-matrimony-mysore"
  },
  {
    "name": "Gowda Brides and Grooms in Bagalkot",
    "link": "gowda-marriages-matrimonial-matrimony-bagalkot"
  },
  {
    "name": "Adi Kannada Brides and Grooms in Mangalore",
    "link": "adi-kannada-marriages-matrimonial-matrimony-mangalore"
  },
  {
    "name": "Lingayath Brides and Grooms in Mysore",
    "link": "lingayath-marriages-matrimonial-matrimony-mysore"
  },
  {
    "name": "Vokkaliga Brides and Grooms in Bagalkot",
    "link": "vokkaliga-marriages-matrimonial-matrimony-bagalkot"
  },
  {
    "name": "Brahmin Brides and Grooms in Mysore",
    "link": "brahmin-marriages-matrimonial-matrimony-mysore"
  },
  {
    "name": "Vishwakarma Brides and Grooms in Mysore",
    "link": "vishwakarma-marriages-matrimonial-matrimony-mysore"
  },
  {
    "name": "Iyer Brides and Grooms in Gulbarga",
    "link": "iyer-marriages-matrimonial-matrimony-gulbarga"
  },
  {
    "name": "Iyengar Brides and Grooms in Mysore",
    "link": "iyengar-marriages-matrimonial-matrimony-mysore"
  },
  {
    "name": "Kannada Brahmin Brides and Grooms in Belgaum",
    "link": "kannada-brahmin-marriages-matrimonial-matrimony-belgaum"
  },
  {
    "name": "Gowda Brides and Grooms in Gulbarga",
    "link": "gowda-marriages-matrimonial-matrimony-gulbarga"
  },
  {
    "name": "Devanga Brides and Grooms in Mysore",
    "link": "devanga-marriages-matrimonial-matrimony-mysore"
  },
  {
    "name": "Lingayath Brides and Grooms in Belgaum",
    "link": "lingayath-marriages-matrimonial-matrimony-belgaum"
  },
  {
    "name": "Vokkaliga Brides and Grooms in Gulbarga",
    "link": "vokkaliga-marriages-matrimonial-matrimony-gulbarga"
  },
  {
    "name": "Adi Kannada Brides and Grooms in Mysore",
    "link": "adi-kannada-marriages-matrimonial-matrimony-mysore"
  },
  {
    "name": "Vishwakarma Brides and Grooms in Belgaum",
    "link": "vishwakarma-marriages-matrimonial-matrimony-belgaum"
  },
  {
    "name": "Iyer Brides and Grooms in Visakhapatnam",
    "link": "iyer-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Brahmin Brides and Grooms in Belgaum",
    "link": "brahmin-marriages-matrimonial-matrimony-belgaum"
  },
  {
    "name": "Kannada Brahmin Brides and Grooms in Hubli",
    "link": "kannada-brahmin-marriages-matrimonial-matrimony-hubli"
  },
  {
    "name": "Kapu Brides and Grooms in Visakhapatnam",
    "link": "kapu-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Iyengar Brides and Grooms in Belgaum",
    "link": "iyengar-marriages-matrimonial-matrimony-belgaum"
  },
  {
    "name": "Lingayath Brides and Grooms in Hubli",
    "link": "lingayath-marriages-matrimonial-matrimony-hubli"
  },
  {
    "name": "Naidu Brides and Grooms in Visakhapatnam",
    "link": "naidu-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Devanga Brides and Grooms in Belgaum",
    "link": "devanga-marriages-matrimonial-matrimony-belgaum"
  },
  {
    "name": "Vishwakarma Brides and Grooms in Hubli",
    "link": "vishwakarma-marriages-matrimonial-matrimony-hubli"
  },
  {
    "name": "Telugu Yadav Brides and Grooms in Visakhapatnam",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Adi Kannada Brides and Grooms in Belgaum",
    "link": "adi-kannada-marriages-matrimonial-matrimony-belgaum"
  },
  {
    "name": "Kannada Brahmin Brides and Grooms in Bagalkot",
    "link": "kannada-brahmin-marriages-matrimonial-matrimony-bagalkot"
  },
  {
    "name": "Iyer Brides and Grooms in Nellore",
    "link": "iyer-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Brahmin Brides and Grooms in Hubli",
    "link": "brahmin-marriages-matrimonial-matrimony-hubli"
  },
  {
    "name": "Lingayath Brides and Grooms in Bagalkot",
    "link": "lingayath-marriages-matrimonial-matrimony-bagalkot"
  },
  {
    "name": "Kapu Brides and Grooms in Nellore",
    "link": "kapu-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Iyengar Brides and Grooms in Hubli",
    "link": "iyengar-marriages-matrimonial-matrimony-hubli"
  },
  {
    "name": "Vishwakarma Brides and Grooms in Bagalkot",
    "link": "vishwakarma-marriages-matrimonial-matrimony-bagalkot"
  },
  {
    "name": "Naidu Brides and Grooms in Nellore",
    "link": "naidu-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Devanga Brides and Grooms in Hubli",
    "link": "devanga-marriages-matrimonial-matrimony-hubli"
  },
  {
    "name": "Kannada Brahmin Brides and Grooms in Gulbarga",
    "link": "kannada-brahmin-marriages-matrimonial-matrimony-gulbarga"
  },
  {
    "name": "Telugu Yadav Brides and Grooms in Nellore",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Adi Kannada Brides and Grooms in Hubli",
    "link": "adi-kannada-marriages-matrimonial-matrimony-hubli"
  },
  {
    "name": "Lingayath Brides and Grooms in Gulbarga",
    "link": "lingayath-marriages-matrimonial-matrimony-gulbarga"
  },
  {
    "name": "Iyer Brides and Grooms in Guntur",
    "link": "iyer-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Brahmin Brides and Grooms in Bagalkot",
    "link": "brahmin-marriages-matrimonial-matrimony-bagalkot"
  },
  {
    "name": "Vishwakarma Brides and Grooms in Gulbarga",
    "link": "vishwakarma-marriages-matrimonial-matrimony-gulbarga"
  },
  {
    "name": "Kapu Brides and Grooms in Guntur",
    "link": "kapu-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Iyengar Brides and Grooms in Bagalkot",
    "link": "iyengar-marriages-matrimonial-matrimony-bagalkot"
  },
  {
    "name": "Telugu Brahmin Brides and Grooms in Visakhapatnam",
    "link": "telugu-brahmin-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Naidu Brides and Grooms in Guntur",
    "link": "naidu-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Devanga Brides and Grooms in Bagalkot",
    "link": "devanga-marriages-matrimonial-matrimony-bagalkot"
  },
  {
    "name": "Arya Vysya Brides and Grooms in Visakhapatnam",
    "link": "arya-vysya-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Telugu Yadav Brides and Grooms in Guntur",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Adi Kannada Brides and Grooms in Bagalkot",
    "link": "adi-kannada-marriages-matrimonial-matrimony-bagalkot"
  },
  {
    "name": "Reddy Brides and Grooms in Visakhapatnam",
    "link": "reddy-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Iyer Brides and Grooms in Vijayawada",
    "link": "iyer-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Brahmin Brides and Grooms in Gulbarga",
    "link": "brahmin-marriages-matrimonial-matrimony-gulbarga"
  },
  {
    "name": "Yadav Brides and Grooms in Visakhapatnam",
    "link": "yadav-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Kapu Brides and Grooms in Vijayawada",
    "link": "kapu-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Iyengar Brides and Grooms in Gulbarga",
    "link": "iyengar-marriages-matrimonial-matrimony-gulbarga"
  },
  {
    "name": "Telugu Brahmin Brides and Grooms in Nellore",
    "link": "telugu-brahmin-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Naidu Brides and Grooms in Vijayawada",
    "link": "naidu-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Devanga Brides and Grooms in Gulbarga",
    "link": "devanga-marriages-matrimonial-matrimony-gulbarga"
  },
  {
    "name": "Arya Vysya Brides and Grooms in Nellore",
    "link": "arya-vysya-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Telugu Yadav Brides and Grooms in Vijayawada",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Adi Kannada Brides and Grooms in Gulbarga",
    "link": "adi-kannada-marriages-matrimonial-matrimony-gulbarga"
  },
  {
    "name": "Reddy Brides and Grooms in Nellore",
    "link": "reddy-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Iyer Brides and Grooms in Kurnool",
    "link": "iyer-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Brahmin Brides and Grooms in Visakhapatnam",
    "link": "brahmin-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Yadav Brides and Grooms in Nellore",
    "link": "yadav-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Kapu Brides and Grooms in Kurnool",
    "link": "kapu-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Iyengar Brides and Grooms in Visakhapatnam",
    "link": "iyengar-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Telugu Brahmin Brides and Grooms in Guntur",
    "link": "telugu-brahmin-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Naidu Brides and Grooms in Kurnool",
    "link": "naidu-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Kamma Brides and Grooms in Visakhapatnam",
    "link": "kamma-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Arya Vysya Brides and Grooms in Guntur",
    "link": "arya-vysya-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Telugu Yadav Brides and Grooms in Kurnool",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Balija Brides and Grooms in Visakhapatnam",
    "link": "balija-marriages-matrimonial-matrimony-visakhapatnam"
  },
  {
    "name": "Reddy Brides and Grooms in Guntur",
    "link": "reddy-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Iyer Brides and Grooms in Chittoor",
    "link": "iyer-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Brahmin Brides and Grooms in Nellore",
    "link": "brahmin-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Yadav Brides and Grooms in Guntur",
    "link": "yadav-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Kapu Brides and Grooms in Chittoor",
    "link": "kapu-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Iyengar Brides and Grooms in Nellore",
    "link": "iyengar-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Telugu Brahmin Brides and Grooms in Vijayawada",
    "link": "telugu-brahmin-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Naidu Brides and Grooms in Chittoor",
    "link": "naidu-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Kamma Brides and Grooms in Nellore",
    "link": "kamma-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Arya Vysya Brides and Grooms in Vijayawada",
    "link": "arya-vysya-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Telugu Yadav Brides and Grooms in Chittoor",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Balija Brides and Grooms in Nellore",
    "link": "balija-marriages-matrimonial-matrimony-nellore"
  },
  {
    "name": "Reddy Brides and Grooms in Vijayawada",
    "link": "reddy-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Iyer Brides and Grooms in Hyderabad",
    "link": "iyer-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Brahmin Brides and Grooms in Guntur",
    "link": "brahmin-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Yadav Brides and Grooms in Vijayawada",
    "link": "yadav-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Kapu Brides and Grooms in Hyderabad",
    "link": "kapu-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Iyengar Brides and Grooms in Guntur",
    "link": "iyengar-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Telugu Brahmin Brides and Grooms in Kurnool",
    "link": "telugu-brahmin-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Naidu Brides and Grooms in Hyderabad",
    "link": "naidu-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Kamma Brides and Grooms in Guntur",
    "link": "kamma-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Arya Vysya Brides and Grooms in Kurnool",
    "link": "arya-vysya-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Telugu Yadav Brides and Grooms in Hyderabad",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Balija Brides and Grooms in Guntur",
    "link": "balija-marriages-matrimonial-matrimony-guntur"
  },
  {
    "name": "Reddy Brides and Grooms in Kurnool",
    "link": "reddy-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Iyer Brides and Grooms in Secunderabad",
    "link": "iyer-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Brahmin Brides and Grooms in Vijayawada",
    "link": "brahmin-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Yadav Brides and Grooms in Kurnool",
    "link": "yadav-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Kapu Brides and Grooms in Secunderabad",
    "link": "kapu-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Iyengar Brides and Grooms in Vijayawada",
    "link": "iyengar-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Telugu Brahmin Brides and Grooms in Chittoor",
    "link": "telugu-brahmin-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Naidu Brides and Grooms in Secunderabad",
    "link": "naidu-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Kamma Brides and Grooms in Vijayawada",
    "link": "kamma-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Arya Vysya Brides and Grooms in Chittoor",
    "link": "arya-vysya-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Telugu Yadav Brides and Grooms in Secunderabad",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Balija Brides and Grooms in Vijayawada",
    "link": "balija-marriages-matrimonial-matrimony-vijayawada"
  },
  {
    "name": "Reddy Brides and Grooms in Chittoor",
    "link": "reddy-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Iyer Brides and Grooms in Warangal",
    "link": "iyer-marriages-matrimonial-matrimony-warangal"
  },
  {
    "name": "Brahmin Brides and Grooms in Kurnool",
    "link": "brahmin-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Yadav Brides and Grooms in Chittoor",
    "link": "yadav-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Kapu Brides and Grooms in Warangal",
    "link": "kapu-marriages-matrimonial-matrimony-warangal"
  },
  {
    "name": "Iyengar Brides and Grooms in Kurnool",
    "link": "iyengar-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Telugu Brahmin Brides and Grooms in Hyderabad",
    "link": "telugu-brahmin-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Naidu Brides and Grooms in Warangal",
    "link": "naidu-marriages-matrimonial-matrimony-warangal"
  },
  {
    "name": "Kamma Brides and Grooms in Kurnool",
    "link": "kamma-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Arya Vysya Brides and Grooms in Hyderabad",
    "link": "arya-vysya-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Telugu Yadav Brides and Grooms in Warangal",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-warangal"
  },
  {
    "name": "Balija Brides and Grooms in Kurnool",
    "link": "balija-marriages-matrimonial-matrimony-kurnool"
  },
  {
    "name": "Reddy Brides and Grooms in Hyderabad",
    "link": "reddy-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Iyer Brides and Grooms in Karimnagar",
    "link": "iyer-marriages-matrimonial-matrimony-karimnagar"
  },
  {
    "name": "Brahmin Brides and Grooms in Chittoor",
    "link": "brahmin-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Yadav Brides and Grooms in Hyderabad",
    "link": "yadav-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Kapu Brides and Grooms in Karimnagar",
    "link": "kapu-marriages-matrimonial-matrimony-karimnagar"
  },
  {
    "name": "Iyengar Brides and Grooms in Chittoor",
    "link": "iyengar-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Telugu Brahmin Brides and Grooms in Secunderabad",
    "link": "telugu-brahmin-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Naidu Brides and Grooms in Karimnagar",
    "link": "naidu-marriages-matrimonial-matrimony-karimnagar"
  },
  {
    "name": "Kamma Brides and Grooms in Chittoor",
    "link": "kamma-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Arya Vysya Brides and Grooms in Secunderabad",
    "link": "arya-vysya-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Telugu Yadav Brides and Grooms in Karimnagar",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-karimnagar"
  },
  {
    "name": "Balija Brides and Grooms in Chittoor",
    "link": "balija-marriages-matrimonial-matrimony-chittoor"
  },
  {
    "name": "Reddy Brides and Grooms in Secunderabad",
    "link": "reddy-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Iyer Brides and Grooms in Khammam",
    "link": "iyer-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Brahmin Brides and Grooms in Hyderabad",
    "link": "brahmin-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Yadav Brides and Grooms in Secunderabad",
    "link": "yadav-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Kapu Brides and Grooms in Khammam",
    "link": "kapu-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Iyengar Brides and Grooms in Hyderabad",
    "link": "iyengar-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Telugu Brahmin Brides and Grooms in Warangal",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Naidu Brides and Grooms in Khammam",
    "link": "naidu-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Kamma Brides and Grooms in Hyderabad",
    "link": "kamma-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Arya Vysya Brides and Grooms in Warangal",
    "link": "arya-vysya-marriages-matrimonial-matrimony-warangal"
  },
  {
    "name": "Telugu Yadav Brides and Grooms in Khammam",
    "link": "telugu-yadav-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Balija Brides and Grooms in Hyderabad",
    "link": "balija-marriages-matrimonial-matrimony-hyderabad"
  },
  {
    "name": "Reddy Brides and Grooms in Warangal",
    "link": "reddy-marriages-matrimonial-matrimony-warangal"
  },
  {
    "name": "Brahmin Brides and Grooms in Khammam",
    "link": "brahmin-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Brahmin Brides and Grooms in Secunderabad",
    "link": "brahmin-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Yadav Brides and Grooms in Warangal",
    "link": "yadav-marriages-matrimonial-matrimony-warangal"
  },
  {
    "name": "Iyengar Brides and Grooms in Khammam",
    "link": "iyengar-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Iyengar Brides and Grooms in Secunderabad",
    "link": "iyengar-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Telugu Brahmin Brides and Grooms in Karimnagar",
    "link": "telugu-brahmin-marriages-matrimonial-matrimony-karimnagar"
  },
  {
    "name": "Kamma Brides and Grooms in Khammam",
    "link": "kamma-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Kamma Brides and Grooms in Secunderabad",
    "link": "kamma-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Arya Vysya Brides and Grooms in Karimnagar",
    "link": "arya-vysya-marriages-matrimonial-matrimony-karimnagar"
  },
  {
    "name": "Balija Brides and Grooms in Khammam",
    "link": "balija-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Balija Brides and Grooms in Secunderabad",
    "link": "balija-marriages-matrimonial-matrimony-secunderabad"
  },
  {
    "name": "Reddy Brides and Grooms in Karimnagar",
    "link": "reddy-marriages-matrimonial-matrimony-karimnagar"
  },
  {
    "name": "Telugu Brahmin Brides and Grooms in Khammam",
    "link": "telugu-brahmin-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Brahmin Brides and Grooms in Warangal",
    "link": "brahmin-marriages-matrimonial-matrimony-warangal"
  },
  {
    "name": "Yadav Brides and Grooms in Karimnagar",
    "link": "yadav-marriages-matrimonial-matrimony-karimnagar"
  },
  {
    "name": "Arya Vysya Brides and Grooms in Khammam",
    "link": "arya-vysya-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Iyengar Brides and Grooms in Warangal",
    "link": "iyengar-marriages-matrimonial-matrimony-warangal"
  },
  {
    "name": "Brahmin Brides and Grooms in Karimnagar",
    "link": "brahmin-marriages-matrimonial-matrimony-karimnagar"
  },
  {
    "name": "Reddy Brides and Grooms in Khammam",
    "link": "reddy-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Kamma Brides and Grooms in Warangal",
    "link": "kamma-marriages-matrimonial-matrimony-warangal"
  },
  {
    "name": "Iyengar Brides and Grooms in Karimnagar",
    "link": "iyengar-marriages-matrimonial-matrimony-karimnagar"
  },
  {
    "name": "Yadav Brides and Grooms in Khammam",
    "link": "yadav-marriages-matrimonial-matrimony-khammam"
  },
  {
    "name": "Balija Brides and Grooms in Warangal",
    "link": "balija-marriages-matrimonial-matrimony-warangal"
  },
  {
    "name": "Kamma Brides and Grooms in Karimnagar",
    "link": "kamma-marriages-matrimonial-matrimony-karimnagar"
  },
  {
    "name": "Balija Brides and Grooms in Karimnagar",
    "link": "balija-marriages-matrimonial-matrimony-karimnagar"
  }
]


//others

export const OTHERS_LIST = [
  {
    "name": "Tamil Christian Brides and Grooms",
    "link": "tamil-christian-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kerala Christian Brides and Grooms",
    "link": "kerala-christian-marriages-matrimonial-matrimony"
  },
  {
    "name": "Telugu Second Marriage Brides and Grooms",
    "link": "telugu-second-marriage-matrimonial-matrimony"
  },
  {
    "name": "Telugu Divorcee Brides and Grooms",
    "link": "telugu-divorcee-marriages-matrimonial-matrimony"
  },
  {
    "name": "Tamil Divorcee Brides and Grooms",
    "link": "tamil-divorcee-marriages-matrimonial-matrimony"
  },
  {
    "name": "Divorcee Brides and Grooms",
    "link": "divorcee-marriages-matrimonial-matrimony"
  },
  {
    "name": "Kerala Divorcee Brides and Grooms",
    "link": "kerala-divorcee-marriages-matrimonial-matrimony"
  }
]