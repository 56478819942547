import React, { createContext, useState } from "react";
export const NavListContext = createContext();
export default function NavListProvider({children}) {
  const [navList, setNavList] = useState(["Matches"]);
  const navMenu = {
    navList,
    setNavList,
  };
  return (
    <NavListContext.Provider value={navMenu}>
      {children}
    </NavListContext.Provider>
  )
}