import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom';
import { LoginContext } from '../Contexts/LoginContext';
import { api } from '../config';

function ProtectedRoute({children}) {
    var loc = new URL(window.location.href)
    api.API_URL = `https://${loc.hostname}`
    const { isLoggedIn } = useContext(LoginContext);
    if (isLoggedIn()) {
        return <Navigate replace={true} to="/dashboard" />
    }
    return children;
}

export default ProtectedRoute