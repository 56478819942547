import React, { useEffect, useState } from 'react'
import welcomeimage from '../../Components/Kalyanlite/assets/Images/mobileinhand.png'
import googleicon from '../../Components/Kalyanlite/assets/Images/google.png'
import playstoreicon from '../../Components/Kalyanlite/assets/Images/appstore.png'
import RouteSegments from '../../Routes/RouteSegment';
import KjBannerPopup from '../DashboardComponents/KjBannerPopup';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import Constants, { EventName } from '../../Constants/constants';
import { useAnalytics } from '../../Hooks/usePageChange';
import { capitalizeFirstLetter } from './utils';
import { useTranslation } from 'react-i18next';

const WelcomePage = (props) => {

    const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

    const userData = useSelector(
        (state) => state?.DashBoardAPI?.ProfileData?.data,
    );

    const { t: trans } = useTranslation();

    const [showKjBannerPopup, setShowKjBannerPopup] = useState(false);
    const [showKjThankPopup, setShowKjThankPopup] = useState(false);
    const [muhuratBanner, setMuhuratBanner] = useState("");
    const { onClickTrack } = useAnalytics()

    const isFamilyPage = useMatch(`${RouteSegments.MATCH_GROUP}`)
    const CommunityName = localStorage.getItem("TAG_CONTENT")?.split(" ")[3];

    useEffect(() => {
        if(userData){
            setMuhuratBanner(userData?.bannerTop?.showphotobanner)
        }
    }, [userData])
    
    const bannerClick = () => {
        onClickTrack(EventName.V3_Matches_Top_KJ_Banner_Book_Appointment_Button)
        setShowKjBannerPopup(true)
    }

    const closeModel = () => {
        setShowKjBannerPopup(false)
        setShowKjThankPopup(false)
    }

    

    return (
        <>
        {showKjBannerPopup ? (
          <KjBannerPopup
            closeModel={closeModel}
            setShowKjThankPopup={setShowKjThankPopup}
            setShowKjBannerPopup={setShowKjBannerPopup}
            title={trans('view_profile.send_message')}
            username={userData?.username}
          />
        ) : (
          <></>
        )}
        {isFamilyPage ? <></> : 
            muhuratBanner &&
            <div className='cursor-pointer' onClick={bannerClick}>
                <img className="h-fit w-full px-[5rem]" src={muhuratBanner} alt="" />
            </div>
        }
        {isCommunitySite ? 
            <div className={`flex justify-center items-center ${ isFamilyPage ? "h-full" : "h-[75%]"} font-bold lg:text-[24px] xl:text-[26px]`}>
                Welcome to <p  className="text-[#D63838] font-bold lg:text-[24px] xl:text-[26px] px-2">{CommunityName}</p> Kalyan Matrimony
            </div> : 
            <div  className={`flex ${isCommunitySite ? "invisible" : "" }`}>
                <div className={props?.menu == "chatpage" ? "flex md:ml-[4.5rem] lg:ml-6":"flex md:ml-[4.5rem] lg:ml-6"}>
                    <div className="flex justify-start items-end ml-auto md:ml-[-4rem] lg:ml-auto">
                        <div className="relative">
                        <div className={`${isFamilyPage ? "md:w-[16rem]  md:h-[92vh] lg:w-[19rem] xl:w-[24rem] xl:h-[35rem] lg:h-[31rem] flex items-end" : "md:w-[16rem]  md:h-[87vh] lg:w-[19rem] xl:w-[24rem] xl:h-[70dvh] lg:h-[70dvh] flex items-end "}`}>
                                <img className="md:h-[30vh] lg:h-[58vh] h-full w-full object-cover" src={welcomeimage} alt="WelcomeImage" />
                            </div>
                        </div>
                    </div>
                </div>
            <div className={props?.menu == "chatpage" ? "self-end right-[-16rem] md:right-[0rem] lg:right-0 relative bottom-[10rem] md:bottom-[13rem] md:mt-[5rem] lg:bottom-[9rem] xl:bottom-[11rem] 2xl:bottom-[13rem] "
            :"self-end right-[-16rem] md:right-[-17rem] lg:right-0 relative bottom-[10rem] md:bottom-[25rem] md:mt-[5rem] lg:bottom-[9rem] xl:bottom-[11rem] 2xl:bottom-[13rem] "}>
                    <div className="flex justify-center flex-col">
                        <p className="lg:text-[24px] xl:text-[26px] text-[#000000DB] whitespace-nowrap"><span className="text-[#D63838] font-bold lg:text-[24px] xl:text-[26px]">{trans('matches.download')}</span> {trans('matches.our_app_to')} </p>
                        <p className="text-[#000000DB] lg:text-[20px] xl:text-[23px] text-start">{trans('matches.search__connect')} </p>
                        <p className="text-[#000000] lg:text-[20px] xl:text-[23px] text-start font-bold">{trans('matches.with_matches_instantly')}</p>
                    </div>
                    <div className="flex md:gap-3 lg:gap-6 xl:gap-10 lg:mt-[2rem] md:mt-[1rem]">
                        <a className='cursor-pointer' target='_blank' href={RouteSegments.staticLinkForAppDownload.ios}>
                            <img className="w-[8rem] md:w-[6rem] md:h-[2rem] lg:h-[2.2rem] xl:w-[7rem] xl:h-[2.4rem] 2xl:w-[8rem] 2xl:h-[2.5rem]" src={playstoreicon} alt="ApptoreIcon" />
                        </a>
                        <a className='cursor-pointer' target='_blank'  href={RouteSegments.staticLinkForAppDownload.android}>

                        <img className="w-[8rem] md:w-[6rem] md:h-[2rem] lg:h-[2.2rem] xl:w-[7rem] xl:h-[2.4rem] 2xl:w-[8rem] 2xl:h-[2.5rem]" src={googleicon} alt="PlayStoreIcon" />
                        </a>
                    </div>
                </div>
            </div>
        }
{/*             

        <div className='md:flex lg:hidden hidden md:block'>
            <img
                className="h-[70vh] w-full object-contained"
                // style={{ width: '100vw', height: '70vh', objectPosition: 'left top' }}
                src={welcomeimage}
                alt="WelcomeImage"
            />
        </div>
        <div className="flex flex-col justify-center items-center">
            <div className="flex justify-center flex-col">
                <p className="lg:text-[24px] xl:text-[26px] text-[#000000DB] whitespace-nowrap">
                    <span className="text-[#D63838] font-bold lg:text-[24px] xl:text-[26px]">Download</span> Our App to
                </p>
                <p className="text-[#000000DB] lg:text-[20px] xl:text-[23px] text-start">Search & Connect with</p>
                <p className="text-[#000000] lg:text-[20px] xl:text-[23px] text-start font-bold">Matches Instantly</p>
            </div>
            <div className="flex gap-4 lg:gap-6 xl:gap-10 mt-[2rem]">
                <a className='cursor-pointer' target='_blank' href={RouteSegments.staticLinkForAppDownload.ios}>
                    <img className="w-[8rem] md:w-[6rem]  md:h-[2rem] lg:h-[2.2rem] xl:h-[2.8rem]" src={playstoreicon} alt="ApptoreIcon" />
                </a>
                <a className='cursor-pointer' target='_blank' href={RouteSegments.staticLinkForAppDownload.android}>
                    <img className="w-[8rem] md:w-[6rem]  md:h-[2rem] lg:h-[2.2rem] xl:h-[2.8rem]" src={googleicon} alt="PlayStoreIcon" />
                </a>
            </div>
        </div> */}
    

        </>
    )
}

export default WelcomePage;
