import { FILTER_REQUEST, FILTER_REQUEST_API_ERROR, FILTER_REQUEST_SUCCESS } from "./ActionTypes";


  //Sent FIlter request
  export const sendFilterRequest = (values) => {
    //console.log(values);
    return {
      type: FILTER_REQUEST,
      payload: {values}
    };
  };

  export const sendFilterRequestSuccess = data => {
    //console.log(data,"12344");
    return {
      type: FILTER_REQUEST_SUCCESS,
      payload: data,
    };
  };
  
  export const sendFilterRequestApiError = error => {
    return {
      type: FILTER_REQUEST_API_ERROR,
      payload: error,
    };
  };


  