import React, { useContext, useEffect, useState } from "react";

import { useLocation } from "react-router";


const SelectedCount = (props) => {

  
 

      const handleChange = (e) => {
        const { name, checked } = e.target;

        console.log(checked,name,"checked");
        if (name === "shortlisted") {
            // alert("short")
            props.setShortlisted(checked  ? "" : "on");
          } else if (name === "ignored") {
            props.setIgnored(checked ? "" : "on");
          } else if (name === "viewed") {
            props.setViewed(checked ? "" : "on");
          }         
          props.setIsLoading(true)
          // props.checkboxchange()        
        }
        
     
    return (
        <>
            <div>
                <div className="flex pt-3 px-2 lg:px-7">
                    <p className="font-semibold text-[#000000] flex flex-col justify-center items-center text-[16px]">{props?.title}</p>
                   
                   {/* Count For View All Page Commented. Do Not Delete This Code */}

                   {/* {props?.count === 0 ? "" :
                  <span className="inline-flex  font-bold justify-center items-center ml-2 w-[3rem] h-[1.8rem] md:w-[3rem] md:h-[2rem] lg:w-[3.5rem] lg:h-[2rem] text-[14px] md:text-[14px] lg:text-[15px] text-[#000000] bg-[#FDC300] rounded-[10px]">
                    {props?.count}
                  </span>  } */}

                </div>

                <div className="space-x-4 mt-4 px-2 lg:px-7 flex">
                    <div className="space-x-2 flex justify-center items-center">

                    <input
                                  id="shortlisted"
                                  name="shortlisted"
                               
                                  onChange={handleChange} 
                                  type="checkbox"
                                  value="shortlisted"
                                  className="accent-red-500 border border-[#F18181] "
                                  checked={props.shortlisted===""?true:false}
                                  
                                 
                                  
                                />

                  
                        <label className="flex text-[#575556] text-[13px] justify-center items-center">Selected
                        </label>
                    </div>

                    <div className="space-x-2 flex justify-center items-center">

                    <input
                                  id="ignored"
                                  name="ignored"
                                 
                                  value="ignored"
                                  onChange={handleChange} 
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  checked={props.ignored ==="" ?true:false}
                                />
                        <label className="flex text-[#575556] text-[13px] justify-center items-center">Rejected
                        </label>
                    </div>
                    <div className="space-x-2 flex justify-center items-center">

                                    <input
                                  id="viewed"
                                  name="viewed"
                                  value="viewed"
                                  
                                  onChange={handleChange} 
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  checked={props.viewed===""?true:false}
                                />
                        <label className="flex text-[#575556] text-[13px] justify-center items-center">Viewed
                        </label>
                    </div>

                </div>

            </div>
        </>
    );
};

export default SelectedCount;
