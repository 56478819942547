import React from 'react'
import { ProfileAccordianComponent } from '../ViewMessageComponents/AccordianComponents';
import PlusIcon from "../../Images/Path 3979.svg"
import AccrodianSelectImage from '../../Images/Group 930@2x.png'
import HobbyComponent from './MyProfileComponents/HobbyComponent';
import { useTranslation } from 'react-i18next';


export default function HobbiesInterests(props) {

  const { t:trans } = useTranslation();

  return (
    <div>
      
      {/* Hobbies And Interests */}
     <div className=" md:block p-4 md:p-0">
       <div className="mx-auto mt-2 md:mt-8 lg:mt-8">
         <div className="mx-auto bg-white">
           <div className="border-[#EAE6DF]">
             <ProfileAccordianComponent
               title={trans('my_profile.hobbies__interests')}
               titleClassName="bg-[#DADADAE0] lg:py-[0.7rem] flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] tracking-wider lg:text-[16px]" 
               defaultView={true}
               titleIcon1={
                 <img
                   src={AccrodianSelectImage}
                   className=""
                   alt=""
                 />
               }
               titleIcon2={
                 <img
                   src={PlusIcon}
                   className=""
                   alt=""
                 />
               }
               Callback={() => {}}
             >
               <div className="mb-4">
                 <div className="">
                  <HobbyComponent
                    data={props?.data?.hobbiesAndInterest} 
                    formik={props?.formik}
                    keyValue={"hobbiesInterest"}
                  />
                 </div>
               </div>
             </ProfileAccordianComponent>
           </div>
         </div>
       </div>
     </div> 
      {/* Music Genres You Listen to */}
     <div className=" md:block p-4 md:p-0">
       <div className="mx-auto mt-2 md:mt-8 lg:mt-8">
         <div className="mx-auto bg-white">
           <div className="border-[#EAE6DF]">
             <ProfileAccordianComponent
               title={trans('my_profile.music_genres_you_listen_to')}
               titleClassName="bg-[#DADADAE0] lg:py-[0.7rem] flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] tracking-wider lg:text-[16px]" 
               titleIcon1={
                 <img
                   src={AccrodianSelectImage}
                   className=""
                   alt=""
                 />
               }
               titleIcon2={
                 <img
                   src={PlusIcon}
                   className=""
                   alt=""
                 />
               }
               Callback={() => {}}
             >
               <div className="mb-4">
                 <div className="">
                  <HobbyComponent
                    data={props?.data?.musicListen} 
                    formik={props?.formik}
                    keyValue={"musicListen"}
                  />
                 </div>
               </div>
             </ProfileAccordianComponent>
           </div>
         </div>
       </div>
     </div> 
     {/* Favorite Music Directors */}
     <div className=" md:block p-4 md:p-0">
       <div className="mx-auto mt-2 md:mt-8 lg:mt-8">
         <div className="mx-auto bg-white">
           <div className="border-[#EAE6DF]">
             <ProfileAccordianComponent
               title={trans('my_profile.favorite_music_directors')}
               titleClassName="bg-[#DADADAE0] lg:py-[0.7rem] flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] tracking-wider lg:text-[16px]" 
               titleIcon1={
                 <img
                   src={AccrodianSelectImage}
                   className=""
                   alt=""
                 />
               }
               titleIcon2={
                 <img
                   src={PlusIcon}
                   className=""
                   alt=""
                 />
               }
               Callback={() => {}}
             >
               <div className="mb-4">
                 <div className="">
                  <HobbyComponent
                    data={props?.data?.favoriteMusicDirectors} 
                    formik={props?.formik}
                    keyValue={"musicDirectors"}
                  />
                 </div>
               </div>
             </ProfileAccordianComponent>
           </div>
         </div>
       </div>
     </div> 
     {/* READING */}
     <div className=" md:block p-4 md:p-0">
       <div className="mx-auto mt-2 md:mt-8 lg:mt-8">
         <div className="mx-auto bg-white">
           <div className="border-[#EAE6DF]">
             <ProfileAccordianComponent
               title={trans('my_profile.books_you_read')}
               titleClassName="bg-[#DADADAE0] lg:py-[0.7rem] flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] tracking-wider lg:text-[16px]" 
               titleIcon1={
                 <img
                   src={AccrodianSelectImage}
                   className=""
                   alt=""
                 />
               }
               titleIcon2={
                 <img
                   src={PlusIcon}
                   className=""
                   alt=""
                 />
               }
               Callback={() => {}}
             >
               <div className="mb-4">
                 <div className="">
                  <HobbyComponent
                    data={props?.data?.TypeofBooksYouRead} 
                    formik={props?.formik}
                    keyValue={"booksRead"}
                  />
                 </div>
               </div>
             </ProfileAccordianComponent>
           </div>
         </div>
       </div>
     </div> 
     {/* MOVIES YOU WATCH */}
     <div className=" md:block p-4 md:p-0">
       <div className="mx-auto mt-2 md:mt-8 lg:mt-8">
         <div className="mx-auto bg-white">
           <div className="border-[#EAE6DF]">
             <ProfileAccordianComponent
               title={trans('my_profile.movies_you_watch')}
               titleClassName="bg-[#DADADAE0] lg:py-[0.7rem] flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] tracking-wider lg:text-[16px]" 
               titleIcon1={
                 <img
                   src={AccrodianSelectImage}
                   className=""
                   alt=""
                 />
               }
               titleIcon2={
                 <img
                   src={PlusIcon}
                   className=""
                   alt=""
                 />
               }
               Callback={() => {}}
             >
               <div className="mb-4">
                 <div className="">
                  <HobbyComponent
                    data={props?.data?.TypeOfMoviesYouWatch} 
                    formik={props?.formik}
                    keyValue={"moviesWatch"}
                  />
                 </div>
               </div>
             </ProfileAccordianComponent>
           </div>
         </div>
       </div>
     </div>
     {/* Favorite Movie Directors */}
     <div className=" md:block p-4 md:p-0">
       <div className="mx-auto mt-2 md:mt-8 lg:mt-8">
         <div className="mx-auto bg-white">
           <div className="border-[#EAE6DF]">
             <ProfileAccordianComponent
               title={trans('my_profile.favorite_movie_directors')}
               titleClassName="bg-[#DADADAE0] lg:py-[0.7rem] flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] tracking-wider lg:text-[16px]" 
               titleIcon1={
                 <img
                   src={AccrodianSelectImage}
                   className=""
                   alt=""
                 />
               }
               titleIcon2={
                 <img
                   src={PlusIcon}
                   className=""
                   alt=""
                 />
               }
               Callback={() => {}}
             >
               <div className="mb-4">
                 <div className="">
                  <HobbyComponent
                    data={props?.data?.FavoriteMovieDirectors} 
                    formik={props?.formik}
                    keyValue={"movieDirectors"}
                  />
                 </div>
               </div>
             </ProfileAccordianComponent>
           </div>
         </div>
       </div>
     </div>
     {/* SPORTS AND FITNESS */}
     <div className=" md:block p-4 md:p-0">
       <div className="mx-auto mt-2 md:mt-8 lg:mt-8">
         <div className="mx-auto bg-white">
           <div className="border-[#EAE6DF]">
             <ProfileAccordianComponent
               title={trans('my_profile.sports__fitness_activities__ga')}
               titleClassName="bg-[#DADADAE0] lg:py-[0.7rem] flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] tracking-wider lg:text-[16px]" 
               titleIcon1={
                 <img
                   src={AccrodianSelectImage}
                   className=""
                   alt=""
                 />
               }
               titleIcon2={
                 <img
                   src={PlusIcon}
                   className=""
                   alt=""
                 />
               }
               Callback={() => {}}
             >
               <div className="mb-4">
                 <div className="">
                  <HobbyComponent
                    data={props?.data?.SportsAndFitnessActivitiesAndGames} 
                    formik={props?.formik}
                    keyValue={"sportsFitnessActivitiesGames"}
                  />
                 </div>
               </div>
             </ProfileAccordianComponent>
           </div>
         </div>
       </div>
     </div>
     {/* FOOD */}
     <div className=" md:block p-4 md:p-0">
       <div className="mx-auto mt-2 md:mt-8 lg:mt-8">
         <div className="mx-auto bg-white">
           <div className="border-[#EAE6DF]">
             <ProfileAccordianComponent
               title={trans('my_profile.favorite_cuisine')}
               titleClassName="bg-[#DADADAE0] lg:py-[0.7rem] flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] tracking-wider lg:text-[16px]" 
               titleIcon1={
                 <img
                   src={AccrodianSelectImage}
                   className=""
                   alt=""
                 />
               }
               titleIcon2={
                 <img
                   src={PlusIcon}
                   className=""
                   alt=""
                 />
               }
               Callback={() => {}}
             >
               <div className="mb-4">
                 <div className="">
                  <HobbyComponent
                    data={props?.data?.FavouriteCuisine} 
                    formik={props?.formik}
                    keyValue={"favouriteCuisine"}
                  />
                 </div>
               </div>
             </ProfileAccordianComponent>
           </div>
         </div>
       </div>
     </div>
     {/* LANGUAGES YOU KNOW */}
     {props?.data?.languagesYouKnow && 
     <div className=" md:block p-4 md:p-0">
       <div className="mx-auto mt-2 md:mt-8 lg:mt-8">
         <div className="mx-auto bg-white">
           <div className="border-[#EAE6DF]">
             <ProfileAccordianComponent
               title={trans('my_profile.languages_you_know').toUpperCase()}
               titleClassName="bg-[#DADADAE0] lg:py-[0.7rem] flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] tracking-wider lg:text-[16px]" 
               titleIcon1={
                 <img
                   src={AccrodianSelectImage}
                   className=""
                   alt=""
                 />
               }
               titleIcon2={
                 <img
                   src={PlusIcon}
                   className=""
                   alt=""
                 />
               }
               Callback={() => {}}
             >
               <div className="mb-4">
                 <div className="">
                  <HobbyComponent
                    data={props?.data?.languagesYouKnow} 
                    formik={props?.formik}
                    keyValue={"languagesKnow"}
                  />
                 </div>
               </div>
             </ProfileAccordianComponent>
           </div>
         </div>
       </div>
     </div>
      }
     {/* LANGUAGES THAT YOU WISH TO KNOW */}
     {props?.data?.languagesThatIwishToKnow &&
     <div className=" md:block p-4 md:p-0">
       <div className="mx-auto mt-2 md:mt-8 lg:mt-8">
         <div className="mx-auto bg-white">
           <div className="border-[#EAE6DF]">
             <ProfileAccordianComponent
               title={trans('my_profile.languages_that_you_wish_to_kno').toUpperCase()}
               titleClassName="bg-[#DADADAE0] lg:py-[0.7rem] flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] tracking-wider lg:text-[16px]" 
               titleIcon1={
                 <img
                   src={AccrodianSelectImage}
                   className=""
                   alt=""
                 />
               }
               titleIcon2={
                 <img
                   src={PlusIcon}
                   className=""
                   alt=""
                 />
               }
               Callback={() => {}}
             >
               <div className="mb-4">
                 <div className="">
                  <HobbyComponent
                    data={props?.data?.languagesThatIwishToKnow} 
                    formik={props?.formik}
                    keyValue={"languagesWishToKnow"}
                  />
                 </div>
               </div>
             </ProfileAccordianComponent>
           </div>
         </div>
       </div>
     </div>
     }
 </div>
  )
}
