import { useSelector } from 'react-redux';

function ActionRestrict({ children, remove = true, canEdit = null }) {
    const userData = useSelector(
        (state) => state?.DashBoardAPI?.ProfileData?.data,
    );

    const restrictAction = canEdit != null ? canEdit : userData?.editfromadmin == "Y";
    // const restrictAction = true;
    return (
        restrictAction
            ? remove ? null : <div onClick={e => {
                e.stopPropagation();
                e.preventDefault()
            }}
                className='opacity-30 cursor-not-allowed pointer-events-none'>{children}</div>
            : children
    )
}

export default ActionRestrict