
import React from "react";
import Loader from "./Loader";
const RedButton = (props) => {
    return (
      <button className={`text-[12px] text-[#FFFFFF] ${props.loading ? "bg-white" : "bg-[#D10A11]"} p-1  px-3 rounded-[5px]`} onClick={()=>{props.onClick()}}>
        {props.loading ? <Loader className="!inline bg-white text-white"/> : props.btnName}
      </button>
    );
  };
  export {RedButton}