import React from 'react'
import { Navigate } from 'react-router-dom';

function ConditionalRoute({ children, allow = true, to = "/dashboard", conditionFor }) {
    if (!allow) {
        if(conditionFor == "afterRegPhotoPopup"){
            sessionStorage.setItem("showUploadPhotoPopup","true")
        }
        return <Navigate replace={true} to={to} />
    }
    return children;
}

export default ConditionalRoute;
