import React, { useEffect, useRef, useState } from 'react'

//constant component
import { LANGUAGE_LIST, CASTE_LIST, CITY_LIST, CASTE_CITY_LIST, OTHERS_LIST } from './Constant'

//Route segment
import RouteSegments from '../../Routes/RouteSegment'

export default function MoreLink() {
    const [showCount, setShowCount]                     = useState(30)
    const [isShowMoreCaste, setIsShowMoreCaste]         = useState(false)
    const [isShowMoreCity, setIsShowMoreCity]           = useState(false)
    const [isShowMoreCasteCity, setIsShowMoreCasteCity] = useState(false)
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
      setClicked(true); 
      localStorage.setItem("isMatrimonyRegPopup","")
    };
   

  return (
    <div className='font-segeo  '>
      <div className=" px-[1.25rem] md:px-[3.25rem] py-[2.25rem] md:border-t-[2px] border-[#00000029]  ">
            {/* <!-- by language --> */}
            <p className=" lg:pt-[2rem] text-[#D10A11] text-[14px] font-bold "> By Language </p>
             <div className=" md:grid md:grid-cols-6 lg:grid lg:grid-cols-9 mt-4 ">
                {LANGUAGE_LIST.slice(0, 30).map((language,i) => (
                    <div className="col-span-3">
                        <div className="">
                            <p className="text-[#F18181] text-[14px] " >
                                <a href={language.link} target="_blank" rel="noopener noreferrer" className='cursor-pointer' onClick={handleClick}>{language.name}</a>
                            </p>
                        </div>
                    </div>
                ))} 
            </div>
            {/* <!-- by caste --> */}
            <p className=" pt-[2rem] text-[#D10A11] text-[14px] font-bold "> By Caste </p>
             <div className=" md:grid md:grid-cols-6 lg:grid lg:grid-cols-9 mt-4 ">
                {CASTE_LIST.slice(0, 30).map((caste,i) => (
                    <div className="col-span-3">
                        <div className="">
                            <p className="text-[#F18181] text-[14px]" >
                                <a href={caste.link} target="_blank" rel="noopener noreferrer" className='cursor-pointer' onClick={handleClick}>{caste.name}</a>
                            </p>
                        </div>
                        {!isShowMoreCaste &&
                        i+1 == showCount &&
                            <p className=" text-[#D10A11] text-[14px] font-semibold pt-[1rem] cursor-pointer" onClick={()=>setIsShowMoreCaste(true)}> Show More ...</p>
                        }
                   
                    </div>
                ))} 
                {isShowMoreCaste && CASTE_LIST.slice(30, CASTE_LIST.length).map((caste,i) => (
                    <div className="col-span-3">
                        <div className="">
                        <p className="text-[#F18181] text-[14px]" >
                            <a href={caste.link} target="_blank" rel="noopener noreferrer" className='cursor-pointer' onClick={handleClick}>{caste.name}</a>
                        </p>
                        </div>
                    </div>
                ))}
            </div>
            {/* <!-- by city --> */}
            <p className=" pt-[2rem] text-[#D10A11] text-[14px] font-bold "> By City </p>
             <div className=" md:grid md:grid-cols-6 lg:grid lg:grid-cols-9 mt-4 "> 
                {CITY_LIST.slice(0, 30).map((city,i) => (
                    <div className="col-span-3">
                        <div className="">
                            <p className="text-[#F18181] text-[14px]" >
                                <a href={city.link} target="_blank" rel="noopener noreferrer" className='cursor-pointer' onClick={handleClick}>{city.name}</a>
                            </p>
                        </div>
                        {!isShowMoreCity &&
                        i+1 == showCount &&
                            <p className=" text-[#D10A11] text-[14px] font-semibold pt-[1rem] cursor-pointer" onClick={()=>setIsShowMoreCity(true)}> Show More ...</p>
                        }
                   
                    </div>
                ))} 
                {isShowMoreCity && CITY_LIST.slice(30, CITY_LIST.length).map((city,i) => (
                    <div className="col-span-3">
                        <div className="">
                        <p className="text-[#F18181] text-[14px]" >
                            <a href={city.link} target="_blank" rel="noopener noreferrer" className='cursor-pointer' onClick={handleClick}>{city.name}</a>
                        </p>
                        </div>
                    </div>
                ))}
            </div>
            {/* <!-- by caste and city --> */}
            <p className=" pt-[2rem] text-[#D10A11] text-[14px] font-bold "> By Caste & City </p>
             <div className=" md:grid md:grid-cols-6 lg:grid lg:grid-cols-9 mt-4 ">
                {CASTE_CITY_LIST.slice(0, 30).map((casteCity,i) => (
                    <div className="col-span-3">
                        <div className="">
                            <p className="text-[#F18181] text-[14px]" >
                                <a href={casteCity.link} target="_blank" rel="noopener noreferrer" className='cursor-pointer' onClick={handleClick}>{casteCity.name}</a>
                            </p>
                        </div>
                        {!isShowMoreCasteCity &&
                        i+1 == showCount &&
                            <p className=" text-[#D10A11] text-[14px] font-semibold pt-[1rem] cursor-pointer" onClick={()=>setIsShowMoreCasteCity(true)}> Show More ...</p>
                        }
                   
                    </div>
                ))} 
                {isShowMoreCasteCity && CASTE_CITY_LIST.slice(30, CASTE_CITY_LIST.length).map((casteCity,i) => (
                    <div className="col-span-3">
                        <div className="">
                        <p className="text-[#F18181] text-[14px]" >
                            <a href={casteCity.link} target="_blank" rel="noopener noreferrer" className='cursor-pointer' onClick={handleClick}>{casteCity.name}</a>
                        </p>
                        </div>
                    </div>
                ))}
            </div>
            {/* <!-- Others  --> */}
            <p className=" pt-[2rem] text-[#D10A11] text-[14px] font-bold "> Others </p>
             <div className=" md:grid md:grid-cols-6 lg:grid lg:grid-cols-9 mt-4 ">
                {OTHERS_LIST.slice(0, 30).map((other,i) => (
                    <div className="col-span-3">
                        <div className="">
                            <p className="text-[#F18181] text-[14px]" >
                                <a href={other.link} target="_blank" rel="noopener noreferrer" className='cursor-pointer' onClick={handleClick}>{other.name}</a>
                            </p>
                        </div>
                   
                    </div>
                ))} 
            </div>
        </div>
    </div>
  )
}
