import { BOOK_APPOINTMENT, BOOK_APPOINTMENT_API_ERROR, BOOK_APPOINTMENT_SUCCESS, 
  GET_STATE_LIST, GET_STATE_LIST_API_ERROR, GET_STATE_LIST_SUCCESS, 
  GET_STORE_LIST, GET_STORE_LIST_API_ERROR, GET_STORE_LIST_SUCCESS } from "./ActionTypes";

  //get state list
  export const getStateList = (id,email) => {
    return {
      type: GET_STATE_LIST,
      payload: {id,email}
    };
  };

  export const getStateListSuccess = data => {
    return {
      type: GET_STATE_LIST_SUCCESS,
      payload: data,
    };
  };
  
  export const getStateListApiError = error => {
    return {
      type: GET_STATE_LIST_API_ERROR,
      payload: error,
    };
  };

  //get store list
  export const getStoreList = (stateId,id,email) => {
    return {
      type: GET_STORE_LIST,
      payload: {stateId,id,email}
    };
  };

  export const getStoreListSuccess = data => {
    return {
      type: GET_STORE_LIST_SUCCESS,
      payload: data,
    };
  };
  
  export const getStoreListApiError = error => {
    return {
      type: GET_STORE_LIST_API_ERROR,
      payload: error,
    };
  };

  //book appointment
  export const submitBookAppointment = (result,type, values, id, email) => {
    return {
      type: BOOK_APPOINTMENT,
      payload: {result,type,values, id, email}
    };
  };

  export const submitBookAppointmentSuccess = data => {
    return {
      type: BOOK_APPOINTMENT_SUCCESS,
      payload: data,
    };
  };
  
  export const submitBookAppointmentApiError = error => {
    return {
      type: BOOK_APPOINTMENT_API_ERROR,
      payload: error,
    };
  };


  