import React from 'react'
import { createPortal } from 'react-dom'
import * as Images from "../../../Components/Kalyanlite/assets/images"
import { useAnalytics } from '../../../Hooks/usePageChange';
import Constants, { EventName } from '../../../Constants/constants';
import axios from 'axios';
import config from '../../../config';
import EndPoints from '../../../Constants/EndPoints';

function MobileSiteLoginPopup({ onClose }) {
    const { onClickTrack } = useAnalytics()


    const onContinue = () => {
        onClickTrack(EventName.Home_CLC_Redirect_Continue_with_Mobile_site_Button)
        onClose()
    }

    const onOpen = async () => {
        onClickTrack(EventName.Home_CLC_Redirect_Open_with_Android_app_Button)
        const loginFromUrl = sessionStorage.getItem(Constants.loginLocalStorageKeys.loginFromUrl)
        sessionStorage.removeItem(Constants.loginLocalStorageKeys.loginFromUrl)
        const res = await axios.post(`${config.api.API_URL}${EndPoints.clcnotificationurl()}`, {
            longurl: loginFromUrl
        })
        if (res.data?.statusCode == 200) {
            window.open(res?.data?.data)
        }
        onClose()
    }

    return (
        createPortal(<div className='flex justify-center bg-opacityblack items-end md:items-center lg:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-[100%] lg:w-[35%] xl:w-[33%] md:my-6 md:mx-6 md:flex md:justify-center md:items-center'>
                <div className="rounded-tr-[35px] rounded-tl-[35px] shadow-lg relative lg:mt-[0.5rem] lg:mr-[0.5rem] flex flex-col md:w-[70%] lg:w-full bg-white outline-none focus:outline-none md:rounded-[22px] p-4">
                    <p className='text-[20px] font-semibold font-poppins py-4'>Continue with:</p>
                    <div className='flex justify-between items-center'>
                        <div className='flex items-center mr-2'>
                            <img alt='mobile app logo' className='h-16 w-16' src={Images.KalyanMobileAppLogo} />
                            <p className='text-[18px] font-medium font-poppins'>Kalyan matrimony app</p>
                        </div>
                        <button onClick={onOpen} className='bg-[#D71920] rounded-md px-[28px] py-2 text-[14px] text-white'>Open</button>
                    </div>
                    <div className='flex justify-between items-center mt-4'>
                        <div className='flex items-center mr-2'>
                            <img alt='mobile app logo' className='h-[50px] w-16' src={Images.Web} />
                            <p className='text-[18px] font-medium font-poppins'>Browser</p>
                        </div>
                        <button onClick={onContinue} className='bg-[#D9D9D9] rounded-md px-4 py-2 text-[14px] '>Continue</button>
                    </div>
                </div>
            </div>
        </div>,
            document.body,
        )
    )
}

export default MobileSiteLoginPopup