import { all, fork } from "redux-saga/effects";

 import homeSaga from './Home/Saga'
 import loginSaga from "./Login/Saga";
 import registerSaga from "./Register/Saga";
 import paymentSaga from "./Payment/Saga";
 import contactSaga from "./Contact/Saga";
 import conversationSaga from "./Conversation/Saga";
 import filterSectionSaga from "./FilterSection/Saga";
 import bookAppointmentSaga from "./BookAppointment/Saga";
 import searchByIdSaga from "./SearchById/Saga";
 import exploreProfile from "./ExploreProfile/Saga";
 import bannerRedirectionSaga from "./BannerRedirection/Saga";
 import searchPageCheckSaga from "./SearchPageCheck/Saga";
 import contentCardSaga from "./ContentCard/Saga";
 import storeRegistrationSaga from "./StoreRegistration/Saga";
 import DashBoardAPISaga from "./DashboardAPI/Saga";
import kalyanLiteSaga from "./KalyanLite/saga";
import ChatsSaga from "./Chats/Saga";
import KalyanRewardsSaga from "./KalyanRewards/Saga";

export default function* rootSaga() {
  yield all([ 
    fork(homeSaga),
    fork(loginSaga),
    fork(registerSaga),
    fork(paymentSaga),
    fork(contactSaga),
    fork(conversationSaga),
    fork(filterSectionSaga),
    fork(bookAppointmentSaga),
    fork(searchByIdSaga),
    fork(exploreProfile),
    fork(bannerRedirectionSaga),
    fork(searchPageCheckSaga),
    fork(contentCardSaga),
    fork(storeRegistrationSaga),
    fork(DashBoardAPISaga),
    fork(kalyanLiteSaga),
    fork(ChatsSaga),
    fork(KalyanRewardsSaga)
  ]);
}
