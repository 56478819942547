import React from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { RoutePaths } from '../Routes/RouteSegment';
import Constants from '../Constants/constants';

function Redirect() {

    const [searchParams] = useSearchParams()
    const page = searchParams.get("page")

    if (page) {
        switch (page) {
            case RoutePaths.PROFILE:
                const pageUrl = searchParams.get("pageUrl")
                const id = window[Constants.loginLocalStorageKeys.currentProfileId]
                window[Constants.loginLocalStorageKeys.currentProfileId] = undefined
                return <Navigate to={pageUrl} replace state={{ id, newTab: true, notificationId: searchParams.get('notificationId') }} />
            default:
                return <Navigate to="/" replace />
        }
    }
    return <Navigate to="/" replace />
}

export default Redirect