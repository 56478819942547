import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nBackend from "i18next-http-backend";
import en from "../src/locales/en.json"
import ta from "../src/locales/ta.json"
import config from './config';



i18n
.use(i18nBackend)
.use(initReactI18next).init({
  resources: localStorage.getItem('islocal') ?{
    en: { translation: en },
    ta: { translation: ta },
  //   hi: { translation: hi },
  }:undefined,
  backend: localStorage.getItem('islocal') ? undefined: {
    loadPath: `${config.s3Url}/translations/{{lng}}.json`,
  },
  // backend: {
  //   loadPath: `https://kmuat.s3.us-west-2.amazonaws.com/translations/{{lng}}.json`,
  // },
  lng: localStorage.getItem('vernacularCode') || 'en', // default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React already escapes by default
  },

});

// export const languageOptions =[
  
//     {
//         value:"en",
//         label:"English"
//     },
//     {
//         value:"ta",
//         label:"தமிழ்"
//     },
// ]; 


  


export default i18n;