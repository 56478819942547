import {
    REGISTER_USER,
    REGISTER_SUCCESS,
    API_ERROR,
    REGISTER_EMAIL_CHECK,
    REGISTER_EMAIL_CHECK_SUCCESS,
    REGISTER_PHONE_CHECK,
    REGISTER_PHONE_CHECK_SUCCESS,
    REGISTER_EMAIL_CHECK_FAILURE,
    RESET_API_ERROR
  } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const register = (state = initialState, action) => {
    //console.log(action.type,"12345");
    switch (action.type) {
        case REGISTER_EMAIL_CHECK:
        state = {
          ...state,
          data:action.payload.data,
          loading: true,
        };
        break;
        case REGISTER_EMAIL_CHECK_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          FailureData:action.payload.data,
          loading: false,
        };
        break;
        case REGISTER_EMAIL_CHECK_FAILURE:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          FailureData:action.payload.data,
          loading: false,
        };
        break;
        case REGISTER_PHONE_CHECK:
          //console.log(action.payload.data,"statse");
        state = {
          ...state,
          data:action.payload.data,
          loading: true,
        };
        break;
        case REGISTER_PHONE_CHECK_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          FailureData:action.payload.data,
          loading: false,
        };
        break;
      case REGISTER_USER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case REGISTER_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          data:action.payload.data,
          loading: false,
        };
        break;
      case API_ERROR:
        //console.log( action.payload.data,"123456");
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;
        case RESET_API_ERROR:
          state = {
            ...state,
            error: "",
            loading: false,
          };
          break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default register;
  