// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDGnd9v03ef5WewGuBmLI5jz4rUMKHta10",
    authDomain: "kalyan-matrimony-5b2cf.firebaseapp.com",
    databaseURL: "https://kalyan-matrimony-5b2cf.firebaseio.com",
    projectId: "kalyan-matrimony-5b2cf",
    storageBucket: "kalyan-matrimony-5b2cf.appspot.com",
    messagingSenderId: "83766648236",
    appId: "1:83766648236:web:fc05edc77595877a9a2e09",
    measurementId: "G-EB8DJ011H2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Messaging service
export const messaging = getMessaging(app);