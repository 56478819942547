import React, { useEffect, useState } from 'react'
import { CommonPopup } from '../CommonPopup'
import * as Yup from 'yup';
import { BasiSelectView, SelectInput } from '../Edit Profile/EditProfileUtills'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { searchByCityRequest, searchByEducationRequest, searchByOccupationRequest } from '../../Store/ExploreProfile/Action'
import { MultiSelectView, MultiSelectViewHeading } from '../Edit Profile/PartnerPreference/PartnerPreferenceUtils';
import { registerCityList, registerStateList } from '../../Store/Home/Action';
import { useNavigate } from 'react-router-dom';
import RouteSegments, { RouteHelper } from '../../Routes/RouteSegment';
import educationIcon from '../../Images/Group 1781.svg'
import Constants from '../../Constants/constants';
import searchcityImage from '../../Images/Group 592.svg'
import searchOcupationImage from '../../Images/Group 594.svg'
import { useTranslation } from 'react-i18next';

const ExploreProfile = (props) => {
  const [searchByCityPopup, setSearchByCityPopup] = useState(false)
  const [searchByEducationPopup, setSearchByEducationPopup] = useState(false)
  const [searchByOccupationPopup, setSearchByOccupationPopup] = useState(false)

  const [countryDropDown, setCountryDropDown] = useState([]);
  const [stateDropDown, setStateDropDown] = useState([]);
  const [cityDropDown, setcityDropDown] = useState([]);
  const [educationDropDown, setEducationDropdown] = useState([]);
  const [occupationDropDown, setOccupationDropDown] = useState([]);
  const [isLoading, setIsloading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { t: trans } = useTranslation();

  const formik = useFormik({
    initialValues: {
      country: [],
      state: [],
      city: [],
      education: [],
      occupation: []
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({}),

    onSubmit: (values) => {
      //console.log(values,"valuessss");
      if(searchByCityPopup){
      setSearchByCityPopup(false)
      apiCallFilterByCity(values);
      }
      else if(searchByEducationPopup){
        setSearchByEducationPopup(false)
        apiCallFilterByEducation(values);
        }
      else if(searchByOccupationPopup){
        setSearchByOccupationPopup(false)
        apiCallFilterByOccupation(values);
      }
    },
  });

 
  const apiCallFilterByCity = (values) => {
    navigate(RouteHelper.getExploreUrl, { state: { isfrom:"explore", filterBy: "city",
       cityId: values.city, stateId: values.state, countryId: values.country, menu:"explorematches" } }
      )
  }

  const apiCallFilterByEducation = (values) => {
    navigate(RouteHelper.getExploreUrl, { state: { isfrom:"explore", filterBy: "education",
    educationId: values.education, menu:"explorematches" } }
      )
  }

  const apiCallFilterByOccupation = (values) => {
    navigate(RouteHelper.getExploreUrl, { state: { isfrom:"explore", filterBy: "occupation",
    occupationId: values.occupation, menu:"explorematches" } }
      )
  }

  useEffect(() => {}, [
    countryDropDown,
    stateDropDown,
    cityDropDown,
    formik.values,
  ]);

  


  const {countryData}  = useSelector(state => ({
    countryData: state.ExploreProfile?.searchCityResultData?.data
  }));

  //console.log(countryData,"countryDatacountryData");

  useEffect(()=>{
    let tempCountry = [];
    let tempState = [];
    let tempCity = [];
    countryData?.country?.map((countryy,i) =>{
      if(countryy.selected == "Y"){
        tempCountry.push(countryy.id)
        formik.setFieldValue("country",tempCountry);
      }
    })
    countryData?.state?.map((state,i) =>{
      if(state.selected == "Y"){
        tempState.push(state.id)
        formik.setFieldValue("state",tempState);
      }
    })
    countryData?.city?.map((city,i) =>{
      if(city.selected == "Y"){
        tempCity.push(city.id)
        formik.setFieldValue("city",tempCity);
      }
    })
  },[countryData])


  useEffect(() => {
    setCountryDropDown(countryData?.country)
    setStateDropDown(countryData?.state)
    setcityDropDown(countryData?.city)
  },[countryData])

  // useEffect(() => {
  //   dispatch(searchByCityRequest("city")) 
  // },[])

  const apiCallState = (country) => {
    dispatch(registerStateList(country))
  }
  const {educationData}  = useSelector(state => ({
    educationData: state.ExploreProfile?.searchEducationResultData?.data
  }));

  //console.log(educationData,"educationDataeducationData");

  useEffect(()=>{
    let tempEducation = [];
    educationData?.education?.map((education,i) =>{
      if(education.selected == "Y"){
        tempEducation.push(education.id)
        formik.setFieldValue("education",tempEducation);
      }
    })
  },[educationData])


  useEffect(() => {
    setEducationDropdown(educationData?.education)
  },[educationData])

  const {occupationData}  = useSelector(state => ({
    occupationData: state.ExploreProfile?.searchOccupationResultData?.data
  }));

  //console.log(occupationData,"occupationDataoccupationData");

  useEffect(()=>{
    let tempOccupation = [];
    occupationData?.occupation?.map((occupation,i) =>{
      if(occupation.selected == "Y"){
        tempOccupation.push(occupation.id)
        formik.setFieldValue("occupation",tempOccupation);
      }
    })
  },[occupationData])


  useEffect(() => {
    setOccupationDropDown(occupationData?.occupation)
  },[occupationData])

  console.log(localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail),'logged email');

  useEffect(() => {
    if(localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) || !localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail)){
      setIsloading(true)
      if(searchByCityPopup){

      dispatch(searchByCityRequest("city"))
    }
    if(searchByEducationPopup){
    dispatch(searchByEducationRequest("education"))
    }
    if(searchByOccupationPopup){
    dispatch(searchByOccupationRequest("occupation"))
    }
    }
  },[localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail),searchByCityPopup,searchByEducationPopup,searchByOccupationPopup])

  const {stateData}  = useSelector(state => ({
    stateData: state.Home?.stateList?.data?.data?.states
  }));

  //console.log(stateData,"countryDatacountryData");


  useEffect(() => {
    setStateDropDown(stateData)
    const temp = [];
    stateData?.forEach(state => {
          if(formik?.values?.state?.includes(state.id)){
            temp.push(state.id)
          }
        });
        formik.setFieldValue("state", temp);
        if(searchByCityPopup){
        apiCallCity(temp);
      }
  },[stateData])

  const apiCallCity = (state) => {
    dispatch(registerCityList(state))
  }


  const {cityData}  = useSelector(state => ({
    cityData: state.Home?.cityList?.data?.data?.cities,
  }));



  useEffect(() => {
    setcityDropDown(cityData)
    const temp = [];
    cityData?.forEach(city => {
      if(formik?.values?.city?.includes(city.id)){
        temp.push(city.id)
      }
    });
    formik.setFieldValue("city", temp);

  },[cityData])

  useEffect(()=>{
    if(cityData || stateData || countryData || educationData || occupationData){
      setIsloading(false)
    }

  },[cityData, stateData, countryData ,educationData ,occupationData])

  return (
    <>
    {searchByCityPopup &&
    <CommonPopup title="Search By City" close={()=>setSearchByCityPopup(false)} menu="" isLoading={isLoading}>
      <form onSubmit={formik.handleSubmit}>
      {/* <p className='text-center'>Select Country</p> */}
      <MultiSelectView
        required={true}
        title={trans('view_profile.country')}
        placeholder={trans('set_your_partner_expectations.any_country')}
        inputID={"country"}
        inputName={"country"}
        value={formik.values.country}
        defalutValue={[formik.values.country==""?null:formik.values.country]}
        onChange={(e) => {
          //console.log(Array.from(e), "Array.from(e)");
          formik.setFieldValue("country", Array.from(e));
          formik.setFieldValue("state", []);
          formik.setFieldValue("city", []);

          apiCallState(Array.from(e),false);
        }}        
        error={formik.values.country}
        options={countryDropDown}
      />
      {formik?.errors?.country  && formik?.touched?.country  &&(
            <p className="text-[red] text-[12px]">{formik?.errors?.country}</p>
          )}

      {/* <p className='text-center'>Select State</p> */}
      <MultiSelectView
        required={true}
        title={"State"}
        placeholder={trans('set_your_partner_expectations.any_state')}
        inputID={"state"}
        inputName={"state"}
        value={formik.values.state}
        defalutValue={[formik.values.state==""?null:formik.values.state]}
        onChange={(e) => {
          formik.setFieldValue("state", Array.from(e));
          apiCallCity(Array.from(e),false);
        }}        
        error={formik.values.state}
        options={stateDropDown}
      />
      {formik?.errors?.state  && formik?.touched?.state  &&(
            <p className="text-[red] text-[12px]">{formik?.errors?.state}</p>
          )}

      {/* <p className='text-center'>Select CIty</p> */}
      <MultiSelectView
        required={true}
        title={trans('set_your_partner_expectations.city')}
        placeholder={trans('set_your_partner_expectations.any_city')}
        inputID={"city"}
        inputName={"city"}
        value={formik.values.city}
        defalutValue={[formik.values.city==""?null:formik.values.city]}
        onChange={(e) => {
          formik.setFieldValue("city", Array.from(e));
        }}        
        error={formik.values.city}
        options={cityDropDown}
      />
      {formik?.errors?.city  && formik?.touched?.city  &&(
            <p className="text-[red] text-[12px]">{formik?.errors?.city}</p>
          )}
      <div className="justify-center space-x-4 flex pt-[2rem]">
        <div className='  border border-[#9F9F9F] rounded-[5px]'><button className=' text-[#7A7A7A] text-[13px] px-[3rem] py-[0.3rem]' onClick={()=>setSearchByCityPopup(false)}>Cancel</button></div>
        <div className=''>{formik.values.country?.length>0 && formik.values.state?.length>0 && formik.values.city?.length>0 && 
        <button type="submit" className='bg-[#D10A11]  rounded-[5px] text-white  text-[13px] px-[3rem] py-[0.3rem]'>Apply</button>}</div>
      </div>
      </form>
            
      
    </CommonPopup>
    }

{searchByEducationPopup &&
      <CommonPopup title="Search By Education" close={()=>setSearchByEducationPopup(false)} menu="" isLoading={isLoading}>
        <form onSubmit={formik.handleSubmit}>
        {/* <p className='text-center'>Select Country</p> */}
        <MultiSelectViewHeading
          required={true}
          title={trans('view_profile.education')}
          placeholder={trans('set_your_partner_expectations.any_education')}
          inputID={"education"}
          inputName={"education"}
          value={formik.values.education}
          defalutValue={[formik.values.education==""?null:formik.values.education]}
          onChange={(e) => {
            //console.log(Array.from(e), "Array.from(e)");
            formik.setFieldValue("education", Array.from(e));
          }}        
          error={formik.values.education}
          options={educationDropDown}
        />
  
      
        <div className="justify-center space-x-4 flex pt-[2rem]">
          <div className='  border border-[#9F9F9F] rounded-[5px]'><button className=' text-[#7A7A7A] text-[13px] px-[3rem] py-[0.3rem]' onClick={()=>setSearchByEducationPopup(false)}>Cancel</button></div>
          <div>{formik.values.education?.length>0 &&
          <button type="submit" className='bg-[#D10A11]  rounded-[5px] text-white  text-[13px] px-[3rem] py-[0.4rem]'>Apply</button>}</div>
        </div>
        </form>
              
        
      </CommonPopup>
      }
    {searchByOccupationPopup &&
      <CommonPopup title="Search By Occupation" close={()=>setSearchByOccupationPopup(false)} menu="" isLoading={isLoading}>
        <form onSubmit={formik.handleSubmit}>
        {/* <p className='text-center'>Select Country</p> */}
        <MultiSelectViewHeading
          required={true}
          title={trans('view_profile.occupation')}
          placeholder={trans('set_your_partner_expectations.any_occupation')}
          inputID={"occupation"}
          inputName={"occupation"}
          value={formik.values.occupation}
          defalutValue={[formik.values.occupation==""?null:formik.values.occupation]}
          onChange={(e) => {
            //console.log(Array.from(e), "Array.from(e)");
            formik.setFieldValue("occupation", Array.from(e));
          }}        
          error={formik.values.occupation}
          options={occupationDropDown}
        />
  
      
        <div className="justify-center space-x-4 flex pt-[2rem]">
          <div className='  border border-[#9F9F9F] rounded-[5px]'><button className=' text-[#7A7A7A] text-[13px] px-[3rem] py-[0.3rem]' onClick={()=>setSearchByOccupationPopup(false)}>Cancel</button></div>
          <div>{formik.values.occupation?.length>0 &&
          <button type="submit" className='bg-[#D10A11]  rounded-[5px] text-white  text-[13px] px-[3rem] py-[0.4rem]'>Apply</button>}</div>
        </div>
        </form>
              
        
      </CommonPopup>
      }
    <div className="section md:border-[1.5px] md:border-gray-300 mb-4 rounded-[10px] p-3 font-segeo ">
                        <div className="flex justify-between mx-2">
                          <p className="text-[13pt] md:text-[17px] lg:pl-[1.1rem]  lg:text-[19px] text-[#575556] font-segeo font-semibold">
                            Explore Different Profiles
                          </p>
                        </div>
                        <div className="flex justify-evenly py-7 items-center mx-0 md:mx-0 lg:mx-7 space-x-2">
                          {/* <div className="flex flex-col items-center">
                            <img
                              className="w-[33%] h-[50%] md:w-[30%] md:h-[30%] lg:w-[50%] lg:h-[50%]"
                              src="/Assets/Images/Group 592.svg"
                              alt=""
                            />
                            <span className="text-[#575556] py-1 font-bold text-[12px] md:pl-[1.5rem] whitespace-nowrap">
                              Search Near by Location
                            </span>
                          </div>
                          <div className="flex flex-col items-center md:mb-[2px] lg:-mb-[1.1rem]">
                            <img
                              className="w-[77%] h-[50%] md:w-[52%] md:h-[25%] lg:w-[50%] lg:h-[50%]"
                              src="/Assets/Images/Group 593.svg"
                              alt=""
                            />
                            <span className="text-[#575556] pt-1 font-bold text-[12px] whitespace-nowrap">
                              Search By City
                            </span>
                          </div>
                          <div className="flex flex-col items-center lg:-mb-[1.1rem] md:mr-[2px]">
                            <img
                              className="w-[33%] h-[50%] md:w-[34%] md:h-[25%] lg:w-[50%] lg:h-[50%]"
                              src="/Assets/Images/Group 594.svg"
                              alt=""
                            />
                            <span className="text-[#575556] pt-1 text-[12px] font-bold md:pl-[1.5rem] whitespace-nowrap">
                              Search By Profession
                            </span>
                          </div> */}
                          <div  onClick={()=>setSearchByCityPopup(true)} className="flex flex-col items-center mb-3 md:mb-0 ">
                            <img
                              className="h-[50%] w-[56%] md:h-[50%] md:w-[63%] lg:h-[52%] lg:w-[52%] cursor-pointer"
                              src={searchcityImage}
                              alt=""
                            />
                            <span className="cursor-pointer text-[#575556] py-1 font-bold text-[12px] text-center  md:whitespace-nowrap" >
                              Search by City
                            </span>
                          </div>
                          <div onClick={()=>setSearchByEducationPopup(true)} className="flex flex-col items-center">
                            <img
                              className="cursor-pointer h-[4.8rem] w-[8rem] md:h-[5.2rem] md:w-[11rem] lg:h-[50%] lg:w-[50%]"
                              // src="/Assets/Images/Group 1781.svg"
                              src={educationIcon}
                              alt=""
                            />
                            <span className="cursor-pointer text-[#575556] pt-1 font-bold text-[12px] text-center  md:whitespace-nowrap" >
                              Search By Education
                            </span>
                          </div>
                          <div onClick={()=>setSearchByOccupationPopup(true)} className="flex flex-col items-center">
                            <img
                              className="cursor-pointer h-[4.8rem] w-[57%] md:h-[50%] md:w-[4.9rem] lg:h-[50%] lg:w-[50%]"
                              src={searchOcupationImage}
                              alt=""
                            />
                            <span className="cursor-pointer text-[#575556] pt-1 text-[12px] font-bold text-center md:whitespace-nowrap" >
                              Search By Profession
                            </span>
                          </div>
                        </div>
                      </div>
                      </>
  )
}

export default ExploreProfile
