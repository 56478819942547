import React, { useState, useEffect } from "react";
import { EducationCheckView } from "../../Components/Edit Profile/PartnerPreference/PartnerPreferenceUtils";
import { EduactionListTag } from "../../Components/Edit Profile/EditProfileUtills";

const OccupationComponent = (props) => {
    const [occupationdata, setOccupationData] = useState([]);
   /// education 
    const setInitialOcupation=()=>{
    setOccupationData([]);
    let tempOccupationData = props?.educationinfofreshData?.occupation;
    tempOccupationData?.map((e, i) => {
      if (e.heading === "Y") {
        let newsection = {
          title: e,
          options: [],
        };
        for (var index = i + 1; index < tempOccupationData.length; index++) {
          if (tempOccupationData[index].heading === "N") {
            newsection.options = [
              ...newsection.options,
              tempOccupationData[index],
              
            ];
          } else {
            break;
          }
        }

        let selected = e.selected;
        const len = newsection?.options?.filter((element) => element.selected === "Y")?.length ?? 0

        if (len === 0) {
          selected = "N"
        } else if (newsection?.options?.length !== len) {
          selected = "P"
        } else {
          selected = "Y"
        }

        newsection = {
          ...newsection,
          title: { ...newsection.title, selected }
        }

        setOccupationData((oldvalue) => [...oldvalue, newsection]);
      }
    });
  }
   const handleSingleSelectOccupation = (value) => {
    let newVal = occupationdata?.map((ele, i) => {
      return {
        ...ele,
        options: occupationdata[i].options.map((e, ind) => {
          if (e.id === value) {
            return { ...e, selected: e.selected === "Y" ? "N" : "Y" };
          } else {
            return e;
          }
        }),
      };
    });
    setOccupationData(newVal);

    let setAllValue = newVal?.map((ele, i) => {
      return {
        ...ele,
        title: {
          ...ele.title,
          selected:
            newVal[i].options.length > 0 &&
            newVal[i].options
            .map((element, index) => element.selected === "Y")
            .every((v) => v === true)
            ? "Y"
            : newVal[i].options
            .map((element, index) => element.selected === "N")
            .every((v) => v === true)
            ? "N"
            :"P",
        },
      };
    });

    setOccupationData(setAllValue);
    setSelectedOccupationData(setAllValue)
  };
  const handleSelectAllOccupation = (value) => {
    let newVal = occupationdata?.map((ele, i) => {
      if (ele.title.id === value) {
        return {
          ...ele,
          title: {
            ...ele.title,
            selected: ele.title.selected === "Y" ? "N" : "Y",
          },
          options: occupationdata[i].options.map((e, ind) => {
            return { ...e, selected: ele.title.selected === "Y" ? "N" : "Y" };
          }),
        };
      } else {
        return ele;
      }
    });

    setOccupationData(newVal);
    setSelectedOccupationData(newVal)
  };
  const setSelectedOccupationData=(value)=>{
    let selectedID= []
    {value?.map((ele, index) => {
      
          {ele.title.selected === "Y" && selectedID.push(ele.title.id)}
          {ele?.options.map(
            (e, i) =>
              e.selected === "Y" && selectedID.push(e.id)
          )}
        
          })}
          props?.setEditPreference({ ...props?.editPreference, ["occupationId"]: selectedID })
          props.data.setFieldValue('occupation',selectedID)
  }

   useEffect(() => {
    setOccupationData();
    setInitialOcupation();
  }, [props?.educationinfofreshData]);
  useEffect(() => {
   
  }, [occupationdata]);
    return(
        <>
         <div className=" md:flex  md:space-x-3 ">
                          <div className="flex items-center ">
                            <div className="border border-[#8F8F8F] w-full space-y-2 md:space-y-2 lg:space-y-3 h-[15rem] overflow-y-auto rounded-[4px] p-[1rem] md:p-[0.9rem] lg:p-2.5 ">
                              {occupationdata?.map((e, i) => (
              <EducationCheckView 
              setIsPPEdited={props?.setIsPPEdited}
              fromPath={props?.fromPath}
              setFilterOnchange={props?.setFilterOnchange}
              onChange={props?.setFilterOnchange}
              setEducationError={props?.setEducationError}
                key={i}
                title={e.title}
                value={e.options}
                occupationdata={occupationdata}
                callBackSelectAll={handleSelectAllOccupation}
                callBackSingleSelect={handleSingleSelectOccupation}
              />
            ))}
                            </div>
                          </div>
                          <div className=" border border-[#8F8F8F] w-full mt-[2rem] md:mt-0 md:w-[16rem] h-[15rem] overflow-y-auto rounded-[4px]">
                             <div className="flex flex-wrap m-2">
            {occupationdata?.map((ele, index) => (
              <>
                {ele?.options.map(
                  (e, i) =>
                    e.selected === "Y" && <EduactionListTag key={i} text={e.name} />
                )}
              </>
            ))}
          </div>
                          </div>
                        </div>
        </>
    )
}

export default OccupationComponent
