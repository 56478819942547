import React, { useState } from 'react'
import { BsArrowDownShort } from "react-icons/bs"
import { AiFillCloseCircle } from "react-icons/ai"
import { useEffect } from 'react'
import Constants from '../Constants/constants'
import RouteSegments from '../Routes/RouteSegment'
import { useTranslation } from 'react-i18next'

function DownloadAppBar({ dashboardData, haveBottom = false }) {
    const [isDownloadAppVisible, setIsDownloadAppVisible] = useState(false)
    const [timer, setTimer] = useState(null)

    const {t:trans}=useTranslation();
    const closeDownloadApp = () => {
        setIsDownloadAppVisible(false)
        localStorage.setItem(Constants.loginLocalStorageKeys.appDownloadPopupAlreadyShowed, "Yes")
        localStorage.setItem(Constants.loginLocalStorageKeys.showDownloadPopup, "No")
    }

    useEffect(() => {
        const isAlreacyClosed = localStorage.getItem(Constants.loginLocalStorageKeys.appDownloadPopupAlreadyShowed)
        const currentlyShowing = localStorage.getItem(Constants.loginLocalStorageKeys.showDownloadPopup)
        console.log("dashboardData-DownloadAppBar", dashboardData, isAlreacyClosed, currentlyShowing);
        if (dashboardData?.isAppDownload === "No" && isAlreacyClosed !== "Yes" && currentlyShowing !== "Yes") {
            if (timer == null) {
                const t = setTimeout(() => {
                    setIsDownloadAppVisible(true)
                    localStorage.setItem(Constants.loginLocalStorageKeys.showDownloadPopup, "Yes")
                }, Constants.appDownloadWaitTime);
                setTimer(t)
            }
        } else if (currentlyShowing === "Yes") {
            setIsDownloadAppVisible(true)
        }

        return () => {
            clearTimeout(timer)
        }
    }, [dashboardData, timer])


    if (isDownloadAppVisible) {
        return (
            <footer className={`md:hidden fixed h-24 bg-white border border-[#D10A11] inset-x-0 rounded-t-[30px] flex items-center justify-center flex-col text-center ${haveBottom ? "bottom-14" : "bottom-0"}`}>
                <AiFillCloseCircle onClick={closeDownloadApp} color='#D10A11' size={22} className='absolute left-0 top-0' />
                <p className='text-sm'>{trans('clcdownload_app.download_our_app_to_get_instan')}</p>
                <button onClick={() => {
                    window.open(RouteSegments.staticLinkForAppDownload.android)
                }} className='flex items-center text-sm justify-center my-2 font-bold px-2 py-1 text-white bg-black rounded'>
                    Install Now <BsArrowDownShort size={18} className='ml-1' />
                </button>
            </footer>

        )
    } else {
        return <></>
    }
}

export default DownloadAppBar