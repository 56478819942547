import { call, put, takeEvery } from "redux-saga/effects";
import {  SEARCH_BY_ID} from "./ActionTypes";
import { searchByIdRequestApiError, searchByIdRequestSuccess } from "./Action";
import { Search_By_Id } from "../../Helpers/api_helpers";
import Constants from '../../Constants/constants';


function* searchById({ payload: {values} }) {
  //console.log(values,"datadata");
  try {
    const response = yield call(Search_By_Id,{
      email : localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail),
      pageno : "1",
      toProfileId : values.kalyanid,
      userId : localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
  });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(searchByIdRequestSuccess(response));
    } else {
      yield put(searchByIdRequestSuccess(response));
    }
  } catch (error) {
    yield put(searchByIdRequestApiError(error));
  }
}




function* searchByIdSaga() {
  yield takeEvery(SEARCH_BY_ID, searchById);
}

export default searchByIdSaga;
