import { call, put, takeEvery } from "redux-saga/effects";

// Redux States
import { Callback_api, Coupen_Code, Dynamic_Pixel, Membership_details, Pay_Now ,Offline_Profile} from '../../Helpers/api_helpers'
import { CALLBACK_DETAIL, COUPEN_CODE, DYNAMIC_PIXEL, MEMBERSHIP_DETAIL, PAY_NOW ,OFFLINE_PROFILE} from './ActionTypes';
import { callBackDetailApiError, callBackDetailSuccess, coupenCodeApiError, coupenCodeSuccess, dynamicPixelApiError, dynamicPixelSuccess, getMemberShipDetailApiError, 
  getMemberShipDetailSuccess, payNowApiError, payNowSuccess ,getOfflineDetailSuccess,getOfflineDetailFail,offlineprofileSuccess} from "./Action";
import Constants from "../../Constants/constants";

function* membershipDetail({ payload: {id,email} }) {
  //console.log(id,email,"datadata");
  try {
    const response = yield call(Membership_details,{
      email:email ?? null,
      userId: id,
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice)
    });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(getMemberShipDetailSuccess(response));
    } else {
      yield put(getMemberShipDetailApiError(response));
    }
  } catch (error) {
    yield put(getMemberShipDetailApiError(error));
  }
}

// function* offlineprofile({ payload }) {
//   try {
//       let request = {
//         "user_id":"325"
//       }


//       const axiosConfig = {
//           headers: {
//               Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
//           },
//           data: {
//               ...request,
//           },
//       };

//       const response = yield call(Offline_Profile, axiosConfig);
//       console.log(response,"mmkkk");
//       if (response.data) {
//           yield put(offlineprofileSuccess(response.data));
//       } 
    
//   } catch (error) {
//   }
// }


function* callBackDetail({ payload: {values,id} }) {
  //console.log(values,id,"datadata");
  try {
    const response = yield call(Callback_api,{
      mobileno:values.mobileNo,
      userId:id,
      mothertongue:values.language
    });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(callBackDetailSuccess(response));
    } else {
      yield put(callBackDetailApiError(response));
    }
  } catch (error) {
    yield put(callBackDetailApiError(error));
  }
}

function* coupenCode({ payload: {values,id, discountCode} }) {
  //console.log(values,id,discountCode,"datadata");
  try {
    const response = yield call(Coupen_Code,{
      email:localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      pack:id,
      userId:values,
      discountCode:discountCode
    });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(coupenCodeSuccess(response));
    } else {
      yield put(coupenCodeApiError(response));
    }
  } catch (error) {
    yield put(coupenCodeApiError(error));
  }
}

//pay now
function* payNowDetail({ payload: {values,id, discountCode} }) {
  //console.log(values,id,discountCode,"datadata");
  try {
    const response = yield call(Pay_Now,{
      email:localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      userId:values,
      pack:id,
      discountapplied: "",
      discount: discountCode,
      phonepack:"" ,
      horopack:"" ,
      timespent:"" ,
      basicpack: ""
    });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(payNowSuccess(response));
    } else {
      yield put(payNowApiError(response));
    }
  } catch (error) {
    yield put(payNowApiError(error));
  }
}

//dynamic pixel
function* dynamicPixelData({ payload: {} }) {
  //console.log(values,id,discountCode,"datadata");
  try {
    const response = yield call(Dynamic_Pixel,{
      email:localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      userId:localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
    });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(dynamicPixelSuccess(response));
    } else {
      yield put(dynamicPixelApiError(response));
    }
  } catch (error) {
    yield put(dynamicPixelApiError(error));
  }
}


function* paymentSaga() {
  yield takeEvery(MEMBERSHIP_DETAIL, membershipDetail);
  yield takeEvery(CALLBACK_DETAIL,callBackDetail);
  yield takeEvery(COUPEN_CODE, coupenCode);
  yield takeEvery(PAY_NOW, payNowDetail);
  yield takeEvery(DYNAMIC_PIXEL, dynamicPixelData);
  // yield takeEvery(OFFLINE_PROFILE, offlineprofile);
}

export default paymentSaga;
