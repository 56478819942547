import React from 'react'
import './Skeleton.css'

//skeleton loader
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function SidePannelProfileSkeleton({ cards, menu }) {
    return (
        <div className={`${menu == "selectedprofileslider" && 'flex w-[100%]'}`}>
            {Array(cards)
                .fill(0)
                .map((item, i) => (
                    <section className="md:px-2 px-[1rem] lg:px-2 md:pt-2 lg:pt-2 pt-3 md:w-[106%] lg:w-[100%] relative z-0" key={i}>
                        <div className="grid grid-cols-1 gap-6">
                            <div className=" flex w-full  rounded-lg sahdow-lg overflow-hidden flex-row">
                                <div className='block'>
                                    <Skeleton className='card-skeleton-profile-img' />
                                    <p><Skeleton className='card-skeleton-contact' /></p>
                                    <p><Skeleton className='card-skeleton-email' /></p>
                                </div>
                                <div className="w-full pb-[1rem] grid text-left space-y-1">
                                    <div className="px-3 space-y-1 pt-3"> 
                                        <p><Skeleton className='card-skeleton-contact' /></p>                                                                               
                                        <p><Skeleton className='h-[2rem] px-3 rounded-[5px] card-skeleton-button' /></p> 
                                        <p><Skeleton className='mb-[0.3rem] card-skeleton-contact' /></p>
                                        <p><Skeleton className='mb-[0.3rem] card-skeleton-email' /></p>
                                    </div>                                                                                                                                                            
                                </div>
                            </div>
                        </div>
                        <div className='space-y-2'>
                            <div className="flex justify-between items-center">
                                <div className='flex items-center'>
                                    <div className='grid grid-cols-12 items-center flex gap-3'>
                                        <div className='col-span-2'>
                                            <p><Skeleton className='card-skeleton-icons' /></p>
                                        </div>
                                        <div className='col-span-10'>
                                            <p><Skeleton className='mb-[0.3rem] card-skeleton-text' /></p>
                                            <p><Skeleton className='mb-[0.3rem] card-skeleton-text' /></p>
                                        </div>
                                    </div>
                                </div>
                                <p><Skeleton className='h-[1.5rem] px-3 rounded-[5px] card-skeleton-button' /></p> 

                            </div>    
                            <div className="flex justify-between items-center">
                                <div className='flex items-center'>
                                    <div className='grid grid-cols-12 items-center flex gap-3'>
                                        <div className='col-span-2'>
                                            <p><Skeleton className='card-skeleton-icons' /></p>
                                        </div>
                                        <div className='col-span-10'>
                                            <p><Skeleton className='mb-[0.3rem] card-skeleton-text' /></p>
                                            <p><Skeleton className='mb-[0.3rem] card-skeleton-text' /></p>
                                        </div>
                                    </div>
                                </div>
                                <p><Skeleton className='h-[1.5rem] px-3 rounded-[5px] card-skeleton-button' /></p> 

                            </div> 
                            <div className="flex justify-between items-center">
                                <div className='flex items-center'>
                                    <div className='grid grid-cols-12 items-center flex gap-3'>
                                        <div className='col-span-2'>
                                            <p><Skeleton className='card-skeleton-icons' /></p>
                                        </div>
                                        <div className='col-span-10'>
                                            <p><Skeleton className='mb-[0.3rem] card-skeleton-text' /></p>
                                            <p><Skeleton className='mb-[0.3rem] card-skeleton-text' /></p>
                                        </div>
                                    </div>
                                </div>
                                <p><Skeleton className='h-[1.5rem] px-3 rounded-[5px] card-skeleton-button' /></p> 

                            </div>    
                            <div className="flex justify-between items-center">
                                <div className='flex items-center'>
                                    <div className='grid grid-cols-12 items-center flex gap-3'>
                                        <div className='col-span-2'>
                                            <p><Skeleton className='card-skeleton-icons' /></p>
                                        </div>
                                        <div className='col-span-10'>
                                            <p><Skeleton className='mb-[0.3rem] card-skeleton-text' /></p>
                                            <p><Skeleton className='mb-[0.3rem] card-skeleton-text' /></p>
                                        </div>
                                    </div>
                                </div>
                                <p><Skeleton className='h-[1.5rem] px-3 rounded-[5px] card-skeleton-button' /></p> 

                            </div>                              
                        </div>
                        <div className='mt-2'>
                        <div className="flex justify-between items-center">
                        <p><Skeleton className='px-3 card-skeleton-title' /></p>
                        <p><Skeleton className='h-[1.5rem] px-3 rounded-[5px] card-skeleton-button' /></p> 
                        </div>
                        </div>
                    </section>
                ))
            }
        </div>
    )
}
