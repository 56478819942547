//onload membership detail
export const MEMBERSHIP_DETAIL = "MEMBERSHIP_DETAIL"
export const MEMBERSHIP_DETAIL_SUCCESS = "MEMBERSHIP_DETAIL_SUCCESS"
export const MEMBERSHIP_DETAIL_API_ERROR = "MEMBERSHIP_DETAIL_API_ERROR"

//call back
export const CALLBACK_DETAIL = "CALLBACK_DETAIL"
export const CALLBACK_DETAIL_SUCCESS = "CALLBACK_DETAIL_SUCCESS"
export const CALLBACK_DETAIL_API_ERROR = "CALLBACK_DETAIL_API_ERROR"

//apply coupen code
export const COUPEN_CODE = "COUPEN_CODE"
export const COUPEN_CODE_SUCCESS = "COUPEN_CODE_SUCCESS"
export const COUPEN_CODE_API_ERROR = "COUPEN_CODE_API_ERROR"

//apply pay now
export const PAY_NOW = "PAY_NOW"
export const PAY_NOW_SUCCESS = "PAY_NOW_SUCCESS"
export const PAY_NOW_API_ERROR = "PAY_NOW_API_ERROR"

//dynamic pixel now
export const DYNAMIC_PIXEL = "DYNAMIC_PIXEL"
export const CLEAR_DYNAMIC_PIXEL = "CLEAR_DYNAMIC_PIXEL"
export const DYNAMIC_PIXEL_SUCCESS = "DYNAMIC_PIXEL_SUCCESS"
export const DYNAMIC_PIXEL_API_ERROR = "DYNAMIC_PIXEL_API_ERROR"

// export const OFFLINE_PROFILE = 'OFFLINE_PROFILE';
// export const OFFLINE_PROFILE_SUCCESS = 'OFFLINE_PROFILE_SUCCESS';
// export const OFFLINE_PROFILE_FAIL = 'OFFLINE_PROFILE_FAIL';
