//onload register form detail
export const REGISTER_DETAIL = "REGISTER_DETAIL"
export const REGISTER_DETAIL_SUCCESS = "REGISTER_DETAIL_SUCCESS"
export const API_ERROR = "REGISTER_API_ERROR"

//caste api call 
export const CASTE_LIST = "CASTE_LIST"
export const GET_CASTE_LIST_SUCCESS = "GET_CASTE_LIST_SUCCESS"

//sub caste api call 
export const SUB_CASTE_LIST = "SUB_CASTE_LIST"
export const GET_SUB_CASTE_LIST_SUCCESS = "GET_SUB_CASTE_LIST_SUCCESS"

//Register sub caste api call 
export const PROFILE_SUB_CASTE_LIST = "PROFILE_SUB_CASTE_LIST"
export const GET_PROFILE_SUB_CASTE_LIST_SUCCESS = "GET_PROFILE_SUB_CASTE_LIST_SUCCESS"

//star api call 
export const STAR_LIST = "STAR_LIST"
export const GET_STAR_LIST_SUCCESS = "GET_STAR_LIST_SUCCESS"

//raasi api call 
export const RAASI_LIST = "RAASI_LIST"
export const GET_RAASI_LIST_SUCCESS = "GET_RAASI_LIST_SUCCESS"

//country api call
export const COUNTRY_LIST = "COUNTRY_LIST"
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS"

//state api call
export const STATE_LIST = "STATE_LIST"
export const GET_STATE_LIST_SUCCESS = "GET_STATE_LIST_SUCCESS"

//city api call
export const CITY_LIST = "CITY_LIST"
export const GET_CITY_LIST_SUCCESS = "GET_CITY_LIST_SUCCESS"

//pincode api call
export const PINCODE_LIST = "PINCODE_LIST"
export const GET_PINCODE_LIST_SUCCESS = "GET_PINCODE_LIST_SUCCESS"

//caste api call 
export const DENOMINATION_LIST = "DENOMINATION_LIST"
export const GET_DENOMINATION_LIST_SUCCESS = "GET_DENOMINATION_LIST_SUCCESS"
export const GET_DENOMINATION_LIST_FAIL = "GET_DENOMINATION_LIST_FAIL"

//form submission api call
export const REGISTER_SUBMIT = "REGISTER_SUBMIT"
export const REGISTER_SUBMIT_SUCCESS = "REGISTER_SUBMIT_SUCCESS"

//validate name detail
export const VALIDATE_NAME = "VALIDATE_NAME"
export const VALIDATE_NAME_SUCCESS = "VALIDATE_NAME_SUCCESS"
export const VALIDATE_NAME_ERROR = "VALIDATE_NAME_ERROR"
