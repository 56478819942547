//getConversation list 
export const GET_CONVERSATION = "GET_CONVERSATION"
export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS"
export const GET_CONVERSATION_FAIL = "GET_CONVERSATION_FAIL"


export const GET_MORE_CONVERSATION = "GET_MORE_CONVERSATION"
export const GET_MORE_CONVERSATION_SUCCESS = "GET_MORE_CONVERSATION_SUCCESS"
export const GET_MORE_CONVERSATION_FAIL = "GET_MORE_CONVERSATION_FAIL"

export const GET_CONVERSATION_BY_PAGE = "GET_CONVERSATION_BY_PAGE";
export const GET_CONVERSATION_BY_PAGE_SUCCESS = "GET_CONVERSATION_BY_PAGE_SUCCESS";
export const GET_CONVERSATION_BY_PAGE_FAIL = "GET_CONVERSATION_BY_PAGE_FAIL";

export const TOGGLE_MUTE_CONVERSATIONS = "TOGGLE_MUTE_CONVERSATIONS"


//for sending text, audio, doc, reply messages
export const SEND_MESSAGE = "SEND_MESSAGE"
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS"
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL"

export const SEND_GROUP_MESSAGE = "SEND_GROUP_MESSAGE"
export const SEND_GROUP_MESSAGE_SUCCESS = "SEND_GROUP_MESSAGE_SUCCESS"
export const SEND_GROUP_MESSAGE_FAIL = "SEND_GROUP_MESSAGE_FAIL"

export const SEND_CUSTOMER_SERVICE_MESSAGE = "SEND_CUSTOMER_SERVICE_MESSAGE"
export const SEND_CUSTOMER_SERVICE_MESSAGE_SUCCESS = "SEND_CUSTOMER_SERVICE_MESSAGE_SUCCESS"
export const SEND_CUSTOMER_SERVICE_MESSAGE_FAIL = "SEND_CUSTOMER_SERVICE_MESSAGE_FAIL"

export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED"
export const GROUP_MESSAGE_RECEIVED = "GROUP_MESSAGE_RECEIVED"

export const STAR_MESSAGE = "STAR_MESSAGE"
export const STAR_MESSAGE_SUCCESS = "STAR_MESSAGE_SUCCESS"
export const STAR_MESSAGE_FAIL = "STAR_MESSAGE_FAIL"


export const OFFLINE_PROFILE = "OFFLINE_PROFILE"
export const OFFLINE_PROFILE_SUCCESS = "OFFLINE_PROFILE_SUCCESS"
export const OFFLINE_PROFILE_FAIL = "OFFLINE_PROFILE_FAIL"


//get chat list 
export const GET_CHAT_LIST = "GET_CHAT_LIST"
export const GET_CHAT_LIST_SUCCESS = "GET_CHAT_LIST_SUCCESS"
export const GET_CHAT_LIST_FAIL = "GET_CHAT_LIST_FAIL"

//get group chat list 
export const GET_GROUP_CHAT_LIST = "GET_GROUP_CHAT_LIST"
export const GET_GROUP_CHAT_LIST_SUCCESS = "GET_GROUP_CHAT_LIST_SUCCESS"
export const GET_GROUP_CHAT_LIST_FAIL = "GET_GROUP_CHAT_LIST_FAIL"

//get bulk request 
export const GET_BULK_REQUEST = "GET_BULK_REQUEST"
export const GET_BULK_REQUEST_SUCCESS = "GET_BULK_REQUEST_SUCCESS"
export const GET_BULK_REQUEST_FAIL = "GET_BULK_REQUEST_FAIL"

export const SEARCH_CHAT_LIST = "SEARCH_CHAT_LIST"
export const SEARCH_CHAT_LIST_SUCCESS = "SEARCH_CHAT_LIST_SUCCESS"
export const SEARCH_CHAT_LIST_FAIL = "SEARCH_CHAT_LIST_FAIL"

//user media
export const GET_CHAT_MEDIA = "GET_CHAT_MEDIA"
export const GET_CHAT_MEDIA_SUCCESS = "GET_CHAT_MEDIA_SUCCESS"
export const GET_CHAT_MEDIA_FAIL = "GET_CHAT_MEDIA_FAIL"

export const GET_MORE_CHAT_MEDIA = "GET_MORE_CHAT_MEDIA"
export const GET_MORE_CHAT_MEDIA_SUCCESS = "GET_MORE_CHAT_MEDIA_SUCCESS"
export const GET_MORE_CHAT_MEDIA_FAIL = "GET_MORE_CHAT_MEDIA_FAIL"


//set current chat
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT"

//close current chat
export const CLOSE_CURRENT_CHAT = "CLOSE_CURRENT_CHAT"

//add selected chats
export const ADD_SELECTED_CHAT = "ADD_SELECTED_CHAT"
export const REMOVE_SELECTED_CHAT = "REMOVE_SELECTED_CHAT"
export const REMOVE_ALL_SELECTED_CHAT = "REMOVE_ALL_SELECTED_CHAT"
export const TOGGLE_MESSAGE_SELECTION = "TOGGLE_MESSAGE_SELECTION";

// Conversation status
export const GET_CONVERSATION_STATUS = "GET_CONVERSATION_STATUS"
export const GET_CONVERSATION_STATUS_SUCCESS = "GET_CONVERSATION_STATUS_SUCCESS"
export const GET_CONVERSATION_STATUS_FAIL = "GET_CONVERSATION_STATUS_FAIL"

export const DELETE_CHAT = "DELETE_CHAT"


export const LOG_DOCUMENT_VIEW = "LOG_DOCUMENT_VIEW"

export const RESET_UNREAD_COUNT = "RESET_UNREAD_COUNT"

export const GET_FAMILY_CHAT_DETAIL ="GET_FAMILY_CHAT_DETAIL"

export const GET_FAMILY_CHAT_DETAIL_SUCCESS ="GET_FAMILY_CHAT_DETAIL_SUCCESS"

export const GET_FAMILY_CHAT_DETAIL_FAIL ="GET_FAMILY_CHAT_DETAIL_FAIL"

//group chat reactions

export const REACTIONS_ADD = "REACTIONS_ADD"
export const REACTIONS_RECEIVED = "REACTIONS_RECEIVED"
export const REACTIONS_ADD_SUCCESS = "REACTIONS_ADD_SUCCESS"
export const REACTIONS_ADD_FAIL = "REACTIONS_ADD_FAIL"


//get group Conversation list 
export const GET_GROUP_CONVERSATION = "GET_GROUP_CONVERSATION"
export const GET_GROUP_CONVERSATION_SUCCESS = "GET_GROUP_CONVERSATION_SUCCESS"
export const GET_GROUP_CONVERSATION_FAIL = "GET_GROUP_CONVERSATION_FAIL"


export const GET_GROUP_MORE_CONVERSATION = "GET_GROUP_MORE_CONVERSATION"
export const GET_GROUP_MORE_CONVERSATION_SUCCESS = "GET_GROUP_MORE_CONVERSATION_SUCCESS"
export const GET_GROUP_MORE_CONVERSATION_FAIL = "GET_GROUP_MORE_CONVERSATION_FAIL"

export const GET_FAMILY_CHAT_LIST ="GET_FAMILY_CHAT_LIST"

export const GET_FAMILY_CHAT_LIIST_SUCCESS ="GET_FAMILY_CHAT_LIST_SUCCESS"

export const GET_FAMILY_CHAT_LIST_FAIL ="GET_FAMILY_CHAT_LIST_FAIL"

export const DELETE_GROUP_MESSAGE = "DELETE_GROUP_MESSAGE";

export const RESET_MSG_UNREAD_COUNT = "RESET_MSG_UNREAD_COUNT"
export const RESET_UNREAD_MSG_COUNT = "RESET_UNREAD_MSG_COUNT"

export const GROUP_STAR_MESSAGE = "GROUP_STAR_MESSAGE"
export const GROUP_STAR_MESSAGE_SUCCESS = "GROUP_STAR_MESSAGE_SUCCESS"
export const GROUP_STAR_MESSAGE_FAIL = "GROUP_STAR_MESSAGE_FAIL"


export const READ_GROUP_MESSAGE = "READ_GROUP_MESSAGE"
export const READ_GROUP_MESSAGE_SUCCESS = "READ_GROUP_MESSAGE_SUCCESS"


export const READ_BY_RECEIVED = "READ_BY_RECEIVED"

// get customer service conversation list

export const GET_CUSTOMER_SERVICE_CONVERSATION = "GET_CUSTOMER_SERVICE_CONVERSATION"
export const GET_CUSTOMER_SERVICE_CONVERSATION_SUCCESS = "GET_CUSTOMER_SERVICE_CONVERSATION_SUCCESS"
export const GET_CUSTOMER_SERVICE_CONVERSATION_FAIL = "GET_CUSTOMER_SERVICE_CONVERSATION_FAIL"

export const GET_CUSTOMER_SERVICE_MORE_CONVERSATION = "GET_CUSTOMER_SERVICE_MORE_CONVERSATION"
export const GET_CUSTOMER_SERVICE_MORE_CONVERSATION_SUCCESS = "GET_CUSTOMER_SERVICE_MORE_CONVERSATION_SUCCESS"
export const GET_CUSTOMER_SERVICE_MORE_CONVERSATION_FAIL = "GET_CUSTOMER_SERVICE_MORE_CONVERSATION_FAIL"

export const CHAT_UNREAD_MSG_COUNT = "CHAT_UNREAD_MSG_COUNT"
export const CHAT_UNREAD_MSG_COUNT_SUCCESS = "CHAT_UNREAD_MSG_COUNT_SUCCESS"
export const CHAT_UNREAD_MSG_COUNT_FAIL = "CHAT_UNREAD_MSG_COUNT_FAIL"

export const RANKING_POPUP = "RANKING_POPUP"
export const RANKING_POPUP_SUCCESS = "RANKING_POPUP_SUCCESS"
export const RANKING_POPUP_FAIL = "RANKING_POPUP_FAIL"

export const RESET_UN_READ_COUNT = "RESET_UN_READ_COUNT"
export const RESET_UN_READ_COUNT_SUCCESS = "RESET_UNREAD_COUNT_SUCCESS"
export const RESET_UN_READ_COUNT_FAIL = "RESET_UN_READ_COUNT_FAIL"


// set family code
export const FAMILY_SET_FAMILY_CODE = "FAMILY_SET_FAMILY_CODE"
export const FAMILY_SET_FAMILY_CODE_SUCCESS = "FAMILY_SET_FAMILY_CODE_SUCCESS"
export const FAMILY_SET_FAMILY_CODE_FAIL = "FAMILY_SET_FAMILY_CODE_FAIL"

// profile request send message
export const PROFILE_SEND_MESSAGE = "PROFILE_SEND_MESSAGE"
export const PROFILE_SEND_MESSAGE_SUCCESS = "PROFILE_SEND_MESSAGE_SUCCESS"
export const PROFILE_SEND_MESSAGE_FAIL = "PROFILE_SEND_MESSAGE_FAIL"