import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { ViewAllButton } from "../DashboardComponents/DashboardSection";
import {
  MatchesProfileCard,
  MatchesProfileCard2,
  ProfileLookingForCard,
} from "../DashboardComponents/ProfileCard";
import { useNavigate } from "react-router";
import { RouteHelper } from "../../Routes/RouteSegment";
import SliderLeftIcon from "../../Images/slider1.svg"
import SliderRightIcon from "../../Images/slider2.svg"

const MatchesProfileSection = (props) => {
  const navigate = useNavigate();
  const sliderRef = useRef();
  const parentRef = useRef(null);

  const [selectReject, setSelectReject] = useState({
    select: props?.cardData?.selectedprofile ?? false,
    reject: props?.cardData?.selectedrejected ?? false,
    message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
  });
  useEffect(() => { }, [selectReject]);
  const shiftNext = (num) => {
    if (num === props.data.length - 1) {
      props.callLoadMore();
    }
  };
  var sliderResponsive = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 765,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 740,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    swipe: true,
    swipeToSlide: true,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <img alt="" src={SliderLeftIcon} className="w-7" />,
    nextArrow: <img alt="" src={SliderRightIcon} className="w-7" />,
    afterChange: shiftNext,
    // onReInit: reinit,
    rows: 1,
    variableWidth: true,
    responsive: sliderResponsive,
  };

  useEffect(() => {
    if (!parentRef.current) {
      return;
    }
    parentRef?.current?.addEventListener('wheel', (e) => handleScroll(e));

    return (() => {
      parentRef?.current?.removeEventListener('wheel', (e) => handleScroll(e));
    });
  }, [parentRef, props?.data]);

  const handleScroll = e => {
    if (e.deltaX > 0) {
      sliderRef && sliderRef?.current?.slickNext();
    } else if (e.deltaX < 0) {
      sliderRef && sliderRef?.current?.slickPrev();
    }
  };
  return (
    <div>
      <div className="flex justify-between py-3">
        <p className="text-[13pt] md:text-[15px] whitespace-nowrap  lg:text-[19px] text-[#575556] font-segeo font-semibold">
          {props.title}
        </p>
        {props?.menu === "similarview" ?
        props.count?

          <span className="inline-flex  font-bold justify-center items-center ml-2 w-[3rem] h-[1.8rem] md:w-[3rem] md:h-[2rem] lg:w-[3.5rem] lg:h-[2rem] text-[13px] md:text-[13px] lg:text-[14px] text-[#000000] bg-[#FDC300] rounded-[10px]">
            {props.count}
          </span>
          : "":""
        }
        <span className=" hidden md:block mx-4">
          <ViewAllButton btnCallBack={props.ViewAllCallBack} />
        </span>
      </div>
      <div className="">
        {/* {props?.data && (
          <Slider className="pt-3" {...settings}>
            {props?.data?.map((element, i) => (
              <MatchesProfileCard
                key={element.profileid}
                cardData={element}
                callBackNav={() =>
                  navigate(`/matches?id=${element.profileid}`, { state: { id: element.profileid },replace:true })
                }
                callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                profileid={props?.profileid}
                callBackPhone={()=>props.callBackViewPhone(element.profileid)}
                callbackSelectReject={(type, typeRequest) => {
                  props.callbackSelectReject(
                    type,
                    element.profileid,
                    typeRequest,element,i
                  );
                }}
              />
            ))}
          </Slider>
        )} */}
        <div ref={parentRef} className="rightSideSimilarSlider">
          <Slider className="pt-3 gap-4 " {...settings} ref={sliderRef} >
            {props?.data?.map((element, i) => {

              return (
                <MatchesProfileCard
                  title={props?.title}
                  // console={//console.log(element,"element")}
                  key={element.profileid}
                  cardData={element}
                  callBackNav={() =>
                    navigate(RouteHelper.getProfileUrl, { state: { id: element.profileid } })
                  }
                  callbackParentNumberRequest={() => props.callbackParentNumberRequest(element)
                  }
                  callbackRequestPhoto={() => props.callbackRequestPhoto(element)}
                  profileid={props?.profileid}
                  callBackPhone={() => props.callBackViewPhone(element.profileid)}
                  callbackSelectReject={(type, typeRequest) => {
                    props.callbackSelectReject(type, element.profileid, typeRequest, element, i);
                  }}
                />
              );

            })}
          </Slider>
          <center className="text-center relative  tracking-[0.3rem]  top-[1.5rem] lg:top-[1rem] ">....</center>
          <br />
        </div>
      </div>
    </div>
  );
};

const MatchesProfileSection2 = (props) => {
  const navigate = useNavigate();

  const sliderRef = useRef();
  const parentRef = useRef(null);

  const shiftNext = (num) => {
    if (num === props.data.length - 2) {
      props.callLoadMore();
    }
  };
  const reinit = () => { };
  useEffect(() => { }, []);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    //   variableWidth: true,
    prevArrow: <img alt="" src={SliderLeftIcon} className="w-6" />,
    nextArrow: <img alt="" src={SliderRightIcon} className="w-6" />,
    afterChange: shiftNext,

    //   responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 1.2,
    //         slidesToScroll: 1,
    //       },
    //     },
    //     {
    //       breakpoint: 850,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         arrows: false,
    //       },
    //     },
    //     {
    //       breakpoint: 765,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1,
    //         arrows: false,
    //       },
    //     },
    //     {
    //       breakpoint: 740,
    //       settings: {
    //         slidesToShow: 1.5,
    //         slidesToScroll: 1,
    //         arrows: false,
    //       },
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         arrows: false,
    //       },
    //     },
    //   ],
  };
  useEffect(() => {
    if (!parentRef.current) {
      return;
    }
    parentRef?.current?.addEventListener('wheel', (e) => handleScroll(e));

    return (() => {
      parentRef?.current?.removeEventListener('wheel', (e) => handleScroll(e));
    });
  }, [parentRef, props?.data]);

  const handleScroll = e => {
    if (e.deltaX > 0) {
      sliderRef && sliderRef?.current?.slickNext();
    } else if (e.deltaX < 0) {
      sliderRef && sliderRef?.current?.slickPrev();
    }
  };

  return (
    <div className="section border border-[#F1F1F1] md:p-3 mt-[1rem]">
      <div className="flex mx-[1.25rem] py-2">
        <p className="text-[20px] text-[#575556] font-semibold">
          {props.title}
        </p>
        <span className="inline-flex  font-bold justify-center items-center ml-2 w-[1.8rem] h-[1.8rem] md:w-[1.6rem] md:h-[1.6rem] lg:w-[2.5rem] lg:h-[2rem] text-[11px] md:text-[11px] lg:text-[13px] text-[#000000] bg-[#FDC300] rounded-[10px]">
          {props?.count}
        </span>

      </div>
      {/* <div className=" hidden md:hidden flex lg:flex overflow-x-auto py-3 mx-12 md:mx-4 lg:mx-16 gap-12 md:gap-4 lg:gap-12"> */}
      {props?.data && (
        <div ref={parentRef}>
          <Slider className="py-3  " {...settings} ref={sliderRef} >
            {props?.data?.map((element, i) => (


              <ProfileLookingForCard
                key={element.profileid}
                cardData={element}
                callBackNav={() =>
                  navigate(RouteHelper.getProfileUrl, { state: { id: element.profileid } })
                }
                callbackRequestPhoto={() => props.callbackRequestPhoto(element)}
                callBackPhone={() => props.callBackViewPhone(element.profileid)}
                callbackParentNumberRequest={() => props.callbackParentNumberRequest(element)
                }
                callbackSelectReject={(type, typeRequest) => {
                  props.callbackSelectReject(
                    type,
                    element.profileid,
                    typeRequest, element, i
                  );
                }}
              />
            ))}
          </Slider>
        </div>
      )}
      <span className="md:hidden block mx-4 text-end py-2 mb-[5rem]">
        <ViewAllButton btnCallBack={props.ViewAllCallBack} />

      </span>

    </div>
  );
};

export { MatchesProfileSection, MatchesProfileSection2 };
