export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

//change mobile number
export const CHANGE_MOBILE_NO = "CHANGE_MOBILE_NO"
export const CHANGE_MOBILE_NO_SUCCESS = "CHANGE_MOBILE_NO_SUCCESS"
export const CHANGE_MOBILE_NO_ERROR = "CHANGE_MOBILE_NO_API_ERROR"

//send OTP
export const SEND_OTP = "SEND_OTP"
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS"
export const SEND_OTP_ERROR = "SEND_OTP_API_ERROR"


// OTP validation
export const OTP_VALIDATION = "OTP_VALIDATION"
export const OTP_VALIDATION_SUCCESS = "OTP_VALIDATION_SUCCESS"
export const OTP_VALIDATION_ERROR = "OTP_VALIDATION_API_ERROR"

// Mobile verification
export const MOBILE_VERIFICATION = "MOBILE_VERIFICATION"
export const MOBILE_VERIFICATION_SUCCESS = "MOBILE_VERIFICATION_SUCCESS"
export const MOBILE_VERIFICATION_ERROR = "MOBILE_VERIFICATION_API_ERROR"

// Request again
export const REQUEST_AGAIN = "REQUEST_AGAIN"
export const REQUEST_AGAIN_SUCCESS = "REQUEST_AGAIN_SUCCESS"
export const REQUEST_AGAIN_ERROR = "REQUEST_AGAIN_API_ERROR"

// Mobile verification fresh
export const MOBILE_VERIFICATION_FRESH = "MOBILE_VERIFICATION_FRESH"
export const MOBILE_VERIFICATION_FRESH_SUCCESS = "MOBILE_VERIFICATION_FRESH_SUCCESS"
export const MOBILE_VERIFICATION_FRESH_ERROR = "MOBILE_VERIFICATION_FRESH_API_ERROR"

//get basic data
export const GET_BASIC_DATA = "GET_BASIC_DATA"
export const GET_BASIC_DATA_SUCCESS = "GET_BASIC_DATA_SUCCESS"

//reset password
export const RESET_PASSWORD = "RESET_PASSWORD_USER"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_API_ERROR = "RESET_PASSWORD_API_ERROR"

//more link content
export const MORE_LINK_CONTENT = "MORE_LINK_CONTENT"
export const MORE_LINK_CONTENT_SUCCESS = "MORE_LINK_CONTENT_SUCCESS"
export const MORE_LINK_CONTENT_API_ERROR = "MORE_LINK_CONTENT_API_ERROR"

//login from admin
export const LOGIN_FROM_ADMIN_USER = "LOGIN_FROM_ADMIN_USER"
export const LOGIN_FROM_ADMIN_SUCCESS = "LOGIN_FROM_ADMIN_SUCCESS"
export const LOGIN_FROM_ADMIN_API_ERROR = "LOGIN_FROM_ADMIN_API_ERROR"

//login from admin partial
export const LOGIN_FROM_ADMIN_PARTIAL_USER = "LOGIN_FROM_ADMIN_PARTIAL_USER"
export const LOGIN_FROM_ADMIN_PARTIAL_SUCCESS = "LOGIN_FROM_ADMIN_PARTIAL_SUCCESS"
export const LOGIN_FROM_ADMIN_PARTIAL_API_ERROR = "LOGIN_FROM_ADMIN_PARTIAL_API_ERROR"

//login from email
export const LOGIN_FROM_EMAIL_USER = "LOGIN_FROM_EMAIL_USER"
export const LOGIN_FROM_EMAIL_SUCCESS = "LOGIN_FROM_EMAIL_SUCCESS"
export const LOGIN_FROM_EMAIL_API_ERROR = "LOGIN_FROM_EMAIL_API_ERROR"

//login from sms
export const LOGIN_FROM_SMS_USER = "LOGIN_FROM_SMS_USER"
export const LOGIN_FROM_SMS_SUCCESS = "LOGIN_FROM_SMS_SUCCESS"
export const LOGIN_FROM_SMS_API_ERROR = "LOGIN_FROM_SMS_API_ERROR"

//login from akhayatritiya
export const LOGIN_FOR_AKHAYATRITIYA = "LOGIN_FOR_AKHAYATRITIYA"
export const LOGIN_FOR_AKHAYATRITIYA_SUCCESS = "LOGIN_FOR_AKHAYATRITIYA_SUCCESS"
export const LOGIN_FOR_AKHAYATRITIYA_API_ERROR = "LOGIN_FOR_AKHAYATRITIYA_API_ERROR"

//comunity site detail
export const COMMUNITY_SITE_DETAIL = "COMMUNITY_SITE_DETAIL"
export const COMMUNITY_SITE_DETAIL_SUCCESS = "COMMUNITY_SITE_DETAIL_SUCCESS"
export const COMMUNITY_SITE_DETAIL_API_ERROR = "COMMUNITY_SITE_DETAIL_API_ERROR"

//site map detail
export const SITE_MAP = "SITE_MAP"
export const SITE_MAP_SUCCESS = "SITE_MAP_SUCCESS"
export const SITE_MAP_API_ERROR = "SITE_MAP_API_ERROR"

//login from offline
export const LOGIN_FROM_OFFLINE = "LOGIN_FROM_OFFLINE"
export const LOGIN_FROM_OFFLINE_SUCCESS = "LOGIN_FROM_OFFLINE_SUCCESS"
export const LOGIN_FROM_OFFLINE_ERROR = "LOGIN_FROM_OFFLINE_ERROR"

//login from offline view profile
export const LOGIN_FOR_VIEW_PROFILE = "LOGIN_FOR_VIEW_PROFILE"
export const LOGIN_FOR_VIEW_PROFILE_SUCCESS = "LOGIN_FOR_VIEW_PROFILE_SUCCESS"
export const LOGIN_FOR_VIEW_PROFILE_ERROR = "LOGIN_FOR_VIEW_PROFILE_ERROR"
