import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import RouteSegments, { RouteHelper } from "../../Routes/RouteSegment";
import ImageHoroscopePopup from "../Edit Profile/ImageHoroscopePopup";
import { CommonSuccessPopup, UploadPhotoPopup } from "../CommonPopup";
import ParentsPhonePopup from "./ParentsPhonePopup";
import ViewMessageCards from "../ViewMessageComponents/ViewMessageCards";
import Constants, { EditProfileMenu, InboxFilters } from "../../Constants/constants";
import { NavBarContext } from "../../Contexts/NavBarContext";
import { NavListContext } from "../../Contexts/navContext"
import { CommonPopup } from "../../Components/CommonPopup";
import useModule from "../../Hooks/useModule"
import ConsultIcon from "../../Images/Group 225.svg"
import MessageIcon from "../../Images/Group 428.svg"
import myphoto from '../../Images/Group 17.svg'
import parantsImage from '../../Images/Group 174.svg'
import unreadImage from '../../Images/Group 175.svg'
import profileReplyImage from '../../Images/Group 176.svg'
import CardSkeleton from "../skeletonLoader/CardSkeleton";
import SidePannelProfileSkeleton from "../skeletonLoader/SidePannelProfileSkeleton";
import { CardSkeletonWithSlider, CardSkeletonWithSliderProfile } from "../../Components/skeletonLoader/CardSkeletonWithSlider";
import { useTranslation } from 'react-i18next';

import Loader from "../Loader";

const ProfileSection = (props) => {
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);

  useEffect(() => {
    //console.log(props?.data,"avartarcheck");
  }, [props?.menu, props?.data, props?.profileDataCount, props?.onSubmitSuccess])


  return (
    <>
      {props?.data?.length != 0 ?
        <div className={`${props?.data ? 'bg-[#E9E9E9]' : 'bg-white'}`}>
          <UserDetailsSection data={props?.data} />
          <div className="p-5 md:p-3 lg:p-3 xl:p-5 bg-[#E9E9E9] ">
            <UserDetailsRequestSection menu={props?.menu} onSubmitSuccess={props?.onSubmitSuccess} profileDataCount={props?.profileDataCount} data={props?.data} refresh={props.refresh} />
          </div>
        </div>
        :
        <div className=" section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1] my-6 bg-white" >
          <SidePannelProfileSkeleton cards={1} menu="profile" />
          <div className="mt-4 px-3">
            <CardSkeletonWithSlider cards={3} menu={props?.menu} />
          </div>
        </div>
      }
      {props?.showList == false &&
        <>
          <p className="pt-[1rem] pl-[0.5rem] text-[#575556] font-segeo font-bold text-[20px]">
            {props?.selectedMessageType == "All Message" ?
              "All Messages" :
              props?.selectedMessageType == "Unread Message" ?
                "Unread Messages" :
                props?.selectedMessageType == "Replypending Message" ?
                  "Reply Pending Messages" :
                  props?.selectedMessageType == "Read Message" ?
                    "Replied Messages" :
                    "Sent Messages"

            }
          </p>
          <div className="pr-[15px] h-[21rem] overflow-y-auto Noscroll">
            <SidePanleList inboxData={props?.inboxData} selectedFilter={props?.selectedFilter} showList={props?.showList}
              onClickViewBtn={(i, val, element) => props?.onClickViewBtn(i, val, element)}
              isFrom={"sidepanel"}
            />
          </div>
        </>
      }
    </>
  );
};
export const UserDetailsRequestRow = (props) => {

  const { t:trans } = useTranslation();

  return (
    <li className={`flex  justify-between ${props.title === trans('my_profile.profile_completionscore') ? "" : "border-b-[1px] border-[#7A7A7A] border-opacity-50"}`}>
      <div className=" flex py-1">
        <div className="py-3">
          <img className="w-5 h-5" src={props.icon} alt="" />
        </div>
        <div className="px-3 md:px-2 lg:px-3 w-full">
          <span onClick={() => { props.onTitleClick() }} className=" md:text-[12px] lg:text-[14px] font-segeo font-bold text-[#7A7A7A] cursor-pointer">
            {props.title}
          </span>
          {props.isShowCount ? (
            <span onClick={() => { props.onCountClick() }} className="inline-flex justify-center items-center font-bold ml-2 w-[1.8rem] h-[1.8rem] md:w-[1.6rem] md:h-[1.6rem] lg:w-[1.8rem] lg:h-[1.8rem] text-[11px] md:text-[11px] lg:text-[12px] text-[#000000] bg-[#FDC300] rounded-full">
              {props.count}
            </span>
          ) : (
            // <></>
            props?.title == "Edit Partner Preference" ?
              props.editpreferencecount == "0" ?
                ""
                :
                <span className="inline-flex justify-center items-center font-bold ml-2 w-[1.8rem] h-[1.8rem] md:w-[1.6rem] md:h-[1.6rem] lg:w-[1.8rem] lg:h-[1.8rem] text-[11px] md:text-[11px] lg:text-[12px] text-[#000000] bg-[#FDC300] rounded-full">
                  {props.editpreferencecount}
                </span>
              : ""

          )}
          <p className="text-[11px] md:text-[9px] lg:text-[10px] font-semibold font-segeo  text-[#9F9F9F]">
            {props.subtitle}
          </p>
        </div>
      </div>
      <div className="text-center mt-3 ">
        <button
          className=" text-[#FFFFFF] w-24 lg:w-24 md:w-24 font-segeo font-semibold text-[11px] bg-[#D10A11] p-1 rounded-[5px]"
          onClick={() => {
            props.btnCallback();
          }}
        >
          {props.buttonName}
        </button>
      </div>
    </li>
  );
};
export const UserDetailsRequestSection = ({ profileDataCount, data, props }) => {
  console.log(profileDataCount, 'cjbvbievuce');
  const navigate = useNavigate();
  const [showParentNumPopup, setShowParentNumPopup] = useState(false)
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);
  const { navList, setNavList } = useContext(NavListContext);
  const [messageCount, setMessageCount] = useState(false)

  const { isInbox } = useModule()
  const { t: trans } = useTranslation();

  useEffect(() => {
    //console.log(menuSelect,"menumenu");
    setMessageCount(profileDataCount?.profileunreadmessagecount)
    //console.log(messageCount,"messagecount");
    if (profileDataCount?.profileunreadmessage === "Read & Reply") {
      setMessageCount(profileDataCount?.profileunreadmessagecount)
    }

  }, [menuSelect, messageCount])

  const enableReplace = isInbox ? true : false;

  return (
    <>{showParentNumPopup &&
      <ParentsPhonePopup close={() => setShowParentNumPopup(false)}
        title={"Parent's Number"}
        callBackApply={() => { }}
        onSubmitSuccess={props?.onSubmitSuccess}
        parentsIsdCode={data?.parentsIsdCode}
        parentsMobileNumber={data?.parentsMobileNumber}
        refresh={props?.refresh}
      />
    }

      <ul>
        {data?.myphoto === "N" && <UserDetailsRequestRow
          title={"Photo Request"}
          icon={myphoto}
          isShowCount={profileDataCount?.photorequestcount === "0" ? false : true}
          count={profileDataCount?.photorequestcount}
          subtitle={
            profileDataCount?.photorequestcount === "0"
              ? "We request you to add your photo"
              : `${profileDataCount?.photorequestcount} People have requested your photo`
          }
          btnCallback={() => {
            // //console.log("btn click");

            navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
          }}
          onTitleClick={() => {
            navigate(RouteHelper.getInboxByTab(InboxFilters.requestReceived[1].urlPath), { replace: enableReplace });
          }}
          onCountClick={() => {
            // navigate(RouteSegments.INBOX, {
            //   state: { menu: "REQUESTRECEIVED", subMenu:1 },
            // });
          }}
          buttonName={trans('matches.add_now')}
        />}
        <UserDetailsRequestRow
          title={
            data?.parentsNumberAvailable === "No"
              ? "Add Parent's Number"
              : "Edit Parent's Number"
          }
          icon={parantsImage}
          isShowCount={false}
          subtitle={
            data?.parentsNumberAvailable === "No"
              ? "To get more response"
              : "Edit Your Parent's number "
          }
          btnCallback={() => {
            setShowParentNumPopup(true)
            // //console.log("btn click");
          }}
          onTitleClick={() => { }}
          onCountClick={() => { }}
          buttonName={
            data?.parentsNumberAvailable === "No" ? trans('matches.add_now') : "Edit"
          }
        />

        <UserDetailsRequestRow
          title={
            profileDataCount?.profileunreadmessage ? "Unread Message" : "View Messages"
          }
          icon={unreadImage}
          isShowCount={profileDataCount?.profileunreadmessage ? true : false}
          count={profileDataCount?.profileunreadmessagecount}
          subtitle={
            profileDataCount?.profileunreadmessage
              ? `You have ${profileDataCount?.profileunreadmessagecount} Unread Message`
              : "View your Messages"
          }
          btnCallback={() => {
            // //console.log("btn click");
            setMenuSelect("3");
            if (profileDataCount?.profileunreadmessage) {
              navigate(RouteHelper.getInboxByTab(InboxFilters.message[1].urlPath), {
                replace: true
              });
              setNavList(['Inbox', 'Unread Messages'])
            } else {
              navigate(RouteHelper.getInboxByTab(InboxFilters.message[0].urlPath), { replace: enableReplace });
              setNavList(['Inbox', 'All Messages'])
            }
          }}
          onTitleClick={() => { }}
          onCountClick={() => {


          }}
          buttonName={profileDataCount?.profileunreadmessage ? "Read & Reply" : "Read Now"}
        />
        <UserDetailsRequestRow
          title={
            profileDataCount?.profilereplyedmessage
              ? "Reply Pending"
              : "Edit Partner Preference"
          }
          icon={profileReplyImage}
          isShowCount={profileDataCount?.profilereplyedmessage ? true : false}
          count={profileDataCount?.profilereplyedmessagecount}
          editpreferencecount={profileDataCount?.editpreferencecount}
          subtitle={
            profileDataCount?.profilereplyedmessage
              ? `${profileDataCount?.profilereplyedmessagecount} People are waiting for your response.`
              : "For Accurate Matches"
          }
          onTitleClick={() => { }}
          btnCallback={() => {
            setMenuSelect("3")
            // //console.log("btn click");
            if (profileDataCount?.profilereplyedmessage) {
              navigate(RouteHelper.getInboxByTab(InboxFilters.message[2].urlPath), { replace: enableReplace });
            } else {
              navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath));


            }
          }}
          onCountClick={() => { }}
          buttonName={

            profileDataCount?.profilereplyedmessage ? "Reply Now" : "Edit Now"
          }

        />
        <UserDetailsRequestRow
          title={trans('my_profile.profile_completionscore')}
          icon={"/Assets/Images/profile1.svg"}
          isShowCount={true}
          count={`${profileDataCount?.profilecompletationcount ?? "0"}%`}
          subtitle={`Your Profile is ${profileDataCount?.profilecompletationcount === "100"
            ? "Complete"
            : "Incomplete"
            }`}
          onTitleClick={() => { }}
          onCountClick={() => { }}
          btnCallback={() => {
            navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath), { state: { tab: "1" } });
            setMenuSelect("4")
          }}
          buttonName={
            profileDataCount?.profilecompletationcount === "100"
              ? "Edit"
              : "Complete Now"
          }
        />

        {/* <li className="flex justify-between border-b-[1px] border-[#7A7A7A] border-opacity-50">
            <div className=" flex py-1">
              <div className="py-3">
                <img className="w-5 h-5" src="/Assets/Images/Group 17.svg" />
              </div>
              <div className="px-3 md:px-2 lg:px-3">
                <span className="text-[13px] font-bold text-[#7A7A7A]">
                  Photo Request
                </span>
                <span className="inline-flex justify-center items-center font-bold ml-2 p-1 h-5 w-5 text-[11px] md:text-[11px] lg:text-[14px] text-[#000000] bg-[#FDC300] rounded-full">
                {props.data.photorequestcount}
                </span>
                <p className="text-[11px] md:text-[9px] lg:text-[11px] font-segeo  text-[#9F9F9F]">
                  We Request you to Add your Photo
                </p>
              </div>
            </div>
            <div className="text-center mt-3 ">
              <button className=" text-[#FFFFFF] px-5 text-[11px] bg-[#D10A11] p-1 rounded-[5px]">
                Add Now
              </button>
            </div>
          </li> 
        <li className="flex justify-between border-b-[1px] border-[#7A7A7A] border-opacity-50">
            <div className=" flex py-1">
              <div className="py-3">
                <img className="w-5 h-5" src="/Assets/Images/Group 174.svg" />
              </div>
              <div className="px-3">
                <span className="text-[13px] font-bold text-[#7A7A7A]">
                  Edit Parents Number
                </span>
                <p className="text-[11px] md:text-[9px] lg:text-[11px] font-segeo  text-[#9F9F9F]">
                  Edit Your Parent's Number 
                </p>
              </div>
            </div>
            <div className="text-center mt-3 ">
              <button className=" text-[#FFFFFF] px-8 text-[11px] bg-[#D10A11] p-1 rounded-[5px]">
                Edit
              </button>
            </div>
          </li> 
       <li className="flex justify-between border-b-[1px] border-[#7A7A7A] border-opacity-50">
          <div className=" flex py-1">
            <div className="py-3">
              <img className="w-5 h-5" src="/Assets/Images/Group 175.svg" />
            </div>
            <div className="px-3">
              <span
                className="text-[13px] font-bold
                                    text-[#7A7A7A]"
              >
                View Messages
              </span>
              <span className="inline-flex justify-center items-center font-bold ml-2 p-1 h-5 w-5 text-[11px] md:text-[11px] lg:text-[14px] text-[#000000] bg-[#FDC300] rounded-full">
                {props.data.profilemessage_count}
              </span>
              <p className="text-[11px] md:text-[9px] lg:text-[11px] font-segeo  text-[#9F9F9F]">
                View your Messages
              </p>
            </div>
          </div>
          <div className="text-center mt-3 ">
            <button className=" text-[#FFFFFF] px-4  text-[11px] bg-[#D10A11] p-1  rounded-[5px]">
              Read Now
            </button>
          </div>
        </li>
        <li className="flex justify-between border-b-[1px] border-[#7A7A7A] border-opacity-50">
          <div className=" flex py-1">
            <div className="py-3">
              <img className="h-5 w-5" src="/Assets/Images/Group 176.svg" />
            </div>
            <div className="px-3">
              <span
                className="text-[13px] font-bold
                                    text-[#7A7A7A]"
              >
                Edit Partner Preference
              </span>
              <span className="inline-flex justify-center items-center font-bold ml-2 p-1 h-5 w-5 text-[11px] md:text-[11px] lg:text-[14px] text-[#000000] bg-[#FDC300] rounded-full">
                {props.data.editpreferencecount}
              </span>
              <p className="text-[11px] md:text-[9px] lg:text-[11px] font-segeo  text-[#9F9F9F]">
                For Accurate Matches
              </p>
            </div>
          </div>
          <div className="text-center mt-3 ">
            <button className=" text-[#FFFFFF] px-5 text-[11px] bg-[#D10A11] p-1   rounded-[5px]">
              Edit Now
            </button>
          </div>
        </li>
         <li className="flex justify-between">
          <div className=" flex py-1">
            <div className="py-3">
              <img className="w-5 h-5" src="/Assets/Images/profile1.svg" />
            </div>
            <div className="px-3">
              <span className="text-[13px] font-bold text-[#7A7A7A]">
                Profile Completion
              </span>
              <span className="inline-flex justify-center items-center font-bold ml-2 p-1 h-6 w-6 text-[9px] md:text-[9px] lg:text-[10px] text-[#000000] bg-[#FDC300] rounded-full">
                {props.data.profilecompletationcount}%
              </span>
              <p className="text-[11px] md:text-[9px] lg:text-[11px] font-segeo  text-[#9F9F9F]">
                Your Profile is complete
              </p>
            </div>
          </div>
          <div className="text-center mt-3 ">
            <button className=" text-[#FFFFFF] px-8 text-[11px] bg-[#D10A11]  p-1 rounded-[5px]">
              Edit
            </button>
          </div>
        </li> */}
      </ul>
    </>
  );
};
export const UserDetailsSection = (props) => {
  const navigate = useNavigate();
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  // api/submitparentsnumber
  // api/emptyparentsnumber
  useEffect(() => {
    //console.log(props?.data?.avatar, "avatarcheck1");
  }, [props?.data])

  const { t: trans } = useTranslation();

  return (

    props?.data?.profile_imageurl &&
    <div className="rounded-b-[20px] p-3 md:p-1 md:pt-[0.5rem] lg:pt-[0.75rem] lg:p-3 md:rounder-b-[10px] lg:rounder-b-[20px] bg-[#7A7A7A]">
      {showImagePopup ? (
        <ImageHoroscopePopup
          close={() => setShowImagePopup(false)}
          title={trans('photo_view_popup__password_pro.view_photo')}
          onButtonCallback={() => {
            navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
          }}
          isShowEditBtn={true}
        >
          <img
            alt=""
            src={props?.data?.profile_imageurl}
            className="w-[70vw] lg:w-[50vw] h-[50vh]"
          // style={{ width: "50vw", height: "400px" }}
          />
        </ImageHoroscopePopup>
      ) : (
        <></>
      )}
      {showUploadImagePopup ? (
        <UploadPhotoPopup
          close={() => setShowUploadImagePopup(false)}
          title={trans('my_profile.upload_photo')}
          callBack={() => {

            setsuccessMessage(trans('photos.image_uploaded_successfully'))
            setshowSuccessPopup(true)

          }}
          isFrom={"rightpanel"}
        />
      ) : (
        <></>
      )}
      {showSuccessPopup && (

        <CommonSuccessPopup
          close={() => setshowSuccessPopup(false)}
          title={trans('photos.success')}
          content={successMessage}
        />
      )}
      <div className="flex md:space-x-5 lg:space-x-8">
        <div>
          <span className=" relative inline-block">
            <img
              alt=""
              onClick={() => {
                // setShowImagePopup(true);
                if (props?.data?.avatar === "Y") {
                  navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
                } else {
                  // setShowImagePopup(true);
                  navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath), { state: { tab: "1" } });
                }
              }}
              src={props?.data?.profile_imageurl}
              className="w-24 h-24 lg:w-28 lg:h-28 md:h-18 md:w-18  mx-5 md:mx-3 lg:mx-0 cursor-pointer"
            />
            <span
              onClick={() => {
                setShowUploadImagePopup(true)
                // navigate(RouteSegments.EDITPROFILE, { state: { tab: "3" } });
              }}
              className="absolute top-[0.2rem] lg:top-[0.3rem] right-[0rem] lg:right-[0rem] inline-flex items-center justify-center lg:px-[6px] md:px-1  md:text-[16px] lg:text-[24px]  font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-[#FDC300] rounded-full cursor-pointer"
            >
              +
            </span>
          </span>
          <div className="flex flex-col items-center">
            <p className="text-[#FEBABA] font-segeo font-semibold text-[11px] md:text-[10px]  lg:text-[13px] whitespace-nowrap">
            {trans('my_profile_icon.hi')} {props?.data?.username},
            </p>
            <p className="text-[#FFFFFF] font-bold font-segeo text-[11px] md:text-[11px] lg:text-[12px] xl:text-[13px] ">
              {trans('my_profile_icon.welcome_back')}
            </p>
          </div>
        </div>
        {/* <!-- <div className="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">20</div> --> */}
        <div>
          <div className="mt-4 md:mx-1 lg:mx-2">
            {props?.data?.isPaid == "Y" ? (
              <></>
            ) : (
              <p className=" text-[12px] md:text-[12px] lg:text-[13px]  text-[#E9E9E9]">
                {trans('my_profile_icon.to_contact_matches')}
              </p>
            )}
            <div className="py-1">
              <button
                onClick={() => {
                  if (props?.data?.isPaid == "Y") {
                    navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[6].urlPath), { state: { tab: "7" } });
                  } else {

                    navigate(RouteSegments.MEMBERSHIP_REGISTER())
                  }
                }}
                className="bg-[#FDC300] text-[#FFFFFF] font-bold text-[12px] rounded-[5px]  p-2 px-7"
              >
                {props?.data?.isPaid == "Y" ? "My Plan" : trans('matches.pay_now')}
              </button>
            </div>
            <div className="flex  py-1">
              <img className="w-3 h-3 mt-1" src={ConsultIcon} alt="" />

              <p className=" text-[12px] md:mt-[3px] lg:mt-0 md:text-[11px] lg:text-[12px] px-2 font-bold font-segeo text-[#FFFFFF]">
                {/* {props?.data?.usercountrycode} {props?.data?.userphoneno} */}
                <a href={`tel:+${Constants.helpLinePhNo}`}>{Constants.helpLinePhNo}</a>
              </p>
            </div>
            <div className="flex py-1 ">
              <img className="h-3 w-3 mt-1" src={MessageIcon} alt="" />
              <p className="whitespace-nowrap md:mt-[1px] lg:mt-0 text-[12px] lg:text-[12px] md:text-[11px]  px-2 font-bold font-segeo text-[#FFFFFF]  md:pt-[2px]">
                {/* {props?.data?.usermailid} */}

                <a href={`mailto:${Constants.helpLineMailId}`}>{Constants.helpLineMailId}</a>

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // : <SidePannelProfileSkeleton  cards={1} menu="profile"/>
  );
};

export const SidePanleList = (props) => {
  //console.log(props, "fhgfhgf");
  const navigate = useNavigate()
  const [ProfileStatusMessage, setProfileStatusMessage] = useState("")
  const [showProfileStatusPopup, setShowProfileStatusPopup] = useState(false)
  const { t: trans } = useTranslation();


  return (

    <div>
      {showProfileStatusPopup && (
        <CommonPopup title={trans('my_profile.profile_status')} close={() => setShowProfileStatusPopup(false)} menu="profileStatus">
          <p>{ProfileStatusMessage}</p>
        </CommonPopup>
      )
      }
      {props?.inboxData &&
        props?.inboxData.length > 0 ?
        props?.inboxData.map((element, i, row) => {
          //console.log(element.profileId, "hjhjk");
          if (i <= 9) {
            let viewBtnName = "";
            let profileName = "";
            let profileAge = "";
            let profileHeight = "";
            let profileContent = "";
            let messageType = ""
            let profileimageurl = ""
            if (props?.selectedFilter.selectedType === "MESSAGE") {
              profileName = element.profilename;
              profileAge = element.profileage;
              profileHeight = element.profileheight;
              profileContent = element.messagesamplecontent;
              messageType = element?.msgtype ?? element.messagetype
              profileimageurl = element.profileimageurl
              if (
                messageType === "Sent" ||
                messageType === "Read"
              ) {
                viewBtnName = "View Message";
              } else if (messageType === "ReplyPending") {
                viewBtnName = "Reply";
              } else if (messageType === "Unread") {
                viewBtnName = "Read & Reply";
              } else {
                viewBtnName = "View Message";
              }
            } else if (
              props?.selectedFilter.selectedType === "REQUESTRECEIVED"
            ) {
              profileName = element.name;
              profileAge = element.age;
              profileHeight = element.height;
              profileContent = element.requestContent;
              profileimageurl = element.profilepic
              if (element.requestType === "Photo") {
                viewBtnName = "Add Photo";
              } else if (element.requestType === "Horoscope") {
                viewBtnName = "Add Horoscope";
              } else if (element.requestType === "Trust") {
                viewBtnName = "Add Document";
              } else {
                viewBtnName = "View";
              }
            } else if (props?.selectedFilter.selectedType === "REQUESTSENT") {
              profileName = element.name;
              profileAge = element.age;
              profileHeight = element.height;
              profileContent = element.requestContent;
              profileimageurl = element.profilepic
              if (element.acceptmsgtype === "Y") {
                if (element.requestType === "Photo") {
                  viewBtnName = "Photo Added";
                } else if (element.requestType === "Horoscope") {
                  viewBtnName = "Horoscope Added";
                } else if (element.requestType === "Trust") {
                  viewBtnName = "Document Added";
                } else {
                  viewBtnName = "View";
                }
              } else {
                viewBtnName = "Requested";
              }
            }

            return (
              <>
                <ViewMessageCards
                  showList={props?.showList}
                  key1={i}
                  key={i}
                  cardData={element}
                  profileimageurl={profileimageurl}
                  profileName={profileName}
                  profileAge={profileAge}
                  profileHeight={profileHeight}
                  profileContent={profileContent}
                  viewBtnName={viewBtnName}
                  selectedFilter={props?.selectedFilter.selectedType === "MESSAGE"}
                  onClickViewBtn={(e) => props?.onClickViewBtn(i, e, element)}
                  onClickViewProfile={() => { navigate(RouteHelper.getProfileUrl, { state: { id: element.profileId } }) }}
                  lengthOfRow={row}
                  isFrom={props.isFrom}
                  viewProfileStatusPopup={(value) => {
                    setProfileStatusMessage(value);
                    setShowProfileStatusPopup(true)
                  }}
                />
              </>
            );
          }
        })
        :
        <div className="flex justify-center mt-20 text-[16px] text-[#7A7A7A] font-semibold">{trans('registration.no_data')}</div>
      }
    </div>
  )
}

export default ProfileSection;