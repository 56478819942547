import { Popover, Tooltip } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import { debounceFn } from '../Kalyanlite/utils';

const OverflowToolTip = ({ as: Tag = 'div', children, placement = "topLeft", ...props }) => {
    const textElementRef = useRef(null);
    const [isOverflow, setIsOverflow] = useState(false);



    /**
     * The `compareSize` function checks if the scroll width of a text element is greater than its client
     * width and sets a state based on the comparison.
     */
    const compareSize = () => {
        const compare =
            textElementRef?.current?.scrollWidth > textElementRef?.current?.clientWidth;
        console.log("compareSize", { compare, scrollwindt: textElementRef?.current?.scrollWidth, "client": textElementRef?.current?.clientWidth });

        setIsOverflow(compare);
    };

    useEffect(() => {
        compareSize();
    }, [textElementRef, props])

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
        return () => {
            window.removeEventListener('resize', compareSize)
        }
    }, []);




    useEffect(() => {
        const element = textElementRef?.current;

        if (!element) return;

        const observer = new ResizeObserver(debounceFn(compareSize, 500));

        observer.observe(element);
        return () => {
            observer?.disconnect();
        };
    }, [])


    return <Tooltip overlayClassName="font-Poppins-Regular" trigger={['hover', 'contextMenu']} placement={placement} title={isOverflow ? children : undefined}>
        <Tag ref={textElementRef} {...props}>{children}</Tag>
    </Tooltip>
};

export default OverflowToolTip;