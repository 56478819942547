import axios from "axios";

export const post_method = (url, data) => {
       return axios.post(url, data);
}

export const get_method = (url) => {
       return axios.get(`${url}`);
};


export const cms_post_method = (url, data) => {
       return axios.post(url, data?.data, { headers: data?.headers });
}
