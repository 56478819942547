import * as actionTypes from "./ActionTypes"

export const getConversationList = (payload) => {
    return {
        type: actionTypes.GET_CONVERSATION,
        payload,
    };
};

export const getConversationSuccess = (payload) => {
    return {
        type: actionTypes.GET_CONVERSATION_SUCCESS,
        payload,
    };
};

export const getConversationFail = (payload) => {
    return {
        type: actionTypes.GET_CONVERSATION_FAIL,
        payload,
    };
};

//more conversation
export const getMoreConversation = payload => ({ type: actionTypes.GET_MORE_CONVERSATION, payload })
export const getMoreConversationSuccess = payload => ({ type: actionTypes.GET_MORE_CONVERSATION_SUCCESS, payload })
export const getMoreConversationFail = payload => ({ type: actionTypes.GET_MORE_CONVERSATION_FAIL, payload })

//more conversation
export const getConversationByPage = payload => ({ type: actionTypes.GET_CONVERSATION_BY_PAGE, payload })
export const getConversationByPageSuccess = payload => ({ type: actionTypes.GET_CONVERSATION_BY_PAGE_SUCCESS, payload })
export const getConversationByPageFail = payload => ({ type: actionTypes.GET_CONVERSATION_BY_PAGE_FAIL, payload })

export const toggleMuteConversations = payload => ({ type: actionTypes.TOGGLE_MUTE_CONVERSATIONS, payload })


export const sendMessage = (payload) => {
    return {
        type: actionTypes.SEND_MESSAGE,
        payload,
    };
}

export const sendMessageSuccess = (payload) => {
    return {
        type: actionTypes.SEND_MESSAGE_SUCCESS,
        payload,
    };
}

export const sendMessageFail = (payload) => {
    return {
        type: actionTypes.SEND_MESSAGE_FAIL,
        payload,
    };
}

// profile Request send message 
export const profileSendMessage = (payload) => {
    return {
        type: actionTypes.PROFILE_SEND_MESSAGE,
        payload,
    };
}

export const profileSendMessageSuccess = (payload) => {
    return {
        type: actionTypes.PROFILE_SEND_MESSAGE_SUCCESS,
        payload,
    };
}

export const profileSendMessageFail = (payload) => {
    return {
        type: actionTypes.PROFILE_SEND_MESSAGE_FAIL,
        payload,
    };
}

export const getChatList = (payload) => {
    return {
        type: actionTypes.GET_CHAT_LIST,
        payload,
    };
};

export const getChatListSuccess = (payload) => {
    return {
        type: actionTypes.GET_CHAT_LIST_SUCCESS,
        payload,
    };
};

export const getChatListFail = (payload) => {
    return {
        type: actionTypes.GET_CHAT_LIST_FAIL,
        payload,
    };
};

//search bulk request
export const getBulkRequest = payload => ({ type: actionTypes.GET_BULK_REQUEST, payload })
export const getBulkRequestSuccess = payload => ({ type: actionTypes.GET_BULK_REQUEST_SUCCESS, payload })
export const getBulkRequestFail = payload => ({ type: actionTypes.GET_BULK_REQUEST_FAIL, payload })



//search chat list
export const searchChatList = payload => ({ type: actionTypes.SEARCH_CHAT_LIST, payload })
export const searchChatListSuccess = payload => ({ type: actionTypes.SEARCH_CHAT_LIST_SUCCESS, payload })
export const searchChatListFail = payload => ({ type: actionTypes.SEARCH_CHAT_LIST_FAIL, payload })



// chat media
export const getChatMedia = payload => ({ type: actionTypes.GET_CHAT_MEDIA, payload })
export const getChatMediaSuccess = payload => ({ type: actionTypes.GET_CHAT_MEDIA_SUCCESS, payload })
export const getChatMediaFail = payload => ({ type: actionTypes.GET_CHAT_MEDIA_FAIL, payload })

// chat media
export const getMoreChatMedia = payload => ({ type: actionTypes.GET_MORE_CHAT_MEDIA, payload })
export const getMoreChatMediaSuccess = payload => ({ type: actionTypes.GET_MORE_CHAT_MEDIA_SUCCESS, payload })
export const getMoreChatMediaFail = payload => ({ type: actionTypes.GET_MORE_CHAT_MEDIA_FAIL, payload })


export const setCurrentChat = (receiverId,conversationId) => {
    return {
        type: actionTypes.SET_CURRENT_CHAT,
        payload: {
            receiverId,
            conversationId,
        }
    }
}

export const closeCurrentChat = (receiverId, conversationId) => {
    return {
        type: actionTypes.CLOSE_CURRENT_CHAT,
        payload: {
            receiverId,
            conversationId,
        }
    }
}


export const addSelectedChat = (chatId, conversationId) => {
    return {
        type: actionTypes.ADD_SELECTED_CHAT,
        payload: {
            chatId,
            conversationId,
        }
    }
}

export const removeSelectedChat = (chatId, conversationId) => {
    return {
        type: actionTypes.REMOVE_SELECTED_CHAT,
        payload: {
            chatId,
            conversationId,
        }
    }
}

export const removeAllSelectedChat = (conversationId) => {
    return {
        type: actionTypes.REMOVE_SELECTED_CHAT,
        payload: {
            conversationId
        }
    }
}

export const toggleMessageSelection = (conversationId) => {
    return {
        type: actionTypes.TOGGLE_MESSAGE_SELECTION,
        payload: {
            conversationId
        }
    }
}

export const deleteMessage = payload => ({ type: actionTypes.DELETE_CHAT, payload })

export const messageReceived = payload => ({ type: actionTypes.MESSAGE_RECEIVED, payload })

export const groupMessageReceived = payload => ({ type: actionTypes.GROUP_MESSAGE_RECEIVED, payload })

export const starMessage = payload => ({ type: actionTypes.STAR_MESSAGE, payload })

export const starMessageSuccess = payload => ({ type: actionTypes.STAR_MESSAGE_SUCCESS, payload })

export const starMessageFail = payload => ({ type: actionTypes.STAR_MESSAGE_FAIL, payload })

export const offlineprofile = payload => ({ type: actionTypes.OFFLINE_PROFILE, payload })

export const offlineprofileSuccess = payload => ({ type: actionTypes.OFFLINE_PROFILE_SUCCESS, payload })

export const offlineprofileFail = payload => ({ type: actionTypes.OFFLINE_PROFILE_FAIL, payload })

export const conversationStatus = (receiver_id) => {
    return { 
        type: actionTypes.GET_CONVERSATION_STATUS, 
        payload:{ 
            receiver_id
        } 
    }
}
export const conversationStatusSuccess = payload => ({ type: actionTypes.GET_CONVERSATION_STATUS_SUCCESS, payload })
export const conversationStatusFail = payload => ({ type: actionTypes.GET_CONVERSATION_STATUS_FAIL, payload })



/**
 * The function logs a document view with the specified profile ID.
 */
export const logDocumentView = toProfileId => ({ type: actionTypes.LOG_DOCUMENT_VIEW, payload: { toProfileId } })


export const resetUnreadCount = ({
    conversationId, receiverId, receiver_status, isChatOpened
}) => ({
    type: actionTypes.RESET_UNREAD_COUNT,
    payload: { conversationId, receiverId, receiver_status, isChatOpened }
})

export const resetMsgUnreadCount = ({
    conversationId, receiverId, receiver_status, isChatOpened
}) => ({
    type: actionTypes.RESET_MSG_UNREAD_COUNT,
    payload: { conversationId, receiverId, receiver_status, isChatOpened }
})
export const resetUnreadMsgCount = ({
    conversationId, receiverId, receiver_status, isChatOpened
}) => ({
    type: actionTypes.RESET_UNREAD_MSG_COUNT,
    payload: { conversationId, receiverId, receiver_status, isChatOpened }
})
export const getFamilyChatDetail = (payload) => {
    return {
        type: actionTypes.GET_FAMILY_CHAT_DETAIL,
        payload,
    };
};

export const getFamilyChatDetailSuccess = (payload) => {
    return {
        type: actionTypes.GET_FAMILY_CHAT_DETAIL_SUCCESS,
        payload,
    };
};

export const getFamilyChatDetailFail = (payload) => {
    return {
        type: actionTypes.GET_FAMILY_CHAT_DETAIL_FAIL,
        payload,
    };
};

export const addReactions = (payload) => {
    return {
        type: actionTypes.REACTIONS_ADD,
        payload,
    };
};

export const addReactionsSuccess = (payload) => {
    return {
        type: actionTypes.REACTIONS_ADD_SUCCESS,
        payload,
    };
};

export const addReactionsFail = (payload) => {
    return {
        type: actionTypes.REACTIONS_ADD_FAIL,
        payload,
    };
};

export const reactionReceived = (payload) => {
    return {
        type: actionTypes.REACTIONS_RECEIVED,
        payload,
    };
};




//group conversation
export const getGroupConversation = payload => ({ type: actionTypes.GET_GROUP_CONVERSATION, payload })
export const getGroupConversationSuccess = payload => ({ type: actionTypes.GET_GROUP_CONVERSATION_SUCCESS, payload })
export const getGroupConversationFail = payload => ({ type: actionTypes.GET_GROUP_CONVERSATION_FAIL, payload })


export const getMoreGroupConversation = payload => ({ type: actionTypes.GET_GROUP_MORE_CONVERSATION, payload })
export const getMoreGroupConversationSuccess = payload => ({ type: actionTypes.GET_GROUP_MORE_CONVERSATION_SUCCESS, payload })
export const getMoreGroupConversationFail = payload => ({ type: actionTypes.GET_GROUP_MORE_CONVERSATION_FAIL, payload })



export const sendGroupMessage = payload => ({ type: actionTypes.SEND_GROUP_MESSAGE, payload })
export const sendGroupMessageSuccess = payload => ({ type: actionTypes.SEND_GROUP_MESSAGE_SUCCESS, payload })
export const sendGroupMessageFail = payload => ({ type: actionTypes.SEND_GROUP_MESSAGE_FAIL, payload })

// export const getFamilyChatList = (payload) => {
//     return {
//         type: actionTypes.GET_FAMILY_CHAT_LIST,
//         payload,
//     };
// };

// export const getFamilyChatListSuccess = (payload) => {
//     return {
//         type: actionTypes.GET_FAMILY_CHAT_LIIST_SUCCESS,
//         payload,
//     };
// };

// export const getFamilyChatListFail = (payload) => {
//     return {
//         type: actionTypes.GET_FAMILY_CHAT_LIST_FAIL,
//         payload,
//     };
// };

export const getGroupChatList = payload => ({ type: actionTypes.GET_GROUP_CHAT_LIST, payload })
export const getGroupChatListSuccess = payload => ({ type: actionTypes.GET_GROUP_CHAT_LIST_SUCCESS, payload })
export const getGroupChatListFail = payload => ({ type: actionTypes.GET_GROUP_CHAT_LIST_FAIL, payload })
export const deleteGroupMessage = payload => ({ type: actionTypes.DELETE_GROUP_MESSAGE, payload });

// Group starred message
export const groupStarMessage = payload => ({ type: actionTypes.GROUP_STAR_MESSAGE, payload })

export const groupStarMessageSuccess = payload => ({ type: actionTypes.GROUP_STAR_MESSAGE_SUCCESS, payload })

export const groupStarMessageFail = payload => ({ type: actionTypes.GROUP_STAR_MESSAGE_FAIL, payload })



export const readGroupMessage = payload => ({ type: actionTypes.READ_GROUP_MESSAGE, payload })
export const readGroupMessageSuccess = payload => ({ type: actionTypes.READ_GROUP_MESSAGE_SUCCESS, payload })


export const readbyReceived = payload => ({ type: actionTypes.READ_BY_RECEIVED, payload })


// customer Service conversation
export const getCustomerServiceConversation = payload => ({ type: actionTypes.GET_CUSTOMER_SERVICE_CONVERSATION, payload })
export const getCustomerServiceConversationSuccess = payload => ({ type: actionTypes.GET_CUSTOMER_SERVICE_CONVERSATION_SUCCESS, payload })
export const getCustomerServiceConversationFail = payload => ({ type: actionTypes.GET_CUSTOMER_SERVICE_CONVERSATION_FAIL, payload })

export const getMoreCustomerServiceConversation = payload => ({ type: actionTypes.GET_CUSTOMER_SERVICE_MORE_CONVERSATION, payload })
export const getMoreCustomerServiceConversationSuccess = payload => ({ type: actionTypes.GET_CUSTOMER_SERVICE_MORE_CONVERSATION_SUCCESS, payload })
export const getMoreCustomerServiceConversationFail = payload => ({ type: actionTypes.GET_CUSTOMER_SERVICE_MORE_CONVERSATION_FAIL, payload })

export const sendCustomerServiceMessage = payload => ({ type: actionTypes.SEND_CUSTOMER_SERVICE_MESSAGE, payload })
export const sendCustomerServiceMessageSuccess = payload => ({ type: actionTypes.SEND_CUSTOMER_SERVICE_MESSAGE_SUCCESS, payload })
export const sendCustomerServiceMessageFail = payload => ({ type: actionTypes.SEND_CUSTOMER_SERVICE_MESSAGE_FAIL, payload })

export const UnreadMsgCount = payload => ({ type: actionTypes.CHAT_UNREAD_MSG_COUNT, payload })
export const UnreadMsgCountSuccess = payload => ({ type: actionTypes.CHAT_UNREAD_MSG_COUNT_SUCCESS, payload })
export const UnreadMsgCountFail = payload => ({ type: actionTypes.CHAT_UNREAD_MSG_COUNT_FAIL, payload })

export const RankingPopup = payload => ({ type: actionTypes.RANKING_POPUP, payload })
export const RankingPopupSuccess = payload => ({ type: actionTypes.RANKING_POPUP_SUCCESS, payload })
export const RankingPopupFail = payload => ({ type: actionTypes.RANKING_POPUP_FAIL, payload })

export const ResetUnReadCount = payload => ({ type: actionTypes.RESET_UN_READ_COUNT, payload })
export const ResetUnReadCountSuccess = payload => ({ type: actionTypes.RESET_UN_READ_COUNT_SUCCESS, payload })
export const ResetUnReadCountFail = payload => ({ type: actionTypes.RESET_UN_READ_COUNT_FAIL, payload })

// set family code
export const setFamilyCode = payload => ({ type: actionTypes.FAMILY_SET_FAMILY_CODE, payload })
export const setFamilyCodeSuccess = payload => ({ type: actionTypes.FAMILY_SET_FAMILY_CODE_SUCCESS, payload })
export const setFamilyCodeFail = payload => ({ type: actionTypes.FAMILY_SET_FAMILY_CODE_FAIL, payload })