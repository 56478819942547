import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import RouteSegments, { RouteHelper } from "../../Routes/RouteSegment";
import Constants, { InboxFilters } from "../../Constants/constants";
import RedChatIcon from "../../Images/Group 228.svg"
import GreyMsgIcon from "../../Images/Group 1651.svg"
import XIcon from "../../Images/Group 761@2x.png"
import OnlineIcon from "../../Images/Group 619.svg"
import LockIcon from "../../Images/lockicon.svg"
import { dateFormat } from "../../Utils";
import { useTranslation } from "react-i18next";

const Modal = (props) => {
  
  const navigate = useNavigate()
   //console.log(props?.data?.ispaid,"paid")
   const { t: trans } = useTranslation();

  useEffect(() => {
    
  }, [props.cardData, props?.data]);

  //console.log(props?.data?.ispaid,"padi")
  const [showModal, setShowModal] = useState(false);
  const [convoshowModal, setConvoShowModal] = useState(false);
  // if (convoshowModal===false) {
  //   setShowModal(false)
  // }
   
  return ( 
    <>
      <>
        {props.menu === "web" ? (
          <>
           {props.convocount === "0" ? "":
           <div className="flex items-center">    <button className="flex" 
            onClick={() => {props.requestcount === "0"?  setConvoShowModal(true) : setShowModal(true)} }>
              <button
                type="button"
                className="inline-flex relative items-center  text-center text-white "
              >
                <img className="h-5 w-5" src={RedChatIcon} alt="" />
                <div className="inline-flex absolute -top-[0.65rem] -right-2 justify-center w-4 h-4 items-center  border border-[#FFFFFF] text-[10px] font-bold text-white bg-[#FDC300] rounded-full">
                  {props.convocount}
                </div>
              </button>
              <span
                className=" pl-2 text-[12px]
                                       text-[#D10A11] font-segeo"
              >
                Conversation
              </span>
            </button>
            </div>  
              }
             
          </>
        ) : props.menu === "tab" ? (
          <>
          {props.convocount === "0" ? "":
            <button
              onClick={() => {props.requestcount === "0"?  setConvoShowModal(true) : setShowModal(true)} }
              className="flex col-span-1 justify-around"
            >
              <button
                type="button"
                className="inline-flex relative items-center  text-center text-white "
              >
                <img className="w-4" src={RedChatIcon} alt="" />
                <div className="inline-flex absolute -top-2 lg:-top-2  md:-top-[0.7rem] -right-2 justify-center w-4 h-4 items-center  border border-[#FFFFFF] text-[10px] font-bold text-white bg-[#FDC300] rounded-full">
                  {props.convocount}
                </div>
              </button>
              <span
                className=" text-[11px] lg:pl-1 w-full pl-1 md:pl-2 text-[#D10A11] font-segeo"
              >
                {" "}
                Conversation
              </span>
            </button>
     }
             
          </>
        ) : props.menu === "mobile"?(
          <>
          {props.convocount === "0" ? "":
          <div className="flex" 
          onClick={() => {props.requestcount === "0"?  setConvoShowModal(true) : setShowModal(true)} }
          >
                        <button
                          type="button" className="inline-flex relative items-center  text-center text-white ">
                           <img className="h-4 w-4" src={RedChatIcon}alt=""/>                                 
                           <div className="inline-flex absolute -top-[0.5rem] md:-top-1 -right-2 justify-center w-4 h-4 items-center  border border-[#FFFFFF] text-[10px] font-bold text-white bg-[#FDC300] rounded-full">
                             {props.convocount}
                             </div>
                        </button>
                        <span className="px-3 text-[8pt] md:text-[12px] text-[#D10A11] font-semibold  font-segeo"> Conversation </span>
                     </div>
        }
        </>

        ) : " "}
      </>
      {!convoshowModal ?
      showModal ? (
        <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto ">
            {/* <div className="relative inline-block"> */}
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-[22rem] md:w-[30rem]  rounded-2xl ">
              <div className="  items-start">
                <p className="text-[#D10A11] flex justify-center font-bold text-[21px] md:text-[24px] lg:text-[26px] ">
                  Conversation Type
                </p>
                <div
                  style={{ borderWidth: "2px" }}
                  className="  border-t-[#D10A11] border-dashed border-[#FFFFFF]  md:block md:col-span-5 lg:col-span-5 mx-[0.2rem] md:mx-0 md:ml-[0.7rem] lg:mx-[2.5rem] mt-[1rem] py-[1.5rem] "
                >
                  <div>
                    <div className="mx-[2rem]  ">
                      <div
                        onClick={() => setConvoShowModal(true)} className=" cursor-pointer flex space-x-3 pb-[1rem] "
                        style={{ borderBottom: "1px solid" }}
                      >
                        <div className=" cursor-pointer flex justify-center items-center ">
                          <img
                            className="w-[2rem]"
                            src={GreyMsgIcon}
                            alt=""
                          />
                        </div>
                        <p className=" text-[14px] justify-center items-center flex text-[#575556] font-semibold  ">
                          Message
                          <button>
                            <div
                              className=" absolute inline-flex items-center justify-center w-6 h-6 left-[11rem] lg:left-[12.6rem] top-[6rem] lg:top-[5.6rem] md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px] font-bold text-white bg-[#D10A11] rounded-full"
                            >
                              {props.convocount}
                            </div>
                          </button>
                        </p>
                      </div>
                      <div className=" cursor-pointer flex space-x-3 mt-[1rem] ">
                        <div className="flex justify-center items-center ">
                          <img
                            className="w-[2rem]"
                            src={GreyMsgIcon}
                            alt=""
                          />
                        </div>
                        <button
                        onClick={() => {
                          navigate(RouteHelper.getInboxByTab(InboxFilters.requestReceived[0].urlPath));
                        } }
                         className=" text-[14px] justify-center items-center flex text-[#575556] font-semibold  ">
                          {trans('view_profile.request')}
                          <button>
                            <div
                              className="
                      absolute inline-flex items-center left-[11rem] lg:left-[12.6rem] top-[9.2rem] lg:top-[9.5rem] justify-center w-6 h-6  md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px] font-bold text-white bg-[#D10A11] rounded-full"
                            >
                              {props.requestcount}
                            </div>
                          </button>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() => setShowModal(false)}
              className="
                    absolute top-[0rem] right-[0rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2"
            >
              <img
                className="w-[2rem]"
                src={XIcon}
                alt=""
              />
            </button>
          </div>
        </div>
      ) : (
        ""
      ) : " "
    }
      <>
      {convoshowModal ? (
        <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto ">
           
            <div className="bg-white rounded-[1rem] w-[22rem] md:w-auto ">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-[1rem] sm:pb-4 md:w-[34rem] lg:w-[40rem] xl:w-[45rem]  rounded-2xl">
              <div className="  items-start ">
                <p className="text-[#D10A11] flex justify-center font-bold text-[21px] md:text-[24px] lg:text-[26px] ">
                  Conversation
                </p>
                <div
                    style={{ borderWidth: "2px" }}
                  className="  border-t-[#D10A11] border-dashed border-[#FFFFFF]  md:block md:col-span-5 lg:col-span-5  mt-[1rem] pt-[1.5rem] "
                >
                  <div>
                    <div className="mx-[1rem]  ">
                      <div
                         className=" cursor-pointer flex space-x-3 pb-[1rem] "
                         
                      >
                        <div className=" cursor-pointer relative flex justify-center items-center ">
                          <img
                           src={props?.cardData?.profileurl}
          alt=""
          className={`${
            props?.cardData?.profileblurPhoto === "Y" ? "blur-[2px]  " :  " "
          } w-[4rem] rounded-full `}
                            
                            
                           
                          /> 
                          {props?.cardData?.profilefromPasswordProtected === "Y" && (
                  <div
                  className="absolute  top-[-0.4rem] right-[0.1rem] md:right-[0rem] lg:right-[0rem] flex justify-center items-center h-[100%] w-[100%]  cursor-pointer"
                  > 
                <img
                  className="h-[2rem] w-[2rem] "
                  src={LockIcon}
                  alt="Person"
                />
                  </div>
                 )}
                        </div>
                          <div>
                             {props.cardData.profilelastOnline === "Online Now" ? (
              <div className="flex ">
                <img
                  className=" w-3 h-3"
                  src={OnlineIcon}
                  alt=""
                />
                <span
                  className=" px-2 text-[10px]
                                          text-[#575556]"
                >
                  Online Chat Now
                </span>
              </div>
            ) : (
              <span className="md:flex  text-[#039511] font-segeo font-semibold text-[10px] px-1">
                Last Online: {dateFormat(props?.cardData?.profilelastOnline)}
              </span>
            )}
                       
                             <p className=" text-[#575556] md:text-[14px] lg:text-[17px] py-1 text-[10px]  font-bold ">
                              {props.cardData.profilename} | <span className="text-[#7A7A7A]  text-[12px] lg:text-[14px] "> {props.cardData.smProfileId}  </span> 
                              
                             </p>
                             <p className="text-[#7A7A7A]  text-[12px] lg:text-[14px] ">
                               {props.cardData.profileage} |  {props.cardData.profileheight} | {props.cardData.profilequalification} | {props.cardData.profilenativelanguage} | {props.cardData.profilecountry}</p>
                          </div>
                      </div>
                      {(localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                        "Your profile is hidden"
                        :
                      props.cardData?.profileStatus == "open"?
                      <>
                       <p className="text-[#7A7A7A]  text-[12px] lg:text-[14px] ">
                        {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore */}
                        {props.cardData.message}
                       </p>
                       <div className="flex justify-center items-center ">
                       <button
                        onClick={()=>navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), { state: { profileId: props?.cardData?.profileid, threadId:props?.cardData?.messageSent[0].subjectId, type:props?.type } })}
                        className=" bg-[#D10A11]  px-[1rem] mt-[1rem] py-[1rem] md:px-[1.5rem] lg:px-[2rem] md:text-[12px] lg:text-[14px] font-bold rounded-[5px] h-[31px] md:h-[27px] lg:h-[38px] flex justify-center items-center text-[#FFFFFF] ">
                         Reply
                       </button>
                       </div>
                       </>
                       :
                       props.cardData?.profileStatus== "hidden"?
                      "profile has been hidden"
                      :props.cardData?.profileStatus== "inactive"?
                      "profile has been inactive"
                      :props.cardData?.profileStatus== "delete"?
                      "profile has been deleted"
                      :props.cardData?.profileStatus== "suspension"?
                      "profile has been suspended"
                      :""
                        }
                    </div>
                  </div>
                </div>
              </div>
             
              </div>
              {props.data.isPaid === "Y"? ""
              :
              (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) != "hidden" &&
              <div className="bg-[#F18181] p-[0.4rem] md:p-[1rem] ">
              <div className="flex space-x-5 justify-center items-center">
              <p className="text-white text-[14px] flex items-center justify-center  ">
                Buy a Phone Pack to Contact Instantly
              </p>
              <button
              onClick={() => {
                            navigate(RouteSegments.MEMBERSHIP_REGISTER())
                  }}
               className="
               bg-[#FFFFFF]   px-[1rem] py-[1rem] md:px-[0.5rem] lg:px-[1rem] md:text-[12px] lg:text-[14px] font-bold rounded-[5px] h-[31px] md:h-[27px] lg:h-[38px] flex justify-center items-center text-[#D10A11] text-[11px] " >
                {trans('matches.pay_now')}
              </button>
              </div>
              </div>
              
}
               
            </div>
            
            <button              
              onClick={() => setConvoShowModal(false)
              }
              className="
                    absolute top-[0rem] right-[0rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2"
            >
              <img
              onClick={()=> setShowModal(false)}
                className="w-[2rem]"
                src={XIcon}
                alt=""
              />
            </button>
          </div>
        </div>
      ) : (
        " "
      )

      }
      </>
    </>
  );
};

export default Modal;
