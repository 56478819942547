
import React from "react"
import CloseIcon from "../assets/Images/cancelicon.svg";
import successicon from "../assets/Images/Group 3560.svg"
import { useTranslation } from 'react-i18next';

const SuccessPopup = ({ closePopup, menu }) => {

    const { t: trans } = useTranslation();

    return (
        <div>


            <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-6">
                    <div className="">
                        <button
                            className=' bg-transparent z-40 border-0 text-black  absolute top-0 right-[-1px]'
                            onClick={closePopup}

                        >
                            <img className='h-6 w-6 ' src={CloseIcon} alt='' />
                        </button>
                    </div>
                    <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col lg:w-[30rem] lg:h-[12rem] bg-white outline-none focus:outline-none">

                        <div className="relative p-6 flex-auto text-center items-center justify-center mt-6">
                            <div className="space-y-2">
                                <p className="text-[#D10A11] font-bold font-Poppins text-[18px]">
                                    {trans('family_invite_success_popup.success')}
                                </p>
                                {menu == "grouppage" ?
                                    <p className="text-[#777777] font-regular font-Poppins text-[14px] text-center">{trans('family_invite_success_popup.invite_sent_successfully')}</p>
                                    :
                                    menu == "profileSelect" ?
                                        <p className="text-[#777777] font-regular font-Poppins text-[14px] text-center">
                                            {trans('success_popup.profile_is_selected_and_shared')}
                                        </p>
                                        :
                                        <p className="text-[#777777] font-regular font-Poppins text-[14px] text-center">
                                            {trans('success_popup.request_sent_successfully')}
                                        </p>


                                }
                            </div>
                            <div className="flex py-4 justify-center">
                                <img src={successicon} className="w-8 h-8" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default SuccessPopup;

