import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import EndPoints from "../../Constants/EndPoints";
import Constants from "../../Constants/constants";
import { LoginContext } from "../../Contexts/LoginContext";
import { NavBarContext } from "../../Contexts/NavBarContext";
import LoaderGif from "../../Gif/loader.gif";
import professionalIcon from '../../Images/Group 930@2x.png';
import PlusIcon from "../../Images/Path 3979.svg";
import { RouteHelper } from "../../Routes/RouteSegment";
import { GET, POST } from "../../Services/api_services";
import config from "../../config";
import { CommonPopup } from "../CommonPopup";
import { MultiSelectView } from "../Edit Profile/PartnerPreference/PartnerPreferenceUtils";
import Loader from "../Loader";
import { ProfileAccordianComponent } from '../ViewMessageComponents/AccordianComponents';
import EducationComponent from "./educationComponent";
import OccupationComponent from "./occupationComponent";
import { useTranslation } from "react-i18next";

const AdvanceSearch = (props) => {
    const { loginDetail, logout } = useContext(LoginContext);
    const { setMenuSelect ,closeFilter} = useContext(NavBarContext);

    const [isLoading, setLoading] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(false)
    const [advanceSearch, setAdvanceSearch] = useState([])
    const [stateDropDown, setstateDropDown] = useState([]);
  const [cityDropDown, setcityDropDown] = useState([]);
  const [raasiDropDown, setRaasiDropDown] = useState([])
   const [showLoader, setShowLoader] = useState(false)
   const [castDropDown, setCastDropDown] = useState([]);
   const [subCasteDropDown, setSubCastDropDown] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [ageFromValue, setAgeFromValue] = useState([])
  const [loader, setLoader] = useState(false)
  const [filterCheckData, setFilterCheckData] = useState("")
  const [showFilterCheckPopup, setShowFilterCheckPopup] = useState(false)
  const [filterOnchange, setFilterOnchange] = useState(false)
  const [ageError, setAgeError] = useState(false)
  const [heightError, setHeightError] = useState(false)
  const [educationError, setEducationError] = useState(false)
  const [occupationError, setOccupationError] = useState(false)
  const [isPPEdited, setIsPPEdited] = useState("no")


  const [editPreference, setEditPreference] = useState({
    email: loginDetail()[0],
    userId: loginDetail()[1],
    ppfromsearch: "yes"
  });

  console.log(editPreference,"editPreferenceeditPreference");

  const navigate =useNavigate()
  const { t: trans } = useTranslation();
   /// pagenumber
  const PageNumValue =  {
    pageNumber:1,
  }
  const [pageNumber, setPageNumber] = useState(PageNumValue)

    /// age
  // const ageFromValue = [];
  const [ageToValue, setAgeToValue] = useState([]);


  useEffect(() => {
    for (let i = 18; i <= 70; i++) {
      setAgeFromValue(oldvalue => [...oldvalue, i]);
      setAgeToValue(oldvalue => [...oldvalue, i]);
    }
  },[])

  const setAge = (from) => {
    let temp = [];
    for (let i = from; i <= 70; i++) {
      temp.push(i);
    }
    setAgeToValue(temp);
  };


     const apicalladvancesearch = async () => {
    setLoading(true);
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.advanceSearchUrl()}`,
      request
    );

    if (statusCode === 200) {
      setAdvanceSearch(data.data);
      setLoader(true)
    } else if (statusCode === 401) {
      logout();
    }
    setLoading(false);
  };
  

  /// formik
    const formik = useFormik({
    initialValues: {
      maritalStatus: advanceSearch?.maritalStatus
        ? advanceSearch?.maritalStatus
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.name)
        : [],
        physicalStatus: advanceSearch?.physicalStatus
        ? advanceSearch?.physicalStatus
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.name)
        : [],
      ageFrom: advanceSearch?.minage ? advanceSearch?.minage : "",
      ageTo: advanceSearch?.maxage ? advanceSearch?.maxage : "",
      country: advanceSearch?.country
        ? advanceSearch?.country
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      state: advanceSearch?.state
        ? advanceSearch?.state
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      city: advanceSearch?.city
        ? advanceSearch?.city
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      motherTongue: advanceSearch?.motherTongue
        ? advanceSearch?.motherTongue
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      religion: advanceSearch?.religion
        ? advanceSearch?.religion
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
        denomination:advanceSearch?.domain
        ? advanceSearch?.domain
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      caste: advanceSearch?.caste
        ? advanceSearch?.caste
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
        subcaste: advanceSearch?.subCaste
        ? advanceSearch?.subCaste
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      minheight: advanceSearch?.minheightId
        ? advanceSearch?.minheightId
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)[0]
        : [],
      maxheight: advanceSearch?.maxheightId
        ? advanceSearch?.maxheightId
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)[0]
        : [],

      education: advanceSearch?.education
        ? advanceSearch?.education

            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
        occupation: advanceSearch?.occupation
        ? advanceSearch?.occupation

            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      employment: advanceSearch?.employedIn
        ? advanceSearch?.employedIn
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      star: advanceSearch?.star
        ? advanceSearch?.star
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      raasi: advanceSearch?.raasi
        ? advanceSearch?.raasi
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      dosham: advanceSearch?.dosham
        ? advanceSearch?.dosham
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      foodhabits: advanceSearch?.food
        ? advanceSearch?.food
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      smoking: advanceSearch?.smoking
        ? advanceSearch?.smoking
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      drinking: advanceSearch?.drinking
        ? advanceSearch?.drinking
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      withPhoto:"",
      withHoroscope:"",
      shortlisted:"",
      ignored:"on",
      contacted:"on",
      viewed:""
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({}),

    onSubmit: (values) => {
      apiCallSubmitPreference(values);
      setSubmitStatus(true)
       setShowLoader(true)
    },
  });

  const isChristian = formik.values.religion?.includes(Constants.christialId)

  useEffect(() => {
    setAge(formik.values.ageFrom)
    if((Math.abs(formik.values.ageFrom - formik.values.ageTo))<3){
      setAgeError(true)
    }
    else{
      setAgeError(false)
    }
    if((Math.abs(Number(formik.values.minheight- formik.values.maxheight)))<12){
      setHeightError(true)
    }
    else{
      setHeightError(false)
    }
    if(formik.values?.education?.length == 1){
      setEducationError(true)
    }
    else{
      setEducationError(false)
    }
  },[formik.values.ageFrom])
 
     const apiCallSubmitPreference = async (values) => {

      let request = {
         pageno: `${pageNumber.pageNumber}`,
         email: loginDetail()[0] ?? null,
          userId: loginDetail()[1],
          ageTo: values?.ageTo,
          ageFrom: values?.ageFrom,
          heightFrom: values?.minheight,
          heightTo: Number(values?.minheight)>Number(values?.maxheight)?values?.minheight:values?.maxheight,
          marital_status: values?.maritalStatus,
          physicalStatus: values?.physicalStatus,
          mother_tongue: values?.motherTongue,
          educationId: values?.education,
          subcasteId: values?.subcaste,
          casteIdList: values?.caste,
          countryIdList: values?.country,
          religionIdList: values?.religion,
          domainIdList:values?.religion?.includes(Constants.christialId) ? values?.denomination : [], 
          employedInIdList: values?.employment,
          occupationIdList: values?.occupation,
          cityIdList: values?.city,
          stateIdList: values?.state,
          starIdList: values?.star,
          raasiIdList: values?.raasi,
          doshamIdList: values?.dosham,
          drinkingIdList: values?.drinking,
          smokingIdList: values?.smoking,
          foodIdList: values?.foodhabits,
          shortlisted: values?.shortlisted,
          ignored: values?.ignored,
          contacted: values?.contacted,
          viewed: values?.viewed,
          withHoroscope: values?.withHoroscope,
          withPhoto: values?.withPhoto,
          ppedited:isPPEdited
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.searchPageNew()}`,
      request
    );

    if (statusCode === 200) {
      
        setFilteredData(data?.data);

        sessionStorage.setItem("preferenceRequest",JSON.stringify(editPreference))
        props.viewAllCallback()
          navigate(RouteHelper.getSavePartnerPreferenceUrl, 
            { state: {contentData:data?.data?.contentCard, dataSet:data?.data,  data: data?.data.filtermatches, isfrom:"advance_search",request : request },
         })
        setMenuSelect("")
        closeFilter()
      
    } else if (statusCode === 401) {
      logout();
    }
  };
   
      const apiCallCast = async (mothertonge, religion,initial) => {
    // getCastUrl

    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getCastUrl(mothertonge, religion)}`
    );
    if (statusCode === 200) {
      if (data.status == "Success") {
        setCastDropDown(data.data.castes);
         if (initial === false) { 
        const temp = [];
        data?.data?.castes?.forEach(caste => {
          if(formik?.values?.caste?.includes(caste.id)){
            temp.push(caste.id)
          }
        });
        formik.setFieldValue("caste", temp);
        apiCallSubCast(temp,false)
      }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  // getsubcaste  
   const apiCallSubCast = async (castId,initial = false) => { 
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getSubCastUrl(castId)}`
    );
    if (statusCode === 200) {
      if (data.status == "Success") {
        setSubCastDropDown(data.data.subcastes);

        if(initial === false){
          const temp = [];
          data?.data?.subcastes?.forEach(caste => {
            if(formik?.values?.subcaste?.includes(caste.id)){
              temp.push(caste.id)
            }
          });
          formik.setFieldValue("subcaste", temp);
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

   const apiCallState = async (countryID,initial=false) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getStateUrl(countryID)}`
    );
    if (statusCode === 200) {
      if (data.status === "Success") {
        setstateDropDown(data.data.states);
        if (initial === false) {
          const temp = [];
          data?.data?.states?.forEach(state => {
            if (formik?.values?.state?.includes(state.id)) {
              temp.push(state.id)
            }
          });
          formik.setFieldValue("state", temp);
          apiCallCity(temp,initial);
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apiCallCity = async (stateID, initial=false) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getCityUrl(stateID)}`
    );
    if (statusCode === 200) {
      if (data.status === "Success") {
        setcityDropDown(data.data.cities);

        if (initial === false) {
          const temp = [];
          data?.data?.cities?.forEach(city => {
            if (formik?.values?.city?.includes(city.id)) {
              temp.push(city.id)
            }
          });
          console.log("temp-087",temp,formik?.values?.city);
          formik.setFieldValue("city", temp);
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
  }; 

  const apiCallRaasi = async (starId,initial=false) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getRaasiUrl(starId)}`
    );
    if (statusCode === 200) {
      if (data.status === "Success") {
        setRaasiDropDown(data.data.raasi);

       if(initial === false){
        const temp = [];
        data?.data?.raasi?.forEach(caste => {
          if(formik?.values?.raasi?.includes(caste.id)){
            temp.push(caste.id)
          }
        });
        formik.setFieldValue("raasi", temp);
       }
      }
    } else if (statusCode === 401) {
      logout();
    }
  }
      useEffect(() => {

        const initialContryIds = advanceSearch?.country
          ?.filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
        if (initialContryIds != null && initialContryIds?.length > 0) {
          apiCallState(initialContryIds, true);
        }

        const initialStateIds = advanceSearch?.state
          ?.filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
        if (initialStateIds != null && initialStateIds?.length > 0) {
          apiCallCity(initialStateIds, true);
        }

      
     
    const initialStarIds = advanceSearch?.star
    ?.filter((e) => e.selected === "Y")
     .map((e,i) => e.id) 
     if(initialStarIds != null && initialStarIds?.length > 0){
      apiCallRaasi(initialStarIds,true)    
    }

    const initialMotherTongue = advanceSearch?.motherTongue
      ?.filter((e) => e.selected === "Y")
      .map((e, i) => e.id);

    const initialReligion = advanceSearch?.religion
      ?.filter((e) => e.selected === "Y")
      .map((e, i) => e.id);

      if(initialMotherTongue != null && initialMotherTongue?.length > 0 && initialReligion != null && initialReligion?.length > 0){
        apiCallCast(initialMotherTongue, initialReligion,true);   
      }

    

    const initialCaste = advanceSearch?.caste
      ?.filter((e) => e.selected === "Y")
      .map((e, i) => e.id);

      if(initialCaste != null && initialCaste?.length > 0){
        apiCallSubCast(initialCaste,true)
      }

  }, [advanceSearch]);

  useEffect(() => {
    apicalladvancesearch()
  },[]);
  
  useEffect(() => {
  },[formik.values])

  useEffect(()=>{
    if(filterOnchange){
      setShowLoader(true)
      basicSearchCheck(formik.values)
    }
  },[formik.values])

  useEffect(()=>{
    if(filterOnchange){
      if(formik.values?.education?.length == 1){
              setEducationError(true)
            }
            else{
              setEducationError(false)
            }
    }
  },[formik.values.education ])

  useEffect(()=>{
    if(filterOnchange){
      setShowLoader(true)
      if(formik.values?.occupation?.length == 1){
              setOccupationError(true)
            }
            else{
              setOccupationError(false)
            }
    }
  },[formik.values.occupation ])

  const basicSearchCheck = async(values) => {
   
      let request = {
        pageno: `${pageNumber.pageNumber}`,
         email: loginDetail()[0],
          userId: loginDetail()[1],
          ageTo: values?.ageTo,
          ageFrom: values?.ageFrom,
          heightFrom: values?.minheight,
          heightTo: Number(values?.minheight)>Number(values?.maxheight)?values?.minheight:values?.maxheight,
          marital_status: values?.maritalStatus,
          physicalStatus: values?.physicalStatus,
          mother_tongue: values?.motherTongue,
          educationId: values?.education,
          subcasteId: values?.subcaste,
          casteIdList: values?.caste,
          countryIdList: values?.country,
          religionIdList: values?.religion, 
          employedInIdList: values?.employment,
          occupationIdList: values?.occupation,
          cityIdList: values?.city,
          stateIdList: values?.state,
          starIdList: values?.star,
          raasiIdList: values?.raasi,
          doshamIdList: values?.dosham,
          drinkingIdList: values?.drinking,
          smokingIdList: values?.smoking,
          foodIdList: values?.foodhabits,
          shortlisted: values?.shortlisted,
          ignored: values?.ignored,
          contacted: values?.contacted,
          viewed: values?.viewed,
          withHoroscope: values?.withHoroscope,
          withPhoto: values?.withPhoto,
      };
  
      let { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.searchPageResultSet()}`,
        request
      );
  
      if (statusCode === 200) {
        if(data?.data?.criterialessthan15!=""){
        setShowFilterCheckPopup(true)
        setFilterCheckData(data?.data?.criterialessthan15);
        }

        
      } else if (statusCode === 401) {
        logout();
      }
      setFilterOnchange(false)
      setShowLoader(false)
    };
 

  useEffect(() => {}, [ advanceSearch,stateDropDown,cityDropDown,raasiDropDown, filteredData]);

  console.log(advanceSearch,"advance");

  return (
    <>
    {showFilterCheckPopup &&
      <CommonPopup title="Search Criteria Alert" close={() => setShowFilterCheckPopup(false)} menu="profileStatus">
        <div className='flex  flex-col justify-center'>
          <p>{filterCheckData}</p>
          <div className='flex justify-center mt-3'>
            <button className='rounded-[5px] text-[#FFFFFF] bg-[#D10A11] px-[6%] py-[2%] font-bold' onClick={()=>setShowFilterCheckPopup(false)}>OK</button>
          </div>
        </div>
      </CommonPopup>
    }
    {showLoader && <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />}
    {loader?
    <form onSubmit={formik.handleSubmit}>
      <div
                    className=" p-4 rounded-lg bg-gray-50 "
                    x-show="tab === 2"
                  >
                    <div className=" md:grid grid-cols-9 mt-[2rem] ">
                      <div className="col-span-2 flex items-center   ">
                        <p className=" md:text-[12px] lg:text-[14px] flex  items-center text-[#575556] font-semibold  ">
                        {trans('set_your_partner_expectations.age_range')}
                        </p>
                      </div>
                      <div className="  col-span-7 ">
                        <p className="  ">
                          <div className="flex space-x-3 ">
                            <label className=" select font-bold text-[#575556] ">
                                <select
                                  name="ageFrom"
                                  id="ageFrom"
                                  value={formik.values?.ageFrom}
                                  onChange={(e) => {
                                    // add a key/value pair
                                    setIsPPEdited("yes")
                                    formik.handleChange(e);
                                    setAge(e.target.value);
                                    if(e.target.value>formik.values?.ageTo){
                                      setEditPreference({ ...editPreference, ["age_from"]: e.target.value, ["age_to"]: e.target.value });
                                    }
                                    else{
                                      setEditPreference({ ...editPreference, ["age_from"]: e.target.value, ["age_to"]: formik?.values?.ageTo  });
                                    }
                                    setFilterOnchange(true)
                                    if(e.target.value<formik.values?.ageTo){
                                      if((Math.abs(formik.values.ageTo - e.target.value))<3){
                                        setAgeError(true)
                                      }
                                      else{
                                        setAgeError(false)
                                      }
                                    }
                                    else{
                                      setAgeError(true)
                                    }
                                  }}
                                  className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
                                >
                                  {ageFromValue.map((e, i) => (
                                    <option key={i} value={e}>
                                      {e}
                                    </option>
                                  ))}
                                </select>
                            </label>
                            <p className="flex justify-center items-center font-bold text-[#575556] md:text-[12px] lg:text-[16px] ">
                              To
                            </p>
                            <label className=" select font-bold text-[#575556] ">
                               <select
                                  name="ageTo"
                                  id="ageTo"
                                  value={formik.values?.ageTo}
                                  onChange={(e) => {
                                    setEditPreference({ ...editPreference, ["age_from"]: formik.values.ageFrom, ["age_to"]: e.target.value  });
                                    setIsPPEdited("yes")
                                    formik.handleChange(e);
                                    setFilterOnchange(true)
                                    if((Math.abs(formik.values.ageFrom - e.target.value))<3){
                                      setAgeError(true)
                                    }
                                    else{
                                      setAgeError(false)
                                    }
                                  }}
                                  className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
                                >
                                  {ageToValue.map((e, i) => (
                                    <option key={i} value={e}>
                                      {e}
                                    </option>
                                  ))}
                                </select>
                            </label>
                          </div>
                          {ageError ? <span className="text-[red] text-[12px]">{trans('filters.broaden_this_parameter_a_littl')}</span> : ""}
                        </p>
                      </div>
                    </div>

                    <div className=" md:grid grid-cols-9 mt-[1rem] md:mt-[2rem] ">
                      <div className=" col-span-2 flex items-center ">
                        <p className="   md:text-[12px] lg:text-[14px] text-[#575556] font-semibold   ">
                        {trans('view_profile.height')}
                        </p>
                      </div>
                      <div className="  col-span-7 ">
                        <p className="  ">
                          <div className="flex space-x-3 ">
                            <label className=" select font-bold text-[#575556] ">
                               <select
                                  name="minheight"
                                  id="minheight"
                                  onChange={(e) => {
                                    if(e.target.value>formik.values?.maxheight){
                                      setEditPreference({ ...editPreference, ["height_from"]: e.target.value, ["height_to"]: e.target.value });
                                    }
                                    else{
                                      setEditPreference({ ...editPreference, ["height_from"]: e.target.value, ["height_to"]: formik?.values?.maxheight  });
                                    }
                                    setIsPPEdited("yes")
                                    formik.handleChange(e);
                                    setFilterOnchange(true)
                                    if(Number(formik.values.maxheight) > Number(e.target.value)){
                                      if((Math.abs(Number(formik.values.maxheight) - Number(e.target.value)))<12){
                                        setHeightError(true)
                                      }
                                      else{
                                        setHeightError(false)
                                      }
                                    }
                                    else{
                                      setHeightError(true)
                                    }
                                  }}
                                  value={formik.values?.minheight}
                                  className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
                                >
                                  {advanceSearch?.minheightId?.map((e, i) => (
                                    <option key={i} value={e.id}>
                                      {e.name}
                                    </option>
                                  ))}
                                </select>
                            </label>
                            <p className="flex justify-center items-center font-bold text-[#575556] md:text-[12px] lg:text-[16px] ">
                              To
                            </p>
                            <label className=" select font-bold text-[#575556] ">
                               <select
                                  name="maxheight"
                                  id="maxheight"
                                  onChange={(e) => {
                                    setEditPreference({ ...editPreference, ["height_to"]: e.target.value, ["height_from"]: formik.values.minheight });
                                    setIsPPEdited("yes")
                                    formik.handleChange(e);
                                    setFilterOnchange(true)
                                    if((Math.abs((Number(formik.values.minheight)) - e.target.value))<12){
                                      setHeightError(true)
                                    }
                                    else{
                                      setHeightError(false)
                                    }
                                  }}
                                  value={formik.values?.maxheight}
                                  className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
                                >
                                  {advanceSearch?.maxheightId?.map((e, i) => {;
                                  return(
                                    formik.values?.minheight?.length>0 && parseInt(formik.values?.minheight) <= parseInt(e.id) ?
                                    <option key={i} value={e.id}>
                                      {e.name}
                                    </option>
                                    :""
                                  )})}
                                </select>
                            </label>
                          </div>
                          {heightError ? <span className="text-[red] text-[12px]">{trans('filters.broaden_this_parameter_a_littl')}</span> : ""}
                        </p>
                      </div>
                    </div>

                    <MultiSelectView
                        page="search"
                        title={trans('view_profile.marital_status')}
                        inputID={"maritalStatus"}
                        inputName={"maritalStatus"}
                        placeholder={trans('set_your_partner_expectations.any_marital_status')}
                        value={formik.values?.maritalStatus}
                        defalutValue={[formik.values?.maritalStatus]}
                        onChange={(e) => {
                          let maritalStatusId = advanceSearch?.maritalStatus?.filter((data)=>{
                            return Array.from(e).includes(data.name)
                          }).map((data)=>data?.id)
                          setEditPreference({ ...editPreference, ["maritalStatusId"]: maritalStatusId })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("maritalStatus", Array.from(e));
                        }}
                        error={formik.errors.maritalStatus}
                        options={advanceSearch?.maritalStatus}
                      />

                      <MultiSelectView
                        page="search"
                        title={trans('view_profile.physical_status')}
                        inputID={"physicalStatus"}
                        inputName={"physicalStatus"}
                        placeholder={trans('set_your_partner_expectations.any_physical_status')}
                        value={formik.values?.physicalStatus}
                        defalutValue={[formik.values?.physicalStatus]}
                        onChange={(e) => {
                          setEditPreference({ ...editPreference, ["physicalStatusId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("physicalStatus", Array.from(e));
                        }}
                        error={formik.errors.physicalStatus}
                        options={advanceSearch?.physicalStatus}
                      />

                      <MultiSelectView
                        page="search"
                        title={trans('view_profile.mother_tongue')} 
                        inputID={"motherTongue"}
                        inputName={"motherTongue"}
                        placeholder={trans('set_your_partner_expectations.any_mother_tongue')}
                        value={formik.values?.motherTongue}
                        defalutValue={[formik.values?.motherTongue]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["motherTongueId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("motherTongue", Array.from(e));
                          apiCallCast(Array.from(e), formik.values?.religion,false);
                        }} 
                        error={formik.errors.motherTongue}
                        options={advanceSearch?.motherTongue}
                      />
                      <MultiSelectView
                        page="search"
                        title={trans('view_profile.religion')}
                        inputID={"religion"}
                        inputName={"religion"}
                        placeholder={trans('set_your_partner_expectations.any_religion')}
                        value={formik.values?.religion}
                        defalutValue={[formik.values?.religion]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["religionId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("religion", Array.from(e));
                           apiCallCast(formik.values?.motherTongue, Array.from(e),false);
                        }}
                        error={formik.errors.religion}
                        options={advanceSearch?.religion}
                      />
                      {isChristian && <MultiSelectView
                        page="search"
                        title={trans('view_profile.denomination')}
                        inputID={"denomination"}
                        inputName={"denomination"}
                        placeholder={trans('set_your_partner_expectations.any_denomination')}
                        value={formik.values?.denomination}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["denominationId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("denomination", Array.from(e));
                        }}
                        error={formik.errors.denomination}
                        options={advanceSearch?.domain}
                      />}
                      <MultiSelectView
                        page="search"
                        title={trans('view_profile.caste')}
                        inputID={"caste"}
                        inputName={"caste"}
                        placeholder={trans('set_your_partner_expectations.any_caste')}
                        value={formik.values?.caste}
                        defalutValue={[formik.values?.caste]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["casteId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("caste", Array.from(e));
                          apiCallSubCast( Array.from(e))
                          
                        }}
                        error={formik.errors.caste}
                        options={castDropDown}
                      />
                       <MultiSelectView
                        page="search"
                        title={trans('set_your_partner_expectations.subcaste')}
                        inputID={"subcaste"}
                        inputName={"subcaste"}
                        placeholder={trans('set_your_partner_expectations.any_subcaste')}
                        value={formik.values?.subcaste}
                        defalutValue={[formik.values?.subcaste]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["subcasteId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("subcaste", Array.from(e));
                        }}
                        error={formik.errors.subcaste}
                        options={subCasteDropDown}
                      />
                     
                    <div className=" mx-auto mt-8">
                      <div className=" mx-auto ">
                        <div className=" rounded-[9px] md:rounded-[18px] border border-[#707070]  ">
                          <ProfileAccordianComponent
                            title={trans('my_profile.professional_details')}
                            titleClassName={`flex items-center justify-between p-[1rem]   w-full pl-3 pr-2 font-bold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-[1rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
                            defaultView={true}
                            titleIcon1={
                              <img
                                src={professionalIcon}
                                className=""
                                alt=""
                              />
                            }
                            titleIcon2={
                              <img
                                src={PlusIcon}
                                className=""
                                alt=""
                              />
                            }
                            Callback={() => {}}
                          >
                            <div x-show="faqFour" x-collapse>
                              <div className=" lg:grid lg:grid-cols-9 mt-[2rem]  md:mx-[1rem] lg:mx-[2rem] ">
                                <div className=" lg:col-span-2 flex items-center pl-[1rem] md:pl-0 ">
                                  <p className="   md:text-[12px] lg:text-[14px] text-[#575556] font-semibold   ">
                                    Education
                                  </p>
                                </div>
                                <div className=" m-[1rem] md:m-0 lg:col-span-7 ">
                                   <EducationComponent
                                    setIsPPEdited = {setIsPPEdited}
                                    fromPath = "basic_advance_search"
                                    editPreference={editPreference}
                                    setEditPreference={setEditPreference}
                                    menu="advancesearch"
                                    data={formik}
                                    setEducationError={setEducationError}
                                    setFilterOnchange={setFilterOnchange}
                                    educationinfofreshData={advanceSearch}
                                  /> 
                                    {educationError ? <span className="text-[red] text-[12px]">Add more criteria to get better results</span> : ""}
                                </div>
                              </div>
                              <div className="pl-[1rem] pr-[1rem]">
                              <MultiSelectView
                        page="search"
                        title={trans('view_profile.employment_type')}
                        inputID={"employment"}
                        inputName={"employment"}
                        placeholder={trans('filters.any_employment_type')}
                        value={formik.values?.employment}
                        defalutValue={[formik.values?.employment]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["employedInId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("employment", Array.from(e));
                        }}
                        error={formik.errors.employment}
                        options={advanceSearch?.employedIn}
                      />
                             </div>  
                              <div className=" lg:grid lg:grid-cols-9 mt-[2rem]  md:mx-[1rem] lg:mx-[2rem] ">
                                <div className=" lg:col-span-2 flex items-center pl-[1rem] md:pl-0 ">
                                  <p className="   md:text-[12px] lg:text-[14px] text-[#575556] font-semibold   ">
                                    Occupation
                                  </p>
                                </div>
                                <div className=" m-[1rem] md:m-0 lg:col-span-7 md:mb-[2rem] lg:mb-[2rem] ">
                                   <OccupationComponent
                                      setIsPPEdited = {setIsPPEdited}
                                      fromPath = "basic_advance_search"
                                      editPreference={editPreference}
                                      setEditPreference={setEditPreference}
                                      data={formik}
                                      setOccupationError={setOccupationError}
                                      setFilterOnchange={setFilterOnchange}
                                      educationinfofreshData={advanceSearch}
                                    /> 
                                    {occupationError ? <span className="text-[red] text-[12px]">Add more criteria to get better results</span> : ""}
                                </div>
                              </div>
                            </div>
                          </ProfileAccordianComponent>
                        </div>
                      </div>
                    </div>
                     <div className="  mx-auto mt-8">
                      <div className=" rounded-[9px] md:rounded-[18px] border border-[#707070]  ">
                        <ProfileAccordianComponent
                          title={"Location"}
                          titleClassName={`flex items-center justify-between p-[1rem]   w-full pl-3 pr-2 font-bold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-[1rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
                          defaultView={true}
                          titleIcon1={
                            <img
                              src={professionalIcon}
                              className=""
                              alt=""
                            />
                          }
                          titleIcon2={
                            <img
                              src={PlusIcon}
                              className=""
                              alt=""
                            />
                          }
                          Callback={() => {}}
                        >
                          <div  className="pl-[1rem] pr-[1rem] pb-[1rem] ">
                            <MultiSelectView
                        page="search"
                        title={trans('view_profile.country')}
                        inputID={"country"}
                        inputName={"country"}
                        placeholder={trans('set_your_partner_expectations.any_country')}
                        value={formik.values?.country}
                        defalutValue={[formik.values?.country]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["countryId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("country", Array.from(e));
                          apiCallState( Array.from(e))
                        }}
                        error={formik.errors.country}
                        options={advanceSearch?.country}
                      />
                     <MultiSelectView
                        page="search"
                        title={"State"}
                        inputID={"state"}
                        placeholder={trans('set_your_partner_expectations.any_state')}
                        inputName={"state"}
                        value={formik.values?.state}
                        defalutValue={[formik.values?.state]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["stateId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("state", Array.from(e));
                           apiCallCity(Array.from(e))
                        }}
                        error={formik.errors.state}
                        options={stateDropDown}
                      />
                      <MultiSelectView
                        page="search"
                        title={trans('set_your_partner_expectations.city')}
                        inputID={"city"}
                        inputName={"city"}
                        placeholder={trans('set_your_partner_expectations.any_city')}
                        value={formik.values?.city}
                        defalutValue={[formik.values?.city]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["cityId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("city", Array.from(e));
                        }}
                        error={formik.errors.city}
                        options={cityDropDown}
                      />
                            
                          </div>
                        </ProfileAccordianComponent>
                      </div>
                    </div>
                    <div className=" mx-auto mt-8">
                      <div className=" rounded-[9px] md:rounded-[18px] border border-[#707070]  ">
                        <ProfileAccordianComponent
                          title={trans('my_profile.astrological_information')}
                          titleClassName={`flex items-center justify-between p-[1rem]  w-full pl-3 pr-2 font-bold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-[1rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
                          defaultView={true}
                          titleIcon1={
                            <img
                              src={professionalIcon}
                              className=""
                              alt=""
                            />
                          }
                          titleIcon2={
                            <img
                              src={PlusIcon}
                              className=""
                              alt=""
                            />
                          }
                          Callback={() => {}}
                        >
                          <div className="pl-[1rem] pr-[1rem] pb-[1rem] ">
                             <MultiSelectView
                        page="search"
                        title={trans('view_profile.star')}
                        inputID={"star"}
                        inputName={"star"}
                        placeholder={trans('set_your_partner_expectations.any_star')}
                        value={formik.values?.star}
                        defalutValue={[formik.values?.star]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["starId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("star", Array.from(e));
                          apiCallRaasi( Array.from(e))
                          
                        }}
                        error={formik.errors.star}
                        options={advanceSearch?.star}
                      />
                      <MultiSelectView
                        page="search"
                        title={trans('view_profile.raasi')}
                        inputID={"raasi"}
                        inputName={"raasi"}
                        placeholder={trans('set_your_partner_expectations.any_raasi')}
                        value={formik.values?.raasi}
                        defalutValue={[formik.values?.raasi]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["raasiId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("raasi", Array.from(e));
                        }}
                        error={formik.errors.raasi}
                        options={raasiDropDown}
                      />
                      <MultiSelectView
                        page="search"
                        title={trans('view_profile.dosham')}
                        inputID={"dosham"}
                        placeholder={trans('common_placeholder.any')}
                        inputName={"dosham"}
                        value={formik.values?.dosham}
                        defalutValue={[formik.values?.dosham]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["doshamId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("dosham", Array.from(e));
                        }}
                        error={formik.errors.dosham}
                        options={advanceSearch?.dosham}
                      />
                      
                             
                          </div>
                        </ProfileAccordianComponent>
                      </div>
                    </div>
                    <div className="  mx-auto mt-8">
                      <div className=" rounded-[9px] md:rounded-[18px] border border-[#707070]  ">
                        <ProfileAccordianComponent
                          title={trans('my_profile.habits')}
                          titleClassName={`flex items-center justify-between p-[1rem]  w-full pl-3 pr-2 font-bold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-[1rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
                          defaultView={true}
                          titleIcon1={
                            <img
                              src={professionalIcon}
                              className=""
                              alt=""
                            />
                          }
                          titleIcon2={
                            <img
                              src={PlusIcon}
                              className=""
                              alt=""
                            />
                          }
                          Callback={() => {}}
                        >
                          <div className="pl-[1rem] pr-[1rem] pb-[1rem] " >
                            <MultiSelectView
                            page="search"
                        title={trans('view_profile.food_habits')}
                        inputID={"foodhabits"}
                        inputName={"foodhabits"}
                        placeholder={trans('common_placeholder.any')}
                        value={formik.values?.foodhabits}
                        defalutValue={[formik.values?.foodhabits]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["foodId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("foodhabits", Array.from(e));
                        }}
                        error={formik.errors.foodhabits}
                        options={advanceSearch?.food}
                         
                      />
                     <MultiSelectView
                        page="search"
                        title={trans('view_profile.smoking')}
                        inputID={"smoking"}
                        inputName={"smoking"}
                        placeholder={trans('common_placeholder.any')}
                        value={formik.values?.smoking}
                        defalutValue={[formik.values?.smoking]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["smokingId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("smoking", Array.from(e));
                        }}
                        error={formik.errors.smoking}
                        options={advanceSearch?.smoking}
                      />
                      <MultiSelectView
                        page="search"
                        title={trans('view_profile.drinking')}
                        inputID={"drinking"}
                        inputName={"drinking"}
                        placeholder={trans('common_placeholder.any')}
                        value={formik.values?.drinking}
                        defalutValue={[formik.values?.drinking]}
                        onChange={(e) => {
                            setEditPreference({ ...editPreference, ["drinkingId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("drinking", Array.from(e));
                        }}
                        error={formik.errors.drinking}
                        options={advanceSearch?.drinking}
                      />
                            
                          </div>
                        </ProfileAccordianComponent>
                      </div>
                    </div>
                    <div className=" mx-auto mt-8">
                      <div className="  ">
                        <div className=" rounded-[9px] md:rounded-[18px] border border-[#707070]  ">
                          <ProfileAccordianComponent
                            title={"FILTER"}
                            titleClassName={`flex items-center justify-between p-[1rem]   w-full pl-3 pr-2 font-bold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-[1rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
                            defaultView={true}
                            titleIcon1={
                              <img
                                src={professionalIcon}
                                className=""
                                alt=""
                              />
                            }
                            titleIcon2={
                              <img
                                src={PlusIcon}
                                className=""
                                alt=""
                              />
                            }
                            Callback={() => {}}
                          >
                            <div className="p-[1rem] md:p-0" x-show="faqFive" x-collapse>
                              <div className=" md:grid grid-cols-9 md:pl-[1rem] lg:pl-[2rem] mt-[2rem] ">
                                <div className=" col-span-2 flex items-center ">
                                  <p className="   md:text-[12px] lg:text-[14px] text-[#575556] font-semibold   ">
                                    Show Profile
                                  </p>
                                </div>
                                <div className="col-span-7 pt-[1rem] md:pt-0 ">
                                  <div className="flex space-x-3">
                                    <div className="flex md:items-start space-x-1 lg:items-center ">
                                      <label>
                                         <input
                                id="withPhoto"
                                name="withPhoto"
                                onChange={(e)=> {
                                  formik.setFieldValue("withPhoto",formik.values?.withPhoto==="on" ? "" : "on" )
                                }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.withPhoto}
                                  checked={formik.values?.withPhoto==="on" ? true : false }
                                />   
                                      </label>
                                      <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                        {trans('filters.with_photo')}
                                      </p>
                                    </div>
                                    <div className="flex md:items-start space-x-1 lg:items-center  ">
                                      <label>
                                        <input
                                id="withHoroscope"
                                name="withHoroscope"
                                onChange={(e)=> {
                                  formik.setFieldValue("withHoroscope",formik.values?.withHoroscope==="on" ? "" : "on" )
                                }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.withHoroscope}
                                  checked={formik.values?.withHoroscope==="on" ? true : false }
                                />  
                                      </label>
                                      <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                      {trans('filters.with_horoscope')}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className=" md:grid grid-cols-9 md:pl-[1rem] lg:pl-[2rem] mt-[2rem] mb-[2rem] ">
                                <div className=" col-span-2 flex  items-baseline ">
                                  <p className="   md:text-[12px] lg:text-[14px] text-[#575556] font-semibold   ">
                                    Don't show Profile
                                  </p>
                                </div>
                                <div className="col-span-7">
                                  <div className=" grid grid-cols-2 pt-[1rem] md:pt-0 md:flex md:space-x-3 lg:space-x-7 ">
                                    <div className="flex md:items-start space-x-1 lg:items-center  ">
                                      <label>
                                        <input
                                id="ignored"
                                name="ignored"
                                onChange={(e)=> {
                                  formik.setFieldValue("ignored",formik.values?.ignored==="on" ? "" : "on" )
                                }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.ignored}
                                  checked={formik.values?.ignored==="on" ? true : false }
                                /> 
                                      </label>
                                      <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                      {trans('filters.rejected')}
                                      </p>
                                    </div>
                                    <div className="flex md:items-start space-x-1 lg:items-center  ">
                                      <label>
                                         <input
                                id="contacted"
                                name="contacted"
                                onChange={(e)=> {
                                  formik.setFieldValue("contacted",formik.values?.contacted==="on" ? "" : "on" )
                                }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.contacted}
                                  checked={formik.values?.contacted==="on" ? true : false }
                                /> 
                                      </label>
                                      <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                        {trans('matches.already_contacted')}
                                      </p>
                                    </div>
                                    <div className="flex md:items-start space-x-1 lg:items-center  ">
                                      <label>
                                         <input
                                id="viewed"
                                name="viewed"
                                onChange={(e)=> {
                                  formik.setFieldValue("viewed",formik.values?.viewed==="on" ? "" : "on" )
                                }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.viewed}
                                  checked={formik.values?.viewed==="on" ? true : false }
                                /> 
                                      </label>
                                      <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                      {trans('filters.viewed_profiles')}
                                      </p>
                                    </div>
                                    <div className="flex md:items-start space-x-1 lg:items-center  ">
                                      <label>
                                          <input
                                id="shortlisted"
                                name="shortlisted"
                                onChange={(e)=> {
                                  formik.setFieldValue("shortlisted",formik.values?.shortlisted==="on" ? "" : "on" )
                                }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.shortlisted}
                                  checked={formik.values?.shortlisted==="on" ? true : false }
                                /> 
                                      </label>
                                      <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                        {trans('my_profile_icon.selected_profiles')}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ProfileAccordianComponent>
                        </div>
                      </div>
                    </div>
                  </div>
        <div 
                    className="flex justify-end mb-[6rem] md:mb-[3rem] ">
                  <button
                 onClick={() => 
                  setMenuSelect("")
                  }
                    type="submit"
                    className="flex items-center justify-center h-[2rem] md:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] w-[7rem] md:w-[8rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                  >
                    {
                        submitStatus?
                       <><span className="flex">
                       <img alt='' src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5"/><span>Searching...</span>
                       </span>
                       </>: <>Search</>
                      }
                  </button>
                </div>
    </form>
    : 
     <center>
            <img src={LoaderGif} className='pt-[9rem] pb-[2rem]' alt="" />
            </center>
                    }
                    </>
  )
}

export default AdvanceSearch
