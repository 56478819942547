import Constants from "../../Constants/constants";
import * as actionTypes from "./ActionTypes"

const updateInitialMatch = ({ state, isFetching, isLoadmoreFetching, data = [], page, contentCard, banner,payload }) => {
    const initialMatch = state.initialMatch;
    const pagination = state.initialMatch?.pagination;
    let updatedContentCard = {
        ...initialMatch.contentCardData,
    }
    let updatedBanner = {
        ...initialMatch.banner,
    }

    if (contentCard) {
        updatedContentCard[pagination.page] = contentCard
    }

    if (banner) {
        updatedBanner[pagination.page] = banner
    }

    console.log("updateInitialMatch", { updatedBanner, updatedContentCard });

    return {
        ...state,
        pagination: {},
        initialMatch: {
            pagination: {
                page: page ?? pagination.page,
                size: Constants.matchRowCount,
            },
            contentCardData: updatedContentCard,
            banner: updatedBanner,
            isFetching: isFetching ?? initialMatch?.isFetching,
            isLoadmoreFetching: isLoadmoreFetching ?? initialMatch?.isLoadmoreFetching,
            hasMoreData: data.length === Constants.matchRowCount,
            data: payload?.isFrom == "logout" ? [] : payload?.isFrom == "dontshowProfile" ? data : [...initialMatch.data, ...data],
        }
    }
}

const updateSelectedMatch = ({ state, isFetching, isLoadmoreFetching, data = [], page }) => {
    const selectedMatches = state.selectedMatches;
    const pagination = state.selectedMatches?.pagination;
    const pageNo = page ?? pagination.page;

    return {
        ...state,
        selectedMatches: {
            pagination: {
                page: pageNo,
                size: Constants.matchRowCount,
            },
            isFetching: isFetching ?? selectedMatches?.isFetching,
            isLoadmoreFetching: isLoadmoreFetching ?? selectedMatches?.isLoadmoreFetching,
            hasMoreData: data.length === Constants.matchRowCount,
            data: pageNo == 1 ? [...data] : [...selectedMatches.data, ...data],
        }
    }
}

const updateNameSearchedMatch = ({ state, isFetching, isLoadmoreFetching, data = [], page, requestPayload }) => {
    const nameSearchMatches = state.nameSearchMatches;
    const pagination = state.nameSearchMatches?.pagination;

    return {
        ...state,
        // currentMatch: {
        //     ...state?.currentMatch,
        //     category: Constants.matchCatogory.nameSearch,
        //     data: data?.length > 0 && !requestPayload?.isMobileView ? { profileid: data[0]?.profileid } : state?.currentMatch?.data
        // },
        nameSearchMatches: {
            pagination: {
                page: page ?? pagination.page,
                size: Constants.matchRowCount,
            },
            isFetching: isFetching ?? nameSearchMatches?.isFetching,
            isLoadmoreFetching: isLoadmoreFetching ?? nameSearchMatches?.isLoadmoreFetching,
            hasMoreData: data.length === Constants.matchRowCount,
            data: page === 1 ? [] : [...nameSearchMatches.data, ...data],
        }
    }
}

const updateFilterMatches = ({ state, isFetching, isLoadmoreFetching, data = [], page, filterType, contentCard, banner }) => {

    const filterMatch = state?.filterMatches[filterType]
    const pagination = filterMatch?.pagination
    const currentPage = page ?? pagination.page;
    const currentData = currentPage > 1 ? (filterMatch?.data ?? []) : []


    let updatedContentCard = {
        ...filterMatch?.contentCardData,
    }
    let updatedBanner = {
        ...filterMatch?.banner,
    }

    if (contentCard) {
        updatedContentCard[pagination.page] = contentCard
    }

    if (banner) {
        updatedBanner[pagination.page] = banner
    }

    const updatetedFilterMatch = {
        ...filterMatch,
        pagination: {
            page: currentPage,
            size: Constants.matchRowCount,
        },
        contentCardData: updatedContentCard,
        banner: updatedBanner,
        isFetching: isFetching ?? filterMatch?.isFetching,
        isLoadmoreFetching: isLoadmoreFetching ?? filterMatch?.isLoadmoreFetching,
        hasMoreData: data.length === Constants.matchRowCount,
        data: [...currentData, ...data],
    }

    return {
        ...state,
        filterMatches: {
            ...state?.filterMatches,
            [filterType]: updatetedFilterMatch,
        }
    }
}

const updateBasicSearchMatch = ({ state, isFetching, isLoadmoreFetching, data = [], page, contentCard, banner, isBasicSearch = null, searchTerms, response }) => {
    const basicSearchMatches = state.basicSearchMatches;
    const pagination = state.basicSearchMatches?.pagination;
    const currentPage = page ?? pagination.page;
    let updatedContentCard = {
        ...basicSearchMatches.contentCardData,
    }
    let updatedBanner = {
        ...basicSearchMatches.banner,
    }

    if (contentCard) {
        updatedContentCard[pagination.page] = contentCard
    }

    if (banner) {
        updatedBanner[pagination.page] = banner
    }


    return {
        ...state,
        basicSearchMatches: {
            isBasicSearch: isBasicSearch ?? basicSearchMatches.isBasicSearch,
            searchTerms: searchTerms ?? basicSearchMatches?.searchTerms,
            response: response ?? basicSearchMatches.response,
            totalCount: currentPage == 1 ? response?.resultCount : basicSearchMatches?.totalCount,
            pagination: {
                page: currentPage,
                size: Constants.matchRowCount,
            },
            contentCardData: updatedContentCard,
            banner: updatedBanner,
            isFetching: isFetching ?? basicSearchMatches?.isFetching,
            isLoadmoreFetching: isLoadmoreFetching ?? basicSearchMatches?.isLoadmoreFetching,
            hasMoreData: data.length === Constants.matchRowCount,
            data: currentPage == 1 ? [...data] : [...basicSearchMatches.data, ...data],
        }
    }
}

const initialState = {
    error: "",
    loading: true,
    matchProfileLoading: false,
    nextRequestCount: 0,
    nextRequestCatagory: "",
    viewByIdLoading: true,
    viewByIdError: "",
    currentMatch: {
        type: "",
        data: {}
    },
    matches: {
        Profileslookingfor: [],
        Profileslookingforme: []
    },
    basicSearchMatches: {
        isBasicSearch: true,
        searchTerms: {},
        pagination: {
            page: 0,
            size: Constants.matchRowCount,
        },
        isFetching: true,
        isLoadmoreFetching: false,
        hasMoreData: true,
        data: [],
    },
    initialMatch: {
        pagination: {
            page: 0,
            size: Constants.matchRowCount,
        },
        isFetching: true,
        isLoadmoreFetching: false,
        hasMoreData: true,
        data: [],
    },
    selectedMatches: {
        pagination: {
            page: 0,
            size: Constants.matchRowCount,
        },
        isFetching: true,
        isLoadmoreFetching: false,
        hasMoreData: true,
        data: [],
    },
    searchedMatches: {},
    nameSearchMatches: {
        pagination: {
            page: 0,
            size: Constants.matchRowCount
        },
        isFetching: true,
        isLoadmoreFetching: false,
        hasMoreData: true,
        data: [],
    },
    filterMatches: {
        appliedMatches: [],
        filters: {},
    },
    matchProfiles: {},
    profileSelectedStatus : '',
    requestTypeLoading : false,
    requestKey : ""
};

/**
 * The function `changeMatch` takes in a state object and a boolean value indicating whether to move to
 * the next or previous match, and returns an updated state object with the current match changed
 * accordingly.
 * @param state - The `state` parameter is an object that contains the current state of the
 * application. It likely includes information about the current user, their matches, and other
 * relevant data.
 * @param isNext - A boolean value indicating whether the next match should be selected (true) or the
 * previous match should be selected (false).
 * @returns a modified state object. If the index of the current match is greater than 0 and less than
 * the length of the current match list minus 1, the function updates the currentMatch data to the next
 * or previous match in the list based on the value of the isNext parameter. Otherwise, it returns the
 * original state object.
 */
const changeMatch = (state, isNext) => {
    let userId = state.currentMatch?.data?.profileid;
    let selectedMatches = state?.selectedMatches?.data;
    let selectedMatchIds = selectedMatches?.map(match => match.profileid)
    let otherMatches = state?.initialMatch?.data?.filter(match => !selectedMatchIds.includes(match.profileid))
    let filterMatches = state?.filterMatches
    let catagory = state.currentMatch?.category;
    let matchType = state.currentMatch?.matchType;
    let currentMatchList = catagory === "INITIAL"
        ? state?.initialMatch?.data
        : catagory === "NAME_SEARCH"
            ? state?.nameSearchMatches?.data
            : catagory === "SELECTED_MATCH"
                ? state?.selectedMatches?.data
                : catagory === Constants.matchCatogory.otherMatches
                    ? otherMatches
                    : catagory === Constants.matchCatogory.filterMatches
                        ? filterMatches[matchType]?.data
                        : catagory === Constants.matchCatogory.basicSearch
                            ? state?.basicSearchMatches?.data
                            : []
    let hasMoreData = catagory === "INITIAL"
        ? state?.initialMatch?.hasMoreData
        : catagory === "NAME_SEARCH"
            ? state?.nameSearchMatches?.hasMoreData
            : catagory === "SELECTED_MATCH"
                ? state?.selectedMatches?.hasMoreData
                : catagory === Constants.matchCatogory.otherMatches
                    ? otherMatches
                    : catagory === Constants.matchCatogory.filterMatches
                        ? filterMatches[matchType]?.hasMoreData
                        : []
    let index;

    // state.matches?.response_received?.forEach(match => {
    //     currentMatchList.push(match)
    // });
    // state.matches?.Profileslookingforme?.forEach(match => {
    //     currentMatchList.push(match)
    // });
    // state.matches?.Profileslookingfor?.forEach(match => {
    //     currentMatchList.push(match)
    // });

    index = currentMatchList?.findIndex(match => match.profileid == userId)

    console.log({ catagory, currentMatchList, index });

    if ((index > 0 || isNext) && index <= (currentMatchList.length - 1)) {
        const currentMatchIndex = isNext ? index + 1 : index - 1
        if(currentMatchIndex != currentMatchList.length){
            return {
                ...state,
                currentMatch: {
                    ...state.currentMatch,
                    data: currentMatchList[currentMatchIndex],
                },
            }
        }else{
            return {
                ...state,
                nextRequestCount: state.nextRequestCount + 1,
                nextRequestCatagory: catagory,
            };
        }
    } else {
        return state
    }
}

/**
 * The above function is a reducer in JavaScript that handles different actions and updates the state
 * accordingly.
 * @param [state] - The `state` parameter represents the current state of the reducer. It is an object
 * that contains various properties and their corresponding values.
 * @param action - The `action` parameter is an object that represents the action being dispatched. It
 * contains two properties: `type` and `payload`. The `type` property specifies the type of action
 * being performed, while the `payload` property contains any additional data associated with the
 * action.
 * @returns The reducer function is returning the updated state object based on the action type.
 */
const reducer = (state = initialState, action) => {
    let profileData;
    let userId;
    let matchProfiles;
    let currentMatchList = [];
    let index;
    let isPagetwo;

    const payload = action.payload

    switch (action.type) {
        case actionTypes.GET_RECOMMENDED_MATCHES:
            return updateInitialMatch({
                state,
                isFetching: payload.page === 1,
                page: payload.page,
                isLoadmoreFetching: payload.page > 1
            })
        case actionTypes.GET_RECOMMENDED_SUCCESS:
            return updateInitialMatch({
                state,
                isFetching: false,
                isLoadmoreFetching: false,
                data: payload.data?.matcheslist,
                banner: payload.data?.bannerWebNew,
                contentCard: payload?.data?.contentCard,
                showcastemigration:payload?.data?.showcastemigration,
                castedata:payload?.data?.castedata,
                subcastedata:payload?.data?.subcastedata,
                nextRequestCatagory: "",
                nextRequestCount: 0,
                payload:payload
            })
        case actionTypes.GET_RECOMMENDED_FAIL:
            return updateInitialMatch({
                state,
                isFetching: false,
                data: [],
                payload:payload
            })

        //basic and advance search matches
        case actionTypes.SEARCH_MATCHES:
            return updateBasicSearchMatch({
                state,
                searchTerms: payload.searchTerms,
                isFetching: payload.page === 1,
                page: payload.page,
                isLoadmoreFetching: payload.page > 1,
                isBasicSearch: payload.isBasicSearch
            })
        case actionTypes.SEARCH_MATCHES_SUCCESS:
            return updateBasicSearchMatch({
                state,
                isFetching: false,
                isLoadmoreFetching: false,
                data: payload.data?.filtermatches,
                banner: payload.data?.bannerWebNew,
                contentCard: payload?.data?.contentCard,
                showcastemigration:payload?.data?.showcastemigration,
                castedata:payload?.data?.castedata,
                subcastedata:payload?.data?.subcastedata,
                response: payload.data
            })
        case actionTypes.SEARCH_MATCHES_FAIL:
            return updateBasicSearchMatch({
                state,
                isFetching: false,
                data: []
            })
        case actionTypes.CLEAR_SEARCH:
            return {
                ...state,
                basicSearchMatches: initialState.basicSearchMatches
            }


        //for selected matches
        case actionTypes.GET_SELECTED_MATCHES:
            return updateSelectedMatch({
                state,
                isFetching: payload.page === 1,
                page: payload.page,
                isLoadmoreFetching: payload.page > 1
            })
        case actionTypes.GET_SELECTED_MATCHES_SUCCESS:
            return updateSelectedMatch({
                state,
                isFetching: false,
                isLoadmoreFetching: false,
                data: payload.data?.Selectedprofiles
            })
        case actionTypes.GET_SELECTED_MATCHES_FAIL:
            return updateSelectedMatch({
                state,
                isFetching: false,
                data: []
            })
        case actionTypes.SEARCH_MATCHES_BY_TEXT:
            return updateNameSearchedMatch({
                state,
                isFetching: payload.page === 1,
                page: payload.page,
                isLoadmoreFetching: payload.page > 1
            })
        case actionTypes.SEARCH_MATCHES_BY_TEXT_SUCCESS:
            return updateNameSearchedMatch({
                state,
                isFetching: false,
                isLoadmoreFetching: false,
                data: payload.data?.searchmatches,
                requestPayload: payload.requestPayload,
            })
        case actionTypes.SEARCH_MATCHES_BY_TEXT_FAIL:
            return updateNameSearchedMatch({
                state,
                isFetching: false,
                data: []
            })

        case actionTypes.VIEW_PROFILE_BY_ID:
            return {
                ...state,
                viewByIdLoading: true,
            }
        case actionTypes.VIEW_PROFILE_BY_ID_SUCCESS:
            return {
                ...state,
                viewByIdLoading: false,
                searchedMatches: {
                    ...state.searchedMatches,
                    [payload.smProfileId]: payload.data,
                },
                currentMatch: payload.requestPayload?.isChat
                    ? state?.currentMatch
                    : {
                    ...state?.currentMatch,
                    data: payload.data?.profiledetails
                },
                viewByIdError: "",
            }
        case actionTypes.VIEW_PROFILE_BY_ID_FAIL:
            return {
                ...state,
                viewByIdLoading: false,
                viewByIdError: payload
            }
        case actionTypes.GET_MATCHES:
            return initialState;
        case actionTypes.GET_MATCHES_SUCCESS:
            // const initislSelected = payload.Profileslookingfor[0]
            return {
                ...state,
                loading: false,
                matches: payload?.data,
                currentMatch: {
                    type: "",
                    data: {}
                },
            }
        case actionTypes.GET_MATCHES_FAIL:
            return {
                ...state,
                error: payload
            }
        case actionTypes.APPLY_FILTER:
            return {
                ...state,
                filterMatches: {
                    ...state?.filterMatches,
                    appliedMatches: payload,
                }
            }
        case actionTypes.GET_FILTER_MATCHES:
            return updateFilterMatches({
                state: state,
                filterType: payload.filterType,
                isFetching: payload.page === 1,
                page: payload.page,
                isLoadmoreFetching: payload.page > 1
            })
        case actionTypes.GET_FILTER_MATCHES_SUCCESS:
            return updateFilterMatches({
                state: state,
                filterType: payload.filterType,
                isFetching: false,
                isLoadmoreFetching: false,
                data: payload.data,
                banner:payload.bannerWeb,
                contentCard:payload.contentCard
            })
        case actionTypes.GET_FILTER_MATCHES_FAIL:
            return state;
        case actionTypes.SET_CURRENT_MATCH:
            return {
                ...state,
                nextRequestCatagory: "",
                nextRequestCount: 0,
                currentMatch: {
                    type: payload.type,
                    data: payload.data,
                    category: payload.category,
                    matchType: payload.matchType,
                    matchdata:payload.matchdata,
                },
            }
        case actionTypes.CLOSE_CURRENT_MATCH:
            return {
                ...state,
                nextRequestCount: 0,
                nextRequestCatagory: "",
                currentMatch: initialState.currentMatch
            }

        case actionTypes.NEXT_MATCH:
            return changeMatch(state, true)
        case actionTypes.PREVIOUS_MATCH:
            return changeMatch(state, false)

        //profile select
        case actionTypes.TOGGLE_PROFILE_SELECTED:
            return state;

        case actionTypes.TOGGLE_PROFILE_SELECTED_SUCCESS:

            profileData = state.matchProfiles[payload.profileId]
            profileData.profiledetails.selectedprofile = !profileData.profiledetails.selectedprofile
            profileData.profiledetails.profileSelectedStatus = payload.statusMessage

            console.log(payload,'kfjjdfkdf');
            return {
                ...state,
                matchProfiles: {
                    ...state.matchProfiles,
                    [payload.profileId]: profileData,
                },                   
            }

        case actionTypes.TOGGLE_PROFILE_SELECTED_FAIL:
            profileData = state.matchProfiles[payload.profileId]
            profileData.profiledetails.selectedprofile = profileData.profiledetails.selectedprofile
            console.log(payload,'kfjjdfkdf');
            profileData.profiledetails.profileSelectedStatus = payload.statusMessage

            return {
                ...state,
                matchProfiles: {
                    ...state.matchProfiles,
                    [payload.profileId]: profileData,
                },            
            }

        case actionTypes.RESET_PROFILE_SELECTED_STATUS:
            profileData = state.matchProfiles[payload.profileId];
            profileData.profiledetails.profileSelectedStatus = undefined;
            return {
                ...state,
                matchProfiles: {
                ...state.matchProfiles,
                [payload.profileId]: profileData,
                },
            };

        // profile reject
        case actionTypes.TOGGLE_PROFILE_REJECTED:

            profileData = state.matchProfiles[payload.profileId]
            profileData.profiledetails.selectedrejected = !profileData.profiledetails.selectedrejected

            return {
                ...state,
                matchProfiles: {
                    ...state.matchProfiles,
                    [payload.profileId]: profileData
                }
            }
        case actionTypes.TOGGLE_PROFILE_BLOCK:

            profileData = state.matchProfiles[payload.profileId]

            if (payload.isBlocked) {
                profileData.profiledetails.profileblockedByMe = "N"
            } else {
                profileData.profiledetails.profileblockedByMe = "Y"
            }

            return {
                ...state,
                matchProfiles: {
                    ...state.matchProfiles,
                    [payload.profileId]: profileData
                }
            }

        case actionTypes.VIEW_PHONE:
            return state;
        case actionTypes.VIEW_PHONE_SUCCESS:

            userId = payload.phoneNumberVieweeData[0]?.profileId
            profileData = state.matchProfiles[userId]
            profileData.viewPhone = payload;

            return {
                ...state,
                matchProfiles: {
                    ...state.matchProfiles,
                    [userId]: profileData
                }
            }
        case actionTypes.VIEW_PHONE_FAIL:
            return state;

        case actionTypes.GET_MATCH_PROFILE:
            return {
                ...state,
                matchProfileLoading: true,
            }
        case actionTypes.GET_MATCH_PROFILE_SUCCESS:
            isPagetwo = payload.isPagetwo === true;

            userId = isPagetwo ? payload.userId : payload?.data?.profiledetails?.profileid;

             profileData = {
                ...payload.data,
            }

            var first = profileData?.profiledetails?.profileurl;
            profileData?.profileimages?.sort(function (x, y) { return x == first ? -1 : y == first ? 1 : 0; });


            matchProfiles = {
                ...state?.matchProfiles,
                [userId]: isPagetwo ? {
                    ...state?.matchProfiles[userId],
                    profilePreference: payload.data
                } : profileData,
            }
            return {
                ...state,
                matchProfileLoading: false,
                matchProfiles: userId ? matchProfiles : state?.matchProfiles
            }

        case actionTypes.GET_MATCH_PROFILE_PREF_SUCCESS:
            userId = payload?.request?.viewprofileid;

            matchProfiles = {
                ...state?.matchProfiles,
                [userId]: {
                    ...state?.matchProfiles[userId],
                    profilePreference: {
                        ...state?.matchProfiles[userId]?.profilePreference,
                        oppositeExpectation: payload.data,
                    }
                },
            }
            return {
                ...state,
                matchProfileLoading: false,
                matchProfiles: userId ? matchProfiles : state?.matchProfiles
            }
        case actionTypes.GET_MATCH_PROFILE_FAIL:
            return state;

        case actionTypes.MATCH_REQUEST:
            profileData = state.matchProfiles[payload.profileId]
            if (payload.requestType === "Photo") {
                profileData.profiledetails.profilerequestcheck = true;
            }
            if (payload.requestType === "Horoscope") {
                profileData.horoscopeRequest = "Y";
            }
            if (payload.requestType === "Parents Number") {
                profileData.profiledetails.parentnumberrequestcheck = true;
            }
            if (payload.requestType === "Trust") {
                profileData.trustRequest = "Y";
            }
            return {
                ...state,
                matchProfiles: {
                    ...state.matchProfiles,
                    [payload.profileId]: profileData
                }
            }

        // store request type api
        case actionTypes.STORE_REQUEST_TYPE:
            return {
                ...state,
                requestTypeLoading : true,
                requestKey : payload.keyName
            };

        case actionTypes.STORE_REQUEST_TYPE_SUCCESS:

            const keyName = payload.keyName;  // e.g., "foodandhabits?.Smoking"

            // Step 1: Parse the keyName into an array of keys
            const keys = keyName.replace('?.', '.').split('.');
            profileData = state.matchProfiles[payload.receiver_profileid]
            // Step 2: Traverse the profileData object using the keys array
            let currentLevel = profileData;
            for (let i = 0; i < keys.length - 1; i++) {
                const key = keys[i];

                // If the key does not exist, initialize it as an empty object
                if (!currentLevel[key]) {
                    currentLevel[key] = {};
                }

                // Move to the next level
                currentLevel = currentLevel[key];
            }
            console.log(profileData,'storeRequestPayload');
            // Step 3: Set the final key's value to "requested"
            currentLevel[keys[keys.length - 1]] = "requested";

            return {
                ...state,
                matchProfiles: {
                    ...state.matchProfiles,
                    [payload.receiver_profileid]: profileData,
                },       
                requestTypeLoading : false,
                requestKey : payload.keyName
            }

        case actionTypes.STORE_REQUEST_TYPE_FAIL:
            return {
                ...state,
                requestTypeLoading : false,
                requestKey : payload.keyName
            }

        default:
            return state;
    }

};

export default reducer;