import React from "react";
import CloseIcon from "../../../Images/Group 761@2x.png"

const WarningPopup = (props) => {
  return (
    <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6 max-w-3xl ">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-between border-b border-solid bg-[#D10A11] border-gray-300 rounded-t ">
            <h3 className="text-[16px] text-white ml-4 font-semibold align-middle">
              {props.title}
            </h3>
            <button
              className=" bg-transparent border-0 text-black float-right pr-4"
              onClick={() => {
                props.close();
              }}
            >
              <img
                className="h-5 w-5 "
                src={CloseIcon}
                alt=""
              />
            </button>
          </div>
          <div className="relative p-6 flex-auto">
            {props.content ? props.content : props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningPopup;
