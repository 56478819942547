import React from 'react'
import RangeFilter from "./rangeFilter"

const BasicFilter = (props) => {

    //console.log(props?.data?.maxage,"maxdata")
  return (
    <div>
        <div> 
            <RangeFilter
            menu={props?.menu}
            data={props?.data}
            rangeFilter={props?.onChange}
            />
        </div>                                        
    </div>
  )
}

export default BasicFilter
