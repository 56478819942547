import React, { useState, useEffect, useContext } from "react";
import { NavListContext } from "../../Contexts/navContext";
import { CommonSuccessPopup, UploadPhotoPopup } from "../CommonPopup";
import { useNavigate } from "react-router";
import RouteSegments, { RouteHelper, RoutePaths } from "../../Routes/RouteSegment";
import Constants, { InboxFilters } from "../../Constants/constants";
import { NavBarContext } from "../../Contexts/NavBarContext";
import { message } from "antd";
import OnlineImage from '../../Images/Group 619.svg'
import ProfileCount from '../../Images//Group 263.svg'
import lockicon from '../../Images/lockicon.svg'
import kalyanImage1 from '../../Images/kalyan image 1.png'
import profileImagecount from '../../Images/Group 164.svg'
import { dateFormat } from "../../Utils";
import { useTranslation } from "react-i18next";




 
const ProfileCard = (props) => {
  
  const { t: trans } = useTranslation();
 
  // console.log(props,"jikjkljlo");
 
  const { navList, setNavList } = useContext(NavListContext);
  const navigate = useNavigate()
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);
   const [requestPhoto, setRequestPhoto] = useState() 
  const [selectReject, setSelectReject] = useState({
    select: props?.cardData?.selectedprofile ?? false,
    reject: props?.cardData?.selectedrejected ?? false,
    message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    photoRequest: props?.cardData?.profilerequestcheck ?? false
  });
  // //console.log("card use effect",props?.cardData?.profilealreadyContected);
  

  useEffect(()=> {
     
     setRequestPhoto(props?.photoRequest=="Y"?true:false)
       //console.log(props?.photoRequest,"photoRequest3");
   },[props?.photoRequest])

   useEffect(() => {
    //console.log(requestPhoto,"1234");
   },[requestPhoto])
  useEffect(() => {
    // //console.log("card use effect");
  }, [selectReject, props]);

  useEffect(() => {
    // //console.log("lenght", props?.data?.length);
    //console.log(props?.phonenumberstatus,"phonen03");
  }, [props?.phonenumberstatus]);

  useEffect(() => {
    setSelectReject({
      select: props?.cardData?.selectedprofile ?? false,
      reject: props?.cardData?.selectedrejected ?? false,
      photoRequest: props?.cardData?.profilerequestcheck ?? false
    });
  }, []);

  useEffect(() => {
    setSelectReject({
      select: selectReject.select,
      reject: selectReject.reject,
      message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    });
  }, [props]);

  const onMessageClick = () => {
    if (props?.title == "Selected Profile" || props?.title == "Recommend Matches") {
      if (props?.cardData?.profileblockedByMe == "N") {
        props.callbackSelectReject("MESSAGE", "")
      }
      else {
        props?.callBackBlockProfile()
      }
    }
    else {
      props.callbackSelectReject("MESSAGE", "")
    }
  }

  const onlineNowClick = ()=>{
    if(props?.cardData?.profilealreadyContected === "Y" && !Constants.profileStatusOptions.includes(props?.cardData?.profileStatus) && props.dashboard1Data?.status !== "pending" && props.dashboard1Data?.status !== "hidden"){
      navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), { state: { profileId: props?.cardData?.profileid, threadId:props?.cardData?.messageSent[0].subjectId, type:"Sent" } })
    }else{
      onMessageClick()
    }
  }


  
  return (
    <>
      <div className="bg-white p-2 ">
        <div className="">
          <div className="flex ">
            {props?.title === "Profiles Matching the Partner Expectation" || props?.title === "Profiles looking for me" ?
              <div className="flex">
                  <div className="absolute top-[25px] left-[1rem] md:top-[20px] lg:top-[25px] md:left-[20px] lg:left-[20px] hidden py-[0.1rem] bg-[#9F9F9F] rounded-[6px] px-1 z-[1] md:hidden lg:hidden">
                    {props?.cardData
                      ?.profilelastOnline === "Online Now" ? (
                      <>
                        <img
                          className="w-3 h-3"
                          src={OnlineImage}
                          alt=""
                        />
                        <p className="text-[#039511] font-bold text-[8px] lg:px-1">
                          {trans('view_profile.online')}
                        </p>
                      </>
                    ) : (
                      <p className="text-[#000000] font-bold text-[8px] lg:px-1">
                        {
                          dateFormat(props?.cardData
                            ?.profilelastOnline)
                        }
                      </p>
                    )}
                  </div>
                  {props?.cardData?.profileimagecount === "0" ?"":
                    <div className="absolute top-[25px] left-[118px] md:top-[20px] lg:top-[25px] md:left-[230px] lg:left-[260px] hidden md:flex py-[0.1rem] bg-[#FEBABA] rounded-[6px] px-1 z-[1] ">
                        <img
                          className="w-3 h-3"
                          src={ProfileCount}
                          alt=""
                        />
                        <p className="text-[#000000] font-bold text-[9px] px-1">
                          {props?.cardData?.profileimagecount}
                        </p>
                    </div>
                    
                  }
              </div>
              : <></>
          }
            <div className="flex relative imageCard"  >
              <img
                onClick={() => 
                  props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending" ?
                    props?.callBackNav()
                  :
                    props.cardData.profileStatus == "inactive"?
                    props.viewProfileStatusPopup("Profile has been inactive")
                    :props.cardData.profileStatus == "hidden" ?
                    props.viewProfileStatusPopup("Profile has been hidden")
                    :props.cardData.profileStatus == "delete" ?
                    props.viewProfileStatusPopup("Profile has been deleted")
                    :props.cardData.profileStatus == "suspension" ?
                    props.viewProfileStatusPopup("Profile has been suspended")
                    :""
                }
                onDoubleClick={() => {
                  //console.log("double click");
                }}
                src={props?.cardData?.profileurl}
                // width="150"
                alt=" "
                onError={i => {
                  i.target.style.width='8rem';
                }}
                className={`${props?.cardData?.profileblurPhoto === "Y" ? "blur-[2px]" : ""
                  }  border-l border-t w-[9.5rem] md:w-[10.5rem] lg:w-[11rem] cursor-pointer md:h-full`}
              />
              {props?.cardData?.profilefromPasswordProtected === "Y" && (
                <div
                onClick={() => 
                  props?.cardData?.profileStatus == "open" || props?.cardData?.profileStatus == "pending"?
                  props?.callBackNav()
                  :
                  props.cardData.profileStatus == "inactive"?
                        props.viewProfileStatusPopup("Profile has been inactive")
                        :props.cardData.profileStatus == "hidden" ?
                        props.viewProfileStatusPopup("Profile has been hidden")
                        :props.cardData.profileStatus == "delete" ?
                        props.viewProfileStatusPopup("Profile has been deleted")
                        :props.cardData.profileStatus == "suspension" ?
                        props.viewProfileStatusPopup("Profile has been suspended")
                        :""
                  }                 className="absolute top-0 flex justify-center items-center h-[100%] w-[9.5rem] md:w-[10.5rem] lg:w-[11rem] cursor-pointer"
                >
                  <img
                    className="h-[2rem] w-[2rem]"
                    src={lockicon}
                    alt="Person"
                  />
                </div>
              )}
              {props?.cardData?.isAvatarPic && (
                <div
                   onClick={() => {
                    if(props?.title == "Selected Profile"){
                    if(props?.cardData?.profileblockedByMe=="N"){
                     if(props?.cardData?.profilerequestcheck === true){

                              }
                    else if (props?.cardData?.profilerequestcheck === false) {
                       setSelectReject({
                        ...selectReject,
                        photoRequest: !selectReject.photoRequest,
                      });
                      props.callbackRequestPhoto(
                        
                      );
                    }
                  }
                  else{
                    props?.callBackBlockProfile()
                  }
                }
                else{
                  if(props?.cardData?.profilerequestcheck === true){

                  }
                  else if (props?.cardData?.profilerequestcheck === false) {
                    setSelectReject({
                      ...selectReject,
                      photoRequest: !selectReject.photoRequest,
                    });
                    props.callbackRequestPhoto(
                      
                    );
                  }
                }
                  }}
                  className="absolute top-0 flex justify-center items-end h-[100%] w-full cursor-pointer"
                >
                  <span className="text-[#575556] mb-6  w-full text-[12px] flex  justify-center items-center font-bold ">
                    {props?.cardData?.profilerequestcheck  
                      ? trans('view_profile.photo_requested')
                      : trans('view_profile.request_photo')}
                  </span>
                </div>
              )}
            </div>
            <div className=" border-t border-gray-200 border-r text-left flex-grow">
              <div className=" mt-2">
                <div className="flex justify-end md:justify-between">
                  <div
                    className="hidden md:flex justify-between cursor-pointer"
                      onClick={() => {
                        if(props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"){
                          props.callBackNav()
                          }
                          else{
                          if(props.cardData.profileStatus == "inactive")  props.viewProfileStatusPopup("Profile has been inactive")
                          else if(props.cardData.profileStatus == "hidden") props.viewProfileStatusPopup("Profile has been hidden")
                          else if(props.cardData.profileStatus == "delete") props.viewProfileStatusPopup("Profile has been deleted")
                          else props.viewProfileStatusPopup("Profile has been suspended")
                        }
                        
                        setNavList([...navList, "Profile Details"])
                    }}
                    onDoubleClick={() => { }}
                  >
                    <p className="px-3 md:px-3 lg:px-3 text-[#575556] font-bold text-[12px] md:text-[12px] lg:text-[12px] xl:text-[14px]">
                      {props.cardData.smProfileId}
                    </p>
                  </div>

                  {props.cardData.profilelastOnline === "Online Now" ? (
                    <div onClick={onlineNowClick} className="flex cursor-pointer justify-end item-center">
                      <img
                        className=" w-3 h-3 md:w-2 md:h-2 lg:w-3 lg:h-3 self-center"
                        src={OnlineImage}
                        alt=""
                      />
                      <span className="text-[#039511] px-2 md:px-1 lg:px-2 text-[9px] md:text-[8px] lg:text-[10px] self-center">
                        Online Chat Now
                      </span>
                      {/* <!-- <span className=" md:hidden lg:flex text-[#039511] font-segeo font-semibold text-[9px] px-2">Last Online:29 Jul 2018</span> --> */}
                    </div>
                  ) : (
                    <span className="md:flex justify-end text-[#039511] font-segeo font-semibold text-[10px] lg:text-[8px] xl:text-[10px] px-1 self-center">
                      Last Online: {dateFormat(props?.cardData?.profilelastOnline)}
                    </span>
                  )}

                </div>


              </div>
              {/* <p className="  text-[#039511]  text-end  font-segeo font-semibold text-[10px] px-2 md:hidden lg:hidden">
                Last Online:29 Jul 2018
              </p> */}
              <div
                className=" px-1 space-y-1 mx:px-1 pl-3  lg:px-2 xl:px-3  md:w-[14rem] lg:w-[13rem] xl:w-[14rem] h-[7rem] md:h-[8rem] md:w-[14rem]"

                onDoubleClick={() => { }}
              >
                <span 
                onClick={() => 
                  props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                  props.callBackNav()
                  :
                  props.cardData.profileStatus == "inactive"?
                  props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.profileStatus == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.profileStatus == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.profileStatus == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""
                 
                  } className="text-[#575556] cursor-pointer  md:text-[17px] lg:text-[16px] xl:text-[18px] text-[10pt]  font-bold">
                  {props.cardData.profilename}
                </span>
                <p className="text-[#7A7A7A] text-[9pt] md:text-[14px] lg:text-[12px] xl:text-[14px] opacity-[0.8] ">
                  {props.cardData.profileage}, {props.cardData.profileheight},{" "}
                  {props.cardData.profilenativelanguage}
                </p>
                <p className="text-[#7A7A7A] text-[9pt] md:text-[14px] lg:text-[12px] xl:text-[14px] opacity-[0.8]  ">
                  {props.cardData.profilecaste},
                </p>
                 {props?.title == "Selected Profile" &&
                 props.cardData.profileStatus == "inactive" ||
                 props.cardData.profileStatus == "hidden"||
                 props.cardData.profileStatus == "delete" ||
                 props.cardData.profileStatus == "suspension"?
                <span className="flex">
                <p className="text-[#7A7A7A] text-[9pt] md:text-[14px] lg:text-[12px] xl:text-[14px] opacity-[0.8] ">
                  {props.cardData.profilecity}, 
                </p>
                <p className="text-[#7A7A7A] cut-text text-[9pt] md:text-[14px] lg:text-[12px] xl:text-[14px] opacity-[0.8] ">
                  {props.cardData.profilestate} 
                </p>
                </span>
                :
                <p className="text-[#7A7A7A] text-[9pt] md:text-[14px] lg:text-[12px] xl:text-[14px] opacity-[0.8] ">
                  {props.cardData.profilecity},  {props.cardData.profilestate} 
                </p>

}
                  
                {props?.title == "Selected Profile" &&
                  <p className="text-[#7A7A7A] text-[9pt] md:text-[14px] lg:text-[12px] xl:text-[14px] opacity-[0.8] h-4">
                    {props.cardData.profileStatus == "inactive"?
                      "Profile has been inactive"
                      :props.cardData.profileStatus == "hidden"?
                      "Profile has been hidden"
                      :props.cardData.profileStatus == "delete"?
                      "Profile has been deleted"
                      :props.cardData.profileStatus == "suspension"?
                      "Profile has been suspened"
                      :""
                    }
                  </p>
                }
              </div>
              <div>
                <div className="flex py-1  md:px-2 justify-around items-center">
                  <div
                    className=" flex flex-col items-center cursor-pointer w-[18%]"
                    onClick={() => {
                      setSelectReject({
                        ...selectReject,
                        select: !selectReject.select, 
                      });

                      props.callbackSelectReject(
                        "SELECT",
                        selectReject.select ? "Selected" : "Select"
                      );
                    }}
                  > 
                    <img
                      className="w-5 h-5 md:w-4 md:h-4 lg:w-4 lg:h-4 xl:w-5 xl:h-5"
                      src={
                        selectReject.select
                        ? "/Assets/Images/Group 169 green.svg"
                        : "/Assets/Images/Group 169.svg"
                      }


                      alt=""
                    />
                    <span
                      className={`${selectReject.select
                        ? "text-[#039511]"
                        : "text-[#7A7A7A]"
                        } text-[11px] font-medium`}
                    >
                      {selectReject.select ? "Selected" : "Select"}
                    </span>
                  </div>
                  <div
                    className=" w-[23%]  flex flex-col items-center cursor-pointer"
                    onClick={() => {
                      setSelectReject({
                        ...selectReject,
                        reject: !selectReject.reject,
                      });

                      props.callbackSelectReject(
                        "REJECT",
                        selectReject.reject ? "Rejected" : "Reject"
                      );
                    }}
                  >
                    <img
                      className="w-5 h-5 md:w-4 md:h-4 lg:w-4 lg:h-4 xl:w-5 xl:h-5"
                      src={
                        selectReject.reject
                          ? "/Assets/Images/Group 166 red.svg"
                          : "/Assets/Images/Group 166.svg"
                      }
                      alt=""
                    />
                    <span
                      className={`${selectReject.reject
                        ? "text-[#D10A11]"
                        : "text-[#7A7A7A]"
                        } text-[11px] font-medium`}
                    >
                      {selectReject.reject ? "Rejected" : "Reject"}
                    </span>
                  </div>

                  <div
                    className="flex flex-col items-center cursor-pointer"
                    // onClick={() => { 
                    //   props.callbackSelectReject("MESSAGE", "")
                    // }}
                    onClick={onMessageClick}
                       >
                    <img
                      className="w-6 h-6 md:w-5 md:h-5 lg:w-6 lg:h-6"
                      src={
                        selectReject.message
                          ? "/Assets/Images/Group 185 green.svg"
                          : "/Assets/Images/Group 185.svg"
                      }
                      alt="" />
                    <span
                      className={`${selectReject.message
                        ? "text-[#039511]"
                        : "text-[#7A7A7A]"
                        } text-[11px]`}
                    >
                      {selectReject.message ? "Messaged" : "Message"}
                    </span>
                     {/* <span 
                      className={`${selectReject.message
                        ? "text-[#039511]"
                        : "text-[#7A7A7A]"
                        } text-[11px]`}
                    >{selectReject.message
                      ? "Messaged" : "Message"}

                    </span> */}
                  </div>



                </div>
              </div>
            </div>
          </div> 

          <div className="flex justify-around bg-[#FEBABA] p-2">
            <button
              onClick={() => { 
                 

                 props.callBackPhone()
                 

                }}             
                 className=" flex text-[12px] text-[#D10A11]"
            >
              <img className=" w-5 " alt="" src="/Assets/Images/Group 173.svg" />
            
              <span className="px-2 pt-1 ">{trans('view_profile.phone_number')}</span>
 
            </button>

            {props?.cardData?.profileparentsnumber ?
              <button
              onClick={() => { 
                  
                props.callBackPhone()
                
                
                }}                 className=" flex text-[#039511] text-[12px] "
              >
                <img className="  w-5" src="/Assets/Images/Group 172.svg" alt=""/>
                {/* <span className="px-2 pt-1"> Number</span>{" "} */}
                
              <span className="px-2 pt-1 ">Parents Number</span>

              </button>
              :props?.cardData?.parentnumberrequestcheck?   
              <button
              onClick={() => { 
                  
                // props.callBackPhone()
                
                }}                 className=" flex text-[#039511] text-[12px] "
              >
                <img className="  w-5" src="/Assets/Images/Group 172.svg" alt=""/>
                {/* <span className="px-2 pt-1"> Number</span>{" "} */}
                
              <span className="px-2 pt-1 "> Parents Number Requested </span>

              </button>
              :
              <button
              onClick={() => { 
                  
                if(props?.cardData?.profileblockedByMe == "Y" ){
                  props?.callBackBlockProfile()
                }else {
                  props.callbackParentNumberRequest()
                }
                
                }}                 className=" flex text-[#039511] text-[12px] "
              >
                <img className="  w-5" src="/Assets/Images/Group 172.svg" alt=""/>
                {/* <span className="px-2 pt-1"> Number</span>{" "} */}
                
              <span className="px-2 pt-1 "> Request Parents Number</span>

              </button>
            }

          </div>
        </div>
      </div>
    </>
  );
};

const ResponseProfileCard = (props) => {

  const { menuSelect, setMenuSelect } = useContext(NavBarContext);
  const navigate = useNavigate();
  const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
   const [selectReject, setSelectReject] = useState({
    select: props?.cardData?.selectedprofile ?? false,
    reject: props?.cardData?.selectedrejected ?? false,
    message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    photoRequest: props?.cardData?.profilerequestcheck ?? false
  });

  const photoRequest = props?.cardData?.profilerequestcheck ?? false

  const { t: trans } = useTranslation();

  useEffect(() => {
    setSelectReject({
      select: props?.cardData?.selectedprofile ?? false,
      reject: props?.cardData?.selectedrejected ?? false,
      photoRequest: props?.cardData?.profilerequestcheck ?? false
    });
  }, []);

  useEffect(() => {
    setSelectReject({
      select: selectReject.select,
      reject: selectReject.reject,
      message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    });
  }, [props]);

  
   
  useEffect(() => {
     //console.log(props?.cardData?.profileStatus, "responsecard");
  }, [props?.type, props?.cardData?.profileStatus]);

  const onlineNowClick = ()=>{
    if(props?.cardData?.profilealreadyContected === "Y" && !Constants.profileStatusOptions.includes(props?.cardData?.profileStatus) && props.dashboard1Data?.status !== "pending" && props.dashboard1Data?.status !== "hidden"){
      navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), { state: { profileId: props?.cardData?.profileid, threadId:props?.cardData?.messageSent[0].subjectId, type:"Sent" } })
    }else{
      props.callbackSelectReject("MESSAGE", "")
    }
  }
 
  return (
    <div className="bg-white  mr-2">
      <div className=" w-[15rem]  md:w-[15rem]  lg:w-[16.4rem]">
        <div className=" p-2 bg-[#E9E9E9] rounded-[10px]">
          <div className="flex relative h-[12rem] md:h-[12rem] lg:h-[13rem]  w-[14rem]  md:w-[15.4rem]  lg:w-[15.4rem]">
            <div className="h-[12rem] md:h-[13rem] lg:h-[13rem]  w-[14rem]  md:w-[14rem]  lg:w-[15.4rem]">
              <img
               onClick={() => 
                props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                props.callBackNav()
                :
                props.cardData.profileStatus == "inactive"?
                props.viewProfileStatusPopup("Profile has been inactive")
                :props.cardData.profileStatus == "hidden" ?
                props.viewProfileStatusPopup("Profile has been hidden")
                :props.cardData.profileStatus == "delete" ?
                props.viewProfileStatusPopup("Profile has been deleted")
                :props.cardData.profileStatus == "suspension" ?
                props.viewProfileStatusPopup("Profile has been suspended")
                :""}
                onDoubleClick={() => { }}
                className={`${props?.cardData?.profileblurPhoto === "Y" && props?.cardData?.isAvatarPic === false ? "blur-[2px]" : ""
                  } rounded-[10px] h-full w-full cursor-pointer`}
                src={props?.cardData?.profileurl} //'Assets/Images/kalyan image 1.png'//
                alt=""
              />
            </div>
            {props?.cardData?.profilefromPasswordProtected === "Y" && props?.cardData?.isAvatarPic === false  && (
              <div
              onClick={() => 
                props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                props.callBackNav()
                :
                props.cardData.profileStatus == "inactive"?
                props.viewProfileStatusPopup("Profile has been inactive")
                :props.cardData.profileStatus == "hidden" ?
                props.viewProfileStatusPopup("Profile has been hidden")
                :props.cardData.profileStatus == "delete" ?
                props.viewProfileStatusPopup("Profile has been deleted")
                :props.cardData.profileStatus == "suspension" ?
                props.viewProfileStatusPopup("Profile has been suspended")
                :""}
                className="absolute top-0 flex justify-center items-center h-[12rem] md:h-[12rem] lg:h-[13rem]  w-[14rem]  md:w-[14rem]  lg:w-[15.4rem] cursor-pointer"
              >
                <img
                  className="h-[2rem] w-[2rem]"
                  src={lockicon}
                  alt="Person"
                />
              </div>
            )}
            {props?.cardData?.isAvatarPic && (
              <div className="absolute top-0 flex justify-center items-end h-[12rem] md:h-[13rem] lg:h-[13rem]  w-[14rem]  md:w-[14rem]  lg:w-[15.4rem] cursor-pointer">
                <span
                   onClick={() => {

                     if(photoRequest){}
                    else {
                     
                      props.callbackRequestPhoto();
                    }
                  }}
                  className="text-[#575556] mb-6 w-[100%]   flex justify-center items-center  font-bold "
                >
                  {photoRequest
                    ? trans('view_profile.photo_requested')
                    : trans('view_profile.request_photo')}
                </span>
              </div>
            )}
          </div>
          <div className="flex justify-between lg:mt-0 md:mt-5">
            <div className="hidden md:flex justify-between ">
            <p className="hidden md:flex px-2 md:px-2 lg:px-2 text-[12px] md:text-[14px] lg:text-[14px] text-[#7A7A7A] self-center">
              {props?.cardData?.smProfileId}
            </p>
          </div>
          {props.cardData.profilelastOnline === "Online Now" ? (
            <div
              onClick={onlineNowClick}
              className=" hidden md:flex py-2 px-2 justify-end cursor-pointer "
            >
              <img
                className=" w-3 h-3 md:w-3 lg:mt-[0.1rem] md:h-3 lg:w-3 lg:h-3"
                src={OnlineImage}
                alt=""
              />
              <span className=" text-[#039511] px-2 md:px-1 lg:px-2 text-[9px] md:text-[8px] lg:text-[10px] ">
                Online Chat Now
              </span>
            </div>
          ) : (
            <span className="hidden md:flex justify-end text-[#039511] font-segeo font-semibold text-[9px] md:text-[9px] lg:text-[10px] py-2 px-2">
              Last Online: {dateFormat(props?.cardData?.profilelastOnline)}
            </span>
          )}
</div>
          

          <div className="px-2 pb-2 -mt-[0.5rem] md:mt-0 lg:mt-0">
            {props?.cardData?.profilelastOnline === "Online Now" ? (
              <div
              onClick={onlineNowClick}
                className="flex justify-end pt-5 px-2 space-x-1 md:hidden cursor-pointer"
              >
                <img className=" w-3 h-3" src={OnlineImage} alt=""/>
                <span className="text-[#039511] px-2 md:px-0 lg:px-2  text-[9px] md:text-[10px] lg:text-[10px] ">
                  Online Chat Now
                </span>
              </div>
            ) : (

              <span className="flex justify-end md:hidden flex text-[#039511] font-segeo font-semibold text-[9px] md:text-[10px] lg:text-[10px] pt-5 px-2 lg:hidden ">
                Last Online: {dateFormat(props?.cardData?.profilelastOnline)}
              </span>
            )}
            <div
              className="cursor-pointer space-y-1"

            >
              {/* <p className="hidden md:flex px-2 md:px-0 lg:px-3 mt-2 lg:mt-2 md:mt-4 text-[12px] md:text-[12px]  text-[#7A7A7A] lg:hidden ">
                {props?.cardData?.smProfileId}
              </p> */}
              <p 
              onClick={() => 
              props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                props.callBackNav()
                :
                props.cardData.profileStatus == "inactive"?
                props.viewProfileStatusPopup("Profile has been inactive")
                :props.cardData.profileStatus == "hidden" ?
                props.viewProfileStatusPopup("Profile has been hidden")
                :props.cardData.profileStatus == "delete" ?
                props.viewProfileStatusPopup("Profile has been deleted")
                :props.cardData.profileStatus == "suspension" ?
                props.viewProfileStatusPopup("Profile has been suspended")
                :""
                
              }
                className="font-semibold cursor-pointer leading-[19px] md:leading-[16px] py-1  text-[#575556] text-[10pt] md:text-[16px] lg:text-[16px]">
                {props?.cardData?.profilename}
              </p>
              <p className="md:hidden text-[9pt] md:text-[15px] lg:text-[14px] text-[#7A7A7A] ">
                {props?.cardData?.smProfileId}
              </p>

              <p className="text-[9pt] md:text-[14px] lg:text-[14px] text-[#7A7A7A]">
                {props?.cardData?.profileage}, {props.cardData.profileheight},{" "}
                {props?.cardData?.profilenativelanguage}
              </p>
              {/* <div className=""> */}
              {/* {props.isViewmore && props.type!='recentlyviewed'?
                <p className="truncate w-[12rem]  md:w-[12rem]  lg:w-[12.4rem] text-[9pt] md:text-[14px] lg:text-[14px] text-[#000000]">
                  Message: {props?.cardData?.message}
                </p>
                : ""

              } */}
               {/* {props?.title == "Selected Profile" && */}
                  <p className="text-[#7A7A7A] text-[9pt] md:text-[14px] lg:text-[14px] opacity-[0.8] h-[1rem] ">
                    {props.cardData.profileStatus == "inactive"?
                      "Profile has been inactive"
                      :props.cardData.profileStatus == "hidden"?
                      "Profile has been hidden"
                      :props.cardData.profileStatus == "delete"?
                      "Profile has been deleted"
                      :props.cardData.profileStatus == "suspension"?
                      "Profile has been suspened"
                      :
                      props.isViewmore && props.type!='recentlyviewed' ?
                      <p className="truncate w-[12rem]  md:w-[12rem]  lg:w-[12.4rem] text-[9pt] md:text-[14px] lg:text-[14px] text-[#000000]">
                        Message: {props?.cardData?.message}
                      </p>
                      :""
                      
                    }
                  </p>
{/* } */}
              {/* </div> */}
            </div>
            <div
              

              onClick={() => 
                
                props?.isViewmore ? 
                props?.type === "Unread"?
                props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                  navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), { state: { profileId: props?.cardData?.profileid, threadId:props?.cardData?.messageSent[0].subjectId, type:props?.type,isfrom:"converstion" } })
                 :
                 props.cardData.profileStatus == "inactive"?
                  props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.profileStatus == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.profileStatus == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.profileStatus == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""
                  : props?.type === "recentlyviewed" ? 
                  props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                  props.callBackNav()
                  :
                  props.cardData.profileStatus == "inactive"?
                  props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.profileStatus == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.profileStatus == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.profileStatus == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""
                  : 
                    props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                    props.callBackNav()
                    :
                    props.cardData.profileStatus == "inactive"?
                    props.viewProfileStatusPopup("Profile has been inactive")
                    :props.cardData.profileStatus == "hidden" ?
                    props.viewProfileStatusPopup("Profile has been hidden")
                    :props.cardData.profileStatus == "delete" ?
                    props.viewProfileStatusPopup("Profile has been deleted")
                    :props.cardData.profileStatus == "suspension" ?
                    props.viewProfileStatusPopup("Profile has been suspended")
                    :""
                :
                props.callbackUploadRequestPhoto()
                }
              className="text-[9pt] md:text-[12px] lg:text-[12px] text-[#D10A11] font-semibold font-segeo cursor-pointer underline mt-[0.6rem] "
            >
              {props.isViewmore ?
               <button
               className="underline"
               >View More</button>
                :
               <button>  Send Photo </button>
                }
              { }
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseProfileCard2 = () => {
  return (
    <div className="bg-white">
      <div className=" w-[13rem]  md:w-[16rem]  lg:w-[24.4rem]">
        <div className=" p-2 bg-[#E9E9E9] rounded-[10px]">
          <img
            className=" h-[12rem] md:h-[11rem] lg:h-[13rem]"
            src={kalyanImage1}
            alt=""
          />
          <div className=" hidden md:hidden lg:flex mt-2 justify-end ">
            <img
              className=" w-3 h-3 md:w-2 md:h-2 lg:w-3 lg:h-3 self-center"
              src={OnlineImage}
              alt=""
            />
            <span className=" px-2 md:px-1 lg:px-2 text-[9px] md:text-[8px] lg:text-[10px] text-[#575556] self-center">
              Online Chat Now
            </span>
            {/* <!-- <span className=" md:hidden lg:flex text-[#039511] font-segeo font-semibold text-[9px] px-2">Last Online:29 Jul 2018</span> --> */}
          </div>
          <div className="hidden md:flex justify-between">
            <p className="hidden lg:flex px-2 md:px-0 lg:px-3 mt-2 text-[12px] md:text-[9px] lg:text-[14px] text-[#7A7A7A] ">
              KM89188842
            </p>
          </div>

          <div className="px-2">
            <div className="flex justify-end mt-2 space-x-1 lg:hidden">
              <img className=" w-3 h-3" src={OnlineImage} alt=""/>
              <span className=" px-2 md:px-0 lg:px-2  text-[9px] md:text-[10px] lg:text-[10px] text-[#575556]">
                Online Chat Now
              </span>
            </div>
            <p className="hidden md:flex px-2 md:px-0 lg:px-3 mt-2 text-[12px] md:text-[12px]  text-[#7A7A7A] lg:hidden ">
              KM89188842
            </p>
            <p className="font-semibold leading-[19px] md:leading-[16px] py-1  text-[#575556] text-[10pt] md:text-[16px] lg:text-[16px]">
              Gayathiri Ravichandran
            </p>
            <p className="md:hidden text-[9pt] md:text-[15px] lg:text-[14px] text-[#7A7A7A] ">
              KM89188842
            </p>

            <p className="text-[9pt] md:text-[14px] lg:text-[14px] text-[#7A7A7A]">
              25 yrs, 4'7", Tamil
            </p>
            <p className="text-[9pt] md:text-[14px] lg:text-[14px] text-[#000000]">
              Message: I am Intrested...
            </p>
            <a
              href=""
              className="text-[9pt] md:text-[14px] lg:text-[14px] text-[#D10A11] font-semibold underline "
               
            >
              View More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const ProfileLookingForCard = (props) => {
  const [buttonAction, setButtonAction] = useState({
    select: props?.cardData?.selectedprofile ?? false,
    reject: props?.cardData?.selectedrejected ?? false,
    message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    phoneNo: props?.cardData?.selectedphoneno ?? false,
    parentsPhoneNo: props?.cardData?.selectedparentno ?? false,
    photoRequest: props?.cardData?.profilerequestcheck ?? false

  });
  const navigate = useNavigate()
  const [requestPhoto, setRequestPhoto] = useState() 

  const { t: trans } = useTranslation();

   useEffect(()=> {
     setRequestPhoto(props?.photoRequest?.photoRequest=="Y"?true:false)
   },[requestPhoto])

  useEffect(() => {
    //console.log(props?.photoRequest,"photorequest");
  },[props?.photoRequest])
  useEffect(() => {
    // //console.log("card use effect");
  }, [buttonAction]);
  // useEffect(() => {
    //console.log("props updated");
  //   setButtonAction({
  //     select: props?.cardData?.selectedprofile ?? false,
  //     reject: props?.cardData?.selectedrejected ?? false,
  //     message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
  //     phoneNo: props?.cardData?.selectedphoneno ?? false,
  //     parentsPhoneNo: props?.cardData?.selectedparentno ?? false,
  //     photoRequest: props?.cardData?.profilerequestcheck ?? false
  //   });
  // }, [props]);
  useEffect(() => {
    setButtonAction({
      select: props?.cardData?.selectedprofile ?? false,
      reject: props?.cardData?.selectedrejected ?? false,
      photoRequest: props?.cardData?.profilerequestcheck ?? false,
      phoneNo: props?.cardData?.selectedphoneno ?? false,
      parentsPhoneNo: props?.cardData?.selectedparentno ?? false,
    });
  }, []);

  useEffect(() => {
    setButtonAction({
      select: buttonAction.select,
      reject: buttonAction.reject,
      photoRequest: buttonAction.photoRequest,
      phoneNo: buttonAction?.phoneNo,
      parentsPhoneNo: buttonAction?.parentsPhoneNo,
      message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    });
  }, [props]);

  const onMessageClick = () => {
    if (buttonAction.message === false) {
      setButtonAction({ ...buttonAction, message: true });
    }
     props.callbackSelectReject("MESSAGE", "")
     }

  const onlineNowClick = ()=>{
    if(props?.cardData?.profilealreadyContected === "Y" && !Constants.profileStatusOptions.includes(props?.cardData?.profileStatus) && props.dashboard1Data?.status !== "pending" && props.dashboard1Data?.status !== "hidden"){
      navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), { state: { profileId: props?.cardData?.profileid, threadId:props?.cardData?.messageSent[0].subjectId, type:"Sent" } })
    }else{
      onMessageClick()
    }
  }

  return (
    <div className="bg-white mx-2 xl:mx-4">
      <div className="">
        {/* md:w-[20rem]  lg:w-[26.4rem] */}
        <div className="flex md:h-[27rem] lg:h-[28rem]">
          <div
            className="rounded-[10px] border-b-[3px] border-[#D10A11] w-full"
            style={{ boxShadow: "0px 0px 1px #D10A11" }}
          // style="box-shadow: 0px 4px 1px #D10A11;"
          >
            <div className="flex ">
              <span className="relative inline-block rounded-tl-[10px] w-full">
              <div className="flex">
                  {/* <div className="absolute top-[200px] md:top-[20px] lg:top-[25px] md:left-[20px] lg:left-[20px] flex py-[0.1rem] bg-[#9F9F9F] rounded-[6px] px-1 z-[1]">
                    {props?.cardData
                      ?.profilelastOnline === "Online Now" ? (
                      <>
                        <img
                          className="w-3 h-3"
                          src={OnlineImage}
                          alt=""
                        />
                        <p className="text-[#039511] font-bold text-[8px] lg:px-1">
                          online
                        </p>
                      </>
                    ) : (
                      <p className="text-[#000000] font-bold text-[8px] lg:px-1">
                        {
                          props?.cardData
                            ?.profilelastOnline
                        }
                      </p>
                    )}
                  </div> */}
                  {/* {props?.cardData?.profileimagecount > "0" ?
                    <div className="absolute top-[200px] md:top-[20px] lg:top-[25px] md:left-[230px] lg:left-[260px] flex py-[0.1rem] bg-[#FEBABA] rounded-[6px] px-1 z-[1]">
                        <img
                          className="w-3 h-3"
                          src={ProfileCount}
                          alt=""
                        />
                        <p className="text-[#000000] font-bold text-[9px] px-1">
                          {props?.cardData?.profileimagecount}
                        </p>
                    </div>
                    :<></>
                  } */}
                </div>
                <div className="flex relative  ">
                {props?.cardData?.profileimagecount ==="0"? ""
                    :
                    <span
                      // style="--tw-translate-x:10%; --tw-translate-y:-18%"
                      style={{ transform: "translateX(10%) translateY(-18%)" }}
                      className="hidden md:inline-flex bg-[#FEBABA]  rounded-[4px] absolute right-5 top-[20px]  items-center justify-center px-1 md:px-1 lg:px-2 lg:py-1 md:text-[7px] lg:text-[10px] font-bold leading-none text-[#000000]  "
                    >
                      <img
                        src={profileImagecount}
                        alt=""
                        className="h-[1rem] md:h-[1rem] p-[3px] pl-[6px] md:p-[3px] lg:p-[1px] lg:pl-[7px] lg:h-[1rem] "
                      />
                      <span className="px-2 md:px-2 lg:px-2">
                        {props?.cardData?.profileimagecount}
                      </span>
                      {/* <!-- </span> --> */}
                    </span>
}
                  <div className="w-full">
                    <img
                      onClick={() => 
                       props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                        props.callBackNav()
                      :
                      props.cardData.profileStatus == "inactive"?
                      props.viewProfileStatusPopup("Profile has been inactive")
                      :props.cardData.profileStatus == "hidden" ?
                      props.viewProfileStatusPopup("Profile has been hidden")
                      :props.cardData.profileStatus == "delete" ?
                      props.viewProfileStatusPopup("Profile has been deleted")
                      :props.cardData.profileStatus == "suspension" ?
                      props.viewProfileStatusPopup("Profile has been suspended")
                      :""}
                      onDoubleClick={() => { }}
                      src={props.cardData.profileurl} //"/Assets/Images/image.png"
                      className={`${props?.cardData?.profileblurPhoto === "Y"
                        ? "blur-[2px]"
                        : ""
                        }   w-full h-[18rem] md:h-[18rem] lg:h-[21rem] rounded-tl-[10px] rounded-bl-md cursor-pointer border-t`}
                      alt=""
                    />
                  </div>
                  {props?.cardData?.profilefromPasswordProtected === "Y" && (
                    <div
                    onClick={() => 
                      props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                      props.callBackNav()
                      :
                      props.cardData.profileStatus == "inactive"?
                      props.viewProfileStatusPopup("Profile has been inactive")
                      :props.cardData.profileStatus == "hidden" ?
                      props.viewProfileStatusPopup("Profile has been hidden")
                      :props.cardData.profileStatus == "delete" ?
                      props.viewProfileStatusPopup("Profile has been deleted")
                      :props.cardData.profileStatus == "suspension" ?
                      props.viewProfileStatusPopup("Profile has been suspended")
                      :""}
                      className="absolute top-0 flex justify-center items-center  h-[18rem] md:h-[18rem] lg:h-[21rem] cursor-pointer"
                    >
                      <img
                        className="h-[2rem] w-[2rem]"
                        src={lockicon}
                        alt="Person"
                      />
                    </div>
                  )}
                  
                  {props?.cardData?.isAvatarPic && (
                    <div className="absolute top-0 flex justify-center items-end w-full h-[18rem] md:h-[18rem] lg:h-[21rem] cursor-pointer">
                      <span
                        onClick={() => {

                     if(props?.cardData?.profilerequestcheck === true){

                              }
                    else if (props?.cardData?.profilerequestcheck === false) {
                       setButtonAction({
                        ...buttonAction,
                        photoRequest: !buttonAction.photoRequest,
                      });
                      props.callbackRequestPhoto(
                        
                      );
                    }
                  }}
                        className="text-[#575556] mb-10 w-[100%] flex justify-center items-center text-center font-bold "
                      >
                        {props?.cardData?.profilerequestcheck  
                          ? trans('view_profile.photo_requested')
                          : trans('view_profile.request_photo')}
                      </span>
                    </div>
                  )}
                </div>
                {/* {props?.cardData?.profilefromPasswordProtected === "Y" && ( */}
                  <span
                    // style="--tw-translate-x:10%; "
                    style={{ transform: "translateX(10%)" }}
                    className="flex absolute top-[1.5rem] md:top-3 lg:top-4  inline-flex items-center justify-center md:px-1 lg:px-2 lg:py-1 md:text-[7px] lg:text-[10px] font-bold leading-none text-[#000000] transform  bg-[#9F9F9F] rounded-md "
                  >
                    {props.cardData.profilelastOnline === "Online Now" ? (
                      <div onClick={onlineNowClick} className="cursor-pointer flex">
                        {" "}
                        <img
                          src={OnlineImage}
                          alt=""
                          className="h-[1rem] md:h-[1rem] lg:h-[1rem] "
                        />
                        <span className="px-2 py-1 md:py-0 text-[13px] md:px-1 lg:px-2">Online</span>
                      </div>
                    ) : (
                      // props?.cardData?.profilelastOnline > 0 ?
                        <span className="inline-block text-[13px] py-1 px-2 md:px-1 lg:px-2">
                          {dateFormat(props?.cardData?.profilelastOnline)}
                        </span>
                        // : ""
                    )}
                    {/* <!-- <span className="bg-[#9F9F9F] rounded-md"> --> */}
                    

                  </span>

                {/* ) */}

                {/* } */}

              </span>

              {/* {props?.cardData?.profileparentsnumber ? */}
               <div className={props?.cardData?.profileparentsnumber?" bg-[#E9E9E9] items-center lg:p-[0.5rem] md:p-[0.5rem] rounded-tr-[10px] md:space-y-1 lg:space-y-4 grid "
               : " bg-[#E9E9E9] items-center lg:p-[0rem] md:p-0 rounded-tr-[10px] md:space-y-1 lg:space-y-4 grid "}>
               <div
                 className=" flex flex-col items-center cursor-pointer pt-[0.5rem]"
                 onClick={() => {
                   setButtonAction({
                     ...buttonAction,
                     select: !buttonAction.select,
                   });

                   props.callbackSelectReject(
                     "SELECT",
                     buttonAction.select ? "Selected" : "Select"
                   );
                 }}
               >
                 <img
                   className="w-4 h-4 lg:w-4 lg:h-4 xl:w-5 xl:h-5 md:w-5 md:h-5"
                   src={
                     buttonAction.select
                       ? "/Assets/Images/Group 169 green.svg"
                       : "/Assets/Images/Group 169.svg"
                   }
                   alt=""
                 />
                 <span
                   className={`${buttonAction.select ? "text-[#039511]" : "text-[#7A7A7A]"
                     } text-[9px] md:text-[11px] lg:text-[13px]`}
                 >
                   {buttonAction.select ? "Selected" : "Select"}
                 </span>
               </div>
               <div
                 className="  flex flex-col items-center cursor-pointer"
                 onClick={() => {
                   setButtonAction({
                     ...buttonAction,
                     reject: !buttonAction.reject,
                   });

                   props.callbackSelectReject(
                     "REJECT",
                     buttonAction.reject ? "Rejected" : "Reject"
                   );
                 }}
               >
                 <img
                   className="w-4 h-4 lg:w-4 lg:h-4 xl:w-5 xl:h-5 md:w-5 md:h-5"
                   src={
                     buttonAction.reject
                       ? "/Assets/Images/Group 166 red.svg"
                       : "/Assets/Images/Group 166.svg"
                   }
                   alt=""
                 />
                 <span
                   className={`${buttonAction.reject ? "text-[#D10A11]" : "text-[#7A7A7A]"
                     } text-[9px] md:text-[11px] lg:text-[13px] `}
                 >
                   {buttonAction.reject ? "Rejected" : "Reject"}
                 </span>
               </div>
               <div
                 className="flex   flex-col items-center cursor-pointer"
                 onClick={onMessageClick}     
               >
                 <img
                   className="w-4 h-4 lg:w-4 lg:h-4 xl:w-5 xl:h-5 md:w-5 md:h-5"
                   src={
                     buttonAction.message
                       ? "/Assets/Images/Group 185 green.svg"
                       : "/Assets/Images/Group 185.svg"
                   }
                   alt=""
                 />
                 <span
                   className={`${buttonAction.message ? "text-[#039511]" : "text-[#7A7A7A]"
                     } text-[9px] md:text-[11px] lg:text-[13px] `}
                 >
                   {buttonAction.message ? "Messaged" : "Message"}
                 </span>
               </div>
               {

               }
               <div
                onClick={() => { 
                  props.callBackPhone()
                  if (buttonAction.phoneNo === false) {
                    setButtonAction({ ...buttonAction, phoneNo: true });
                  }
              }} 
                className="flex   flex-col items-center cursor-pointer"
               >
                 <img
                   className="w-4 h-4 lg:w-5 lg:h-5 md:w-5 md:h-5"
                   src={
                     buttonAction.phoneNo
                       ? "/Assets/Images/Group 173.svg"
                       : "/Assets/Images/Group 1731.svg"
                   }
                   alt=""
                 />
                 <span
                   className={`${buttonAction.phoneNo  && 
                    (props.cardData.profileStatus  != "inactive" &&
                      props.cardData.profileStatus != "hidden" &&
                      props.cardData.profileStatus != "delete" &&
                      props.cardData.profileStatus != "suspension")
                    
                    ? "text-[#D10A11] font-bold" : "text-[#7A7A7A]"
                     } text-center text-[9px] px-3 md:text-[11px] lg:text-[13px]`}
                 >
                   {trans('view_profile.phone_number')}
                 </span>
               </div>

               {props?.cardData?.profileparentsnumber ?
                 <div
                 onClick={() => { 
                    props.callBackPhone()
                    if (buttonAction.parentsPhoneNo === false) {
                      setButtonAction({ ...buttonAction, parentsPhoneNo: true });
                    }
                 }}
                 className="flex   flex-col items-center cursor-pointer"
                 >
                   <img
                     className="w-4 h-4 lg:w-5 lg:h-5 md:w-5 md:h-5"
                     src={
                       buttonAction.parentsPhoneNo
                         ? "/Assets/Images/Group 172.svg"
                         : "/Assets/Images/Group 174.svg"
                     }
                     alt=""
                   />
                   <span
                     className={`${buttonAction.parentsPhoneNo && 
                      (props.cardData.profileStatus  != "inactive" &&
                        props.cardData.profileStatus != "hidden" &&
                        props.cardData.profileStatus != "delete" &&
                        props.cardData.profileStatus != "suspension")
                       ? "text-[#039511] font-bold "
                       : "text-[#7A7A7A]"
                       }  text-center text-[9px] px-3 md:text-[11px] lg:text-[13px] `}
                   >
                     Parents Number
                   </span>
                 </div>
                 : 
                props?.cardData?.parentnumberrequestcheck?   
              <button
              className="flex   flex-col items-center cursor-pointer"
              onClick={() => { 
                  
                // props.callBackPhone()
                
                }}                
              >
                <img className="w-4 h-4 lg:w-5 lg:h-5 md:w-5 md:h-5"
                     src={
                      //  buttonAction.parentsPhoneNo
                      //    ? "/Assets/Images/Group 172.svg"
                      //    : 
                         "/Assets/Images/Group 174.svg"
                     }
                     alt=""
                 />
                {/* <span className="px-2 pt-1"> Number</span>{" "} */}
                
              <span   className={`${buttonAction.parentsPhoneNo && 
                      (props.cardData.profileStatus  != "inactive" &&
                        props.cardData.profileStatus != "hidden" &&
                        props.cardData.profileStatus != "delete" &&
                        props.cardData.profileStatus != "suspension")
                       ? "text-[#7A7A7A] "
                       : "text-[#7A7A7A]"
                       }  text-center text-[9px] px-3 md:text-[11px] lg:text-[13px] `}
              ><p className="whitespace-nowrap">Parents No</p>
              <p>{trans('view_profile.requested')}</p>
               </span>

              </button>
              :
              <button
              onClick={() => { 
                  
                if(props?.cardData?.profileblockedByMe == "Y"){
                  props?.callBackBlockProfile()
                }else {
                  props.callbackParentNumberRequest()
                }
                
                }}       className="flex   flex-col items-center cursor-pointer"
              >
                 <img className="w-4 h-4 lg:w-5 lg:h-5 md:w-5 md:h-5"
                     src={
                       buttonAction.parentsPhoneNo
                         ? "/Assets/Images/Group 172.svg"
                         : "/Assets/Images/Group 174.svg"
                     }
                     alt=""
                 />

                {/* <span className="px-2 pt-1"> Number</span>{" "} */}
                
              <span   className={`${buttonAction.parentsPhoneNo && 
                      (props.cardData.profileStatus  != "inactive" &&
                        props.cardData.profileStatus != "hidden" &&
                        props.cardData.profileStatus != "delete" &&
                        props.cardData.profileStatus != "suspension")
                       ? "text-[#039511] font-bold "
                       : "text-[#7A7A7A]"
                       }  text-center text-[9px] px-3 md:text-[11px] lg:text-[13px] `}
              > Request Parents No</span>

              </button>

                 

               }

             </div>
             {/* : */}
             {/* <div className=" bg-[#E9E9E9] items-center lg:p-[0.7rem] md:p-1 rounded-tr-[10px] space-y-5 grid  ">
             <div
               className="  flex flex-col items-center cursor-pointer pt-[0.5rem] "
               onClick={() => {
                 setButtonAction({
                   ...buttonAction,
                   select: !buttonAction.select,
                 });

                 props.callbackSelectReject(
                   "SELECT",
                   buttonAction.select ? "Selected" : "Select"
                 );
               }}
             >
               <img
                 className="w-4 h-4 lg:w-5 lg:h-5 md:w-5 md:h-5"
                 src={
                   buttonAction.select
                     ? "/Assets/Images/Group 169 green.svg"
                     : "/Assets/Images/Group 169.svg"
                 }
                 alt=""
               />
               <span
                 className={`${buttonAction.select ? "text-[#039511]" : "text-[#7A7A7A]"
                   } text-[9px] md:text-[11px] lg:text-[13px]`}
               >
                 {buttonAction.select ? "Selected" : "Select"}
               </span>
             </div>
             <div
               className="  flex flex-col items-center cursor-pointer"
               onClick={() => {
                 setButtonAction({
                   ...buttonAction,
                   reject: !buttonAction.reject,
                 });

                 props.callbackSelectReject(
                   "REJECT",
                   buttonAction.reject ? "Rejected" : "Reject"
                 );
               }}
             >
               <img
                 className="w-4 h-4 lg:w-5 lg:h-5 md:w-5 md:h-5"
                 src={
                   buttonAction.reject
                     ? "/Assets/Images/Group 166 red.svg"
                     : "/Assets/Images/Group 166.svg"
                 }
                 alt=""
               />
               <span
                 className={`${buttonAction.reject ? "text-[#D10A11]" : "text-[#7A7A7A]"
                   } text-[9px] md:text-[11px] lg:text-[13px] `}
               >
                 {buttonAction.reject ? "Rejected" : "Reject"}
               </span>
             </div>
             <div
               className=" flex flex-col items-center cursor-pointer"
               onClick={() => {
                 if (buttonAction.message === false) {
                   setButtonAction({ ...buttonAction, message: true });
                 }
                 props.callbackSelectReject("MESSAGE", "")
                }}
             >
               <img
                 className="w-4 h-4 lg:w-5 lg:h-5 md:w-5 md:h-5"
                 src={
                   buttonAction.message
                     ? "/Assets/Images/Group 185 green.svg"
                     : "/Assets/Images/Group 185.svg"
                 }
                 alt=""
               />
               <span
                 className={`${buttonAction.message ? "text-[#039511]" : "text-[#7A7A7A]"
                   } text-[9px] md:text-[11px] lg:text-[13px] `}
               >
                 {buttonAction.message ? "Messaged" : "Message"}
               </span>
             </div>
             {

             }
             <div
              onClick={() => { 
                props.callBackPhone()
              }}          
              className="flex py-1 md:py-1 lg:py-2 mt-3 flex-col items-center cursor-pointer"
             >
               <img
                 className="w-4 h-4 lg:w-5 lg:h-5 md:w-5 md:h-5"
                 src={
                   buttonAction.phoneNo
                     ? "/Assets/Images/Group 173.svg"
                     : "/Assets/Images/Group 1731.svg"
                 }
                 alt=""
               />
               <span
                 className={`${buttonAction.phoneNo ? "text-[#D10A11] font-bold" : "text-[#7A7A7A]"
                   } text-center text-[9px] px-3 md:text-[11px] lg:text-[13px]`}
               >
                 {trans('view_profile.phone_number')}
               </span>
             </div>

             {props?.cardData?.profileparentsnumber ?
               <div
               onClick={() => { 
                props.callBackPhone()
                }}                 className="flex py-1 md:py-1 lg:py-2  flex-col items-center cursor-pointer"
               >
                 <img
                   className="w-4 h-4 lg:w-5 lg:h-5 md:w-5 md:h-5"
                   src={
                     buttonAction.parentsPhoneNo
                       ? "/Assets/Images/Group 172.svg"
                       : "/Assets/Images/Group 174.svg"
                   }
                   alt=''
                 />
                 <span
                   className={`${buttonAction.parentsPhoneNo
                     ? "text-[#039511] font-bold "
                     : "text-[#7A7A7A]"
                     }  text-center text-[9px] px-3 md:text-[11px] lg:text-[13px] `}
                 >
                   Parents Number
                 </span>
               </div>
               : ""

             }

           </div>

              } */}

             
            </div>
            <div
              className="p-2 pl-4"

            >
              <div className="">
                <p 
                onClick={() => 
                  props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                  props.callBackNav()
                  :
                  props.cardData.profileStatus == "inactive"?
                  props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.profileStatus == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.profileStatus == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.profileStatus == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""}
                  className="cursor-pointer text-[16px] font-bold text-[#D10A11] leading-[19px]
                                    "
                >
                  {props.cardData.profilename}
                </p>
              </div>
              <p className="hidden md:hidden  lg:flex mt-1 text-[#7A7A7A] text-[14px]">
                {props.cardData.profileage}, {props.cardData.profileheight},{" "}
                {props.cardData.profilenativelanguage},{" "}
                {props.cardData.profileoccupation}
              </p>
              <p className="mt-1 text-[#7A7A7A] text-[14px] lg:hidden">
                {props.cardData.profileage}, {props.cardData.profileheight},{" "}
                {props.cardData.profilenativelanguage},
              </p>
              <p className="mt-1 text-[#7A7A7A] text-[14px] lg:hidden">
                {" "}
                {props.cardData.profileoccupation}
              </p>

              <div className="pb-7 ">
                <span className=" text-[#7A7A7A] text-[14px]">
                  {props.cardData.profilecaste},{" "}
                </span>
                <span className="text-[#7A7A7A] text-[14px]">
                  {props.cardData.profilecity}, {props.cardData.profilestate}
                </span>
              </div>
               {props?.title == "Selected Profile" &&
                  <p className="text-[#7A7A7A] text-[9pt] md:text-[14px] lg:text-[14px] opacity-[0.8] h-[1rem]">
                    {props.cardData.profileStatus == "inactive"?
                      "Profile has been inactive"
                      :props.cardData.profileStatus == "hidden"?
                      "Profile has been hidden"
                      :props.cardData.profileStatus == "delete"?
                      "Profile has been deleted"
                      :props.cardData.profileStatus == "suspension"?
                      "Profile has been suspened"
                      :""
                    }
                  </p>
}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const MatchesProfileCard = (props) => {
  const [selectReject, setSelectReject] = useState({
    select: props?.cardData?.selectedprofile ?? false,
    reject: props?.cardData?.selectedrejected ?? false,
    message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    photoRequest: props?.cardData?.profilerequestcheck ?? false
  });
const navigate = useNavigate()
  const photoRequest = props?.cardData?.profilerequestcheck ?? false
     
   const [requestPhoto, setRequestPhoto] = useState() 
   const { t: trans } = useTranslation();

  //  useEffect(()=> {
  //    setRequestPhoto(props?.photoRequest?.photoRequest=="Y"?true:false)
  //  },[requestPhoto])
 
  useEffect(() => {
    // console.log("card use effect"); 
  }, [selectReject]);
  useEffect(() => {
    setSelectReject({
      select: props?.cardData?.selectedprofile ?? false,
      reject: props?.cardData?.selectedrejected ?? false,
      photoRequest: props?.cardData?.profilerequestcheck ?? false
    });
  }, []);

  useEffect(() => {
    setSelectReject({
      select: selectReject.select,
      reject: selectReject.reject,
      message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    });
  }, [props]);

  const onMessageClick = () => {
    // if (selectReject.message === false) {
    //   setSelectReject({ ...selectReject, message: true });
    // }
    props.callbackSelectReject("MESSAGE", "")
  }

  const onlineNowClick = ()=>{
    if(props?.cardData?.profilealreadyContected === "Y" && !Constants.profileStatusOptions.includes(props?.cardData?.profileStatus) && props.dashboard1Data?.status !== "pending" && props.dashboard1Data?.status !== "hidden"){
      navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), { state: { profileId: props?.cardData?.profileid, threadId:props?.cardData?.messageSent[0].subjectId, type:"Sent" } })
    }else{
      onMessageClick()
    }
  }

  return (
    <>
      <div className="bg-white mr-2 ">
        <div className=" w-auto">
          <div className="flex ">
            <div className="flex relative">

              <img
                onClick={() => 
                  props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                  props.callBackNav()
                :
                props.cardData.profileStatus == "inactive"?
                props.viewProfileStatusPopup("Profile has been inactive")
                :props.cardData.profileStatus == "hidden" ?
                props.viewProfileStatusPopup("Profile has been hidden")
                :props.cardData.profileStatus == "delete" ?
                props.viewProfileStatusPopup("Profile has been deleted")
                :props.cardData.profileStatus == "suspension" ?
                props.viewProfileStatusPopup("Profile has been suspended")
                :""}
                src={props?.cardData?.profileurl}
                width="150"
                alt=""
                className={`${props?.cardData?.profileblurPhoto === "Y" ? "blur-[2px]" : ""
                  } w-[8.5rem] md:w-[8.5rem] lg:w-[10.5rem] aspect-[1/1]`}
              />
              {props?.cardData?.profilefromPasswordProtected === "Y" && (
                <div
                onClick={() =>
                  props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                  props.callBackNav()
                  :
                  props.cardData.profileStatus == "inactive"?
                  props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.profileStatus == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.profileStatus == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.profileStatus == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""}           className="absolute top-0 flex justify-center items-center h-[100%] w-[8.5rem] md:w-[8.5rem] lg:w-[10.5rem] cursor-pointer"
                >
                  <img
                    className="h-[2rem] w-[2rem]"
                    src={lockicon}
                    alt="Person"
                  />
                </div>
              )}
              {props?.cardData?.isAvatarPic && (
                <div
                  onClick={() => {
                    if (photoRequest) {
                    }
                    else {
                      props.callbackRequestPhoto();
                    }
                  }}
                  className="absolute top-0 flex justify-center items-end h-[100%] w-[8.5rem] md:w-[8.5rem] lg:w-[10.5rem] cursor-pointer"
                >
                  <span className="text-[#575556] mb-6 w-full text-center font-bold ">
                    {photoRequest
                      ? trans('view_profile.photo_requested')
                      : trans('view_profile.request_photo')}
                  </span>
                </div>
              )}
            </div>
            <div className=" border-t border-gray-200 border-r text-left flex-grow">
              <div className=" mt-2 pl-[0.5rem] md:pl-0">
                 <div className="flex justify-end md:justify-between">
                  <div
                    className="hidden md:flex justify-between cursor-pointer"
                      onClick={() => {
                        if(props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"){
                          props.callBackNav()
                          }
                          else{
                          if(props.cardData.profileStatus == "inactive")  props.viewProfileStatusPopup("Profile has been inactive")
                          else if(props.cardData.profileStatus == "hidden") props.viewProfileStatusPopup("Profile has been hidden")
                          else if(props.cardData.profileStatus == "delete") props.viewProfileStatusPopup("Profile has been deleted")
                          else props.viewProfileStatusPopup("Profile has been suspended")
                        }
                        
                        // setNavList([...navList, "Profile Details"])
                    }}
                    onDoubleClick={() => { }}
                  >
                    <p className="px-3 md:px-1 lg:px-3 text-[#575556] font-bold text-[12px] md:text-[12px] lg:text-[14px]">
                      {props.cardData.smProfileId}
                    </p>
                  </div>
                {props.cardData.profilelastOnline === "Online Now" ? (
                  <div onClick={onlineNowClick} className="flex cursor-pointer  justify-end item-center">
                    <img
                      className=" w-3 h-3 md:w-2 md:h-2 lg:w-3 lg:h-3 self-center"
                      src={OnlineImage}
                      alt=""
                    />
                    <span className=" px-2 md:px-1 lg:px-2 text-[9px] md:text-[8px] lg:text-[10px] text-[#575556] self-center">
                      Online Chat Now
                    </span>
                    {/* <!-- <span className=" md:hidden lg:flex text-[#039511] font-segeo font-semibold text-[9px] px-2">Last Online:29 Jul 2018</span> --> */}
                  </div>
                ) : (
                  <span className="md:flex justify-end text-[#039511] font-segeo font-semibold text-[7px] lg:text-[10px] px-1">
                    Last Online: {dateFormat(props?.cardData?.profilelastOnline)}
                  </span>
                )}
                </div>
                {/* <div className="hidden md:flex justify-between">
                  <p className="px-3 md:pl-1 lg:px-3 text-[#575556] font-bold text-[12px] md:text-[12px] lg:text-[14px]">
                    {props.cardData.smProfileId}
                  </p>
                </div> */}
              </div>
              {/* <p className="  text-[#039511]  text-end  font-segeo font-semibold text-[10px] px-2 md:hidden lg:hidden">
                Last Online:29 Jul 2018
              </p> */}
              <div className=" px-3 space-y-1 md:px-1 lg:px-3">
                <span 
                onClick={() =>
                  props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                  props.callBackNav()
                  :
                  props.cardData.profileStatus == "inactive"?
                  props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.profileStatus == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.profileStatus == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.profileStatus == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""}
                className="text-[#575556] md:text-[14px] lg:text-[18px] text-[13pt] break-all h-[10px] font-bold cursor-pointer">
                  {props.cardData.profilename}
                </span>
                <p className="text-[#7A7A7A] text-[11pt] md:text-[12px] lg:text-[14px] whitespace-nowrap">
                  {props.cardData.profileage}, {props.cardData.profileheight},{" "}
                  {props.cardData.profilenativelanguage}
                </p>
                <p className="text-[#7A7A7A] text-[11pt] md:text-[12px] lg:text-[14px] whitespace-nowrap">
                  {props.cardData.profilecaste},
                </p>
                <p className="text-[#7A7A7A] text-[11pt] md:text-[12px] lg:text-[14px] break-all">
                  {props.cardData.profilecity}, {props.cardData.profilestate}
                </p>
                 {props?.title == "Selected Profile" &&
                  <p className="text-[#7A7A7A] text-[9pt] md:text-[14px] lg:text-[14px] opacity-[0.8] h-[1rem]">
                    {props.cardData.profileStatus == "inactive"?
                      "Profile has been inactive"
                      :props.cardData.profileStatus == "hidden"?
                      "Profile has been hidden"
                      :props.cardData.profileStatus == "delete"?
                      "Profile has been deleted"
                      :props.cardData.profileStatus == "suspension"?
                      "Profile has been suspened"
                      :""
                    }
                  </p>
}
              </div>
              <div className="md:pl-[14px] lg:pl-0">
                <div className="flex py-1  md:px-0 justify-around items-center">
                  <div
                    className="mr-3 w-[10%] md:mr-1 lg:mr-3 flex flex-col items-center cursor-pointer"
                    onClick={() => {
                      setSelectReject({
                        ...selectReject,
                        select: !selectReject.select,
                      });

                      props.callbackSelectReject(
                        "SELECT",
                        selectReject.select ? "Selected" : "Select"
                      );
                    }}
                  >
                    <img
                      className="w-5 h-5 md:w-4 md:h-4 lg:w-5 lg:h-5"
                      src={ selectReject.select ? "/Assets/Images/Group 169 green.svg" : "/Assets/Images/Group 169.svg" }
                      alt=""
                    />
                    <span
                      className={`${selectReject.select
                        ? "text-[#039511]"
                        : "text-[#7A7A7A]"
                        } text-[11px] font-medium`}
                    >
                      {selectReject.select ? "Selected" : "Select"}
                    </span>
                  </div>
                  <div
                    className="mr-3 w-[10%] md:w-[25%] lg:w-[10%] md:mr-1 lg:mr-3 flex flex-col items-center cursor-pointer md:pl-[9px] lg:pl-0 "
                    onClick={() => {
                      setSelectReject({
                        ...selectReject,
                        reject: !selectReject.reject,
                      });

                      props.callbackSelectReject(
                        "REJECT",
                        selectReject.reject ? "Rejected" : "Reject"
                      );
                    }}
                  >
                    <img
                      className="w-5 h-5 md:w-3 md:h-3 lg:w-5 lg:h-5"
                      src={
                        selectReject.reject
                          ? "/Assets/Images/Group 166 red.svg"
                          : "/Assets/Images/Group 166.svg"
                      }
                      alt=""
                    />
                    <span
                      className={`${selectReject.reject
                        ? "text-[#D10A11]"
                        : "text-[#7A7A7A]"
                        } text-[11px] mt-[2px] `}
                    >
                      {selectReject.reject ? "Rejected" : "Reject"}
                    </span>
                  </div>
                  <div
                    className="flex flex-col items-center cursor-pointer"
                    onClick={onMessageClick}
                  >
                    <img
                      className={props?.title=="Similar Matching Profiles"?"w-6 h-6 md:w-4 md:h-4 lg:w-6 lg:h-6": "w-6 h-6 md:w-5 md:h-5 lg:w-6 lg:h-6"}
                      src={
                        selectReject.message
                          ? "/Assets/Images/Group 185 green.svg"
                          : "/Assets/Images/Group 185.svg"
                      }
                      alt=""
                    />
                    <span
                      className={`${selectReject.message
                        ? "text-[#039511]"
                        : "text-[#7A7A7A]"
                        } text-[11px]`}
                    >{selectReject.message
                      ? "Messaged" : "Message"}

                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-around bg-[#FEBABA] p-2">
            <button
              onClick={() => { 
                props.callBackPhone()
                }}
              className=" flex text-[12px] text-[#D10A11]"
            >
              <img className=" w-5 " src="/Assets/Images/Group 173.svg" alt=""/>
              <span className="px-2 pt-1">{trans('view_profile.phone_number')}</span>
            </button>

             {props?.cardData?.profileparentsnumber ?
              <button
              onClick={() => { 
                  
                props.callBackPhone()
                
                
                }}                 className=" flex text-[#039511] text-[12px] "
              >
                <img className="  w-5" src="/Assets/Images/Group 172.svg" alt=""/>
                {/* <span className="px-2 pt-1"> Number</span>{" "} */}
                
              <span className="px-2 pt-1 ">Parents Number</span>

              </button>
              :props?.cardData?.parentnumberrequestcheck?   
              <button
              onClick={() => { 
                  
                // props.callBackPhone()
                
                }}                 className=" flex text-[#039511] text-[12px] "
              >
                <img className="  w-5" src="/Assets/Images/Group 172.svg" alt=""/>
                {/* <span className="px-2 pt-1"> Number</span>{" "} */}
                
              <span className="px-2 pt-1 md:hidden lg:flex"> Parents Number Requested </span>
              <span className="px-2 pt-1 md:flex lg:hidden"> Parents No Requested </span>

              </button>
              :
              <button
              onClick={() => { 
                if(props?.cardData?.profileblockedByMe == "Y"){
                  props?.callBackBlockProfile()
                }else {
                  props.callbackParentNumberRequest()
                }
                }}                 
                className=" flex text-[#039511] text-[12px] "
              >
                <img className="  w-5" src="/Assets/Images/Group 172.svg" alt=""/>
                {/* <span className="px-2 pt-1"> Number</span>{" "} */}
                
              <span className="px-2 pt-1 md:hidden lg:flex "> Request Parents Number</span>
              <span className="px-2 pt-1 md:flex lg:hidden "> Request Parents No</span>
              </button>
            }


          </div>
        </div>
      </div>
    </>
  );
};

const MatchesProfileCard2 = (props) => {

  const { t: trans } = useTranslation();

  const [buttonAction, setButtonAction] = useState({
    select: props?.cardData?.selectedprofile ?? false,
    reject: props?.cardData?.selectedrejected ?? false,
    message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    phoneNo: props?.cardData?.selectedphoneno ?? false,
    parentsPhoneNo: props?.cardData?.selectedparentno ?? false,
     photoRequest: props?.cardData?.profilerequestcheck ?? false
  });

  useEffect(() => {
    // //console.log("card use effect");
  }, [buttonAction]);
  useEffect(() => {
    //console.log("props updated");
    setButtonAction({
      select: props?.cardData?.selectedprofile ?? false,
      reject: props?.cardData?.selectedrejected ?? false,
      message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
      phoneNo: props?.cardData?.selectedphoneno ?? false,
      parentsPhoneNo: props?.cardData?.selectedparentno ?? false,
       photoRequest: props?.cardData?.profilerequestcheck ?? false
    });
  }, [props]);
  return (
    <div className="bg-white mx-2">
      <div className="flex grid grid-cols-1 mx-5">
        <div
          className="rounded-b-[10px] "
          style={{ boxShadow: "0px 4px 1px #D10A11" }}
        >
          <div className="flex ">
            <div className="flex relative">
              <div className="w-[16rem] lg:w-[20rem] md:w-[18rem]">
                <img
                 onClick={() => 
                  props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                  props.callBackNav()
                :
                props.cardData.profileStatus == "inactive"?
                props.viewProfileStatusPopup("Profile has been inactive")
                :props.cardData.profileStatus == "hidden" ?
                props.viewProfileStatusPopup("Profile has been hidden")
                :props.cardData.profileStatus == "delete" ?
                props.viewProfileStatusPopup("Profile has been deleted")
                :props.cardData.profileStatus == "suspension" ?
                props.viewProfileStatusPopup("Profile has been suspended")
                :""}
                  onDoubleClick={() => { }}
                  src={props.cardData.profileurl} //"/Assets/Images/image.png"
                  className={`${props?.cardData?.profileblurPhoto === "Y"
                    ? "blur-[2px]"
                    : ""
                    }  object-cover w-full h-[18rem] md:h-[18rem] lg:h-[21rem] rounded-tl-[10px] rounded-bl-md cursor-pointer`}
                  alt=""
                />
              </div>
              {props?.cardData?.profilefromPasswordProtected === "Y" && (
                <div
                onClick={() => 
                  props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                  props.callBackNav()
                  :
                  props.cardData.profileStatus == "inactive"?
                  props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.profileStatus == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.profileStatus == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.profileStatus == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""}                  className="absolute top-0 flex justify-center items-center w-[18rem] lg:w-[20rem] md:w-[18rem] h-[18rem] md:h-[18rem] lg:h-[21rem] cursor-pointer"
                >
                  <img
                    className="h-[2rem] w-[2rem]"
                    src={lockicon}
                    alt="Person"
                  />
                </div>
              )}
              {props?.cardData?.isAvatarPic && (
                <div className="absolute top-0 flex justify-center items-end w-[18rem] lg:w-[20rem] md:w-[18rem] h-[18rem] md:h-[18rem] lg:h-[21rem] cursor-pointer">
                  <span
                      onClick={() => {

                     if(props?.cardData?.profilerequestcheck === true){

                              }
                    else if (props?.cardData?.profilerequestcheck === false) {
                       setButtonAction({
                        ...buttonAction,
                        photoRequest: !buttonAction.photoRequest,
                      });
                      props.callbackRequestPhoto(
                        
                      );
                    }
                  }}
                    className="text-[#ffffff] w-[100%] bg-[#D10A11] h-[2rem] flex justify-center items-center text-center font-bold "
                  >
                    {props?.cardData?.profilerequestcheck
                      ? trans('view_profile.photo_requested')
                      : trans('view_profile.request_photo')}
                  </span>
                </div>
              )}
            </div>
            <div className="mt-[1rem]">
              <div className="absolute   left-[41px] flex  bg-[#9F9F9F] rounded-[6px]  md:hidden ">
                {props.cardData.profilelastOnline === "Online Now" ? (
                  <>
                    {" "}
                    <img
                      className="w-[1rem]"
                      src={OnlineImage}
                      alt=""
                    />
                    <p className="text-[#000000] font-bold text-[13px] px-1">
                      {trans('view_profile.Online')}
                    </p>
                  </>
                ) : (
                  <span className="text-[#000000] font-bold text-[13px] px-1">
                    {dateFormat(props.cardData.profilelastOnline)}
                  </span>
                )}
              </div>
              <div className="absolute   right-[123px] flex  bg-[#FEBABA] rounded-[6px] px-1 md:hidden  ">
                <img className="" src={ProfileCount} alt=""/>
                <p className="text-[#000000] font-bold text-[13px] px-1">5</p>
              </div>
            </div>
            {/* <div className="absolute top-[189px] md:top-[200px] left-[30px] flex py-1 bg-[#9F9F9F] rounded-[6px] px-2">
              <img className="" src={OnlineImage} />
              <p className="text-[#000000] font-bold text-[13px] px-1">online</p>
            </div> */}
            {/* <div className="absolute top-[189px] md:top-[200px] right-[28px] flex py-1 bg-[#FEBABA] rounded-[6px] px-2">
              <img className="" src={ProfileCount} />
              <p className="text-[#000000] font-bold text-[13px] px-1">5</p>
            </div> */}
            <div className=" bg-[#E9E9E9] grid items-center p-2 rounded-tr-md ">
              <div
                className=" py-1 flex flex-col items-center"
                onClick={() => {
                  setButtonAction({
                    ...buttonAction,
                    select: !buttonAction.select,
                  });

                  props.callbackSelectReject(
                    "SELECT",
                    buttonAction.select ? "Selected" : "Select"
                  );
                }}
              >
                <img
                  className="w-4 h-4"
                  src={
                    buttonAction.select
                      ? "/Assets/Images/Group 169 green.svg"
                      : "/Assets/Images/Group 169.svg"
                  }
                  alt=""
                />
                <span
                  className={`${buttonAction.select ? "text-[#039511]" : "text-[#7A7A7A]"
                    }  text-[10px] `}
                >
                  {buttonAction.select ? "Selected" : "Select"}
                </span>
              </div>
              <div
                className=" py-1 flex flex-col items-center"
                onClick={() => {
                  setButtonAction({
                    ...buttonAction,
                    reject: !buttonAction.reject,
                  });

                  props.callbackSelectReject(
                    "REJECT",
                    buttonAction.reject ? "Rejected" : "Reject"
                  );
                }}
              >
                <img
                  className="w-4 h-4"
                  src={
                    buttonAction.reject
                      ? "/Assets/Images/Group 166 red.svg"
                      : "/Assets/Images/Group 166.svg"
                  }
                  alt=""
                />
                <span
                  className={`${buttonAction.reject ? "text-[#D10A11]" : "text-[#7A7A7A]"
                    } text-[10px]`}
                >
                  {buttonAction.reject ? "Rejected" : "Reject"}
                </span>
              </div>
              <div
                className="flex py-1 flex-col items-center"
                onClick={() => {
                  // if (buttonAction.message === false) {
                  //   setButtonAction({ ...buttonAction, message: true });
                  // }
                  props.callbackSelectReject("MESSAGE", "")
                }}
              >
                <img
                  className="w-4 h-4"
                  src={
                    buttonAction.message
                      ? "/Assets/Images/Group 185 green.svg"
                      : "/Assets/Images/Group 185.svg"
                  }
                  alt=""
                />
                <span
                  className={`${buttonAction.message ? "text-[#039511]" : "text-[#7A7A7A]"
                    }  text-[10px]`}
                >{buttonAction.message
                  ?
                  "Messaged" : "Message"}
                </span>
              </div>
              <div className="flex py-1 flex-col items-center">
                <img
                  className="w-4 h-4"
                  src={
                    buttonAction.phoneNo
                      ? "/Assets/Images/Group 173.svg"
                      : "/Assets/Images/Group 1731.svg"
                  }
                  alt=""
                />
                <span
                  className={`${buttonAction.phoneNo ? "text-[#D10A11] font-bold" : "text-[#7A7A7A]"
                    } text-center text-[10px]`}
                >
                  {trans('view_profile.phone_number')}
                </span>
              </div>
              <div className="flex py-1 flex-col items-center">
                <img
                  className="w-4 h-4"
                  src={
                    buttonAction.parentsPhoneNo
                      ? "/Assets/Images/Group 172.svg"
                      : "/Assets/Images/Group 174.svg"
                  }
                  alt=""
                />
                <span
                  className={`${buttonAction.parentsPhoneNo
                    ? "text-[#039511] font-bold "
                    : "text-[#7A7A7A]"
                    }   text-center text-[10px]`}
                >
                  Parents Number
                </span>
              </div>
            </div>
          </div>
          <div className="p-2 ">
            <div className="">
              <p className=" text-[18px] font-bold text-[#D10A11]">
                {props.cardData.profilename}
              </p>
            </div>
            <p className=" whitespace-nowrap mt-1 text-[#7A7A7A] text-[14px]">
              {props.cardData.profileage}, {props.cardData.profileheight},{" "}
              {props.cardData.profilenativelanguage},{" "}
              {props.cardData.profileoccupation}
            </p>
            <div className="pb-7 ">
              <span className=" text-[#7A7A7A] text-[14px]">
                {props.cardData.profilecaste},
              </span>
              <span className="text-[#7A7A7A] text-[14px]">
                {props.cardData.profilecity}, {props.cardData.profilestate}
              </span>
            </div>
             {props?.title == "Selected Profile" &&
                  <p className="text-[#7A7A7A] text-[9pt] md:text-[14px] lg:text-[14px] opacity-[0.8] h-[1rem]">
                    {props.cardData.profileStatus == "inactive"?
                      "Profile has been inactive"
                      :props.cardData.profileStatus == "hidden"?
                      "Profile has been hidden"
                      :props.cardData.profileStatus == "delete"?
                      "Profile has been deleted"
                      :props.cardData.profileStatus == "suspension"?
                      "Profile has been suspened"
                      :""
                    }
                  </p>
}
          </div>
        </div>
      </div>
    </div>
  );
};
export {
  ProfileCard,
  MatchesProfileCard,
  ResponseProfileCard,
  ResponseProfileCard2,
  ProfileLookingForCard,
  MatchesProfileCard2,
};
