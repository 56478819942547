import {
  HELP_DATA_DETAIL_BEFORE_LOGIN, HELP_DATA_DETAIL_BEFORE_LOGIN_API_ERROR, HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT, HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT_API_ERROR, HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT_SUCCESS, HELP_DATA_DETAIL_BEFORE_LOGIN_SUCCESS,
  } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const contact = (state = initialState, action) => {
    ////console.log(action.type,"12345");
    switch (action.type) {
      case HELP_DATA_DETAIL_BEFORE_LOGIN:
        state = {
          ...state,
          loading: true,
        };
        break;
        case HELP_DATA_DETAIL_BEFORE_LOGIN_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          helpDataDetail:action.payload.data,
          loading: false,
        };
        break;
      case HELP_DATA_DETAIL_BEFORE_LOGIN_API_ERROR:
        ////console.log( action.payload.data,"123456");
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

        //FAQ form submit
        case HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT:
          state = {
            ...state,
            loading: true,
          };
          break;
          case HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT_SUCCESS:
          //console.log(action.payload.data,"statse");
          state = {
            ...state,
            helpFormSubmitDetail:action.payload.data,
            loading: false,
          };
          break;
        case HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT_API_ERROR:
          ////console.log( action.payload.data,"123456");
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
          break;
       
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default contact;
  