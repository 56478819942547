import { useMatch } from 'react-router-dom'
import RouteSegments, { RoutePaths } from '../Routes/RouteSegment'

function useModule() {
    const isV3User = localStorage.getItem("isV3User")

    const inboxMatch = useMatch(`/${RoutePaths.INBOX}/*`)
    const dashboardMatch = useMatch(`${RouteSegments.DASHBOARD}/*`)
    const editProfileMatch = useMatch(`/${isV3User ? RouteSegments.EDITPROFILE : RoutePaths.EDITPROFILE}/*`)
    const searchMatch = useMatch(`${RouteSegments.SEARCH}/*`)
    const notificationsMatch = useMatch(`${isV3User ? RouteSegments.NOTIFICATION_LITE : RouteSegments.NOTIFICATION}/*`)
    const payNowMatch = useMatch(`${RouteSegments.MEMBERSHIP_REGISTER()}`)

    const isInbox = inboxMatch != null;
    const isMatch = dashboardMatch != null;
    const isEditProfileMatch = editProfileMatch != null;
    const isSearch = searchMatch != null;
    const isNotifications = notificationsMatch != null;
    const isMemberShip = payNowMatch != null;

    return { isInbox, isMatch, isEditProfileMatch, isSearch, isNotifications, isMemberShip }
}

export default useModule