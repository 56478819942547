import { BLOCK_PROFILE, BLOCK_PROFILE_API_ERROR, BLOCK_PROFILE_SUCCESS, CLEAR_ASTRO_BALANCE, GET_ASTRO_BALANCE, GET_ASTRO_BALANCE_API_ERROR, GET_ASTRO_BALANCE_SUCCESS, GET_HOROSCOPE_MATCH, GET_HOROSCOPE_MATCH_API_ERROR, GET_HOROSCOPE_MATCH_SUCCESS, MESSAGE_THREAD, MESSAGE_THREAD_API_ERROR, MESSAGE_THREAD_SUCCESS, SENT_MESSAGE, SENT_MESSAGE_API_ERROR, SENT_MESSAGE_SUCCESS } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const Conversation = (state = initialState, action) => {
    //console.log(action.type,"12345");
    switch (action.type) {
      case MESSAGE_THREAD:
        state = {
          ...state,
          loading: true,
        };
        break;
        case MESSAGE_THREAD_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          MessageData:action.payload.data,
          loading: false,
        };
        break;
      case MESSAGE_THREAD_API_ERROR:
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

        case SENT_MESSAGE:
          state = {
            ...state,
            loading: true,
          };
          break;
          case SENT_MESSAGE_SUCCESS:
          //console.log(action.payload.data,"statse");
          state = {
            ...state,
            sentMessageData:action.payload.data,
            loading: false,
          };
          break;
        case SENT_MESSAGE_API_ERROR:
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
          break;

          case BLOCK_PROFILE:
          state = {
            ...state,
            loading: true,
          };
          break;
          case BLOCK_PROFILE_SUCCESS:
          //console.log(action.payload.data,"statse");
          state = {
            ...state,
            blockProfileData:action.payload.data,
            loading: false,
          };
          break;
        case BLOCK_PROFILE_API_ERROR:
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
          break;

          case GET_ASTRO_BALANCE:
            state = {
              ...state,
              loading: true,
            };
            break;
      case CLEAR_ASTRO_BALANCE:
        return {
          ...state,
          astroBalanceData: null,
        }
            case GET_ASTRO_BALANCE_SUCCESS:
            //console.log(action.payload.data,"statse");
            state = {
              ...state,
              astroBalanceData:action.payload.data,
              loading: false,
            };
            break;
          case GET_ASTRO_BALANCE_API_ERROR:
            state = {
              ...state,
              error: action.payload.data,
              loading: false,
              isUserLogout: false,
            };
            break;

            case GET_HOROSCOPE_MATCH:
              state = {
                ...state,
                loading: true,
              };
              break;
              case GET_HOROSCOPE_MATCH_SUCCESS:
              //console.log(action.payload.data,"statse");
              state = {
                ...state,
                horoMatchData:action.payload.data,
                loading: false,
              };
              break;
            case GET_HOROSCOPE_MATCH_API_ERROR:
              state = {
                ...state,
                error: action.payload.data,
                loading: false,
                isUserLogout: false,
              };
              break;

      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default Conversation;
  