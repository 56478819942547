import {  BANNER_REDIRECTION_AKHAYATRITIYA, BANNER_REDIRECTION_AKHAYATRITIYA_API_ERROR, BANNER_REDIRECTION_AKHAYATRITIYA_SUCCESS, BANNER_REDIRECTION_DEVELOPER, BANNER_REDIRECTION_DEVELOPER_API_ERROR, BANNER_REDIRECTION_DEVELOPER_SUCCESS } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const BannerRedirection = (state = initialState, action) => {
    ////console.log(action.type,"12345");
    switch (action.type) {
          //cpay now
        case BANNER_REDIRECTION_DEVELOPER:
          state = {
            ...state,
            loading: true,
          };
          break;
          case BANNER_REDIRECTION_DEVELOPER_SUCCESS:
          //console.log(action.payload.data,"statse");
          state = {
            ...state,
            bannerRedireectionDeveloperData:action.payload.data,
            loading: false,
          };
          break;
        case BANNER_REDIRECTION_DEVELOPER_API_ERROR:
          ////console.log( action.payload.data,"123456");
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
          break;

          case BANNER_REDIRECTION_AKHAYATRITIYA:
          state = {
            ...state,
            loading: true,
          };
          break;
          case BANNER_REDIRECTION_AKHAYATRITIYA_SUCCESS:
          //console.log(action.payload.data,"statse");
          state = {
            ...state,
            bannerRedireectionDeveloperData:action.payload.data,
            loading: false,
          };
          break;
        case BANNER_REDIRECTION_AKHAYATRITIYA_API_ERROR:
          ////console.log( action.payload.data,"123456");
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
          break;

      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default BannerRedirection;
  