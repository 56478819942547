import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    API_ERROR,
    GET_BASIC_DATA,
    GET_BASIC_DATA_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_API_ERROR,
    SEND_OTP,
    SEND_OTP_SUCCESS,
    SEND_OTP_ERROR,
    OTP_VALIDATION,
    OTP_VALIDATION_SUCCESS,
    OTP_VALIDATION_ERROR,
    MOBILE_VERIFICATION,
    MOBILE_VERIFICATION_SUCCESS,
    MOBILE_VERIFICATION_ERROR,
    REQUEST_AGAIN,
    REQUEST_AGAIN_SUCCESS,
    REQUEST_AGAIN_ERROR,
    MOBILE_VERIFICATION_FRESH,
    MOBILE_VERIFICATION_FRESH_SUCCESS,
    MOBILE_VERIFICATION_FRESH_ERROR,
    CHANGE_MOBILE_NO,
    CHANGE_MOBILE_NO_SUCCESS,
    CHANGE_MOBILE_NO_ERROR,
    MORE_LINK_CONTENT,
    MORE_LINK_CONTENT_SUCCESS,
    MORE_LINK_CONTENT_API_ERROR,
    LOGIN_FROM_ADMIN_USER,
    LOGIN_FROM_ADMIN_SUCCESS,
    LOGIN_FROM_ADMIN_API_ERROR,
    LOGIN_FROM_SMS_USER,
    LOGIN_FROM_SMS_SUCCESS,
    LOGIN_FROM_SMS_API_ERROR,
    COMMUNITY_SITE_DETAIL,
    COMMUNITY_SITE_DETAIL_SUCCESS,
    COMMUNITY_SITE_DETAIL_API_ERROR,
    LOGIN_FROM_EMAIL_USER,
    LOGIN_FROM_EMAIL_SUCCESS,
    LOGIN_FROM_EMAIL_API_ERROR,
    LOGIN_FOR_AKHAYATRITIYA,
    LOGIN_FOR_AKHAYATRITIYA_SUCCESS,
    LOGIN_FOR_AKHAYATRITIYA_API_ERROR,
    LOGIN_FROM_ADMIN_PARTIAL_USER,
    LOGIN_FROM_ADMIN_PARTIAL_SUCCESS,
    LOGIN_FROM_ADMIN_PARTIAL_API_ERROR,
    SITE_MAP,
    SITE_MAP_SUCCESS,
    SITE_MAP_API_ERROR,
    LOGIN_FROM_OFFLINE,
    LOGIN_FROM_OFFLINE_SUCCESS,
    LOGIN_FROM_OFFLINE_ERROR,
    LOGIN_FOR_VIEW_PROFILE,
    LOGIN_FOR_VIEW_PROFILE_SUCCESS,
    LOGIN_FOR_VIEW_PROFILE_ERROR
  } from "./ActionTypes";
  
  export const loginUser = (user, history) => {
    //console.log(user,"12344");
    return {
      type: LOGIN_USER,
      payload: { user, history },
    };
  };
  
  export const loginSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: LOGIN_SUCCESS,
      payload: user,
    };
  };
  
  export const apiError = error => {
    ////console.log(error,"12345");
    return {
      type: API_ERROR,
      payload: error,
    };
  };

  //change mobile number

  export const changeMobileNo = (user, history) => {
    ////console.log(user,"12344");
    return {
      type: CHANGE_MOBILE_NO,
      payload: { user, history },
    };
  };
  
  export const changeMobileNoSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: CHANGE_MOBILE_NO_SUCCESS,
      payload: user,
    };
  };
  
  export const changeMobileNoapiError = error => {
    ////console.log(error,"12345");
    return {
      type: CHANGE_MOBILE_NO_ERROR,
      payload: error,
    };
  };

  //send otp

  export const sendOTP = (user) => {
    //console.log(user,"12344");
    return {
      type: SEND_OTP,
      payload: { user },
    };
  };
  
  export const sendOTPSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: SEND_OTP_SUCCESS,
      payload: user,
    };
  };
  
  export const sendOTPApiError = error => {
    ////console.log(error,"12345");
    return {
      type: SEND_OTP_ERROR,
      payload: error,
    };
  };

  //otp verification

export const OTPValidation = (user, requestData, phone, history, formik) => {
    //console.log(user, requestData, phone, history,"12344");
    return {
      type: OTP_VALIDATION,
      payload: { user, requestData, phone, history, formik },
    };
  };
  
  export const OTPValidationSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: OTP_VALIDATION_SUCCESS,
      payload: user,
    };
  };
  
  export const OTPValidationApiError = error => {
    ////console.log(error,"12345");
    return {
      type: OTP_VALIDATION_ERROR,
      payload: error
    };
  };

  //otp verification

  export const mobileVerification = (user, requestData, history,setIsSubmitting) => {
    ////console.log(user,"12344");
    return {
      type: MOBILE_VERIFICATION,
      payload: { user, requestData, history,setIsSubmitting },
    };
  };
  
  export const mobileVerificationSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: MOBILE_VERIFICATION_SUCCESS,
      payload: user,
    };
  };
  
  export const mobileVerificationApiError = error => {
    ////console.log(error,"12345");
    return {
      type: MOBILE_VERIFICATION_ERROR,
      payload: error,
    };
  };

    //request again

    export const requestAgain = () => {
      ////console.log(user,"12344");
      return {
        type: REQUEST_AGAIN,
        payload: {},
      };
    };
    
    export const requestAgainSuccess = user => {
      ////console.log(user,"12344");
      return {
        type: REQUEST_AGAIN_SUCCESS,
        payload: user,
      };
    };
    
    export const requestAgainApiError = error => {
      ////console.log(error,"12345");
      return {
        type: REQUEST_AGAIN_ERROR,
        payload: error,
      };
    };

     //fresh mobile verification

     export const mobileVerificationFresh = () => {
      ////console.log(user,"12344");
      return {
        type: MOBILE_VERIFICATION_FRESH,
        payload: {},
      };
    };
    
    export const mobileVerificationFreshSuccess = user => {
      //console.log(user,"12344");
      return {
        type: MOBILE_VERIFICATION_FRESH_SUCCESS,
        payload: user,
      };
    };
    
    export const mobileVerificationFreshApiError = error => {
      ////console.log(error,"12345");
      return {
        type: MOBILE_VERIFICATION_FRESH_ERROR,
        payload: error,
      };
    };

  //get basic data

  export const getBasicData = (userId) => {
    ////console.log(userId,"12344");
    return {
      type: GET_BASIC_DATA,
      payload: { userId },
    };
  };

  export const getBasicDataSuccess = data => {
    ////console.log(data,"12344");
    return {
      type: GET_BASIC_DATA_SUCCESS,
      payload: data,
    };
  };

  //reset password

  export const resetPassword = (user, id, history) => {
    //console.log(user, id, history,"123449090");
    return {
      type: RESET_PASSWORD,
      payload: { user, id, history },
    };
  };
  
  export const resetPasswordSuccess = user => {
    //console.log(user,"12344");
    return {
      type: RESET_PASSWORD_SUCCESS,
      payload: user,
    };
  };
  
  export const resetPasswordApiError = error => {
    ////console.log(error,"12345");
    return {
      type: RESET_PASSWORD_API_ERROR,
      payload: error,
    };
  };

  //more link content

  export const moreLinkContent = (user) => {
    ////console.log(history,"12344");
    return {
      type: MORE_LINK_CONTENT,
      payload: { user },
    };
  };
  
  export const moreLinkContentSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: MORE_LINK_CONTENT_SUCCESS,
      payload: user,
    };
  };
  
  export const moreLinkContentApiError = error => {
    ////console.log(error,"12345");
    return {
      type: MORE_LINK_CONTENT_API_ERROR,
      payload: error,
    };
  };

  //login from admin

  export const loginFromAdminUser = (user, history, from) => {
    //console.log(user,"12344");
    return {
      type: LOGIN_FROM_ADMIN_USER,
      payload: { user, history, from },
    };
  };
  
  export const loginFromAdminSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: LOGIN_FROM_ADMIN_SUCCESS,
      payload: user,
    };
  };
  
  export const loginFromAdminApiError = error => {
    ////console.log(error,"12345");
    return {
      type: LOGIN_FROM_ADMIN_API_ERROR,
      payload: error,
    };
  };

  //login from admin partial

  export const loginFromAdminPartialUser = (user, history, from) => {
    //console.log(user,"12344");
    return {
      type: LOGIN_FROM_ADMIN_PARTIAL_USER,
      payload: { user, history, from },
    };
  };
  
  export const loginFromAdminPartialSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: LOGIN_FROM_ADMIN_PARTIAL_SUCCESS,
      payload: user,
    };
  };
  
  export const loginFromAdminPartialApiError = error => {
    ////console.log(error,"12345");
    return {
      type: LOGIN_FROM_ADMIN_PARTIAL_API_ERROR,
      payload: error,
    };
  };

  //login from email

  export const loginFromEmailUser = (user, history, from) => {
    //console.log(user,"12344");
    return {
      type: LOGIN_FROM_EMAIL_USER,
      payload: { user, history, from },
    };
  };
  
  export const loginFromEmailSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: LOGIN_FROM_EMAIL_SUCCESS,
      payload: user,
    };
  };
  
  export const loginFromEmailApiError = error => {
    ////console.log(error,"12345");
    return {
      type: LOGIN_FROM_EMAIL_API_ERROR,
      payload: error,
    };
  };

   //login from sms

   export const loginFromSMSUser = (user, history, from) => {
    //console.log(user,"12344");
    return {
      type: LOGIN_FROM_SMS_USER,
      payload: { user, history, from },
    };
  };
  
  export const loginFromSMSSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: LOGIN_FROM_SMS_SUCCESS,
      payload: user,
    };
  };
  
  export const loginFromSMSApiError = error => {
    ////console.log(error,"12345");
    return {
      type: LOGIN_FROM_SMS_API_ERROR,
      payload: error,
    };
  };

     //login for akshatya

     export const loginForAkhayatritiya = (user, history, from) => {
      //console.log(user,"12344");
      return {
        type: LOGIN_FOR_AKHAYATRITIYA,
        payload: { user, history, from },
      };
    };
    
    export const loginForAkhayatritiyaSuccess = user => {
      ////console.log(user,"12344");
      return {
        type: LOGIN_FOR_AKHAYATRITIYA_SUCCESS,
        payload: user,
      };
    };
    
    export const loginForAkhayatritiyaApiError = error => {
      ////console.log(error,"12345");
      return {
        type: LOGIN_FOR_AKHAYATRITIYA_API_ERROR,
        payload: error,
      };
    };

  //communtiy site details

  export const communitySiteDetail = (value, history, from) => {
    return {
      type: COMMUNITY_SITE_DETAIL,
      payload: { value, history, from },
    };
  };
  
  export const communitySiteDetailSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: COMMUNITY_SITE_DETAIL_SUCCESS,
      payload: user,
    };
  };
  
  export const communitySiteDetailApiError = error => {
    ////console.log(error,"12345");
    return {
      type: COMMUNITY_SITE_DETAIL_API_ERROR,
      payload: error,
    };
  };

  //site map details

  export const siteMapDetail = (value) => {
    return {
      type: SITE_MAP,
      payload: { value },
    };
  };
  
  export const siteMapDetailSuccess = user => {
    ////console.log(user,"12344");
    return {
      type: SITE_MAP_SUCCESS,
      payload: user,
    };
  };
  
  export const siteMapDetailApiError = error => {
    ////console.log(error,"12345");
    return {
      type: SITE_MAP_API_ERROR,
      payload: error,
    };
  };

  //login for offline

  export const loginFromOffline = (user, history, from) => {
  //console.log(user,"12344");
    return {
      type: LOGIN_FROM_OFFLINE,
      payload: { user, history, from },
    };
  };

  export const loginFromOfflineSuccess = user => {
  ////console.log(user,"12344");
    return {
      type: LOGIN_FROM_OFFLINE_SUCCESS,
      payload: user,
    };
  };

  export const loginFromOfflineError = error => {
  ////console.log(error,"12345");
    return {
      type: LOGIN_FROM_OFFLINE_ERROR,
      payload: error,
    };
  };

  //login for view profile

  export const loginForViewProfile = (user, history, from) => {
    //console.log(user,"12344");
      return {
        type: LOGIN_FOR_VIEW_PROFILE,
        payload: { user, history, from },
      };
    };
  
    export const loginForViewProfileSuccess = user => {
    ////console.log(user,"12344");
      return {
        type: LOGIN_FOR_VIEW_PROFILE_SUCCESS,
        payload: user,
      };
    };
  
    export const loginForViewProfileError = error => {
    ////console.log(error,"12345");
      return {
        type: LOGIN_FOR_VIEW_PROFILE_ERROR,
        payload: error,
      };
    };