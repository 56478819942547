import { SEARCH_PAGE_CHECK_REQUEST, SEARCH_PAGE_CHECK_REQUEST_API_ERROR, SEARCH_PAGE_CHECK_REQUEST_SUCCESS } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const SearchPageCheck = (state = initialState, action) => {
    //console.log(action.type,"12345");
    switch (action.type) {
      case SEARCH_PAGE_CHECK_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
        case SEARCH_PAGE_CHECK_REQUEST_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          searchPageCheckResponseData:action.payload.data,
          loading: false,
        };
        break;
      case SEARCH_PAGE_CHECK_REQUEST_API_ERROR:
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default SearchPageCheck;
  