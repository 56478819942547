import React, { useState, useEffect, useContext } from "react";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { GET, POST } from "../../Services/api_services";
import Constants, {
  loginFrom,
  EventName,
  EventKeys,
} from "../../Constants/constants";
import axios from "axios";
import { LoginContext } from "../../Contexts/LoginContext";

import { useSearchParams } from "react-router-dom";
import { validateImage } from "../../Utils";
import config from "../../config";
import Loader from "../Loader";
import UploadingImage from "../../Images/Group 964.svg";
import HoroscopeGenarateImage from "../../Images/Group 963@2x.png";
import { useAnalytics } from "../../Hooks/usePageChange";
import "../../Components/ReasonforDeletion/datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"; // Import moment library for date formatting
import HoroComponent from "./HoroComponent";
import { NavBarContext } from "../../Contexts/NavBarContext";
import ImageHoroscopePopup from "../Edit Profile/ImageHoroscopePopup";
import ImageHoroscopePopupBackground from "../../Pages/AfterRegistation/ImageHoroscopePopupBackground";
import { CommonSuccessPopup, NotificationPopup } from "../CommonPopup";
import { Update_Notification_Status } from "../../Helpers/api_helpers";
import { getDeviceInfo, getGroupAuthId } from "../Kalyanlite/utils";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const HoroBasic = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const hoursArray = [...new Array(12)];
  const minutesSecondsArray = [...new Array(59)];
  const [placeDataList, setplaceDataList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });
  const { completeNow,setCompleteNow } = useContext(NavBarContext);
  const [showHoroScopeLoader,setShowHoroScopeLoader  ] = useState(true)
  const [editMyProfileData, setEditMyProfielData] = useState();
  const [generatedHoroScopeUrl, setgeneratedHoroscopeurl] = useState({ url: "", flag: "", show: false });

  const [horoscopeData, setHoroscopeData] = useState();
  const notificationId = sessionStorage.getItem(
    Constants.loginLocalStorageKeys.notificationId
  )

  const {t:trans} = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { onClickTrack } = useAnalytics();

  useEffect(() => {
    console.log("generatedHoroScopeUrl", generatedHoroScopeUrl);
    console.log(horoscopeData?.language[0]?.name,"horoscopeData?.language[0]?.name");
  }, [
 
    horoscopeData,
    generatedHoroScopeUrl
   
  ]);

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );


  // const SubmitCall = (value) => {
  //   //console.log(value);
  // };

  const [generatedUrl, setgeneratedurl] = useState();
  useEffect(() => {}, [generatedUrl, placeDataList]);

  const navigateToProfile = () => {
    searchParams.set("tab", "1");
    setSearchParams(searchParams);
  };

  const callNotificationStatus = async(data,type)=>{    
    const request  = {      
        "email" : localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
        "userId" : localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
        "isClicked" : data,
        "notificationId" : notificationId,
        "clickButton" : type
    }
    Update_Notification_Status(request);

    if (!data) {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId,notificationId)
    }
  
  }

  const showNotification = ({ description = "", title = `${trans('photos.status')}!` }) => {
    setNotification({ show: true, title: title, message: description });
  };

  const onHoroScopeFileChange = (file, type) => {
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Height and Width must be more than 100px",
        });
      },
      onExtensionError: (fileType) => {
        showNotification({
          title: "Invalid image",
          description: "Invalid extension!",
        });
      },
      onFileSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Image size should not be greater than 10mb",
        });
      },
      onSuccess: (img, readerStr) => {
        handleUpload(file, type);
      },
    });
  };
  const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId
  const handleUpload = async (file, type) => {
    setIsUploading(true);
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("trustDocumentUploadFrom", loginFrom);
      fileData.append("documents", file);
      fileData.append("type", type);
      if(props?.isFrom == "clcRedirection"){
        fileData.append("notificationId", notificationId);
      }
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("horoscoperequesttypeid", 86);
      fileData.append("recieverprofileid", getGroupAuthId());

      if(props.isFrom=='TrailPack'){
        fileData.append("editviatrialpack", props.editviatrialpack);
        fileData.append("notificationId",NotificationId);
      }
      // let config = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };

      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.trustDocumentUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      //console.log("callBackPopup",data);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          setIsUploading(false);
          setCompleteNow(true)
          if(props?.isFrom == "clcRedirection" || props?.isFrom == "chat" || props?.isFromSms == "yes"){
            props?.OnClose()
            props?.setShowingSuccessPopup({data:data?.data?.data?.fileUploadpath,show:true})
            // setgeneratedHoroscopeurl({url:data?.data?.data?.fileUploadpath,flag:"GenerateHoroscope",show:true});
          }else if(props.from == 'trailpack'){
            props.setClickAddHoro(false);
            props?.postData()
            props.callBackPopup(data?.data?.data?.fileUploadpath, true);
          }
          else{
          props?.setShowHoroPopup(false)
          // alert("Image upload succesfully");
          // props.callBackReload();
          props?.postData()
          props.callBackPopup(data?.data?.data?.fileUploadpath, true);
          // navigateToProfile()
          }
        }
      }
    } else {
      alert("Please upload file less than 10 mb");
    }

    setIsUploading(false);
  };

  const apiCallEditProfile = async (setHoroscope = true) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);
      if(setHoroscope){
        setgeneratedHoroscopeurl({url:data?.data?.horoscope,flag:data?.data?.horoflag,show:false});
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apiCallHoroscope = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice
      ),
      frommanagePage: "Y",
      ...(props.isFrom === 'TrailPack' && { editviatrialpack: props.editviatrialpack }),
      ...(props.isFrom === 'TrailPack' && { notificationId: props.notificationId }),
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.getHoroscopeUrl()}`,
      request
    );
    //console.log("statusCode", data?.data);
    if (statusCode === 200) {
      setHoroscopeData(data?.data);
    } else if (statusCode === 401) {
      logout();
    }
  };

  


  useEffect(()=>{
    apiCallHoroscope()
  },[])

  const [showhoroscope, setShowHoroscope] = useState(false);

  const handelClick = () => {
    setShowHoroscope(true);
  };
  const closeNotification = () => {
    setNotification({ show: false, message: "", title: "" });
  };
 
  return (
    <div>
      {isUploading ? (
        <Loader
          className="loaderTransperancy"
          loaderClassName="loaderTransperancySpin"
        />
      ) : (
        ""
      )}
     {
        notification.show
          ? <NotificationPopup close={closeNotification} title={notification.title} content={notification.message} />
          : null
      }
     {generatedHoroScopeUrl.show ? (
            <ImageHoroscopePopup
              console={console.log("123456")}
              close={() => {
                setgeneratedHoroscopeurl(prev=>({...prev,show:false}))
                if(props?.isFrom == "chat" || props?.isFromSms == "yes"){
                  props?.OnClose()
                }
                }
              }
              title={"View Horoscope"}
              deleteImage={true}              
              isShowEditBtn={false}
            >
              {generatedHoroScopeUrl.flag === "GenerateHoroscope" ? (
                <div className="relative">
                <iframe
                  title="Horoscope"
                  src={generatedHoroScopeUrl.url}
                  className="w-[70vw] lg:w-[60vw] h-[50vh]"
                />
                {/* {showHoroScopeLoader === true? <div className="absolute maxabsolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><Loader /></div>:null} */}
                </div>
              ) : (
                <img
                  alt=""
                  src={generatedHoroScopeUrl.url}
                  className="w-[70vw] lg:w-[60vw] h-[50vh]"
                />
              )}
            </ImageHoroscopePopup>
          ) : (
            <></>
          )}

        <div className="md:block">
          <div
            className={` text-[13px] md:p-[1rem] lg:p-0 bg-[#fffff] rounded-[14px]`}
          >
            <div className={` "lg:grid lg:grid-cols-12"`}>
              {showhoroscope ? (
               <HoroComponent
               postData={props?.postData}
               data={horoscopeData}
               isFrom={props?.isFrom}
               isFromSms={props?.isFromSms}
               callBackPopup={(url, isUpload) => {
                console.log('url-12', url,isUpload);
                if (isUpload === false) {
                    setgeneratedHoroscopeurl({ url, flag: 'GenerateHoroscope', show: true });
                    setShowHoroScopeLoader(true)
                }
                apiCallEditProfile(false)

              }}
                editviatrialpack= {props.editviatrialpack}
                from= {props.from}
                />
              ) :
              props?.isFrom == "clcRedirection" || props?.isFrom == "chat" || props?.isFromSms == "yes" ? 
              ( 
              <>
                <HoroComponent
                  postData={props?.postData}
                  data={horoscopeData}
                  isFrom={props?.isFrom}
                  isFromSms={props?.isFromSms}
                  callBackPopup={(url, isUpload) => {
                  console.log('url-12', url,isUpload);
                  if (isUpload === false) {
                    if(props?.isFrom == "chat" || props?.isFromSms == "yes"){
                      setgeneratedHoroscopeurl({ url, flag: 'GenerateHoroscope', show: true });
                      setShowHoroScopeLoader(true)
                    }
                    else{
                      props?.OnClose()
                      props?.setShowingSuccessPopup({data:url,show:true})
                      setShowHoroScopeLoader(true)
                    }
                  }
                  apiCallEditProfile(false)
                  }}
                  callNotificationStatus = {callNotificationStatus}
                 />
                  <div>
                    <div className={` space-y-3  ${props?.isFrom == "clcRedirection" ? "" : "md:py-[1rem]"} lg:py-0 md:mx-[1rem] lg:mx-0  justify-center items-center `}>
                      <div
                        className={` ${
                          props?.menu == "addhoro"
                            ? "flex justify-center"
                            : "lg:flex lg:space-x-5"
                        }  `}
                      >
                        <div
                          className={`hidden ${
                            props?.menu == "addhoro"
                              ? "flex justify-center items-center"
                              : ""
                          } md:block`}
                        >
                          <div className=" border-[4px] border-[#D10A11] rounded-full md:hidden lg:block ">
                            <p className="text-[#D10A11] text-[22px] p-[0.4rem] font-bold ">
                              OR
                            </p>
                          </div>
                        </div>
                        <div className="lg:hidden flex justify-center items-center m-[1rem] ">
                          <div className=" border-[3px] border-[#D10A11] text-[#D10A11] text-center text-[16px]  rounded-[50%] w-[60px] h-[60px] flex justify-center items-center lg:block ">
                            OR
                          </div>
                        </div>
                      </div>
                      <label className=" flex justify-center ">
                        <div
                          className="
                  flex items-center space-x-2 justify-end  font-bold text-white bg-[#37B37F] px-[2.5rem] rounded-[4px] py-2 md:text-[11px] lg:text-[11px]  
                  "
                        >
                          <img
                            alt=""
                            className=" md:w-[1rem] lg:w-[1rem]"
                            src={UploadingImage}
                          />
                          <p
                            onClick={() => {
                              onClickTrack(EventName.CLC_Upload_Button_V3_Site_Generate_Upload_Horoscope_Popup);
                              callNotificationStatus(true,"upload Horoscope")
                            }}
                          >
                            {" "}
                            {isUploading
                              ? "Uploading..."
                              : trans('trial_pack_popup__horscope_det.upload_horoscope')}{" "}
                          </p>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            disabled={isUploading}
                            onChange={(event) => {
                              // //console.log(
                              //   "file",
                              //   URL.createObjectURL(event.target.files[0])
                              // );
                              if (event.target.files[0]) {
                                onHoroScopeFileChange(
                                  event.target.files[0],
                                  "horoscope"
                                );
                              }
                            }}
                            accept="image/jpeg, image/png"
                          />
                        </div>
                      </label>
                    </div>
                  </div>
              </>
                )
              : (
                <div>
                   <p className="text-[#000000] items-center font-semibold text-[18px] pt-2 justify-center  text-center">
             {trans('trial_pack_popup__horscope_det.add_horoscope')}
              </p>
              {props?.isFrom != "clcRedirection" &&
                  <div
                    onClick={()=>{
                      handelClick();
                      props.from == 'trailpack' && onClickTrack(EventName.Trial_Pack_popup_Add_Horoscope_Generate_Button)
                    }}
                    className=" flex justify-center mt-[2rem] md:mb-[1rem] "
                  >
                    <button
                      disabled={isGenerating}
                      className="
                   flex items-center space-x-2 justify-end  font-bold text-white bg-[#D10A11] px-[2rem]  lg:px-[2rem] rounded-[4px] py-[0.4rem] lg:py-2  md:text-[11px] lg:text-[11px]  
                  "
                    >
                      <img
                        alt=""
                        className=" w-[1.3rem] md:w-[1rem] lg:w-[1rem]"
                        src={HoroscopeGenarateImage}
                      />
                      <p>
                        {" "}
                        
                        {trans('trial_pack_popup__horscope_det.generate_horoscope')}
                      </p>
                    </button>
                  </div>
              }

                  <div className=" space-y-3  md:py-[1rem] lg:py-0 md:mx-[1rem] lg:mx-0  justify-center items-center ">
                    <div
                      className={` ${
                        props?.menu == "addhoro"
                          ? "flex justify-center"
                          : "lg:flex lg:space-x-5"
                      }  `}
                    >
                      <div
                        className={`hidden ${
                          props?.menu == "addhoro"
                            ? "flex justify-center items-center"
                            : ""
                        } md:block`}
                      >
                        <div className=" border-[4px] border-[#D10A11] rounded-full md:hidden lg:block ">
                          <p className="text-[#D10A11] text-[22px] p-[0.4rem] font-bold ">
                            OR
                          </p>
                        </div>
                      </div>
                      <div className="lg:hidden flex justify-center items-center m-[1rem] ">
                        <div className=" border-[3px] border-[#D10A11] text-[#D10A11] text-center text-[16px]  rounded-[50%] w-[60px] h-[60px] flex justify-center items-center lg:block ">
                          OR
                        </div>
                      </div>
                    </div>
                    <label className=" flex justify-center ">
                      <div
                        className="
                 flex items-center space-x-2 justify-end  font-bold text-white bg-[#37B37F] px-[2.5rem] rounded-[4px] py-2 md:text-[11px] lg:text-[11px]  
                "
                      >
                        <img
                          alt=""
                          className=" md:w-[1rem] lg:w-[1rem]"
                          src={UploadingImage}
                        />
                        <p
                          onClick={() => {
                            if(props.from == 'trailpack'){
                              onClickTrack(EventName.Trial_Pack_popup_Add_Horoscope_Upload_Button)
                            }else{
                            onClickTrack(EventName.profileUploadHoroscope);
                            }
                          }}
                        >
                          {" "}
                          {isUploading
                            ? "Uploading..."
                            : trans('trial_pack_popup__horscope_det.upload_horoscope')}{" "}
                        </p>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          disabled={isUploading}
                          onChange={(event) => {
                            // //console.log(
                            //   "file",
                            //   URL.createObjectURL(event.target.files[0])
                            // );
                            if (event.target.files[0]) {
                              onHoroScopeFileChange(
                                event.target.files[0],
                                "horoscope"
                              );
                            }
                          }}
                          accept="image/jpeg, image/png"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
    </div>
  );
};

export default HoroBasic;