import {
    REGISTER_DETAIL,
    REGISTER_DETAIL_SUCCESS,
    API_ERROR,
    CASTE_LIST,
    GET_CASTE_LIST_SUCCESS,
    SUB_CASTE_LIST,
    GET_SUB_CASTE_LIST_SUCCESS,
    RAASI_LIST,
    GET_RAASI_LIST_SUCCESS,
    COUNTRY_LIST,
    GET_COUNTRY_LIST_SUCCESS,
    STATE_LIST,
    GET_STATE_LIST_SUCCESS,
    PINCODE_LIST,
    GET_PINCODE_LIST_SUCCESS,
    REGISTER_SUBMIT,
    REGISTER_SUBMIT_SUCCESS,
    CITY_LIST,
    GET_CITY_LIST_SUCCESS,
    STAR_LIST,
    GET_STAR_LIST_SUCCESS,
    PROFILE_SUB_CASTE_LIST,
    GET_PROFILE_SUB_CASTE_LIST_SUCCESS,
    VALIDATE_NAME,
    VALIDATE_NAME_SUCCESS,
    VALIDATE_NAME_ERROR,
    DENOMINATION_LIST,
    GET_DENOMINATION_LIST_SUCCESS,
    GET_DENOMINATION_LIST_FAIL
  } from "./ActionTypes";


  //onload register form detail
  export const registerDetail = (payload) => {
    return {
      type: REGISTER_DETAIL,
      payload: payload,
    };
  };

  export const registerDetailSuccess = user => {
    //////console.log(user,"12344");
    return {
      type: REGISTER_DETAIL_SUCCESS,
      payload: user,
    };
  };
  
  export const apiError = error => {
    //////console.log(error,"12345");
    return {
      type: API_ERROR,
      payload: error,
    };
  };

  // caste api call
  export const registerCasteList = (motherTongueId, ReligionId) => {
    //////console.log(motherTongueId, ReligionId,"12344");
    return {
      type: CASTE_LIST,
      payload: {motherTongueId, ReligionId},
    };
  };

  export const getCasteListSuccess = user => {
    console.log(user,"12344");
    return {
      type: GET_CASTE_LIST_SUCCESS,
      payload: user,
    };
  };

   // sub caste api call
   export const registerSubCasteList = (casteId) => {
    //////console.log(casteId,"12344");
    return {
      type: SUB_CASTE_LIST,
      payload: {casteId},
    };
  };

  export const getSubCasteListSuccess = user => {
    //////console.log(user,"12344");
    return {
      type: GET_SUB_CASTE_LIST_SUCCESS,
      payload: user,
    };
  };

   // sub caste api call
   export const profileSubCasteList = (casteId) => {
    //////console.log(casteId,"12344");
    return {
      type: PROFILE_SUB_CASTE_LIST,
      payload: {casteId},
    };
  };

  export const getProfileSubCasteListSuccess = user => {
    //////console.log(user,"12344");
    return {
      type: GET_PROFILE_SUB_CASTE_LIST_SUCCESS,
      payload: user,
    };
  };

  // star api call
  export const registerStarList = (motherTongueId) => {
    //console.log(motherTongueId,"12344");
    return {
      type: STAR_LIST,
      payload: {motherTongueId},
    };
  };

  export const getStarListSuccess = user => {
    //////console.log(user,"12344");
    return {
      type: GET_STAR_LIST_SUCCESS,
      payload: user,
    };
  };

    // raasi api call
    export const registerRaasiList = (starId,motherTounge) => {
      //console.log(starId,"12344");
      return {
        type: RAASI_LIST,
        payload: {starId,motherTounge},
      };
    };
  
    export const getRassiListSuccess = user => {
      //////console.log(user,"12344");
      return {
        type: GET_RAASI_LIST_SUCCESS,
        payload: user,
      };
    };

    // Country residence api call
    export const registerCountryList = (countryId) => {
      //////console.log(countryId,"12344");
      return {
        type: COUNTRY_LIST,
        payload: {countryId},
      };
    };
  
    export const getCountrySuccess = user => {
      //////console.log(user,"12344");
      return {
        type: GET_COUNTRY_LIST_SUCCESS,
        payload: user,
      };
    };

    // State residence api call
    export const registerStateList = (countryId) => {
      //////console.log(countryId,"12344");
      return {
        type: STATE_LIST,
        payload: {countryId},
      };
    };
  
    export const getStateSuccess = user => {
      //////console.log(user,"12344");
      return {
        type: GET_STATE_LIST_SUCCESS,
        payload: user,
      };
    };

    // city residence api call
    export const registerCityList = (stateId) => {
      //////console.log(cityId,"12344");
      return {
        type: CITY_LIST,
        payload: {stateId},
      };
    };
  
    export const getCitySuccess = user => {
      //////console.log(user,"12344");
      return {
        type: GET_CITY_LIST_SUCCESS,
        payload: user,
      };
    };

     // Pincode api call
     export const registerPincodeList = (pincode) => {
      return {
        type: PINCODE_LIST,
        payload: {pincode},
      };
    };
  
    export const getPincodeSuccess = user => {
      return {
        type: GET_PINCODE_LIST_SUCCESS,
        payload: user,
      };
    };

    // form submission api call
    export const registerApiSubmit = (values, history,setSubmitting) => {
      return {
        type: REGISTER_SUBMIT,
        payload: {values, history,setSubmitting},
      };
    };
  
    export const registerApiSubmitSuccess = data => {
      return {
        type: REGISTER_SUBMIT_SUCCESS,
        payload: data,
      };
    };

    //name constraint detail
  export const validateName = (name) => {
    return {
      type: VALIDATE_NAME,
      payload: name
    };
  };

  export const validateNameSuccess = data => {
    //////console.log(user,"12344");
    return {
      type: VALIDATE_NAME_SUCCESS,
      payload: data,
    };
  };

  export const validateNameError = data => {
    //////console.log(user,"12344");
    return {
      type: VALIDATE_NAME_ERROR,
      payload: data,
    };
  };
  

    // denomination api call
    export const getDenominationList = () => {
      return {
        type: DENOMINATION_LIST,
        payload: {},
      };
    };
  
    export const getDenominationListSuccess = payload => {
      return {
        type: GET_DENOMINATION_LIST_SUCCESS,
        payload: payload,
      };
    };

    export const getDenominationListFail = payload => {
      return {
        type: GET_DENOMINATION_LIST_FAIL,
        payload: payload,
      };
    };