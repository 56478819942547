import { call, put, takeEvery } from "redux-saga/effects";

// Redux States
import { helpDataBeforeLogin, helpDataBeforeLoginSubmit } from '../../Helpers/api_helpers'
import { HELP_DATA_DETAIL_BEFORE_LOGIN, HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT } from './ActionTypes';
import { helpDataDetailBeforeLoginApiError, helpDataDetailBeforeLoginSubmitApiError, helpDataDetailBeforeLoginSubmitSuccess, helpDataDetailBeforeLoginSuccess } from "./Action";

function* helpDataBeforeLoginApiCall({ payload: {data} }) {
  //console.log("1234567890");
  try {
    const response = yield call(helpDataBeforeLogin)
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(helpDataDetailBeforeLoginSuccess(response));
    } else {
      yield put(helpDataDetailBeforeLoginApiError(response));
    }
  } catch (error) {
    yield put(helpDataDetailBeforeLoginApiError(error));
  }
}

function* helpDataBeforeLoginSubmitApiCall({ payload: {data} }) {
  //console.log(data,"datadata");
  try {
    const response = yield call(helpDataBeforeLoginSubmit,{
      "name": data.name,
      "email": data.email,
      "phonenumber": data.phonenumber.toString(),
      "type": data.type,
      "subject": data.subject,
      "question": data.question

    })
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(helpDataDetailBeforeLoginSubmitSuccess(response));
    } else {
      yield put(helpDataDetailBeforeLoginSubmitApiError(response));
    }
  } catch (error) {
    yield put(helpDataDetailBeforeLoginSubmitApiError(error));
  }
}


function* contactSaga() {
  yield takeEvery(HELP_DATA_DETAIL_BEFORE_LOGIN, helpDataBeforeLoginApiCall);
  yield takeEvery(HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT, helpDataBeforeLoginSubmitApiCall);
}

export default contactSaga;
