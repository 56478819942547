import React, { Component, useEffect, useState, useContext } from "react";
import { NavListContext } from "../../Contexts/navContext";
import Modal from "./Modal";
import LockIcon from "../../Images/lockicon.svg";
import OnlineIcon from "../../Images/Group 619.svg";
import GreenSelectIcon from "../../Images/Group 169 green.svg";
import GreySelectIcon from "../../Images/Group 169.svg";
import RedCircleCloseIcon from "../../Images/Group 166 red.svg";
import GreyCircleCloseIcon from "../../Images/Group 166.svg";
import { RouteHelper } from "../../Routes/RouteSegment";
import Constants, { InboxFilters } from "../../Constants/constants";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "../../Utils";
import { useTranslation } from "react-i18next";

const SearchResultCard = (props) => {

  const [showModal, setShowModal] = useState(false);
  const { navList, setNavList } = useContext(NavListContext);

  
  //  const [requestPhoto, setRequestPhoto] = useState()
const navigate  = useNavigate()
  const [selectReject, setSelectReject] = useState({
    select: props?.cardData?.selectedprofile ?? false,
    reject: props?.cardData?.selectedrejected ?? false,
    message: false, //props?.cardData?.profilealreadyContected == "Y" ? true : false,
    photoRequest: props?.cardData?.profilerequestcheck ?? false,
  });
  useEffect(() => {}, [selectReject]);
  useEffect(() => {}, [showModal]);
  useEffect(() => {}, [props?.data]);
  useEffect(() => {
    console.log(props?.cardData,"props updated");
    // console.log(props?.cardData?.profilealreadyContected,"props updatedprops updated");
    setSelectReject({
      select: selectReject.select,
      reject: selectReject.reject,
      message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    });
  }, [props]);

  const { t: trans } = useTranslation();

  useEffect(() => {
    setSelectReject({
      select: props?.cardData?.selectedprofile ?? false,
      reject: props?.cardData?.selectedrejected ?? false,
      photoRequest: props?.cardData?.profilerequestcheck ?? false,
    });
  }, []);

  const onMessageClick = () => {
    if (props.cardData?.profileblockedByMe == "Y") {
      props?.callBackBlockProfile();
    } else {
      props?.callbackSelectReject("MESSAGE", "");
    }
    // props.callbackSelectReject("MESSAGE", "")
  }

  const onlineNowClick = ()=>{
    if(props?.cardData?.profilealreadyContected === "Y" && !Constants.profileStatusOptions.includes(props?.cardData?.profileStatus) && props.data?.status !== "pending" && props.data?.status !== "hidden"){
      navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), { state: { profileId: props?.cardData?.profileid, threadId:props?.cardData?.messageSent[0].subjectId, type:"Sent" } })
    }else{
      onMessageClick()
    }
  }
 

  return (
   <div className="relative mt-[2.5rem] ">
      {props?.cardData?.adminSelected? 
      <div className=" flex justify-center items-center">
        <div className="">
        <div className =" absolute w-[2rem] top-[-0.3rem] left-[6.7rem] md:top-[-0.6rem] lg:top-[-0.5rem] z-[1] md:left-[8rem]
  border-l-[15px] border-l-transparent
  border-t-[17px] border-t-[#D10A11]
  border-r-[22px] border-r-transparent">
</div>
        <p className="text-white text-[10px] md:text-[12px] lg:text-[15px] absolute top-[-1.3rem] left-[6rem] md:top-[-1.9rem] md:left-[8rem]  z-[1] font-segeo px-[1rem] rounded-lg font-bold bg-[#D10A11] px-[8px] py-[4px] ">
        {trans('matches.profile_selected_by_relationsh')}
        </p>
        </div>
      </div>
       : "" } 
      <div className=" hidden md:flex w-full   sahdow-lg overflow-hidden  flex-col md:flex-row">
        <div className="md:col-span-4 relative md:w-[76%] lg:w-[42%] imageCard">
          <img
            //    className=" h-full w-[17rem]"
            src={props?.cardData?.profileurl}
            alt=" "
            onClick={() =>
              props.cardData.profileStatus == "open" ||
              props.cardData.profileStatus == "pending"
                ? props.callBackNav()
                : props.cardData.profileStatus == "inactive"
                ? props.viewProfileStatusPopup("Profile has been inactive")
                : props.cardData.profileStatus == "hidden"
                ? props.viewProfileStatusPopup("Profile has been hidden")
                : props.cardData.profileStatus == "delete"
                ? props.viewProfileStatusPopup("Profile has been deleted")
                : props.cardData.profileStatus == "suspension"
                ? props.viewProfileStatusPopup("Profile has been suspended")
                : ""
            }
            onError={i => {
              i.target.style.borderBottomWidth='1px';
            }}
            className={`${
              props?.cardData?.profileblurPhoto === "Y"
                ? "blur-[2px] md:w-full lg:w-full "
                : " md:w-full  lg:w-full "
            } md:w-[13.9rm] md:h-[12rem] lg:h-full border-l border-t `}
          />
          {/* {//console.log(props?.cardData?.profileurl,"props?.cardData?.profileurl")} */}
          {props?.cardData?.profilefromPasswordProtected === "Y" && (
            <div
              onClick={() =>
                props.cardData.profileStatus == "open" ||
                props.cardData.profileStatus == "pending"
                  ? props.callBackNav()
                  : props.cardData.profileStatus == "inactive"
                  ? props.viewProfileStatusPopup("Profile has been inactive")
                  : props.cardData.profileStatus == "hidden"
                  ? props.viewProfileStatusPopup("Profile has been hidden")
                  : props.cardData.profileStatus == "delete"
                  ? props.viewProfileStatusPopup("Profile has been deleted")
                  : props.cardData.profileStatus == "suspension"
                  ? props.viewProfileStatusPopup("Profile has been suspended")
                  : ""
              }
              className="absolute top-0 flex justify-center items-center h-[100%] w-[8.5rem] md:w-[8.5rem] lg:w-[10.5rem] cursor-pointer"
            >
              <img
                className="h-[3rem] w-[3rem] md:ml-[1rem] lg:ml-[3rem]"
                src={LockIcon}
                alt="Person"
              />
            </div>
          )}

          {props?.cardData?.isAvatarPic && (
            <div
              onClick={() => {
                if (props.cardData?.profileblockedByMe == "Y") {
                  props?.callBackBlockProfile();
                } else {
                  if (props?.cardData?.profilerequestcheck === true) {
                  } else if (props?.cardData?.profilerequestcheck === false) {
                    setSelectReject({
                      ...selectReject,
                      photoRequest: !selectReject.photoRequest,
                    });
                    props.callbackRequestPhoto();
                  }
                }
              }}
              className="absolute top-0 flex  items-end h-[100%]  w-[100%] cursor-pointer"
            >
              <span className=" text-[#575556] mb-6   md:px-3 lg:px-0 md:text-[11px] flex justify-center items-center lg:text-[17px] lg:mr-0 w-[100%] md:w-[100%] lg:w-full  md:h-[2rem] md:py-2 lg:py-2 lg:h-[3rem] font-bold text-center ">
                {props?.cardData?.profilerequestcheck
                  ? trans('view_profile.photo_requested')
                  : trans('view_profile.request_photo')}
              </span>
            </div>
          )}
        </div>
        <div className="w-full grid md:col-span-6 border-t border-r border-[#E9E9E9] text-left space-y-1">
          <div className="px-3 space-y-1 pt-3">
            <div className="md:block lg:hidden">
              {props.cardData?.profilelastOnline === "Online Now" ? (
                <div onClick={onlineNowClick} className="flex cursor-pointer">
                  <img className=" w-3 h-3" src={OnlineIcon} alt="" />
                  <span
                    className=" px-2 text-[10px]
                                          text-[#575556]"
                  >
                    Online Chat Now
                  </span>
                </div>
              ) : (
                <span className="md:flex justify-end text-[#039511] font-segeo font-semibold text-[10px] px-1">
                  Last Online: {dateFormat(props?.cardData?.profilelastOnline)}
                </span>
              )}
              <p
                onClick={() =>
                  props.cardData.profileStatus == "open" ||
                  props.cardData.profileStatus == "pending"
                    ? props.callBackNav()
                    : props.cardData.profileStatus == "inactive"
                    ? props.viewProfileStatusPopup("Profile has been inactive")
                    : props.cardData.profileStatus == "hidden"
                    ? props.viewProfileStatusPopup("Profile has been hidden")
                    : props.cardData.profileStatus == "delete"
                    ? props.viewProfileStatusPopup("Profile has been deleted")
                    : props.cardData.profileStatus == "suspension"
                    ? props.viewProfileStatusPopup("Profile has been suspended")
                    : ""
                }
                className="text-[#575556] md:text-[15px] lg:text-[16px] pt-1 text-[12pt]  font-bold"
              >
                {props.cardData.smProfileId}
              </p>
              <p className="text-[#575556] md:text-[15px] lg:text-[16px]  text-[12pt]  font-bold">
                {props.cardData.profilename}
              </p>
            </div>
            <div className=" md:hidden lg:flex lg:justify-between">
              <button
                onClick={() => {
                  if (
                    props.cardData.profileStatus == "open" ||
                    props.cardData.profileStatus == "pending"
                  ) {
                    props.callBackNav();
                  } else {
                    if (props.cardData.profileStatus == "inactive")
                      props.viewProfileStatusPopup("Profile has been inactive");
                    else if (props.cardData.profileStatus == "hidden")
                      props.viewProfileStatusPopup("Profile has been hidden");
                    else if (props.cardData.profileStatus == "delete")
                      props.viewProfileStatusPopup("Profile has been deleted");
                    else
                      props.viewProfileStatusPopup(
                        "Profile has been suspended"
                      );
                    // else ""
                  }
                  // setNavList([...navList, "Profile Details"])
                }}
                className="text-[#575556] md:text-[14px] lg:text-[16px] py-1 text-[10px]  font-bold"
              >
                {props.cardData.profilename} |
                <span> {props.cardData.smProfileId} </span>
              </button>

              {props.cardData?.profilelastOnline === "Online Now" ? (
                <div onClick={onlineNowClick} className="flex cursor-pointer">
                  <img className=" w-3 h-3" src={OnlineIcon} alt="" />
                  <span
                    className=" px-2 text-[10px]
                                          text-[#575556]"
                  >
                    Online Chat Now
                  </span>
                </div>
              ) : (
                <span className="md:flex justify-end text-[#039511] font-segeo font-semibold text-[10px] px-1">
                  Last Online: {dateFormat(props?.cardData?.profilelastOnline)}
                </span>
              )}
            </div>
            <p className="text-[#7A7A7A]  text-[12px] lg:text-[14px] ">
              {props.cardData.profileage}, {props.cardData.profileheight},{" "}
              {props.cardData.profilenativelanguage},{" "}
              {props.cardData.profilecaste},{props.cardData.profilecity},{" "}
              {props.cardData.profilestate}{" "}
            </p>
            <p className="font-segeo text-[#7A7A7A] text-[12px] lg:text-[14px] ">
              {props.cardData.profilequalification}/{" "}
              {props.cardData.profileoccupation}{" "}
            </p>
            {/* {props.cardData.profileStatus === "open"?
             ""
            : */}
            {props?.menu == "response" ||
            props?.menu == "selectedprofile" ||
            props?.menu == "photorequestreceived" ? (
              <p className="text-[#7A7A7A] text-[12px] md:text-[14px] lg:text-[14px] font-segeo h-[1rem]">
                {props.cardData.profileStatus == "inactive"
                  ? "Profile has been inactive"
                  : props.cardData.profileStatus == "hidden"
                  ? "Profile has been hidden"
                  : props.cardData.profileStatus == "delete"
                  ? "Profile has been deleted"
                  : props.cardData.profileStatus == "suspension"
                  ? "Profile has been suspened"
                  : ""}
              </p>
            ) : (
              ""
            )}
            {/* } */}
          </div>
          <div className="flex justify-around  items-center md:pl-1 lg:pl-0">
            <div
              className="mr-3 flex flex-col items-center w-[10%]"
              onClick={() => {
                setSelectReject({
                  ...selectReject,
                  select: !selectReject.select,
                });

                props.callbackSelectReject(
                  "SELECT",
                  selectReject.select ? "Selected" : "Select"
                );
              }}
            >
              <img
                className="w-5 h-5 lg:w-5 lg:h-5 md:w-[1.2rem] h-[1.2rem]"
                src={selectReject.select ? GreenSelectIcon : GreySelectIcon}
                alt=""
              />
              <span
                className={`${
                  selectReject.select ? "text-[#039511]" : "text-[#7A7A7A]"
                } text-[11px] lg:text-[11px] md:text-[10px]`}
              >
                {selectReject.select ? "Selected" : "Select"}
              </span>
            </div>
            <div
              className="mr-3 flex flex-col items-center w-[10%] md:w-[14%]"
              onClick={() => {
                setSelectReject({
                  ...selectReject,
                  reject: !selectReject.reject,
                });

                props.callbackSelectReject(
                  "REJECT",
                  selectReject.reject ? "Rejected" : "Reject"
                );
              }}
            >
              <img
                className="w-5 h-5 lg:w-5 lg:h-5 md:w-[1.2rem] h-[1.2rem]"
                src={
                  selectReject.reject ? RedCircleCloseIcon : GreyCircleCloseIcon
                }
                alt=""
              />
              <span
                className={`${
                  selectReject.reject ? "text-[#D10A11]" : "text-[#7A7A7A]"
                } lg:text-[11px] md:text-[10px] text-[11px]`}
              >
                {selectReject.reject ? "Rejected" : "Reject"}
              </span>
            </div>
            <div
              className="flex flex-col  items-center cursor-pointer"
              onClick={onMessageClick}
            >
              {/* {selectReject.message} */}
              {/* selectReject.message ? "/Assets/Images/Group 185 green.svg" : "/Assets/Images/Group 185.svg" */}

              <img
                className="w-5 h-5 lg:w-6 lg:h-6 md:w-[1.5rem] h-[1.5rem]"
                src={
                  props.cardData?.profilealreadyContected == "Y"
                    ? "/Assets/Images/Group 185 green.svg"
                    : "/Assets/Images/Group 185.svg"
                }
                alt=""
              />
              <span
                className={`${
                  props.cardData?.profilealreadyContected == "Y"
                    ? "text-[#039511]"
                    : "text-[#7A7A7A]"
                } text-[11px] md:text-[10px] lg:text-[11px]`}
              >
                {props.cardData?.profilealreadyContected == "Y"
                  ? "Messaged"
                  : "Message"}
              </span>
            </div>
          </div>

          <div className="md:hidden lg:flex justify-around bg-[#FEBABA] py-2">
            <Modal
              type="Unread"
              menu="web"
              data={props.data}
              cardData={props.cardData}
              requestcount={props.cardData.requestCount}
              convocount={props.cardData.totalConversationCount}
            />
            <div className="flex items-center">
              <button
                className=" flex text-[12px] items-center lg:text-[12px] md:text-[11px] text-[#D10A11] font-segeo"
                onClick={() => props.callBackPhone()}
              >
                <img
                  className=" md:h-4 md:w-4 lg:h-5  lg:w-5 h-5 w-5"
                  src="/Assets/Images/Group 173.svg"
                  alt=""
                />
                {props?.cardData?.selectedphoneno ? (
                  <span className=" text-[11px] pl-2 text-[#D10A11] font-segeo">
                    {trans('view_profile.phone_number')}
                  </span>
                ) : (
                  <span className=" text-[11px] pl-2 text-[#D10A11] font-segeo">
                    {trans('view_profile.phone_number')}
                  </span>
                )}
                {/* <span className=" text-[11px] pl-2 text-[#D10A11] font-segeo"> {trans('view_profile.phone_number')}</span> */}
              </button>
            </div>
            {props?.cardData?.profileparentsnumber === "" ? (
              <div className="flex items-center">
                <button
                  onClick={() => {
                    if (props.cardData?.profileblockedByMe == "Y") {
                      props?.callBackBlockProfile();
                    } else {
                      if (props?.cardData?.parentnumberrequestcheck === false) {
                        props.callBackParentsNumber();
                      }
                    }
                  }}
                  className="flex text-[#039511] text-[12px] lg:text-[12px] md:text-[11px]  font-segeo"
                >
                  <img
                    className="md:h-4 md:w-4 lg:h-5  lg:w-5 h-5 w-5"
                    src="/Assets/Images/Group 172.svg"
                    alt=""
                  />
                  <span className="text-[11px] pl-2 text-[#D10A11] font-segeo">
                    {" "}
                    {props?.cardData?.parentnumberrequestcheck
                      ? trans('view_profile.parents_number_requested')
                      : trans('view_profile.request_parents_number')}
                  </span>
                </button>
              </div>
            ) : (
              <div className="flex items-center">
                <button
                  onClick={() => props.callBackPhone()}
                  className=" flex text-[#039511] text-[12px] lg:text-[12px] md:text-[11px]  font-segeo "
                >
                  <img
                    className="  md:h-4 md:w-4 lg:h-5  lg:w-5 h-5 w-5"
                    src="/Assets/Images/Group 172.svg"
                    alt=""
                  />
                  {props?.cardData?.selectedparentno ? (
                    <span className="text-[11px] pl-2 text-[#D10A11] font-segeo">
                      Parents Number
                    </span>
                  ) : (
                    <span className="text-[11px] pl-2 text-[#D10A11] font-segeo">
                      Parents Number
                    </span>
                  )}
                  {/* // <span className=" text-[11px] pl-2 text-[#D10A11] font-segeo">Parents Number</span> */}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className=" md:hidden flex w-full  rounded-t-lg sahdow-lg overflow-hidden mt-3 lg:mt-0 flex-row">
        <div className="col-span-4 relative w-[85%] md:w-[76%] lg:w-[42%]">
          <img
            //    className=" h-full w-[17rem]"
            src={props?.cardData?.profileurl}
            alt=""
            onClick={() =>
              props.cardData.profileStatus == "open" ||
              props.cardData.profileStatus == "pending"
                ? props.callbackRequestPhoto()
                : props.cardData.profileStatus == "inactive"
                ? props.viewProfileStatusPopup("Profile has been inactive")
                : props.cardData.profileStatus == "hidden"
                ? props.viewProfileStatusPopup("Profile has been hidden")
                : props.cardData.profileStatus == "delete"
                ? props.viewProfileStatusPopup("Profile has been deleted")
                : props.cardData.profileStatus == "suspension"
                ? props.viewProfileStatusPopup("Profile has been suspended")
                : ""
            }
            className={`${
              props?.cardData?.profileblurPhoto === "Y"
                ? "blur-[2px] md:w-full lg:w-full "
                : " md:w-full  lg:w-full "
            } md:w-[13.9rm] h-[12rem] lg:h-[13.6rem] `}
          />
          {/* {//console.log(props?.cardData?.profileurl,"props?.cardData?.profileurl")} */}
          {props?.cardData?.profilefromPasswordProtected === "Y" && (
            <div
              onClick={() =>
                props.cardData.profileStatus == "open" ||
                props.cardData.profileStatus == "pending"
                  ? props.callBackNav()
                  : props.cardData.profileStatus == "inactive"
                  ? props.viewProfileStatusPopup("Profile has been inactive")
                  : props.cardData.profileStatus == "hidden"
                  ? props.viewProfileStatusPopup("Profile has been hidden")
                  : props.cardData.profileStatus == "delete"
                  ? props.viewProfileStatusPopup("Profile has been deleted")
                  : props.cardData.profileStatus == "suspension"
                  ? props.viewProfileStatusPopup("Profile has been suspended")
                  : ""
              }
              className="absolute top-0 flex justify-center items-center h-[100%] w-[8.5rem] md:w-[8.5rem] lg:w-[10.5rem] cursor-pointer"
            >
              <img
                className="h-[3rem] w-[3rem] md:ml-[1rem] lg:ml-[3rem]"
                src={LockIcon}
                alt="Person"
              />
            </div>
          )}

          {props?.cardData?.isAvatarPic && (
            <div
              onClick={() => {
                if (props?.cardData?.profilerequestcheck === false) {
                  props.callbackRequestPhoto();
                }
              }}
              className="absolute top-0 flex  items-end h-[100%]  w-[100%] cursor-pointer"
            >
              <span className=" text-[#575556] mb-6 w-full  h-[2rem] text-center  py-1 ">
                {props?.cardData?.profilerequestcheck
                  ? trans('view_profile.photo_requested')
                  : trans('view_profile.request_photo')}
              </span>
            </div>
          )}
        </div>
        <div className="w-full grid md:col-span-6 border-t border-r border-[#E9E9E9] text-left space-y-1">
          <div className="px-3 space-y-1 pt-2">
            <div className="md:block lg:hidden">
              {props.cardData?.profilelastOnline === "Online Now" ? (
                <div onClick={onlineNowClick} className="flex cursor-pointer justify-end  ">
                  <img className=" w-3 h-3" src={OnlineIcon} alt="" />
                  <span
                    className=" px-2 text-[10px]
                                          text-[#575556]"
                  >
                    Online Chat Now
                  </span>
                </div>
              ) : (
                <span className="flex justify-end text-[#039511] font-segeo font-semibold text-[10px] px-1">
                  Last Online: {dateFormat(props?.cardData?.profilelastOnline)}
                </span>
              )}
              <p
                onClick={() =>
                  props.cardData.profileStatus == "open" ||
                  props.cardData.profileStatus == "pending"
                    ? props.callBackNav()
                    : props.cardData.profileStatus == "inactive"
                    ? props.viewProfileStatusPopup("Profile has been inactive")
                    : props.cardData.profileStatus == "hidden"
                    ? props.viewProfileStatusPopup("Profile has been hidden")
                    : props.cardData.profileStatus == "delete"
                    ? props.viewProfileStatusPopup("Profile has been deleted")
                    : props.cardData.profileStatus == "suspension"
                    ? props.viewProfileStatusPopup("Profile has been suspended")
                    : ""
                }
                className="text-[#575556] md:text-[15px] lg:text-[16px] pt-1 text-[12pt]  font-bold"
              >
                {props.cardData.smProfileId}
              </p>
              <p className="text-[#575556] md:text-[15px] lg:text-[16px]  text-[12pt]  font-bold">
                {props.cardData.profilename}
              </p>
            </div>
            {/* <div className=" md:hidden lg:flex lg:justify-between">
              <button
               onClick={() => {
                if(props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"){
                props.callBackNav()
                }
                else{
                if(props.cardData.profileStatus == "inactive")  props.viewProfileStatusPopup("Profile has been inactive")
                else if(props.cardData.profileStatus == "hidden") props.viewProfileStatusPopup("Profile has been hidden")
                else if(props.cardData.profileStatus == "delete") props.viewProfileStatusPopup("Profile has been deleted")
                else props.viewProfileStatusPopup("Profile has been suspended")
                // else ""
                }
                // setNavList([...navList, "Profile Details"])
                  }} 
                  className="text-[#575556] md:text-[14px] lg:text-[16px] py-1 text-[10px]  font-bold">
                {props.cardData.profilename} |
                <span
                 
                >
                  {" "}
                  {props.cardData.smProfileId}{" "}
                </span>
              </button>

              {props.cardData.profilelastOnline === "Online Now" ? (
                <div className="flex ">
                  <img
                    className=" w-3 h-3"
                    src="/Assets/Images/Group 619.svg"
                    alt=""
                  />
                  <span
                    className=" px-2 text-[10px]
                                          text-[#575556]"
                  >
                    Online Chat Now
                  </span>
                </div>
              ) : (
                <span className="md:flex justify-end text-[#039511] font-segeo font-semibold text-[10px] px-1">
                  Last Online: {props?.cardData?.profilelastOnline}
                </span>
              )}

            </div> */}
            <p className="text-[#7A7A7A]  text-[12px] lg:text-[14px] ">
              {props.cardData.profileage}, {props.cardData.profileheight},{" "}
              {props.cardData.profilenativelanguage},{" "}
              {props.cardData.profilecaste},{props.cardData.profilecity},{" "}
              {props.cardData.profilestate}{" "}
            </p>
            <p className="font-segeo text-[#7A7A7A] text-[12px] lg:text-[14px] ">
              {props.cardData.profilequalification}/{" "}
              {props.cardData.profileoccupation}{" "}
            </p>
            {/* {props.cardData.profileStatus === "open"?
             ""
            : */}
            {props?.menu == "response" ||
            props?.menu == "selectedprofile" ||
            props?.menu == "photorequestreceived" ? (
              <p className="text-[#7A7A7A] text-[12px] md:text-[14px] lg:text-[14px] font-segeo h-[1rem]">
                {props.cardData.profileStatus == "inactive"
                  ? "Profile has been inactive"
                  : props.cardData.profileStatus == "hidden"
                  ? "Profile has been hidden"
                  : props.cardData.profileStatus == "delete"
                  ? "Profile has been deleted"
                  : props.cardData.profileStatus == "suspension"
                  ? "Profile has been suspened"
                  : ""}
              </p>
            ) : (
              ""
            )}
            {/* } */}
          </div>
          <div className="flex justify-around  items-center md:pl-1 lg:pl-0">
            <div
              className="mr-3 flex flex-col items-center w-[10%]"
              onClick={() => {
                setSelectReject({
                  ...selectReject,
                  select: !selectReject.select,
                });

                props.callbackSelectReject(
                  "SELECT",
                  selectReject.select ? "Selected" : "Select"
                );
              }}
            >
              <img
                className="w-5 h-5 lg:w-5 lg:h-5 md:w-[1.2rem] h-[1.2rem]"
                src={selectReject.select ? GreenSelectIcon : GreySelectIcon}
                alt=""
              />
              <span
                className={`${
                  selectReject.select ? "text-[#039511]" : "text-[#7A7A7A]"
                } text-[11px] lg:text-[11px] md:text-[10px]`}
              >
                {selectReject.select ? "Selected" : "Select"}
              </span>
            </div>
            <div
              className="mr-3 flex flex-col items-center w-[10%] md:w-[14%]"
              onClick={() => {
                setSelectReject({
                  ...selectReject,
                  reject: !selectReject.reject,
                });

                props.callbackSelectReject(
                  "REJECT",
                  selectReject.reject ? "Rejected" : "Reject"
                );
              }}
            >
              <img
                className="w-5 h-5 lg:w-5 lg:h-5 md:w-[1.2rem] h-[1.2rem]"
                src={
                  selectReject.reject ? RedCircleCloseIcon : GreyCircleCloseIcon
                }
                alt=""
              />
              <span
                className={`${
                  selectReject.reject ? "text-[#D10A11]" : "text-[#7A7A7A]"
                } lg:text-[11px] md:text-[10px] text-[11px]`}
              >
                {selectReject.reject ? "Rejected" : "Reject"}
              </span>
            </div>
            <div
              className="flex flex-col  items-center"
              onClick={() => {
                if (props.cardData?.profileblockedByMe == "Y") {
                  props?.callBackBlockProfile();
                } else {
                  props.callbackSelectReject("MESSAGE", "");
                }
              }}
            >
              <img
                className="w-5 h-5 lg:w-6 lg:h-6 md:w-[1.5rem] h-[1.5rem]"
                src={
                  selectReject.message
                    ? "/Assets/Images/Group 185 green.svg"
                    : "/Assets/Images/Group 185.svg"
                }
                alt=""
              />
              <span
                className={`${
                  selectReject.message ? "text-[#039511]" : "text-[#7A7A7A]"
                } text-[11px] md:text-[10px] lg:text-[11px]`}
              >
                {selectReject.message ? "Messaged" : "Message"}
              </span>
            </div>
          </div>

          {/* <div className="md:hidden lg:flex justify-around bg-[#FEBABA] py-2">
            <Modal type="Unread" menu="web" data={props.data} cardData={props.cardData} requestcount={props.cardData.requestCount} convocount={props.cardData.totalConversationCount} />
            <div className="flex items-center"><button className=" flex text-[12px] items-center lg:text-[12px] md:text-[11px] text-[#D10A11] font-segeo"
              onClick={() => props.callBackPhone()} >
              <img className=" md:h-4 md:w-4 lg:h-5  lg:w-5 h-5 w-5" src="/Assets/Images/Group 173.svg" alt="" />
              { props?.cardData?.selectedphoneno?
              <span className=" text-[11px] pl-2 text-[#D10A11] font-segeo">{trans('view_profile.phone_number')}</span>
              :
               <span className=" text-[11px] pl-2 text-[#D10A11] font-segeo">{trans('view_profile.phone_number')}</span>
              
              
}
            </button>
            </div>
            {props?.cardData?.profileparentsnumber === "" ? ""
              :
              <div className="flex items-center">
              <button
                onClick={() => props.callBackPhone()} 
                className=" flex text-[#039511] text-[12px] lg:text-[12px] md:text-[11px]  font-segeo ">
                <img className="  md:h-4 md:w-4 lg:h-5  lg:w-5 h-5 w-5" src="/Assets/Images/Group 172.svg" alt="" />
                 {props?.cardData?.selectedparentno? 
              <span className="text-[11px] pl-2 text-[#D10A11] font-segeo">Parents Number</span>
              :
              <span className="text-[11px] pl-2 text-[#D10A11] font-segeo">Parents Number</span>
                 }
              </button>
              </div>


            }
          </div> */}
        </div>
      </div>

      <div className="flex  justify-around bg-[#FEBABA] py-2 lg:hidden">
        <Modal
          type="Unread"
          ispaid={props?.data?.isPaid}
          menu="tab"
          data={props.data}
          cardData={props.cardData}
          requestcount={props.cardData.requestCount}
          convocount={props.cardData.totalConversationCount}
        />
        <button
          className=" flex text-[12px] text-[#D10A11] font-segeo"
          onClick={() => props.callBackPhone()}
        >
          <img
            className="md:h-4 md:w-4 lg:h-5  lg:w-5 h-5 w-5"
            src="/Assets/Images/Group 173.svg"
            alt=""
          />
          <span className="text-[11px] pl-2 text-[#D10A11] font-segeo">
            {" "}
            {trans('view_profile.phone_number')}
          </span>
        </button>
        {props?.cardData?.profileparentsnumber === "" ? (
          <button
            onClick={() => {
              if (props.cardData?.profileblockedByMe == "Y") {
                props?.callBackBlockProfile();
              } else {
                if (props?.cardData?.parentnumberrequestcheck === false) {
                  props.callBackParentsNumber();
                }
              }
            }}
            className=" flex text-[#039511] text-[12px] font-segeo "
          >
            <img
              className=" md:h-4 md:w-4 lg:h-5  lg:w-5 h-5 w-5"
              src="/Assets/Images/Group 172.svg"
              alt=""
            />
            <span className="text-[11px] pl-2 text-[#D10A11] font-segeo">
              {props?.cardData?.parentnumberrequestcheck
                ? trans('view_profile.parents_number_requested')
                : trans('view_profile.request_parents_number')}
            </span>
          </button>
        ) : (
          <button
            onClick={() => props.callBackPhone()}
            className=" flex text-[#039511] text-[12px] font-segeo "
          >
            <img
              className=" md:h-4 md:w-4 lg:h-5  lg:w-5 h-5 w-5"
              src="/Assets/Images/Group 172.svg"
              alt=""
            />
            <span className="text-[11px] pl-2 text-[#D10A11] font-segeo">
              Parents Number
            </span>
          </button>
        )}
      </div>
      {/* {//console.log(showModal,"modal123")}
            {showModal ? (
      <Modal count={showModal} 
       value= {props.cardData.totalConversationCount}
       />
     ) : ""
     }    */}
    </div>
  );
};

export default SearchResultCard;
