import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducer'
import { createStore, applyMiddleware, compose } from 'redux'
import rootSaga from "./saga";


export default function configureStore(initialState) {
  // Note: passing middleware as the last argument to createStore requires redux@>=3.1.0
  const sagaMiddleware = createSagaMiddleware()
  const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    initialState,
    // applyMiddleware(sagaMiddleware)
    composedEnhancers(applyMiddleware(sagaMiddleware)), // enable redux dev tools
    // composedEnhancers
  );
  sagaMiddleware.run(rootSaga);

  return store
}