import React, { useContext, useEffect, useState } from 'react'
import { conversationStatus, getConversationList, sendMessage, setCurrentChat } from '../../../Store/Chats/Action';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileMatch, matchRequest, viewPhone } from '../../../Store/KalyanLite/Action';
import { LoginContext } from '../../../Contexts/LoginContext';
import MesageIcon from "./../assets/Images/Group 1718.png"
import VideoIcon from "./../assets/Images/Group 1717.png"
import ParentsIcon from "./../assets/Images/Group 1716.png"
import CallIcon from "./../assets/Images/Group 1715.png"
import onlineicon from './../assets/Images/online icon.png'
import shareIcon from './../assets/Images/red share.png'
import * as Images from "../assets/images"
import Skeleton from 'react-loading-skeleton';
import EndPoints from '../../../Constants/EndPoints';
import { GET,POST } from '../../../Services/api_services';
import config from '../../../config';
import { BulkRequestPopup, CommonCameraPopup, CommonPayNowPopup, CommonSuccessPopup, CommonUnBlockPopup, CommonValidPopup ,CommonErrorPopup} from '../../CommonPopup';
import SuccessPopup from '../Popup/SuccessPopup';
import { Conversation_Data, Family_Chats_Detail, Family_Chats_List } from '../../../Helpers/api_helpers';
import Constants from '../../../Constants/constants';
import { useNavigate } from 'react-router-dom';
import PhonePopup from '../../DashboardComponents/PhonePopup';
import { ImageViewer } from '../Common/CarouselWithNav';
import ProcessImage from '../Common/ProcessImage';
import RouteSegments from '../../../Routes/RouteSegment';
import PhonePopupLite from '../../DashboardComponents/PhonePopupLite';
import { NavBarContext } from "../../../Contexts/NavBarContext";
import { sendGroupMessage } from "../../../Store/Chats/Action";
import { getDeviceInfo, getGroupAuthId } from "../../../Components/Kalyanlite/utils";
import { useTranslation } from 'react-i18next';



function MatchPopup({ close, profileId, onViewProfile, from, openChat }) {
    const isFromChat = from === "chat"

    const [paynowPopupData, setPaynowPopupData] = useState({ show: false, title: "", content: "" })
    const [nonValidationPopupData, setNonValidationPopupData] = useState({ show: false, title: "", content: "" })
    const [showUnBlockPopup, setShowUnBlockPopup] = useState(false)
    const [showBulkRequestPopupData, setShowBulkRequestPopupData] = useState({ show: false, title: "", content: "", message: "" })
    const [SuccessPopupState, setSuccessPopupState] = useState({
        show: false,
        message: "",
    });
    const [isPhotoRequestVisible, setPhotoRequestVisibility] = useState(false);
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [PhotoPopup, setPhotoPopup] = useState(false);
    const [parentPopup, setParentPopup] = useState(false);
    const [isChatLoading, setIsChatLoading] = useState(false);
    const [showCameraPopup, setShowCameraPopup] = useState(false)
    const [phonePopupType, setPhonePopupType] = useState("")
    const [showPhonePopup, setShowPhonePopup] = useState(false);
    const [showImageViewer, setShowImageViewer] = useState(false);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loginDetail, logout } = useContext(LoginContext);

    const { t: trans } = useTranslation();

    const profileData = useSelector((state) => state.KalyanLite?.matchProfiles[profileId]);
    const isLoading = useSelector((state) => state?.KalyanLite?.matchProfileLoading);
    const userData = useSelector(state => state?.DashBoardAPI?.ProfileData?.data);
    const { conversationStatusData } = useSelector((state) => {
        return {
            conversationStatusData: state?.Chats?.conversationStatusData,
        };
    });


    const viewPhoneData = profileData?.viewPhone;



    //for parents number
    const isParentsNumberAvailble =
        profileData?.profiledetails?.profileparentsnumber != "";
    const isParentsNumberRequested =
        profileData?.profiledetails?.parentnumberrequestcheck === true;

    //for photo
    const isProfilePicAvailable = !profileData?.profiledetails?.isAvatarPic;
    const isProfilePicRequested =
        profileData?.profiledetails?.profilerequestcheck;

    const profilePic = profileData?.profiledetails?.profileurl;

    const profileblockedByMe = profileData?.profiledetails?.profileblockedByMe === "Y";
    const profileblockedByOther = profileData?.profiledetails?.profileblockedByOther === "Y";
    const profileStatus = profileData?.profiledetails?.profileStatus;
    const profileName = profileData?.profiledetails?.profilename;

    const loggedInProfileStatus = userData?.status
    const loggedInUserPaid = userData?.isPaid === "Y"


    const handleError = (event) => {
        // Handle the image loading error
        event.target.src = Images.DefaultAvatarFull; // Set the source to the default image
    };


    const createConversation = async () => {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    Constants.loginLocalStorageKeys.chatLogin,
                )}`,
            },
            data: {
                receiver_id: parseInt(profileData?.profiledetails?.profileid),
                page_size: 1,
                page: 1
            },
        };

        const response = await Conversation_Data(axiosConfig);

        if (response.status === 200) {
            const conversation_id = response?.data?.conversation_id;

            // Dispatch action to update chat list
            dispatch(getConversationList());

            return { status: 200, conversation_id };
        } else {
            return response;
        }
    };

    const sendRequest = async (messageType) => {

        dispatch(matchRequest({ profileId: profileId, requestType: messageType }));

        const bulktrigger = messageType === "Photo" || messageType === "Parents Number"

        let request = {
            email: loginDetail()[0],
            userId: loginDetail()[1],
            toProfileId: `${profileId}`,
            sendsms: "Y",
            typeList: [messageType],
            triggerType: "singletrigger",
            bulktrigger: bulktrigger,
            receiver_profileid : profileId,
            ...(messageType.includes("Photo") && {photorequesttypeid : 85}),
            ...(messageType.includes("Horoscope") && {hororequesttypeid : 86}),
            ...(messageType.includes("Parents Number") && {parentnumberrequesttypeid : 87}),
            ...(messageType.includes("Trust") && {trustrequesttypeid : 88}),            
            requestsentvia : "viewprofile",
            fromPage : "chat",
            loginFrom : getDeviceInfo()
        };

        let { statusCode, data } = await POST(
            `${config.api.API_URL}${EndPoints.requestPhotoHoroTrustParent()}`,
            request,
        );
        console.log("sendRequest", data);

        if (statusCode === 200) {
            let message = "";
            let content = "";
            let message_type_id;
            if (messageType === "Photo") {
                message = trans('send_bulk_request.photo_request_sent');
                content = "Request Photo";
                message_type_id = 5;
            } else if (messageType === "Horoscope") {
                message = trans('send_bulk_request.horoscope_request_sent');
                content = "Request Horoscope";
                message_type_id = 6;
            } else if (messageType === "Trust") {
                message = trans('success_popup.trust_document_request_sent');
                content = "Request Trust Documents";
                message_type_id = 8;
            } else if (messageType === "Parents Number") {
                message = trans('send_bulk_request.parents_number_request_sent');
                content = "Request Parent's Phone Number";
                message_type_id = 7;
            }


            if (conversationStatusData.is_conversation_exists) {
                dispatch(
                    sendMessage({
                        receiver_id: profileId,
                        conversation_id: conversationStatusData?.conversation_id,
                        file: content,
                        message_type_id: message_type_id,
                    }),
                );
            } else {
                const response = await createConversation()

                if (response.status === 200) {
                    const conversation_id = response?.conversation_id
                    dispatch(
                        sendMessage({
                            receiver_id: profileId,
                            conversation_id: conversation_id,
                            file: content,
                            message_type_id: message_type_id,
                        }),
                    );
                }
            }

            if (data?.data?.recomm_count > 0 && request.bulktrigger === true) {
                setShowBulkRequestPopupData({
                    show: true,
                    title: trans('photos.success'),
                    content: message,
                    message: data?.data?.bulkrequestcontent,
                })
            } else {
                if (messageType === "Photo") {
                    setPhotoPopup(true);
                } else if (messageType === "Horoscope") {

                } else if (messageType === "Trust") {

                } else if (messageType === "Parents Number") {
                    setParentPopup(true);
                }
            }
        } else if (statusCode === 401) {
            logout();
        }
    };
    const showSuccessPopup = (message) => {
        setSuccessPopupState({ show: true, message });
    };

    const apiCallBulkRequest = async () => {

        let request = {
            email: loginDetail()[0],
            userId: loginDetail()[1],
            "typeList": [
                "Photo",
                "Parents Number"
            ],
            "triggerType": "bulk"
        }

        let { statusCode, data } = await POST(
            `${config.api.API_URL}${EndPoints.bulkrequest()}`,
            request
        );

        if (statusCode === 200) {

            closeBulkRequestPopup()
            showSuccessPopup("Request Sent Successfully");
        } else {
            // Display the popup when the API call is not successful
            setPopupVisibility(true);
        }

    }


    useEffect(() => {
        if (!profileData) {
            dispatch(getProfileMatch({
                email: loginDetail()[0],
                userId: loginDetail()[1],
                viewprofileid: profileId,
                status: "new",
                callFrom:"MatchPopup",
                loginFrom:getDeviceInfo(),
                pageno: 1,
            }));
        }

        dispatch(conversationStatus(profileId))
    }, [profileId]);

    const onPhotoClick = () => {
        if (!isProfilePicRequested) {

            if (profileblockedByMe) {
                setShowUnBlockPopup(true)
                return;
            }

            if (loggedInProfileStatus === "pending") {
                setNonValidationPopupData({ show: true, title: trans('non_validation_popup.non_validated_user'), content:trans('non_validation_popup__request.your_profile_is_under_validati') })
            }
            sendRequest("Photo");

        }
        else {
            // Show the popup when "REQUEST PHOTO" is clicked
            setPhotoRequestVisibility(true);
        }

    };

    const closeBulkRequestPopup = () => {
        setShowBulkRequestPopupData({ show: false, content: "", message: "", title: "" })
    }

    const onBulkRequestSend = (status) => {
        if (status) {
            apiCallBulkRequest()
        } else {
            closeBulkRequestPopup()
        }
    }

    const closeSuccessPopup = () => {
        setSuccessPopupState({ show: false, message: "" });
    };

    const onChatClick = async () => {
        if (profileblockedByMe) {
            setShowUnBlockPopup(true)
            return;
        }
        if (isChatLoading) return;

        if(isFromChat){
            openChat()
            return;
        }

        if (conversationStatusData?.is_conversation_exists) {
            dispatch(setCurrentChat(profileData?.profiledetails?.profileid, conversationStatusData?.conversation_id));
            navigate(RouteSegments.CHAT_LITE, { state: { isFrom: "profile" } });
            close()
            return;
        } else {

            const response = await createConversation()


            if (response.status === 200) {
                const conversation_id = response?.data?.conversation_id
                dispatch(setCurrentChat(profileData?.profiledetails?.profileid, conversation_id));
                navigate(RouteSegments.CHAT_LITE, { state: { isFrom: "profile" } });
                close()
            } else {
                // alert("fail");
            }
        }


    };

    const onVideoClick = () => {
        if (profileblockedByMe) {
            setShowUnBlockPopup(true)
            return;
        }

        if (loggedInProfileStatus === "pending") {
            setNonValidationPopupData({ show: true, title: trans('non_validation_popup.non_validated_user'), content: "Your Profile is under validation. Post done you can make a video call" })
            return;
        }

        if (!loggedInUserPaid) {
            setPaynowPopupData({ show: true, title: "Buy a Pack to Make a Video Call", content: "Upgrade to Make a Video Call" })
            return;
        }
        setShowCameraPopup(true)
    }

    const onPhoneViewClick = (type) => {
        if (!loggedInUserPaid && profileblockedByMe) {
            setPaynowPopupData({ show: true, title: "Buy a Pack to Contact Profile", content: "Upgrade To View Phone Number" })
            return;
        }

        if (profileblockedByMe) {
            setShowUnBlockPopup(true);
            return;
        }

        setPhonePopupType(type);

        if (viewPhoneData) {
            setShowPhonePopup(true);
        } else {
            dispatch(
                viewPhone({
                    profileId: profileId,
                }),
            );
            setShowPhonePopup(true);
        }
    };
    const closeImagePreview = () => {
        setShowImageViewer(false);
    };

    const onProfileClick = (e) => {
        // setShowImageViewer(true);
        onViewProfile(e)
        close()
    }
    const {setMenuSelect, setGroupChatClicked } = useContext(NavBarContext);
    const [familydata,setFamilydata] = useState([])
    console.log(profileData?.profiledetails?.profileid,'profileData?.profiledetails?.profileid');
    const apiCallFamilyData = async () => {
        try {
          const profileId = userData?.profileId;
            if (!profileId) {
                // Handle the case when profileId is not available
                console.error('ProfileId not available');
                return;
            }
    
            const { statusCode, data } = await GET(
                `${config.api.API_URL}${EndPoints.getfamilyUrl(profileId)}`
            );
    
            if (statusCode === 200) {
                if (data.status === 'Success') {
                  console.log(data.data.groupMembers,'groupMembers');
                    setFamilydata(data.data.groupMembers); 
                    if(data.data.groupMembers?.length > 0){
                     
                      const axiosConfig = {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            Constants.loginLocalStorageKeys.chatLogin,
                          )}`,
                        },
                        data: {
                          "profile_id" : userData?.profileId,
                          "auth_id": getGroupAuthId()
                        },
                      };
                      const familyListResponse = await Family_Chats_List(axiosConfig);
                      const conversationId = familyListResponse?.data?.groupData[0]?.conversation_id
                      console.log(familyListResponse,'familyListResponse');
                      console.log(conversationId,'conversationId');
                      const authId = localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
                      const familyLoginId = localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginId)   
                      if(conversationId){               
                        dispatch(setCurrentChat(profileData?.profiledetails?.profileid, conversationId));
                        if(profileData?.profiledetails?.profileid){
                        dispatch(
                          sendGroupMessage({
                            sender_id: familyLoginId ?? authId ,
                            shared_profile_id: profileData?.profiledetails?.profileid,
                            group_id : userData?.profileId,
                            conversation_id: conversationId,
                            file: 'profile',
                            message_type_id: 5,
                          }),
                        );
                        navigate(RouteSegments.CHAT_LITE, { state: { isFrom: "profile", profiledetails:profileData?.profiledetails} });
                      }
                      }else{                   
                          const axiosConfig = {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                Constants.loginLocalStorageKeys.chatLogin,
                              )}`,
                            },
                            data: {
                              "profile_id" : userData?.profileId,
                              "auth_id" : userData?.auth_id ,
                            },
                          };
                          const createConversation = await Family_Chats_Detail(axiosConfig)
                          const conversationId = createConversation?.data?.conversation_id
                          dispatch(setCurrentChat(profileData?.profiledetails?.profileid, conversationId));
                          if(profileData?.profiledetails?.profileid){
                          dispatch(
                            sendGroupMessage({
                              sender_id: familyLoginId ?? authId ,
                              shared_profile_id: profileData?.profiledetails?.profileid,
                              group_id : userData?.profileId,
                              conversation_id: conversationId,
                              file: 'profile',
                              message_type_id: 5,
                            }),
                          );
                          navigate(RouteSegments.CHAT_LITE, { state: { isFrom: "profile", profiledetails:profileData?.profiledetails} });   
                          }                                      
                      }
                        return;                  
                    } else{
                      setFamilyChatAllow(true)                
                    }              
                }
            } else if (statusCode === 401) {
                // Handle unauthorized error
            } else {
                console.error(`Unexpected status code: ${statusCode}`);
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
      };
    const handleShareProfileClick = ()=>{ 
        setGroupChatClicked(true)
        apiCallFamilyData() 
    }
    const [familyChatAllow,setFamilyChatAllow] = useState(false)

    function formatLastSeen(lastSeenTimestamp) {

        
        const currentDate = new Date();
        const lastSeenDate = new Date(lastSeenTimestamp);
    
        // Check if the user is online now
        if (lastSeenTimestamp === "Online Now") {
            return trans('view_profile.online');
        }
    
        // Check if the user was seen today
        if (
            currentDate.getFullYear() === lastSeenDate.getFullYear() &&
            currentDate.getMonth() === lastSeenDate.getMonth() &&
            currentDate.getDate() === lastSeenDate.getDate()
        ) {
            // Format time (e.g., Last seen today at 4:00 PM)
            return `Last seen today at ${lastSeenDate.toLocaleTimeString([], { hour: "numeric",hourCycle : "h12", minute: "2-digit" })}`;
        }
    
        // Check if the user was seen yesterday
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
    
        if (
            yesterday.getFullYear() === lastSeenDate.getFullYear() &&
            yesterday.getMonth() === lastSeenDate.getMonth() &&
            yesterday.getDate() === lastSeenDate.getDate()
        ) {
            // Format time (e.g., Last seen yesterday at 6:00 PM)
            return `Last seen yesterday at ${lastSeenDate.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })}`;
        }
    
        // Format date as DD/MM/YY (e.g., Last seen at 2/7/23)
        const day = lastSeenDate.getDate().toString().padStart(2, "0");
        const month = (lastSeenDate.getMonth() + 1).toString().padStart(2, "0");
        const year = lastSeenDate.getFullYear().toString().slice(2);
        const lastseenText = trans('view_profile.last_seen_at');
    
    
        return `${lastseenText} ${day}/${month}/${year}`;
    }


    return isLoading
        ? (
            <div className="bg-white rounded-md">
                <Skeleton className='!w-[14rem] h-[14rem]' />
                <div className="flex flex-row space-x-4 items-center justify-center mt-4">
                    <Skeleton className="!w-[1.5rem] h-[1.5rem]" alt='' />
                    <Skeleton className="!w-[1.5rem] h-[1.5rem] " alt='' />
                    <Skeleton className="!w-[1.5rem] h-[1.5rem] " alt='' />
                    <Skeleton className="!w-[1.5rem] h-[1.5rem] " alt='' />
                    <Skeleton className="!w-[1.5rem] h-[1.5rem] " alt='' />
                </div>
            </div>
        ) : (
            <>
                <ImageViewer
                    images={profileData?.profileimages}
                    closeModal={closeImagePreview}
                    isOpen={showImageViewer}
                />
                {showBulkRequestPopupData.show && (
                    <BulkRequestPopup
                        close={closeBulkRequestPopup}
                        onSend={onBulkRequestSend}
                        content={showBulkRequestPopupData.content}
                        message={showBulkRequestPopupData?.message}
                        imageSrc={showBulkRequestPopupData.imageSrc}
                        title={showBulkRequestPopupData.title} />
                )}
                {SuccessPopupState.show && (
                    <CommonSuccessPopup
                        close={closeSuccessPopup}
                        title={trans('photos.success')}
                        content={SuccessPopupState.message}
                    />
                )}
                {showPhonePopup && viewPhoneData ? (
                    // <PhonePopup
                    <PhonePopupLite
                        phoneNumData={viewPhoneData}
                        close={() => setShowPhonePopup(false)}
                        title={trans('view_phone_number_popup.view_phone_number')}
                        isParents={phonePopupType === "parents"}
                        formatLastSeen={formatLastSeen}
                    />
                ) : (

                    <></>
                )}
                {
                    paynowPopupData.show && <CommonPayNowPopup
                        close={() => setPaynowPopupData({ show: false })}
                        title={paynowPopupData.title}
                        content={paynowPopupData.content}
                    />
                }
                {nonValidationPopupData.show && <CommonValidPopup
                    close={() => setNonValidationPopupData({ show: false })}
                    title={nonValidationPopupData.title}
                    content={nonValidationPopupData.content} />
                }
                {showUnBlockPopup && (
                    <CommonUnBlockPopup name={profileName} receiverId={profileId} close={() => setShowUnBlockPopup(false)} />
                )}
                {showCameraPopup && (
                    <CommonCameraPopup close={() => setShowCameraPopup(false)} />
                )}
                {familyChatAllow && (
                    <CommonErrorPopup 
                    closePopup={() => setFamilyChatAllow(false)} message="Add a member and share profiles across your family & friends to discuss with them" 
                    buttonName={trans('matches.add_now')}
                    buttonClick={()=>navigate(RouteSegments.MATCH_GROUP)} 
                    />
                )}
                {parentPopup && <SuccessPopup closePopup={() => { setParentPopup(false); setPopupVisibility(true) }} />}
                <div className="bg-white">
                    {/* <ProcessImage
                        blurPhoto={profileData?.blurPhoto}
                        alreadyContacted={profileData?.alreadyContected}
                        myphoto={profileData?.myphoto}
                        passwordProtected={profileData?.fromPasswordProtected}
                        onClick={onProfileClick}
                        onError={handleError}
                        isAvatarPic={profileData?.profiledetails?.isAvatarPic}
                        lockClassName='h-[36px]'
                        className={`w-[14rem] h-[14rem] object-cover cursor-pointer`}
                        src={profilePic}
                        alt=""
                    /> */}
                    <ProcessImage
                        blurPhoto={profileData?.blurPhoto}
                        alreadyContacted={profileData?.alreadyContected}
                        myphoto={profileData?.myphoto}
                        passwordProtected={profileData?.fromPasswordProtected}
                        onClick={onProfileClick}
                        onError={handleError}
                        isAvatarPic={profileData?.profiledetails?.isAvatarPic}
                        lockClassName='h-[36px]'
                        className={`w-[14rem] h-[14rem] object-cover cursor-pointer relative`} // Added relative positioning
                        src={profilePic}
                        alt=""
                    >
                        {/* Positioned div containing exclamation mark */}
                        <div onClick={onViewProfile} className='cursor-pointer absolute top-0 right-0 mt-2 mr-2 flex items-center justify-center bg-[#D10A11] rounded-full w-[1.5rem] h-[1.5rem]'>
                            <div className='text-white'>!</div>
                        </div>
                    </ProcessImage>

                    {!isProfilePicAvailable ? (
                        <div className="absolute bottom-[3.8rem] lg:bottom-16 left-0 right-0 w-full flex justify-center px-2">
                            <h3
                                onClick={onPhotoClick}
                                className='w-fit text-center text-[16px] xl:text-[18px] text-[#E9E9E9] font-segeo font-semibold cursor-pointer'>
                                {isProfilePicRequested
                                    ? trans('view_profile.photo_requested')
                                    : trans('view_profile.request_photo')}
                            </h3>
                            {/* {PhotoPopup &&
                                <SuccessPopup
                                    closePopup={() => {
                                        setPhotoPopup(false)
                                        setPhotoRequestVisibility(true)
                                    }}
                                />} */}
                        </div>
                    ) : null}
                    <div className="flex flex-row space-x-6 items-center justify-between mt-4">
                        <img onClick={onChatClick} src={MesageIcon} className="w-[1.5rem] h-[1.5rem] cursor-pointer" alt='' />
                        <img onClick={()=>{
                            // onVideoClick();
                        }} src={VideoIcon} className="w-[1.5rem] h-[1.5rem] cursor-pointer" alt='' />
                        {isParentsNumberAvailble && <button className='cursor-pointer' onClick={() => onPhoneViewClick("phone")}>
                            <img src={ParentsIcon} className="w-[1.5rem] h-[1.5rem] cursor-pointer" alt='' />
                        </button>}
                        <button className='cursor-pointer' onClick={() => onPhoneViewClick("parents")}>
                            <img src={CallIcon} className="w-[1.5rem] h-[1.5rem] cursor-pointer" alt='' />
                        </button>
                        {/* <div onClick={onViewProfile} className='cursor-pointer flex items-center justify-center bg-[#D10A11] rounded-full w-[1.5rem] h-[1.5rem]'>
                            <div className='text-white items-center justify-center flex'>!</div>
                        </div> */}
                        <button className='cursor-pointer' onClick={handleShareProfileClick}>
                            <img src={shareIcon} className="w-[1.5rem] h-[1.5rem] cursor-pointer" alt='' />
                        </button>
                    </div>
                </div>
            </>
        )
}

export default MatchPopup