import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import EndPoints from "../../../Constants/EndPoints";
import config from "../../../config";
import Constants, {
  EditProfileMenu,
  emailid,
  EventKeys,
  EventName,
  InboxFilters,
  MenuFunction,
} from "../../../Constants/constants";
import CloseIcon from "../../../Components/Kalyanlite/assets/Images/cancelicon.svg";
import { CommonChooseFamilyPopup } from "../../CommonPopup";
import { POST, GET } from "../../../Services/api_services";
import { NavBarContext } from "../../../Contexts/NavBarContext";
import * as KalyanLiteSelector from "../../../Store/KalyanLite/selectors";
import { validateNumber } from "../../../Utils";
import { useAnalytics } from "../../../Hooks/usePageChange";
import { useTranslation } from "react-i18next";

const FamilyfriedloginPopup = ({  props ,close}) => {
  // console.log(parentsMobileNumber,"parentsMobileNumber1");
  const [showChoosFriendPopup, setShowChoosFriendPopup] = useState(false);
  const [familynumber,setFamilynumber]=useState([])
  const {onClickTrack} = useAnalytics()

  const { t: trans } = useTranslation();

  console.log(familynumber,'familynumber123');


  const formik = useFormik({
    initialValues: {
      parentsMobileNumber: "",
      isdcode: Constants.indiaIsdCodeID,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      parentsMobileNumber: Yup.string()
        .min(7, trans('family.please_enter_a_valid_mobile_nu')).max(15,trans('family.please_enter_a_valid_mobile_nu'))
        .test("india", "Please enter a valid Mobile Number", function (value) {
          const { isdcode } = this.parent;
          if (isdcode == Constants.indiaIsdCodeID) {
            return !value || value.length === 10 ? true : this.createError({ message: trans('family.please_enter_a_valid_mobile_nu'), path: "parentsMobileNumber" });
          }
          return true;
        })
        .test("other", "Please enter a valid Mobile Number", function (value) {
          const { isdcode } = this.parent;
          if (isdcode != Constants.indiaIsdCodeID) {
            return !value || (value.length >= 7 && value.length <= 15)  ? true : this.createError({ message: trans('family.please_enter_a_valid_mobile_nu'), path: "parentsMobileNumber" });
          }
          return true;
        })
        .required("Please enter a Mobile Number"),
      isdcode: Yup.string().required("Please Select Isd code"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      // Set the phone number in state and localStorage
      setFamilynumber([]); // Reset familynumber if needed
      localStorage.setItem(Constants.loginLocalStorageKeys.parentsMobileNumber, values.parentsMobileNumber);

      // Continue with your API call and logic
      const index = dropdownData?.isdcode?.findIndex(data => data.countryId === values.isdcode)

      apiCallFamilyNumberData(values.parentsMobileNumber, dropdownData?.isdcode[index]?.isdcode, setSubmitting);
      onClickTrack(EventName.Home_page_Login_as_FamilyFriend_Submit_Button,{[EventKeys.Home_page_Login_as_FamilyFriend_Submit_Button_Request]:values})

    },
  });



  const { dropdownData } = useSelector((state) => ({
    dropdownData: state?.Home?.rData?.data,
  }));

  console.log("showChoosFriendPopup:", dropdownData);

 

  const apiCallFamilyNumberData = async (parentsMobileNumber, isdcode, setSubmitting) => {
    try {
      const { statusCode, data } = await GET(
        `${config.api.API_URL}${EndPoints.getphonenumberUrl(parentsMobileNumber)}`,
        
      );

      if (statusCode === 200) {
        setSubmitting(false)
        console.log("familyMemberDetails", data);
        if (data.status === "Success") {
          if (data.data.status == "FAILURE") {
            formik.setFieldError("parentsMobileNumber", data?.data?.message??"Please enter verified mobile number.")
          } 
          else if (data?.data?.familyMemberDetails.every(member => member.familynumber == "N")) {
            formik.setFieldError("parentsMobileNumber","Please enter a registered family number")
          }
          else {
            setFamilynumber(data.data.familyMemberDetails);
            setShowChoosFriendPopup(true)
          }
        }
      } else if (statusCode === 401) {
      } else {
        console.error(`Unexpected status code: ${statusCode}`);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const parentsMobileNumber = localStorage.getItem(
    Constants.loginLocalStorageKeys.parentsMobileNumber
  );


  useEffect(() => {
    // if (parentsMobileNumber) {
    //   apiCallFamilyNumberData(parentsMobileNumber);
    // }
  }, [parentsMobileNumber]);


  return (
    <>
      {showChoosFriendPopup && (
        <CommonChooseFamilyPopup  parentsMobileNumber={parentsMobileNumber} familynumber={familynumber} setFamilynumber={setFamilynumber} close={() => setShowChoosFriendPopup(false)} />
      )}

      <div  className={showChoosFriendPopup ?"flex justify-center  items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-0 outline-none focus:outline-none":"flex justify-center bg-opacityblack  items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"}>
        <div className="relative w-[100%] md:w-[60%] lg:w-[35%] md:my-6 md:mx-6">
          <div className="">
            <button
              className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
              onClick={() => {
                close();
              }}
            >
              <img className="h-6 w-6" src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="border-0 rounded-tr-[40px] rounded-tl-[40px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative p-6 flex-auto justify-center items-center text-center">
              <p className="text-[18px] text-[#D10A11] mt-2 ml-2 font-semibold font-poppins text-center">
                FAMILY/FRIEND LOGIN
              </p>
            </div>
            <div className="flex items-center justify-center relative px-6 py-3 bottom-6 flex-auto">
              <form onSubmit={formik.handleSubmit} className="flex flex-col">
                <div className="flex space-x-2">
                  <label className="familyselect select font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[4rem]">
                  <select
                  id="isdcode"
                  name="isdcode"
                  value={formik.values.isdcode}
                  onChange={formik.handleChange}
                  className=" form-input shadow-sm pt-[0.4rem] bg-[#E9E9E9]  text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F]  py-[8px] px-2   md:py-2 md:px-2  w-full block !bg-[position:calc(100%_-_5px)_8px] md:!bg-[position:calc(100%_-_5px)_14px] lg:!bg-[position:calc(100%_-_5px)_14px]"
                >
                  <option value="" disabled selected>
                    Select
                  </option>

                  {dropdownData?.isdcode?.map((isdcode, i) => (
                    <option className="p-2" value={isdcode.countryId}>
                      {isdcode.isdcode}
                    </option>
                  ))}

                </select>
                  </label>

                  <label className="">
                    <input 
                      name="parentsMobileNumber"
                      id="parentsMobileNumber"
                      type="tel"
                      placeholder="Enter Your Mobile Number"
                      onChange={(e) => {
                        if (validateNumber(e.target.value)) {
                          formik.handleChange(e)
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.parentsMobileNumber}
                      className="no-spinner p-2.5 my-1 w-[13rem] text-[13px] font-Poppins text-[#575556] rounded-[3px] border border-[#C4C4C4] focus:outline-none"
                    />
                    
                   
                  </label>
                </div>
                <div className="h-2 mb-2">
                    {formik.touched.parentsMobileNumber && formik.errors.parentsMobileNumber && (
                      <p className="text-[red] pt-1 text-[12px] text-end">{formik.errors.parentsMobileNumber}</p>
                    )}
                    
                  </div>
                <div className="flex justify-center mt-2 items-center space-x-8">
                  <button
                    type="button"
                    onClick={() => {
                      onClickTrack(EventName.Home_page_Login_as_Family_Friend_Cancel_Button)
                        close();
                      }}
                    className="flex items-center justify-center my-1 h-[1.5rem] text-[#575556] bg-white border border-[#707070] px-[2.3rem] md:px-[2.3rem] grow whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex items-center justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] grow whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                  >
                    {formik.isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FamilyfriedloginPopup;
