import { SEARCH_PAGE_CHECK_REQUEST, SEARCH_PAGE_CHECK_REQUEST_API_ERROR, SEARCH_PAGE_CHECK_REQUEST_SUCCESS } from "./ActionTypes";


  //search page check
  export const searchPageCheckRequest = (values, loginDetail) => {
    console.log(values,"valuesvalues");
    return {
      type: SEARCH_PAGE_CHECK_REQUEST,
      payload: {values, loginDetail}
    };
  };

  export const searchPageCheckRequestSuccess = data => {
    //console.log(data,"12344");
    return {
      type: SEARCH_PAGE_CHECK_REQUEST_SUCCESS,
      payload: data,
    };
  };
  
  export const searchPageCheckRequestApiError = error => {
    return {
      type: SEARCH_PAGE_CHECK_REQUEST_API_ERROR,
      payload: error,
    };
  };


  