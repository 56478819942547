import { SEARCH_BY_ID, SEARCH_BY_ID_API_ERROR, SEARCH_BY_ID_SUCCESS } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const SearchById = (state = initialState, action) => {
    //console.log(action.type,"12345");
    switch (action.type) {
      case SEARCH_BY_ID:
        state = {
          ...state,
          loading: true,
        };
        break;
        case SEARCH_BY_ID_SUCCESS:
        //console.log(action.payload.data,"statse");
        state = {
          ...state,
          searchResultData:action.payload.data,
          loading: false,
        };
        break;
      case SEARCH_BY_ID_API_ERROR:
        state = {
          ...state,
          searchResultData: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default SearchById;
  