import React from "react";
import CloseIcon from "../../Images/Group 761@2x.png"



const ImageHoroscopePopupBackground = (props) => {
  return (
    <div className="hidden flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt=""/>
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1  border-b border-dashed border-[#D10A11] mx-4  rounded-t justify-between ">
            <h3 className="text-[18px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
            <h3 className="text-[18px] text-[#D10A11] ml-2 font-semibold align-middle cursor-pointer"
             onClick={props?.openHoroMatch}
             >
              {props.isMatch}
            </h3>
          </div>
          <div className="relative p-6 flex-auto">{props.children}</div>
        </div>
       
      </div>
    </div>
  );
};

export default ImageHoroscopePopupBackground;
