import React from 'react'
import CloseIcon from "../../Images/Group 761@2x.png"

function ProfilePicturePopup({ onClose, title, children }) {
    return (
        <div onClick={onClose} className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-6">
                <div className="">
                    <button
                        className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
                        onClick={onClose}
                    >
                        <img alt='' className="h-5 w-5 " src={CloseIcon} />
                    </button>
                </div>
                <div onClick={(e) => e.stopPropagation()} className="border-0 rounded-lg shadow-lg mt-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
                        <h3 className="text-[12px] md:text-[18px] lg:text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
                            {title}
                        </h3>
                    </div>
                    {children}
                </div>

            </div>
        </div>
    );
}

export default ProfilePicturePopup