import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NavListContext } from "../../Contexts/navContext";
import { useDispatch, useSelector } from "react-redux";
import { searchByIdRequest } from '../../Store/SearchById/Action';
import { NavBarContext } from "../../Contexts/NavBarContext";
import { RouteHelper } from "../../Routes/RouteSegment";
import SearchIconRed from "../../Images/Group 256.svg"


const Searchbyid = (props) => {
  const { setMenuSelect,closeFilter } = useContext(NavBarContext);

  const [ProfileStatusMessage, setProfileStatusMessage] = useState("")
  const [searchId, setSearchId] = useState("")
  const [statusMessage, setStatusMessage] = useState("")
  const { navList, setNavList } = useContext(NavListContext);
  const navigate = useNavigate();
  const dispatch = useDispatch()
   
  const { statusmsg } = useSelector(state => ({
    statusmsg: state.SearchById?.searchResultData
  }));

  const formik = useFormik({
    initialValues: {
      kalyanid: "",
    },
    validationSchema: Yup.object().shape({ 
      kalyanid: Yup.string().required("Please Enter Profile ID"),
    }),

    onSubmit: (values) => {
      searchResult(values);
    },
  }); 

  const searchResult = (values) => {
    setSearchId(values)
    dispatch(searchByIdRequest(values))
    
  }


  useEffect(() => {
    if(statusmsg?.data?.status){
      setStatusMessage("")
      setStatusMessage(statusmsg?.data?.status)
    }

  },[statusmsg,statusMessage])

  
  useEffect(() => {
    if (statusMessage == "SUCCESS") {
      console.log(searchId,"searchId");
      navigate(RouteHelper.getProfileUrl, { state: { KMid: searchId, isfrom: "search" } })
      setNavList([...navList, "Profile Details"])
      setMenuSelect("")
      closeFilter()

      }
      else if(statusMessage == "FAILURE"){
        setProfileStatusMessage(statusmsg?.data?.statusmessage)
      }
      else{

      }
      setStatusMessage("")

  },[statusMessage])
  
 
  return (
    <>
      {props?.menu === "filtersearch" ?
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="">
            <div className="relative flex pl-[2.5rem]">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-[3.25rem] pointer-events-none">
                    <img
                      className="w-5 h-5"
                      src={SearchIconRed}
                      alt=""
                    />
                    <span className="sr-only">Search icon</span>
                  </div>
                  <input
                   value={formik.values.kalyanid}
                   onChange={formik.handleChange}
                   type="text"
                      id="search-navbar"
                      name="kalyanid"
                     className="!placeholder-[#D10A11] focus:outline-none border-none block p-1 pl-10 w-64 md:w-[23rem] rounded-md"
                    placeholder="Search by ID"
                  />
                  <button
                    type="submit"
                    className="flex py-[3px] px-[12px] rounded-tr-[10px] rounded-bl-[10px] justify-between items-center bg-[#D10A11] absolute mt-[3px] ml-[13rem] md:ml-[20rem]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="white"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                </div>
              {!formik.errors.kalyanid && ProfileStatusMessage && <p className="md:text-[red] text-[12px] text-white h-[1rem] pl-[2.5rem]">{ProfileStatusMessage}</p>}
              {formik.errors.kalyanid &&
                <p className="md:text-[red] text-white text-[12px] h-[1rem] pl-[2.5rem]">{formik.errors.kalyanid}</p>
              }
            </div>
          </form>
        </div>
        :
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="grid  place-content-end  pt-[1rem] ">
              <div className="flex space-x-2 h-[2rem] ">
                <input
                  type="text"
                  id="search-navbar"
                  name="kalyanid"
                  value={formik.values.kalyanid}
                  onChange={formik.handleChange}
                  className="placeholder-[#D10A11] outline-none focus:outline-none border border-[#8F8F8F]  block p-2  w-full  rounded-md sm:text-sm "
                  placeholder="Search by ID"
                />

                <button
                  type="submit"
                  className="flex py-[3px] px-[12px] rounded-tr-[10px] rounded-bl-[10px] justify-between items-center bg-[#D10A11] "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="white"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
              </div>
              {!formik.errors.kalyanid && ProfileStatusMessage && <p className="md:text-[red] text-[#D10A11]  text-[12px] h-[1rem] pl-[2.5rem]">{ProfileStatusMessage}</p>}
              {formik.errors.kalyanid &&
                <p className="md:text-[red] text-white text-[12px] h-[1rem] pl-[2.5rem]">{formik.errors.kalyanid}</p>
              }
            </div>
          </form>
        </div>

      }

    </>
  );
};

export default Searchbyid;
