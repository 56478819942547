import React, { Component, useEffect, useState, useContext } from "react";
import SearchResultCard from "./SearchResultCard";
import SearchResultCard2 from "./SearchResultCard2";
import SearchResultCardMobileview from "./SearchResultCardMobileview";
import { useNavigate, useLocation } from "react-router";
import { RouteHelper, RoutePaths } from "../../Routes/RouteSegment";
import Constants from "../../Constants/constants";
import ContentCard from "./ContentCard";
import { CommonCheckButton } from "../DashboardComponents/DashboardUtills";


const SearchResultSection = (props) =>  {

   useEffect(() => {
     
   }, [props.menu,props.dashboardData,props?.photoRequest,props?.recommendationsmatchesCount ]);
  // console.log(props.data, "menuitem");
  console.log(props?.content,props.menu ,props?.contentCardRange, "menuitem1");
  const navigate = useNavigate();
  const location = useLocation()
  // recommended

  useEffect(() => {

    return () => {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    }
  }, [])


  const onProfileClick = (id) => {
    //old
    // navigate(RoutePaths.PROFILE, {
    //   state: { id: element?.profileid },
    // })

    //new
    // localStorage.setItem(Constants.loginLocalStorageKeys.currentProfileId, id)
    const notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)
    sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    const win = window.open(`/redirect?page=${RoutePaths.PROFILE}&pageUrl=/${RoutePaths.DASHBOARD}/${RoutePaths.PROFILE}&notificationId=${notificationId}`)
    if (win != null) {
      win[Constants.loginLocalStorageKeys.currentProfileId] = id
    }
    console.log("onProfileClick",win[Constants.loginLocalStorageKeys.currentProfileId] = id);
  }

  console.log(props,"resultsection");

  return (
    <div className="mt-6">
      <div className="hidden md:block ">
        <div className="w-full">
          <section className= {props?.menu === "recommended" ?"md:px-2 px-2    lg:pt-[0rem] md:w-[106%] lg:w-[100%]":"md:px-2 px-2   lg:pt-[0rem] md:w-[106%] lg:w-[100%]"}>
            {props.menu === "weekly" ? (
              <div className="grid grid-cols-1 gap-[0rem] ">
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                      props?.content?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          
                          data={props?.content[j]}
                          contentcard={props?.content}  
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}
                          />
                          :""
                          :""
                          ))
                      }
                    <SearchResultCard 
                    menu={props?.menu}
                      key={element?.profileid} 
                      cardData={element}
                      select={props?.onselect}
                      data={props.dashboardData}
                      // photoRequest={props?.photoRequest}
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                      callBackNav={() =>
                        onProfileClick(element?.profileid)
                      }
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                    /></>
                  ))}
              </div>
            ) : props.menu === "recommended" ? (
              <div className="grid grid-cols-1 gap-[0rem]">
             
                
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                       

                    <SearchResultCard
                    menu={props?.menu}
                    //  photoRequest={props?.photoRequest}
                      key={element?.profileid}
                      data={props.dashboardData}
                      onselect={props?.recommendationsmatchesCount}
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                      cardData={element}
                      callBackNav={() =>
                        onProfileClick(element?.profileid)
                      }
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                    />
                    </>
                  ))}
              </div>
            ) : props.menu === "response" ? (
              <div className="grid grid-cols-1 gap-[0rem]">
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                    <SearchResultCard2
                    //  photoRequest={props?.photoRequest}
                    menu={props?.menu}
                      key={element?.profileid}
                      cardData={element}
                      type="Unread"
                      viewProfileStatusPopup={(msg) => props?.viewProfileStatusPopup(msg)}
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackNav={() => 
                        onProfileClick(element?.profileid)
                      }
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                    />
                    </>
                  ))} 
               </div>
            ) : props.menu === "profilelookingfor" ? (
              <div className="grid grid-cols-1 gap-[0rem]">
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                    <SearchResultCard 
                    //  photoRequest={props?.photoRequest}
                    menu={props?.menu}
                    key={element?.profileid}
                      cardData={element}
                      data={props.dashboardData}
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                      callBackNav={() =>
                        onProfileClick(element?.profileid)
                      }
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                       
                    />
                    </>
                  ))}
              </div>
            ) : props.menu === "profilelookingforme" ? (
              <div className="grid grid-cols-1 gap-[0rem]">
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                    <SearchResultCard
                    //  photoRequest={props?.photoRequest}/
                    menu={props?.menu}
                      key={element?.profileid}
                      cardData={element}
                      data={props.dashboardData}
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackNav={() =>
                        onProfileClick(element?.profileid)
                      }
                      
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                    />
                    </>
                  ))}
              </div>
            ) : props.menu === "photorequestreceived" ? (
              <div className="grid grid-cols-1 gap-[0rem]">
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                      <SearchResultCard2
                        viewProfileStatusPopup={(msg) => props?.viewProfileStatusPopup(msg)}
                        menu={props?.menu}
                        key={element?.profileid}
                        cardData={element}
                        //  photoRequest={props?.photoRequest}
                        type="photorequest"
                        callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                        callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                        callBackNav={() =>
                          onProfileClick(element?.profileid)
                        }
                        callbackSelectReject={(type, typeRequest) => {
                          props.callbackSelectReject(
                            type,
                            element?.profileid,
                            typeRequest,
                            element,
                            i
                          );
                        }}  
                      />
                     </>
                     
                  ))}
              </div>
            ) : props.menu === "mutualmatches" ? (
              <div className="grid grid-cols-1 gap-[0rem]">
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                    <SearchResultCard
                    menu={props?.menu}
                    //  photoRequest={props?.photoRequest}
                      key={element?.profileid}
                      cardData={element}
                      data={props.dashboardData}
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                      callBackNav={() =>
                        onProfileClick(element?.profileid)
                      }
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }} 
                    />
                    </>
                  ))}
              </div>
            ) : props.menu === "selectedprofile" ? (
              <div className="grid grid-cols-1 gap-[0rem]">
             
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                       props?.content?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          :""
                          ))
                      }
                      
                    <SearchResultCard 
                    menu={props?.menu}
                    //  photoRequest={props?.photoRequest}
                      key={element?.profileid}
                      data={props.dashboardData}
                      cardData={element}
                      callBackBlockProfile={() => props?.callBackBlockProfile(element)}
                      viewProfileStatusPopup={(msg) => props?.viewProfileStatusPopup(msg)}
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                      callBackNav={() =>
                        onProfileClick(element?.profileid)
                      }
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }} 
                    />
                    </>
                  ))}
              </div>
            ) 
            : props?.menu === "recentlyviewed" ? (
               
              <div className="grid grid-cols-1 gap-[0rem]">
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                    <SearchResultCard
                    //  photoRequest={props?.photoRequest}
                    menu={props?.menu}
                      key={element?.profileid}
                      data={props.dashboardData}
                      cardData={element}
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                      callBackNav={() =>
                        onProfileClick(element?.profileid)
                      }
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }} 
                    />
                    </>
                  ))}
              </div>
            ) 
            : props.menu === "similarmatches" ? (
              <div className="grid grid-cols-1 gap-[0rem]">
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                    <SearchResultCard
                    menu={props?.menu}
                    //  photoRequest={props?.photoRequest}
                      key={element?.profileid}
                      data={props.dashboardData}

                      cardData={element}
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                      callBackNav={() =>{
                        localStorage.setItem("profileId", element?.profileid )
                        onProfileClick(element?.profileid)
                      }}
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }} 
                    />
                    </>
                  ))}
              </div>
            ) 
            : props.menu === "savepartnerpreferences" ? (
              <div className="grid grid-cols-1 gap-[0rem]">
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                    <SearchResultCard
                    //  photoRequest={props?.photoRequest}
                    menu={props?.menu}
                      key={element?.profileid}
                      cardData={element}
                      data={props.dashboardData}
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                      callBackNav={() =>
                        onProfileClick(element?.profileid)
                      }
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }} 
                    />
                    </>
                  ))}
              </div>
            ) 
             :props.menu === "explorematches" ? (
              <div className="grid grid-cols-1 gap-[0rem]">
                {props?.data &&
                  props?.data?.map((element, i) => (
                    <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                        searchBy={props?.searchBy}
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                    <SearchResultCard 
                    menu={props?.menu}
                      key={element?.profileid}
                      cardData={element}
                      //  photoRequest={props?.photoRequest}
                      data={props.dashboardData}
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                      callBackNav={() =>
                        onProfileClick(element?.profileid)
                      }
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                    />
                    </>
                  ))}
              </div>
            ) 
            : (
              " "
            )}
          </section>
        </div>
        {/* <div className="text-center md:mt-[1.3rem] lg:mt-[2rem] mb-[1rem]">
                  <button className=" rounded-[5px] text-[10px] px-3 py-2 bg-[#D10A11] text-[#FFFFFF] font-bold font-segeo">Load More</button>
               </div> */}
      </div>
      
      <div className="px-4  block md:pt-[2.25rem]  md:hidden">
        
        {props.menu === "weekly" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCardMobileview
                  key={element?.profileid}
                  cardData={element}
                  data={props.dashboardData}
                  callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                  callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                  callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                  props={props.menu}
                  callBackNav={() =>
                    onProfileClick(element?.profileid)
                  }
                  callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                />
                </>
              ))}
          </div>
        ) : props.menu === "recommended" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCardMobileview
                  key={element?.profileid}
                  cardData={element}
                  data={props.dashboardData}
                  callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                  callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                  callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                  callBackNav={() =>
                    onProfileClick(element?.profileid)
                  }
                  callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }} 
                />
                </>
              ))}
          </div>
        ) : props.menu === "response" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCard2
                  key={element?.profileid}
                  cardData={element}
                  menu="response"
                  type="Unread"
                  viewProfileStatusPopup={(msg) => props?.viewProfileStatusPopup(msg)}
                  callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                  callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                  callBackNav={() =>
                    onProfileClick(element?.profileid)
                  }
                  callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }} 
                />
                </>
              ))}
          </div>
        ) : props.menu === "profilelookingfor" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCardMobileview
                  key={element?.profileid}
                  cardData={element}
                  viewProfileStatusPopup={(msg) => props?.viewProfileStatusPopup(msg)}
                  callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                  callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                  callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                  callBackNav={() =>
                    onProfileClick(element?.profileid)
                  }
                  callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                />
                </>
              ))}
          </div>
        ) : props.menu === "profilelookingforme" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCardMobileview
                  key={element?.profileid}
                  cardData={element}
                  callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                  callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                  callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                  callBackNav={() =>
                    onProfileClick(element?.profileid)
                  }
                  callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                />
                </>
              ))}
          </div>
        ) : props.menu === "photorequestreceived" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCard2
                    viewProfileStatusPopup={(msg) => props?.viewProfileStatusPopup(msg)}
                    menu={props?.menu}
                      key={element?.profileid}
                      cardData={element}
                      type="photorequest"
                      callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                      callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                      callBackNav={() =>
                        onProfileClick(element?.profileid)
                      }
                      callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}  
                    />
                    </>
              ))}
          </div>
        ) : props.menu === "mutualmatches" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCardMobileview
                  data={props.dashboardData} 
                  key={element?.profileid}
                  cardData={element}
                  callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                  callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                  callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                  callBackNav={() =>
                    onProfileClick(element?.profileid)
                  }
                  callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                />
                </>
              ))}
          </div>
        ) : props.menu === "selectedprofile" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
       
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCardMobileview
                 data={props.dashboardData}
                  key={element?.profileid}
                  callBackBlockProfile={() => props?.callBackBlockProfile(element)}
                  cardData={element}
                  callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                  callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                  callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                  callBackNav={() =>
                    onProfileClick(element?.profileid)
                  }
                  callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                />
                </>
              ))}
          </div>
        )
        : props.menu === "similarmatches" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCardMobileview
                 data={props.dashboardData}
                  key={element?.profileid}
                  cardData={element}
                  callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                  callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                  callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                  callBackNav={() =>
                    onProfileClick(element?.profileid)
                  }
                  callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                />
                </>
              ))}
          </div>
        )
         : props.menu === "recentlyviewed" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCardMobileview
                 data={props.dashboardData}
                  key={element?.profileid}
                  cardData={element}
                  callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                  callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                  callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                  callBackNav={() =>
                    onProfileClick(element?.profileid)
                  }
                  callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                />
                </>
              ))}
          </div>
        )
         : props.menu === "savepartnerpreferences" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCardMobileview
                  key={element?.profileid}
                  data={props.dashboardData}
                  cardData={element}
                  callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                  callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                  callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                  callBackNav={() =>
                    onProfileClick(element?.profileid)
                  }
                  callbackSelectReject={(type, typeRequest) => {
                        props.callbackSelectReject(
                          type,
                          element?.profileid,
                          typeRequest,
                          element,
                          i
                        );
                      }}
                />
                </>
              ))}
          </div>
        ) 
        :props.menu === "explorematches" ? (
          <div className="grid grid-cols-1 gap-[0rem]">
            {props?.data &&
              props?.data?.map((element, i) => (
                <>
                      {props?.contentCardRange?.map((range, j) => (
                      i == range?
                        <ContentCard 
                        searchBy={props?.searchBy}
                          dashboardData={props?.dashboardData}
                          onSubmitSuccess={props?.onSubmitSuccess}
                          index={i}
                          data={props?.content[j]} 
                          setContentCardRange={props?.setContentCardRange}
                          contentCardPosition={props?.contentCardPosition} 
                          setContentCardPosition={props?.setContentCardPosition} 
                          contentCardRange={props?.contentCardRange}/>
                          :""
                          ))
                      }
                <SearchResultCard 
                menu={props?.menu}
                  key={element?.profileid}
                  cardData={element}
                  data={props.dashboardData}
                  callbackRequestPhoto={()=>props.callbackRequestPhoto(element)}
                  callBackPhone={()=>props.callBackViewPhone(element?.profileid,element)}
                  callBackParentsNumber={()=>props.callBackParentsNumber(element)}
                  callBackNav={() =>
                    onProfileClick(element?.profileid)
                  }
                  callbackSelectReject={(type, typeRequest) => {
                    props.callbackSelectReject(
                      type,
                      element?.profileid,
                      typeRequest,
                      element,
                      i
                    );
                  }}
                />
                </>
              ))}
          </div>
        ) 
        : (
          " "
        
        )}
      </div>
    </div>
  );
};

export default SearchResultSection;
