import{React, useState, createContext} from 'react'
export const CurrentContactContext = createContext('');

/**
 * The CurrentContextProvider function is a React component that provides state and functions related to
 * FAQs to its children components.
 * @returns The CurrentContextProvider component is being returned. It wraps the children components with
 * the CurrentContext.Provider, providing the addDetail object as the value for the CurrentContext.
 */
const CurrentContextProvider = ({children}) => {
    const [currentContact, setCurrentContact] = useState({name:'',pp:""});
    const [sliderImages,setSliderImgaes] = useState([]);
    const [sliderShow, setSliderShow] = useState(false);
    const addDetail = {currentContact, setCurrentContact,sliderImages,setSliderImgaes,sliderShow, setSliderShow}

    return (
        <CurrentContactContext.Provider value={addDetail}>
            {children}
        </CurrentContactContext.Provider>
    )
}

export default CurrentContextProvider
