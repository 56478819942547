import { DASHBOARD_LEFT_PANEL_DATA, DASHBOARD_LEFT_PANEL_DATA_API_ERROR, DASHBOARD_LEFT_PANEL_DATA_SUCCESS, DASHBOARD_MATCHES_LIST, DASHBOARD_MATCHES_LIST_API_ERROR, DASHBOARD_MATCHES_LIST_SUCCESS, DASHBOARD_PROFILE_DATA, DASHBOARD_PROFILE_DATA_API_ERROR, DASHBOARD_PROFILE_DATA_SUCCESS } from "./ActionTypes";

  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const DashBoardAPI = (state = initialState, action) => {
    switch (action.type) {
      case DASHBOARD_MATCHES_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
        case DASHBOARD_MATCHES_LIST_SUCCESS:
          console.log(action.payload.data,"action.payload");
        state = {
          ...state,
          matchesListData:action.payload.data,
          loading: false,
        };
        break;
      case DASHBOARD_MATCHES_LIST_API_ERROR:
        state = {
          ...state,
          matchesListDataError: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

      case DASHBOARD_LEFT_PANEL_DATA:
        state = {
          ...state,
          loading: true,
        };
        break;
        case DASHBOARD_LEFT_PANEL_DATA_SUCCESS:
        state = {
          ...state,
          leftPanelData:action.payload.data,
          loading: false,
        };
        break;
      case DASHBOARD_LEFT_PANEL_DATA_API_ERROR:
        state = {
          ...state,
          leftPanelDataError: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

      case DASHBOARD_PROFILE_DATA:
        state = {
          ...state,
          loading: true,
        };
        break;
        case DASHBOARD_PROFILE_DATA_SUCCESS:
        state = {
          ...state,
          ProfileData:action.payload.data,
          loading: false,
        };
        break;
      case DASHBOARD_PROFILE_DATA_API_ERROR:
        state = {
          ...state,
          ProfileDataError: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;

      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default DashBoardAPI;
  