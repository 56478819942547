import React from 'react'
import BannerIcon1 from "../../Images/banner@2x.png"
import BannerIcon2 from "../../Images/banner_v2@2x.png"

const Bannersection = () => {
  return (
     <div className="px-[4rem] mt-[3rem] md:mt-0 md:col-span-3 md:px-[2rem] lg:px-[4rem] md:py-[2rem] lg:py-[3rem] ">
              <div>
                <img src={BannerIcon1} alt=""/>
                <img src={BannerIcon2} alt=""/>
            </div>
            </div>
  )
}

export default Bannersection
