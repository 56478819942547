//register email & check
export const REGISTER_EMAIL_CHECK = "REGISTER_EMAIL_CHECK"
export const REGISTER_EMAIL_CHECK_SUCCESS = "REGISTER_EMAIL_CHECK_SUCCESS"
export const REGISTER_EMAIL_CHECK_FAILURE = "REGISTER_EMAIL_CHECK_FAILURE"
export const REGISTER_PHONE_CHECK = "REGISTER_PHONE_CHECK"
export const REGISTER_PHONE_CHECK_SUCCESS = "REGISTER_PHONE_CHECK_SUCCESS"



//register form
export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const API_ERROR = "REGISTER_API_ERROR"
export const RESET_API_ERROR = "REGISTER_RESET_API_ERROR"
