import { SEARCH_BY_ID, SEARCH_BY_ID_API_ERROR, SEARCH_BY_ID_SUCCESS } from "./ActionTypes";


  //Sent FIlter request
  export const searchByIdRequest = (values) => {
    //console.log(values);
    return {
      type: SEARCH_BY_ID,
      payload: {values}
    };
  };

  export const searchByIdRequestSuccess = data => {
    //console.log(data,"12344");
    return {
      type: SEARCH_BY_ID_SUCCESS,
      payload: data,
    };
  };
  
  export const searchByIdRequestApiError = error => {
    return {
      type: SEARCH_BY_ID_API_ERROR,
      payload: error,
    };
  };


  