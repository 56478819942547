import { call, put, takeEvery } from "redux-saga/effects";
import { BOOK_APPOINTMENT, GET_STATE_LIST, GET_STORE_LIST} from "./ActionTypes";
import { getStateListApiError, getStateListSuccess, getStoreListApiError, getStoreListSuccess, submitBookAppointment, submitBookAppointmentApiError, submitBookAppointmentSuccess} from "./Action";
import { Book_Appointment, Get_State_List, Get_Store_List } from "../../Helpers/api_helpers";
import RouteSegments from "../../Routes/RouteSegment";


function* GetStateList({ payload: {id,email} }) {
  //console.log(id,email,"datadata");
  try {
    const response = yield call(Get_State_List,{
      "email": email ?? null,
      "userId": id,
      "loginFrom": "Android",
      "loginFromEmail": "appointment"
  });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(getStateListSuccess(response));
    } else {
      yield put(getStateListSuccess(response));
    }
  } catch (error) {
    yield put(getStateListApiError(error));
  }
}

function* getStoreList({ payload: {stateId,id,email} }) {
  //console.log(stateId,id,email,"datadata");
  try {
    const response = yield call(Get_Store_List,{
        "userId": id,
        "email": email ?? null,
        "stateId": stateId
  });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(getStoreListSuccess(response));
    } else {
      yield put(getStoreListSuccess(response));
    }
  } catch (error) {
    yield put(getStoreListApiError(error));
  }
}

function* BookAppointmentApiCall({ payload: {result, type, values, id, email} }) {
  //console.log(result, type, values, id, email,"datadata");
  try {
    const response = yield call(Book_Appointment,{
      "userId": id,
      "email":email ?? null,
      "muhurattype":type,
      "stateId":values?.state,
      "storeId":values?.store,
      "groomName":values?.name,
      "mobileNumber":values?.phone,
      "appoinmentDate":result,
      "loginFrom":"",
      "loginFromEmail":"appoinment"
  });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      if(response.data.data.status === "SUCCESS"){
        // history(RouteSegments.staticLinkForBookAppointment)
        yield put(submitBookAppointmentSuccess(response));
      }
    } else {
      yield put(submitBookAppointmentSuccess(response));
    }
  } catch (error) {
    yield put(submitBookAppointmentApiError(error));
  }
}




function* bookAppointmentSaga() {
  yield takeEvery(GET_STATE_LIST, GetStateList);
  yield takeEvery(GET_STORE_LIST, getStoreList);
  yield takeEvery(BOOK_APPOINTMENT, BookAppointmentApiCall);
}

export default bookAppointmentSaga;
