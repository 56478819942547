export const GET_ACTIVITY_LIST = "GET_ACTIVITY_LIST"
export const GET_ACTIVITY_LIST_SUCCESS = "GET_ACTIVITY_LIST_SUCCESS"
export const GET_ACTIVITY_LIST_FAIL = "GET_ACTIVITY_LIST_FAIL"

export const GET_ACTIVITY_TOTAL_POINTS = "GET_ACTIVITY_TOTAL_POINTS"
export const GET_ACTIVITY_TOTAL_POINTS_SUCCESS = "GET_ACTIVITY_TOTAL_POINTS_SUCCESS"
export const GET_ACTIVITY_TOTAL_POINTS_FAIL = "GET_ACTIVITY_TOTAL_POINTS_FAIL"


export const ADD_POINT = "ADD_POINT"
export const ADD_POINT_SUCCESS = "ADD_POINT_SUCCESS"
export const ADD_POINT_FAIL = "ADD_POINT_FAIL"

export const HIDE_CELEBRATION = "HIDE_CELEBRATION"
export const POINT_AWARDED = "POINT_AWARDED"

export const REWARD_POPUP_SHOWN = "REWARD_POPUP_SHOWN"

