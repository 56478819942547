//get state list
export const GET_STATE_LIST = "GET_STATE_LIST"
export const GET_STATE_LIST_SUCCESS = "GET_STATE_LIST_SUCCESS"
export const GET_STATE_LIST_API_ERROR = "GET_STATE_LIST_API_ERROR"

//get store list
export const GET_STORE_LIST = "GET_STORE_LIST"
export const GET_STORE_LIST_SUCCESS = "GET_STORE_LIST_SUCCESS"
export const GET_STORE_LIST_API_ERROR = "GET_STORE_LIST_API_ERROR"

//submit Appointemnt
export const BOOK_APPOINTMENT = "BOOK_APPOINTMENT"
export const BOOK_APPOINTMENT_SUCCESS = "BOOK_APPOINTMENT_SUCCESS"
export const BOOK_APPOINTMENT_API_ERROR = "BOOK_APPOINTMENT_API_ERROR"
