import { useEffect, useState } from 'react'
import { useJitsu } from "@jitsu/jitsu-react"
import { useLocation ,useRouteMatch,useParams} from "react-router-dom"
import Constants, { PageName } from '../Constants/constants'
import RouteSegments , {RoutePaths} from '../Routes/RouteSegment'
import EndPoints from '../Constants/EndPoints'
import { useSelector } from 'react-redux'


export default function usePageChange(data) {

    const { analytics } = useJitsu()
    const location = useLocation()
    const isV3User = localStorage.getItem("isV3User")
    const {tab} = useParams();
    
    const pageName = location.pathname ==  RouteSegments.MATCH_LITE ? PageName.Matches_Matches 
    : location.pathname == RouteSegments.ABOUT_US ? PageName.Home_page_About_us
    : location.pathname == RouteSegments.SAFETY_AND_SECURITY ? PageName.Home_page_Safety_Security
    : location.pathname == RouteSegments.ABOUT_KALYAN_JEWELLERS ? PageName.Home_page_About_Kalyan_Jewellers
    : location.pathname == RouteSegments.HOW_IT_WORKS ? PageName.Home_page_How_it_Works
    : location.pathname == RouteSegments.HELP_AND_FAQ() ? PageName.Home_page_Help_Support
    : location.pathname == RouteSegments.DELETE_PHOTO ? PageName.Home_page_Delete_Photo 
    : location.pathname == RouteSegments.TERMS_AND_CONDITION ? PageName.Home_page_Terms_Conditions
    : location.pathname == RouteSegments.JEWELLERY_MICROSITE ? PageName.Home_page_Book_an_Appointment
    : location.pathname == RouteSegments.BANNER_REDIRECTION_GOLD ? PageName.Home_page_Kalyan_Gold_Scheme   
    : location.pathname == RouteSegments.PRIVACY_POLICY ? PageName.Home_page_Privacy_Policy  
    : location.pathname == RouteSegments.MATCH_CONVERSATIONS_LITE ? PageName.Matches_New_conversation       
    : location.pathname == RouteSegments.MATCH_GROUP ? PageName.Family_Family 
    : location.pathname == RouteSegments.FILTER_LITE  ? PageName.Filter_Main_Filter 
    : location.pathname == RouteSegments.CHAT_LITE  ? PageName.Chat_Chat
    : location.pathname == RouteSegments.MATCH_CONVERSATIONS_LITE  ? PageName.Chat_Chat_Search
    : location.pathname == RouteSegments.MEMBERSHIP_REGISTER()  ? PageName.Payment_Payment 
    : location.pathname == `/${RoutePaths.EDITPROFILE_LITE}/${tab}` ? 
            tab == 'my-profile' ? PageName.MyProfileButton
            :tab=='partner-preferences' ? PageName.ProfilePartnerPreference
            :tab=='photos' ? PageName.ProfilePhotos
            :tab=='trust-mark' ? PageName.ProfileTrustMark
            :tab=='horoscope' ? PageName.ProfileHoroscope
            :tab=='settings' ? PageName.ProfileSettings
            :tab=='my-plan' ? PageName.ProfileMyPlan
            :tab=='profile-viewed-by-me' ? PageName.ProfileViewedByMe
            :tab=='who-viewed-my-profile' ? PageName.ProfileWhoViewedMyProfile
            :tab=='phone-numbers-viewed-by-me' ? PageName.ProfilePhoneNumberViewedByMe
            :tab=='who-viewed-my-phone-number' ? PageName.ProfileWhoViewedMyPhoneNumber
            :null         
   
    : location.pathname == RouteSegments.NOTIFICATION_LITE  ? PageName.Notifications_Notifications           
    
    :null

    
    useEffect(() => {
        const loginName = localStorage.getItem(Constants.loginLocalStorageKeys.loginUserName)
        const loginId = localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
        const loginEmail = localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail)
        analytics.page({ userId: loginId, name: loginName, email: loginEmail,...{pageName: `${isV3User ? "V3" : "V2"}_${pageName}`}})
    }, [location.pathname])

    return location;
}

export const useAnalytics = () => {

    const { analytics } = useJitsu()

    const isV3User = localStorage.getItem("isV3User")

    const onClickTrack = (eventName, data) => {
        const loginId = localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
        analytics.track(`${isV3User ? "V3" : "V2"}_${eventName}`, { userId: loginId, ...data })
    }

    return { onClickTrack }
}

export const usePageTrack = () => {

    const { analytics } = useJitsu()

    const isV3User = localStorage.getItem("isV3User")

    const onPageTrack = (pageName , path, url) => {
        const loginName = localStorage.getItem(Constants.loginLocalStorageKeys.loginUserName)
        const loginId = localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
        const loginEmail = localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail)
        analytics.page({ userId: loginId, name: loginName, email: loginEmail,...{pageName: `${isV3User ? "V3" : "V2"}_${pageName}`}})
    }

    return { onPageTrack }
}

