import React from 'react'
import phoneimage from '../../Components/Kalyanlite/assets/Images/Group 172.svg'
import AddPhoto from '../../Components/Kalyanlite/assets/Images/Group 1801.svg'
import AddPartnerPrefIcon from '../../Components/Kalyanlite/assets/Images/Group 2378.svg';
import DownloadourApp from '../../Components/Kalyanlite/assets/Images/Group 3864.svg'
import FamilyIcon from '../../Components/Kalyanlite/assets/Images/newgroupicon.svg'
import { useTranslation } from 'react-i18next';

const TemplateMessage = ({ flag, content, onClick, className, isLoading }) => {

    const { t: trans } = useTranslation();

    let icon;
    let buttonText = trans('matches.add_now');
    let imgWidth = "3rem";
    let imgHeight = "3rem";

    if (flag === "Add partner preference") {
        icon = AddPartnerPrefIcon;
        buttonText = trans('matches.set_now')
    } else if (flag === "Add parents number") {
        icon = phoneimage;
    } else if (flag === "Add Photo") {
        icon = AddPhoto;
    } else if (flag === "Add Family Members") {
        icon = FamilyIcon;
        buttonText = trans('matches.add_family_members')
    }
    else {
        // For DownloadourApp case
        icon = DownloadourApp;
        buttonText = trans('matches.download_now');
        imgWidth = "4rem";
        imgHeight = "4rem";
    }
    // if (flag === "Add parents number") {
    //     return <div className="border border-[#F181818A] shadow-[rgba(50,50,93,0.25)_0px_-2px_5px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] mx-3 rounded-[20px] mt-2">
    //         <div className="flex items-center justify-center space-x-5 py-3 px-2">
    //             <img alt='' src={phoneimage} className="w-[3rem] h-[3rem]" />
    //             <p className="text-[10px] text-[#575556] text-center">{content}</p>
    //         </div>
    //         <button onClick={onClick} className="rounded-tl-[30px] rounded-br-[30px] font-bold float-right text-[9px] py-1 px-7 bg-[#D10A11] text-white">Add Now</button>

    //     </div>
    // } else if (flag === "Add photo") {
    //     return <div className="border border-[#F181818A] shadow-[rgba(50,50,93,0.25)_0px_-2px_5px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] mx-3 rounded-[20px] mt-2">
    //     <div className="flex items-center justify-center space-x-5 py-3 px-2">
    //         <img alt='' src={phoneimage} className="w-[3rem] h-[3rem]" />
    //         <p className="text-[10px] text-[#575556] text-center">{content}</p>
    //     </div>
    //     <button onClick={onClick} className="rounded-tl-[30px] rounded-br-[30px] font-bold float-right text-[9px] py-1 px-7 bg-[#D10A11] text-white">Add Now</button>

    // </div>
    // } else if (flag === "Add partner preference") {
    //     return <div className="border border-[#F181818A] shadow-[rgba(50,50,93,0.25)_0px_-2px_5px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] mx-3 rounded-[20px] mt-2">
    //     <div className="flex items-center justify-center space-x-5 py-3 px-2">
    //         <img alt='' src={phoneimage} className="w-[3rem] h-[3rem]" />
    //         <p className="text-[10px] text-[#575556] text-center">{content}</p>
    //     </div>
    //     <button onClick={onClick} className="rounded-tl-[30px] rounded-br-[30px] font-bold float-right text-[9px] py-1 px-7 bg-[#D10A11] text-white">Add Now</button>

    // </div>
    // }

    return <div className={`flex flex-col border border-[#F181818A] shadow-[rgba(50,50,93,0.25)_0px_-2px_5px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] mx-3 my-2 rounded-[20px]`}>
        <div className="flex items-center justify-center space-x-5 pt-3 px-2">
            <img alt='' src={icon} className={`w-[${imgWidth}] h-[${imgHeight}]`} />
            <p className="text-[9px] text-center md:text-[8px] lg:text-[10px] text-[#575556] font-semibold">{content}</p>
        </div>
        <button disabled={isLoading} onClick={onClick} className="w-fit self-end rounded-tl-[30px] rounded-br-[30px] font-bold float-right text-[9px] py-1 px-7 bg-[#D10A11] text-white">
            {isLoading ? trans('matches.loading') : buttonText}
        </button>
    </div>

    // return (
    //     <>
    //         <div className="border border-[#F181818A] shadow-[rgba(50,50,93,0.25)_0px_-2px_5px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] mx-3 rounded-[20px] mt-2">
    //             <div className="flex items-center justify-center space-x-5 py-3 px-2">
    //                 <img src={phoneimage} className="w-[3rem] h-[3rem]" />
    //                 <p className="text-[10px] text-[#575556] text-center">Dear Name, adding your Parent's Number helps serious partners reach you faster.</p>
    //             </div>
    //             <button className="rounded-tl-[30px] rounded-br-[30px] font-bold float-right text-[9px] py-1 px-7 bg-[#D10A11] text-white">Add Now</button>

    //         </div>

    //         <div className="mt-2 border border-[#F181818A] mx-3 rounded-[20px]">
    //             <div className="flex items-center justify-center space-x-5 py-2 px-2">
    //                 <img src={photoicon} className="w-9 h-8" />
    //                 <p className="text-center text-[10px] text-[#575556]">Dear Name, Adding a Photo to your Profile will increase your chances of getting more responses. Click here and Add a Photo Now.</p>
    //             </div>
    //             <button className="rounded-tl-[30px] rounded-br-[30px] float-right text-[9px] py-1 px-7 bg-[#D10A11] text-white">Add Now</button>

    //         </div>

    //         <div className="mt-2 border border-[#F181818A] mx-3 rounded-[20px]">
    //             <div className="flex items-center justify-center space-x-5 py-2 px-2">
    //                 <img src={photoicon} className="w-9 h-8" />
    //                 <p className="text-center text-[10px] text-[#575556]">Dear Name, Set Your Partner Expectations and increase your chances of finding your perfect match more easily.</p>
    //             </div>
    //             <button className="rounded-tl-[30px] rounded-br-[30px] float-right text-[9px] py-1 px-7 bg-[#D10A11] text-white">Set Now</button>

    //         </div>

    //          {/* <div className="px-5 mt-3">
    //       <p className="text-[#575556] text-[11px]">
    //       Profiles filtered above are based on filters selected 
    //       <br/>by you to view more profiles,<span className="underline text-[#575556] text-[11px]">Edit Filters here.</span>
    //        <br/>
    //        <a className="text-[11px] text-[#D10A11]">Edit Partner Expectations</a> 
    //        <span className="text-[11px] text-[#D10A11]"> To View More Matches</span>
    //       </p>
    //       <div className="mt-2 flex justify-center items-center">
    //       <button className="bg-[#D10A11] text-white rounded-md py-1 px-7  text-[11px]">Yes</button>

    //         </div>
    //     </div> */}
    //         </>


    // )
}

export default TemplateMessage