import React from 'react'
import * as Images from "../assets/images"
import CloseIcon from "../../../Images/Group 761@2x.png";

function PaymentStatusPopup({ paymentStatus, close }) {
    let isPaymentSuccess = paymentStatus === "success"

    const registrationAmont = localStorage.getItem("REG_AMOUNT");    

    return (
        <div className="flex justify-center bg-opacityblack75 items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full md:w-[50vw] lg:w-[40vw]">
                <button
                    className="hidden md:block bg-transparent z-40 border-0 text-black  absolute -top-2.5 right-[-6px]"
                    onClick={close}
                >
                    <img className="h-6 w-6 " src={CloseIcon} alt="" />
                </button>
                <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className='flex items-center justify-center py-4'>
                        <p className='bg-[#03951130] font-Poppins-Regular font-bold text-[#1F6700] text-[18px] px-6 py-2 rounded-md'>
                            {isPaymentSuccess ? "Transaction Successful!" : "Transaction Failure!"}
                        </p>
                    </div>
                    <hr className='border-dashed border-[#707070]' />
                    <div className='px-4 pb-4'>
                        <div className='flex items-center justify-center pt-2'>
                            {isPaymentSuccess ?
                                <img src={Images.TickGif} className='w-14 h-14' alt='' />
                                : <img src={Images.PaymentFailIcon} className='w-14 h-14' alt='' />}
                        </div>
                        <p className='font-Poppins-SemiBold text-[14px] text-center py-4'>
                            {isPaymentSuccess
                                ? `Your payment of Rs.${registrationAmont} for profile registration in Kalyan Matrimony is successful`
                                : `Your payment of Rs.${registrationAmont} for profile registration in Kalyan Matrimony is Failed`}
                        </p>
                        <p className='text-[#4C5B71] font-Poppins-SemiBold text-[14px] text-center py-2'>Use our helpline if you face any issues or queries</p>
                        <p className='text-[#D71920] font-Poppins-SemiBold text-[12px] text-center py-2'>
                            <img src={Images.PaymentHelplineIcon} className='w-6 h-w-6 inline-block mr-2' alt='' />
                            Phone no : 044 40146969</p>
                        {isPaymentSuccess && <div className='flex items-center justify-center mt-4 mb-2'>
                            <button onClick={close} className='bg-[#D10A11] text-[12px] font-segeo font-bold text-white px-2.5 py-1.5 rounded-md'>Continue Registering</button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentStatusPopup