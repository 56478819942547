import React, { useEffect, useRef, useState, useContext } from "react";

const CommonRadioButton = (props) => {


  useEffect(() => {
    
  }, [props?.radiotext ,props?.id]);
 const string = props?.radiotext;
 const value =props?.id;
 let text   = props?.radiotext;
 const result = text.indexOf('Please reach')!= -1 ? [text.slice(0,text.indexOf('Please reach')), <br />,text.slice(text.indexOf('Please reach'))]:  props.radiotext;
 

 
  return (
    <div className="flex mt-2 items-center">
      <div className="bg-white dark:bg-gray-100 rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative cursor-pointer">
        <input
          aria-labelledby="label1"
          id={props.id}
          checked={props.selected}
          type="radio"
          value={props.name}
          onChange={(e) => props.onChange(e)}
          name={props.name}
          // className="checkbox appearance-none checked:opacity-100 checked:ring-2 checked:ring-offset-2 checked:ring-[#D10A11] focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full"
        className=" accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer w-full h-full"
        />
        <div className="check-icon hidden  border-4 border-[#D10A11] rounded-full w-full h-full z-1"></div>
      </div>
      <label
        htmlFor={props.id}
        className=" font-normal text-[11px] md:text-[10px] lg:text-[13px] text-[#575556] pl-1 cursor-pointer"
      >
        {result}
       
        {/* {string?.split(".").map((item,i) => 
             <div className="">
                 {item.length > 1 ? item + "." : item}
              </div>
             )} */}
     
        {//console.log(props?.id ,"props?.dashboard1Data?.msgcontent2 ")

        }
      </label>
    </div>
  );
};

const CommonCheckButton=(props)=>{
    return(
        <div className="flex mt-2 items-center">
      <div className="bg-white dark:bg-gray-100 rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative cursor-pointer">
        <input
        //   aria-labelledby="label1"
          id={props.id}
          checked={props.selected}
          type="checkbox"
          value={props.name}
          onChange={(e) => props.onChange(e)}
          name={props.name}
          {...props}
        //   className="checkbox appearance-none checked:opacity-100 checked: checked:ring-[#D10A11] focus:outline-none border  border-gray-400 absolute cursor-pointer w-full h-full"
        />
        <div className="check-icon hidden  border-4 border-[#D10A11] rounded-full w-full h-full z-1"></div>
      </div>
      <label
        htmlFor={props.id}
        className=" font-semibold text-[11px] md:text-[10px] lg:text-[13px] text-[#575556] pl-1 cursor-pointer"
      >
        {props.checktext}
      </label>
    </div>
    )
}
export {CommonRadioButton,CommonCheckButton};
