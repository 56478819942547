import React from 'react'
import Constants from '../../Constants/constants'

function CommunityTag({ className = "" }) {
    const isCommunitySite = localStorage?.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

    return isCommunitySite ? (
        <>
            <div className='mobview bg-white'>
                <div className='mob-top md:!left-[68%] md:right-[7%]'>
                    <div id='top1' className='md:h-[3rem]'>
                        <p className='py-1 md:pt-2'>
                            {localStorage.getItem(
                                Constants.loginLocalStorageKeys.tagContent,
                            )}
                        </p>
                    </div>
                </div>
                <br />
            </div>
            <div className='webview'>
                <div className='mob-top !left-[68%] right-[7%]'>
                    <div id='top1' className='h-[3rem] !z-[11]'>
                        <p className='pt-2'>
                            {localStorage.getItem(
                                Constants.loginLocalStorageKeys.tagContent,
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </>
    ) : null
}

export default CommunityTag