import React, { useContext, useEffect, useState } from "react";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST } from "../../../Services/api_services";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import Constants, { EventKeys, EventName, loginFrom } from "../../../Constants/constants";
import config from "../../../config";
import CloseIcon from "../../../Images/Group 761@2x.png"
import profileicon from '../../../Components/Kalyanlite/assets/Images/verificationprofile.svg'
import { useAnalytics } from "../../../Hooks/usePageChange";
import { useTranslation } from "react-i18next";

const OtpPopup = (props) => {
  const [counter, setCounter] = useState(30);
  const { loginDetail, logout } = useContext(LoginContext);
  const { onClickTrack } = useAnalytics()
  const { t: trans } = useTranslation();

  const isV3User = localStorage.getItem("isV3User")


  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    // enableReinitialize: true,
    validationSchema: Yup.object().shape({
      otp: Yup.string().required(trans('settings.please_enter_pin')),
    }),

    onSubmit: (values) => {
      apiCallVerifyOTP(values);
      // onClickTrack(EventName.profileVerifyMobileButton , {[EventKeys.profileVerifyMobile]:request})
    },
  });
  const apiCallVerifyOTP = async (value) => {
    //console.log("otp", value);


    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      otp: value.otp,
      isdcode: props.isdCode,
      mobileNumber: props.mobileNum,
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.verifymobileUrl()}`,
      request
    );
    if (statusCode === 200) {
      //console.log("data.", data.data);
      if (data.data.status == "success") {
        //   setShowOTPpopup(true);
        props.onVerified();

      } else {
        formik.setFieldError("otp", data.data.status)
        //console.log(data.data.statusMessage)

      }
      onClickTrack(EventName.profileVerifyMobileButton, { [EventKeys.profileVerifyMobile]: request })
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apicallSendOTP = async () => {
    if (counter > 0) {

    } else {
      setCounter(30)
      props.callBackOTP()

    }
    onClickTrack(EventName.profileRequestAgainButton)
    //console.log("send otp");
  };
  return (
    <>
      {isV3User ?
        <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-[90%]  md:w-[54%] lg:w-[38%] my-6 mx-auto ">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className=''>
                <button
                  className=' bg-transparent z-40 border-0 text-black  absolute top-[-6px] right-[-6px]'
                  onClick={() => {
                    props.close();
                  }}>
                  <img className='h-5 w-5 ' src={CloseIcon} alt='' />
                </button>
              </div>
              <div className="flex pt-4 items-center justify-center ">
                <img className="w-[4rem] h-[4rem]" src={profileicon} alt="" />
              </div>
              <h3 className="flex items-center pt-1 justify-center rounded-t  text-[22px] font-semibold text-[#D10A11] align-middle ">
                {trans('settings.mobile_verification')}
              </h3>

              <div className="relative px-[4rem] py-2 mb-2 flex flex-col justify-center items-center">
                <p className=" text-[#575556] text-center text-[14px] font-semibold">
                  {/* A SMS with your verification PIN has been sent to your mobile
                  number - {props.mobileNum} */}
                  {trans('settings.a_sms_with_your_verification_p', { mob_number: props.mobileNum })}

                </p>

                <form
                  onClick={formik.handleSubmit}
                  className="flex flex-col pt-1 space-y-3 items-center justify-center RegPhone"
                >
                  <input
                    name={"otp"}
                    id={"otp"}
                    type="number"
                    placeholder={trans('settings.enter_pin')}
                    value={formik.values.moreInfo}
                    onChange={formik.handleChange}
                    className=" RegPhone block p-2.5 my-1 w-[8rem] text-sm text-[#8F8F8F] text-center bg-gray-50 rounded-[3px] border border-[#707070] truncate"
                  />
                  {formik.errors.otp && (
                    <p className="text-[red] text-[12px]">{formik.errors.otp}</p>
                  )}
                  <button
                    type="submit"
                    className=" flex items-center justify-center my-1 text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold py-2 md:py-2 lg:py-2 md:text-[12px] lg:text-[13px]"
                  >
                    {trans('settings.verify_mobile')}
                  </button>
                </form>
                <p className="py-3  text-center text-[#575556] text-[14px] font-semibold">
                  {trans('settings.sms_will_reach_you_shortly_if')}
                  <span
                    onClick={apicallSendOTP}
                    className="underline cursor-pointer text-[#0000ff]"
                  >
                    {counter > 0 ? trans('settings.wait_for_number_s', { number: `${counter}s` }) : trans('settings.request_again')}
                  </span>
                </p>
              </div>
              <div className="flex justify-end  mr-5"></div>
            </div>
          </div>
        </div>
        :
        <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-[92%] md:w-[38%] my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex justify-end">
                <button
                  className=" bg-transparent border-0 text-black relative right-[-1.6rem] top-[-1.5rem] float-right pr-4"
                  onClick={() => {
                    props.close();
                  }}
                >
                  <img
                    className=" md:h-6 md:w-6 h-4 w-4 mt-4"
                    src={CloseIcon}
                    alt=""
                  />
                </button>
              </div>
              <div className="flex items-center justify-center py-2  rounded-t ">
                <h3 className="text-[22px] font-semibold text-[#D10A11] align-middle ">
                  {trans('settings.mobile_verification')}
                </h3>
              </div>
              <p className=" mx-7 border-t-2 border-t-[#D10A11] border-dashed"></p>

              <div className="relative p-6 flex flex-col justify-center items-center">
                <p className=" text-[#575556] text-[14px] font-semibold">
                  {trans('settings.a_sms_with_your_verification_p', { mob_number: props.mobileNum })}

                </p>

                <form
                  onClick={formik.handleSubmit}
                  className="flex flex-col items-center justify-center RegPhone"
                >
                  <input
                    name={"otp"}
                    id={"otp"}
                    type="number"
                    placeholder={trans('settings.enter_pin')}
                    value={formik.values.moreInfo}
                    onChange={formik.handleChange}
                    className=" RegPhone block p-2.5 my-1 w-[8rem] text-sm text-gray-900 text-center bg-gray-50 rounded-[3px] border border-[#8F8F8F]"
                  />
                  {formik.errors.otp && (
                    <p className="text-[red] text-[12px]">{formik.errors.otp}</p>
                  )}
                  <button
                    type="submit"
                    className=" flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[19px]"
                  >
                    {trans('settings.verify_mobile')}
                  </button>
                </form>
                <p className="py-3  text-[#575556] text-[14px] font-semibold">
                  {trans('settings.sms_will_reach_you_shortly_if')}
                  <span
                    onClick={apicallSendOTP}
                    className="underline cursor-pointer text-[#0000ff]"
                  >
                    {counter > 0 ? trans('settings.wait_for_number_s', { number: `${counter}s` }) : trans('settings.request_again')}
                  </span>
                </p>
              </div>
              <div className="flex justify-end  mr-5"></div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default OtpPopup;
