import { call, put, takeEvery } from "redux-saga/effects";
import Constants from "../../Constants/constants";


// Login Redux States
import { REGISTER_USER, REGISTER_EMAIL_CHECK, REGISTER_PHONE_CHECK} from "./ActionTypes";
import { apiError, registerSuccess, registerEmailCheckSuccess, registerEmailCheckFailure, registerPhoneCheckSuccess } from "./Action";
import { Register, Register_email_check, Register_phone_check } from "../../Helpers/api_helpers";
import RouteSegments from "../../Routes/RouteSegment";
import { getDeviceInfo } from "../../Components/Kalyanlite/utils";



function* registerEmailCheck({ payload: user }) {
  //console.log(user,"123");
  try { // 
    //console.log(user.values.email,"123");
    const responseEmail = yield call(Register_email_check, {
      email: user.values.email ?? null,
      loginFrom : getDeviceInfo()
    });
    //console.log(responseEmail.data.data.status,"email check api response");
    if (responseEmail.data.status === "Success") {
      if (responseEmail.data.data.status === "FAILURE") {
        yield put(registerEmailCheckFailure(responseEmail));
      }
      else{
        yield put(registerEmailCheckSuccess(responseEmail));
      }
        }
        else {
          //console.log("123");
          yield put(apiError(responseEmail));
        }
       
  } catch (error) {
    //console.log("123");
    yield put(apiError(error));
  } 
}

function* registerPhoneCheck({ payload: phone }) {
  //console.log(phone,"123");
  try { // 
    //console.log(phone.phoneNumber,"123");
    const response = yield call(Register_phone_check, {
      mobileNumber: phone.phoneNumber,
    });
    //console.log(response,"register response");
    if (response.data.status === "Success") {
      if (response.data.data.status === "FAILURE") {
        yield put(registerEmailCheckFailure(response));
      }
      else{
        localStorage.setItem(Constants.loginLocalStorageKeys.loginId,response.data.data.userId)
        yield put(registerEmailCheckSuccess(response));
      }
        } else {
      //console.log("123");
      yield put(apiError(response));
    }
  } catch (error) {
    //console.log("123");
    yield put(apiError(error));
  }
}


function* registerUser({ payload: { user, history } }) {
  //console.log(user,history,"123");
  try { // 
    //console.log(user,"123");
    const response = yield call(Register, {
      username: user.name,
      password: user.password,
      email: user.email ?? null,
      mobileNumber: user.phone,
      apikey: "",
      countryId: user.countryId,
      profileForId: user.profileId,
      casteId: user.casteId,
      motherTongueId: user.motherTongueId,
      religionId: user.religionId,
      gender: user.gender,
      utmSource: user.utmsource,
      utmMedium: user.utmmedium,
      utmCampaign: user.utmcampaign,
      domainId :user.denomination,
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      registerfrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      webKey: localStorage.getItem(Constants.loginLocalStorageKeys.fcmToken),
      otherCaste: user.otherCaste,
    });
    console.log(response,"register response");
    if (response.data.status === "SUCCESS") {
      localStorage.setItem(Constants.loginLocalStorageKeys.loginName,response.data.data.email ?? "") //username
      localStorage.setItem(Constants.loginLocalStorageKeys.loginPassword,user?.password ?? "")
      localStorage.setItem(Constants.loginLocalStorageKeys.loginId,response.data.data.userid) //userid
      localStorage.setItem(Constants.loginLocalStorageKeys.isLoggedIn,true) //userid
      yield put(registerSuccess(response));
      //console.log('history', history);
      // history('/')
      // window.location.href="https://www.kalyanmatrimony.com/"
      if (response.data.data?.paymentToken) {
        localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.BASIC_INFO)
        window.location.href = response.data.data?.paymentToken
      } else {
        localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.BASIC_INFO)
        history(RouteSegments.BASIC_INFO);
      }
      //console.log("123");
    } else {
      //console.log("123");
      yield put(apiError(response));
    }
  } catch (error) {
    //console.log("123");
    yield put(apiError(error));
  }
}


function* registerSaga() {
  yield takeEvery(REGISTER_USER, registerUser);
  yield takeEvery(REGISTER_EMAIL_CHECK, registerEmailCheck);
  yield takeEvery(REGISTER_PHONE_CHECK, registerPhoneCheck);
}

export default registerSaga;
