//onload membership detail
export const MEMBERSHIP_DETAIL = "MEMBERSHIP_DETAIL"
export const MEMBERSHIP_DETAIL_SUCCESS = "MEMBERSHIP_DETAIL_SUCCESS"
export const MEMBERSHIP_DETAIL_API_ERROR = "MEMBERSHIP_DETAIL_API_ERROR"

//call back
export const CALLBACK_DETAIL = "CALLBACK_DETAIL"
export const CALLBACK_DETAIL_SUCCESS = "CALLBACK_DETAIL_SUCCESS"
export const CALLBACK_DETAIL_API_ERROR = "CALLBACK_DETAIL_API_ERROR"

//apply coupen code
export const COUPEN_CODE = "COUPEN_CODE"
export const COUPEN_CODE_SUCCESS = "COUPEN_CODE_SUCCESS"
export const COUPEN_CODE_API_ERROR = "COUPEN_CODE_API_ERROR"

//apply pay now
export const PAY_NOW = "PAY_NOW"
export const PAY_NOW_SUCCESS = "PAY_NOW_SUCCESS"
export const PAY_NOW_API_ERROR = "PAY_NOW_API_ERROR"

//banner popup
export const BANNER_REDIRECTION_DEVELOPER = "BANNER_REDIRECTION_DEVELOPER"
export const BANNER_REDIRECTION_DEVELOPER_SUCCESS = "BANNER_REDIRECTION_DEVELOPER_SUCCESS"
export const BANNER_REDIRECTION_DEVELOPER_API_ERROR = "BANNER_REDIRECTION_DEVELOPER_API_ERROR"

//akhayatritiya popup
export const BANNER_REDIRECTION_AKHAYATRITIYA = "BANNER_REDIRECTION_AKHAYATRITIYA"
export const BANNER_REDIRECTION_AKHAYATRITIYA_SUCCESS = "BANNER_REDIRECTION_AKHAYATRITIYA_SUCCESS"
export const BANNER_REDIRECTION_AKHAYATRITIYA_API_ERROR = "BANNER_REDIRECTION_AKHAYATRITIYA_API_ERROR"