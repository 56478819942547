import { DASHBOARD_LEFT_PANEL_DATA, DASHBOARD_LEFT_PANEL_DATA_API_ERROR, DASHBOARD_LEFT_PANEL_DATA_SUCCESS, DASHBOARD_MATCHES_LIST, DASHBOARD_MATCHES_LIST_API_ERROR, DASHBOARD_MATCHES_LIST_SUCCESS, DASHBOARD_PROFILE_DATA, DASHBOARD_PROFILE_DATA_API_ERROR, DASHBOARD_PROFILE_DATA_SUCCESS } from "./ActionTypes";


//matches list api call
  export const dashboardMatcheslist = (values) => {
    return {
      type: DASHBOARD_MATCHES_LIST,
      payload: {values}
    };
  };

  export const dashboardMatcheslistSuccess = data => {
    console.log(data,"datadatadata");
    return {
      type: DASHBOARD_MATCHES_LIST_SUCCESS,
      payload: data,
    };
  };
  
  export const dashboardMatcheslistApiError = error => {
    return {
      type: DASHBOARD_MATCHES_LIST_API_ERROR,
      payload: error,
    };
  };

  //left panel api call
  export const dashboardLeftPanelData = (values) => {
    return {
      type: DASHBOARD_LEFT_PANEL_DATA,
      payload: {values}
    };
  };

  export const dashboardLeftPanelDataSuccess = data => {
    return {
      type: DASHBOARD_LEFT_PANEL_DATA_SUCCESS,
      payload: data,
    };
  };
  
  export const dashboardLeftPanelDataApiError = error => {
    return {
      type: DASHBOARD_LEFT_PANEL_DATA_API_ERROR,
      payload: error,
    };
  };

  //profile data api call
  export const dashboardProfileData = (values,logout) => {
    return {
      type: DASHBOARD_PROFILE_DATA,
      payload: {values,logout}
    };
  };

  export const dashboardProfileDataSuccess = data => {
    return {
      type: DASHBOARD_PROFILE_DATA_SUCCESS,
      payload: data,
    };
  };
  
  export const dashboardProfileDataApiError = error => {
    return {
      type: DASHBOARD_PROFILE_DATA_API_ERROR,
      payload: error,
    };
  };


  