import React from 'react'
import './Skeleton.css'

//skeleton loader
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CardSkeletonWithSlider = ({cards,menu}) => {
  return (
    <div className='flex -z-50 mt-2 overflow-hidden'>
        {Array(cards)
            .fill(0)
            .map((item,i) => (
                <div className="bg-white  mr-2 flex">
                    <div className=" w-[15rem]  md:w-[15rem]  lg:w-[16.4rem]">
                        <div className=" p-2 bg-[#E9E9E9] rounded-[10px]">
                            <div className="contents relative h-[12rem] md:h-[12rem] lg:h-[13rem]  w-[14rem]  md:w-[14rem]  lg:w-[15.4rem]">
                                <div className="h-[12rem] ">
                                    <Skeleton className='h-[11rem]'/>
                                </div>          
                                <div className="px-2 pb-2 ">
                                    <div>
                                        <p className="">
                                            <Skeleton className='mb-[0.3rem] card-skeleton-id'/>
                                        </p>  
                                        <p className="">
                                            <Skeleton className='mb-[0.3rem] card-skeleton-name'/>
                                        </p>
                                        <p className="">
                                            <Skeleton className='mb-[0.3rem] card-skeleton-age'/>
                                        </p>
                                        <p className="">
                                            <Skeleton className='mb-[0.3rem] card-skeleton-description'/>
                                        </p>
                        
                                        <p className="">    
                                            <Skeleton className='mb-[0.3rem] card-skeleton-name'/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
    </div>
  )
};

const CardSkeletonWithSliderProfile = ({cards,menu}) => {
    return (
      <div className='flex -z-50 mt-2 overflow-hidden'>
          {Array(cards)
              .fill(0)
              .map((item,i) => (
                <div className="bg-white mx-2">
                <div className=" w-[21rem]  md:w-[24rem]  lg:w-[26rem]">
                  <div className="flex ">
                    <div
                      className=""
                    >
                      <div className="flex ">
                        <span className="relative inline-block">
                          <div className="flex relative">
                            <div className="w-[18rem] lg:w-[20rem] md:w-[18rem]">
                            <Skeleton className='h-[16rem] rounded-none'/>
                            </div>
                          </div>
                        </span>
          
                         <div className="mt-[4px] bg-[#E9E9E9] items-center lg:p-[0.7rem] md:p-1 rounded-tr-[10px]  grid grid-rows-5 grid-flow-col w-[5.5rem]">
                         {/* <div
                           className="py-1 md:mt-1 lg:mt-0 md:py-1 lg:py-2 flex flex-col items-center cursor-pointer"
                         >
                            <Skeleton className='mb-[0.3rem] card-skeleton-id'/>
                         </div>
                         <div
                           className="py-1 md:py-1 lg:py-2  flex flex-col items-center cursor-pointer"
                         >
                            <Skeleton className='mb-[0.3rem] card-skeleton-id'/>
                         </div>
                         <div
                           className="flex py-1 md:py-1 lg:py-2  flex-col items-center cursor-pointer"
                         >
                            <Skeleton className='mb-[0.3rem] card-skeleton-id'/>
                         </div> */}
                         <div
                           className="flex py-1 md:py-1 lg:py-2  flex-col items-center cursor-pointer"
                         >
                                                                      <Skeleton className='mb-[0.3rem] card-skeleton-id'/>
                         </div>
          
                           <div
                             className="flex py-1 md:py-1 lg:py-2  flex-col items-center cursor-pointer"
                           >
                                                                         <Skeleton className='mb-[0.3rem] card-skeleton-id'/>

                           </div>
          
                       </div>
          
                       
                      </div>
                                        <p className="">
                                            <Skeleton className='mb-[0.3rem] card-skeleton-name w-[15rem] px-[2rem]'/>
                                        </p>
                                        <p className="">
                                            <Skeleton className='mb-[0.3rem] card-skeleton-age w-[15rem] px-[2rem]'/>
                                        </p>
                                        <p className="">
                                            <Skeleton className='mb-[0.3rem] card-skeleton-description w-[23rem] px-[2rem]'/>
                                        </p>
                                        <br/>
                    </div>
                  </div>
                </div>
              </div>
              ))
          }
      </div>
    )
  };

export {
    CardSkeletonWithSlider,
    CardSkeletonWithSliderProfile
}
