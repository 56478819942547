//get match data from apis
export const GET_MATCHES = "GET_MATCHES"
export const GET_MATCHES_SUCCESS = "GET_MATCHES_SUCCESS"
export const GET_MATCHES_FAIL = "GET_MATCHES_FAIL"

//get inital  match based on recommendation
export const GET_RECOMMENDED_MATCHES = "GET_RECOMMENDED_MATCHES"
export const GET_RECOMMENDED_SUCCESS = "GET_RECOMMENDED_SUCCESS"
export const GET_RECOMMENDED_FAIL = "GET_RECOMMENDED_FAIL"


//get selected matches
export const GET_SELECTED_MATCHES = "GET_SELECTED_MATCHES"
export const GET_SELECTED_MATCHES_SUCCESS = "GET_SELECTED_MATCHES_SUCCESS"
export const GET_SELECTED_MATCHES_FAIL = "GET_SELECTED_MATCHES_FAIL"


//get filter matches
export const GET_FILTER_MATCHES = "GET_FILTER_MATCHES";
export const GET_FILTER_MATCHES_SUCCESS = "GET_FILTER_MATCHES_SUCCESS";
export const GET_FILTER_MATCHES_FAIL = "GET_FILTER_MATCHES_FAIL";

export const APPLY_FILTER = "APPLY_FILTER"
export const REMOVE_FILTER = "REMOVE_FILTER"


//basic and advance search
export const SEARCH_MATCHES = "SEARCH_MATCHES"
export const SEARCH_MATCHES_SUCCESS = "SEARCH_MATCHES_SUCCESS"
export const SEARCH_MATCHES_FAIL = "SEARCH_MATCHES_FAIL"

export const CLEAR_SEARCH = "CLEAR_SEARCH"

//search profile by id
export const VIEW_PROFILE_BY_ID = "VIEW_PROFILE_BY_ID"
export const VIEW_PROFILE_BY_ID_SUCCESS = "VIEW_PROFILE_BY_ID_SUCCESS"
export const VIEW_PROFILE_BY_ID_FAIL = "VIEW_PROFILE_BY_ID_FAIL"

export const SEARCH_MATCHES_BY_TEXT = "SEARCH_MATCHES_BY_TEXT"
export const SEARCH_MATCHES_BY_TEXT_SUCCESS = "SEARCH_MATCHES_BY_TEXT_SUCCESS"
export const SEARCH_MATCHES_BY_TEXT_FAIL = "SEARCH_MATCHES_BY_TEXT_FAIL"


//when user select match card
export const SET_CURRENT_MATCH = "SET_CURRENT_MATCH"
export const NEXT_MATCH = "NEXT_MATCH"
export const PREVIOUS_MATCH = "PREVIOUS_MATCH"
export const CLOSE_CURRENT_MATCH = "CLOSE_CURRENT_MATCH"


//profile data
export const GET_MATCH_PROFILE = "GET_MATCH_PROFILE"
export const GET_MATCH_PROFILE_SUCCESS = "GET_MATCH_PROFILE_SUCCESS"
export const GET_MATCH_PROFILE_FAIL = "GET_MATCH_PROFILE_FAIL"
//prefrence
export const GET_MATCH_PROFILE_PREF = "GET_MATCH_PROFILE_PREF"
export const GET_MATCH_PROFILE_PREF_SUCCESS = "GET_MATCH_PROFILE_PREF_SUCCESS"
export const GET_MATCH_PROFILE_PREF_FAIL = "GET_MATCH_PROFILE__PREF_FAIL"


//get profile data
export const GET_PROFILE = "GET_PROFILE"


//update profile details like requests photo, horoscope etc,

export const MATCH_REQUEST = "MATCH_REQUEST"

//profiles update
export const TOGGLE_PROFILE_SELECTED = "TOGGLE_PROFILE_SELECTED"
export const TOGGLE_PROFILE_SELECTED_SUCCESS    = "TOGGLE_PROFILE_SELECTED_SUCCESS"
export const TOGGLE_PROFILE_SELECTED_FAIL = "TOGGLE_PROFILE_SELECTED_FAIL"
export const RESET_PROFILE_SELECTED_STATUS = "RESET_PROFILE_SELECTED_STATUS"


export const TOGGLE_PROFILE_REJECTED = "TOGGLE_PROFILE_REJECTED"

export const TOGGLE_PROFILE_BLOCK = "TOGGLE_PROFILE_BLOCK"

export const VIEW_PHONE = "VIEW_PHONE"
export const VIEW_PHONE_SUCCESS = "VIEW_PHONE_SUCCESS"
export const VIEW_PHONE_FAIL = "VIEW_PHONE_FAIL"

// Request field
export const STORE_REQUEST_TYPE = "STORE_REQUEST_TYPE"
export const STORE_REQUEST_TYPE_SUCCESS    = "STORE_REQUEST_TYPE_SUCCESS"
export const STORE_REQUEST_TYPE_FAIL = "STORE_REQUEST_TYPE_FAIL"

export const NOTIFICATION_CLICKED_TRACKING = "NOTIFICATION_CLICKED_TRACKING"
