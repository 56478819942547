export const getConversations = (state) => state.Chats?.conversations[`${state.Chats?.currentChat?.conversationId}`];

export const getTotalUnreadCount = state => {
    const groupUnreadCount = state?.Chats?.groupChatList[0]?.unread_message_count ?? 0
    const customerUnreadCount = state.Chats?.unReadCustomerMsgCount?.unread_message_count ?? 0
    return state.Chats?.unreadProfileCount + (groupUnreadCount > 0 ? 1 : 0) + (customerUnreadCount > 0 ? 1 : 0)
};


export const getBulkRequest = (state) => {
    return {
        bulkRequestFetching: state?.Chats?.bulkRequest?.isFetching,
        bulkRequestData: state?.Chats?.bulkRequest?.data,
        isBulkRequestLoadMore: state?.Chats?.bulkRequest?.isLoadmoreFetching,
        bulkRequestPagination: state?.Chats?.bulkRequest?.pagination,
        hasMoreBulkRequest: state?.Chats?.bulkRequest?.hasMoreData,
        bulkRequestBanner: state?.Chats?.bulkRequest?.banner,
    }
}