import { COMMUNITY_DETAIL } from "../../Helpers/EndPoints";
import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    API_ERROR,
    GET_BASIC_DATA,
    GET_BASIC_DATA_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_API_ERROR,
    SEND_OTP,
    SEND_OTP_SUCCESS,
    SEND_OTP_ERROR,
    OTP_VALIDATION,
    OTP_VALIDATION_SUCCESS,
    OTP_VALIDATION_ERROR,
    MOBILE_VERIFICATION,
    MOBILE_VERIFICATION_SUCCESS,
    MOBILE_VERIFICATION_ERROR,
    REQUEST_AGAIN,
    REQUEST_AGAIN_SUCCESS,
    REQUEST_AGAIN_ERROR,
    MOBILE_VERIFICATION_FRESH,
    MOBILE_VERIFICATION_FRESH_SUCCESS,
    MOBILE_VERIFICATION_FRESH_ERROR,
    CHANGE_MOBILE_NO,
    CHANGE_MOBILE_NO_SUCCESS,
    CHANGE_MOBILE_NO_ERROR,
    MORE_LINK_CONTENT,
    MORE_LINK_CONTENT_SUCCESS,
    MORE_LINK_CONTENT_API_ERROR,
    LOGIN_FROM_ADMIN_USER,
    LOGIN_FROM_ADMIN_SUCCESS,
    LOGIN_FROM_ADMIN_API_ERROR,
    LOGIN_FROM_SMS_USER,
    LOGIN_FROM_SMS_SUCCESS,
    LOGIN_FROM_SMS_API_ERROR,
    COMMUNITY_SITE_DETAIL_SUCCESS,
    COMMUNITY_SITE_DETAIL_API_ERROR,
    LOGIN_FROM_EMAIL_SUCCESS,
    LOGIN_FROM_EMAIL_API_ERROR,
    LOGIN_FOR_AKHAYATRITIYA,
    LOGIN_FOR_AKHAYATRITIYA_SUCCESS,
    LOGIN_FOR_AKHAYATRITIYA_API_ERROR,
    LOGIN_FROM_ADMIN_PARTIAL_USER,
    LOGIN_FROM_ADMIN_PARTIAL_SUCCESS,
    LOGIN_FROM_ADMIN_PARTIAL_API_ERROR,
    SITE_MAP,
    SITE_MAP_SUCCESS,
    SITE_MAP_API_ERROR,
  } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const login = (state = initialState, action) => {
    //console.log(action.type,"12345");
    switch (action.type) {
      case LOGIN_USER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case LOGIN_SUCCESS:
        state = {
          ...state,
          adminLoginData:action.payload.data,
          loading: false,
        };
        break;
      case API_ERROR:
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
        };
        break;

        case LOGIN_FROM_ADMIN_USER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case LOGIN_FROM_ADMIN_SUCCESS:
        state = {
          ...state,
          adminLoginData:action.payload.data,
          loading: false,
        };
        break;
      case LOGIN_FROM_ADMIN_API_ERROR:
        state = {
          ...state,
          adminLoginError: action.payload.data,
          loading: false,
        };
        break;

        case LOGIN_FROM_ADMIN_PARTIAL_USER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case LOGIN_FROM_ADMIN_PARTIAL_SUCCESS:
        state = {
          ...state,
          adminLoginData:action.payload.data,
          loading: false,
        };
        break;
      case LOGIN_FROM_ADMIN_PARTIAL_API_ERROR:
        state = {
          ...state,
          adminLoginError: action.payload.data,
          loading: false,
        };
        break;

        case LOGIN_FROM_EMAIL_SUCCESS:
        state = {
          ...state,
          loading: true,
        };
        break;
      case LOGIN_FROM_EMAIL_SUCCESS:
        state = {
          ...state,
          adminLoginData:action.payload.data,
          loading: false,
        };
        break;
      case LOGIN_FROM_EMAIL_API_ERROR:
        state = {
          ...state,
          adminLoginError: action.payload.data,
          loading: false,
        };
        break;

        case LOGIN_FROM_SMS_USER:
          state = {
            ...state,
            loading: true,
          };
          break;
        case LOGIN_FROM_SMS_SUCCESS:
          state = {
            ...state,
            adminLoginData:action.payload.data,
            loading: false,
          };
          break;
        case LOGIN_FROM_SMS_API_ERROR:
          state = {
            ...state,
            adminLoginError: action.payload.data,
            loading: false,
          };
          break;

          case LOGIN_FOR_AKHAYATRITIYA:
          state = {
            ...state,
            loading: true,
          };
          break;
        case LOGIN_FOR_AKHAYATRITIYA_SUCCESS:
          state = {
            ...state,
            adminLoginData:action.payload.data,
            loading: false,
          };
          break;
        case LOGIN_FOR_AKHAYATRITIYA_API_ERROR:
          state = {
            ...state,
            adminLoginError: action.payload.data,
            loading: false,
          };
          break;

        case CHANGE_MOBILE_NO:
        state = {
          ...state,
          loading: true,
        };
        break;
      case CHANGE_MOBILE_NO_SUCCESS:
        state = {
          ...state,
          Changeddata:action.payload.data,
          loading: false,
        };
        break;
      case CHANGE_MOBILE_NO_ERROR:
        state = {
          ...state,
          data: action.payload.data,
          loading: false,
        };
        break;

        case SEND_OTP:
        state = {
          ...state,
          loading: true,
        };
        break;
      case SEND_OTP_SUCCESS:
        state = {
          ...state,
          data:action.payload.data,
          loading: false,
        };
        break;
      case SEND_OTP_ERROR:
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
        };
        break;
        case OTP_VALIDATION:
        state = {
          ...state,
          loading: true,
        };
        break;
      case OTP_VALIDATION_SUCCESS:
        //console.log(action.payload.data,"action.payload.data");
        state = {
          ...state,
          otpdata:action.payload.data,
          loading: false,
        };
        break;
      case OTP_VALIDATION_ERROR:
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
        };
        break;
        case MOBILE_VERIFICATION:
          state = {
            ...state,
            loading: true,
          };
          break;
        case MOBILE_VERIFICATION_SUCCESS:
          state = {
            ...state,
            mvdata:action.payload.data,
            loading: false,
          };
          break;
        case MOBILE_VERIFICATION_ERROR:
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
          };
          break;
          case MOBILE_VERIFICATION_FRESH:
          state = {
            ...state,
            loading: true,
          };
          break;
        case MOBILE_VERIFICATION_FRESH_SUCCESS:
          state = {
            ...state,
            mvfdata:action.payload.data,
            loading: false,
          };
          break;
        case MOBILE_VERIFICATION_FRESH_ERROR:
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
          };
          break;
          case REQUEST_AGAIN:
          state = {
            ...state,
            loading: true,
          };
          break;
        case REQUEST_AGAIN_SUCCESS:
          state = {
            ...state,
            RequestAgaindata:action.payload.data,
            loading: false,
          };
          break;
        case REQUEST_AGAIN_ERROR:
          state = {
            ...state,
            error: action.payload.data,
            loading: false,
          };
          break;
        case GET_BASIC_DATA:
        state = {
          ...state,
          loading: true,
        };
        break;
        case GET_BASIC_DATA_SUCCESS:
          state = {
            ...state,
            basicData:action.payload.data,
            loading: false,
          };
          break;
          case RESET_PASSWORD:
          state = {
            ...state,
            loading: true,
          };
          break;
        case RESET_PASSWORD_SUCCESS:
          //console.log( action.payload.data,"123456");
          state = {
            ...state,
            resetPasswordData:action.payload.data,
            loading: false,
          };
          break;
        case RESET_PASSWORD_API_ERROR:
          //////console.log( action.payload.data,"123456");
          state = {
            ...state,
            resetPasswordError: action.payload.data,
            loading: false,
          };
          break;
        case MORE_LINK_CONTENT:
          state = {
            ...state,
            loading: true,
          };
          break;
        case MORE_LINK_CONTENT_SUCCESS:
          state = {
            ...state,
            moreLinkContent:action.payload.data,
            loading: false,
          };
          break;
        case MORE_LINK_CONTENT_API_ERROR:
          //////console.log( action.payload.data,"123456");
          state = {
            ...state,
            resetPasswordError: action.payload.data,
            loading: false,
          };
          break;
          case COMMUNITY_DETAIL:
          state = {
            ...state,
            loading: true,
          };
          break;
        case COMMUNITY_SITE_DETAIL_SUCCESS:
          state = {
            ...state,
            communitySiteContent:action.payload.data,
            loading: false,
          };
          break;
        case COMMUNITY_SITE_DETAIL_API_ERROR:
          state = {
            ...state,
            resetPasswordError: action.payload.data,
            loading: false,
          };
          break;
          case SITE_MAP:
          state = {
            ...state,
            loading: true,
          };
          break;
        case SITE_MAP_SUCCESS:
          state = {
            ...state,
            siteMapContent:action.payload.data,
            loading: false,
          };
          break;
        case SITE_MAP_API_ERROR:
          state = {
            ...state,
            resetPasswordError: action.payload.data,
            loading: false,
          };
          break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default login;
  