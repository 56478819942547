import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./ActionTypes"
import { Offline_Profile,Chats_List, Conversation_Data, Delete_Message, Search_Chats_List, Send_Message, Conversation_Status, Get_Media_Files, Set_Chat_Mute, View_Document, Star_Message, Get_Bulk_Request, Family_Chats_Detail, Group_Conversation_Data, Send_Group_Message, Group_Chats_List, Family_Chats_Reactions_Add, Family_Chats_STAR_MESSAGE, Delete_Group_Message, Read_Group_Message, CUSTOMER_SERVICE_CONVERSATION, CustomerService_Conversation_Data, Send_Customer_Service_Message, Unread_Msg_Count, SET_Family_code, Kmcare_Create_Conversation, Ranking_Popup, UnreadMsg_Count, Profile_Send_Message } from "../../Helpers/api_helpers";

import { getChatListFail, getChatListSuccess, getConversationFail, getConversationSuccess, getMoreConversationFail, getMoreConversationSuccess, searchChatListFail, searchChatListSuccess, sendMessageFail, sendMessageSuccess, conversationStatusSuccess, conversationStatusFail, getChatMediaSuccess, getChatMediaFail, starMessageSuccess, starMessageFail, getBulkRequestSuccess, getBulkRequestFail, getFamilyChatDetailSuccess, 
    getFamilyChatDetailFail, sendGroupMessageSuccess, sendGroupMessageFail, getGroupConversationSuccess, getGroupConversationFail, addReactionsSuccess, 
    addReactionsFail, getGroupChatListFail, getGroupChatListSuccess, 
    getMoreGroupConversationSuccess, getMoreGroupConversationFail, 
    groupStarMessageSuccess, groupStarMessageFail,offlineprofileSuccess,
    getCustomerServiceConversation,getCustomerServiceConversationSuccess,getCustomerServiceConversationFail,
    getMoreCustomerServiceConversation,getMoreCustomerServiceConversationSuccess,getMoreCustomerServiceConversationFail, sendCustomerServiceMessageSuccess, sendCustomerServiceMessageFail, UnreadMsgCountSuccess, UnreadMsgCountFail, setFamilyCodeSuccess, setFamilyCodeFail, RankingPopupSuccess, RankingPopupFail, ResetUnReadCountFail, ResetUnReadCountSuccess, profileSendMessageSuccess, profileSendMessageFail, getChatList,
    } from "./Action";


// import { getChatListFail, getChatListSuccess, getConversationFail, getConversationSuccess, getMoreConversationFail,
//      getMoreConversationSuccess, searchChatListFail, searchChatListSuccess,
//       sendMessageFail, sendMessageSuccess, conversationStatusSuccess, 
//       conversationStatusFail, getChatMediaSuccess, getChatMediaFail, 
//       starMessageSuccess, starMessageFail, getBulkRequestSuccess, getBulkRequestFail ,
//     offlineprofileSuccess} from "./Action";
import Constants from "../../Constants/constants";
import * as selectors from "./selectors"
import { message } from "antd";
import { closeCurrentMatch } from "../KalyanLite/Action";
import { getGroupAuthId ,getCustomerServiceAuthId} from "../../Components/Kalyanlite/utils";
import { FAMILY_STAR_MESSAGE } from "../../Helpers/EndPoints";

function* getConversationList({ payload }) {

    try {
        const axiosConfig = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                ...payload,
                page_size: Constants.maxConversation,
                page: 1,
                is_starred: payload?.is_starred,
                receiver_status:payload?.receiver_status,
                user_id: localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
                notificationId: payload.notificationId
            }
          };
        const response = yield call(Conversation_Data, axiosConfig);
        if (response.status == 200) {
            yield put(getConversationSuccess({ ...response.data, is_starred: payload?.is_starred }));
        } else {
            yield put(getConversationFail(response));
        }
    } catch (error) {
        yield put(getConversationFail(error));
    }
}


function* getMoreConversationList({ payload }) {

    try {
        const paginationData = yield select(selectors.getConversations)
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                ...payload,
                page_size: Constants.maxConversation,
                page: paginationData?.conversationPagination?.page,
                is_starred: payload.is_starred,
                user_id: localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
            }
        };
        const response = yield call(Conversation_Data, axiosConfig);
        if (response.status == 200) {
            console.log("response.data", response.data);
            yield put(getMoreConversationSuccess(response.data));
        } else {
            yield put(getMoreConversationFail(response));
        }
    } catch (error) {
        yield put(getMoreConversationFail(error));
    }
}

function* getGroupConversationList({ payload }) {

    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "profile_id": localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
                "auth_id": getGroupAuthId(),
                "get_starred_message": payload?.is_starred,
                "page": 1,
                "page_size": Constants.maxConversation
             
            }
        };
        const response = yield call(Group_Conversation_Data, axiosConfig);
        if (response.status == 200) {
            yield put(getGroupConversationSuccess({ ...response.data, is_starred: payload?.is_starred }));
        } else {
            yield put(getGroupConversationFail(response));
        }
    } catch (error) {
        yield put(getConversationFail(error));
    }
}


function* getMoreGroupConversationList({ payload }) {

    try {
        const paginationData = yield select(selectors.getConversations)
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "profile_id": localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
                "auth_id": getGroupAuthId(),
                "get_starred_message": payload?.is_starred,
                "page": paginationData?.conversationPagination?.page,
                "page_size": Constants.maxConversation
            }
        };
        const response = yield call(Group_Conversation_Data, axiosConfig);
        if (response.status == 200) {
            console.log("response.data", response.data);
            yield put(getMoreGroupConversationSuccess(response.data));
        } else {
            yield put(getMoreGroupConversationFail(response));
        }
    } catch (error) {
        yield put(getMoreGroupConversationFail(error));
    }
}



function* sendMessage({ payload }) {
    try {
        console.log("sendMessage", payload);
        const formData = new FormData()

        formData.append('receiver_id', payload.receiver_id)
        formData.append('conversation_id', payload.conversation_id)
        formData.append('loginFrom', localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice))
        
        if (payload.message_type_id != 3) {
            formData.append('file', payload.file)
        } else if (payload.message_type_id == 2) {
            formData.append('file', payload.file, payload.audioName)
        } else {
            payload.file?.map(f => {
                formData.append('file[]', f)
            })
        }
        formData.append('message_type_id', payload.message_type_id)
        if (payload.parent_message_id) {
            formData.append('parent_message_id', payload.parent_message_id)
        }
        formData.append("message_read_status", "false")

        if (payload.notificationId) {
            formData.append("notificationId", payload.notificationId)
        }

        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
                "Content-Type": 'multipart/form-data'
            },
            data: formData
        };
        const response = yield call(Send_Message, axiosConfig);
        if (response.status == 200) {
            yield put(sendMessageSuccess(response));
        } else {
            yield put(sendMessageFail(response));
        }
    } catch (error) {
        console.log("sendMessage", error, payload);
        yield put(sendMessageFail(error));
    }
}

// 
function* profileSendMessage({ payload }) {
    try {
        console.log("profilesendMessage", payload);
        const formData = new FormData()

        formData.append('receiver_id', payload.receiver_id)
        formData.append('sender_id', payload.sender_id)
        formData.append('loginFrom', localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice))
        formData.append('message_type_id', payload.message_type_id)

        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
                "Content-Type": 'multipart/form-data'
            },
            data: formData
        };
        const response = yield call(Profile_Send_Message, axiosConfig);
        if (response.status == 200) {
            if(payload?.isFrom == "viewprofile"){
                yield call(getChatsList, { 
                    payload: {
                        page_size: Constants.matchRowCount,
                        page: 1
                    }
                });
            }
            yield put(profileSendMessageSuccess(response));
        } else {
            yield put(profileSendMessageFail(response));
        }
    } catch (error) {
        console.log("profilesendMessage", error, payload);
        yield put(profileSendMessageFail(error));
    }
}

function* sendGroupMessage({ payload }) {
    try {
        console.log("sendMessage", payload);
        const formData = new FormData()

        formData.append('sender_id', getGroupAuthId())
        formData.append('group_id', localStorage.getItem(Constants.loginLocalStorageKeys.profileId))
        formData.append('conversation_id', payload.conversation_id)
        if(payload.shared_profile_id){
            formData.append('shared_profile_id',payload.shared_profile_id)        
        }
        if (payload.message_type_id != 3) {
            formData.append('file', payload.file)
        } else if (payload.message_type_id == 2) {
            formData.append('file', payload.file, payload.audioName)
        } else {
            payload.file?.map(f => {
                formData.append('file[]', f)
            })
        }
        formData.append('message_type_id', payload.message_type_id)
        if (payload.parent_message_id) {
            formData.append('parent_message_id', payload.parent_message_id)
        }
        formData.append("message_read_status", "false")
        formData.append("is_from_selected", payload?.is_from_selected)


        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
                "Content-Type": 'multipart/form-data'
            },
            data: formData
        };
        const response = yield call(Send_Group_Message, axiosConfig);
        if (response.status == 200) {
            yield put(sendGroupMessageSuccess(response));
        } else {
            yield put(sendGroupMessageFail(response));
        }
    } catch (error) {
        console.log("sendMessage", error, payload);
        yield put(sendGroupMessageFail(error));
    }
}

function* getChatsList({ payload }) {
    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "auth_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
                ...payload
            }
        };
        const response = yield call(Chats_List, axiosConfig);
        console.log(response.data.list_data, "chatListData");
        if (response.data) {
            yield put(getChatListSuccess(response.data));
        } else {
            yield put(getChatListFail(response));
        }
    } catch (error) {
        yield put(getChatListFail(error));
    }
}


function* getGroupChatsList({ payload }) {
    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "profile_id": payload?.profileId,
                "auth_id":getGroupAuthId()
            }
        };
        const response = yield call(Group_Chats_List, axiosConfig);
        console.log(response.data.list_data, "chatListData");
        if (response.data) {
            yield put(getGroupChatListSuccess(response.data));
        } else {
            yield put(getGroupChatListFail(response));
        }
    } catch (error) {
        yield put(getGroupChatListFail(error));
    }
}


function* searchChatsList({ payload }) {
    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "auth_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
                ...payload
            }
        };
        const response = yield call(Search_Chats_List, axiosConfig);
        if (response.data) {
            yield put(searchChatListSuccess(response.data));
        } else {
            yield put(searchChatListFail(response));
        }
    } catch (error) {
        yield put(searchChatListFail(error));
    }
}

function* removeMessage({ payload }) {
    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "message_id": [payload.message.id],
                "deleteForEveryone": payload.deleteForEveryone
            }

        };
        const response = yield call(Delete_Message, axiosConfig);
        // if (response.data) {
        //     yield put(searchChatListSuccess(response.data));
        // } else {
        //     yield put(searchChatListFail(response));
        // }
    } catch (error) {
        // yield put(searchChatListFail(error));
    }
}

function* getConversationStatus({ payload }) {
  try {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          Constants.loginLocalStorageKeys.chatLogin
        )}`,
      },
      data: {
        ...payload,
      },
    };
    const response = yield call(Conversation_Status, axiosConfig);
    console.log(response.data, "conversationStatus");
    if (response.data) {
      yield put(conversationStatusSuccess(response.data));
    } else {
      yield put(conversationStatusFail(response));
    }
  } catch (error) {
    yield put(conversationStatusFail(error));
  }
}

/* The `setCurrentChatEffect` function is a generator function that is used as a saga effect in the
Redux-Saga middleware. When it is called, it dispatches an action `closeCurrentMatch` with the
payload passed to it. The `put` function is a Redux-Saga effect that dispatches an action to the
Redux store. In this case, it dispatches the `closeCurrentMatch` action with the payload, which will
trigger the corresponding reducer to update the state in the Redux store. */
function* setCurrentChatEffect({ payload }) {
    yield put(closeCurrentMatch(payload))
}


function* getChatMedia({ payload }) {

    try {

        let request = { ...payload }

        request.page = 1;
        request.page_size = 20;

        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    Constants.loginLocalStorageKeys.chatLogin
                )}`,
            },
            data: {
                ...request,
            },
        };
        const response = yield call(Get_Media_Files, axiosConfig);
        if (response.data) {
            yield put(getChatMediaSuccess({ ...response.data, type_id: payload.type_id }));
        } else {
            yield put(getChatMediaSuccess(response));
        }
    } catch (error) {
        yield put(getChatMediaFail(error));
    }
}

function* toggleMuteConversation({ payload }) {

    try {

        let request = { ...payload }


        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    Constants.loginLocalStorageKeys.chatLogin
                )}`,
            },
            data: {
                ...request,
            },
        };
        const response = yield call(Set_Chat_Mute, axiosConfig);
        if (response.data) {

        } else {

        }
    } catch (error) {

    }
}

function* logDocumentView({ payload }) {
    try {

        let request = {
            email: localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
            userId: localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
            "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            "toProfileId": payload.toProfileId,
        }

        yield call(View_Document, request);
    } catch (error) {

    }
}

// function* starMessage({ payload }) {
//     try {

//         let request = {
//             "message_id": payload.id
//         }

//         const axiosConfig = {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem(
//                     Constants.loginLocalStorageKeys.chatLogin
//                 )}`,
//             },
//             data: {
//                 ...request,
//             },
//         };

//         yield call(Star_Message, axiosConfig);
//     } catch (error) {

//     }
// }
function* starMessage({ payload }) {
    try {
        let request = {
            "message_id": payload.id,
            "receiver_id": payload.receiver_id,
            "conversation_id":payload.conversation_id 
        }


        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                ...request,
            },
        };

        const response = yield call(Star_Message, axiosConfig);
        console.log(response,"mmkkk");
        if (response.data) {
            yield put(starMessageSuccess(response.data));
        } else {
            yield put(starMessageFail(response));
        }
      
    } catch (error) {
    }
}

function* offlineprofile({ payload }) {
    try {
        let request = {
          view_profile_id:payload?.profile_id,
          user_id: localStorage?.getItem(Constants.loginLocalStorageKeys.loginId)
        }


        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                ...request,
            },
        };

        const response = yield call(Offline_Profile, axiosConfig);
        console.log(response,"mmkkk");
        if (response.data) {
            yield put(offlineprofileSuccess(response.data));
        } 
      
    } catch (error) {
    }
}

function* resetUnreadCount({ payload }) {

    if (!payload.isChatOpened) return;

    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                receiver_id: payload.receiverId,
                page_size: 1,
                page: 1,
                is_starred: false,
                receiver_status: payload.receiver_status
            }
        };
        const response = yield call(Conversation_Data, axiosConfig);

    } catch (error) {

    }
}

function* resetMsgUnreadCount({ payload }) {

    if (!payload.isChatOpened) return;

    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "profile_id": localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
                "auth_id": getGroupAuthId(),
                "page": 1,
                "page_size": 1

            }
        };
        const response = yield call(Group_Conversation_Data, axiosConfig);

    } catch (error) {

    }
}

// function* resetUnreadMsgCount({ payload }) {

//     if (!payload.isChatOpened) return;

//     try {
//         const axiosConfig = {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
//             },
//             data: {
//                 "sender_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
//                 "page": 1,
//                 "page_size": 1

//             }
//         };
//         const response = yield call(CustomerService_Conversation_Data, axiosConfig);

//     } catch (error) {

//     }
// }

function* resetUnreadMsgCount({ payload }) {
    try {

        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "conversation_id": localStorage.getItem(Constants.loginLocalStorageKeys.conversationId),
                "sender_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
            }

        };
        const response = yield call(UnreadMsg_Count, axiosConfig);
        if (response.data) {
            
            yield put(ResetUnReadCountSuccess(response.data));
        } else {
            yield put(ResetUnReadCountFail(response));
        }
    } catch (error) {
        // yield put(searchChatListFail(error));
    }
}

function* getBulkRequests({ payload }) {
    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "auth_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
                ...payload
            }
        };
        const response = yield call(Get_Bulk_Request, axiosConfig);
        console.log(response.data.list_data, "chatListData");
        if (response.data) {
            yield put(getBulkRequestSuccess(response.data));
        } else {
            yield put(getBulkRequestFail(response));
        }
    } catch (error) {
        yield put(getBulkRequestFail(error));
    }
}

function* getFamilyChatsDetail({ payload }) {
    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "auth_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
                ...payload
            }
        };
        const response = yield call(Family_Chats_Detail, axiosConfig);
        console.log(response, "family chats details");
        if (response) {
            yield put(getFamilyChatDetailSuccess(response?.data));
        } else {
            yield put(getFamilyChatDetailFail(response?.data));
        }
    } catch (error) {
        yield put(getFamilyChatDetailFail(error));
    }
}

function* addGroupChatReactions({ payload }) {
    const authId = localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
    const familyLoginId = localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginId)
    const profile_id = localStorage.getItem(Constants.loginLocalStorageKeys.profileId);

    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "auth_id": familyLoginId ?? authId ,
                "profile_id" : profile_id ,
                ...payload
            }
        };
        const response = yield call(Family_Chats_Reactions_Add, axiosConfig);
        console.log(response, "reactions response");
        if (response) {
            yield put(addReactionsSuccess({data:response?.data?.data,id:payload?.group_message_id,conversation_id:payload?.conversation_id}));
        } else {
            yield put(addReactionsFail(response?.data));
        }
    } catch (error) {
        yield put(addReactionsFail(error));
    }
}

// function* getFamilyChatsList({ payload }) {
//     try {
//         const axiosConfig = {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
//             },
//             data: {
//                 "auth_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
//                 ...payload
//             }
//         };
//         const response = yield call(Family_Chats_Detail, axiosConfig);
//         console.log(response, "family chats details");
//         if (response) {
//             yield put(getFamilyChatListSuccess(response?.data));
//         } else {
//             yield put(getFamilyChatListFail(response?.data));
//         }
//     } catch (error) {
//         yield put(getFamilyChatListFail(error));
//     }
// }


function* groupStarMessage({ payload }) {
    console.log(payload,'erty');
    try {
        let request = {
            "group_message_id": payload.id,
            "group_id": payload.group_id,
            "starred_by":getGroupAuthId() 
        }


        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                ...request,
            },
        };

        const response = yield call(Family_Chats_STAR_MESSAGE, axiosConfig);
        console.log(response,"mmkkk");
        if (response.data) {
            yield put(groupStarMessageSuccess(response.data));
        } else {
            yield put(groupStarMessageFail(response));
        }
      
    } catch (error) {
    }
}

function* removeGroupMessage({ payload }) {
    try {
        const authId = localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
        const familyLoginId = localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginId)
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "message_id": payload.message.id,
                "auth_id": getGroupAuthId()  ,
                "group_id":localStorage.getItem(Constants.loginLocalStorageKeys.profileId)
            }

        };
        const response = yield call(Delete_Group_Message, axiosConfig);
        // if (response.data) {
        //     yield put(searchChatListSuccess(response.data));
        // } else {
        //     yield put(searchChatListFail(response));
        // }
    } catch (error) {
        // yield put(searchChatListFail(error));
    }
}


function* readGroupMessageApi({ payload }) {
    try {

        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "group_conversation_id": payload?.conversation_id,
                "auth_id": getGroupAuthId(),
                "profile_id": localStorage.getItem(Constants.loginLocalStorageKeys.profileId)
            }

        };
        const response = yield call(Read_Group_Message, axiosConfig);
        // if (response.data) {
        //     yield put(searchChatListSuccess(response.data));
        // } else {
        //     yield put(searchChatListFail(response));
        // }
    } catch (error) {
        // yield put(searchChatListFail(error));
    }
}
function* readCustomerMessageApi({ payload }) {
    try {

        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "sender_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
                // "conversation_id": payload.conversation_id
            }

        };
        const response = yield call(Unread_Msg_Count, axiosConfig);
        if (response.data) {
            
            yield put(UnreadMsgCountSuccess(response.data));
        } else {
            yield put(UnreadMsgCountFail(response));
        }
    } catch (error) {
        // yield put(searchChatListFail(error));
    }
}
function* getCustomerServiceConversationList({ payload }) {
    const isConversationExist = yield select(state => state?.Chats?.isKmcareConversationExist);
    try {
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "sender_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
                // "auth_id": getCustomerServiceAuthId(),
                // "get_starred_message": payload?.is_starred,
                "page_size": Constants.maxConversation,
                "page": 1,
                
             
            }
        };
        if (!isConversationExist) {
            const createConversationaxiosConfig = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        Constants.loginLocalStorageKeys.chatLogin,
                    )}`,
                },
                data: {
                    "sender_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
                }
            };

            const conversationResponse = yield call(Kmcare_Create_Conversation, createConversationaxiosConfig);
        }
        const response = yield call(CustomerService_Conversation_Data, axiosConfig);
        if (response.status == 200) {
            console.log(response,"responsenn");
            yield put(getCustomerServiceConversationSuccess({ ...response.data, is_starred: payload?.is_starred }));
        } else {
            yield put(getCustomerServiceConversationFail(response));
        }
    } catch (error) {
        yield put(getConversationFail(error));
    }
}


function* getMoreCustomerServiceConversationList({ payload }) {

    try {
        const paginationData = yield select(selectors.getConversations)
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                "sender_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
                // "auth_id": getCustomerServiceAuthId(),
                // "get_starred_message": payload?.is_starred,
                "page_size": Constants.maxConversation,
                "page": paginationData?.conversationPagination?.page,
               
            }
        };
        const response = yield call(CustomerService_Conversation_Data, axiosConfig);
        if (response.status == 200) {
            console.log("response.data", response.data);
            yield put(getMoreCustomerServiceConversationSuccess(response.data));
        } else {
            yield put(getMoreCustomerServiceConversationFail(response));
        }
    } catch (error) {
        yield put(getMoreCustomerServiceConversationFail(error));
    }
}

function* sendCustomerServiceMessage({ payload }) {
    try {
        console.log("sendCustomerServiceMessage", payload);
        const formData = new FormData()

        formData.append('sender_id', getCustomerServiceAuthId())
        // formData.append('receiver_id', localStorage.getItem(Constants.loginLocalStorageKeys.profileId))
        formData.append('conversation_id', payload.conversation_id || '')
        formData.append('message_template_id', payload.message_template_id || '')
        if(payload.shared_profile_id){
            formData.append('shared_profile_id',payload.shared_profile_id)        
        }
        
        if (payload.message_type_id != 3) {
            formData.append('file', payload.file)
        } else if (payload.message_type_id == 2) {
            formData.append('file', payload.file, payload.audioName)
        }else if(payload.message_type_id == 7){
            formData.append('message_template_id', payload.message_template_id);  // Adding image as binary

        }
         else {
            payload.file?.map(f => {
                formData.append('file[]', f)
            })
        }
        formData.append('message_type_id', payload.message_type_id)
        if (payload.parent_message_id) {
            formData.append('parent_message_id', payload.parent_message_id)
        }
        // formData.append("message_read_status", "false")


        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
                "Content-Type": 'multipart/form-data'
            },
            data: formData
        };
        const response = yield call(Send_Customer_Service_Message, axiosConfig);
        if (response.status == 200) {
            const { conversation_id } = response.data;
            yield put(sendCustomerServiceMessageSuccess(response));
        } else {
            yield put(sendCustomerServiceMessageFail(response));
        }
    } catch (error) {
        console.log("sendMessage", error, payload);
        yield put(sendCustomerServiceMessageFail(error));
    }
}

// function* getUnreadMessageCount({ payload }) {
//     try {
//         const axiosConfig = {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
//             },
//             data: {
//                 "sender_id": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
//                 "conversation_id": payload.conversation_id
               
//             }
//         };
//         const response = yield call(Unread_Msg_Count, axiosConfig);
//         if (response.status === 200) {
//             yield put(UnreadMsgCountSuccess(response.data));
//         } else {
//             yield put(UnreadMsgCountFail(response));
//         }
//     } catch (error) {
//         yield put(UnreadMsgCountFail(error));
//     }
// }

function* setFamilyCode({ payload }) {

    try {
        let request = {
            "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
            "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
            "familycode" : payload 
        }


        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                ...request,
            },
        };

        const response = yield call(SET_Family_code, axiosConfig);
        console.log(response,"mmkkk");
        if (response.data) {
            yield put(setFamilyCodeSuccess(response.data));
        } else {
            yield put(setFamilyCodeFail(response));
        }
      
    } catch (error) {
    }
}
function* rankingPopup({ payload }) {

    try {
        let request = {
            "profile_id": localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
            "agent_id": payload.agent_id,
            "message_id": payload.message_id,
            "ratings": payload.ratings,
            "comments": payload.comments,
        }


        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
            },
            data: {
                ...request,
            },
        };

        const response = yield call(Ranking_Popup, axiosConfig);
        console.log(response,"rankingresponse");
        if (response.data) {
            yield put(RankingPopupSuccess(response.data));
        } else {
            yield put(RankingPopupFail(response));
        }
      
    } catch (error) {
    }
}


function* ChatsSaga() {
    yield takeEvery(actionTypes.SEND_MESSAGE, sendMessage);
    yield takeEvery(actionTypes.SEND_GROUP_MESSAGE, sendGroupMessage);
    yield takeEvery(actionTypes.SEND_CUSTOMER_SERVICE_MESSAGE, sendCustomerServiceMessage);
    yield takeLatest(actionTypes.GET_CONVERSATION, getConversationList);
    yield takeLatest(actionTypes.GET_MORE_CONVERSATION, getMoreConversationList);
    yield takeEvery(actionTypes.GET_CHAT_LIST, getChatsList);
    yield takeEvery(actionTypes.SEARCH_CHAT_LIST, searchChatsList);
    yield takeEvery(actionTypes.GET_CONVERSATION_STATUS, getConversationStatus);
    yield takeEvery(actionTypes.DELETE_CHAT, removeMessage)

    yield takeLatest(actionTypes.GET_GROUP_CONVERSATION, getGroupConversationList);
    yield takeLatest(actionTypes.GET_GROUP_MORE_CONVERSATION, getMoreGroupConversationList);

    yield takeEvery(actionTypes.GET_GROUP_CHAT_LIST, getGroupChatsList);

    yield takeEvery(actionTypes.GET_CHAT_MEDIA, getChatMedia)

    //close if chat match view opened when changing chat
    yield takeEvery(actionTypes.SET_CURRENT_CHAT, setCurrentChatEffect)

    yield takeEvery(actionTypes.TOGGLE_MUTE_CONVERSATIONS, toggleMuteConversation)

    yield takeEvery(actionTypes.LOG_DOCUMENT_VIEW, logDocumentView)

    yield takeEvery(actionTypes.STAR_MESSAGE, starMessage)

    yield takeEvery(actionTypes.OFFLINE_PROFILE, offlineprofile)
    
    
    yield takeEvery(actionTypes.RESET_UNREAD_COUNT, resetUnreadCount)

    yield takeLatest(actionTypes.GET_BULK_REQUEST, getBulkRequests)

    yield takeEvery(actionTypes.GET_FAMILY_CHAT_DETAIL,getFamilyChatsDetail)

    // yield takeEvery(actionTypes.GET_FAMILY_CHAT_LIST,getFamilyChatsList)

    yield takeEvery(actionTypes.REACTIONS_ADD,addGroupChatReactions)

    yield takeEvery(actionTypes.DELETE_GROUP_MESSAGE,removeGroupMessage)

    yield takeEvery(actionTypes.RESET_MSG_UNREAD_COUNT, resetMsgUnreadCount)

    // yield takeEvery(actionTypes.RESET_UNREAD_MSG_COUNT,resetUnreadMsgCount)
    
    yield takeEvery(actionTypes.GROUP_STAR_MESSAGE, groupStarMessage)

    yield takeEvery([actionTypes.READ_GROUP_MESSAGE, actionTypes.GET_GROUP_CONVERSATION_SUCCESS], readGroupMessageApi)

    yield takeLatest(actionTypes.GET_CUSTOMER_SERVICE_CONVERSATION, getCustomerServiceConversationList);
    yield takeLatest(actionTypes.GET_CUSTOMER_SERVICE_MORE_CONVERSATION, getMoreCustomerServiceConversationList);
    
    yield takeEvery([actionTypes.CHAT_UNREAD_MSG_COUNT],readCustomerMessageApi);

    yield takeEvery(actionTypes.FAMILY_SET_FAMILY_CODE, setFamilyCode)

    yield takeEvery(actionTypes.RANKING_POPUP, rankingPopup)

    yield takeEvery(actionTypes.RESET_UN_READ_COUNT, resetUnreadMsgCount);

    // profile send message
    yield takeEvery(actionTypes.PROFILE_SEND_MESSAGE, profileSendMessage);


}

export default ChatsSaga;