import { call, put, takeEvery } from "redux-saga/effects";
import { SEARCH_PAGE_CHECK_REQUEST} from "./ActionTypes";
import { searchPageCheckRequestApiError, searchPageCheckRequestSuccess } from "./Action";
import { Send_Filter_Request, Send_Search_Page_Check_Request } from "../../Helpers/api_helpers";


function* searchPageRequest({ payload: {values, loginDetail} }) {
  console.log(values,"datadata");
  try {
    const response = yield call(Send_Search_Page_Check_Request,{
            pageno: values?.pageno,
            email: loginDetail()[0],
            userId: loginDetail()[1],
            ageTo: values?.ageTo,
            ageFrom: values?.ageFrom,
            heightFrom: values?.heightFrom,
            heightTo: values?.heightTo,
            marital_status: values?.marital_status,
            mother_tongue: values?.mother_tongue,
            educationId: values?.educationId,
            subcasteId: values?.subcasteId,
            casteIdList: values?.casteIdList,
            countryIdList: values?.countryIdList,
            religionIdList: values?.religionIdList,
            shortlisted: values?.shortlisted,
            ignored: values?.ignored,
            contacted: values?.contacted,
            viewed: values?.viewed,
            withHoroscope: values?.withHoroscope,
            withPhoto: values?.withPhoto
  });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(searchPageCheckRequestSuccess(response));
    } else {
      yield put(searchPageCheckRequestApiError(response));
    }
  } catch (error) {
    yield put(searchPageCheckRequestApiError(error));
  }
}




function* searchPageCheckSaga() {
  yield takeEvery(SEARCH_PAGE_CHECK_REQUEST, searchPageRequest);
}

export default searchPageCheckSaga;
