import React, { useState, useEffect, useContext } from "react";
//validation 
import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { registerEmailCheck, registerPhoneCheck } from "../../Store/Register/Action";
import OtpPopup from "../Edit Profile/SettingsComponents/OtpPopup";
import { Link, useNavigate } from "react-router-dom";
import RouteSegments from "../../Routes/RouteSegment";
import { LoginContext } from "../../Contexts/LoginContext";
import Closeicon from "../../Images/cancelicon.svg"
import MailLinkIcon from "../../Images/Group 1095.svg"
import PhoneIcon from "../../Images/phone.svg"
import XIcon from "../../Images/Group 761@2x.png"
import { useAnalytics } from "../../Hooks/usePageChange";
import { EventKeys, EventName } from "../../Constants/constants";
import { useTranslation } from "react-i18next";

const ForgetPasswordPopup = (props) => {

  const dispatch = useDispatch()
  const { setPhoneNumber } = useContext(LoginContext)

  const [showModal, setShowModal] = useState(false);
  const [convoshowModal, setConvoShowModal] = useState(false);
  const [phoneOTPModal, setphoneOTPModal] = useState(false);
  const [statusMessageError, setStatusMessageError] = useState("")
  const [statusMessage, setStatusMessage] = useState("")
  const [values, setValues] = useState()
  const { onClickTrack } = useAnalytics()
  const navigate = useNavigate()

  const { t:trans } = useTranslation();



  const formik = useFormik({
    initialValues: {
      email: '',
      isdCode: '+91',
      mobileNo: ''
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email format')
        .test('emailOrMobile', '', function (value) {
          const { mobileNo } = this.parent;
          return value || mobileNo;
        }),

      mobileNo: Yup.string()
        .matches(/^\d+$/, 'Invalid mobile number format')
        .test('emailOrMobile', 'Please enter the registered e-mail ID OR Phone Number', function (value) {
          const { email } = this.parent;
          return value || email;
        })
        .test('emailAndMobile', 'Please enter the registered e-mail ID OR Phone Number', function (value) {
          const { email } = this.parent;
          return (value && !email) || (!value && email);
        })
        .min(10, 'Mobile number must be at least 10 digits')
        .max(10, 'Mobile number must be at most 10 digits')
    }),
    onSubmit: (values) => {
      console.log(values, "popupvalues");
      SigninFormSubmission(values);
      onClickTrack(EventName.Home_page_Forgot_Password_Submit_Button, { [EventKeys.Home_page_Forgot_Password_Submit_Button_Request]: values })
    },
  })
  const DisplayingErrorMessagesSchema = Yup.object().shape({
    mobileNo: Yup.string()
      .min(8, "Mobile number must be at least 10 digits")
      .max(10, "Mobile number must be at most 10 digits")
  })

  useEffect(() => {
    // console.log(formik.values.email, "1234567890)")
  })

  const { statusmsg } = useSelector(state => ({
    statusmsg: state.Register?.FailureData?.data?.statusmsg
  }));

  const { statussuccess } = useSelector(state => ({
    statussuccess: state.Register?.FailureData?.data?.status
  }));


  useEffect(() => {
    setStatusMessageError("")
  }, [formik.values, statusMessageError])

  useEffect(() => {
    setStatusMessageError(statusmsg)
  }, [statusmsg, statusMessageError])

  useEffect(() => {
    setStatusMessage(statussuccess)
  }, [statusMessage])

  useEffect(() => {
    setConvoShowModal(false)
  }, [convoshowModal])

  useEffect(() => {

  }, [props?.popup, statusMessage, statussuccess])

  useEffect(() => {
    if (statussuccess === "SUCCESS") {
      console.log(showModal, "showModal");
      setShowModal(false)
      setConvoShowModal(true)
      setStatusMessage("")
      values?.email == '' &&
        setTimeout(function () {
          navigate(RouteSegments.GET_OTP)
        }, 1000);
    }
  }, [statusMessage, statussuccess])

  const SigninFormSubmission = (values) => {
    // console.log(values, "valuess");
    setValues(values)
    if (values?.email != '') {
      dispatch(registerEmailCheck(values))
    }
    else {
      setPhoneNumber(values?.mobileNo)
      dispatch(registerPhoneCheck(values?.mobileNo))
    }
  }

  const openModal = () => {
    if (statussuccess == "SUCCESS") {
      setShowModal(false)
      setConvoShowModal(true)
      setStatusMessage("")
      values?.email == '' &&
        setTimeout(function () {
          navigate(RouteSegments.GET_OTP)
        }, 1000);
    }

  }

  const closeModal = (val) => {
    // console.log(values?.email,"values?.email");
    if (values?.email != '' || values?.mobileNo != '') {
      // console.log(values?.email,"values?.email");
      setConvoShowModal(false)
      setShowModal(false)
    }
  }

  return (

    <div className="font-segeo ">
      {props?.popup === true ?
        <p onClick={() => setShowModal(true)} className=
          {`${props?.menu === "sigin" ?
            " cursor-pointer text-[#000000]  whitespace-nowrap md:mt-0 text-[14px] md:text-[#000000] font-bold font-segeo  md:text-[11px] lg:text-[14px]   md:float-left relative top-[-5.7rem] md:top-[-2.6rem] lg:top-[-2.9rem]"


            : "cursor-pointer text-[#000000] top-[-5.8rem]  md:top-[-2.7rem] relative font-bold md:text-[#000000] md:pl-[5.1rem] lg:pl-0 pl-0 text-[11px]  mt-[0.3rem] md:mt-0 md:text-[11px] lg:text-[14px]  float-left"
            }`}
        >Forgot Password? </p>
        :
        <p onClick={() => { setShowModal(true); onClickTrack(props?.menu === "sigin" ? EventName.Home_page_Signin_Forgot_Password_Button : EventName.Home_page_Forgot_Password_Forgot_Password_Button) }} className=
          {`${props?.menu === "sigin" ?
            " cursor-pointer text-[#000000]   md:mt-0  whitespace-nowrap text-[12px] md:text-[#000000] font-bold font-segeo  md:text-[11px] lg:text-[14px]   md:float-left relative  top-[-6.1rem] md:top-[-2.9rem] lg:top-[-3rem]"

            : props?.menu === "homeSignin" ?
              " cursor-pointer text-[#000000] font-bold mt-1 whitespace-nowrap md:mt-0 text-[13px] md:text-[#000000] font-segeo  md:text-[11px] lg:text-[14px]   md:float-left relative top-[-6.3rem] md:top-[-3rem] lg:top-[-2.9rem]"

              : props?.menu === "community" ?
                "cursor-pointer text-[#FFFFFF] md:text-[#8F8F8F] md:pl-[4.1rem] lg:pl-0 pl-0 text-[11px]  mt-[0.3rem] md:mt-0 md:text-[11px] lg:text-[14px]  float-left"

                : props?.page === "invsignup" ?
                  "cursor-pointer text-[#000000] whitespace-nowrap  md:mt-0 text-[14px] md:text-[#000000]  md:text-[11px] lg:text-[14px]   md:float-left relative  top-[-4.1rem] md:top-[-3rem]"
                  : props?.type === "campainLanding"
                    ?
                    "cursor-pointer text-[#FFFFFF] md:text-[#8F8F8F] md:pl-[10.1rem] lg:pl-0 pl-0 text-[11px]  mt-[0.3rem] md:mt-0 md:text-[11px] lg:text-[14px]  float-left"

                    : "cursor-pointer text-[#FFFFFF] md:text-[#8F8F8F] md:pl-[7.1rem] lg:pl-0 pl-0 text-[11px]  mt-[-1.8rem] md:mt-[15px] lg:mt-0 md:text-[11px] lg:text-[14px]  float-left"
            }`}
        >Forgot Password? </p>
      }
      {/* sigin */}
      <>
        {!convoshowModal ?
          showModal ? (
            <Formik
              validationSchema={DisplayingErrorMessagesSchema}
            >
              <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto ">
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">

                      <div className=" bg-white rounded-lg top-[-8rem] md:top-0 text-left  shadow-xl transform transition-all sm:my-8 w-[21rem] sm:max-w-[26rem] lg:max-w-[32rem] sm:w-full">
                        <div className="relative ">
                          <div className="bg-white md:px-4 px-3 pt-5 rounded-lg pb-4 sm:p-6 sm:pb-4">
                            <div className="  items-start">
                              <p className="text-[#D10A11] flex justify-between px-5 md:px-0 md:justify-center font-bold text-[21px] md:text-[24px] lg:text-[26px] ">
                                Having trouble signing in?
                              </p>
                              <div style={{ borderWidth: "2px" }}
                                className="  border-t-[#D10A11] border-dashed border-[#FFFFFF]  md:block md:col-span-5 lg:col-span-5 mx-[0.2rem] md:mx-0 md:ml-[0.7rem] lg:mx-[2.5rem] mt-[1rem] py-[1.5rem] ">
                                <div>
                                  <div className="mx-[2rem]  ">

                                    <form onSubmit={formik.handleSubmit}>
                                      {/* {console.log(formik?.errors, "errorsss")} */}
                                      <p className=" text-[12px]  text-[#000000] font-semibold mt-[1rem] ">
                                        Reset Password Using Mail ID

                                      </p>
                                      <p className=" pt-[5px] ">
                                        <label className=" font-bold text-[#575556] w-full ">
                                          <input value={formik.values.email}
                                            onChange={formik.handleChange} touched={formik?.touched} type="text" name="email" className="  border border-[#8F8F8F]  pl-2 md:pl-2 text-sm  rounded-[3px] h-[35px]  block w-full md:p-[0.4rem] lg:p-2.5  font-bold    " placeholder="Email" />
                                        </label>
                                        {formik.errors.email && formik.touched.email && (
                                          <div className="text-[red] text-[12px] h-[1rem]">{formik?.errors.email}</div   >
                                        )}
                                      </p>
                                      <div className="flex justify-center items-center py-[1.5rem]  ">
                                        <div className="  border-[4px] border-[#D10A11]  items-center text-[#D10A11] text-[18px]  rounded-[50%] w-[60px] h-[60px] flex justify-center ">
                                          {trans('generate_horoscope.or')}
                                        </div>
                                      </div>
                                      <div className="   ">
                                        <div className="col-span-4 flex items-center  ">
                                          <p className=" md:text-[12px] lg:text-[14px] flex text-[#000000] font-semibold  ">

                                            Reset Password Using Phone Number

                                          </p>
                                        </div>
                                        <div className="  col-span-4 ">
                                          <p className="  ">
                                            <div className="flex space-x-3 ">
                                              <label className=" font-bold text-[#575556] pt-[5px] ">
                                                <input value={formik.values.isdCode}
                                                  touched={formik?.touched}
                                                  disabled
                                                  onChange={formik.handleChange} name="isdCode" type="text" id="isdCode" className="pointer-none  border border-[#8F8F8F]   text-sm  rounded-[3px] h-[35px]  block  md:p-[0.4rem] lg:p-2.5  font-bold  pl-2 md:pl-0   w-[4rem] " />

                                              </label>
                                              <p className=" flex items-center pt-[5px] RegPhone">
                                                <input value={formik.values.mobileNo}
                                                  touched={formik?.touched}
                                                  onChange={formik.handleChange} type="number" name="mobileNo" className=" RegPhone border border-[#8F8F8F]   text-sm  rounded-[3px] h-[35px]  block md:p-[0.4rem] lg:p-2.5  font-bold pl-2 md:pl-2   w-[11rem] md:w-[15rem] " placeholder="Mobile No" />

                                              </p>


                                            </div>
                                            {/* {formik.errors.mobileNo&&
                                                <p className="text-[red] text-[12px] h-[1rem]">{formik.errors.mobileNo}</p>
                                               } */}
                                          </p>
                                        </div>
                                      </div>
                                      {formik.errors.mobileNo && formik.touched.mobileNo && (
                                        <div className="text-[red] text-[12px] h-[1rem]">{formik?.errors.mobileNo}</div   >
                                      )}
                                      {statusMessageError != "" &&
                                        <div className="text-[red] text-[12px] h-[1rem]">{statusMessageError}</div   >
                                      }
                                      <div className="flex justify-center items-center ">
                                        <button type="submit" onClick={() => openModal()}
                                          className=" bg-[#D10A11] mt-[2rem] px-[1rem] py-[1rem] md:px-[0.5rem] lg:px-[1rem] md:text-[12px] lg:text-[14px] font-bold rounded-[5px] h-[31px] md:h-[27px] lg:h-[38px] flex items-center text-[#FFFFFF] ">
                                          Continue
                                        </button>
                                      </div>
                                    </form>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          <button
                            onClick={() => setShowModal(false)}
                            className="
                  absolute top-[0rem]  md:top-[0rem] right-[0rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2"
                          >
                            <img className="w-[2rem]"
                              // src="/Assets/Images/Group 761@2x.png"
                              src={Closeicon}
                              alt="" />
                          </button>
                        </div>

                      </div>

                    </div>
                  </div>

                  <button
                    onClick={() => setShowModal(false)}
                    className="
                  absolute top-[2.2rem] md:top-[2.5rem] right-[2rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2"
                  >
                    <img
                      className="w-[2rem]"
                      src={Closeicon}
                      // src="/Assets/Images/Group 761@2x.png"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </Formik>
          ) : (
            ""
          ) : " "
        }
      </>
      <>
        {convoshowModal ? (
          <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto ">
              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end items-center justify-center min-h-full p-4 text-center sm:p-0">
                  <div className="relative bg-white rounded-lg   text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-[26rem] lg:max-w-[32rem] sm:w-full">
                    <div className="relative inline-block">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="  items-start">
                          <p className="text-[#D10A11] flex justify-center font-bold text-[21px] md:text-[24px] lg:text-[26px] ">
                            {values?.email != "" ?
                              "Check your e-mail"
                              :
                              "Check your Mobile"
                            }
                          </p>
                          <div style={{ borderWidth: "2px" }} className="  border-t-[#D10A11] border-dashed border-[#FFFFFF]  md:block md:col-span-5 lg:col-span-5 mx-[0.2rem] md:mx-0 md:ml-[0.7rem] lg:mx-[2.5rem] mt-[1rem] py-[1.5rem] ">
                            <div>



                              <div className="mx-[2rem]  ">

                                <p className=" text-[14px] justify-center items-center flex text-[#575556] font-semibold mt-[1rem] ">
                                  {/* {statusMessageError} */}
                                  {values?.email != "" ?
                                    "We have sent a link to the registered e-mail ID to reset your password"
                                    :
                                    "We have sent a OTP to the registered mobile number to reset your password"
                                  }

                                </p>


                                <div className="flex justify-center items-center py-[1.5rem]  ">
                                  <div className="  items-center flex justify-center ">
                                    {values?.email != "" ?
                                      <img className="" src={MailLinkIcon} alt="" />
                                      :
                                      <img className="" src={PhoneIcon} alt="" />
                                    }
                                  </div>
                                </div>

                                <div className="flex justify-center items-center ">
                                  <button

                                    onClick={() => closeModal(false)}
                                    className=" bg-[#D10A11]  px-[3rem] py-[1rem] md:px-[3rem]  md:text-[12px] lg:text-[14px] font-bold rounded-[5px] h-[31px] md:h-[27px] lg:h-[38px] flex items-center text-[#FFFFFF] ">
                                    Ok
                                  </button>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => closeModal(false)}
                        className="
                  absolute top-[0rem] right-[0rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2"
                      >
                        <img
                          onClick={() => closeModal(false)}
                          className="w-[2rem]"
                          src={XIcon}
                          alt=""
                        />
                      </button>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        ) : (
          " "
        )
        }
      </>







    </div>
  )
}

export default ForgetPasswordPopup
