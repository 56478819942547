import React from 'react'
import LoaderGif from "../Gif/loader.gif"

function Loader({ className, loaderClassName }) {
    return (
        <div className={`flex h-52 items-center justify-center ${className}`}>
            <img alt="loader" src={LoaderGif} className={`w-16 h-16 ${loaderClassName}`}/>
        </div>
    )
}

export default Loader