import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import RouteSegments from "../Routes/RouteSegment";

export default function UserProfileFooter() {
  return (
    <div>
       <footer className="md:hidden fixed mt-8 inset-x-0 bottom-0">
       <div className="bg-[#D10A11] pt-3  px-3 justify-between  flex  rounded-t-[30px]">
            <Link to={RouteSegments.DASHBOARD}  className="flex flex-col items-center">
                <img className="w-7 h-7" src="/Assets/Images/Group 392.svg" alt=""/>
                <p className="text-[9pt] text-[#FFFFFF]">Matches</p>
                
              </Link>
              <Link  to ={RouteSegments.EDITPROFILE}
              className="flex flex-col items-center">
                <img className="w-7 h-7" src="/Assets/Images/Group 395.svg" alt=""/>
                <p className="text-[9pt] text-[#FFFFFF]">Selected </p>
                <p className="text-[9pt] text-[#FFFFFF]">Profiles</p>
              </Link>
              <Link to={RouteSegments.SEARCH} className="flex flex-col items-center">
                <img className="w-7 h-7" src="/Assets/Images/Group 393.svg" alt=""/>
                <p className="text-[9pt] text-[#FFFFFF]">Search</p>
              </Link>
              <Link to={RouteSegments.INBOX} className="flex flex-col items-center">
                <img className="w-7 h-7" src="/Assets/Images/Group 394.svg" alt=""/>
                <p className="text-[9pt] text-[#FFFFFF]">Message</p>
              </Link>
              <Link to={RouteSegments.NOTIFICATION} className="flex flex-col items-center">
                <img className="w-7 h-7" src="/Assets/Images/Group 396.svg" alt=""/>
                <p className="text-[9pt] text-[#FFFFFF]">Notifications</p>
              </Link>
            </div>
        </footer>
    </div>
  )
}
