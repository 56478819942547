import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import $ from 'jquery'
import useSeoMetaData from './useSeoMetaData.json'
import useSeoMetaDataLite from './useSeoMetaDataLite.json'
function useSeoMeta() {

    const isV3User = localStorage.getItem("isV3User")

    // let metaScripts =require('./useSeoMetaData.json')
    const location = useLocation()
   
    let metaScript;

    if (isV3User) {
        metaScript = useSeoMetaDataLite[location.pathname];
    } else {
        metaScript = useSeoMetaData[location.pathname];
    }

    useEffect(() => {
        if (metaScript) {
            // alert(location.pathname)
            console.log(metaScript?.metaScript,"metaScript");
            $('head').append(`<div class="metaScript">${metaScript?.metaScript}</div>`); 
            
        }
        return () => {
            $('.metaScript').remove()
        }
    }, [metaScript, location.pathname])

    return {metaScript}
}

export default useSeoMeta