import { SEARCH_BY_CITY, SEARCH_BY_CITY_API_ERROR, SEARCH_BY_CITY_SUCCESS, SEARCH_BY_EDUCATION, SEARCH_BY_EDUCATION_API_ERROR, SEARCH_BY_EDUCATION_SUCCESS, SEARCH_BY_FILTER, SEARCH_BY_FILTER_API_ERROR, SEARCH_BY_FILTER_SUCCESS, SEARCH_BY_OCCUPATION, SEARCH_BY_OCCUPATION_API_ERROR, SEARCH_BY_OCCUPATION_SUCCESS } from "./ActionTypes";


  //city request
  export const searchByCityRequest = (values) => {
    //console.log(values);
    return {
      type: SEARCH_BY_CITY,
      payload: {values}
    };
  };

  export const searchByCityRequestSuccess = data => {
    //console.log(data,"12344");
    return {
      type: SEARCH_BY_CITY_SUCCESS,
      payload: data,
    };
  };
  
  export const searchByCityRequestApiError = error => {
    return {
      type: SEARCH_BY_CITY_API_ERROR,
      payload: error,
    };
  };

    //education request
    export const searchByEducationRequest = (values) => {
      //console.log(values);
      return {
        type: SEARCH_BY_EDUCATION,
        payload: {values}
      };
    };
  
    export const searchByEducationRequestSuccess = data => {
      //console.log(data,"12344");
      return {
        type: SEARCH_BY_EDUCATION_SUCCESS,
        payload: data,
      };
    };
    
    export const searchByEducationRequestApiError = error => {
      return {
        type: SEARCH_BY_EDUCATION_API_ERROR,
        payload: error,
      };
    };

      //occupation request
  export const searchByOccupationRequest = (values) => {
    //console.log(values);
    return {
      type: SEARCH_BY_OCCUPATION,
      payload: {values}
    };
  };

  export const searchByOccupationRequestSuccess = data => {
    //console.log(data,"12344");
    return {
      type: SEARCH_BY_OCCUPATION_SUCCESS,
      payload: data,
    };
  };
  
  export const searchByOccupationRequestApiError = error => {
    return {
      type: SEARCH_BY_OCCUPATION_API_ERROR,
      payload: error,
    };
  };

    //filter request
    export const searchByFilterRequest = (values) => {
      //console.log(values);
      return {
        type: SEARCH_BY_FILTER,
        payload: {values}
      };
    };
  
    export const searchByFilterRequestSuccess = data => {
      //console.log(data,"12344");
      return {
        type: SEARCH_BY_FILTER_SUCCESS,
        payload: data,
      };
    };
    
    export const searchByFilterRequestApiError = error => {
      return {
        type: SEARCH_BY_FILTER_API_ERROR,
        payload: error,
      };
    };


  