import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from '../Store/Login/Action';
import { useSearchParams } from 'react-router-dom';
import withRouter from '../Components/withRouter';
import { callBackDetail } from '../Store/Payment/Action';
import { blockInvalidCharForNumber } from '../Constants/constants';
import Constants from "../Constants/constants"
import { useTranslation } from 'react-i18next';

const CallBackForm = (props) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams()
  const { t: trans } = useTranslation();
  const formik = useFormik({
    initialValues: {
      mobileNo: props.mobileNumber,
      language: "49",
    },
    validationSchema: Yup.object().shape({
      mobileNo: Yup.string()
        .min(7, trans('family.please_enter_a_valid_mobile_nu'))
        .max(15, trans('family.please_enter_a_valid_mobile_nu'))

        .required(trans('request_callback_popup.please_enter_mobile_number')),
      language: Yup.string()
        .required("Please select an option"),
    }),
    onSubmit: (values) => {
      CallBackFormSubmission(values);
    },
  });
  const CallBackFormSubmission = async (values) => {
    dispatch(callBackDetail(values, localStorage.getItem(Constants.loginLocalStorageKeys.loginId)))
  }

  let { responseData } = useSelector(state => ({
    responseData: state.Payment?.callbackdata?.status
  }));

  //console.log(responseData,"responseData");

  useEffect(() => {
    responseData = ""
  }, [])

  useEffect(() => {
    if (responseData == "Success") {
      props.callBackSubmit()
    }
  }, [responseData, props.callBackSubmit])
  return (
    <div>
      <div className=" md:pl-[1rem] md:px-[1rem] ">
        <form onSubmit={formik.handleSubmit}>
          <div className="">
            <div className=''>
              <div className='grid grid-cols-9 items-center flex space-x-2'>
                <div className='col-span-4'>
                  <div className="space-x-2">
                    <p className="text-[#000000] font-semibold flex items-center md:text-[12px] lg:text-[16px]  opacity-[0.8] font-semibold">
                      {trans('request_callback_popup.mobile_number')}
                    </p>
                  </div>
                </div>
                <div className='col-span-5'>
                  <div className="">
                    <div className=" relative md:block ">
                      <div className="flex RegPhone">
                        <input id="mobileNo"
                          name="mobileNo"
                          type={"number"}
                          autoComplete="off"
                          onKeyDown={(event) => blockInvalidCharForNumber(event, 15)}
                          value={formik.values.mobileNo}
                          onChange={formik.handleChange}
                          className="truncate RegPhone placeholder-[#575556] font-semibold border border-[#F18181]  block p-2 rounded-md md:text-[10px] md:leading-[13px]  lg:text-sm w-[100%] "
                          placeholder={trans('request_callback_popup.enter_your_mobile_number')} />
                      </div>
                    </div>
                    {formik.errors.mobileNo && formik.touched.mobileNo && (
                      <p className="text-[red] text-[12px]">{formik.errors.mobileNo}</p>
                    )}
                  </div>
                </div>
              </div>
              <br />

              <div className='grid grid-cols-9 flex items-center space-x-2'>
                <div className='col-span-4'>
                  <div className="space-x-2">
                    <p className="text-[#000000] font-semibold flex items-center md:text-[12px] lg:text-[16px]  opacity-[0.8] font-semibold">
                      {trans('request_callback_popup.language')}
                    </p>
                  </div>
                </div>
                <div className='col-span-5'>
                  <div className="space-y-1">
                    <div className=" relative md:block ">
                      <div className="flex ">
                        <select id="language"
                          name="language"
                          autoComplete="off"
                          value={formik.values.language}
                          onChange={formik.handleChange}
                          className="placeholder-[#575556] font-semibold border border-[#F18181]  block p-2 rounded-md md:text-[10px] md:leading-[13px]  lg:text-sm "
                          placeholder="" >
                          <option disabled  >--{trans('request_callback_popup.select')}--</option>
                          <option selected="selected" value="49">Tamil</option>
                          <option value="50">Telugu</option>
                          <option value="33">Malayalam</option>
                          <option value="21">Kannada</option>
                          <option value="19">Hindi</option>
                          <option value="12">English</option>
                        </select>
                      </div>
                    </div>
                    {formik.errors.language && formik.touched.language && (
                      <p className="text-[red] text-[12px]">{formik.errors.language}</p>
                    )}
                  </div>
                </div>
              </div>


            </div>

            <div className="flex justify-center mt-[1.5rem] ">
              <button type="submit" className=" bg-[#D10A11] px-[1rem] md:px-[1rem] lg:px-[1rem] md:text-[12px] lg:text-[14px] font-bold rounded-[5px] h-[27px] lg:h-[38px] flex items-center text-[#FFFFFF] ">
                {trans('request_callback_popup.submit')}
              </button>
            </div>
          </div>
        </form>
      </div>
      <br />
    </div>
  )
}

export default CallBackForm
