import React, { useState, useRef, useLayoutEffect } from 'react';

const OutsideClickListener = ({ as:Tag = "div", onOutsideClick, children, ...props }) => {
  const containerRef = useRef(null);

  // Handle outside click logic
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      onOutsideClick(); // Call the callback when outside click is detected
    }
  };

  // Add event listener manually when the component mounts
  useLayoutEffect(() => {
    // Add event listener for detecting outside clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);  // Empty dependency array ensures this runs only once

  return (
    <Tag ref={containerRef} {...props}>
      {children}
    </Tag>
  );
};

export default OutsideClickListener;
