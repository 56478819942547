import { call, put, takeEvery } from "redux-saga/effects";
import {  DASHBOARD_LEFT_PANEL_DATA, DASHBOARD_MATCHES_LIST, DASHBOARD_PROFILE_DATA, SEARCH_BY_ID} from "./ActionTypes";
import Constants from '../../Constants/constants';
import { dashboardLeftPanelDataApiError, dashboardLeftPanelDataSuccess, dashboardMatcheslistApiError, dashboardMatcheslistSuccess, dashboardProfileDataApiError, dashboardProfileDataSuccess } from "./Action";
import { Dashboard_Left_Panel, Dashboard_Matches_List, Dashboard_Profile_Data } from "../../Helpers/api_helpers";


function* dashboardMatchesList({ payload: {values} }) {
  try {
    const response = yield call(Dashboard_Matches_List,{
      email : localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      userId : localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
  });
  console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(dashboardMatcheslistSuccess(response));
    } else {
      yield put(dashboardMatcheslistSuccess(response));
    }
  } catch (error) {
    yield put(dashboardMatcheslistApiError(error));
  }
}

function* dashboardLeftPanelData({ payload: {values} }) {
  try {
    const response = yield call(Dashboard_Left_Panel,{
      email : localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      userId : localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      pageno: "1",
      status: "new"
  });
    if (response.data.status === "Success") {
      yield put(dashboardLeftPanelDataSuccess(response));
    } else {
      yield put(dashboardLeftPanelDataSuccess(response));
    }
  } catch (error) {
    yield put(dashboardLeftPanelDataApiError(error));
  }
}

function* dashboardProfileData({ payload: { values, logout } }) {
  try {
    const response = yield call(Dashboard_Profile_Data,{
      email : localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      userId: localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice)

  });
    if (response.data.status === "Success") {
      yield put(dashboardProfileDataSuccess(response));
    } else if (response.data.statusCode == 401) {
      if (logout) {
        logout()
      }
    } else {
      yield put(dashboardProfileDataSuccess(response));
    }
  } catch (error) {
    yield put(dashboardProfileDataApiError(error));
  }
}




function* DashBoardAPISaga() {
  yield takeEvery(DASHBOARD_MATCHES_LIST, dashboardMatchesList);
  yield takeEvery(DASHBOARD_LEFT_PANEL_DATA, dashboardLeftPanelData);
  yield takeEvery(DASHBOARD_PROFILE_DATA, dashboardProfileData);
}

export default DashBoardAPISaga;
