import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import configureStore from "./Store/main";
//context
import LoginProvider from "./Contexts/LoginContext";
import NavBarProvider from "./Contexts/NavBarContext";
import NavListProvider from "./Contexts/navContext"
import { ConfigProvider } from "antd";
import CurrentContextProvider from "./Components/Kalyanlite/context/CurrentContactContext";
import { JitsuProvider } from "@jitsu/jitsu-react";
import config from "./config";
import './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={configureStore({})}>
    <BrowserRouter>
      <LoginProvider>
        <NavBarProvider>
          <NavListProvider>
            <CurrentContextProvider>
            <ConfigProvider theme={{
              token:{
                colorPrimary:"#D23C27"
              }
            }}>
                <JitsuProvider options={{
                  host: config.jitsuUrl,
                  writeKey: config.jitsuKey
                }}>
           <App />
                </JitsuProvider>
            </ConfigProvider>
            </CurrentContextProvider>
          </NavListProvider>
        </NavBarProvider>
      </LoginProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
