import React from 'react'
import KMLogo from "../../Images/KM logo@2x.png"
import CommunityTag from '../Header/CommunityTag'


const Header = () => {
  return (
    <div>
       <header className=" sticky top-0 z-10  w-full bg-white">
           <CommunityTag />
      <div className="">

         <div className=" hidden  md:flex  mx-0 p-2 md:p-0 md:mx-5 lg:mx-9  justify-between items-center ">
            <a href="" className="flex items-center py-2 ">
               <img src={KMLogo} className="h-12 md:h-[4rem] lg:h-[4.5rem]" alt=""/>
            </a>
         </div>

           <div className=" flex  mx-0 p-2  justify-center items-center md:hidden ">
            <a href="" className="flex items-center">
               <img src={KMLogo} className="h-[4rem] md:h-[4rem] lg:h-[4.5rem]" alt=""/>
            </a>
          </div>
      <div className="sticky top-0 z-10 border-b-[0.3rem] border-[#D23C27]">

      </div>
      </div>
   </header>
    </div>
  )
}

export default Header
