//get message api call
export const MESSAGE_THREAD = "MESSAGE_THREAD"
export const MESSAGE_THREAD_SUCCESS = "MESSAGE_THREAD_SUCCESS"
export const MESSAGE_THREAD_API_ERROR = "MESSAGE_THREAD_API_ERROR"

//sent message api call
export const SENT_MESSAGE = "SENT_MESSAGE"
export const SENT_MESSAGE_SUCCESS = "SENT_MESSAGE_SUCCESS"
export const SENT_MESSAGE_API_ERROR = "SENT_MESSAGE_API_ERROR"

//block profile api call
export const BLOCK_PROFILE = "BLOCK_PROFILE"
export const BLOCK_PROFILE_SUCCESS = "BLOCK_PROFILE_SUCCESS"
export const BLOCK_PROFILE_API_ERROR = "BLOCK_PROFILE_API_ERROR"

//get match horoscope api call
export const GET_HOROSCOPE_MATCH = "GET_HOROSCOPE_MATCH"
export const GET_HOROSCOPE_MATCH_SUCCESS = "GET_HOROSCOPE_MATCH_SUCCESS"
export const GET_HOROSCOPE_MATCH_API_ERROR = "GET_HOROSCOPE_MATCH_API_ERROR"

//get astro balance api call
export const GET_ASTRO_BALANCE = "GET_ASTRO_BALANCE"
export const GET_ASTRO_BALANCE_SUCCESS = "GET_ASTRO_BALANCE_SUCCESS"
export const GET_ASTRO_BALANCE_API_ERROR = "GET_ASTRO_BALANCE_API_ERROR"

export const CLEAR_ASTRO_BALANCE = "CLEAR_ASTRO_BALANCE"
