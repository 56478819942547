import Compressor from 'compressorjs';
import imageCompression from 'browser-image-compression';


export const imageSizeReducer = async(file,originalImageUpload) => {
        const image = file;
        new Compressor(image, {
          quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
          mimeType:"image/jpeg",
          convertSize: 1000000,
          success: (compressedResult) => {
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.        
            // setCompressedFile(res)
            console.log(compressedResult.size / 1024 / 1024 ,"compressedResult in MB");
            originalImageUpload(compressedResult)
          },
        });        
}

export const imageSizeCompression =  async(file,originalImageUpload) => {
var imageFile = file

var controller = new AbortController();

var options = {
  // other options here
  signal: controller.signal,
}
imageCompression(imageFile, options)
  .then(function (compressedFile) {
    originalImageUpload(compressedFile)
  })
  .catch(function (error) {
    console.log(error.message); // output: I just want to stop
  });

// simulate abort the compression after 1.5 seconds
setTimeout(function () {
  controller.abort(new Error('I just want to stop'));
}, 1500);
}

export const handleImageSizeCompress = async(file,originalImageUpload,setCompressing) => {
const imageFile = file;
// console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
// console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

const options = {
  maxSizeMB: 1,
  useWebWorker: true,
  fileType:'image/png'
}
try {
  const compressedFile = await imageCompression(imageFile, options);
  if(setCompressing){
  setCompressing(false)}
  // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
  // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  console.log(compressedFile,'compressedFile');

  await originalImageUpload(compressedFile)
  // uploadToServer(compressedFile); // write your own logic
} catch (error) {
  console.log(error);
}
}
