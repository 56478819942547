import React, { useContext, useEffect, useState } from "react";
import { Drawer } from "antd";
import { NavBarContext } from "../Contexts/NavBarContext";
import { BsArrowLeft } from "react-icons/bs";
import MultiRangeSliderAge from "../Components/SearchResultComponents/MultiRangeSliderAge";
import FilterSection from "./Filter Panel/FilterSection";
import { FilterPopup } from "./CommonPopup";
import LoaderGif from "../Gif/loader.gif" 
import FilterContents from "./SearchResultComponents/FilterContents";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginContext } from "../Contexts/LoginContext";
// import { FilterContext } from "../Contexts/filterContext";
import Constants, { loginFrom } from "../Constants/constants";
import { useDispatch, useSelector } from "react-redux";
import EndPoints from "../Constants/EndPoints";
import { POST } from "../Services/api_services";
import { IP_Address } from "../Constants/IpAddress";
import RefineFilter from "./SearchResultComponents/RefineFilter";
import { sendFilterRequest } from "../Store/FilterSection/Action";
import BasicFilter from "./SearchResultComponents/BasicFilter";
import RouteSegments from "../Routes/RouteSegment";
import { registerCasteList, registerCityList, registerRaasiList, registerStateList, registerSubCasteList } from "../Store/Home/Action";
import Loader from "./Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import Searchbyid from "./BasicAdvanceSearch/searchbyid";
import config from "../config";
import {  dashboardMatcheslist, dashboardProfileData } from "../Store/DashboardAPI/Action";
import { useTranslation } from "react-i18next";

function FilterPanel(props) {
  const { closeFilter, isOpenFilter } = useContext(NavBarContext);
  const [selectedMenu, setSelectedMenu] = useState("1");

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedFilter, setSelectedFilter] = useState("2")
  // const [isFromComponent, setIsFromComonent] = useState("")
  const [showKjBannerPopup, setShowKjBannerPopup] = useState(false);
  const [showKjThankPopup, setShowKjThankPopup] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [dashboard1Data, setDashboard1Data] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addBanner, setAddBanner] = useState();
  const [recentlyViewedData, setrecentlyViewedData] = useState([]);
  const [recentlyViewedData1, setrecentlyViewedData1] = useState([]);
  const [similarmatchesdata, setSimilarMatchesData] = useState([])
  const [exploreMatchesData, setExploreMatchesData] = useState([])

  const [showProfileStatusPopup, setShowProfileStatusPopup] = useState(false)
  const [ProfileStatusMessage, setProfileStatusMessage] = useState("")
  const { openFilter } = useContext(NavBarContext)
  const [filterOpen, setFilterOpen] = useState(false)

  const { t: trans } = useTranslation();

  const [navitem, setNavItem] = useState(
    location?.state?.isfrom ?? ""
  )
 
  const [isSearch, setIsSearch] = useState(
    {filter:"activity",selected:false},
    {filter:"basic",selected:false},
    {filter:"maritalStatus",selected:false},
    {filter:"physicalStatus",selected:false},
    {filter:"motherTongue",selected:false},
    {filter:"religion",selected:false},
    {filter:"caste",selected:false},
    {filter:"subCaste",selected:false},
    {filter:"education",selected:false},
    {filter:"employedIn",selected:false},
    {filter:"occupation",selected:false},
    {filter:"country",selected:false},
    {filter:"state",selected:false},
    {filter:"city",selected:false},
    {filter:"star",selected:false},
    {filter:"raasi",selected:false},
    {filter:"dosham",selected:false},
    {filter:"food",selected:false},
    {filter:"smoking",selected:false},
    {filter:"drinking",selected:false},
    {filter:"showProfile",selected:false},
    {filter:"dontshowProfile",selected:false},
  )
  const [isSelectAll, setIsSelectAll] = useState("select")

  

  const [viewallData, setViewAllData] = useState([]);
  let cardPageNumValue = {
    weeklyDataPage: 1,
    recommendedPage: 1,
    responseReceivedPage: 1,
    profilelookingforPage: 1,
    profileLookingForMePage: 1,
    mutualPage: 1,
    selectedProfilePage: 1,
    photorequestPage: 1,
    recentlyViewedPage: 1,
    similarMatchesPage:1,
    exploreMatchesPage:1
  };
  const [cardPageNum, setCardPageNum] = useState(cardPageNumValue);
  const [Weeklydata, setWeeklyData] = useState([]);
  const [RecommendedData, setRecommendedData] = useState([]);
  const [responseReceivedData, setresponseReceivedData] = useState([]);
  const [profileILookingForData, setprofileILookingForData] = useState([]);
  const [profileLoookingForMeData, setprofileLoookingForMeData] = useState([]);
  const [MutualData, setMutualData] = useState([]);
  const [selectedProfileData, setSelectedProfileData] = useState([]);
  const [photoRequestData, setphotoRequestData] = useState([]);
  const [advancesearch, setAdvanceSearch] = useState([]);
  const [tempAdvancesearch, setTempAdvanceSearch] = useState([])
  const [messageProfileDetails, setMessageProfileDetails] = useState();
  const [showPhonePopup, setShowPhonePopup] = useState(false);
  const [phoneNumData, setPhoneNumData] = useState();
  const [showRequestPopup, setShowRequestpopup] = useState(false);
  const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
  const [showSendMessagePopup, setSendMessagePopup] = useState(false);
  const [showMessageSuccess, setshowMessageSuccess] = useState(false);
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [messageFor, setMessageFor] = useState();
  const [isChecked,setIsChecked] = useState(false)
  const [selectedActivityFilter,setSelectedActivityFilter] = useState([])
  const [filterRequest, setFilterRequest] = useState()
  //  const { filterData, setFilterData } = useContext(FilterContext);
  // const [showKjBannerPopup, setShowKjBannerPopup] = useState(false);
  //  const [showKjThankPopup, setShowKjThankPopup] = useState(false);
  const [filterData, setFilterData] = useState([])
  const [selectedStarList, setSelectedStarList] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedStates, setSelectedStates] = useState([])
  const [selectedSubCasteList, setSelectedSubCasteList] = useState([])
  const [submitStatus, setSubmitStatus] = useState(false)
   const [addKjBanner, setAddKjBanner] = useState();
   const [latest, setLatest] = useState(false)
   const[showeducation,setShowEducation]=useState("")
   const [filterDataPage, setFilterDataPage] = useState(1)
   const [isFilterLoaded, setIsFilterLoaded] = useState(false);
   const [searchId, setSearchId] = useState("")
  // const { isFilterResult, setIsFilterResult } = useContext(NavBarContext);

   //Profile Data API Call and Response  
   const { ProfileData, matchesListData } = useSelector(state => ({
    ProfileData: state.DashBoardAPI?.ProfileData?.data,
    matchesListData: state.DashBoardAPI?.matchesListData?.data
  }));


   //console.log(latest,"latest");
   


  const [selectedMRList, setSelectedMRList] = useState({
    motherTongue:[],
    religion:[]
  })

  
  let selectedOption = {
    "pageno": 1,
    "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail),
    "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
    "days": "",
    "months": "",
    "weeks": "",
    "ageTo": "",
    "ageFrom": "",
    "heightFrom": "",
    "heightTo": "",
    "maritalStatus": [],
    "physicalStatus":[],
    "motherTongue": [],
    "religion": [],
    "caste":[],
    "subCaste":[],
    "education":[],
    "employedIn":[],
    "occupation":[],
    "country":[],
    "state":[],
    "city":[],
    "star":[],
    "raasi":[],
    "dosham":[],
    "food":[],
    "smoking":[],
    "drinking":[],
    "shortlisted": "",
    "ignored": "",
    "contacted": "",
    "viewed": "",
    "withHoroscope": "",
    "withPhoto": "",
    "minincome":"",
    "maxincome":"",
  }


  //console.log(location.state,"location.state");

 
  useEffect(() => {
    if(ProfileData?.status){
      localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, ProfileData?.status)
      if (ProfileData?.popup[0].showpopup == "RejectedProfile") {
        navigate(RouteSegments.EDITPROFILE)
      }
    }
  }, [ProfileData])
  
  
 

  useEffect(() => {
    // apicallDashboard();
    dispatch(dashboardProfileData())
   
    apicalladvancesearch(location?.state?.isfrom === "basic_search" || location?.state?.isfrom === "advance_search",location?.state?.request);
    
    apicallweekly(5);
     
  }, [location?.state?.isfrom]);

   useEffect(() => {
    recentlyviewCall()
  },[])

  const recentlyviewCall = () => 
   location?.state?.isfrom === "recentlyviewed"?"" : apicallRecentlyViewed(5)
  

  useEffect(() => {
    //console.log("cardPageNum", cardPageNum);
  }, [viewallData, cardPageNum, addBanner]);

  useEffect(() => {
    //console.log("advanceuseffect", advancesearch);
  }, [
    dashboard1Data,
    Weeklydata,
    RecommendedData,
    responseReceivedData,
    profileILookingForData,
    profileLoookingForMeData,
    MutualData,
    selectedProfileData,
    photoRequestData,
    recentlyViewedData,
    recentlyViewedData1,
    advancesearch,
    phoneNumData,
    messageFor,
    showRequestPopup,
    showUploadImagePopup,
    showSendMessagePopup,
    submitStatus,
    addKjBanner
  ]);

  const callBackViewPhone = async (profileId) => {
    //console.log("profile id", profileId);

    // api/viewphonenumber
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      phoneViewFrom: loginFrom,
      toProfileId: `${profileId}`,
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.viewPhoneUrl()}`,
      request
    );

    if (statusCode === 200) {
      //console.log("view phone", data);
      if (data.status === "Success") {
        setPhoneNumData(data.data);
        setShowPhonePopup(true);
        // apicallDashboard()
        dispatch(dashboardProfileData())
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  // const apicallDashboard = async () => {
  //   let request = {
  //     email: loginDetail()[0],
  //     userId: loginDetail()[1],
  //     pageno: "1",
  //     status: "new",
  //   };
  //   let { statusCode, data } = await POST(
  //     `${config.api.API_URL}${EndPoints.dashboardUrl()}`,
  //     request
  //   );

  //   if (statusCode === 200) {
  //     setDashboard1Data(data?.data);
  //             localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, data?.data.status)
  //     setAddBanner(data?.data?.addbanner[0]?.showphotobanner);
  //     setAddKjBanner(data?.data.addbanner[0]?.kjbanner);
  //     if(data?.data?.popup[0].showpopup == "RejectedProfile"){
  //       navigate(RouteSegments.EDITPROFILE)
  //     }
  //     setAddKjBanner(data?.data.addbanner[0]?.kjbanner);
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  // };
  // //console.log(dashboard1Data?.isPaid,"paidy");

  const apicallRecentlyViewed = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      recentpageno: `${cardPageNum.recentlyViewedPage}`,
      recentsize: "5",
      status: "new",
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.recentlyViewUrl()}`,
      request
    );

    if (statusCode === 200) {
      data.data.recently_viewed.map((e, i) => {
        setrecentlyViewedData1((oldValue) => [...oldValue, e]);
      });
      // setrecentlyViewedData(data.data.recently_viewed);

      cardPageNum.recentlyViewedPage = cardPageNum.recentlyViewedPage + 1;
    } else if (statusCode === 401) {
      logout();
    }
  };
  
  //console.log(recentlyViewedData1, "recentlyviewedsearch");
  
  const apicallweekly = async (pagesize) => {
    let url =
      location?.state?.isfrom === "weekly"
        ? EndPoints.weeklyMatchesURl()
        : location?.state?.isfrom === "recommended"? EndPoints.recommendedMatchesUrl()
        : location?.state?.isfrom === "response"  ? EndPoints.responseReceivedUrl()
        : location?.state?.isfrom === "lookingfor"? EndPoints.profilesLookingForUrl() 
        : location?.state?.isfrom === "lookingforme"? EndPoints.profilesLookingFormeUrl() 
        : location?.state?.isfrom === "photorequest"? EndPoints.photoRequestReceivedUrl() 
        : location?.state?.isfrom === "mutual"? EndPoints.mutalMatchesUrl() 
        : location?.state?.isfrom === "selectedprofile"? EndPoints.selectedProfilesUrl() 
        : location?.state?.isfrom === "recentlyviewed"? EndPoints.recentlyViewUrl()
        : location?.state?.isfrom === "similarmatches" ? EndPoints.similarMatchesUrl()
        : location?.state?.isfrom === "explore" ? EndPoints.exploreUrl()
        :" ";

    let request =
      location?.state?.isfrom === "weekly"
        ? {
            email: loginDetail()[0],
      userId: loginDetail()[1],
      weeklypageno: `${cardPageNum.weeklyDataPage}`,
      weeklypagesize: pagesize,
      status: "new",
          }
        : location?.state?.isfrom === "recommended"?  {
           email: loginDetail()[0],
      userId: loginDetail()[1],
      recomendationpageno: `${cardPageNum.recommendedPage}`,
      recomendationpagesize: pagesize,
      status: "new",
          }
          :location?.state?.isfrom === "response" ? {
           email: loginDetail()[0],
           userId: loginDetail()[1],
           response_receivedpageno: `${cardPageNum.responseReceivedPage}`,
           response_receivedpagesize: `${pagesize}`,
           status: "new",
            

        }
          : location?.state?.isfrom ===  "lookingfor" ?
          {
            email: loginDetail()[0],
      userId: loginDetail()[1],
      profileslookingforpageno: `${cardPageNum.profilelookingforPage}`,
      profileslookingforpagesize: pagesize,
      status: "new",
          }
          : location?.state?.isfrom ===  "lookingforme" ?
          {
             email: loginDetail()[0],
      userId: loginDetail()[1],
      profileslookingformepageno: `${cardPageNum.profileLookingForMePage}`,
      profileslookingformepagesize: pagesize,
      status: "new",
          }
          : location?.state?.isfrom ===  "photorequest" ?
          {
           email: loginDetail()[0],
      userId: loginDetail()[1],
      photorequestreceivedpageno: `${cardPageNum.photorequestPage}`,
      photorequestreceivedpagesize: pagesize,
      status: "new",
          }
          : location?.state?.isfrom ===  "mutual" ?
          {
            email: loginDetail()[0],
      userId: loginDetail()[1],
      mutalmatchespageno: `${cardPageNum.mutualPage}`,
      mutalmatchespagesize: pagesize,
      status: "new",
          }
          : location?.state?.isfrom ===  "selectedprofile" ?
          {
            email: loginDetail()[0],
      userId: loginDetail()[1],
      selectedprofilespageno: `${cardPageNum.selectedProfilePage}`,
      selectedprofilepagesize: pagesize,
      status: "new", 
          }
          : location?.state?.isfrom === "recentlyviewed"?
          {
                email:  loginDetail()[0],
    userId:  loginDetail()[1],
    status: "new",
    recentpageno: `${cardPageNum.recentlyViewedPage}`,
    recentsize: pagesize
          }
          :location?.state?.isfrom === "similarmatches"?
          {
             email: loginDetail()[0],
    userId: loginDetail()[1],
    viewprofileid: location?.state?.id?location?.state?.id[0]:localStorage.getItem("profileId"),
    status: "new",
    similarpageno: `${cardPageNum.similarMatchesPage}`,
    similarsize: pagesize
          }
          :location?.state?.isfrom === "explore"?
          {
             email: loginDetail()[0],
              userId: loginDetail()[1],
              filterBy:location.state.filterBy,
              cityId:location.state.cityId,
              stateId:location.state.stateId,
              countryId:location.state.countryId,
              pageno:cardPageNum.exploreMatchesPage,
              educationId:location.state.educationId,
              occupationId:location.state.occupationId
          }
          : " "
          ;
      var loadMoreFor = location?.state?.isfrom === "weekly" ? "weekly":
                        location?.state?.isfrom === "recommended"? "recommended":
                        location?.state?.isfrom === "response"?  "response" :
                        location?.state?.isfrom === "lookingfor"?"lookingfor" :
                        location?.state?.isfrom === "lookingforme"?"lookingforme" :
                        location?.state?.isfrom === "photorequest"?"photorequest" :
                        location?.state?.isfrom === "mutual"?"mutual" :
                        location?.state?.isfrom === "selectedprofile"?"selectedprofile" 
                        : location?.state?.isfrom === "recentlyviewed"? "recentlyviewed"
                        :location?.state?.isfrom === "similarmatches"? "similarmatches"
                        :location?.state?.isfrom === "explore"? "explore"
                        : " ";

     let { statusCode, data } = await POST(`${config.api.API_URL}${url}`, request);

    if (statusCode === 200) {
      setSubmitStatus(false)
      if(loadMoreFor === "weekly"){
      data.data.Weeklymatches.map((e, i) => {
        setWeeklyData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });

      cardPageNum.weeklyDataPage = cardPageNum.weeklyDataPage + 1;
     }else if (loadMoreFor === "recommended"){
       data.data.recommendationsmatches.map((e, i) => {
        setRecommendedData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });
      cardPageNum.recommendedPage = cardPageNum.recommendedPage + 1;
     }else if (loadMoreFor === "response") {
       data.data.response_received.map((e, i) => {
        setresponseReceivedData((oldValue) => [...oldValue, e]);
        //console.log(e,"latestvalue");
        if(e.length>0){
          setLatest(true)
        } 
        
        // e.length>0?setLatest(true): ""

        setIsLoading(false)
      });

      cardPageNum.responseReceivedPage = cardPageNum.responseReceivedPage + 1;
     } else if (loadMoreFor === "lookingfor") {
       data.data.Profileslookingfor.map((e, i) => {
        setprofileILookingForData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });
      cardPageNum.profilelookingforPage = cardPageNum.profilelookingforPage + 1;
     }else if (loadMoreFor === "lookingforme") {
       data.data.Profileslookingforme.map((e, i) => {
        setprofileLoookingForMeData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });
      cardPageNum.profileLookingForMePage = cardPageNum.profileLookingForMePage + 1;
     }else if (loadMoreFor === "photorequest") {
      data.data.PhotoRequestReceived.map((e, i) => {
        setphotoRequestData((oldValue) => [...oldValue, e]);
        setIsLoading(false)

      });

      cardPageNum.photorequestPage = cardPageNum.photorequestPage + 1;
     }else if (loadMoreFor === "mutual") {
       data.data.Mutalmatches.map((e, i) => {
        setMutualData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });
      cardPageNum.mutualPage = cardPageNum.mutualPage + 1;
      
     } 
     else if (loadMoreFor === "selectedprofile") {
       data.data.Selectedprofiles.map((e, i) => {
        setSelectedProfileData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });

      cardPageNum.selectedProfilePage = cardPageNum.selectedProfilePage + 1;
     } else if (loadMoreFor === "recentlyviewed") {
       data.data.recently_viewed.map((e, i) => {
        setrecentlyViewedData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });

      cardPageNum.recentlyViewedPage = cardPageNum.recentlyViewedPage +1 ;
     }  
     else if (loadMoreFor === "similarmatches") {
        if (data.data.similarmatches.length > 0) {
          cardPageNum.similarMatchesPage = cardPageNum.similarMatchesPage + 1;

          data.data.similarmatches.map((e, i) => {
            setSimilarMatchesData((oldValue) => [...oldValue, e]);
          });
          setIsLoading(false)
        }
      }
      else if (loadMoreFor === "explore") {
        //console.log(data?.data,"exploredataaa");
        if (data.data.filtermatches.length > 0) {
          cardPageNum.exploreMatchesPage = cardPageNum.exploreMatchesPage + 1;

          data.data.filtermatches.map((e, i) => {
            setExploreMatchesData((oldValue) => [...oldValue, e]);
          });
          setIsLoading(false)
        }else if(data.data.filtermatches.length===0){
          setShowEducation(trans('matches.no_profiles_found'))
          setIsLoading(false)

        }
      }
    }
     
     else if (statusCode === 401) {
      logout();
    }
  };
  //console.log(exploreMatchesData, "profileselected");
   

  const callBackLoadMore = () => {
     setSubmitStatus(true)
     if(props.isFromComponent == "Filter"){
      // setFilterDataPage(filterDataPage+1)
      RefineSearch("load")
      // RefineSearch()
     }
     else{
      apicallweekly(5);
     }
    
  };
  const callbackSelectReject = (
    btntype,
    profileID,
    typeRequest,
    profileDetails,
    index,
    type
  ) => {
    //console.log("next reint", btntype, profileID, typeRequest);
    if (btntype === "SELECT") {
      apicallSelectReject(typeRequest, profileID).then((res) => {
        //console.log("SELECT", res);
      });
    } else if (btntype === "REJECT") {
      apicallSelectReject(typeRequest, profileID).then((res) => {
        //console.log("REJECT", res, type);
        if (res.status === "Success") {
          if (typeRequest === "Reject") {
            handleReject(profileID, index, type);
          }
        }
      });
    } else if (btntype === "MESSAGE") {
      setMessageProfileDetails(profileDetails);
      setSendMessagePopup(true);
    } else {
    }
  };

  const handleReject = (id, index, type) => {
    //console.log("messageFor", type);

    if (type === "weekly") {
      var newValue = Weeklydata.filter((e) => e.profileid !== id);
      setWeeklyData(newValue);
    } else if (type === "recommend") {
      var newValue = RecommendedData.filter((e) => e.profileid !== id);
      setRecommendedData(newValue);
    } else if (type === "lookingfor") {
      var newValue = profileILookingForData.filter((e) => e.profileid !== id);
      setprofileILookingForData(newValue);
    } else if (type === "lookingforme") {
      var newValue = profileLoookingForMeData.filter((e) => e.profileid !== id);
      setprofileLoookingForMeData(newValue);
    } else if (type === "mutual") {
      var newValue = MutualData.filter((e) => e.profileid !== id);
      setMutualData(newValue);
    } else if (type === "selected") {
      var newValue = selectedProfileData.filter((e) => e.profileid !== id);
      setSelectedProfileData(newValue);
    }
  };
  

  const handleRequestPhoto = () => {
    if ( ProfileData?.status === "pending") {
      // setShowUploadImagePopup(true);
      setShowRequestpopup(true);
    } 
     else{
      setShowRequestpopup(true);
    }
  };

  const handleSetMessage = (id, type) => {
    //console.log("type", type);
    if (type === "weekly") {
      var newvalue = Weeklydata.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" };
        }
        return e;
      });
      setWeeklyData(newvalue);
    } else if (type === "recommend") {
      var newvalue = RecommendedData.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" };
        }
        return e;
      });
      setRecommendedData(newvalue);
    } else if (type === "lookingfor") {
      var newvalue = profileILookingForData.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" };
        }
        return e;
      });
      setprofileILookingForData(newvalue);
    } else if (type === "lookingforme") {
      var newvalue = profileLoookingForMeData.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" };
        }
        return e;
      });
      setprofileLoookingForMeData(newvalue);
    } else if (type === "mutual") {
      var newvalue = MutualData.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" };
        }
        return e;
      });
      setMutualData(newvalue);
    } else if (type === "selected") {
      var newvalue = selectedProfileData.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" };
        }
        return e;
      });
      setSelectedProfileData(newvalue);
    }
  };

    const apicallHoroTrustRequest = async (profileID, value, type) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      type: type,
      sendsms: value.sendSms,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.requestphotoUrl()}`,
      request
    );

    if (statusCode === 200) {
      setsuccessMessage(trans('success_popup.photo_request_sent'));
      // apicallDashboard(1);
      dispatch(dashboardProfileData())
      setshowSuccessPopup(true);
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apicallSelectReject = async (type, profileID) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      type: `${type}`,
      toProfileId: `${profileID}`,
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.selectRejectUrl()}`,
      request
    );

    if (statusCode === 200) {
    } else if (statusCode === 401) {
      logout();
    }
  };
   const apicallMessage = async (profileID, values) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      message: values.message,
      status: "SendMessage",
      sendsms: values.sendSms,
      messageFrom: "Desktop",
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.sendMessageUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.status === "Success") {
        setshowMessageSuccess(true);
        
      }
      return data;
    } else if (statusCode === 401) {
      logout();
    }
    return data;
  };
  
  const apicalladvancesearch = async (preSearch, preSearchRequest) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
    }
    if (preSearch) {
      request = {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        "ageTo": preSearchRequest?.ageTo,
        "ageFrom": preSearchRequest?.ageFrom,
        "heightFrom": Number(preSearchRequest?.heightFrom),
        "heightTo":  Number(preSearchRequest?.heightTo),
        "marital_status": preSearchRequest?.marital_status??[],
        "physicalStatus": preSearchRequest?.physicalStatus??[],
        "mother_tongue": preSearchRequest?.mother_tongue??[],
        "educationId": preSearchRequest?.educationId??[],
        "casteIdList": preSearchRequest?.casteIdList??[],
        "subcasteId": preSearchRequest?.subcasteId??[],
        "countryIdList": preSearchRequest?.countryIdList??[],
        "religionIdList":preSearchRequest?.religionIdList??[],
        "employedInIdList": preSearchRequest?.employedInIdList??[],
        "occupationIdList": preSearchRequest?.occupationIdList??[],
        "cityIdList": preSearchRequest?.cityIdList??[],
        "stateIdList": preSearchRequest?.stateIdList??[],
        "starIdList":preSearchRequest?.starIdList??[],
        "raasiIdList": preSearchRequest?.raasiIdList??[],
        "doshamIdList":preSearchRequest?.doshamIdList??[],
        "drinkingIdList":preSearchRequest?.drinkingIdList??[],
        "smokingIdList":preSearchRequest?.smokingIdList??[],
        "foodIdList": preSearchRequest?.foodIdList??[],
        "shortlisted": preSearchRequest?.shortlisted??"",
        "ignored": preSearchRequest?.ignored??"",
        "contacted": preSearchRequest?.contacted??"",
        "viewed": preSearchRequest?.viewed??"",
        "withHoroscope": preSearchRequest?.withHoroscope??"",
        "withPhoto": preSearchRequest?.withPhoto??"",
        "days": preSearchRequest?.days??"",
        "weeks": preSearchRequest?.weeks??"",
        "months": preSearchRequest?.months??"",
        "minincome": preSearchRequest?.minincome ?? "",
        "maxincome": preSearchRequest?.maxincome ?? "",
      };
    }
    

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${preSearch? EndPoints.searchfilterUrl() : EndPoints.advanceSearchUrl()}`,
      request
    );


    if (statusCode === 200) {
       setFilterOpen(true)
      setAdvanceSearch(data.data);
    } else if (statusCode === 401) {
      logout();
    }
  };

  const dispatch = useDispatch()
  
    const {raasiList}  = useSelector(state => ({
      raasiList: state.Home?.raasiList?.data?.data?.raasi
    }));

    const {stateList}  = useSelector(state => ({
      stateList: state.Home?.stateList?.data?.data?.states
    }));

    const {cityList}  = useSelector(state => ({
      cityList: state.Home?.cityList?.data?.data?.cities
    }));

    const {casteList}  = useSelector(state => ({
      casteList: state.Home?.cList?.data?.data?.castes
    }));
  
  
    const {subCasteList}  = useSelector(state => ({
      subCasteList: state.Home?.scList?.data?.data?.subcastes
    }));
  
    //console.log(raasiList,"1234567890");

    //console.log(stateList,"stateList");

    //console.log(cityList,"cityList");
    
    //console.log(casteList,"casteList");

    //console.log(subCasteList,"subCasteList");


    useEffect(() => {
      raasiList && addFilter(raasiList, "raasi")
    },[raasiList])

    useEffect(() => {
      //console.log(stateList,"stateList");
      stateList && addFilter(stateList, "state")
    },[stateList])

    useEffect(() => {
      //console.log(cityList,"cityList");
      cityList && addFilter(cityList, "city")
    },[cityList])

    useEffect(() => {
      //console.log(casteList,"casteList");
      casteList && addFilter(casteList, "caste")
    },[casteList])

    useEffect(() => {
      //console.log(subCasteList,"subCasteList");
      subCasteList && addFilter(subCasteList, "subCaste")
    },[subCasteList])

    const addFilter = (event, menu, all) => {
        //console.log(event, menu, advancesearch, "stateList");
        // Filter out duplicate child options before appending
        if(isChecked == "N"){
        const uniqueChildOptions = event?.filter(
          (child) =>
            !advancesearch?.[menu]?.find(
              (existingChild) => existingChild.id === child.id
            )
        );
        //console.log(uniqueChildOptions,"uniqueChildOptions");

        let selectAll = uniqueChildOptions?.map((status, i) =>{
          status.selected = 'N'
        })
        // Append child options to raasi options
        setAdvanceSearch((prevSearchOptions) => ({
          ...prevSearchOptions,
          [menu]: [...prevSearchOptions?.[menu], ...uniqueChildOptions],
        }));
        return false;
      }
      else if(isChecked=='C'){
        setAdvanceSearch((prevSearchOptions) => ({
          ...prevSearchOptions,
          [menu]: [],
        }));
      }
      else{
        let as = []
        event.map((selected)=>{
          if(as.indexOf(selected.id)==-1){
            as.push(selected.id)
          }
        })
        
        var uniqueChild = ""
        let res = ""
        res = advancesearch?.[menu]?.map(sel=>sel.id)
        uniqueChild = res?.filter(function(obj) { return as.indexOf(obj) == -1; });
        uniqueChild?.map((unique,i)=>{
          res = advancesearch?.[menu]?.map(sel=>sel.id)
          uniqueChild = res.filter(function(obj) { return as.indexOf(obj) == -1; });
          let index1 = res.indexOf(unique)
            advancesearch?.[menu].splice(index1, 1)
        })
        //console.log(advancesearch?.[menu],"advancesearch?.[menu]");
      }
    }

    // //console.log(advancesearch,"add all filter");

  
    const selectAllFilter = (event, menu, all) => {
      //console.log(event, menu, all,"select all filter");
      if(menu == "motherTongue" ){
        dispatch(registerCasteList(
           
        advancesearch?.["motherTongue"]?.map((status, i) =>(
          status.id
        )),
        advancesearch?.["religion"]?.map((status, i) =>(
          status.selected == "Y" ?? status.id
        )), 
        ))
        advancesearch?.["caste"]?.splice(0,advancesearch?.["caste"]?.length)
        advancesearch?.["subcaste"]?.splice(0,advancesearch?.["subcaste"]?.length)
      }
      if(menu == "religion" ){
        dispatch(registerCasteList(
          advancesearch?.["motherTongue"]?.map((status, i) =>{
            if(status.selected == "Y" ){
              return (
                status.id
              )
            }
            
          }), 
        advancesearch?.["religion"]?.map((status, i) =>(
          status.id
        )), 
       
        ))
        advancesearch?.["caste"]?.splice(0,advancesearch?.["caste"]?.length)
        advancesearch?.["subcaste"]?.splice(0,advancesearch?.["subcaste"]?.length)
      }
      if(menu == "caste") {
        dispatch(registerSubCasteList(
        advancesearch?.["caste"]?.map((status, i) =>(
          status.id
        ))))
        advancesearch?.["subcaste"]?.splice(0,advancesearch?.["subcaste"]?.length)
      }
      // if(menu == "state") {
      //   dispatch(registerCityList(
      //   advancesearch?.["state"]?.map((status, i) =>(
      //     status.id
      //   ))))
      //   advancesearch?.["city"]?.splice(0,advancesearch?.["city"]?.length)
      // }
      // if(menu == "country") {
      //   dispatch(registerStateList(
      //   advancesearch?.["country"]?.map((status, i) =>(
      //     status.id
      //   ))))
      //   advancesearch?.["state"]?.splice(0,advancesearch?.["state"]?.length)
      //   advancesearch?.["city"]?.splice(0,advancesearch?.["city"]?.length)
      // }
      if(menu == "country") {
        let id=[]
        advancesearch?.["country"]?.map((status, i) =>{
          id.push(status.id)
        })
        setIsChecked('N')
        dispatch(registerStateList(id))
      }
      if(menu == "state") {
        let id=[]
        advancesearch?.["state"]?.map((status, i) =>{
          id.push(status.id)
        })
        setIsChecked('N')
        dispatch(registerCityList(id))
      }
      if(menu == "star") {
        let id=[]
        advancesearch?.["star"]?.map((status, i) =>{
          id.push(status.id)
        })
        setIsChecked('N')
        dispatch(registerRaasiList(id))
      }
      let selectAll = advancesearch?.[menu]?.map((status, i) =>{
        status.selected = 'Y'
      })
      setAdvanceSearch({
        ...advancesearch,
        [menu]: advancesearch?.[menu]
      })
    }
  
    const clearAllFilter = (event, menu, clear) => {
      
      if(menu == "religion" || menu == "motherTongue") {
        let clearAll = advancesearch?.[menu]?.map((status, i) =>{
          status.selected = 'N'
        })    
        setIsChecked('C')
        dispatch(registerCasteList(0)) 
        dispatch(registerSubCasteList(0)) 
         setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }
      else if(menu == "caste") {
        let clearAll = advancesearch?.[menu]?.map((status, i) =>{
          status.selected = 'N'
        })    
        setIsChecked('C')
        dispatch(registerSubCasteList(0)) 
         setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }

      // if(menu == "religion" || menu == "motherTongue") {
        // let clearAllCaste = advancesearch?.["caste"]?.splice(0,advancesearch?.["caste"]?.length)
        // let clearAllSubCaste = advancesearch?.["subCaste"]?.splice(0,advancesearch?.["subCaste"]?.length)
        // setAdvanceSearch({
        //   ...advancesearch,
        //   ["caste"]: advancesearch?.[menu],
        //   ["subCaste"]: advancesearch?.[menu]
        // }) 
      // }

      // if(menu == "caste") {
      //   let clearAllSubCaste = advancesearch?.["subCaste"]?.splice(0,advancesearch?.["subCaste"]?.length)
      //   setAdvanceSearch({
      //     ...advancesearch,
      //     ["subCaste"]: advancesearch?.[menu]
      //   }) 
      // }

      // else if(menu == "country") {
      //   let clearAllState = advancesearch?.["state"]?.splice(0,advancesearch?.["state"]?.length)
      //   setAdvanceSearch({
      //     ...advancesearch,
      //     ["state"]: advancesearch?.[menu]
      //   }) 
      //   let clearAllCity = advancesearch?.["city"]?.splice(0,advancesearch?.["city"]?.length)
      //   setAdvanceSearch({
      //     ...advancesearch,
      //     ["city"]: advancesearch?.[menu]
      //   }) 
      // }

      // else if(menu == "state") {
      //   let clearAll = advancesearch?.["city"]?.splice(0,advancesearch?.["city"]?.length)
      //   setAdvanceSearch({
      //     ...advancesearch,
      //     ["city"]: advancesearch?.[menu]
      //   }) 
      // }
      else if(menu == "star") {
        let selectAll = advancesearch?.[menu]?.map((status, i) =>{
          status.selected = 'N'
        })    
        setIsChecked('C')
        dispatch(registerRaasiList(0))  
         setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }

      else if(menu == "country") {
        let selectAll = advancesearch?.[menu]?.map((status, i) =>{
          status.selected = 'N'
        })    
        setIsChecked('C')
        dispatch(registerStateList(0)) 
        dispatch(registerCityList(0)) 
         setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }
      else if(menu == "state") {
        let selectAll = advancesearch?.[menu]?.map((status, i) =>{
          status.selected = 'N'
        })    
        setIsChecked('C')
        dispatch(registerCityList(null)) 
         setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }
     
       else{ let clearAll = advancesearch?.[menu]?.map((status, i) =>{
          status.selected = 'N'
        })
        setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }
      //console.log(advancesearch,"advancesearch");
    }
  
    const filterOnchange = (id, name, selected, menu, event) => {
      //console.log(id, name, selected, menu,event,"selct filter");
      if(menu == "star"){
        let selectedStars = selectedStarList
        let index1 = ""
        index1 = selectedStars.indexOf(id)
        if(index1==-1){
          let object = advancesearch?.[menu]?.filter(star => star.selected === 'Y');
          object.map((selected)=>{
            if(selectedStars.indexOf(selected.id)==-1){
              selectedStars.push(selected.id)
            }
          })
        }
        if(selected == "Y"){
          selectedStars.splice(index1, 1)
        }
        else{
          selectedStars.push(id)
        }
        setSelectedStarList(selectedStars)
        setIsChecked(selected)
        //console.log(selectedStarList,"selectedStarList");
        dispatch(registerRaasiList(selectedStars))
      }


      if(menu == "motherTongue" || menu == "religion"){
        advancesearch?.["subCaste"]?.splice(0,advancesearch?.[menu]?.length)
        let as = selectedMRList?.[menu]
        let index1 = ""
        let object = advancesearch?.[menu]?.filter(o => o.selected === 'Y');
        if(object?.length>0)
        object.map((obj, j) => {
        let index = as.indexOf(obj.id)
        index1 = as.indexOf(id)
        if(index == -1){
          as.push(obj.id)
        }
        if( index1 == -1){
          as.push(id)
        }
      })

      else{
        index1 = as.indexOf(id)
        if( index1 == -1){
          as.push(id)
        }
      }

      if(selected == "Y"){
        as.splice(index1, 1)
      }
        setSelectedMRList(selectedMRList)
        //console.log(as,"selct filter");
        setIsChecked(selected)
        //console.log(selectedMRList,as,"selectedMRList");

        dispatch(registerCasteList(selectedMRList?.motherTongue, selectedMRList?.religion))

      }
      if(menu == "country"){
        let selectedCountry = selectedCountries
        let index1 = ""
        index1 = selectedCountry.indexOf(id)
        if(index1==-1){
          let object = advancesearch?.[menu]?.filter(country => country.selected === 'Y');
          object.map((selected)=>{
            if(selectedCountry.indexOf(selected.id)==-1){
              selectedCountry.push(selected.id)
            }
          })
        }
        if(selected == "Y"){
          selectedCountry.splice(index1, 1)
        }
        else{
          selectedCountry.push(id)
        }
        setIsChecked(selected)
        dispatch(registerStateList(selectedCountry))
        if(selectedCountry?.length==0){
          dispatch(registerCityList(selectedCountry))
        }       
      }

      if(menu == "state"){
        let selectedState = selectedStates
        let index1 = ""
        index1 = selectedState.indexOf(id)
        if(index1==-1){
          let object = advancesearch?.[menu]?.filter(state => state.selected === 'Y');
          object.map((selected)=>{
            if(selectedState.indexOf(selected.id)==-1){
              selectedState.push(selected.id)
            }
          })
        }
        if(selected == "Y"){
          selectedState.splice(index1, 1)
        }
        else{
          selectedState.push(id)
        }
        setIsChecked(selected)
        dispatch(registerCityList(selectedState))
      }      

      if(menu == "caste"){
        let selectedSubCaste = selectedSubCasteList
        let index1 = ""
        let object = advancesearch?.[menu]?.filter(subCaste => subCaste.selected === 'Y');
        if(object?.length>0){
        object.map((obj, j) => {
        let index = selectedSubCaste.indexOf(obj.id)
        index1 = selectedSubCaste.indexOf(id)
        if(index == -1){
          selectedSubCaste.push(obj.id)
        }
        if( index1 == -1){
          selectedSubCaste.push(id)
        }
      })
    }
    else{
      index1 = selectedSubCaste.indexOf(id)
      if( index1 == -1){
        selectedSubCaste.push(id)
      }
    }


    if(selected == "Y"){
      selectedSubCaste.splice(index1, 1)
    }      //console.log(selectedSubCaste,"selct filter");
        setSelectedSubCasteList(selectedSubCaste)
        setIsChecked(selected)
        dispatch(registerSubCasteList(id))
      }
  
      let selectOption = advancesearch?.[menu]?.map((status, i) =>{
        if(status.id == id){
          if(selected == "N"){
            status.selected = 'Y'
          }
          else if(selected == "Y"){
            status.selected = 'N'
          }
        }
      })
      //console.log(advancesearch?.[menu],"advancesearch?.[menu]");
      setAdvanceSearch({
        ...advancesearch,
        [menu]: advancesearch?.[menu]
      }) 
    }

    const filterOnchangeForActivity = (item) => {
      let index = selectedActivityFilter.indexOf(item)
      if(index >-1){
        let removeActivityFIlter = selectedActivityFilter.splice(index,1)
        //console.log(removeActivityFIlter,selectedActivityFilter,"removeActivityFIlter");
        setSelectedActivityFilter(selectedActivityFilter)
      }
      else{
        setSelectedActivityFilter([...selectedActivityFilter,item])
      }
    }

    //console.log(selectedActivityFilter,"selectedActivityFilter");

    const handleSearchFIlter = (e,menu) => {
      //console.log(advancesearch, "advancesearch");
      // setsearchText(e.target.value);
      if (e.target.value !== "") {
        setIsSearch({...isSearch, [menu]:true})
        const filteredEntity = advancesearch?.[menu]?.filter(entity => entity?.name?.toLowerCase()?.indexOf(e.target.value.toLowerCase()) !== -1);
        setTempAdvanceSearch({
          ...tempAdvancesearch,
          [menu]: filteredEntity
        })
      } else {
        setIsSearch({...isSearch, [menu]:false})
        setAdvanceSearch(advancesearch)
      }
    }
  
    const RefineSearch = (e,menu) => {
      props?.SetDataLength(-1)
     if(e != "load"){
      props?.setContentCardData([])
      props?.setContentCardRange([3])
      // alert("hello")
      props?.setIsLoading(true)
        props?.setFilterData([])
        setFilterData([])
      }
      localStorage.getItem("ghj",true)
      closeFilter()
      
      // setIsFilterResult(true)
      props.setIsFromComonent("Filter")
      props.setIsLoadFromRecommeded(false)
      props.setIsFilterResult(true);
      // setFilterDataPage(filterDataPage+1)
      advancesearch?.minheightId?.map((heightId, i)=>{
        if(heightId.floatValue == advancesearch?.minheight){
          selectedOption["heightFrom"] = heightId.id
          if(heightId.floatValue == advancesearch?.maxheight){
            selectedOption["heightTo"] = heightId.id
         }
        }
        else if(heightId.floatValue == advancesearch?.maxheight){
           selectedOption["heightTo"] = heightId.id
        }
      })
      //console.log(advancesearch, selectedActivityFilter, "refineresult");
      Object.entries(selectedOption).map((key,value) => {
        let keyName = key[0]
        //console.log(keyName,"keyNamekeyName");
        if(keyName == "pageno"){
          selectedOption[keyName] = filterDataPage
        }
        else if(keyName == "ageFrom" ){
          selectedOption[keyName] = advancesearch?.["minage"]
        }
        else if(keyName == "ageTo"){
          selectedOption[keyName] = advancesearch?.["maxage"]
        }
        else if(keyName == "heightFrom"){
          selectedOption[keyName] = selectedOption["heightFrom"]
        }
        else if(keyName == "heightTo"){
          selectedOption[keyName] = selectedOption["heightTo"]
        }
        else if(keyName == "days" ||keyName == "weeks" ||keyName == "months" ){
          //console.log(selectedActivityFilter,"selectedActivityFilter");
          selectedActivityFilter?.map((activity,i) => {

            if(activity == "Within a day" && keyName == "days"){
              selectedOption[keyName] = activity
            }
            else if(activity == "Within a week" && keyName == "weeks"){
              selectedOption[keyName] = activity
            }
            else if(activity == "Within a month" && keyName == "months"){
              selectedOption[keyName] = activity
            }
            else{

            }
          })
        }
        
        else if(keyName == "withHoroscope" || keyName == "withPhoto"){
          advancesearch?.["showProfile"]?.map((val,i) => {
            if(val.selected == "Y" && val.name == "With Photo"){
                selectedOption["withPhoto"] = "on"
              }
              else if(val.selected == "Y" && val.name == "With Horoscope"){
                selectedOption["withHoroscope"] = "on"
              }
          })
        }
        else if(keyName == "shortlisted" || keyName == "ignored" || keyName == "contacted" || keyName == "viewed"){
          advancesearch?.["dontshowProfile"]?.map((val,i) => {
            if(val.selected == "Y"){
              if(val.name == "Rejected"){
                selectedOption["ignored"] = "on"
              }
              else if(val.name == "Already Contacted"){
                selectedOption["contacted"] = "on"
              }
              else if(val.name == "Viewed Profiles"){
                selectedOption["viewed"] = "on"
              }
              else {
                selectedOption["shortlisted"] = "on"
              }
            }
          })
        }
        else{
          advancesearch?.[keyName]?.map((val,i) => {
            if(val.selected == "Y"){
              if(keyName == "maritalStatus"){
                selectedOption[keyName].push(val.name)
              }
              else{
                selectedOption[keyName].push(val.id)
              }
            }
            //console.log(selectedOption,"selectedOption");
          })
        }


        setFilterRequest(selectedOption)
      })
      sendFilterRequest()
      dispatch(sendFilterRequest(selectedOption))
      props?.setAdvanceSearch(advancesearch)
      // props?.selectedOption = selectedOption
    }

    const closeFilterPopup = () => {
      closeFilter()
      props?.setOpenFilterPopup(false)
    }

    const ageRange = (min,max) => {
      console.log(min,max,"ageRangeageRange");
      advancesearch["minage"] = min
      advancesearch["maxage"] = max
      setAdvanceSearch(advancesearch)
    }

    const heightRange = (min,max) => {
      //console.log(min,max,"min,max");
      //console.log(advancesearch.minheightId,"advancesearchadvancesearch");
          advancesearch.minheight = min
          advancesearch.maxheight = max
    }


    let {filterResponseData} = useSelector(state => ({
      filterResponseData: state.FilterSection?.filterResponseData?.data?.filtermatches
    }));

    console.log(filterResponseData,"filterResponseDatafilterResponseData");

    useEffect(() => {
      // if(filterResponseData?.length>0 ){
        setSubmitStatus(false)
        if(filterResponseData?.contentCard){
          props?.setContentCardData((oldValue) => [...oldValue, filterResponseData?.contentCard])
        }
      filterResponseData?.map((e, i) => {
        props?.setFilterData((oldValue) => [...oldValue, e])
        setFilterData((oldValue) => [...oldValue, e]);
      });
      setSelectedProfileData([])
      setMutualData([])
      setphotoRequestData([])
      setprofileILookingForData([])
      setprofileLoookingForMeData([])
      setresponseReceivedData([])
      setRecommendedData([])
      setrecentlyViewedData([])
      setSimilarMatchesData([])
      setWeeklyData([])
      setIsLoading(false)
    // }
    },[filterResponseData])

    useEffect(() => {
      if(Object.keys(advancesearch).length > 0){
      let as = selectedMRList?.["motherTongue"]
        let object = advancesearch?.["motherTongue"]?.filter(o => o.selected === 'Y');
        object?.map((obj, j) => {
        let index = as.indexOf(obj.id)
        if(index == -1){
          as.push(obj.id)
        }
      })
      setSelectedMRList(selectedMRList)
    }
    },[selectedMRList?.["motherTongue"],advancesearch?.["motherTongue"]])

    useEffect(() => {
      if(Object.keys(advancesearch).length > 0){
      let as = selectedMRList?.["religion"]
        let object = advancesearch?.["religion"]?.filter(o => o.selected === 'Y');
        object?.map((obj, j) => {
        let index = as.indexOf(obj.id)
        if(index == -1){
          as.push(obj.id)
        }
      })
      setSelectedMRList(selectedMRList)
    }
    },[selectedMRList?.["religion"],advancesearch?.["motherTongue"]])

  //console.log(filterResponseData,"filterResponseData");
  //console.log(dashboard1Data?.isPaid, "paidsearch");
  //console.log(Weeklydata, "photoRequestData");
  const closeModel = () => {
    setShowKjBannerPopup(false)
    setShowKjThankPopup(false)
  }

  const isChristain = advancesearch?.religion?.filter(data=>data?.id == Constants.christialId && data?.selected == "Y")?.length !=0

  return (
    
    

    <FilterPopup
      closable={false}
      bodyStyle={{ padding: "0" ,overflow:"hidden"}}
      close={closeFilterPopup}
      open={isOpenFilter}
    >
      {filterOpen?
      <>
       <div className="">
        <div className="">
          <div className=" md:col-span-5 lg:col-span-5 bg-[#E9E9E9]">
            <div>
              <div className=" bg-[#D10A11] p-2 rounded-b-[30px]">
                <Searchbyid menu="filtersearch"/>
                <div className="flex justify-between">
                  <p className="flex text-[#FFFFFF] text-[14px] py-2">
                    <span>
                      <BsArrowLeft
                        size={18}
                        color="#FFFFFF"
                        onClick={closeFilterPopup}
                      />
                    </span>
                    <span className="ml-2">Filter</span>
                  </p>
                  <p className="text-[#FFFFFF] text-[14px] py-2" onClick={() => {apicalladvancesearch()}}>Clear all</p>
                </div>
              </div>
              <div >
                {/* {!filterOpen ? <Loader className="h-[33rem]"/> : */}
                <div className="grid grid-cols-10">
                  <div className="bg-[#E9E9E9] font-bold pl-[.8rem] pt-2 col-span-4 divide-y overflow-y-scroll h-[36rem] md:h-[44rem] scrollbar-none">
                    <div>
                      <p 
                      onClick={() => {
                        setSelectedMenu("1");
                      }}
                      className={`text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "1"? "text-[#D10A11]": "text-[#575556]" } py-2 cursor-pointer`}>
                        Activity
                      </p>
                    </div>
                    <div>
                      <p 
                      onClick={() => {
                        setSelectedMenu("2");
                      }}
                      className={`text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "2"? "text-[#D10A11]": "text-[#575556]" } py-2 cursor-pointer`}>
                        {trans('view_profile.basic_details')}
                      </p>
                    </div>
                    <div>
                      <p 
                      onClick={() => {
                        setSelectedMenu("3");
                      }}
                      className={`text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "3"? "text-[#D10A11]": "text-[#575556]" } py-2 cursor-pointer`}>
                        {trans('view_profile.marital_status')}
                      </p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("4");
                      }} className={`text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "4"? "text-[#D10A11]": "text-[#575556]" } py-2`}>
                        {trans('view_profile.physical_status')}
                      </p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("5");
                      }} className={`text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "5"? "text-[#D10A11]": "text-[#575556]" } py-2`}>
                        {trans('view_profile.mother_tongue')}
                      </p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("6");
                      }}className={`text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "6"? "text-[#D10A11]": "text-[#575556]" } py-2`}>Religion</p>
                    </div>
                    {isChristain && <div>
                      <p onClick={() => {
                        setSelectedMenu("23");
                      }}className={`text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "23"? "text-[#D10A11]": "text-[#575556]" } py-2`}>Denomination</p>
                    </div>}
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("7");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "7"? "text-[#D10A11]": "text-[#575556]" } py-2`}>Caste</p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("8");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "8"? "text-[#D10A11]": "text-[#575556]" } py-2`}>{trans('view_profile.sub_caste')}</p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("9");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "9"? "text-[#D10A11]": "text-[#575556]" } py-2`}>{trans('set_your_partner_expectations.education')}</p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("10");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "10"? "text-[#D10A11]": "text-[#575556]" } py-2`}>
                        {trans('set_your_partner_expectations.employment_type')}
                      </p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("11");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "11"? "text-[#D10A11]": "text-[#575556]" } py-2`}>{trans('set_your_partner_expectations.occupation')}</p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("12");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "12"? "text-[#D10A11]": "text-[#575556]" } py-2`}>
                        Country Living in
                      </p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("13");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "13"? "text-[#D10A11]": "text-[#575556]" } py-2`}>State</p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("14");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "14"? "text-[#D10A11]": "text-[#575556]" } py-2`}>City</p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("15");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "15"? "text-[#D10A11]": "text-[#575556]" } py-2`}>
                        Star
                      </p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("16");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "16"? "text-[#D10A11]": "text-[#575556]" } py-2`}>
                        {trans('view_profile.raasi')}
                      </p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("17");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "17"? "text-[#D10A11]": "text-[#575556]" } py-2`}>
                        {trans('view_profile.dhosam')}
                      </p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("18");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "18"? "text-[#D10A11]": "text-[#575556]" } py-2`}>Food Habit</p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("19");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "19"? "text-[#D10A11]": "text-[#575556]" } py-2`}>Smoke</p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("20");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "20"? "text-[#D10A11]": "text-[#575556]" } py-2`}>Drink</p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("21");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "21"? "text-[#D10A11]": "text-[#575556]" } py-2`}>
                        Show Profile
                      </p>
                    </div>
                    <div>
                      <p onClick={() => {
                        setSelectedMenu("22");
                      }}className={` text-[13px] border border-b-[#CFCFCF] font-segeo ${selectedMenu === "22"? "text-[#D10A11]": "text-[#575556]" } py-2`}>
                        Don’t show Profile
                      </p>
                    </div>
                  </div>
                {filterOpen?
                  <div className="col-span-6 px-[1rem] pt-4 bg-[#F3F3F3]">

                    <div className={`${selectedMenu === "1" ? "" : "hidden"}`}>
                      <div className="p-2 px-6">
                        {Object.keys(advancesearch).length > 0 ? (
                          <FilterContents
                            data={advancesearch}
                            menu="activity"
                            selectedActivityFilter={selectedActivityFilter}
                            filterOnchangeForActivity={filterOnchangeForActivity}
                          />
                        ) : (
                          " "
                        )}
                      </div>
                      <div>
                        {/* <RefineFilter menu ="activity" RefineSearch={RefineSearch}/> */}
                      </div> 
                    </div>

                    <div className={`${selectedMenu === "2" ? "" : "hidden"}`}>
                      <div className="p-2 px-6">        
                        <BasicFilter
                          menu="Age"
                          onChange={ageRange}
                          data={advancesearch}
                        />
                        <BasicFilter
                          menu="Height"
                          onChange={heightRange}
                          data={advancesearch}
                        />    
                      </div>
                      {/* <RefineFilter menu ="basic" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                    </div>

                    <div className={`${selectedMenu === "3" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["maritalStatus"]?tempAdvancesearch:advancesearch}
                        menu ="maritalStatus"
                        title={trans('view_profile.marital_status')}
                      />
                      <div>
                      {/* <RefineFilter menu ="maritalStatus" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "4" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["physicalStatus"]?tempAdvancesearch:advancesearch}
                        menu ="physicalStatus"
                        title={trans('view_profile.physical_status')}
                      />
                      <div>
                      {/* <RefineFilter menu ="physicalStatus" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "5" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["motherTongue"]?tempAdvancesearch:advancesearch}
                        menu ="motherTongue"
                        title={trans('view_profile.mother_tongue')}
                      />
                      <div>
                      {/* <RefineFilter menu ="motherTongue" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "6" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["religion"]?tempAdvancesearch:advancesearch}
                        menu ="religion"
                        title={trans('view_profile.religion')}
                      />
                      <div>
                      {/* <RefineFilter menu ="religion" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>
                    <div className={`${selectedMenu === "23" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["domain"]?tempAdvancesearch:advancesearch}
                        menu ="domain"
                        title={trans('view_profile.denomination')}
                      />
                      <div>
                      {/* <RefineFilter menu ="domain" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "7" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["caste"]?tempAdvancesearch:advancesearch}
                        menu ="caste"
                        title={trans('view_profile.caste')}
                      />
                      <div>
                      {/* <RefineFilter menu ="caste" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "8" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["subCaste"]?tempAdvancesearch:advancesearch}
                        menu ="subCaste"
                        title={trans('view_profile.sub_caste')}
                      />
                      <div>
                      {/* <RefineFilter menu ="subCaste" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "9" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["education"]?tempAdvancesearch:advancesearch}
                        menu ="education"
                        title={trans('view_profile.education')}
                      />
                      <div>
                      {/* <RefineFilter menu ="education" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "10" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["employedIn"]?tempAdvancesearch:advancesearch}
                        menu ="employedIn"
                        title={"Employment"}
                      />
                      <div>
                      {/* <RefineFilter menu ="employedIn" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "11" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["occupation"]?tempAdvancesearch:advancesearch}
                        menu ="occupation"
                        title={trans('view_profile.occupation')}
                      />
                      <div>
                      {/* <RefineFilter menu ="occupation" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "12" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["country"]?tempAdvancesearch:advancesearch}
                        menu ="country"
                        title={trans('view_profile.country')}
                      />
                      <div>
                      {/* <RefineFilter menu ="country" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "13" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["state"]?tempAdvancesearch:advancesearch}
                        menu ="state"
                        title={"State"}
                      />
                      <div>
                      {/* <RefineFilter menu ="state" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "14" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["city"]?tempAdvancesearch:advancesearch}
                        menu ="city"
                        title={trans('set_your_partner_expectations.city')}
                      />
                      <div>
                      {/* <RefineFilter menu ="city" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "15" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["star"]?tempAdvancesearch:advancesearch}
                        menu ="star"
                        title={trans('view_profile.star')}
                      />
                      <div>
                      {/* <RefineFilter menu ="star" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "16" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["raasi"]?tempAdvancesearch:advancesearch}
                        menu ="raasi"
                        title={trans('view_profile.raasi')}
                      />
                      <div>
                      {/* <RefineFilter menu ="raasi" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "17" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["dosham"]?tempAdvancesearch:advancesearch}
                        menu ="dosham"
                        title={trans('view_profile.dosham')}
                      />
                      <div>
                      {/* <RefineFilter menu ="dosham" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "18" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["food"]?tempAdvancesearch:advancesearch}
                        menu ="food"
                        title={"Food"}
                      />
                      <div>
                      {/* <RefineFilter menu ="food" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "19" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["smoking"]?tempAdvancesearch:advancesearch}
                        menu ="smoking"
                        title={trans('view_profile.smoking')}
                      />
                      <div>
                      {/* <RefineFilter menu ="smoking" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "20" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["drinking"]?tempAdvancesearch:advancesearch}
                        menu ="drinking"
                        title={trans('view_profile.drinking')}
                      />
                      <div>
                      {/* <RefineFilter menu ="drinking" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "21" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["showProfile"]?tempAdvancesearch:advancesearch}
                        menu ="showProfile"
                        title={"Show Profile"}
                      />
                      <div>
                      {/* <RefineFilter menu ="showProfile" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                    <div className={`${selectedMenu === "22" ? "" : "hidden"}`}>
                      <FilterContents
                        handleSearchFIlter={handleSearchFIlter}
                        isSelectAll={isSelectAll}
                        filterOnchange={filterOnchange}
                        data={isSearch["dontshowProfile"]?tempAdvancesearch:advancesearch}
                        menu ="dontshowProfile"
                        title={"Don't show profile"}
                      />
                      <div>
                      {/* <RefineFilter menu ="dontshowProfile" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/> */}
                      </div>
                    </div>

                  </div>
                  :
                 <Loader menu ="" className="h-[33rem] col-span-6 bg-[#"/> 
        //  <center>
        //      <img src={LoaderGif}/>
        //  </center>
      }
                </div>
                {/* // } */}
              </div>
            </div>
          </div>
        </div>
       </div>
       <div className="px-2 space-x-1 py-3 flex justify-around bg-[#F3F3F3]">
        <button
          type="button"
          className="bg-[#F3F3F3] justify-center rounded-md border border-[#9F9F9F] px-8 py-2 text-[14px] font-segeo text-[#7A7A7A] font-bold"
          onClick={closeFilterPopup}
        >
          Cancel
        </button>
        <button
          type="button"
          className="bg-[#F3F3F3] justify-center rounded-md border border-[#9F9F9F] px-8 py-2 text-[14px] font-segeo text-[#7A7A7A] font-bold"
          onClick={() => apicalladvancesearch()}
        >
          Reset
        </button>
        <button
          onClick = {(event) => {
            props?.setFilterDataPage(1)
            props?.setOpenFilterPopup(false)
            // props?.RefineSearch(event,props.menu)
            RefineSearch("apply")
          }
          }
          type="button"
          className="bg-[#D10A11] text-white justify-center rounded-md border border-[#9F9F9F] px-8 py-2 text-[14px] font-segeo font-bold"
        >
          Apply
        </button>
       </div>
      </>
     :
        <center>
        <img src={LoaderGif} alt=""/>
       </center>
      }
    </FilterPopup>
     
  );
}

export default FilterPanel;
