import React from 'react'
import './Skeleton.css'

//skeleton loader
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function CardSkeleton({cards,menu}) {
  return (
    <div className={`${menu == "selectedprofileslider" ? 'flex w-[100%]' : "mt-[2rem]"}`}>
        {Array(cards)
            .fill(0)
            .map((item,i) => (
            <section className="md:px-2 sm:px-0 lg:px-2 md:pt-2 lg:pt-2 pt-3 md:w-[106%] lg:w-[100%] relative z-0" key={i}>
            <div className="grid grid-cols-1 gap-6">
                <div className=" flex w-full  rounded-lg sahdow-lg overflow-hidden flex-row">
                    <div className="flex">
                        <Skeleton className='md:w-[9rem] lg:w-[13rem] w-[10rem] h-[11rem] card-skeleton-img'/>
                    </div>
                    <div className="w-full pb-[1rem] grid text-left space-y-1">
                        <div className="px-3 space-y-1 pt-3">
                        <p
                        >
                                                <Skeleton className='mb-[0.3rem] card-skeleton-id'/>
                        </p>
                        <p
                        >
                                                <Skeleton className='mb-[0.3rem] card-skeleton-name'/>
                        </p>
                        <p
                        >
                                                <Skeleton className='mb-[0.3rem] card-skeleton-age'/>
                        </p>
                        {menu == "photorequestreceived" ?
                        ""
                        :
                        <p
                        >
                                                <Skeleton className='mb-[0.2rem] card-skeleton-description'/>
                        </p>
                        }
                        </div>
                        {menu == "selectedprofile" || menu == "profilelookingfor" || menu == "profilelookingforme" || menu == "selectedprofileslider"?
                             <p
                         >
                                                 <Skeleton className=' card-skeleton-action'/>
                         </p>
                        :
                            <div className="flex justify-end pr-[1rem] ">
                                                    <Skeleton className='h-[2.2rem] px-3 rounded-[5px] w-[7rem]' />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
        ))
        }
    </div>
  )
}
