import React from 'react'
import OfflinePage from '../../Components/Kalyanlite/OfflinePage'

const OfflieProfilePage = () => {
  return (
    <div>
        <OfflinePage/>
    </div>
  )
}

export default OfflieProfilePage